import React, { useEffect, useState } from 'react';

interface CarouselProps {
    items: JSX.Element[];
}

const Carousel: React.FC<CarouselProps> = ({ items }) => {
    const [currentItemIndex, setCurrentItemIndex] = useState(0);

    useEffect(() => {
        const handleNextItem = () => {
            setCurrentItemIndex((prevIndex) => (prevIndex + 1) % items.length);
        };

        const interval = setInterval(handleNextItem, 5000); // Adjust the duration as needed
        return () => clearInterval(interval);
    }, [items]);

    return (
        <div className={`carousel vertical`}>
            <div className="carousel-inner">
                {items.map((item, index) => (
                    <div
                        key={index}
                        className={`carousel-item ${index === currentItemIndex ? 'active' : ''}`}
                        style={{
                            transform: `translateY(${100 * (index - currentItemIndex)}%)`,
                            transition: 'transform 5s ease-in-out', // Adjust the duration and timing function as needed
                        }}
                    >
                        {item}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Carousel;
