export interface HotelEventCenter {
    eventCenterId: number;
    hotelId?: number;
    eventCenterName: string;
    capacity: number;
    description?: string;
    amenityId?: number;
    bookingStatus: boolean;
    basePrice: number;
    currency: string;
    bookingStartDate: Date;
    bookingEndDate: Date;
    contactFirstName?: string;
    contactLastName?: string;
    contactEmail?: string;
    contactPhone?: string;
    photos?:string;
    updated?: Date;
    dateadded:Date;
    adjuster?: string;
}




export const hotelEventCenter: HotelEventCenter = {
    eventCenterId: 0,
    hotelId: 0,
    eventCenterName: "",
    capacity: 0,
    description: "",
    amenityId: 0,
    bookingStatus: false,
    basePrice: 0.00,
    currency: "",
    bookingStartDate: new Date(),
    bookingEndDate: new Date(),
    contactFirstName: "",
    contactLastName: "",
    contactEmail: "",
    contactPhone: "",
    photos: "",
    adjuster: "",
    updated: new Date(),
    dateadded: new Date(),
};
