
import * as React from "react";
import {
    FolderRegular,
    EditRegular,
    OpenRegular,
    DocumentRegular,
    PeopleRegular,
    DocumentPdfRegular,
    VideoRegular,
} from "@fluentui/react-icons";
import {
    TableBody,
    TableCell,
    TableRow,
    Table,
    TableHeader,
    TableHeaderCell,
    TableCellLayout,
    PresenceBadgeStatus,
    Avatar,
} from "@fluentui/react-components";
import axios from "axios";
import { WHITE_HOUSE_API } from "../../../../Constants/Constant";
import { IOnlineReservation } from "../../../../Data/General/OnlineReservation";

interface Item {
    file: { label: string; name: string };
    author: { label: string; status: PresenceBadgeStatus };
    lastUpdated: { label: string; timestamp: number };
    lastUpdate: { label: string; icon: JSX.Element };
}

interface User {
    email: string,
    phoneNumber:string,
    token:string,
  
    // other properties...
  }

interface DefaultProps {
    user: User;
}




const items: Item[] = [
    {
        file: { label: "Delux (R32)", name: "R32" },
        author: { label: "Max Mustermann", status: "available" },
        lastUpdated: { label: "7h ago", timestamp: 1 },
        lastUpdate: {
            label: "Reservation Verified",
            icon: <EditRegular />,
        },
    },
];

const columns = [
    { columnKey: "Room Name", label: "Room Name" },
    { columnKey: "Guest Name", label: "Guest Name" },
    { columnKey: "lastUpdated", label: "Last updated" },
    { columnKey: "lastUpdate", label: "Reservation status" },
];

const OnlineRequest: React.FC<DefaultProps> = ({user}) => {
    const [OnlineReservation, SetOnlineReservation] = React.useState<IOnlineReservation[]>([]);
    
    const fetchOnlineReservation = React.useCallback(async () => {
        try {
          const response = await axios.get(`${WHITE_HOUSE_API}/FetchOnlineRequest/Fetch
          `, {
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
          });
         if(response.data){
          SetOnlineReservation(response.data);
         }
        } catch (error) {
          console.error('Error fetching roles:', error);
        }
      },[user.token])
    
      React.useEffect(() => {
        fetchOnlineReservation();
      }, [ user.token, fetchOnlineReservation]);
    
    
    return (
        <Table arial-label="Default table">
            <TableHeader>
                <TableRow>
                    {columns.map((column) => (
                        <TableHeaderCell key={column.columnKey}>
                            {column.label}
                        </TableHeaderCell>
                    ))}
                </TableRow>
            </TableHeader>
            <TableBody>
                {OnlineReservation.map((item) => (
                    <TableRow key={item.guestId}>
                        <TableCell>
                            <TableCellLayout
                                media={
                                    <Avatar
                                        aria-label={`${item.roomName} (${item.roomNumber})`}
                                        name={`R${item.roomNumber}`}

                                    />
                                }
                            >
                                {item.firstName + " " + item.lastName}
                            </TableCellLayout>
                        </TableCell>
                        <TableCell>
                            <TableCellLayout
                                media={
                                    <Avatar
                                        aria-label={item.firstName + " " + item.lastName}
                                        name={item.firstName + " " + item.lastName}
                                        badge={{
                                            status: "available",
                                        }}
                                    />
                                }
                            >
                                {item.firstName + " " + item.lastName}
                            </TableCellLayout>
                        </TableCell>
                        <TableCell>{item.checkInDate?.toDateString()}</TableCell>
                        <TableCell>
                            <TableCellLayout media={<EditRegular />}>
                                {item.checkInDate?.toDateString()}
                            </TableCellLayout>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>

    );
};

export default OnlineRequest;