/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import './Booking.scss';
import '../../Themes/Fonts/Icon/csc.scss';
import RoomDialog from '../../Dialog/RoomDialog';
import { Room } from '../../../Data/General/Room';
import { WHITE_HOUSE_API } from '../../../Constants/Constant';
import axios from 'axios';
import { RoomDataListItem } from '../../../Data/General/RoomDetail';
import { DetailsList, IColumn } from '@fluentui/react/lib/DetailsList';
import { getTheme } from '@fluentui/react/lib/Styling';
import { Inventory } from '../../../Data/ServicesMenu';
import favoriteImg from '../../Themes/Images/SparklingHeartIcon-7e299b5cd397de9059d67380fa40f6c6.png';
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  makeStyles,
  shorthands,
  Tab,
  TabList,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
} from "@fluentui/react-components";
import {
  AddSquare24Filled,
  ArchiveArrowBack24Regular,
  BreakoutRoom24Regular,
  CalendarReply24Regular,
  ChannelAdd24Regular,
  Checkbox124Filled,
  Clock20Regular,
  CollectionsAdd24Regular,
  Food24Filled,
  HomePerson24Filled,
  PeopleCommunity24Filled,
  PeopleTeam24Regular,
  Vault24Filled,
  WalletCreditCard24Regular,
  WindowDevTools24Regular,
} from '@fluentui/react-icons';

import {
  CheckboxIndeterminate24Filled,
  ConferenceRoom24Filled,
} from '@fluentui/react-icons';
import { Interaction } from './Menu/Interaction';
import RightMenuItem from './Menu/RightMenu';
import type { TabListProps } from '@fluentui/react-components';
import SingleRoomViewDialog from '../../Dialog/SingleRoomViewDialog';
import GuestProfile from '../GuestProfile/GuestProfile';
import ReuseAble from '../Vendors/ReUseableComponent';
import ReservationType from '../ReservationTypes/ReservationType';
import Payment from '../Payment/Payment';
import Guests from '../Guests/Guest';
import CheckedOut from '../CheckedOut/CheckedOut';
import RSPayment from '../Payment/Paymentrs/RSPayment';
import { RoomManager } from '../../../Data/General/RoomManager';
import EventReservation from '../EventReservation/EventReservation';
import EVTPayment from '../Payment/Paymentrs/EVTPayment/EVTPayment';
import { BarInteraction } from './Menu/BarInteractions';
import RoomReservation from '../RoomReservation/RoomReservation';
import BarBilling from '../BarBilling/BarBilling';
import RestaurantBilling from '../RestuarantBilling/RestaurantBilling';
import Loundry from '../Laundry/Loundry';

const useStyles = makeStyles({
  root: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    ...shorthands.padding('50px', '20px'),
    rowGap: '20px',
  },
});

const theme = getTheme();
interface User {
  email: string,
  phoneNumber:string,
  token:string,

  // other properties...
}
interface GuestId{
  number:number;
}
interface SelectedRoom{
  number:any;
  name:any;
}

 interface Message {
  message: string,
  id:number,
}

type Props = Partial<TabListProps> & {
  items: Inventory[];
  selectedOption?: string;
  showViewRoom: boolean;
  showGuestForm: boolean;
  onClose: () => void;
  OnCloseGuest: () => void;
  OnOpenGuest: () => void;
  onOpen: () => void;
  user:User;
};

const Booking: React.FC<Props> = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [results, setResults] = useState<number[]>([]);
  const [roomDetails, setRoomDetailsId] = useState<SelectedRoom>({ number: 0, name: "" });
  const [rooms, setRooms] = useState<RoomManager[]>([]);
  const [activeTab, setActiveTab] = useState('All');
  const [showAvailableRoom, setshowAvailableRoom] = useState(false);
  const [showOccupiedRoom, setshowOccupiedRoom] = useState(false);
  const [showAllRoom, setshowAllRoom] = useState(true);
  const [showCheckedout, setshowCheckedout] = useState(false);
  const [showReserved, setshowReserved] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [selectedRoom, setSelectedRoom] = useState(false);
  const [activeGuestTab, setActiveGuestTab] = useState('Rooms');
  const [showGuestProfile, setShowGuestProfile] = useState(false);
  const [showPaymentpage, setShowPaymentpage] = useState(false);
  const [showGuestNewEntry, setShowGuestNewEntry] = useState(true);
  const [paymentMode, setPaymentMode] = useState<boolean>(false);
const [closeReUseable, setCloseReUseAble] = useState<boolean>(false);
const [closeCheckedOut, setCloseCheckedOut] = useState<boolean>(false);
const [reservationType, setReservationType] = useState<boolean>(false);
const [bookingGuestId, setBookingGuestId] = useState<any>();
const [customerEventId, setCustomerEventId] = useState<any>();
const [bookingServiceId, setBookingServiceId] = useState<any>();
const [pamentOpenMode, setPaymentOpenMode] = useState<boolean>();
const [bookingRoomId, setBookingRoomId] = useState<any>();
const [OpenReservationType, setOpenReservationType] = useState<boolean>(false);
const [activePage, setActivePage] = useState('Rooms');
const [reserveEvent, setReserveEvent] = useState<boolean>(false);
const [billBarRestItem, setBillBarRestItem] = useState<boolean>(false);
const [reserveRoom, setReserveRoom] = useState<boolean>(false);
const [restaurant, setRestaurant] = useState<boolean>(false);
const [message, setMessage] = useState<Message>({message: '', id:0});
const [bar, setBar] = useState<boolean>(false);
const [LoundryBill, setLoundryBill] = useState<boolean>(false);
const [headerItem, setHeaderItem] = useState("");
  const {
    showViewRoom,
    showGuestForm,
    onOpen,
    onClose,
    OnCloseGuest,
    OnOpenGuest,
    user,
  } = props;

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };
  const Tabstyles = useStyles();
  const handleSearchSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const handleGuestTabClick = (
    event: React.MouseEvent<HTMLElement>,
    tabValue: string
  ) => {
    // Set the active page to the one that was clicked
    setActivePage(tabValue);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    handleAllVisibility(tab);
  };

  function dateDifference(date1: Date, date2: Date): number {
    if (date1 && date2) {
      const diffInMs = Math.abs(date2.getTime() - date1.getTime());
      const diffInHours = diffInMs / (1000 * 60 * 60);
      return diffInHours;
    } else {
      return 0;
    }
  }
  // const selection = new Selection({
  // onSelectionChanged: () => {
  // const selectedItem = selection.getSelection()[0];
  // if (selectedItem) {
  // getRoomDetails(selectedItem.roomNumber);
  // }
  // }
  // });

  const handleDialogReUseAbleClose = () => {
    setCloseReUseAble(false);
  };
const HandleCheckedOut = () => {
  setCloseCheckedOut(false);
}

const HandleCheckedOutOpen = () => {
  setCloseCheckedOut(true);
}


const handleDialogReUseAbleOpen = (event:any) => {
  setCloseReUseAble(true);
  setHeaderItem(event);
}
  const handleRoomClick = (number: any, name:any) => {
    getRoomDetails(number, name);
  };

const getRooms = useCallback(async () => {
    try {
      const response = await axios.get<RoomManager[]>(
        `${WHITE_HOUSE_API}/Rooms/getRooms`, {
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        }
      );
      if (response.data) {
        setRooms(response.data);

      }
    } catch (error) {
      console.error(error);
    }
  },[user.token]);


  const getAvailableRooms = useCallback(async () => {
    try {
      const response = await axios.get<RoomManager[]>(
        `${WHITE_HOUSE_API}/Rooms/AllAvailable`, {
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        }
      );
      if (response.data != null) {
        setRooms(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  },[user.token]);



  const getAllReservedRooms = useCallback(async () => {
    try {
      const response = await axios.get<RoomManager[]>(
        `${WHITE_HOUSE_API}/Rooms/Reserved`, {
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        }
      );
      if (response.data != null) {
        setRooms(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  },[user.token]);


const getAllOccupiedRooms = useCallback(async () => {
    try {
      const response = await axios.get<RoomManager[]>(
        `${WHITE_HOUSE_API}/Rooms/Occupied`, {
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        }
      );
      if (response.data != null) {
        setRooms(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  },[user.token]);


  const getAllCheckedoutRooms = useCallback(async () => {
    try {
      const response = await axios.get<RoomManager[]>(
        `${WHITE_HOUSE_API}/Rooms/Checkedout`, {
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        }
      );
      if (response.data != null) {
        setRooms(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);


  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const OpenGuestForm = () => {
    OnOpenGuest();
  };

  const handleAllVisibility = (activeTab: string) => {
    setActiveTab(activeTab);
  };

  useEffect(() => {
    switch (activeTab) {
      case 'All':
        getRooms();
        break;
      case 'Available':
        getAvailableRooms();
        break;
      case 'Checked In':
        getAllOccupiedRooms();
        break;
      case 'Booked':
        getAllCheckedoutRooms();
        break;
      case 'Reserved':
        getAllReservedRooms();
        break;
    }
  }, [activeTab, getAllCheckedoutRooms, getAllOccupiedRooms, getAllReservedRooms, getAvailableRooms, getRooms]);

  const getRoomDetails = useCallback(
    async (number: any, name:any) => {
      setRoomDetailsId({ number: number, name: name});

      onOpen();
    },
    [onOpen]
  );
  

  const columns: IColumn[] = [
    {
      key: 'column1',
      name: 'Room Name',
      fieldName: 'roomName',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column2',
      name: 'Expiring in',
      fieldName: 'expiringIn',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column3',
      name: 'Room Number',
      fieldName: 'roomNumber',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column4',
      name: 'Availability',
      fieldName: 'availability',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column5',
      name: 'Daily Price',
      fieldName: 'rate',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  const items = rooms.map((room, index) => ({
    ...room,
    expiringIn: `${results[index] || 0} hrs`,
    availability: room.availability ? 'Available' : 'Not  Available',
  }));
  function formatNumber(num: number): string {
    return num.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

const handleItemOpener =(name:any)=> {
  if(name === "EventReservation"){
    setReserveEvent(true);
  }else {
    setReserveRoom(true);
  }

}


const handleBarsOpener =(name:any)=> {
  if(name === "Bar"){
    setBar(true);
  }else {
    setRestaurant(true);
  }

}

const handleCloseEvent =()=> {
  setReserveEvent(false);
}

const HandleReserveRoomClose  =()=> {
  setReserveRoom(false);
}


const handleBarBillClosed = () => {
  setBar(false);
}

const handleRestaurantBillClosed = () => {
  setRestaurant(false);
}


  function formatDateString(dateString: Date): string {
    const date = new Date(dateString);
    const now = new Date();
    const oneHourInMilliseconds = 1000 * 60 * 60;
    if (Math.abs(date.getTime() - now.getTime()) <= oneHourInMilliseconds) {
      return 'Expiring soon';
    } else if (date < now) {
      return 'Expired';
    } else {
      const options: Intl.DateTimeFormatOptions = {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      };
      const formattedDate = date.toLocaleDateString('en-US', options);
      return formattedDate.replace(',', ', ');
    }
  }
  const handleCloseGuest = () => {
    OnCloseGuest();
  };


  const HandleOpenReservationType = () => {
    setOpenReservationType(true);
  }

  const HandleCloseReservationType = () => {
    setOpenReservationType(false);
  }

  const closePayments = () => {
    setPaymentMode(false);
  }

  const closePaymentModes = () => {
    setPaymentOpenMode(false);
  }
 
  const OnPaymentOpen = () => {
    setPaymentOpenMode(true);
  }
  const openPayments = () => {
    setPaymentMode(true);
  }

  const HandleLoundryBills = () => {
    setLoundryBill(true);
  }

  const HandleCloseLoundryBills = () => {
    setLoundryBill(false);
  }

  return (
    <>
      <div className="notification-banner border-radious-8">
        <div className="app-notification-banner banner-show border-radious-8"></div>
      </div>
      <div className="flex-fill border-radious-8">
        <div className="middle-call-list-header messages-header-v2 border-radious-8">
          <div className="middle-call-list-container">
            <div className="middle-call-list-header-title">
              <i className="ts-sym book fi fi-tr-book-open-reader"></i>
            </div>

            <TabList defaultSelectedValue="Rooms">
              <Tab
                value="Rooms"
                onClick={(event: any) => handleGuestTabClick(event, 'Rooms')}
                icon={<BreakoutRoom24Regular />}
              >
                Rooms
              </Tab>
              <Tab
                value="Guest"
                onClick={(event: any) => handleGuestTabClick(event, 'Guest')}
                icon={<PeopleTeam24Regular />}
              >
                Guest
              </Tab>
              <Tab
                value="Preferences"
                onClick={(event: any) =>
                  handleGuestTabClick(event, 'Preferences')
                }
                icon={<Clock20Regular />}
              >
                Preferences
              </Tab>
              <Tab
                value="Payments"
                onClick={(event: any) => handleGuestTabClick(event, 'Payments')}
                icon={<WalletCreditCard24Regular />}
              >
                Payments
              </Tab>
              <Tab
                value="Reporting"
                onClick={(event: any) =>
                  handleGuestTabClick(event, 'Reporting')
                }
                icon={<CalendarReply24Regular />}
              >
                Reporting
              </Tab>
            </TabList>
          </div>
        </div>
        <div className="fluent-ui-component alcnan">
          <div>
            <div className="ui-flex cp-cqcrcs">
              <div className="ui-flex cp crcsct-cu  cvcwcx cy cz db">
                <div className="ui-box dc dddedfdg">
                  <div className="ui-flex cp cq dhczdi dj customScrollBar">
                    <div className="ui-flex cp cqdkdl ct">
                      <div className="scrollableWrapper-124">
                        <div className="y-block block-127">
                          <div className="well-129">
                            <nav className="sidebarNav-130">
                              <h2 className="semanticHeading-131">
                                cilsco navigation
                              </h2>
                              <div className="mainSection-125">
                                <div className="sidebarNavCollapsibleLink-161 wc-border-bottom">
                                  <a
                                    href="#"
                                    className="qaNavUserProfile link-137"
                                  >
                                    <div className="flex-138">
                                      <div className="flex-139">
                                        <div
                                          className="y-block block-140"
                                          title="New Entry"
                                        >
                                          <div className="inner-141">
                                          <Button>New Entry</Button>
                                           
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                  <div className="avatarWrapper-134">
                                    <div className="cui-sidebarNavLink-avatar avatar-135">
                                      <div className="sidebarNavCollapsibleLinkAvatar-143">
                                        <div
                                          className="avatar-144"
                                          aria-hidden="true"
                                        >
                                          <div
                                            role="presentation"
                                            className="ms-Persona-coin ms-Persona--size16 coin-147"
                                          >
                                            <div
                                              role="presentation"
                                              className="ms-Persona-imageArea imageArea-149"
                                            >
                                              <div
                                                className="ms-Persona-initials initials-152"
                                                aria-hidden="true"
                                              >
                                                <span></span>
                                              </div>
                                              <div className="ms-Image ms-Persona-image image-153">
                                                <Button
                                                  className="ms-Image-image ms-Image-image--cover ms-Image-image--portrait is-notLoaded is-fadeIn is-error image-189"
                                                  icon={
                                                    <PeopleCommunity24Filled />
                                                  }
                                                ></Button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="sidebarNavCollapsibleLink-132 wc-border-bottom">
                                  <a
                                    aria-label="Communities"
                                    className="qaNavDiscoverCommunities link-137"
                                    href="#"
                                  >
                                    <div className="flex-138">
                                      <div className="flex-139">
                                        <div
                                          className="y-block block-140"
                                          title="Communities"
                                        >
                                          <div className="inner-141">
                                            <Button onClick={() => handleDialogReUseAbleOpen("Check In Guest")}> Check In Guest</Button>
                                           
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                  <div className="avatarWrapper-134">
                                    <div className="cui-sidebarNavLink-avatar avatar-135">
                                      <div className="flex-162">
                                        <Button 
                                          icon={
                                            <Checkbox124Filled className="color-item" />
                                          }
                                        ></Button>
                                      </div>
                                    </div>
                                  </div>
                                  </div>
                                  <div className="sidebarNavCollapsibleLink-132 wc-border-bottom">
                                    <a
                                      aria-label="Communities"
                                      className="qaNavDiscoverCommunities link-137"
                                      href="#"
                                    >
                                      <div className="flex-138">
                                        <div className="flex-139">
                                          <div
                                            className="y-block block-140"
                                            title="Communities"
                                          >
                                            <div className="inner-141">
                                               <Button  onClick={HandleCheckedOutOpen}> Guest Checking Out </Button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                    <div className="avatarWrapper-134">
                                      <div className="cui-sidebarNavLink-avatar avatar-135">
                                        <div className="flex-162">
                                          <Button
                                            icon={
                                              <CheckboxIndeterminate24Filled className="color-item" />
                                            }
                                          ></Button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="sidebarNavCollapsibleLink-132 wc-border-bottom">
                                    <div className="isFocused-157">
                                      <div className="flex-158">
                                        <div className="flexItem-159">
                                          <div className="">
                                            <div className="sidebarNavCollapsibleLink-132">
                                              <a
                                                href=""
                                                className="qaNavHome link-137"
                                              >
                                                <div className="flex-138">
                                                  <div className="y-block block-140">
                                                    <div className="inner-141">
                                                      Reservations
                                                    </div>
                                                  </div>
                                                </div>
                                              </a>
                                              <div className="avatarWrapper-134">
                                                <div className="cui-sidebarNavLink-avatar avatar-135">
                                                  <div className="flex-162">
                                                    <Button
                                                      icon={
                                                        <AddSquare24Filled className="color-item" />
                                                      }
                                                    ></Button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="ms-Dropdown-container root-165">
                                          <div className="ms-Dropdown dropdown-166">
                                            <span className="ms-Dropdown-title title-185">
                                              <Interaction  onInteractionClick={handleItemOpener} />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                               
                                <div className="sidebarNavCollapsibleLink-132 wc-border-bottom">
                                  <div className="avatarWrapper-134">
                                    <div className="cui-sidebarNavLink-avatar avatar-135">
                                      <div className="flex-162">
                                        <Button
                                          icon={
                                            <ChannelAdd24Regular className="color-item" />
                                          }
                                        ></Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                               
                                <div className="">
                                  <div className="isFocused-157">
                                    <div className="flex-158">
                                      <div className="flexItem-159">
                                        <div className="">
                                          <div className="sidebarNavCollapsibleLink-132">
                                            <span
                                             
                                              className="qaNavHome link-137"
                                            >
                                              <div className="flex-138">
                                                <div className="y-block block-140">
                                                  <div className="inner-141">
                                                  Bar & Restuarant Billing
                                                  </div>
                                                </div>
                                              </div>
                                            </span>
                                            <div className="avatarWrapper-134">
                                              <div className="cui-sidebarNavLink-avatar avatar-135">
                                                <div className="flex-162">
                                                  <Button
                                                    icon={
                                                      <Food24Filled  className="color-item" />
                                                    }
                                                  ></Button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="ms-Dropdown-container root-165">
                                        <div className="ms-Dropdown dropdown-166">
                                          <span className="ms-Dropdown-title title-185">
                                            <BarInteraction onInteractionClick={handleBarsOpener} />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="sidebarNavCollapsibleLink-132 wc-border-bottom">
                                  <a
                                    aria-label="Communities"
                                    className="qaNavDiscoverCommunities link-137"
                                   onClick={HandleLoundryBills}
                                  >
                                    <div className="flex-138">
                                      <div className="flex-139">
                                        <div
                                          className="y-block block-140"
                                          title="Communities"
                                        >
                                          <div className="inner-141">
                                            Laundry Billing
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                  <div className="avatarWrapper-134">
                                    <div className="cui-sidebarNavLink-avatar avatar-135">
                                      <div className="flex-162">
                                        <Button
                                          icon={
                                            <ArchiveArrowBack24Regular className="color-item" />
                                          }
                                        ></Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="sidebarNavCollapsibleLink-132 wc-border-bottom">
                                  <span
                                    aria-label="Communities"
                                    className="qaNavDiscoverCommunities link-137"
                                    onClick={(event: any) => handleGuestTabClick(event, 'Guest')}
                                  >
                                    <div className="flex-138">
                                      <div className="flex-139">
                                        <div
                                          className="y-block block-140"
                                          title="Communities"
                                        >
                                          <div className="inner-141">
                                            Guest Account
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </span>
                                  <div className="avatarWrapper-134">
                                    <div className="cui-sidebarNavLink-avatar avatar-135">
                                      <div className="flex-162">
                                        <Button
                                          icon={
                                            <HomePerson24Filled className="color-item" />
                                          }
                                        ></Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                 <div className="sidebarNavCollapsibleLink-132 wc-border-bottom">
                                  <span
                                    aria-label="Communities"
                                    className="qaNavDiscoverCommunities link-137"
                                   
                                  >
                                    <div className="flex-138">
                                      <div className="flex-139">
                                        <div
                                          className="y-block block-140"
                                          title="Communities"
                                        >
                                          <div className="inner-141">
                                            Voucher
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </span>
                                  <div className="avatarWrapper-134">
                                    <div className="cui-sidebarNavLink-avatar avatar-135">
                                      <div className="flex-162">
                                        <Button
                                          icon={
                                            <Vault24Filled className="color-item" />
                                          }
                                        ></Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="">
                                  <div className="isFocused-157">
                                    <div className="flex-158">
                                      <div className="flexItem-159">
                                        <div className="">
                                          <div className="sidebarNavCollapsibleLink-132">
                                            <a
                                              href=""
                                              className="qaNavHome link-137"
                                            >
                                              <div className="flex-138">
                                                <div className="y-block block-140">
                                                  <div className="inner-141">
                                                    Vaucher
                                                  </div>
                                                </div>
                                              </div>
                                            </a>
                                            <div className="avatarWrapper-134">
                                              <div className="cui-sidebarNavLink-avatar avatar-135">
                                                <div className="flex-162">
                                                  <Button
                                                    icon={
                                                      <Vault24Filled className="color-item" />
                                                    }
                                                  ></Button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="ms-Dropdown-container root-165">
                                        <div className="ms-Dropdown dropdown-166">
                                          <span className="ms-Dropdown-title title-185">
                                            <Interaction onInteractionClick={handleEventOpener} />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                              </div>
                              <hr className="horizontalRule-184" />
                              <div className="communitiesSection-126">
                                <div>
                                  <span className="semanticHeading-131-s">
                                    <div className="fixedGridRow-229">
                                      <div className="y-fixedGridColumn fixedGridColumn-281">
                                        <div className="y-block block-282">
                                          <div className="inner-141">
                                            <span className="text-283">
                                              Favorites
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </span>
                                  <div className="y-block block-102">
                                    <div className="favoriteMessage-286">
                                      <div className="flex-287">
                                        <div className="flexItem-288">
                                          <div className="ms-Image image-318">
                                            <img
                                              alt=""
                                              className="ms-Image-image is-loaded ms-Image-image--portrait is-fadeIn image-319"
                                              src={favoriteImg}
                                            />
                                          </div>
                                        </div>
                                        <div className="flexItem-289">
                                          Keep guest favorites at your
                                          fingertips. Favorites will appear
                                          here.{' '}
                                          <a
                                            className="link-290"
                                            href=""
                                            rel="nofollow noopener noreferrer"
                                            target="_blank"
                                          >
                                            Learn more
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <ul className="favoritesList-291"></ul>
                                  </div>{' '}
                                </div>
                              </div>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* file here */}
                  </div>
                </div>

                {activePage === 'Rooms' && (
                  <>
                    <div className="q9iRC css-263">
                      <div className="HOVUa">
                        <div className="nbmyu FkPdL czwRD LCprM">
                          <div className="XW2m0 LyMTI css-269">
                            <div className="VS1hH bkYAr rv6Vd">
                              <div className="rEzfP bK01B VS1hH rf8FT rqwVd">
                                <div className="_EhYJ brFj8 rf8FT">
                                  <div className="ZYym6 VS1hH rf8FT">
                                    <div className="JV_Om rk2CU w6fpB EHnMK rf8FT">
                                      <div className="ms-Check vQae6 ccbWZ root-352">
                                        <span
                                          dir="auto"
                                          className="fui-StyledText ___v7v6t70 f1leeax8"
                                        >
                                          History
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="U1j7P Z9nT9">
                                    <ul className="w-ci-ulc VDlCs K_Q7X">
                                      {[
                                        'All',
                                        'Checked In',
                                        'Reserved',
                                        'Booked',
                                        'Available',
                                      ].map((tab) => (
                                        <li
                                          key={tab}
                                          className="w-ci-ap-li"
                                          role="presentation"
                                          data-tabs="true"
                                          onClick={() => handleTabClick(tab)}
                                        >
                                          <div className="w-ci-a">
                                            <span className="w-ci-span-a">
                                              <span
                                                className={`w-cs-cu-cw
                                                                                                                                            wjv
                                                                                                                                            ${
                                                                                                                                              activeTab ===
                                                                                                                                              tab
                                                                                                                                                ? 'isactive'
                                                                                                                                                : ''
                                                                                                                                            }`}
                                              >
                                                <div dir="auto" className="">
                                                  {tab}
                                                </div>
                                              </span>
                                            </span>
                                          </div>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <DetailsList
                                                    items={items}
                                                    columns={columns}
                                                    setKey="set"
                                                    onItemInvoked={item =>
                                                        getRoomDetails(item.roomNumber)}
                                                    checkButtonAriaLabel="select row"
                                                /> */}
                            {/* onActiveItemChanged={(item) => getRoomDetails(item.roomNumber)} */}

                            <div className="ms-Viewport">
                              <div
                                className="ms-DetailsList is-horizontalConstrained root-c114"
                                data-automationid="DetailsList"
                                data-is-scrollable="false"
                              >
                                <div role="grid">
                                  <div
                                    role="presentation"
                                    className="ms-DetailsList-headerWrapper"
                                  >
                                    <div
                                      role="row"
                                      className="ms-FocusZone css-132 ms-DetailsHeader root-c116"
                                      data-automationid="DetailsHeader"
                                      data-focuszone-id="FocusZone19"
                                    >
                                      <div
                                        className="ms-DetailsHeader-cell ms-DetailsHeader-cellIsCheck cellIsCheck-c119"
                                        aria-labelledby="header17-checkTooltip"
                                        role="columnheader"
                                      >
                                        <span className="ms-DetailsHeader-checkTooltip">
                                          <div
                                            id="header17-check"
                                            data-is-focusable="true"
                                            role="checkbox"
                                            className="ms-DetailsRow-check ms-DetailsHeader-check check-c117 ms-DetailsRow-check--isHeader ms-Check-checkHost check-c133"
                                            aria-checked="false"
                                            data-selection-toggle="true"
                                            data-automationid="DetailsRowCheck"
                                          >
                                            <div className="ms-Check wc-opacity-csr root-c134">
                                              <i
                                                data-icon-name="CircleRing"
                                                aria-hidden="true"
                                                className="ms-Icon mms-circle root-c89 css-c106 ms-Check-circle circle-c135"
                                              >
                                                
                                              </i>
                                              <i
                                                data-icon-name="StatusCircleCheckmark"
                                                aria-hidden="true"
                                                className="ms-Icon mms-circle root-c89 css-c106 ms-Check-check check-c136"
                                              >
                                                
                                              </i>
                                            </div>
                                          </div>
                                        </span>
                                      </div>
                                      <div
                                        role="columnheader"
                                        aria-labelledby="header17-column1-name"
                                        aria-sort="none"
                                        data-is-focusable="true"
                                        className="ms-DetailsHeader-cell mc-66 is-actionable wc-width root-c137"
                                        data-is-draggable="false"
                                        draggable="false"
                                        data-automationid="ColumnsHeaderColumn"
                                        data-item-key="column1"
                                      >
                                        <span className="cellTooltip-c145">
                                          <span
                                            id="header17-column1"
                                            className="ms-DetailsHeader-cellTitle cellTitle-c143"
                                          >
                                            <span
                                              id="header17-column1-name"
                                              className="ms-DetailsHeader-cellName cellName-c144"
                                            >
                                              Room Name
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                      <div
                                        aria-hidden="true"
                                        role="button"
                                        data-is-focusable="false"
                                        data-sizer-index="0"
                                        className="ms-DetailsHeader-cellSizer cellSizer-c123 cellSizerStart-c124"
                                      ></div>
                                      <div
                                        role="columnheader"
                                        aria-labelledby="header17-column2-name"
                                        aria-sort="none"
                                        data-is-focusable="true"
                                        className="ms-DetailsHeader-cell mc-66 is-actionable root-c137"
                                        data-is-draggable="false"
                                        draggable="false"
                                        data-automationid="ColumnsHeaderColumn"
                                        data-item-key="column2"
                                      >
                                        <span className="cellTooltip-c145">
                                          <span
                                            id="header17-column2"
                                            className="ms-DetailsHeader-cellTitle cellTitle-c143"
                                          >
                                            <span
                                              id="header17-column2-name"
                                              className="ms-DetailsHeader-cellName cellName-c144"
                                            >
                                              Expiring in
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                      <div
                                        aria-hidden="true"
                                        className="ms-DetailsHeader-cellSizer cellSizer-c123 cellSizerStart-c124"
                                      ></div>
                                      <div
                                        role="columnheader"
                                        aria-labelledby="header17-column3-name"
                                        aria-sort="none"
                                        data-is-focusable="true"
                                        className="ms-DetailsHeader-cell mc-66 is-actionable root-c137"
                                        data-is-draggable="false"
                                        draggable="false"
                                        data-automationid="ColumnsHeaderColumn"
                                        data-item-key="column3"
                                      >
                                        <span className="cellTooltip-c145">
                                          <span
                                            id="header17-column3"
                                            className="ms-DetailsHeader-cellTitle cellTitle-c143"
                                          >
                                            <span
                                              id="header17-column3-name"
                                              className="ms-DetailsHeader-cellName cellName-c144"
                                            >
                                              Occupant
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                      <div
                                        aria-hidden="true"
                                        role="button"
                                        data-is-focusable="false"
                                        data-sizer-index="2"
                                        className="ms-DetailsHeader-cellSizer cellSizer-c123 cellSizerStart-c124"
                                      ></div>
                                      <div
                                        role="columnheader"
                                        aria-labelledby="header17-column4-name"
                                        aria-sort="none"
                                        data-is-focusable="true"
                                        className="ms-DetailsHeader-cell mc-66 is-actionable root-c137"
                                        data-is-draggable="false"
                                        draggable="false"
                                        data-automationid="ColumnsHeaderColumn"
                                        data-item-key="column4"
                                      >
                                        <span className="cellTooltip-c145">
                                          <span
                                            id="header17-column4"
                                            className="ms-DetailsHeader-cellTitle cellTitle-c143"
                                          >
                                            <span
                                              id="header17-column4-name"
                                              className="ms-DetailsHeader-cellName cellName-c144"
                                            >
                                              Availability
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                      <div
                                        aria-hidden="true"
                                        role="button"
                                        data-is-focusable="false"
                                        data-sizer-index="3"
                                        className="ms-DetailsHeader-cellSizer cellSizer-c123 cellSizerStart-c124"
                                      ></div>
                                      <div
                                        role="columnheader"
                                        aria-labelledby="header17-column5-name"
                                        aria-sort="none"
                                        data-is-focusable="true"
                                        className="ms-DetailsHeader-cell mc-66 is-actionable root-c137"
                                        data-is-draggable="false"
                                        draggable="false"
                                        data-automationid="ColumnsHeaderColumn"
                                        data-item-key="column5"
                                      >
                                        <span className="cellTooltip-c145">
                                          <span
                                            id="header17-column5"
                                            className="ms-DetailsHeader-cellTitle cellTitle-c143"
                                          >
                                            <span
                                              id="header17-column5-name"
                                              className="ms-DetailsHeader-cellName cellName-c144"
                                            >
                                              Daily Price
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                      <div
                                        aria-hidden="true"
                                        role="button"
                                        data-is-focusable="false"
                                        data-sizer-index="4"
                                        className="ms-DetailsHeader-cellSizer cellSizer-c123 cellSizerEnd-c125"
                                      ></div>
                                    </div>
                                  </div>
                                  <div className="table-rooms customScrollBar">
                                  {rooms.map((room) => {
                                    const actualPrice = formatNumber(
                                      Number(room.rate)
                                    );
                                    let checkuser = room.firstName.trim() + " " + room.lastName.trim();
                                   
                                    let userobject = checkuser === " " ? "No Occupant": checkuser;
                                    const checkoutDates = room.chekedOutTime
                                      ? formatDateString(
                                          new Date(room.chekedOutTime)
                                        )
                                      : 'Expired';
                                    return (
                                      <div
                                        key={room.roomId}
                                        role="presentation"
                                        className="ms-DetailsList-contentWrapper"
                                        onClick={() =>
                                          handleRoomClick(room.roomNumber, room.roomName)
                                        }
                                      >
                                        <div
                                          className="ms-SelectionZone"
                                          role="presentation"
                                        >
                                          <div
                                            className="ms-FocusZone css-132 focusZone-115"
                                            data-focuszone-id="FocusZone21"
                                          >
                                            <div
                                              className="ms-List"
                                              role="presentation"
                                            >
                                              <div
                                                role="presentation"
                                                className="ms-List-surface"
                                              >
                                                <div
                                                  className="ms-List-page"
                                                  role="presentation"
                                                >
                                                  <div
                                                    role="presentation"
                                                    className="ms-List-cell"
                                                    data-list-index="0"
                                                    data-automationid="ListCell"
                                                  >
                                                    <div className="ms-FocusZone Field-active-wsce css-c132 ms-DetailsRow is-contentUnselectable root-c151">
                                                      <div
                                                        role="gridcell"
                                                        data-selection-toggle="true"
                                                        className="ms-DetailsRow-cell ms-DetailsRow-cellCheck checkCell-c156"
                                                      >
                                                        <div
                                                          id="row6637-0-checkbox"
                                                          aria-label="select row"
                                                          aria-labelledby="row6637-0-checkbox"
                                                          role="checkbox"
                                                          className="ms-DetailsRow-check ms-Check-checkHost check-c133"
                                                          aria-checked="false"
                                                          data-selection-toggle="true"
                                                          data-automationid="DetailsRowCheck"
                                                        >
                                                          <div className="ms-Check root-c134">
                                                            <span className="circle-c156R circle-c135 css-c106">
                                                              R{room.roomNumber}
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="ms-DetailsRow-fields fields-c157"
                                                        data-automationid="DetailsRowFields"
                                                        role="presentation"
                                                      >
                                                        <div
                                                          role="gridcell"
                                                          className="ms-DetailsRow-cell mc-55 cell-c194 cellUnpadded-c152"
                                                          data-automationid="DetailsRowCell"
                                                          data-automation-key="column1"
                                                        >
                                                          {room.roomName}
                                                        </div>
                                                        <div
                                                          role="gridcell"
                                                          className="ms-DetailsRow-cell cell-c194 mc-66 cellUnpadded-c152 date-scanner sp-css-backgroundColor-blueBackground07 sp-css-borderTop-blueBorder"
                                                          data-automationid="DetailsRowCell"
                                                          data-automation-key="column2"
                                                        >
                                                          {checkoutDates}
                                                          <div className="cti"></div>
                                                        </div>
                                                        <div
                                                          role="gridcell"
                                                          className="ms-DetailsRow-cell cell-c194 mc-66 cellUnpadded-c152"
                                                          data-automationid="DetailsRowCell"
                                                          data-automation-key="column3"
                                                        >
                                                          {userobject}
                                                        </div>
                                                        <div
                                                          role="gridcell"
                                                          className="ms-DetailsRow-cell cell-c194 mc-66 cellUnpadded-c152"
                                                          data-automationid="DetailsRowCell"
                                                          data-automation-key="column4"
                                                        >
                                                          <div
                                                            className={`sp-css-color-neutralDark current-col ${
                                                              room.availability ===
                                                              true
                                                                ? 'sp-css-backgroundColor-blueBackground37'
                                                                : 'sp-css-backgroundColor-warningBackground50'
                                                            }`}
                                                          >
                                                            <span className="signature"></span>
                                                            <span className="signature-content-price">
                                                              {' '}
                                                              {room.status?? "Available"}
                                                            </span>
                                                          </div>
                                                        </div>
                                                        <div
                                                          role="gridcell"
                                                          className="ms-DetailsRow-cell cell-c194 mc-66 cellUnpadded-c152"
                                                          data-automationid="DetailsRowCell"
                                                          data-automation-key="column5"
                                                        >
                                                          <div
                                                            className={`sp-css-color-neutralDark current-col ${
                                                              room.availability ===
                                                              true
                                                                ? 'sp-css-backgroundColor-blueBackground37'
                                                                : 'sp-css-backgroundColor-warningBackground50'
                                                            }`}
                                                          >
                                                            <span className="signature"></span>
                                                            <span className="signature-content-price">
                                                              &#8358;{' '}
                                                              {actualPrice}
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                  </div>
                                 
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ui-box  w-ci-gygz-kskt">
                      <div className="flex-249">
                        <div className="y-block block-140">
                          <div className="flex-249">
                            <div className="">
                              <div className="accentCard-334">
                                <div className="fixedGridRow-314 header-csw">
                                  <div className="y-fixedGridColumn fixedGridColumn-281 wc-colun-456">
                                    <span className="semanticHeading-131-wrc">
                                      <span className="titleText-337">
                                        Add Contracts
                                      </span>
                                    </span>
                                    <div className="add-btn-wac">
                                      <Menu>
                                        <MenuTrigger disableButtonEnhancement>
                                          <MenuButton
                                            icon={<CollectionsAdd24Regular />}
                                          >
                                            Manage
                                          </MenuButton>
                                        </MenuTrigger>

                                        <MenuPopover>
                                          <MenuList>                                      
                                            <MenuItem onClick={OpenGuestForm}>
                                              Add New Guest
                                            </MenuItem>
                                              {/* <MenuItem onClick={handleClick}>
                                              Add New Room
                                            </MenuItem> */}
                                            {/* <MenuItem onClick={HandleOpenReservationType}>
                                              Add Reservation Type
                                            </MenuItem> */}
                                          </MenuList>
                                        </MenuPopover>
                                      </Menu>
                                    </div>
                                  </div>
                                </div>
                                <RightMenuItem user={user} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {activePage === 'Guest' && (
                  <div className="q9iRC-css-263">
                    <GuestProfile user={user} />
                  </div>
                )}

                {activePage === 'Payments' && (
                  <div className="flex page">
                    <Payment user={user} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>{isVisible && <RoomDialog onClose={handleClick} user={user} />}</div>

      {showGuestForm && (
        <div className="fluent-default-layer-host">
          <div className="ms-Layer ms-Layer--fixed root-44">
            <Guests onClose={handleCloseGuest} user={user} />
          </div>
        </div>
      )}

      {showViewRoom && (
        <>
          <div className="fluent-default-layer-host">
            <div className="ms-Layer ms-Layer--fixed root-44">
              <SingleRoomViewDialog
                selected={roomDetails}
                onClose={onClose} user={user}
                onPaymentClick={(guestid, roomid) => {
                  setBookingGuestId(guestid);
                  setBookingRoomId(roomid);
                  openPayments();
                }} />
            </div>
          </div>
        </>
      )}

      {reserveEvent && (
        <>
        <div className="fluent-default-layer-host">
          <div className="ms-Layer ms-Layer--fixed root-44">
                                  
        <EventReservation 
                onPaymentOpen={(customerId, ServiceId) => {
                  setCustomerEventId(customerId);
                  setBookingServiceId(ServiceId);
                  OnPaymentOpen();
                } }
                user={user}
                onClose={handleCloseEvent} />
   </div>
   </div>
        </>
      )}


{reserveRoom && (
        <>
        <div className="fluent-default-layer-host">
          <div className="ms-Layer ms-Layer--fixed root-44">
                                  
        <RoomReservation 
                onPaymentOpen={(customerId, ServiceId) => {
                  setCustomerEventId(customerId);
                  setBookingServiceId(ServiceId);
                  OnPaymentOpen();
                } }
                user={user}
                onClose={HandleReserveRoomClose} />
   </div>
   </div>
        </>
      )}
      { paymentMode && (
        <>
      <div className="Rsp">
         <RSPayment onBarClose={closePayments} user={user} guestId={bookingGuestId} roomId={bookingRoomId} />
      </div>
      </>
        )
      }
      {
        pamentOpenMode && (
          <>
          <div className="Rsp">
             <EVTPayment onBarClose={closePaymentModes} user={user} customerid={customerEventId} servicesid={bookingServiceId} />
          </div>
          </>
        )
      }
      {closeReUseable && (
         <>
         <div className="fluent-default-layer-host">
           <div className="ms-Layer ms-Layer--fixed root-44">
           <ReuseAble   onClose={handleDialogReUseAbleClose} InMessage={headerItem} actionmessage={''} actionMessageos={''} user={user} selected={roomDetails}  />
           </div>
         </div>
       </>
      )}
      {closeCheckedOut && (
         <>
         <div className="fluent-default-layer-host">
           <div className="ms-Layer ms-Layer--fixed root-44">
           <CheckedOut  onClose={HandleCheckedOut} InMessage={headerItem} actionmessage={''} actionMessageos={''} user={user}  />
           </div>
         </div>
       </>
        

      )}
   
   {bar &&
        (
          <BarBilling  
          onPaymentOpen={(customerId, ServiceId) => {
            setCustomerEventId(customerId);
            setBookingServiceId(ServiceId);
            OnPaymentOpen();
          } }
          user={user}
          onBarClose={handleBarBillClosed} 
          message={message}/>
        )
      }

{restaurant &&
        (
          <RestaurantBilling  
          onPaymentOpen={(customerId, ServiceId) => {
            setCustomerEventId(customerId);
            setBookingServiceId(ServiceId);
            OnPaymentOpen();
          } }
          user={user}
          onBarClose={handleRestaurantBillClosed} 
          message={message}/>
        )
      }

{LoundryBill &&
        (
          <Loundry  
          onPaymentOpen={(customerId, ServiceId) => {
            setCustomerEventId(customerId);
            setBookingServiceId(ServiceId);
            OnPaymentOpen();
          } }
          user={user}
          onBarClose={HandleCloseLoundryBills} 
          message={message}/>
        )
      }
    </>
  );
};



export default Booking;
