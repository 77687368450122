import React from "react";
import "./DispatchToast.scss";
import { PresenceBadge } from "@fluentui/react-components";

type DispatchToastProps = {
    message: string;
};
const DispatchToast: React.FC<DispatchToastProps> = ({message}) => {
    console.log("the message is not empty", message);
    return (
        <>
            <div className="r3hfdjz">
                <div className="r1qaza64 fayl5bc f17oyct0">
                    <div className="fui-Toast rzyoxeq">
                        <div className="fui-ToastTitle__media rnm72z1 ___1n2lvop f1m7fhi8">
                            <PresenceBadge />
                        </div>
                        <div id="toast-title85" className="fui-ToastTitle r1mlor6q">{message}</div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default DispatchToast;