import React from "react";
import "./Dialog.scss";
import RoomForm from "../Rooms/Room";

interface User {
    email: string,
    phoneNumber:string,
    token:string,
  
    // other properties...
  }

interface DialogProps {
    onClose: () => void;
    user:User;
}
const RoomDialog: React.FC<DialogProps> = ({ onClose,user }) => {
    const handleOverlayClick = () => {
        onClose();
    }
    return (
        <>
            <div className="w-ci-dialog ts-add-members-speed-dial-dialog rms-modal-dialog ts-modal-dialog add-members-speed-dial">
                <div className="w-ci-dialog-overlay scrollbar-vertical-space" onClick={handleOverlayClick}></div>
                <div className="w-ci-dialog-content">
                    <div className="w-ci-dialog-content-inner">
                        <RoomForm onClose={handleOverlayClick} user={user} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default RoomDialog;
