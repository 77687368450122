import React, { useCallback, useEffect, useState } from 'react';
import './ViewTask.scss';
import { Button, Input, Tooltip } from '@fluentui/react-components';
import { AddFilled, AddRegular, DeleteRegular, DismissRegular, PeopleAddRegular, WrenchScrewdriverFilled } from '@fluentui/react-icons';
import taskDelivarables from "../../Themes/Images/TaskDeliverables.png";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { Field, makeStyles,   Combobox,
  Option,
  shorthands,
  useId,
  Persona, } from "@fluentui/react-components";
import {
  PresenceBadgeStatus,
  Avatar,
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  TableCellLayout,
} from "@fluentui/react-components";
import { WHITE_HOUSE_API } from '../../../Constants/Constant';
import { Assigning, initAssigning } from '../../../Data/General/Assignments';
import axios from 'axios';
import { GetEmployee } from '../../../Data/General/Employee';
import { ITasksManager } from '../../../Data/General/TaskManager';
import { IDepartment } from '../../../Data/General/Department';
import { json } from 'd3';
import Loaders from '../../Common/Loader/Loader';
import DispatchToast from '../../Common/Toast/DispatchToast';
const useStyles = makeStyles({
  control: {
    maxWidth: "300px",
  },
});


interface User {
  email: string,
  phoneNumber:string,
  token:string,
}
interface Message {
  message: string;
  id: number;
}
interface PropEvent  {
  onClose: () => void;
  message:Message;
  user:User;
}

const ViewTask: React.FC<PropEvent> = ({onClose, message, user}) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [buttonHoveredIndex, setButtonHoveredIndex] = useState<number | null>(null);
  const [asignTask, setAsignTask] = useState<Assigning>(initAssigning);
  const [allTaskAsigned, setAllTaskAsigned] = useState<Assigning[]>([]);
  const [getTaskName, setGetTaskName] = useState<ITasksManager[]>([]);
  const [getEmployee, setGetEmployee] = useState<GetEmployee[]>([]);
  const [getDepartment, setGetDepartment] = useState<IDepartment[]>([]);
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [isDisabled, setisDisabled] = useState<boolean>(true);
  const [adjuster, setAdjuster] = useState<GetEmployee>();
  const [dispatchToaster, setDispatchToaster] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState('')
  const styles = useStyles();
  const [tasks, setTasks] = useState([{
    taskName: '',
    assignedTo: '',
    startDate: '',
    dueDate: '',
    department: ''
  }]);

  const addTask = () => {
    setTasks([...tasks, {
      taskName: '',
      assignedTo: '',
      startDate: '',
      dueDate: '',
      department: ''
    }]);
    if(asignTask.employeeId >0 && asignTask.taskId>0 &&asignTask.departmentId>0 && asignTask.endDate && asignTask.endDate){
      allTaskAsigned.push(asignTask);
      setAllTaskAsigned(prevTasks => [...prevTasks, asignTask]);
    }

  };

  const deleteTask = (index:any) => {
    if (tasks.length > 1) {
      const newTasks = [...tasks];
      newTasks.splice(index, 1);
      setTasks(newTasks);
    }
  };
  const handleEmployeeChangeName = (event: any) => {
    fetchAutocompleteEmployee(event.target.value);
  };

  const handleDepartmentChangeName = (event: any) => {
    fetchAutocompleteDepartment(event.target.value);
  };

  const handleTaskChangeName = (event: any) => {
    fetchAutocompleteTask(event.target.value);
  };

  const handleTaskSelection = async (name:string, event:any) => {
    console.log("The selected is: ", name, "Value", event);
    setAsignTask((prevState) => ({ ...prevState, [name]: event }));
  }


  const fetchAutocompleteEmployee = async (inputs: any) => {
    if (inputs.length < 0) {
      return; // don't call the API if the input is less than 2 characters
    }
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Query/emautocomplete?prefix=${inputs}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setGetEmployee(response.data);
    } catch (error) {
      console.error('Error fetching autocomplete results:', error);
    }
  };


  const fetchAutocompleteDepartment = async (inputs: any) => {
    if (inputs.length < 0) {
      return; // don't call the API if the input is less than 2 characters
    }
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Query/Depautocomplete?prefix=${inputs}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setGetDepartment(response.data);
    } catch (error) {
      console.error('Error fetching autocomplete results:', error);
    }
  };


  
  const fetchAutocompleteTask = async (inputs: any) => {
    if (inputs.length < 0) {
      return; // don't call the API if the input is less than 2 characters
    }
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Query/Taskautocomplete?prefix=${inputs}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setGetTaskName(response.data);
    } catch (error) {
      console.error('Error fetching autocomplete results:', error);
    }
  };

  function formatDate(dateString: Date): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero indexed, so +1 and pad with 0
    const day = ('0' + date.getDate()).slice(-2); // Pad with 0
    return `${year}-${month}-${day}`;
  }

  const handleTaskDateChange = (
    name: string,
    date: Date | null | undefined
  ) => {
    if (date) {
      console.log('The Date onSelectDate', date);
      let formatedDate = formatDate(date);
      setAsignTask((prevState) => ({
        ...prevState,
        [name]: formatedDate,
      }));
      setAsignTask((prevState) => ({ ...prevState, [name]: formatedDate }));

    }
  };
  const fetchAdjuster = useCallback(async () => {
    try {
        const response = await axios.get(`${WHITE_HOUSE_API}/Adjuster/${user.phoneNumber}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            },
        });
        const adjuster = response.data;
        setAdjuster(adjuster);
        if(adjuster){
          setAsignTask((prevState) => ({ ...prevState, requestorId: adjuster.employeeId }));

        }
    } catch (error) {
        console.error('Error fetching roles:', error);
    }
}, [user.phoneNumber, user.token]);


  const SubmitAllTask = async () => {
    try {
      console.log("The Object Array : ", asignTask);
     if(allTaskAsigned.length < 1 && asignTask.employeeId >0 && asignTask.departmentId>0 && asignTask.taskId>0 && asignTask.endDate && asignTask.endDate){
      allTaskAsigned.push(asignTask);
      setAllTaskAsigned(prevTasks => [...prevTasks, asignTask]);
     };
      if(allTaskAsigned.length > 0){
        console.log("The Object Array : ", allTaskAsigned);
        setIsLoader(true);
        const response = await axios.post(
          `${WHITE_HOUSE_API}/Asignments`,
          allTaskAsigned,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        if (response.data.status === "Success") {
            setTimeout(() => {
              setIsLoader(false);
            }, 1500);
      
            if (response.data.status === "Success") {
              setResponseMessage(response.data.eMessage);
              setDispatchToaster(true);
              setTimeout(() => {
                setDispatchToaster(false);
              }, 1000);
            }
          
        }
        console.log(response.data);
      }else{
        alert("An error occured, please try again");
      }
    
    } catch (error) {
      setTimeout(() => {
        setIsLoader(false);
      }, 1500);
      console.error('Error:', error);
    }
  };


  
  useEffect(() => {
       
    if(user.phoneNumber){
        fetchAdjuster();
    };
    console.log(allTaskAsigned);
  }, [setAdjuster, message.id, user.phoneNumber, fetchAdjuster, allTaskAsigned]); //
 

  return (
    <>
      <div className="ms-Fabric ms-Layer-content content-46">
        <div className="wc-no outline">
          <div className="ms-Modal is-open root-39">
            <div aria-hidden="true" className="ms-Overlay ms-Overlay--dark root-48"></div>
            <div className="ms-Dialog-main YONwB main-571">
             <div className="ms-Modal-scrollableContent scrollableContent-351">
              <div className="ENdu4">
              {isLoader && (
              <>
                <Loaders />
              </>
                )}
                <div className="kkfQ0 layoutBucketAbove1366px">
                  <div className="dlPt0">
                  {dispatchToaster && (
                       <DispatchToast message={responseMessage}/>
                    )}
                    <div className="tMwcE customScrollBar">
                      <div className="mQpKr">
                      <div className="loAeb">Task calendar</div>
                      </div>
                      <div className="ms-FocusZone css-169 VkUGC">
                        <Button icon={<PeopleAddRegular />} className="ms-Button ms-Button--default Lig48 QrOjl root-314">Add Task Calender</Button>
                      <div className="OFYvV"></div>
                      <Button className="ms-Button ms-Button--default Lig48 root-314 UEOBEG"> {''} </Button>
                      <Button className="ms-Button ms-Button--default Lig48 root-314 UEOBEG">{''}</Button>
                      <Button className="ms-Button ms-Button--default Lig48 root-314 UEOBEG">{''}</Button>
                      <div className="OFYvV"></div>
                      <Button className="ms-Button ms-Button--default Lig48 root-314 UEOBEG">{''}</Button>
                      </div>
                    </div>
                    <div className="wEjJM">
                       <div className="rOvoX">
                        <div className="jZiKG vDlOs">
                          <div className="QPiTk">
                              <div className="m1eDn">
                                <img className="MSGGDU scriptor-inline scriptor-emoji scriptor-hubble-emoji" src={taskDelivarables} alt='' />
                                <span className="scriptor-textRun scriptor-inline"> Project deliverables</span>
                              </div>
                              <div className="WETA_UE" onClick={onClose}>
                                <Button size="small" icon={<DismissRegular  />}></Button>
                              </div>
                          </div>
                        
                        </div>
                         </div>
                         <div className="mcrpC customScrollBar">
                            <div className="jJjU6">
                              <div className="RkC_B">
                                <div className="scriptor-paragraph">
                                  <div className="scriptor-inlin">
                                    <div className="scriptor-hosting-element scriptor-component-block">
                                      <div className="">
                                        <div className="fui-FluentProvider table-4810fui-FluentProvider71 ___jdtuxv0 f19n0e5 fxugw4r f1o700av fk6fouc fkhj508 figsok6 f1i3iumi">
                                          <div className="css-230">
                                            <div className="css-231 css-186">
                                            <Table noNativeElements aria-label="Table without semantic HTML elements">
                                            <TableHeader>
                                              <TableRow>
                                                <TableHeaderCell key="key1">Task</TableHeaderCell>
                                                <TableHeaderCell key="key2">Asigned To </TableHeaderCell>
                                                <TableHeaderCell key="key3">Start Date</TableHeaderCell>
                                                <TableHeaderCell key="key4">Due Date</TableHeaderCell>
                                                <TableHeaderCell key="key4">Department</TableHeaderCell>
                                              </TableRow>
                                            </TableHeader>
                                            <TableBody className="BDHTU_USER">
                                              {tasks.map((task, index) => (
                                                <>
                              {(hoveredIndex === index || buttonHoveredIndex === index) && 
                            <Tooltip withArrow content="Delete row" relationship="label">
                              <Button className="MHDKUE-DEJAO" size="small" icon={<DeleteRegular />} onClick={() => deleteTask(index)} onMouseEnter={() => setButtonHoveredIndex(index)} onMouseLeave={() => setButtonHoveredIndex(null)}></Button>
                            </Tooltip>}
                                                                    
                                                 <TableRow key={index} onMouseEnter={() => setHoveredIndex(index)} onMouseLeave={() => setHoveredIndex(null)}>
                                                  <TableCell>
                                                  <Combobox
                                                  className="No_BHIEG"
                                                  placeholder="Task Name"
                                                    aria-label="autocomplete"
                                                    name="taskId"
                                                    onChange={handleTaskChangeName}
                                                    onSelect={(event) => {
                                                      const target = event.target as HTMLInputElement;
                                                      const selectedValue = target.value; 
                                                      const selectedName = target.name;
                                                      let tskid = getTaskName.find(c => c.taskName === selectedValue);
                                                      handleTaskSelection("taskId", tskid?.taskId);
                                                     
                                                  }}
                                                 
                                                >
                                                    {getTaskName.map((result) => (
                                                        <Option
                                                            key={result.taskId}
                                                            value={result.taskId.toString()}
                                                            text={`${result.taskName}`}
                                                        >
                                                            <Persona
                                                                avatar={{
                                                                    color: 'colorful',
                                                                    'aria-hidden': true,
                                                                }}
                                                                name={`${result.taskName}`}
                                                                presence={{
                                                                    status: 'available',
                                                                }}
                                                                secondaryText={result.priority}
                                                            />
                                                        </Option>
                                                    ))}
                                                </Combobox>
                                                  
                                                  </TableCell>
                                                  <TableCell>
                                                  <Combobox
                                                  className="No_BHIEG"
                                                  placeholder="Add Asignee"
                                                    aria-label="autocomplete"
                                                    name="employeeId"
                                                    onChange={handleEmployeeChangeName}
                                                    onSelect={(event) => {
                                                      const target = event.target as HTMLInputElement;
                                                      const selectedValue = target.value; 
                                                      let newvalue =  selectedValue.replace("@", "");
                                                      let empid = getEmployee.find(c => c.firstName +  " " +c.lastName === newvalue);
                                                      handleTaskSelection("employeeId", empid?.employeeId);
                                                     
                                                  }}
                                                  
                                                >
                                                    {getEmployee.map((result) => (
                                                        <Option
                                                            key={result.employeeId}
                                                            value={`${result.firstName} " "  ${result.lastName}`}
                                                            text={`@${result.firstName} ${result.lastName}`}
                                                        >
                                                            <Persona
                                                                avatar={{
                                                                    color: 'colorful',
                                                                    'aria-hidden': true,
                                                                }}
                                                                name={`${result.firstName} ${result.lastName}`}
                                                                presence={{
                                                                    status: 'available',
                                                                }}
                                                                secondaryText={result.email}
                                                            />
                                                        </Option>
                                                    ))}
                                                </Combobox>

                                                  </TableCell>
                                                  <TableCell>
                                                    <DatePicker
                                                      showWeekNumbers={true}
                                                      showGoToToday={false}
                                                      firstWeekOfYear={1}
                                                      showMonthPickerAsOverlay={true}
                                                      placeholder="Start Date"
                                                      name="startDate"
                                                      className={styles.control}
                                                      onSelectDate={(date) => {
                                                        handleTaskDateChange(
                                                          'startDate',
                                                          date
                                                        );
                                                      }}
                                                    />
                                                  </TableCell>
                                                  <TableCell>
                                                    <DatePicker
                                                      showWeekNumbers={true}
                                                      showGoToToday={false}
                                                      firstWeekOfYear={1}
                                                      showMonthPickerAsOverlay={true}
                                                      placeholder="Due Date"
                                                      className={styles.control}
                                                      name="endDate"
                                                      onSelectDate={(date) => {
                                                        handleTaskDateChange(
                                                          'endDate',
                                                          date
                                                        );
                                                      }}
                                          
                                                    />
                                                  </TableCell>
                                                  <TableCell>
                                                  <Combobox
                                                  className="No_BHIEG"
                                                  placeholder="Department"
                                                    aria-label="autocomplete"
                                                    name="departmentId"
                                                    onChange={handleDepartmentChangeName}
                                                    onSelect={(event) => {
                                                      const target = event.target as HTMLInputElement;
                                                      const selectedValue = target.value; 
                                                      let depid = getDepartment.find(c => c.departmentName  === selectedValue);
                                                      handleTaskSelection("departmentId", depid?.departmentId);
                                                  }}
                                                  
                                                >
                                                    {getDepartment.map((result) => (
                                                        <Option
                                                            key={result.departmentId}
                                                            value={result.departmentName}
                                                            text={`${result.departmentName}`}
                                                        >
                                                            <Persona
                                                                avatar={{
                                                                    color: 'colorful',
                                                                    'aria-hidden': true,
                                                                }}
                                                                name={`${result.departmentName}`}
                                                                presence={{
                                                                    status: 'available',
                                                                }}
                                                                secondaryText={result.location}
                                                            />
                                                        </Option>
                                                    ))}
                                                </Combobox>
                                                  </TableCell>
                                                </TableRow>
                                                </>
                                               
                                              ))}
                                            </TableBody>
                                          </Table>

                                            </div>
                                            <Button className="MHDKUE" size="small" icon={<AddRegular />} onClick={addTask}>Add Task</Button>
                      
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div className="rOvoXUiOgb">
                          <Button onClick={SubmitAllTask} size="medium" appearance="primary" className="MOrneOU">Save Task</Button>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
             </div>
          
          </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default ViewTask;