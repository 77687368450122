import React, { useState } from "react";
import Header from "../Common/Header/Header";
import Sidebar from "../Common/Sidebar/Sidebar";
import RightPage from "./RightPage/RightPage";
import "./WhiteHouseApps.scss";
import "../Themes/Fonts/Icon/csc.scss";
import AppHome from "./APPS/AppHome";
import { SearchBoxProps } from "@fluentui/react-search-preview";
import { useNavigate } from 'react-router-dom';

// ...



interface Message {
    message: string,
    id:number,
  }
  
  type Adjuster = {
    firstname?:string;
    lastname?:string;
    phoneNumber?:string;
    userid?: number;
  }
interface User {
    email: string,
    phoneNumber:string,
    token:string,
  
    // other properties...
  }
interface WhiteHouseAppsProps {
    user: User;
    message: Message;
  }
  

// In WhiteHouseApps.tsx
const WhiteHouseApps: React.FC<WhiteHouseAppsProps> = ({ user,message }) => {
    const [isInnerSidebarVisible, setIsInnerSidebarVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string | undefined>("Landing");
    const [componentStack, setComponentStack] = useState<string[]>([]);
    
    const navigate = useNavigate();
    const handleInnerSidebarToggle = () => {
        setIsInnerSidebarVisible(!isInnerSidebarVisible);
    };

    const handleOptionClick = (optionName: string | undefined) => {
        setSelectedOption(optionName);
        if(selectedOption){
            setComponentStack([...componentStack, selectedOption]);
        };
        if (!isInnerSidebarVisible) {
            handleInnerSidebarToggle();
        }
    };

    const handleLeftIconClick = () => {
        setSelectedOption("Landing");
        setIsInnerSidebarVisible(false);
    };

    const handleRightIconClick = () => {
        if (componentStack.length > 0) {
            const lastComponent = componentStack[componentStack.length - 1];
            setSelectedOption(lastComponent);
            setComponentStack(componentStack.slice(0, -1));
        }
        setIsInnerSidebarVisible(false);
    };

    return (
        <>
            <div className="white-house-container">
                <Header isTabActive={!!selectedOption} onLeftIconClick={handleLeftIconClick} onRightIconClick={handleRightIconClick} SiderbarShowOptionClick={handleOptionClick} user={user}  />
                <div className="white-house-body">
                    <div className="QVF0n">
                        <Sidebar SiderbarShowOptionClick={handleOptionClick} />
                    </div>
                    <div className="fluent-ui-component activitie-page al ci an ao ap bt ar as at au av aw ax ay az ba bb bc bd be bf bg bh bi bj bk bl bm bn bo">
                        <AppHome selectedOption={selectedOption} user={user} message={message}/>
                    </div>
                </div>
            </div>
        </>
    );
};


export default WhiteHouseApps;
