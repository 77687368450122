
import { SearchBox } from "@fluentui/react-search-preview";
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel, Avatar, AvatarGroup, AvatarGroupItem, Checkbox, Field, Label, Radio, RadioGroup, Select, useToastController } from "@fluentui/react-components";
import type { SearchBoxProps } from "@fluentui/react-search-preview";
import axios from 'axios';
import { Persona, PersonaSize, initializeIcons } from '@fluentui/react';
import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { Input } from '@fluentui/react-components';
import { ChevronLeft24Regular, PersonAdd24Regular, PeopleRegular, WeatherRainShowersNight24Regular } from '@fluentui/react-icons';

import {
    Dropdown,
    makeStyles,
    Option,
    shorthands,
    useId,
    Caption1,
    tokens,
    Text,
    Link,
    Button,
    Toaster,
    Toast,
    ToastTitle,
    ToastBody,
    ToastFooter,
} from "@fluentui/react-components";
import type { DropdownProps } from "@fluentui/react-components";
import { IDropdownOption, DetailsList, IColumn, CheckboxVisibility } from '@fluentui/react';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import { WHITE_HOUSE_API } from "../../../Constants/Constant";
import "./RegisterVendor.scss";
import { Vendor } from "../../../Data/General/Vendor";
import Loaders from "../../Common/Loader/Loader";
import { IBusinessTypes } from "../../../Data/General/BusinessType";
interface Titles {
    titleId: number;
    titleName: string;
}
const useStyles = makeStyles({
    main: {
        ...shorthands.gap('16px'),
        display: 'flex',
        flexWrap: 'wrap',
    },

    card: {
        width: '400px',
        maxWidth: '100%',
        minHeight: '250px',
        height: 'fit-content',
    },

    caption: {
        color: tokens.colorNeutralForeground3,
    },

    smallRadius: {
        ...shorthands.borderRadius(tokens.borderRadiusSmall),
    },

    grayBackground: {
        backgroundColor: tokens.colorNeutralBackground3,
    },


    control: {
        maxWidth: "300px",
    },
    wrapper: {
        columnGap: "15px",
        display: "flex",
        marginBottom: '10px',
    },
});

const Vendors: Vendor = {
    vendorId: 0,
    vendorName: "",
    contactPerson: "",
    email: "",
    phone: "",
    address: "",
    notes: "",
    vendorType: "",
    paymentTerms: "",
    isActive: true,
    companyName: "",
    fax: "",
    tagline: "",
    webAddress: "",
    regNumber: "",
    currency: "",
}
interface User {
    email: string,
    phoneNumber:string,
    token:string,
  
    // other properties...
  }
type RegisterCompanyProps = {
    close: () => void;
    user:User;
};
const RegisterCompany: React.FC<RegisterCompanyProps> = ({ close, user }) => {
    const [showForm, setShowForm] = useState(false);
    const [showDetails, setshowDetails] = useState(true);
    const ActionText = showForm ? "Submit" : "Book Now";
    const [query, setQuery] = useState('');
    const [results, setResults] = useState<Vendor[]>([]);
    const [roomName, setRoomName] = useState("");
    const [roomNumber, setRoomNumber] = useState("");
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [showList, setShowList] = useState(false);
    const [hideAll, setHideAll] = useState(true);
    const [titleMapping, setTitleMapping] = useState<Titles[]>([]);
    const [filteredOptions, setFilteredOptions] = useState(titleMapping);
    const outlineId = useId("input-outline");
    const underlineId = useId("input-underline");
    const filledLighterId = useId("input-filledLighter");
    const filledDarkerId = useId("input-filledDarker");
    const [isSearchActive, setisSearchActive] = useState(false);
    const [registerVendor, setRegisterVendor] = useState<Vendor>(Vendors);
    const [isLoader, setIsLoader] = useState<boolean>(true);
    const [businessMapping, setBusinessMapping] = useState<IBusinessTypes[]>([]);
    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);
    const styles = useStyles();
    initializeIcons();

    const beforeLabelId = useId("before-label");
    const afterLabelId = useId("after-label");
    const [currency, setCurrency] = useState('NGN');
    const currencySymbol = currency === 'NGN' ? '₦' : '$';

    const handleCurrencyChange = (event: any) => {
        const option = event.target.value;
        setCurrency(option);
    };



    const items = [
        { key: '1', name: 'Name', value: 'Flat1 Deluxy' },
        { key: '2', name: 'Floor', value: 2 },
        { key: '3', name: 'Area', value: 28 },
        { key: '4', name: 'Capacity', value: '2 + 1, Max. 3' },
        { key: '5', name: 'Cleaning Required', value: 'Yes' },
        { key: '6', name: 'Smoking Allowed', value: 'No' },
    ];

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: 'Name',
            fieldName: 'name',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: 'column2',
            name: 'Value',
            fieldName: 'value',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
        },
    ];

    const handleBrowseAllClick = () => {
        setShowList((prevShowList) => !prevShowList);

    };


    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log(`Searching for: ${searchTerm}`);
    };

    const handleClearSearch = () => {
        setSearchTerm('');
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };
    const fetchTitles = useCallback(async () => {
        try {
            const response = await axios.get(`${WHITE_HOUSE_API}/Title`);
            const Titles = response.data;
            setTitleMapping(Titles);
            setFilteredOptions(Titles);
            console.log('Title items:', Titles);
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    }, []);

    const fetchBusinessTypes = useCallback(async () => {
        try {
            const response = await axios.get(`${WHITE_HOUSE_API}/BusinessType`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });
            const businesstypes = response.data;
            setBusinessMapping(businesstypes);
            console.log('Business items:', businesstypes);
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    }, [user.token]);
    

    useEffect(() => {

    }, [currency]);

    useEffect(() => {
        console.log(currencySymbol);
        fetchTitles();
        fetchBusinessTypes();
    }, [fetchTitles, currency, currencySymbol, fetchBusinessTypes]);

    const handleInputChange = (event: any) => {
        const que = event.target.value;
        setisSearchActive(event.target.value !== '');
        setQuery(que);
        if (que.length >= 1) {
            search(que);
        }
    };
    const toggleForm = () => {
        setShowForm(c => !c);
        setshowDetails(c => !c);

    }
    const SubmitVendorsItem = async () => {
        try {
            setIsLoader(true);
            const response = await axios.post(`${WHITE_HOUSE_API}/Vendor`, registerVendor, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });
            if (response.status === 200) {
                setIsLoader(false);
                notify();
                setRegisterVendor({ vendorId: 0 });
            }
            console.log(response.data);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    
    const handleVendorInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        setRegisterVendor(prevState => ({ ...prevState, [name]: value }));
    };
    
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;

        if (checked) {
            setRegisterVendor(prevState => ({ ...prevState, PaymentTerms: name }));
        } else if (registerVendor.paymentTerms === name) {
            setRegisterVendor(prevState => ({ ...prevState, PaymentTerms: undefined }));
        }
    };


    const handleVendorRadioChange = (event: React.FormEvent<HTMLDivElement>, data: any) => {
        const { value } = data;
        setRegisterVendor(prevState => ({ ...prevState, Currency: value }));
    };

    const notify = () =>
        dispatchToast(
            <Toast>
                <ToastTitle>Registration successful</ToastTitle>
            </Toast>,
            { intent: "success" }
        );
    const search = async (query: any) => {
        try {
            const response = await axios.get(`${WHITE_HOUSE_API}/Query/guest?query=${query}`, {
                params: { query },
            });
            setResults(response.data);
        } catch (error) {
            console.error(error);
        }
    };


    return (
        <>
            <div className="ms-Fabric ms-Layer-content content-46">
                <div className='wc-no outline'>
                    <div className="ms-Modal is-open root-39">
                        <div aria-hidden="true" className="ms-Overlay ms-Overlay--dark root-48"></div>
                        <div id="cilsco-focuszone" className="ms-Dialog-main createListModalMain_979b2e47 main-40">
                            <div aria-hidden="true" id='Ariel-fixed' data-is-visible="true" data-is-focus-trap-zone-bumper="true"></div>
                            <div className="ms-Modal-scrollableContent scrollableContent-54 customScrollBar">
                                <div className="Merging-34">
                                    <div className="createList-root templatePreviewPage">
                                        <div className="templatePreview">
                                            <div className="createList-pageRoot templatePreviewContainer">
                                                <div className="createList-header">
                                                    <div className="templateTitleWithLogo">
                                                        <Avatar icon={<PersonAdd24Regular />} aria-label="Group" />
                                                        <h1 className="createList-headerTitle wc-margin">Company Name</h1>
                                                    </div>
                                                    {/* use this to update all my search */}
                                                    <div className={`ui-box Wsearch-ev-ew c-search_modal__wrapper popover-little ${isSearchActive ? 'mouse-over-active' : ''}`}>
                                                        <SearchBox
                                                            placeholder="Search existing vendors .."
                                                            value={query}
                                                            onChange={handleInputChange}
                                                            onBlur={search}
                                                            className="sear-padd"


                                                        />
                                                        <div className={`c-search_autocomplete__container c-search_autocomplete__container--query-ordered ${isSearchActive ? 'serachbar-active' : ''}`}>
                                                            <div className="c-search_autocomplete c-search_autocomplete--nav-attached">
                                                                <div className="offscreen">
                                                                    <span id="search-autocomplete-query-label-prefix-node">Search</span>
                                                                </div>
                                                                {results.map((g) => {

                                                                    let emailorphone = g.phone ?? g.email;
                                                                    return (
                                                                        <div key={g.companyName} className="c-search_autocomplete__suggestion_list c-search_autocomplete__suggestion_list--taller">
                                                                            <div className="curtain-a wform-css-283-right">
                                                                                <div className="fui-Persona rlroi9i">
                                                                                    <span
                                                                                        role="img"
                                                                                        id="avatar-rd"
                                                                                        className="fui-Avatar r81b29z fui-Persona__avatar ___1c989qy_1o9k6tz f13ar0e0 fid048z f1hek2iy f9h729m f18q9vkd f103ycu4"
                                                                                        aria-label="Kevin Sturgis"
                                                                                        aria-labelledby="avatar-rd avatar-rd__badge"
                                                                                    >
                                                                                        <AvatarGroup>

                                                                                            <AvatarGroupItem name={g.companyName} />


                                                                                        </AvatarGroup>
                                                                                    </span>
                                                                                    <span className="fui-Persona__primaryText rvj41k9">{g.companyName}</span>
                                                                                    <span className="fui-Persona__secondaryText rp1pf9e ___19lp2a0_1p5a1yj f1ryq6si">{emailorphone}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* use this to update all my search */}
                                                    <div className="createList-closeBtnContainer">
                                                        <Button icon={<i className="fi fi-sr-cross-small" />} onClick={close} ></Button>
                                                    </div>
                                                </div>
                                                <>
                                                    <div className="createList-pageContent includeBottomPadding customScrollBar">
                                                        <div className="previewContainer  wc-add-paddin">
                                                            <form action="" className='Form-content'>
                                                                <div className="wform-MyContentControl-module__edgeworth-stack-item-container-refresh__3mUvv wform-css-283 ">
                                                                    <div className="ui-divider  ua-gi-ub-uc">

                                                                        <div className="wc-form-wrapper">
                                                                            {/* <div className="w-add-Guest-room Guest-management-job">
                                                                                <div className="w-add-room-guest rms-block-left">
                                                                                    <div className="w-add-room-guest Guest-inline-property-title">Title</div>
                                                                                    <Dropdown
                                                                                        placeholder="Select a Title"
                                                                                        appearance="filled-darker"
                                                                                    >
                                                                                        <Option>Mr</Option>
                                                                                        <Option>Mrs</Option>
                                                                                        <Option>Master</Option>
                                                                                        <Option>Miss</Option>
                                                                                    </Dropdown>
                                                                                </div>
                                                                                <div className="w-add-room-guest rms-block-right">
                                                                                    <div className="w-add-room-guest rms-inline-property-title">Gender</div>
                                                                                    <Dropdown
                                                                                        placeholder="Select a gender"
                                                                                        appearance="filled-darker"
                                                                                    >
                                                                                        <Option>Male</Option>
                                                                                        <Option>Female</Option>
                                                                                    </Dropdown>
                                                                                </div>
                                                                            </div> */}

                                                                            <div className="w-add-Guest-room Guest-management-job">
                                                                                <div className="w-add-room-guest rms-block-left">
                                                                                    <div className="w-add-room-guest Guest-inline-property-title">Company Name</div>
                                                                                    <div className="firstName-container">
                                                                                        <Input placeholder='Comapany or Business Name'
                                                                                            name="CompanyName"
                                                                                            appearance="filled-darker"
                                                                                            id={filledDarkerId}
                                                                                            onChange={handleVendorInputChange} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="w-add-room-guest rms-block-right">
                                                                                    <div className="w-add-room-guest rms-inline-property-title">Company Tag</div>
                                                                                    <div className="firstName-container">
                                                                                        <Input appearance="filled-darker"
                                                                                            name="Tagline"
                                                                                            placeholder='Company Tags'
                                                                                            onChange={handleVendorInputChange}
                                                                                            id={filledDarkerId} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                            {/* <div className="w-add-Guest-room Guest-management-job">
                                                                                <div className="w-add-room-guest rms-block-left">
                                                                                    <div className="w-add-room-guest Guest-inline-property-title">Date Of Birth</div>
                                                                                    <div className="firstName-container">
                                                                                        <Field>
                                                                                            <DatePicker

                                                                                                showWeekNumbers={true}
                                                                                                firstWeekOfYear={1}
                                                                                                showMonthPickerAsOverlay={true}
                                                                                                placeholder="Date of birth..."
                                                                                                className={styles.control}
                                                                                            />
                                                                                        </Field>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="w-add-room-guest rms-block-right">
                                                                                    <div className="w-add-room-guest rms-inline-property-title">Country</div>
                                                                                    <div className="firstName-container">
                                                                                        <Input appearance="filled-darker" placeholder='Country' id={filledDarkerId} />
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                                                            <div className="w-add-Guest-room Guest-management-job">
                                                                                <div className="w-add-room-guest rms-block-left">
                                                                                    <div className="w-add-room-guest Guest-inline-property-title">Fax:</div>
                                                                                    <div className="firstName-container">
                                                                                        <Input placeholder='Company Phone'
                                                                                            name="Fax"
                                                                                            appearance="filled-darker"
                                                                                            id={filledDarkerId}
                                                                                            onChange={handleVendorInputChange} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="w-add-room-guest rms-block-right">
                                                                                    <div className="w-add-room-guest rms-inline-property-title">Email</div>
                                                                                    <div className="firstName-container">
                                                                                        <Input appearance="filled-darker"
                                                                                            name="Email"
                                                                                            type="email"
                                                                                            placeholder='Email or contact person email'
                                                                                            id={filledDarkerId}
                                                                                            onChange={handleVendorInputChange} />
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <div className="w-add-Guest-room Guest-management-job">
                                                                                <div className="w-add-room-guest rms-block-right">
                                                                                    <div className="w-add-room-guest rms-inline-property-title">Country</div>
                                                                                    <div className="firstName-container">
                                                                                        <Input appearance="filled-darker"
                                                                                            name="Country"
                                                                                            placeholder='Country'
                                                                                            onChange={handleVendorInputChange}
                                                                                            id={filledDarkerId} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="w-add-room-guest rms-block-left">
                                                                                    <div className="w-add-room-guest Guest-inline-property-title">State</div>
                                                                                    <div className="firstName-container">
                                                                                        <Input placeholder='State'
                                                                                            name="State"
                                                                                            appearance="filled-darker"
                                                                                            onChange={handleVendorInputChange}
                                                                                            id={filledDarkerId} />
                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div className="w-add-Guest-room Guest-management-job">
                                                                                <div className="w-add-room-guest rms-block-left">
                                                                                    <div className="w-add-room-guest Guest-inline-property-title">Address</div>
                                                                                    <div className="firstName-container">
                                                                                        <Input placeholder='Address'
                                                                                            name="Address"
                                                                                            appearance="filled-darker"
                                                                                            onChange={handleVendorInputChange}
                                                                                            id={filledDarkerId} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="w-add-room-guest rms-block-right">
                                                                                    <div className="w-add-room-guest rms-inline-property-title">City</div>
                                                                                    <div className="firstName-container">
                                                                                        <Input appearance="filled-darker"
                                                                                            name="City"
                                                                                            onChange={handleVendorInputChange}
                                                                                            placeholder='City'
                                                                                            id={filledDarkerId} />
                                                                                    </div>
                                                                                </div>
                                                                                {/* <div className="w-add-room-guest rms-block-right">
                                                                                    <div className="w-add-room-guest rms-inline-property-title">Passport Number</div>
                                                                                    <div className="firstName-container">
                                                                                        <Input appearance="filled-darker" placeholder='ID/Passport Number' id={filledDarkerId} />
                                                                                    </div>
                                                                                </div> */}
                                                                            </div>
                                                                            <div className="w-add-Guest-room Guest-management-job">
                                                                                <div className="w-add-room-guest rms-block-left">
                                                                                    <div className="w-add-room-guest Guest-inline-property-title">Vendor Name:</div>
                                                                                    <div className="firstName-container">
                                                                                        <Input placeholder='Vendor Name'
                                                                                            name="vendorName"
                                                                                            appearance="filled-darker"
                                                                                            id={filledDarkerId}
                                                                                            onChange={handleVendorInputChange} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="w-add-room-guest rms-block-right">
                                                                                    <div className="w-add-room-guest rms-inline-property-title">Vendor Type</div>
                                                                                    <div className="firstName-container">
                                                                                       
                                                                                    <Select appearance="filled-darker" name="vendorType" id={filledDarkerId} onChange={handleVendorInputChange}>
                                                        <option value="">Select vendor type</option>
                                                        {businessMapping.map((businessType) => {
                                                            var Names = businessType.businessTypeName + ' ' + businessType.description;
                                                            return (
                                                                <option key={businessType.businessTypeId} value={Names}>
                                                                    {businessType.businessTypeName}
                                                                </option>
                                                            );
                                                        })}
                                                    </Select>


                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            {/* <div className="w-add-Guest-room Guest-management-job">
                                                                                <div className="w-add-room-guest rms-block-left">
                                                                                    <div className="w-add-room-guest Guest-inline-property-title">Issues Date</div>
                                                                                    <div className="firstName-container">
                                                                                        <Field>
                                                                                            <DatePicker

                                                                                                showWeekNumbers={true}
                                                                                                firstWeekOfYear={1}
                                                                                                showMonthPickerAsOverlay={true}
                                                                                                placeholder="Issues Date..."
                                                                                                className={styles.control}
                                                                                            />
                                                                                        </Field>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="w-add-room-guest rms-block-right">
                                                                                    <div className="w-add-room-guest rms-inline-property-title">Expire Date</div>
                                                                                    <div className="firstName-container">
                                                                                        <Field>
                                                                                            <DatePicker

                                                                                                showWeekNumbers={true}
                                                                                                firstWeekOfYear={1}
                                                                                                showMonthPickerAsOverlay={true}
                                                                                                placeholder="Expire Date..."
                                                                                                className={styles.control}
                                                                                            />
                                                                                        </Field>
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                                                            {/* <div className="w-add-Guest-room Guest-management-job">
                                                                                <div className="w-add-room-guest rms-block-left">
                                                                                    <div className="w-add-room-guest Guest-inline-property-title">Nationality</div>
                                                                                    <div className="firstName-container">
                                                                                        <Input placeholder='Nationality' appearance="filled-darker" id={filledDarkerId} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="w-add-room-guest rms-block-right">
                                                                                    <div className="w-add-room-guest rms-inline-property-title">Language</div>
                                                                                    <div className="firstName-container">
                                                                                        <Input appearance="filled-darker" placeholder='First Language' id={filledDarkerId} />
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                                                            {/* <div className="w-add-Guest-room Guest-management-job">
                                                                                <div className="w-add-room-guest rms-block-left">
                                                                                    <div className="w-add-room-guest Guest-inline-property-title">Checking Date</div>
                                                                                    <div className="firstName-container">
                                                                                        <Field>
                                                                                            <DatePicker

                                                                                                showWeekNumbers={true}
                                                                                                firstWeekOfYear={1}
                                                                                                showMonthPickerAsOverlay={true}
                                                                                                placeholder="Issues Date..."
                                                                                                className={styles.control}
                                                                                            />
                                                                                        </Field>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="w-add-room-guest rms-block-right">
                                                                                    <div className="w-add-room-guest rms-inline-property-title">Checkout Date</div>
                                                                                    <div className="firstName-container">
                                                                                        <Field>
                                                                                            <DatePicker

                                                                                                showWeekNumbers={true}
                                                                                                firstWeekOfYear={1}
                                                                                                showMonthPickerAsOverlay={true}
                                                                                                placeholder="Expire Date..."
                                                                                                className={styles.control}
                                                                                            />
                                                                                        </Field>
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                                                        </div>



                                                                    </div>

                                                                </div>
                                                                <div className="wform-MyContentControl-module__edgeworth-stack-item-container-refresh__3mUvv wform-css-283-right">
                                                                    <Accordion defaultOpenItems="basicInfo">
                                                                        <AccordionItem value="basicInfo">
                                                                            <AccordionHeader as="h2">General information</AccordionHeader>
                                                                            <AccordionPanel>
                                                                                <Label className="wc-pmargin-b">Mode of payment</Label>
                                                                                <AccordionPanel>
                                                                                    <div role="group" aria-labelledby="hobbiesLabel">
                                                                                        <Checkbox label="Direct Credit" name="PaymentTerms" onChange={handleCheckboxChange} />
                                                                                        <Checkbox label="After Sells" name="PaymentTerms" onChange={handleCheckboxChange} />
                                                                                        <Checkbox label="Invoice" name="PaymentTerms" onChange={handleCheckboxChange} />



                                                                                    </div>
                                                                                </AccordionPanel>
                                                                                <div className="w-add-Guest-room Guest-management-job">
                                                                                    <div className="w-add-room-guest rms-block-left">
                                                                                        <div className="w-add-room-guest Guest-inline-property-title">Web Address</div>
                                                                                        <div className="firstName-container">
                                                                                            <Input placeholder='Web Address'
                                                                                                name="WebAddress"
                                                                                                appearance="filled-darker"
                                                                                                onChange={handleVendorInputChange}
                                                                                                id={filledDarkerId} />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="w-add-room-guest rms-block-right">
                                                                                        <div className="w-add-room-guest rms-inline-property-title wc-margin">Reg No.</div>
                                                                                        <div className="firstName-container wc-margin">
                                                                                            <Input appearance="filled-darker"
                                                                                                placeholder='Business Reg No'
                                                                                                name="RegNumber"
                                                                                                onChange={handleVendorInputChange}
                                                                                                id={filledDarkerId} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="w-add-room-guest rms-inline-property-title wc-margin">Currency Used</div>
                                                                                <RadioGroup
                                                                                    defaultValue="NGN"
                                                                                    name="Currency"
                                                                                    onChange={handleVendorRadioChange}
                                                                                    aria-labelledby="ageLabel"
                                                                                    className="wc-flex"
                                                                                >
                                                                                    <Radio value="NGN" label="NGN" />
                                                                                    <Radio value="USD" label="USD" />
                                                                                </RadioGroup>
                                                                                <div className="w-add-Guest-room Guest-management-job">
                                                                                    <div className="w-add-room-guest rms-block-left">
                                                                                        <div className="w-add-room-guest Guest-inline-property-title">First contact person</div>
                                                                                        <div className="firstName-container">
                                                                                            <Input
                                                                                                name="ContactPerson"
                                                                                                placeholder='contact person name'
                                                                                                type="text" appearance="filled-darker"
                                                                                                onChange={handleVendorInputChange}
                                                                                                id={filledDarkerId} />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="w-add-room-guest rms-block-right wc-margin">
                                                                                        <div className="w-add-room-guest rms-inline-property-title wc-margin">Conatct Person phone number</div>
                                                                                        <div className="firstName-container">
                                                                                            <Input placeholder='contact person Phone'
                                                                                                name="Phone"
                                                                                                appearance="filled-darker"
                                                                                                onChange={handleVendorInputChange}
                                                                                                id={filledDarkerId} />

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* <div className="w-add-Guest-room Guest-management-job">
                                                                                    <div className="w-add-room-guest rms-block-left">
                                                                                        <div className="w-add-room-guest Guest-inline-property-title">CVV</div>
                                                                                        <div className="firstName-container">
                                                                                            <Input placeholder='CVV' appearance="filled-darker" type="password" id={filledDarkerId} />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="w-add-room-guest rms-block-right wc-margin">
                                                                                        <div className="w-add-room-guest rms-inline-property-title wc-margin">Amount</div>
                                                                                        <div className="firstName-container">
                                                                                            <Input
                                                                                                placeholder="Amount to Credit"
                                                                                                type="number"
                                                                                                contentBefore={
                                                                                                    <Text size={400} id={beforeLabelId}>
                                                                                                        {currencySymbol}
                                                                                                    </Text>
                                                                                                }
                                                                                                contentAfter={
                                                                                                    <Text size={400} id={afterLabelId}>
                                                                                                        .00
                                                                                                    </Text>
                                                                                                }
                                                                                                appearance="filled-darker"
                                                                                                id={filledDarkerId}
                                                                                            />

                                                                                        </div>
                                                                                    </div>
                                                                                </div> */}

                                                                                {/* <Label id="ageLabel">More Infomation:</Label>
                                                                                <RadioGroup defaultValue="InitialAddress" aria-labelledby="ageLabel" className="wc-flex">
                                                                                    <Radio value="InitialAddress" label="The same as above" />
                                                                                    <Radio value="InitialAddress2" label="Use Billing Address" />
                                                                                </RadioGroup> */}
                                                                            </AccordionPanel>
                                                                        </AccordionItem>
                                                                        {/* <AccordionItem value="residence">
                                                                            <AccordionHeader as="h2">Guest Status</AccordionHeader>
                                                                            <AccordionPanel>
                                                                                <RadioGroup defaultValue="InitialAddress" aria-labelledby="ageLabel" className="wc-flex">
                                                                                    <Radio value="married" label="Married" />
                                                                                    <Radio value="Single" label="Single" />
                                                                                </RadioGroup>
                                                                                <RadioGroup defaultValue="InitialAddress" aria-labelledby="ageLabel" className="wc-flex">
                                                                                    <Radio value="Children" label="Children" />
                                                                                    <Radio value="Nochildren" label="Without children" />
                                                                                </RadioGroup>
                                                                                <div className="w-add-Guest-room Guest-management-job">
                                                                                    <div className="w-add-room-guest rms-block-left">
                                                                                        <div className="w-add-room-guest Guest-inline-property-title">Adults</div>
                                                                                        <div className="firstName-container">
                                                                                            <Input placeholder='Number of Adults' appearance="filled-darker" id={filledDarkerId} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-add-room-guest rms-block-right">
                                                                                        <div className="w-add-room-guest rms-inline-property-title">Children</div>
                                                                                        <div className="firstName-container wc-margin">
                                                                                            <Input appearance="filled-darker" placeholder='number of childreen' id={filledDarkerId} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <Label htmlFor="country">Country:</Label>
                                                                                <Input type="text" id="country" name="country" />
                                                                            </AccordionPanel>
                                                                        </AccordionItem>
                                                                        <AccordionItem value="hobbies">
                                                                            <AccordionHeader as="h2">Requests</AccordionHeader>
                                                                            <AccordionPanel>
                                                                                <div role="group" aria-labelledby="hobbiesLabel">
                                                                                    <Checkbox label="Books" />
                                                                                    <Checkbox label="Sports" />
                                                                                    <Checkbox label="Music" />
                                                                                    <Checkbox label="Travelling" />


                                                                                </div>
                                                                            </AccordionPanel>
                                                                        </AccordionItem> */}
                                                                    </Accordion>



                                                                </div>
                                                            </form>
                                                        </div >
                                                    </div>


                                                </>
                                            </div>
                                        </div>
                                        <div className="createList-pageFooter">
                                            <div className="createList-leftActions">
                                                <Button data-automationid="createListGoBackButton" className='' icon={<ChevronLeft24Regular />} onClick={toggleForm}> Back</Button>
                                            </div>
                                            <div className="createList-rightActions">
                                                <Button className='wc-first-root-68' onClick={SubmitVendorsItem}>Register</Button>
                                                <Button className='' onClick={close}> Cancel</Button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isLoader && (
                <Loaders />
            )}
        </>
    )
}

export default RegisterCompany;