export interface IEventReservation {
    reservationId: number;
    firstName?: string;
    lastName?: string;
    emailAddress?: string;
    phoneNumber?: string;
    isReserved: boolean,
    isBooked: boolean,
    isCheckin:boolean,
    eventName?: string;
    eventType?: string;
    eventId?:number;
    startDate?: Date;
    endDate?: Date;
    numberOfAttendees?: number;
    numberOfRooms?: number;
    preferredRoom?: string;
    roomTypePreferences?: string;
    cateringRequirements?: string;
    equipmentNeeds?: string;
    specialRequests?: string;
    paymentMethod?: string;
    currency?: string;
    address?: string;
    reservationDateTime: Date;
}

export const reservation: IEventReservation = {
    reservationId: 0,
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    eventName: '',
    eventType: '',
    eventId: 0,
    startDate: new Date(),
    endDate: new Date(),
    numberOfAttendees: 0,
    numberOfRooms: 0,
    preferredRoom: '',
    isReserved: false,
    isBooked: false,
    isCheckin:false,
    roomTypePreferences: '',
    cateringRequirements: '',
    equipmentNeeds: '',
    specialRequests: '',
    paymentMethod: '',
    currency: '',
    address: '',
    reservationDateTime: new Date(),
};
