import { Housekeeping } from "./Housekeeping";
import { InvoiceInterface } from "./Invoice";
import { Maintenance } from "./Maintenance";
import { Reservation } from "./Reservation";
import { RoomAmenity } from "./RoomAmenity";
import { RoomCategoryMapping } from "./RoomCategoryMapping";
import { RoomDetail } from "./RoomDetail";
import { RoomRate } from "./RoomRate";

export interface IRoomManagement {
    id: number;
    roomId: number;
    roomLocation?: string;
    description?: string;
    roomLevel?: number;
    roomStatus?: string;
    roomMaintenance?: string;
    roomTemperature?: string;
    roomSafeStatus?: string;
    callRequests?: string;
    laundryStatus?: string;
    doNotDisturbStatus?: string;
    guestMessages?: string;
    housekeepingRequests?: string;
    guestPreferences?: string;
    roomFeatures?: string;
    modeofPayment?:string;
    roomPackages?: string;
    roomServiceOrders?: string;
    minibarInventory?: string;
    connectingRoomInformation?: string;
    specialOccasionDetails?: string;
    roomCleaningSchedule?: Date;
    localEventsAttractions?: string;
    noiseLevel?: string;
    roomAvailabilityCalendar?: string;
    roomStatusTimestamps?: Date;
    roomPhotos?: string;
    roomMaintenanceHistory?: string;
    roomTemperatureControl?: string;
    roomInRoomSafeStatus?: string;
    wakeUpCallRequests?: string;
    roomAccessibilityFeatures?: string;
    guestFeedbackReviews?: string;
    roomInventory?: string;
    bedType?:string;
    availability?: boolean;
    rate?: number;
    restrictions?: string;
    roomNumber?: number;
    status?: string;
    smoking?: boolean;
    adjusterId?:number;
    housekeeperId?: number;
    roomName?: string;
    checkedInTime?: string;
    firstName?: string;
    lastName?: string;
    checkedOutTime?: string;
    availableDate: Date;
    roomType:any;
    dateAdded: Date;
}

export const InitRoomManagement: IRoomManagement = {
  id: 0,
  roomId: 0,
  roomLocation: "",
  description: "",
  roomLevel: 0,
  roomStatus: "",
  roomMaintenance: "",
  roomTemperature: "",
  roomSafeStatus: "",
  callRequests: "",
  laundryStatus: "",
  doNotDisturbStatus: "",
  guestMessages: "",
  housekeepingRequests: "",
  guestPreferences: "",
  roomFeatures: "",
  roomPackages: "",
  roomServiceOrders: "",
  minibarInventory: "",
  bedType:"Single Bed",
  connectingRoomInformation: "",
  specialOccasionDetails: "",
  roomCleaningSchedule: new Date(),
  localEventsAttractions: "",
  noiseLevel: "",
  roomAvailabilityCalendar: "",
  roomStatusTimestamps: new Date(),
  roomPhotos: "",
  roomMaintenanceHistory: "",
  roomTemperatureControl: "",
  roomInRoomSafeStatus: "",
  wakeUpCallRequests: "",
  roomAccessibilityFeatures: "",
  guestFeedbackReviews: "",
  roomInventory: "",
  availability: false,
  rate: 0,
  restrictions: "",
  roomNumber: 0,
  modeofPayment: '',
  status: "",
  smoking: false,
  adjusterId: 0,
  housekeeperId: 0,
  roomName: "",
  checkedInTime: "",
  firstName: "",
  lastName: "",
  checkedOutTime: "",
  availableDate: new Date(),
  roomType: "",
  dateAdded: new Date()
};
