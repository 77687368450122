import React, { ChangeEvent, FormEvent, useCallback, useContext, useEffect, useState } from 'react';

import "../../../Dialog/SingleRoomViewDialog.scss";
import "../../Book/Booking.scss";
import { Button, Field, Input, Label, Select } from '@fluentui/react-components';
import { AlignSpaceEvenlyHorizontal24Regular, AppRecent24Regular, ArrowAutofitWidth24Regular, ChevronLeft24Regular, WeatherRainShowersNight24Regular } from '@fluentui/react-icons';
import { RoomDataListItem, RoomDetail } from '../../../../Data/General/RoomDetail';
import "./GuestForm.scss";

import {
    Dropdown,
    makeStyles,
    Option,
    shorthands,
    useId,
    Caption1,
    tokens,
    Text,
} from "@fluentui/react-components";
import type { DropdownProps } from "@fluentui/react-components";
import { IDropdownOption, DetailsList, IColumn, CheckboxVisibility } from '@fluentui/react';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import axios from 'axios';
import { SECRETE_KEY, SUBACCOUNT_CODE, WHITE_HOUSE_API, WHITE_HOUSE_S3, formatDateinput } from '../../../../Constants/Constant';
import { initializeIcons } from '@fluentui/react';
import { MoreHorizontal20Regular } from '@fluentui/react-icons';
import {
    Card,
    CardHeader,
    CardPreview,
    CardProps,
} from '@fluentui/react-components';
import { Gender } from '../../../../Data/General/IGender';
import { Guest, ICreateGuest, createGuest } from '../../../../Data/General/Guest';
import { IBooking, ICheckinGuest, InitializeBooking, InitializeCheckingGuest } from '../../../../Data/General/CheckinGuest';
import { SelectedRoomDetail } from '../../../../Data/General/RoomDetails';
import { BookingContext } from '../../../Common/Context/BookContext';


const resolveAsset = (asset: string) => {
    const ASSET_URL =
        `${WHITE_HOUSE_S3}`;

    return `${ASSET_URL}${asset}`;
};

const useStyles = makeStyles({
    main: {
        ...shorthands.gap('16px'),
        display: 'flex',
        flexWrap: 'wrap',
    },

    card: {
        width: '100%',
        maxWidth: '100%',
        minHeight: '250px',
        height: 'fit-content',
    },

    caption: {
        color: tokens.colorNeutralForeground3,
    },

    smallRadius: {
        ...shorthands.borderRadius(tokens.borderRadiusSmall),
    },

    grayBackground: {
        backgroundColor: tokens.colorNeutralBackground3,
    },


    control: {
        maxWidth: "300px",
    },
    wrapper: {
        columnGap: "15px",
        display: "flex",
        marginBottom: '10px',
    },
});


interface User {
    email: string,
    phoneNumber:string,
    token:string,
  
    // other properties...
  }
  interface SelectedRoom{
    number:any;
    name:any;
  }

interface Bookingguest {
    selected: SelectedRoom;
    user:User;
    onClose: () => void;
}

interface Titles {
    titleId: number;
    titleName: string;
}

const GuestForm: React.FC<Bookingguest> = ({selected, user, onClose}) => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [showList, setShowList] = useState(false);
    const [hideAll, setHideAll] = useState(true);
    const [titleMapping, setTitleMapping] = useState<Titles[]>([]);
    const [filteredOptions, setFilteredOptions] = useState(titleMapping);
    const [genderMapping, setGenderMapping] = useState<Gender[]>([]);
    const [filteredGenderOptions, setFilteredGenderOptions] = useState(genderMapping);
    const { bookRoom, setBookRoom } = useContext(BookingContext);
    const [roomDetailsMapping, setRoomDetailsMapping] = useState<SelectedRoomDetail
    >();
    const outlineId = useId("input-outline");
    const underlineId = useId("input-underline");
    const filledLighterId = useId("input-filledLighter");
    const filledDarkerId = useId("input-filledDarker");
    const styles = useStyles();
    initializeIcons();

   

    const handleBrowseAllClick = () => {
        setShowList((prevShowList) => !prevShowList);

    };

    const handleServiceChange = async (name:string, event:any) => {
        const service = event.target.innerText;
        console.log("Services id", service)
        setBookRoom((prevState) => ({ ...prevState, [name]: service }));
      }
      
      const handleStateInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setBookRoom(prevState => ({ ...prevState, [name]: value }));
      };

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    };

    const handleClearSearch = () => {
        setSearchTerm('');
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };



    const fetchTitles = useCallback(async () => {
        try {
            const response = await axios.get(`${WHITE_HOUSE_API}/Title`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                },
            });
            const Titles = response.data;
            setTitleMapping(Titles);
            setFilteredOptions(Titles);
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    }, [user.token]);
    

    const fetchGenders = useCallback(async () => {
        try {
            const response = await axios.get(`${WHITE_HOUSE_API}/Gender`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });
            const genders = response.data;
            setGenderMapping(genders);
            setFilteredGenderOptions(genders);
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    }, [user.token]);
    

    
    const fetchRoomDetails = useCallback(async (id:any) => {
        try {
            const response = await axios.get(`${WHITE_HOUSE_API}/RoomDetail/detail/${id}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });
            setRoomDetailsMapping(response.data);
            setBookRoom((prevState) => ({ ...prevState, roomNumber: id }));
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    }, [setBookRoom, user.token]);

    useEffect(() => {
        fetchGenders();
        fetchTitles();
        fetchRoomDetails(selected.number);
        setBookRoom((prevState) => ({ ...prevState, roomNumber: selected.number }));
    }, [fetchGenders, fetchRoomDetails, fetchTitles, selected, selected.number, setBookRoom]);



 const handleDateInputChange =
(name: string) => (e: Date | null | undefined) => {
  if (e) {
    let datevalue = formatDateinput(e);
    console.log("the date returned is: ", datevalue);
   setBookRoom((prevState) => ({ ...prevState, [name]: datevalue }));
  }
};


    const handleCheckingDropdowInputChange = (name: string) => (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        console.log("The Selected DropDown",selectedValue);
        setBookRoom((prevState) => ({ ...prevState, identity: selectedValue }));
    };
    
    function formatDate(date:any) {
        const options = { weekday: 'short' as const, year: 'numeric' as const, day: 'numeric' as const };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }
    
    
    return (
        <>

            <div className="previewContainer  wc-add-paddin">
                <form action="" className='Form-content'>
                    <div className="wform-MyContentControl-module__edgeworth-stack-item-container-refresh__3mUvv wform-css-283 ">
                        <span>Guest Information:</span>
                        <div className="ui-divider  ua-gi-ub-uc">

                            <div className="wc-form-wrapper">                  
                                <div className="w-add-Guest-room Guest-management-job">
                                    <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">Gender</div>
                                        <div className="firstName-container KEMNHE_UU787">
                                        <Dropdown
                                          placeholder="Select a gender"
                                          appearance="filled-darker"
                                          name="gender"
                                          onOptionSelect={(item) => handleServiceChange("gender", item)}
                                      >
                                          {genderMapping.map((item) => (
                                              <Option key={item.genderId} value={item.genderName}>{item.genderName}</Option>
                                          ))}
                                      </Dropdown>

                                        </div>
                                    </div>
                                    <div className="w-add-room-guest rms-block-right">
                                        <div className="w-add-room-guest rms-inline-property-title">Title</div>
                                        <div className="firstName-container KEMNHE_UU787">
                                        <Dropdown
                                            placeholder="Select a Title"
                                            appearance="filled-darker"
                                            name="title"
                                            onOptionSelect={(item) => handleServiceChange("title", item)}
                                          >
                                            {titleMapping.map((item) => (
                                                <Option key={item.titleId} value={item.titleName}>{item.titleName}</Option>
                                            ))}
                                          </Dropdown>
                                      
                                        </div>
                                    </div>
                                </div>

                                <div className="w-add-Guest-room Guest-management-job">
                                    <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">First Name</div>
                                        <div className="firstName-container">
                                            <Input placeholder='First Name'
                                             appearance="filled-darker"
                                              id="firstName"
                                              name="firstName"
                                              onChange={handleStateInputChange} />
                                        </div>
                                    </div>
                                    <div className="w-add-room-guest rms-block-right">
                                        <div className="w-add-room-guest rms-inline-property-title">Last Name</div>
                                        <div className="firstName-container">
                                            <Input appearance="filled-darker"
                                             placeholder='Last Name'
                                              id="lastName"
                                              name="lastName"
                                              onChange={handleStateInputChange} />
                                        </div>
                                    </div>
                                </div>


                                <div className="w-add-Guest-room Guest-management-job">
                                    <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">Date Of Birth</div>
                                        <div className="firstName-container">
                                            <Field>
                                                <DatePicker
                                                    name="dateofbirth"
                                                    showWeekNumbers={true}
                                                    firstWeekOfYear={1}
                                                    showMonthPickerAsOverlay={true}
                                                    placeholder="Date of birth..."
                                                    className={styles.control}
                                                    onSelectDate={(date) => handleDateInputChange('dateofbirth')(date)}
                                                />
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="w-add-room-guest rms-block-right">
                                        <div className="w-add-room-guest rms-inline-property-title">Number Of Room</div>
                                        <div className="firstName-container">
                                        <Input appearance="filled-darker"
                                             placeholder='Last Name'
                                              id="numberOfRoom"
                                              name="numberOfRoom"
                                              onChange={handleStateInputChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-add-Guest-room Guest-management-job">
                                    <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">Address</div>
                                        <div className="firstName-container">
                                        <Input appearance="filled-darker" 
                                            placeholder='Address'
                                             id="address"
                                             name="address"
                                             onChange={handleStateInputChange} />
                                        </div>
                                    </div>
                                    <div className="w-add-room-guest rms-block-right">
                                        <div className="w-add-room-guest rms-inline-property-title">Country</div>
                                        <div className="firstName-container">
                                            <Input appearance="filled-darker" 
                                            placeholder='Country'
                                             id="country"
                                             name="country"
                                             onChange={handleStateInputChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-add-Guest-room Guest-management-job">
                                    <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">Room Number</div>
                                        <div className="firstName-container">
                                            <Input placeholder='Room Number' 
                                            appearance="filled-darker"
                                            value={selected.number}
                                             disabled
                                             id="roomNumber"
                                             name="roomNumber"
                                             onChange={handleStateInputChange} />
                                        </div>
                                    </div>
                                    <div className="w-add-room-guest rms-block-right">
                                        <div className="w-add-room-guest rms-inline-property-title">City</div>
                                        <div className="firstName-container">
                                            <Input appearance="filled-darker"
                                             placeholder='City' 
                                             id="city"
                                             name="city"
                                             onChange={handleStateInputChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-add-Guest-room Guest-management-job">
                                    <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">Phone Number</div>
                                        <div className="firstName-container">
                                            <Input placeholder='Phone Number' 
                                            appearance="filled-darker"
                                             id="phone"
                                             name="phone"
                                             onChange={handleStateInputChange} />
                                        </div>
                                    </div>
                                    <div className="w-add-room-guest rms-block-right">
                                        <div className="w-add-room-guest rms-inline-property-title">Number Of Children</div>
                                        <div className="firstName-container">
                                            <Input
                                            type="number" appearance="filled-darker"
                                             placeholder='Number of children' 
                                             id="numberOfChildren"
                                             name="numberOfChildren"
                                             onChange={handleStateInputChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-add-Guest-room Guest-management-job">
                                    <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">Number Of adult</div>
                                        <div className="firstName-container">
                                            <Input placeholder='Number Of Adult'
                                             appearance="filled-darker"
                                              id="numberOfadult"
                                              name="numberOfadult"
                                              onChange={handleStateInputChange} />
                                        </div>
                                    </div>
                                    <div className="w-add-room-guest rms-block-right">
                                        <div className="w-add-room-guest rms-inline-property-title">Email</div>
                                        <div className="firstName-container">
                                            <Input appearance="filled-darker"
                                             placeholder='Email' 
                                             id="email"
                                             name="email"
                                             onChange={handleStateInputChange} />
                                        </div>
                                    </div>
                                </div>

                                <div className="w-add-Guest-room Guest-management-job">
                                    <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">Booking Sources</div>
                                        <div className="firstName-container">
                                            <Input placeholder='source'
                                             appearance="filled-darker"
                                              id="source"
                                              name="source"
                                              onChange={handleStateInputChange} />
                                        </div>
                                    </div>
                                    <div className="w-add-room-guest rms-block-right">
                                        <div className="w-add-room-guest rms-inline-property-title">Guest status</div>
                                        <div className="firstName-container">
                                            <Input appearance="filled-darker"
                                             placeholder='Vip/None'
                                              id="guestStatus"
                                              name="guestStatus" 
                                              onChange={handleStateInputChange}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-add-Guest-room Guest-management-job">
                                    <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">Id Type</div>
                                        <div className="firstName-container">
                                        <Select
                                              id="identity"
                                              name="identity"
                                              onChange={handleCheckingDropdowInputChange(
                                                'identity'
                                              )}
                                              appearance="filled-darker"
                                            >
                                              <option>Select ...</option>
                                              <option>National ID Card</option>
                                              <option>Driver's License</option>
                                              <option>Passport</option>
                                              <option>Employee ID Card</option>
                                              <option>Student ID Card</option>
                                              <option>Loyalty Card</option>
                                              <option>Voter ID Card</option>
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="w-add-room-guest rms-block-right">
                                        <div className="w-add-room-guest rms-inline-property-title">ID Number</div>
                                        <div className="firstName-container">
                                            <Input appearance="filled-darker"
                                             placeholder='ID Number'
                                              id="idCardNumber"
                                              name="idCardNumber" 
                                              onChange={handleStateInputChange}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-add-Guest-room Guest-management-job">
                                    <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">Issues Date</div>
                                        <div className="firstName-container">
                                            <Field>
                                                <DatePicker
                                                    name="issuesdate"
                                                    showWeekNumbers={true}
                                                    firstWeekOfYear={1}
                                                    showMonthPickerAsOverlay={true}
                                                    placeholder="Issues Date..."
                                                    className={styles.control}
                                                    onSelectDate={(date) => handleDateInputChange('issuesdate')(date)}
                                                />
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="w-add-room-guest rms-block-right">
                                        <div className="w-add-room-guest rms-inline-property-title">Expire Date</div>
                                        <div className="firstName-container">
                                            <Field>
                                                <DatePicker
                                                    name="expiredate"
                                                    showWeekNumbers={true}
                                                    firstWeekOfYear={1}
                                                    showMonthPickerAsOverlay={true}
                                                    placeholder="Expire Date..."
                                                    className={styles.control}
                                                    onSelectDate={(date) => handleDateInputChange('expiredate')(date)}                                                 
                                                />
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-add-Guest-room Guest-management-job">
                                    <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">Nationality</div>
                                        <div className="firstName-container">
                                            <Input placeholder='Nationality'
                                             appearance="filled-darker"
                                              id="nationality"
                                              name="nationality"
                                              onChange={handleStateInputChange} />
                                        </div>
                                    </div>
                                    <div className="w-add-room-guest rms-block-right">
                                        <div className="w-add-room-guest rms-inline-property-title">Language</div>
                                        <div className="firstName-container">
                                            <Input appearance="filled-darker"
                                             placeholder='First Language'
                                              id="language"
                                              name="language"
                                              onChange={handleStateInputChange}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-add-Guest-room Guest-management-job">
                                    <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">Checking Date</div>
                                        <div className="firstName-container">
                                            <Field>
                                                <DatePicker
                                                    name="arrivalDate"
                                                    showWeekNumbers={true}
                                                    firstWeekOfYear={1}
                                                    showMonthPickerAsOverlay={true}
                                                    placeholder="Checking Date..."
                                                    className={styles.control}
                                                    onSelectDate={(date) => handleDateInputChange('arrivalDate')(date)}   
                                                  
                                                />
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="w-add-room-guest rms-block-right">
                                        <div className="w-add-room-guest rms-inline-property-title">Checkout Date</div>
                                        <div className="firstName-container">
                                            <Field>
                                                <DatePicker
                                                    name="departureDate"
                                                    showWeekNumbers={true}
                                                    firstWeekOfYear={1}
                                                    showMonthPickerAsOverlay={true}
                                                    placeholder="Checking out..."
                                                    className={styles.control}
                                                    onSelectDate={(date) => handleDateInputChange('departureDate')(date)}   
                                                />
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>

                    </div>
                    <div className="wform-MyContentControl-module__edgeworth-stack-item-container-refresh__3mUvv wform-css-283-right">
                    <div className="sbdocs-preview wc-room-details">
                       <div className="xt-azure-datagrid-container REX-Guest paddin-table ext-azure-subscription-grid-cell fxc-base fxc-gc-vscroll fxc-gc-hscroll fxc-gc-fixture fxs-vivaresize">
                        <Card className={styles.card}>
                            <CardPreview
                                className={styles.grayBackground}
                                logo={
                                    <img
                                        className='wc-imanage-image'
                                        // src={resolveAsset('9015_whitehouseimage?AWSAccessKeyId=AKIAWD6U5GWRA5SIAJWA&Expires=1724692914&Signature=BNspr%2FdClwQiZFUsMbUc0r6reZs%3D')}
                                        src={roomDetailsMapping?.roomPhotos}
                                        alt="room"
                                        width="600px"
                                        height="300px" />
                                }
                            >
                            </CardPreview>
                        </Card>
                        <div className="grid-container">
    <div className="grid-item"><strong>Room ID:</strong>{roomDetailsMapping?.id}</div>
    <div className="grid-item"><strong>Room Number:</strong>{roomDetailsMapping?.roomId}</div>
    <div className="grid-item"><strong>Room Name:</strong>{roomDetailsMapping?.roomName}</div>
    <div className="grid-item"><strong>Room Location:</strong>{roomDetailsMapping?.roomLocation}</div>
    <div className="grid-item"><strong>Room Type:</strong>{roomDetailsMapping?.roomType}</div>
    <div className="grid-item"><strong>Description:</strong>{roomDetailsMapping?.description}</div>
    <div className="grid-item"><strong>Room Level:</strong>{roomDetailsMapping?.roomLevel}</div>
    <div className="grid-item"><strong>Room Status:</strong>{roomDetailsMapping?.roomStatus}</div>
    <div className="grid-item"><strong>Room Maintenance:</strong>{roomDetailsMapping?.roomMaintenance}</div>
    <div className="grid-item"><strong>Temperature:</strong>{roomDetailsMapping?.roomTemperature}</div>
    <div className="grid-item"><strong>Safe Status:</strong>{roomDetailsMapping?.roomSafeStatus}</div>
    <div className="grid-item"><strong>Call Requests:</strong>{roomDetailsMapping?.callRequests}</div>
    <div className="grid-item"><strong>Laundry Status:</strong>{roomDetailsMapping?.laundryStatus}</div>
    <div className="grid-item"><strong>Do Not Disturb:</strong>{roomDetailsMapping?.doNotDisturbStatus}</div>
    <div className="grid-item"><strong>Guest Messages:</strong>{roomDetailsMapping?.guestMessages}</div>
    <div className="grid-item"><strong>Housekeeping Requests:</strong>{roomDetailsMapping?.housekeepingRequests}</div>
    <div className="grid-item"><strong>Guest Preferences:</strong>{roomDetailsMapping?.guestPreferences}</div>
    <div className="grid-item"><strong>Room Features:</strong>{roomDetailsMapping?.roomFeatures}</div>
    <div className="grid-item"><strong>Room Packages:</strong>{roomDetailsMapping?.roomPackages}</div>
    <div className="grid-item"><strong>Service Orders:</strong>{roomDetailsMapping?.roomServiceOrders}</div>
    <div className="grid-item"><strong>Minibar Inventory:</strong>{roomDetailsMapping?.minibarInventory}</div>
    <div className="grid-item"><strong>Connecting Room:</strong>{roomDetailsMapping?.connectingRoomInformation}</div>
    <div className="grid-item"><strong>Special Occasion:</strong>{roomDetailsMapping?.specialOccasionDetails}</div>
    <div className="grid-item"><strong>Cleaning Schedule:</strong>{roomDetailsMapping?.roomCleaningSchedule && formatDate(new Date(roomDetailsMapping.roomCleaningSchedule))}</div>
    <div className="grid-item"><strong>Events Attractions:</strong>{roomDetailsMapping?.localEventsAttractions}</div>
    <div className="grid-item"><strong>Noise Level:</strong>{roomDetailsMapping?.noiseLevel}</div>
    <div className="grid-item"><strong>Availability Calendar:</strong>{roomDetailsMapping?.roomAvailabilityCalendar}</div>
    <div className="grid-item"><strong>Status Timestamps:</strong>{roomDetailsMapping?.roomStatusTimestamps && formatDate(new Date(roomDetailsMapping.roomStatusTimestamps))}</div>
    <div className="grid-item"><strong>Maintenance History:</strong>{roomDetailsMapping?.roomMaintenanceHistory}</div>
    <div className="grid-item"><strong>Temperature Control:</strong>{roomDetailsMapping?.roomTemperatureControl}</div>
    <div className="grid-item"><strong>Safe Status:</strong>{roomDetailsMapping?.roomInRoomSafeStatus}</div>
    <div className="grid-item"><strong>Wake Up Call Requests:</strong>{roomDetailsMapping?.wakeUpCallRequests}</div>
    <div className="grid-item"><strong>Accessibility Features:</strong>{roomDetailsMapping?.roomAccessibilityFeatures}</div>
    <div className="grid-item"><strong>Guest Feedback:</strong>{roomDetailsMapping?.guestFeedbackReviews}</div>
    <div className="grid-item"><strong>Room Inventory:</strong>{roomDetailsMapping?.roomInventory}</div>
    <div className="grid-item"><strong>Room Number:</strong>{roomDetailsMapping?.roomNumber}</div>
</div>

                        </div>
                        </div>
                    </div>
                </form>
            </div >


        </>

    )
}


export default GuestForm;