import React, { useCallback, useEffect, useState } from 'react';

import './CheckRequest.scss';
import {
  Avatar,
  Button,
  Field,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Persona,
  Table,
  Tooltip,
} from '@fluentui/react-components';
import {
  CalendarLtrRegular,
  ChevronDownRegular,
  ComposeRegular,
} from '@fluentui/react-icons';
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from '@fluentui/react-components';
import calendericon from '../../Themes/Images/calender.svg';
import { GetEmployee } from '../../../Data/General/Employee';
import axios from 'axios';
import { CHANNEL_ENPOINT, WHITE_HOUSE_API } from '../../../Constants/Constant';
import * as signalR from "@microsoft/signalr";
import { useSignalRConnection } from '../../Hubs/Hubs';
import { IHConnection } from '../../../Data/General/HubConnaction';
import { SearchBox } from '@fluentui/react-search-preview';
interface User {
  email: string;
  phoneNumber: string;
  token: string;
}

interface Message {
  message: string;
  id: number;
}

let iHubConnection: IHConnection = {
  Id: "01",
  Name: "CheckRequest",
};


type Prop = {
  message: Message;
  user: User;
};

const CheckRequest: React.FC<Prop> = ({ user, message }) => {
  const [adjuster, setAdjuster] = useState<GetEmployee>();
  const { connection, channelData } = useSignalRConnection(`${CHANNEL_ENPOINT}/channel-hub`, user.token);

 const [ChannelMessage, setChannelMessage] = useState('');

  const sendChannelMessage = async () => {
    if (connection) {
      try {
        console.log('Message sent!');
      } catch (err) {
        console.log('Error while sending message :(', err);
      }
    }
  };

  const fetchAdjuster = useCallback(async () => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Adjuster/${user.phoneNumber}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const adjuster = response.data;
      setAdjuster(adjuster);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  }, [user.phoneNumber, user.token]);



  useEffect(() => {
    fetchAdjuster();
    console.log("The Returned Data", channelData);
  }, [channelData, fetchAdjuster, message.id]);

  return (
    <>
      <div className="navAndContent_feed">
        <div className="ts-left-rail-wrapper ts-main-flex app-full-viewport-height pull-left left-rail-z-index">
          <div className="ts-left-rail app-rail-common left-rail-box-shadow-overlay left-rail-header-v2-common">
            <div className="left-rail-header-v2">
              <div>
                <div className="active-rail">
                  <div className="show-rail">
                    <div className="left-rail-header-chat">
                      <div className="left-rail-header constant-border">
                      <Field className="MNEHHE">
                        <SearchBox
                            placeholder="Search Requests"
                            appearance="filled-darker"
                           
                        />
                        </Field>
                      </div>
                      <div className="tab-content">
                        <div className="tab-pane left-rail-chat-tab active">
                          <div className="ts-channel-list match-parent chat-list react-chat-list flex-fill">
                            <div className="flex-fill">
                              <div className="YEBOMn">
                                <div className="node_modules--msteams-bridges-components-chat-list-bridge-dist-es-src-chat-list__rootStyle--LAdg7 chat-list-scrollbar MSHHE__ customScrollBar">
                                  <div className="accordion ui ts-left-rail-tree left-rail-top-spacing hide-selected-typing-indicator">
                                    <ul className="chat-list-tree">
                                      <li style={{ flexDirection: 'column' }}>
                                        <div className="active title ts-tree-header left-rail-header-hover">
                                          <div>
                                            <div className="expand-collapse-icon expand-collapse-icon-default">
                                              <ChevronDownRegular />
                                            </div>
                                            <small className="MJKU_U">
                                              Pinned
                                            </small>
                                          </div>
                                        </div>
                                        <div className="content active ts-tree-group">
                                          <div>
                                            <div className="wec-recipient-group-list-item left-rail-item-hover">
                                              <span className="cle-item">
                                                <Persona
                                                  size="small"
                                                  avatar={{
                                                    color: 'colorful',
                                                    'aria-hidden': true,
                                                  }}
                                                  name="All Request"
                                                  presence={{
                                                    status: 'available',
                                                  }}
                                                  secondaryText="New booking request list.."
                                                />
                                                <span className="cle-timestamp cle-margin hide-on-hover app-font-caption">
                                                  12:07 PM
                                                </span>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li style={{ flexDirection: 'column' }}>
                                        <div className="active title MEHT_PUSHT ts-tree-header left-rail-header-hover">
                                          <div>
                                            <div className="expand-collapse-icon expand-collapse-icon-default">
                                              <ChevronDownRegular />
                                            </div>
                                            <small className="MJKU_U">
                                              Recent
                                            </small>
                                          </div>
                                        </div>
                                        <div className="content active ts-tree-group">
                                          <div>
                                            <div className="wec-recipient-group-list-item left-rail-item-hover left-rail-selected">
                                              <span className="cle-item">
                                                <div className="fui-Persona rlroi9i">
                                                  <span
                                                    role="img"
                                                    id="avatar-r31"
                                                    className="fui-Avatar r81b29z fui-Persona__avatar ___1hczxo9_h8bja00 f1w9dchk fxldao9 fy9rknc f1hek2iy f9h729m f18q9vkd f103ycu4"
                                                  >
                                                    <span
                                                      aria-hidden="true"
                                                      className="fui-Avatar__icon rip04v ___1nylqdb_1yztgl5 fe5j1ua f11d4kpn MAKEHY f18f03hv"
                                                    >
                                                      <CalendarLtrRegular className="WECR" />
                                                    </span>
                                                  </span>
                                                  <span className="fui-Persona__primaryText rvj41k9">
                                                    Jude Iloelunachi
                                                  </span>
                                                  <span className="fui-Persona__secondaryText rp1pf9e ___19lp2a0_1p5a1yj f1ryq6si">
                                                    New booking request
                                                  </span>
                                                </div>
                                                <span className="cle-timestamp cle-margin hide-on-hover app-font-caption">
                                                  12:07 PM
                                                </span>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ts-middle ts-main-flex pointer-events-auto hybrid-passthrough">
        <div className="flex-fill">
          <div className="ts-middle-pane flex-fill">
            <div className="messages-header flex-fill">
              <div className="ts-messages-header messages-header-v2">
                <div className="app-messages-header ts-section-divider">
                  <div className="app-chat-header-content">
                    <Avatar
                      icon={<CalendarLtrRegular className="WECR" />}
                      aria-label="Meeting"
                    />
                    <div className="meeting-content">
                      <div className="title ts-sub-title">
                        <div className="title-icon" id="meetingtitle">
                          <div className="title-text">
                            <span
                              id="chat-header-title"
                              className="title-content-style"
                            >
                              Jude Iloelunachi{' '}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ts-tab-content flex-fill">
                <div className="ts-embedded-container flex-fill hybrid-passthrough">
                    <div className="content-child flex-fill">
                        <div className="XbIp4 jmmB7 GNqVo allowTextSelection OuGoX">
                           <div className="rps_206f">
                            <div>
                                {/* Message */}
                               
                            </div>
                           </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckRequest;
