import React, { useCallback, useEffect, useRef, useState } from 'react';
import './Landing.scss';
import {
  bundleIcon,
  CalendarMonthFilled,
  CalendarMonthRegular,
  StarEdit24Regular,
  PeopleTeam24Regular,
  ChevronRight24Regular,
  AccessibilityCheckmark24Filled,
  PeopleCommunityAdd24Filled,
  List20Regular,
  PeopleCommunity24Filled,
  PeopleTeam24Filled,
  PersonClock24Filled,
  PersonWalking24Filled,
  ChevronLeft24Regular,
  Share24Regular,
  CatchUp24Regular,
  Home24Filled,
  HomePerson24Filled,
  ArrowTrendingLines24Regular,
  Home24Regular,
} from '@fluentui/react-icons';
import {
  InteractionTag,
  InteractionTagPrimary,
  InteractionTagSecondary,
} from '@fluentui/react-tags-preview';

import {
  Button,
  Divider,
  makeStyles,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Persona,
  Tooltip,
  Field,
  Popover,
  PopoverTrigger,
  PopoverSurface,
  Card,
  CardPreview,
  Avatar,
  Tag,
} from '@fluentui/react-components';
import ImageOutgoing from '../../Themes/Images/Stock_down_orange_72x72.png';
import ImageIncoming from '../../Themes/Images/Finance_stock_up_green_72x72.png';
import Carousel from '../../Common/Carousel/Sliders';
import Donuts from '../../Common/Chart/DonutChart/DonutGuest';
import DonutsReservation from '../../Common/Chart/DonutChart/DonutReservation';
import RevenueChart from '../../Common/Chart/Revenue/Revebue';
import BarAndMeal from '../../Common/Chart/Revenue/BarAndMeal';
import ReuseAble from '../Vendors/ReUseableComponent';
import EventReservation from '../EventReservation/EventReservation';
import RoomReservation from '../RoomReservation/RoomReservation';
import ViewTask from '../ViewTask/ViewTask';
import ViewStatus from '../ViewCheckedInStatus/ViewStatus';
import { CHANNEL_ENPOINT, WHITE_HOUSE_API } from '../../../Constants/Constant';
import axios from 'axios';
import GuestView from '../GuestView/GuestView';
import { IStays, IDepartures } from '../../../Data/General/StaysOrDeparture';
import { SearchBox } from '@fluentui/react-search-preview';
import { DailyCounts } from '../../../Data/General/DailyCounts';
import { ChannelSlide, ChannelSlideOut } from '../../../Data/General/ChannelSlide';
import { useSignalRConnection } from '../../Hubs/LandingHub';
import * as signalR from '@microsoft/signalr';
interface SelectedRoom {
  number: any;
  name: any;
}

interface Message {
  message: string;
  id: number;
}

interface User {
  email: string;
  phoneNumber: string;
  token: string;

  // other properties...
}
type Props = {
  message: Message;
  user: User;
};

const Landing: React.FC<Props> = (props) => {
  const containerRef = useRef<HTMLUListElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [dates, setDates] = useState<Date[]>([]);
  const [isToday, setIsToday] = useState<boolean>(false);
  const [isOccupancy, setIsOccupancy] = useState<boolean>(true);
  const [closeReUseablePopUp, setReUseablePopUp] = useState<boolean>(false);
  const [roomDetails, setRoomDetailsId] = useState<SelectedRoom>({
    number: 0,
    name: '',
  });
  const [reserveEventReserve, setReserveEventReserve] =
    useState<boolean>(false);
  const [customerEventId, setCustomerEventId] = useState<any>();
  const [bookingServiceId, setBookingServiceId] = useState<any>();
  const [pamentOpenMode, setPaymentOpenMode] = useState<boolean>(false);
  const [reserveRoom, setReserveRoom] = useState<boolean>(false);
  const [handleTask, setHandleTask] = useState<boolean>(false);
  const [viewStatusConrol, setViewStatusControl] = useState<boolean>(false);
  const [currentClickedDate, setCurrentClickedDate] = useState(null);
  const [checkedCount, setCheckedCount] = useState<number[] | null>(null);
  const [handleGuestView, setHandleGuestView] = useState<boolean>(false);
  const [loadepartures, setLoadDeparture] = useState<IDepartures[]>([]);
  const [channelcheckedOutData, setChannelCheckOutData] = useState<ChannelSlideOut[]>([]);
  const [channelCheckedInData, setChannelCheckedInData] = useState<ChannelSlide[]>([]);
  const [connection, setConnection] = useState<signalR.HubConnection | null>(null);
  const [channelData, setChannelData] = useState<string>("");
 const url = `${CHANNEL_ENPOINT}/channel-hub`;
  const [loadstays, setLoadStays] = useState<IStays[]>([]);
  const [loadStatusCounts, setLoadStatusCounts] = useState<number>(0);
  const [loadAdultCounts, setLoadAdultCounts] = useState<number>(0);
  const [loadChildrenCounts, setLoadChildrenCounts] = useState<number>(0);
  const [loadCheckedOutCounts, setLoadCheckedOutCounts] = useState<number>(0);
  const [loadJustArrivedCounts, setLoadJustArrivedCounts] = useState<number>(0);
  const [loadNotSpacifiedCounts, setLoadNotSpacifiedCounts] = useState<number>(0);
  const [loadDailyCount, setLoadDailyCounts] = useState<DailyCounts[]>([]);
  const date = new Date();
  const day = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ][date.getDay()];
  const formattedDate = `${day} ${date.getDate()}, ${date.getFullYear()}`;
  const [searchStays, setSearchStays] = useState('');
  const [searchDeprture, setSearchDepartures] = useState('');

  const filteredStays = loadstays.filter((i) =>
    `${i.firstName} ${i.lastName} R${i.roomNumber} - ${i.roomName}`
      .toLowerCase()
      .includes(searchStays.toLowerCase())
  );

  const filteredDepartures = loadepartures.filter((i) =>
    `${i.firstName} ${i.lastName} R${i.roomNumber} - ${i.roomName}`
      .toLowerCase()
      .includes(searchDeprture.toLowerCase())
  );
  const { message, user } = props;
  const handleOccupancyClick = () => {
    setIsOccupancy(true);
  };
  // const { connection, channelData} = useSignalRConnection(`${CHANNEL_ENPOINT}/channel-hub`, user.token);
 





  const fetchCounters = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/CheckedInOrBooked`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setCheckedCount(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);

  const fetchAdulCounts = useCallback(async () => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Checkin/WithAdultsTodayCount`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setLoadAdultCounts(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);

  const fetchChildrenCounts = useCallback(async () => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Checkin/WithChildrenTodayCount`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setLoadChildrenCounts(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);

  const fetchJustDepartedCounts = useCallback(async () => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Checkin/JustDepartedCount`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setLoadCheckedOutCounts(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);

  const fetchJustArrivedCount = useCallback(async () => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Checkin/JustArrivedCount`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setLoadJustArrivedCounts(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);

  const fetchNotSpecifiedTodayCount = useCallback(async () => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Checkin/NotSpecifiedTodayCount`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setLoadNotSpacifiedCounts(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);

  const fetchStays = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/Checkin/Stays`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setLoadStays(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);

  const fetchDepartures = useCallback(async () => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Checkin/Departures`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setLoadDeparture(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);

  const handleBarAndMealClick = () => {
    setIsOccupancy(false);
  };

  function isSameDay(date1: Date, date2: Date) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  const handleScroll = (direction: any) => {
    if (containerRef.current) {
      const scrollAmount = direction === 'left' ? -100 : 100;
      containerRef.current.style.transform = `translateX(${
        scrollPosition + scrollAmount
      }px)`;
      setScrollPosition(scrollPosition + scrollAmount);
    }
  };

  const fetchNotSpecifiedTodayById = useCallback(async () => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Checkin/NotSpecifiedToday`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setLoadDailyCounts(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);

  const fetchWithChildrensToday = useCallback(async () => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Checkin/WithChildrensToday`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setLoadDailyCounts(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);

  const fetchWithAdultsToday = useCallback(async () => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Checkin/WithAdultsToday`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setLoadDailyCounts(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);


  const fetchCheckinSliaderall = useCallback(async () => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Checkin/CheckinSliders`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setChannelCheckedInData(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);


  const fetchCheckOutSliders = useCallback(async () => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Checkin/CheckOutliders`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setChannelCheckOutData(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);




  const fetchIsCheckedOut = useCallback(async () => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Checkin/CheckedOut`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setLoadDailyCounts(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);

  const fetchJustArrived = useCallback(async () => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Checkin/JustArrived`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setLoadDailyCounts(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);

  const handleShowItem = (id: any) => {
    switch (id) {
      case 1:
        fetchNotSpecifiedTodayById();
        break;
      case 2:
        fetchWithChildrensToday();
        break;
      case 3:
        fetchWithAdultsToday();
        break;
      case 4:
        fetchIsCheckedOut();
        break;
      case 5:
        fetchJustArrived();
        break;
      default:
        console.log('Invalid id');
    }
  };

  const handleDialogReUseAblePopOpen = () => {
    setReUseablePopUp(true);
  };

  const handleDialogReUseAblePopClose = () => {
    setReUseablePopUp(false);
  };

  const handleEventOpenReservation = () => {
    setReserveEventReserve(true);
  };

  const handleEventCloseReservation = () => {
    setReserveEventReserve(false);
  };

  const OnPaymentOpen = () => {
    setPaymentOpenMode(true);
  };

  const OnPaymentCloses = () => {
    setPaymentOpenMode(false);
  };

  const HandleReserveRoomOpen = () => {
    setReserveRoom(true);
  };

  const HandleReserveRoomClose = () => {
    setReserveRoom(false);
  };

  const openTaskHandler = () => {
    setHandleTask(true);
  };

  const CloseTaskHandler = () => {
    setHandleTask(false);
  };
  const HandleOpenView = (date: any) => {
    setViewStatusControl(true);
    setCurrentClickedDate(date);
  };
  const HandleCloseView = () => {
    setViewStatusControl(false);
  };

  const closeVGuestDetail = () => {
    setHandleGuestView(false);
  };

  const handleOpenClick = (item: any) => {
    message.id = item;
    console.log("StayId", item);
    setHandleGuestView(true);
  };


  useEffect(() => {
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(url,{ 
          withCredentials: false,
          accessTokenFactory: async () => user.token,
          transport: signalR.HttpTransportType.WebSockets,
          skipNegotiation: true,
       })
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  }, [user.token, url]);

  useEffect(() => {
    const today = new Date();
    const dates = Array.from({ length: 10 }, (_, i) => {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      return date;
    });
    setDates(dates);
    fetchCounters();
    fetchDepartures();
    fetchAdulCounts();
    fetchChildrenCounts();
    fetchJustDepartedCounts();
    fetchJustArrivedCount();
    fetchNotSpecifiedTodayCount();
    fetchCheckOutSliders();
    fetchCheckinSliaderall();
    fetchStays();
    if (connection) {
      connection.start()
        .then(() => {
          console.log('Connection started!');
        })
        .catch(err => console.log('Error while establishing connection :(', err));
        
      connection.on("ChannelMessage", (data) => {
        console.log('Data:', data);
        setChannelData(data.Channels);
        setChannelCheckOutData(data.CheckedOut);
        setChannelCheckedInData(data.CheckedIn);
      });
  
      connection.onreconnecting(error => {
        console.log(`Connection lost due to error "${error}". Reconnecting.`);
      });
  
      connection.onreconnected(connectionId => {
        console.log(`Connection reestablished. Connected with connectionId "${connectionId}".`);
      });
    }
  
    return () => {
      if (connection) {
        connection.stop();
      }
    };
  }, [connection, fetchAdulCounts, fetchCheckOutSliders, fetchCheckinSliaderall, fetchChildrenCounts, fetchCounters, fetchDepartures, fetchJustArrivedCount, fetchJustDepartedCounts, fetchNotSpecifiedTodayCount, fetchStays]);




  const checkedInItems = channelCheckedInData ? channelCheckedInData.map((i:any, index:any) => {
    const date = new Date(i?.arrivalDate);
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const weekday = weekdays[date.getDay()];
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedDate = `${weekday} ${day}, ${year} ${hours}:${minutes}`;
return (
 <div className="quote-item">
 <div className="left">
   <div className="symb">
     {i.name}
     <img className="notf-icon" src={ImageIncoming} alt="" />
   </div>
   <div className="left-bottom">
     <div className="notf-text">{`${i.roomName} (${i.roomNumber})`}</div>
   </div>
 </div>
 <div className="right">
   <div className="prct-change">{formattedDate}</div>
   <div className="price">Amount paid: {`${i.amount?.toLocaleString()}`}</div>
 </div>
</div>
)}) : [];
  
  const checkedOutItems = channelcheckedOutData ? channelcheckedOutData.map((m:any, ind:any) => {
    const date = new Date(m?.departureDate);
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const weekday = weekdays[date.getDay()];
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedDate = `${weekday} ${day}, ${year} ${hours}:${minutes}`;
return (
 <div key={ind} className="quote-item">
 <div className="left">
   <div className="symb">
     {m.name}
     <img className="notf-icon" src={ImageOutgoing} alt="" />
   </div>
   <div className="left-bottom">
     <div className="notf-text">{`${m.roomName} (${m.roomNumber})`}</div>
   </div>
 </div>
 <div className="right">
   <div className="prct-change">{formattedDate}</div>
   <div className="price">Amount paid: {`${m.amount?.toLocaleString()}`}</div>
 </div>
</div>
)}) : [];
   const items = [...checkedInItems, ...checkedOutItems];
  
 

  return (
    <>
      <div className="navAndContent_feed LSX89j">
        <div className="ts-left-rail-wrapper ts-main-flex app-full-viewport-height pull-left left-rail-z-index MHR98">
          <div className="ts-left-rail app-rail-common left-rail-box-shadow-overlay left-rail-header-v2-common">
            <div className="left-rail-header-v2">
              <div className="active-rail">
                <div className="show-rail">
                  <div className="left-rail-header-chat">
                    <div className="left-rail-header constant-border MSY67">
                      <Menu>
                        <MenuTrigger disableButtonEnhancement>
                          <MenuButton appearance="transparent">
                            Reservations
                          </MenuButton>
                        </MenuTrigger>

                        <MenuPopover>
                          <MenuList>
                            {/* <MenuItem>Check In</MenuItem>
                            <MenuItem>Checked Out</MenuItem> */}
                          </MenuList>
                        </MenuPopover>
                      </Menu>
                      <Menu>
                        <MenuTrigger disableButtonEnhancement>
                          <MenuButton
                            icon={<List20Regular />}
                            appearance="transparent"
                          ></MenuButton>
                        </MenuTrigger>

                        <MenuPopover>
                          <MenuList>
                            {/* <MenuItem>Task</MenuItem>
                            <MenuItem>Restuarants</MenuItem>
                            <MenuItem>Laundry</MenuItem> */}
                          </MenuList>
                        </MenuPopover>
                      </Menu>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-channel_sidebar p-channel_sidebar--classic_nav p-channel_sidebar--iap1 p-channel_sidebar--dimming p-channel_sidebar--contain customScrollBar">
            <div className="p-channel_sidebar__list">
              <div className="wrap">
                <div className="vertical-watchlist ">
                  <Carousel items={items} />
                </div>

                <div className="wc-donut-wrapper">
                  <Accordion defaultOpenItems="1">
                    <AccordionItem value="1">
                      <AccordionHeader>
                        <Divider>Guest Status</Divider>
                      </AccordionHeader>
                      <AccordionPanel className="Add-height-chart customScrollBar">
                        <div className="lifeDailyInfoContainer">
                          <Donuts user={user} loadAdultCounts={loadAdultCounts} loadCheckedOutCounts={loadCheckedOutCounts} loadNotSpacifiedCounts={loadNotSpacifiedCounts} loadChildrenCounts={loadChildrenCounts} loadJustArrivedCounts={loadJustArrivedCounts} />
                          <Popover withArrow>
                            <PopoverTrigger disableButtonEnhancement>
                              <button
                                onClick={() => handleShowItem(1)}
                                className="lifeIndexBarWrapper-DS-EntryPoint1-1"
                              >
                                <div className="lifeIndexBarIconContainer-DS-EntryPoint1-1">
                                  <PersonClock24Filled />
                                </div>
                                <div className="lifeIndexBarTextContainer-DS-EntryPoint1-1">
                                  <div className="lifeIndexBarTitle-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                                    Not Spacified
                                  </div>
                                  <div className="lifeIndexBarSummary-DS-EntryPoint1-1">
                                    <span className="lifeIndexBarLevelText-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1 itemValue sunnyDaysText">
                                      {loadNotSpacifiedCounts}
                                    </span>
                                    <span className="lifeIndexItemLevelCircle-DS-EntryPoint1-1 not-spacified"></span>
                                  </div>
                                </div>
                              </button>
                            </PopoverTrigger>

                            <PopoverSurface className="LKEY-93" tabIndex={-1}>
                              {loadDailyCount.map((c) => {
                                return (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <Tag
                                      media={
                                        <Avatar
                                          name={`${c.firstName} ${c.lastName}`}
                                        />
                                      }
                                    >
                                      {`${c.firstName} ${c.lastName}`}
                                    </Tag>
                                    <Tag
                                      shape="circular"
                                      media={
                                        <Avatar
                                          name={c.roomName}
                                          color="colorful"
                                        />
                                      }
                                    >
                                      {c.roomName} ({c.roomNumber})
                                    </Tag>
                                  </div>
                                );
                              })}
                            </PopoverSurface>
                          </Popover>

                          <Popover withArrow>
                            <PopoverTrigger disableButtonEnhancement>
                              <button   onClick={() => handleShowItem(3)} className="lifeIndexBarWrapper-DS-EntryPoint1-1">
                                <div className="lifeIndexBarIconContainer-DS-EntryPoint1-1">
                                  <PeopleCommunity24Filled />
                                </div>
                                <div className="lifeIndexBarTextContainer-DS-EntryPoint1-1">
                                  <div className="lifeIndexBarTitle-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                                    Adult
                                  </div>
                                  <div className="lifeIndexBarSummary-DS-EntryPoint1-1">
                                    <span className="lifeIndexBarLevelText-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1 itemValue sunnyDaysText">
                                      {loadAdultCounts}
                                    </span>
                                    <span className="lifeIndexItemLevelCircle-DS-EntryPoint1-1 adult-status"></span>
                                  </div>
                                </div>
                              </button>
                            </PopoverTrigger>
                            <PopoverSurface className="LKEY-93" tabIndex={-1}>
                              {loadDailyCount.map((c) => {
                                return (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <Tag
                                      media={
                                        <Avatar
                                          name={`${c.firstName} ${c.lastName}`}
                                        />
                                      }
                                    >
                                      {`${c.firstName} ${c.lastName}`}
                                    </Tag>
                                    <Tag
                                      shape="circular"
                                      media={
                                        <Avatar
                                          name={c.roomName}
                                          color="colorful"
                                        />
                                      }
                                    >
                                      {c.roomName} ({c.roomNumber})
                                    </Tag>
                                  </div>
                                );
                              })}
                            </PopoverSurface>
                          </Popover>

                          <Popover withArrow>
                            <PopoverTrigger disableButtonEnhancement>
                              <button   onClick={() => handleShowItem(2)} className="lifeIndexBarWrapper-DS-EntryPoint1-1">
                                <div className="lifeIndexBarIconContainer-DS-EntryPoint1-1">
                                  <PeopleTeam24Filled />
                                </div>
                                <div className="lifeIndexBarTextContainer-DS-EntryPoint1-1">
                                  <div className="lifeIndexBarTitle-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                                    Children
                                  </div>
                                  <div className="lifeIndexBarSummary-DS-EntryPoint1-1">
                                    <span className="lifeIndexBarLevelText-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1 itemValue sunnyDaysText">
                                      {loadChildrenCounts}
                                    </span>
                                    <span className="lifeIndexItemLevelCircle-DS-EntryPoint1-1"></span>
                                  </div>
                                </div>
                              </button>
                            </PopoverTrigger>
                            <PopoverSurface className="LKEY-93" tabIndex={-1}>
                              {loadDailyCount.map((c) => {
                                return (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <Tag
                                      media={
                                        <Avatar
                                          name={`${c.firstName} ${c.lastName}`}
                                        />
                                      }
                                    >
                                      {`${c.firstName} ${c.lastName}`}
                                    </Tag>
                                    <Tag
                                      shape="circular"
                                      media={
                                        <Avatar
                                          name={c.roomName}
                                          color="colorful"
                                        />
                                      }
                                    >
                                      {c.roomName} ({c.roomNumber})
                                    </Tag>
                                  </div>
                                );
                              })}
                            </PopoverSurface>
                          </Popover>

                          <Popover withArrow>
                            <PopoverTrigger disableButtonEnhancement>
                              <button   onClick={() => handleShowItem(5)} className="lifeIndexBarWrapper-DS-EntryPoint1-1">
                                <div className="lifeIndexBarIconContainer-DS-EntryPoint1-1">
                                  <PersonWalking24Filled />
                                </div>
                                <div className="lifeIndexBarTextContainer-DS-EntryPoint1-1">
                                  <div className="lifeIndexBarTitle-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                                    Just Arrived
                                  </div>
                                  <div className="lifeIndexBarSummary-DS-EntryPoint1-1">
                                    <span className="lifeIndexBarLevelText-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                                      {loadJustArrivedCounts}
                                    </span>
                                    <span className="lifeIndexItemLevelCircle-DS-EntryPoint1-1 Just-Arrived-notchecked"></span>
                                  </div>
                                </div>
                              </button>
                            </PopoverTrigger>
                            <PopoverSurface className="LKEY-93" tabIndex={-1}>
                              {loadDailyCount.map((c) => {
                                return (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <Tag
                                      media={
                                        <Avatar
                                          name={`${c.firstName} ${c.lastName}`}
                                        />
                                      }
                                    >
                                      {`${c.firstName} ${c.lastName}`}
                                    </Tag>
                                    <Tag
                                      shape="circular"
                                      media={
                                        <Avatar
                                          name={c.roomName}
                                          color="colorful"
                                        />
                                      }
                                    >
                                      {c.roomName} ({c.roomNumber})
                                    </Tag>
                                  </div>
                                );
                              })}
                            </PopoverSurface>
                          </Popover>

                          <Popover withArrow>
                            <PopoverTrigger disableButtonEnhancement>
                              <button   onClick={() => handleShowItem(4)} className="lifeIndexBarWrapper-DS-EntryPoint1-1">
                                <div className="lifeIndexBarIconContainer-DS-EntryPoint1-1">
                                  <AccessibilityCheckmark24Filled />
                                </div>
                                <div className="lifeIndexBarTextContainer-DS-EntryPoint1-1">
                                  <div className="lifeIndexBarTitle-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                                    Checked Out
                                  </div>
                                  <div className="lifeIndexBarSummary-DS-EntryPoint1-1">
                                    <span className="lifeIndexBarLevelText-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1 itemValue sunnyDaysText">
                                      {loadCheckedOutCounts}
                                    </span>
                                    <span className="lifeIndexItemLevelCircle-DS-EntryPoint1-1 checked-out"></span>
                                  </div>
                                </div>
                              </button>
                            </PopoverTrigger>
                            <PopoverSurface className="LKEY-93" tabIndex={-1}>
                              {loadDailyCount.map((c) => {
                                return (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <Tag
                                      media={
                                        <Avatar
                                          name={`${c.firstName} ${c.lastName}`}
                                        />
                                      }
                                    >
                                      {`${c.firstName} ${c.lastName}`}
                                    </Tag>
                                    <Tag
                                      shape="circular"
                                      media={
                                        <Avatar
                                          name={c.roomName}
                                          color="colorful"
                                        />
                                      }
                                    >
                                      {c.roomName} ({c.roomNumber})
                                    </Tag>
                                  </div>
                                );
                              })}
                            </PopoverSurface>
                          </Popover>
                        </div>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem value="2">
                      <AccordionHeader>
                        <Divider>Regular guests</Divider>
                      </AccordionHeader>
                      <AccordionPanel className="Add-height-chart customScrollBar">
                        <div className="lifeDailyInfoContainer">
                          {/* <DonutsReservation /> */}
                          {/* <button className="lifeIndexBarWrapper-DS-EntryPoint1-1">
                            <div className="lifeIndexBarIconContainer-DS-EntryPoint1-1">
                              <PersonClock24Filled />
                            </div>
                            <div className="lifeIndexBarTextContainer-DS-EntryPoint1-1">
                              <div className="lifeIndexBarTitle-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                                New
                              </div>
                              <div className="lifeIndexBarSummary-DS-EntryPoint1-1">
                                <span className="lifeIndexBarLevelText-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1 itemValue sunnyDaysText">
                                  12
                                </span>
                                <span className="lifeIndexItemLevelCircle-DS-EntryPoint1-1 not-spacified"></span>
                              </div>
                            </div>
                          </button>
                          <button className="lifeIndexBarWrapper-DS-EntryPoint1-1">
                            <div className="lifeIndexBarIconContainer-DS-EntryPoint1-1">
                              <PeopleCommunity24Filled />
                            </div>
                            <div className="lifeIndexBarTextContainer-DS-EntryPoint1-1">
                              <div className="lifeIndexBarTitle-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                                Due In
                              </div>
                              <div className="lifeIndexBarSummary-DS-EntryPoint1-1">
                                <span className="lifeIndexBarLevelText-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1 itemValue sunnyDaysText">
                                  44
                                </span>
                                <span className="lifeIndexItemLevelCircle-DS-EntryPoint1-1 adult-status"></span>
                              </div>
                            </div>
                          </button>
                          <button className="lifeIndexBarWrapper-DS-EntryPoint1-1">
                            <div className="lifeIndexBarIconContainer-DS-EntryPoint1-1">
                              <PeopleTeam24Filled />
                            </div>
                            <div className="lifeIndexBarTextContainer-DS-EntryPoint1-1">
                              <div className="lifeIndexBarTitle-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                                Due Out
                              </div>
                              <div className="lifeIndexBarSummary-DS-EntryPoint1-1">
                                <span className="lifeIndexBarLevelText-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1 itemValue sunnyDaysText">
                                  5
                                </span>
                                <span className="lifeIndexItemLevelCircle-DS-EntryPoint1-1"></span>
                              </div>
                            </div>
                          </button>
                          <button className="lifeIndexBarWrapper-DS-EntryPoint1-1">
                            <div className="lifeIndexBarIconContainer-DS-EntryPoint1-1">
                              <PersonWalking24Filled />
                            </div>
                            <div className="lifeIndexBarTextContainer-DS-EntryPoint1-1">
                              <div className="lifeIndexBarTitle-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                                Just Arrived
                              </div>
                              <div className="lifeIndexBarSummary-DS-EntryPoint1-1">
                                <span className="lifeIndexBarLevelText-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1 itemValue sunnyDaysText">
                                  Not Checked
                                </span>
                                <span className="lifeIndexItemLevelCircle-DS-EntryPoint1-1 Just-Arrived-notchecked"></span>
                              </div>
                            </div>
                          </button>
                          <button className="lifeIndexBarWrapper-DS-EntryPoint1-1">
                            <div className="lifeIndexBarIconContainer-DS-EntryPoint1-1">
                              <AccessibilityCheckmark24Filled />
                            </div>
                            <div className="lifeIndexBarTextContainer-DS-EntryPoint1-1">
                              <div className="lifeIndexBarTitle-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                                Checked Out
                              </div>
                              <div className="lifeIndexBarSummary-DS-EntryPoint1-1">
                                <span className="lifeIndexBarLevelText-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1 itemValue sunnyDaysText">
                                  15
                                </span>
                                <span className="lifeIndexItemLevelCircle-DS-EntryPoint1-1 checked-out"></span>
                              </div>
                            </div>
                          </button> */}
                        </div>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      
      <div className="ts-middle ts-main-flex pointer-events-auto hybrid-passthrough LSX89j">
        <div className="flex-fill">
          <div className="ts-middle-pane flex-fill">
            <div className="messages-header flex-fill">
              <div className="ts-messages-header messages-header-v2">
                <div className="app-messages-header ts-section-divider">
                  <div className="app-chat-header-content">
                    <span className="list homeItemList Wc-list-active">
                      <li className="list-item">
                        <span className="control active-color">
                          <Button icon={<Home24Regular />}>Home</Button>
                        </span>
                      </li>
                    </span>
                    <span className="list homeItemList">
                      <li className="list-item">
                        <span
                          className="control"
                          onClick={handleDialogReUseAblePopOpen}
                        >
                          <span>Checkin Guest</span>
                        </span>
                      </li>
                    </span>
                    <span className="list homeItemList">
                      <li className="list-item">
                        <span
                          className="control"
                          onClick={HandleReserveRoomOpen}
                        >
                          <span>Room Reservation</span>
                        </span>
                      </li>
                    </span>
                    <span className="list homeItemList">
                      <li className="list-item">
                        <span
                          className="control"
                          onClick={handleEventOpenReservation}
                        >
                          <span>Event Reservation</span>
                        </span>
                      </li>
                    </span>
                    <span className="list homeItemList">
                      <li className="list-item">
                        <span className="control" onClick={openTaskHandler}>
                          <span>Ticket Admin</span>
                        </span>
                      </li>
                    </span>
                  </div>
                </div>
              </div>
              <div className="ts-tab-content flex-fill">
                <div className="ts-embedded-container flex-fill hybrid-passthrough">
                  <div className="content-child flex-fill">
                    <div className="wDailyTabCarousel-DS-EntryPoint1-1">
                      <div className="chevron__left-DS-EntryPoint1-2 MEHJ_POSUBE">
                        {scrollPosition < 0 && (
                          <Button
                            onClick={() => handleScroll('right')}
                            icon={<ChevronLeft24Regular />}
                          ></Button>
                        )}
                      </div>
                      <div className="chevron__right-DS-EntryPoint1-2 MEHJ_POSUBE">
                        {scrollPosition > -700 && (
                          <Button
                            onClick={() => handleScroll('left')}
                            icon={<ChevronRight24Regular />}
                          ></Button>
                        )}
                      </div>
                      <ul
                        ref={containerRef}
                        className="wDailyTabContainer-DS-EntryPoint1-1 scrollToLeft"
                      >
                        {dates.map((date, i) => {
                          const dayOfWeek = date.toLocaleDateString(undefined, {
                            weekday: 'short',
                          });
                          const dayOfMonth = date.getDate();
                          const isToday = isSameDay(date, new Date());

                          return (
                            <li
                              onClick={() => HandleOpenView(new Date(date))}
                              key={date.toString()}
                              className={`wDailyTabContent-DS-EntryPoint1-1 ${
                                isToday ? 'wpDailySelected' : ''
                              }`}
                            >
                              <Button className="wDailyTabItem-DS-EntryPoint1-1">
                                <div className="wDailyTabItemTriangle-DS-EntryPoint1-1"></div>
                                <div className="wDailyTabItemContent-DS-EntryPoint1-1">
                                  <div className="wDailyTabWeatherCondition-DS-EntryPoint1-1">
                                    <div className="wDailyTabDayText-DS-EntryPoint1-1">
                                      {isToday ? 'Today' : dayOfWeek}{' '}
                                      {dayOfMonth}
                                    </div>
                                  </div>
                                  <div className="wDailyTabWeatherCondition-DS-EntryPoint1-1">
                                    <div className="wDailyTabDayCondition-DS-EntryPoint1-1">
                                      <Button
                                        icon={
                                          <PeopleTeam24Regular className="wc-people" />
                                        }
                                      ></Button>
                                    </div>
                                    <div className="wDailyTabDayTempHi-DS-EntryPoint1-1 itemValue rainyDaysText">
                                      {checkedCount && checkedCount[i]}
                                    </div>
                                  </div>
                                </div>
                              </Button>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="ywuFU customScrollBar">
                      <div className="emji-LE89">
                        <div className="LETE907">
                          <div className="lifeDayHourPanel-DS-EntryPoint1-1 ___77lcry0 f10pi13n">
                            <div className="WeatherLifeDayHourPanelRoot-DS-EntryPoint1-">
                              <div className="wDailyInfoSection-DS-EntryPoint1-1">
                                <div className="wDailyTitleSection-DS-EntryPoint1-1">
                                  <div className="wDailyTitleSection_Line1-DS-EntryPoint1-1 Management-hideBackground">
                                    <button className="lifeIndexBarWrapper-DS-EntryPoint1-1">
                                      <div className="lifeIndexBarIconContainer-DS-EntryPoint1-1 departires-wc">
                                        <CatchUp24Regular />
                                      </div>
                                      <div className="lifeIndexBarTextContainer-DS-EntryPoint1-1">
                                        <div className="lifeIndexBarTitle-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                                          Stays
                                        </div>
                                        <div className="mini-engabGb">
                                          <input
                                            type="search"
                                            placeholder="Filter..."
                                            onChange={(event) =>
                                              setSearchStays(event.target.value)
                                            }
                                          />
                                        </div>
                                        <div className="lifeIndexBarSummary-DS-EntryPoint1-1">
                                          <span className="lifeIndexBarLevelText-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                                            {formattedDate}
                                          </span>
                                        </div>
                                      </div>
                                    </button>
                                  </div>
                                  <div className="lifeIndexBarWrapper-DS-EntryPoint1-1-display Life-landing-body"></div>
                                </div>
                                <div className="wDailyOverlapSection-DS-EntryPoint1-1 owa-wc  Wc-hieght-scrollable customScrollBar">
                                  {/* start */}
                                  {filteredStays.map((item) => {
                                    return (
                                      <div
                                        key={item.guestId}
                                        className="lifeIndexBarWrapper-DS-EntryPoint1-1 Life-landing-body"
                                        onClick={() =>
                                          handleOpenClick(item.guestId)
                                        }
                                      >
                                        <Persona
                                          className="De-human-Personal"
                                          textAlignment="start"
                                          name={`${item.firstName} ${item.lastName}`}
                                          tertiaryText={`R${item.roomNumber} - ${item.roomName}`}
                                        />
                                      </div>
                                    );
                                  })}

                                  {/* end */}
                                </div>
                                <div className="wDailyChartSection-DS-EntryPoint1-1"></div>
                              </div>
                            </div>
                          </div>
                          <div className="lifeDayHourPanel-DS-EntryPoint1-1 ___77lcry0 f10pi13n">
                            <div className="WeatherLifeDayHourPanelRoot-DS-EntryPoint1-">
                              <div className="wDailyInfoSection-DS-EntryPoint1-1">
                                <div className="wDailyTitleSection_Line1-DS-EntryPoint1-1 Management-hideBackground">
                                  <button className="lifeIndexBarWrapper-DS-EntryPoint1-1">
                                    <div className="lifeIndexBarIconContainer-DS-EntryPoint1-1 stays-wc">
                                      <Share24Regular />
                                    </div>
                                    <div className="lifeIndexBarTextContainer-DS-EntryPoint1-1">
                                      <div className="lifeIndexBarTitle-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                                        Departures
                                      </div>
                                      <div className="mini-engabGb">
                                        <input
                                          type="search"
                                          placeholder="Filter..."
                                          onChange={(event) =>
                                            setSearchDepartures(
                                              event.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="lifeIndexBarSummary-DS-EntryPoint1-1">
                                        <span className="lifeIndexBarLevelText-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                                          {' '}
                                          {formattedDate}
                                        </span>
                                      </div>
                                    </div>
                                  </button>
                                </div>
                                <div className="lifeIndexBarWrapper-DS-EntryPoint1-1-display Life-landing-body"></div>

                                <div className="wDailyOverlapSection-DS-EntryPoint1-1 owa-wc Wc-hieght-scrollable customScrollBar">
                                  {filteredDepartures.map((i) => {
                                    return (
                                      <div
                                        key={i.guestId}
                                        className="lifeIndexBarWrapper-DS-EntryPoint1-1 Life-landing-body"
                                        onClick={() =>
                                          handleOpenClick(i.guestId)
                                        }
                                      >
                                        <Persona
                                          className="De-human-Personal"
                                          textAlignment="start"
                                          name={`${i.firstName} ${i.lastName}`}
                                          tertiaryText={`R${i.roomNumber} - ${i.roomName}`}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>

                                <div className="wDailyChartSection-DS-EntryPoint1-1"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="LETE907">
                          <div className="lifeDayHourPanel-DS-EntryPoint1-1 ___77lcry0 f10pi13n">
                            <div className="WeatherLifeDayHourPanelRoot-DS-EntryPoint1-">
                              <div className="wDailyInfoSection-DS-EntryPoint1-1">
                                <div className="wDailyTitleSection-DS-EntryPoint1-1">
                                  <div className="wDailyTitleSection_Line1-DS-EntryPoint1-1 Management-hideBackground">
                                    <button className="lifeIndexBarWrapper-DS-EntryPoint1-1">
                                      <div className="lifeIndexBarIconContainer-DS-EntryPoint1-1 HouseKeepin-wc">
                                        <HomePerson24Filled />
                                      </div>
                                      <div className="lifeIndexBarTextContainer-DS-EntryPoint1-1">
                                        <div className="lifeIndexBarTitle-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                                          House Keeping
                                        </div>
                                        <div className="lifeIndexBarSummary-DS-EntryPoint1-1">
                                          <span className="lifeIndexBarLevelText-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                                            {formattedDate}
                                          </span>
                                        </div>
                                      </div>
                                    </button>
                                  </div>
                                  <div className="lifeIndexBarWrapper-DS-EntryPoint1-1-display Life-landing-body"></div>
                                </div>
                                <div className="wDailyOverlapSection-DS-EntryPoint1-1 owa-wc  Wc-hieght-scrollable customScrollBar">
                                  <div className="lifeIndexItemsV2-DS-EntryPoint1-1">
                                    <Tooltip
                                      withArrow
                                      content="John Tochukwu Ibene, is cleaning"
                                      relationship="description"
                                    >
                                      <div className="each-items lifeIndexItemV2-DS-EntryPoint1-1">
                                        <div className="lifeIndexItemIcon-DS-EntryPoint1-1">
                                          <span className="RoomName">S22</span>
                                        </div>
                                        <div className="lifeIndexItemCellV2-DS-EntryPoint1-1">
                                          <div className="lifeIndexItemNameV2-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                                            Double Suite
                                          </div>
                                          <div className="lifeIndexItemLevelContainer-DS-EntryPoint1-1">
                                            <span className="lifeIndexItemLevelCircle-DS-EntryPoint1-1 clearning-process"></span>
                                            <span className="lifeIndexItemLevelV2-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                                              Cleaning in progress
                                            </span>
                                          </div>
                                        </div>
                                        <span className="heaight-16px"></span>
                                      </div>
                                    </Tooltip>
                                    <Tooltip
                                      withArrow
                                      content="Lock spoiled, to be Fixed by John Ugwuanyi."
                                      relationship="description"
                                    >
                                      <div className="each-items lifeIndexItemV2-DS-EntryPoint1-1">
                                        <div className="lifeIndexItemIcon-DS-EntryPoint1-1">
                                          <span className="RoomName">T32</span>
                                        </div>
                                        <div className="lifeIndexItemCellV2-DS-EntryPoint1-1">
                                          <div className="lifeIndexItemNameV2-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                                            Twin Suite
                                          </div>
                                          <div className="lifeIndexItemLevelContainer-DS-EntryPoint1-1">
                                            <span className="lifeIndexItemLevelCircle-DS-EntryPoint1-1 Bad-Poor"></span>
                                            <span className="lifeIndexItemLevelV2-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                                              Maintenance required
                                            </span>
                                          </div>
                                        </div>
                                        <span className="heaight-16px"></span>
                                      </div>
                                    </Tooltip>
                                    <Tooltip
                                      withArrow
                                      content="Dirty, to be cleaned by Kelvin Ugwuanyi."
                                      relationship="description"
                                    >
                                      <div className="each-items lifeIndexItemV2-DS-EntryPoint1-1">
                                        <div className="lifeIndexItemIcon-DS-EntryPoint1-1">
                                          <span className="RoomName">S24</span>
                                        </div>
                                        <div className="lifeIndexItemCellV2-DS-EntryPoint1-1">
                                          <div className="lifeIndexItemNameV2-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                                            Single Suite
                                          </div>
                                          <div className="lifeIndexItemLevelContainer-DS-EntryPoint1-1">
                                            <span className="lifeIndexItemLevelCircle-DS-EntryPoint1-1 Cleaning-required"></span>
                                            <span className="lifeIndexItemLevelV2-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                                              Cleaning required
                                            </span>
                                          </div>
                                        </div>
                                        <span className="heaight-16px"></span>
                                      </div>
                                    </Tooltip>
                                    <Tooltip
                                      withArrow
                                      content="House Kept by Kelvin Ugwuanyi  6hrs 54min ago"
                                      relationship="description"
                                    >
                                      <div className="each-items lifeIndexItemV2-DS-EntryPoint1-1">
                                        <div className="lifeIndexItemIcon-DS-EntryPoint1-1">
                                          <span className="RoomName">S34</span>
                                        </div>
                                        <div className="lifeIndexItemCellV2-DS-EntryPoint1-1">
                                          <div className="lifeIndexItemNameV2-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                                            Single Suite
                                          </div>
                                          <div className="lifeIndexItemLevelContainer-DS-EntryPoint1-1">
                                            <span className="lifeIndexItemLevelCircle-DS-EntryPoint1-1 In-good-condition"></span>
                                            <span className="lifeIndexItemLevelV2-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                                              Safe to book
                                            </span>
                                          </div>
                                        </div>
                                        <span className="heaight-16px"></span>
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                                <div className="wDailyChartSection-DS-EntryPoint1-1"></div>
                              </div>
                            </div>
                          </div>

                          <div className="lifeDayHourPanel-DS-EntryPoint1-1 ___77lcry0 f10pi13n">
                            <div className="WeatherLifeDayHourPanelRoot-DS-EntryPoint1-">
                              <div className="wDailyInfoSection-DS-EntryPoint1-1">
                                <div className="wDailyTitleSection_Line1-DS-EntryPoint1-1 Management-hideBackground">
                                  <button className="lifeIndexBarWrapper-DS-EntryPoint1-1">
                                    <div className="lifeIndexBarIconContainer-DS-EntryPoint1-1 stays-wc">
                                      <ArrowTrendingLines24Regular />
                                    </div>
                                    <div className="lifeIndexBarTextContainer-DS-EntryPoint1-1">
                                      <div className="lifeIndexBarTitle-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                                        <Button
                                          size="small"
                                          shape="circular"
                                          className={
                                            isOccupancy
                                              ? 'active-occupancy'
                                              : 'color-normal'
                                          }
                                          onClick={handleOccupancyClick}
                                        >
                                          Occupancy
                                        </Button>
                                      </div>
                                      <div className="lifeIndexBarTitle-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                                        <Button
                                          size="small"
                                          shape="circular"
                                          className={
                                            isOccupancy
                                              ? 'color-normal'
                                              : 'active-occupancy'
                                          }
                                          onClick={handleBarAndMealClick}
                                        >
                                          Bars & Meal
                                        </Button>
                                      </div>
                                      <div className="lifeIndexBarSummary-DS-EntryPoint1-1">
                                        <span className="lifeIndexBarLevelText-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                                          {formattedDate}
                                        </span>
                                      </div>
                                    </div>
                                  </button>
                                </div>
                                <div className="lifeIndexBarWrapper-DS-EntryPoint1-1-display Life-landing-body"></div>
                                <div className="wDailyOverlapSection-DS-EntryPoint1-1 owa-wc Wc-hieght-scrollable customScrollBar">
                                  {isOccupancy ? (
                                    <RevenueChart />
                                  ) : (
                                    <BarAndMeal />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {closeReUseablePopUp && (
        <>
          <div className="fluent-default-layer-host">
            <div className="ms-Layer ms-Layer--fixed root-44">
              <ReuseAble
                onClose={handleDialogReUseAblePopClose}
                InMessage={message.message}
                actionmessage={''}
                actionMessageos={''}
                user={user}
                selected={roomDetails}
              />
            </div>
          </div>
        </>
      )}

      {reserveEventReserve && (
        <>
          <div className="fluent-default-layer-host">
            <div className="ms-Layer ms-Layer--fixed root-44">
              <EventReservation
                onPaymentOpen={(customerId, ServiceId) => {
                  setCustomerEventId(customerId);
                  setBookingServiceId(ServiceId);
                  OnPaymentOpen();
                }}
                user={user}
                onClose={handleEventCloseReservation}
              />
            </div>
          </div>
        </>
      )}

      {reserveRoom && (
        <>
          <div className="fluent-default-layer-host">
            <div className="ms-Layer ms-Layer--fixed root-44">
              <RoomReservation
                onPaymentOpen={(customerId, ServiceId) => {
                  setCustomerEventId(customerId);
                  setBookingServiceId(ServiceId);
                  OnPaymentOpen();
                }}
                user={user}
                onClose={HandleReserveRoomClose}
              />
            </div>
          </div>
        </>
      )}
      {handleTask && (
        <div className="fluent-default-layer-host">
          <div className="ms-Layer ms-Layer--fixed root-44">
            <ViewTask
              onClose={CloseTaskHandler}
              message={message}
              user={user}
            />
          </div>
        </div>
      )}

      {viewStatusConrol && (
        <div className="fluent-default-layer-host">
          <div className="ms-Layer ms-Layer--fixed root-44">
            <ViewStatus
              onClose={HandleCloseView}
              user={user}
              selectedDate={currentClickedDate}
            />
          </div>
        </div>
      )}

      {handleGuestView && (
        <GuestView onClose={closeVGuestDetail} user={user} message={message} />
      )}
    </>
  );
};

export default Landing;
