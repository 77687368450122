import React, {
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Checkbox,
  Dropdown,
  Input,
  useId,
  Label,
  Radio,
  RadioGroup,
  Select,
  Option,
  Persona,
  Combobox,
  PresenceBadgeStatus,
  Field,
  tokens,
  shorthands,
  makeStyles,
  Text,
  Avatar,
  AvatarGroup,
  AvatarGroupItem,
  TabList,
  Button,
  Tab,
  Divider,
  ProgressBar,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableCellLayout,
  Switch,
} from '@fluentui/react-components';
import {
  AccessibilityCheckmark24Filled,
  Checkbox124Filled,
  ChevronLeft24Regular,
  Mail24Filled,
  Payment24Filled,
  PersonAdd24Regular,
  PersonClock24Filled,
  ChevronDown24Regular,
  PeopleCommunity24Filled,
  Check24Filled,
} from '@fluentui/react-icons';
import { RoomDataListItem } from '../../../Data/General/RoomDetail';
import GuestForm from '../Book/BookingForm/GuestForm';
import { SearchBox } from '@fluentui/react-search-preview';
import type { SearchBoxProps } from '@fluentui/react-search-preview';
import axios from 'axios';
import {ReturnMessage, SECRETE_KEY, SUBACCOUNT_CODE, WHITE_HOUSE_API, formatDateinput } from '../../../Constants/Constant';
import { IRoom, Room } from '../../../Data/General/Room';
import { PersonaSize, initializeIcons } from '@fluentui/react';
import './ReUseable.scss';
import Loaders from '../../Common/Loader/Loader';
import CheckInForms from '../Book/Checkin/CheckInForm';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import {
  ICheckinGuest,
  InitializeCheckingGuest,
} from '../../../Data/General/CheckinGuest';
import { InfoLabel, InfoLabelProps } from '@fluentui/react-components/unstable';
import {
  IRType,
  InterfaceReservationType,
} from '../../../Data/General/ReservationType';
import Payment from '../Payment/Payment';
import RSPayment from '../Payment/Paymentrs/RSPayment';
import {
  TableHeader,
  TableHeaderCell,
  useArrowNavigationGroup,
} from '@fluentui/react-components';
import { IGuestCheckedIn } from '../../../Data/General/Guest';
import { IQuery } from '../../../Data/General/IQuery';
import { Employee } from '../../Authentication/ReturnedEmployee';
import { GetEmployee } from '../../../Data/General/Employee';
import { Gender } from '../../../Data/General/IGender';
import { Title } from '../../../Data/General/ITitle';
import DispatchToast from '../../Common/Toast/DispatchToast';
import { TransactionData, calculateTotalChargeAmount, checkTransaction, checkUntilComplete, initializeTransaction, redirectToAuthorizationUrl } from '../Paystack/payStack';
import PaymentLoaders from '../../Common/Loader/PayMentLoder';
import TransactionState from '../../Common/Loader/TransactionState';
import { InternalTransaction } from '../Transactions/InternalTransaction';
import { InitiateTransactions } from '../../../Data/General/InitiateTransaction';

const useStyles = makeStyles({
  main: {
    ...shorthands.gap('16px'),
    display: 'flex',
    flexWrap: 'wrap',
  },

  card: {
    width: '400px',
    maxWidth: '100%',
    minHeight: '250px',
    height: 'fit-content',
  },

  caption: {
    color: tokens.colorNeutralForeground3,
  },

  smallRadius: {
    ...shorthands.borderRadius(tokens.borderRadiusSmall),
  },

  grayBackground: {
    backgroundColor: tokens.colorNeutralBackground3,
  },

  control: {
    maxWidth: '300px',
  },
  wrapper: {
    columnGap: '15px',
    display: 'flex',
    marginBottom: '10px',
  },
  container: {
    ...shorthands.margin('20px', '0px'),
  },
});

interface User {
  email: string;
  phoneNumber: string;
  token: string;

  // other properties...
}

interface SelectedRoom {
  number: any;
  name: any;
}

interface Message {
  message: string;
  id: number;
}

interface Adjuster {
  firstname: string;
  lastname: string;
  phoneNumber: string;
  userid: number;
}



interface ViewRoomProps {
  onClose: () => void;
  InMessage: string;
  actionmessage: string;
  actionMessageos: string;
  user: User;
  selected: SelectedRoom;
}


let Initmessage: ReturnMessage = {
  header: '',
  body: '',
};


const ReuseAble: React.FC<ViewRoomProps> = ({
  onClose,
  InMessage,
  actionMessageos,
  actionmessage,
  user,
  selected,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [showDetails, setshowDetails] = useState(true);
  const ActionText = showForm ? 'Submit' : 'Book Now';
  const [query, setQuery] = useState('');
  //   const [results, setResults] = useState<Room[]>([]);
  const [inComingMessage, setInComingMessage] = useState(InMessage);
  const [isSearchActive, setisSearchActive] = useState(false);
  const [actionMessage, setActionMessage] = useState(actionmessage);
  const [actionMessageTwo, setActionMessageTwo] = useState(actionMessageos);
  const [selectedTab, setSelectedTab] = useState<any>('General Information');
  const [numberofNight, setNumberofNight] = useState<any | null>('');
  const [roomRates, setRoomRate] = useState<any | null>('');
  const [arrivalDate, setArrivalDate] = useState<Date | null>(null);
  const [departureDate, setDepartureDate] = useState<Date | null>(null);

  const [userSelectedRoomNumber, setUserSelectedRoomNumber] = useState<any>();
  const [userSelectedRoomName, setUserSelectedRoomName] = useState<any>();
  const prevTabRef = useRef<any>();
  const [input, setInput] = useState('');
  const [results, setResults] = useState<IQuery[]>([]);
  const [guest, getGuest] = useState<IQuery>();
  const [roomResults, setRoomResults] = useState<IRoom[]>([]);
  const [reservationType, setReservationType] = useState<
    InterfaceReservationType[]
  >([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<number>();
  const [selectedTypeId, setSelectedTypeId] = useState<number>();
  const [extractShiftId, setExtractedShiftId] = useState<number>();
  const [roomdetails, setRoomDetails] = useState<RoomDataListItem[]>([]);
  const [tobeCheckedOutitems3hours, setTobeCheckedOutitems3hours] = useState<
    IGuestCheckedIn[]
  >([]);
  const [ischecking, setIsChecking] = useState('Payment');
  const [roomName, setRoomName] = useState<string>('');
  const [roomNumber, setRoomNumber] = useState<number>(0);
  const [checkingGuest, setCheckingGuest] = useState<ICheckinGuest>(
    InitializeCheckingGuest
  );
  const [makePayment, setMakePayment] = useState<boolean>(false);
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [isPaymentL, setIsPaymentL] = useState<boolean>(false);
  const [isDisabled, setisDisabled] = useState<boolean>(true);
  const [isCheckedOutItems, setIsCheckedOutItems] = useState<boolean>(false);
  const [roomAmmount, setRoomAmmount] = useState<any>('');
  const [hideIsCheckOut, setHideIsCheckOut] = useState<boolean>(true);
  const [titleMapping, setTitleMapping] = useState<Title[]>([]);
  const [filteredOptions, setFilteredOptions] = useState(titleMapping);
  const [genderMapping, setGenderMapping] = useState<Gender[]>([]);
  const [filteredGenderOptions, setFilteredGenderOptions] =
    useState(genderMapping);
  const [adjuster, setAdjuster] = useState<GetEmployee>();
  const [dispatchToaster, setDispatchToaster] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [currency, setCurrency] = useState('NGN');
  const currencySymbol = currency === 'NGN' ? '₦' : '$';
  const styles = useStyles();
  initializeIcons();
  const selectId = useId();
  const [progressValue, setProgressValue] = React.useState(10);
  const point005prcent:any = 0.007;
  const [expandedRows, setExpandedRows] = useState<Array<boolean>>([]);
  const [singleRoomRates, setSingleRoomRate] = useState<number>(0);
  const [theGuestEmail, setTheGuestEmail] = useState<string>("");
  const [transactionStatus, setTransactionStatus] = useState<boolean>(false);
 const [returnSuccess, setReturnSuccess] = useState<ReturnMessage>(Initmessage);
  const [getBookingId, setGetBookingId] = useState<number>(0);
  const handleRowClick = (index: number) => {
    setExpandedRows((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const intervalDelay = 100;
  const intervalIncrement = 1;

  const handleBlur = (event: any) => {
    const selectedName = event.target.value;
    const selectedEmployee = results.find(
      (result) => `${result.firstName} ${result.lastName}` === selectedName
    );

    if (selectedEmployee) {
      setSelectedEmployeeId(selectedEmployee.roomNumber);
    }
  };

  const handleBlurRST = (event: any) => {
    const selectedName = event.target.value;
    const selectedTypes = reservationType.find(
      (reservationType) => `${reservationType.typeName}` === selectedName
    );

    if (selectedTypes) {
      setSelectedTypeId(selectedTypes.reservationTypeId);
      setCheckingGuest((prevState) => ({
        ...prevState,
        reservationType: selectedTypes?.typeName || ''
    }));
    
    }
  };




  const fetchAutocompleteResults = async (inputs: any) => {
    if (inputs.length < 0) {
      return; // don't call the API if the input is less than 2 characters
    }
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Rooms/search/${inputs}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setRoomResults(response.data);
    } catch (error) {

    }
  };

  const GetAdjusterDetails = useCallback(
    async (inputs: any) => {
      if (inputs.length < 0) {
        return; // don't call the API if the input is less than 2 characters
      }
      try {
        const response = await axios.get(
          `${WHITE_HOUSE_API}/Employee/${inputs}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setAdjuster(response.data);
      } catch (error) {
        console.error('Error fetching autocomplete results:', error);
      }
    },
    [user.token]
  );

  const adjust: Adjuster = {
    firstname: adjuster?.firstName ?? '',
    lastname: adjuster?.lastName ?? '',
    phoneNumber: adjuster?.phoneNumber ?? '',
    userid: adjuster?.employeeId ?? 0,
  };

  const fetchTobeCheckedOutitems3hours = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/Checkin/Checkout`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setTobeCheckedOutitems3hours(response.data);
    } catch (error) {
    }
  }, [user.token]); // add dependencies if any

  const fetchAutocompleteRSTs = async (inputs: any) => {
    if (inputs.length < 3) {
      return; // don't call the API if the input is less than 2 characters
    }
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/ReservationType/search/${inputs}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setReservationType(response.data);
    } catch (error) {

    }
  };

  const handleInputChangeName = (event: any) => {
    fetchAutocompleteResults(event.target.value);
  };

  const handleReservationChange = (event: any) => {
    fetchAutocompleteRSTs(event.target.value);
  };



  const toggleForm = () => {
    setShowForm((c) => !c);
    setshowDetails((c) => !c);
  };
  const MakePayment = () => {
    setMakePayment(true);
  };
  const search = async (query: any) => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Query/guest?query=${query}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          params: { query },
        }
      );
      setResults(response.data);
    } catch (error) {

    }
  };

  const handleInputChange = (event: any) => {
    const que = event.target.value;
    setisSearchActive(event.target.value !== '');
    setQuery(que);
    if (que.length >= 1) {
      search(que);
    }
  };

  const handleCloseForme = () => {
    onClose();
  };

  const goToPrevTab = () => {
    if (prevTabRef.current) {
      setSelectedTab(prevTabRef.current);
    }
  };

  function formatDate(dateString: Date): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero indexed, so +1 and pad with 0
    const day = ('0' + date.getDate()).slice(-2); // Pad with 0
    return `${year}-${month}-${day}`;
  }

  const handleServiceChange = async (name: string, event: any) => {
    const service = event.target.innerText;
    setCheckingGuest((prevState) => ({ ...prevState, [name]: service }));
  };

  const handleCheckingDateInputChange = (
    name: string,
    date: Date | null | undefined
  ) => {
    if (date) {

      let datevalue = formatDateinput(date);
      setCheckingGuest((prevState) => ({
        ...prevState,
        [name]: datevalue,
      }));
    }
  };

  const handleCheckingInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setCheckingGuest((prevState) => ({ ...prevState, [name]: value }));
    console.log("Number of Rooms: ", value);
  };

  const handleCheckingDropdowInputChange =
    (name: string) => (event: React.ChangeEvent<HTMLSelectElement>) => {
      const newValue = event.target.value;
      setCheckingGuest((prevState) => ({ ...prevState, [name]: newValue }));
    };


    // const fetchRoomRate = async (id: any) => {
    //   try {
    //     const response = await axios.get(
    //       `${WHITE_HOUSE_API}/Rooms/price?roomNumber=${id}`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${user.token}`,
    //         },
    //       }
    //     );
    //     setSingleRoomRate(response.data);
    //   } catch (error) {
    //     console.error('Error fetching autocomplete results:', error);
    //   }
    // };
const closeLoad = () => {
  setIsLoader(false);
}
const SubmitCheckinAction = async () => {
  try {
    setIsPaymentL(true);
    const response = await axios.post(
      `${WHITE_HOUSE_API}/Checkin/Checkin`,
      checkingGuest,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );

    if (response.data.status === 'Success') {
      let amt:any = response.data.eAmount;
      let emai:any = response.data.eEmail;
      setGetBookingId(response.data.eBookinId);
      setTheGuestEmail(response.data.eEmail);
      setResponseMessage(response.data.eMessage);
      console.log("Just add data: ", getBookingId);
      let charge:any = await calculateTotalChargeAmount(amt, point005prcent); 
      const transactionData: TransactionData = {
        email: emai,
        amount: (charge * 100).toString(),
        subaccount: SUBACCOUNT_CODE,
        transaction_charge:  charge * point005prcent * 100
      };

      let resTransaction = await initializeTransaction(transactionData);
      redirectToAuthorizationUrl(resTransaction);

      let verification = await checkUntilComplete(resTransaction.data.reference);
      while (verification.data.status !== 'success' && verification.data.status !== 'failed') {
        await new Promise(resolve => setTimeout(resolve, 500));
        verification = await checkUntilComplete(resTransaction.data.reference);
        console.log("I am called: ", new Date());
      }

      console.log("Returned Verification: ", verification);
      if(verification.data.status === 'success'){
        const T:InitiateTransactions = {
          customerEmail: verification.data.customer.email,
          roomNumber: checkingGuest.roomNumber,
          bookingid: response.data.eBookinId,
          transactionId: verification.data.id,
          Amount: verification.data.amount,
          customerPaymentId: verification.data.customer.id,
          paymentChannel: verification.data.channel,
          Souces: 'Front Office',
          gatewayid: 1,
          gatewayresponse: verification.data.gateway_response,
          referencenumber: verification.data.reference,
          clientAccountName: verification.data.subaccount.business_name,
          Currency: verification.data.currency,
          clientId: verification.data.subaccount.id,
          clientBankName: verification.data.subaccount.settlement_bank,
          clientAccountNumber: verification.data.subaccount.account_number
        }
        let internal = await InternalTransaction(T, user.token);
        if(internal){
          Initmessage = {
            header: internal.header,
            body: internal.body,
          };
          setReturnSuccess(Initmessage)
        };
        setIsPaymentL(false);
        setTransactionStatus(true);
        setCheckingGuest({...checkingGuest});
      }else if(verification.data.status === 'failed'){
        Initmessage = {
          header: "Payment " + verification.data.status,
          body: "Room Was not booked",
        };
        setReturnSuccess(Initmessage)
        setIsPaymentL(false);
        setTransactionStatus(true);
      }
    }
  } catch (error) {
    let ermessage = "Error: " + error;
    setResponseMessage(ermessage);
    setIsPaymentL(false);
  }
};

  const handleCurrencyRadioChange = (
    event: React.FormEvent<HTMLDivElement>,
    data: any
  ) => {
    const { value } = data;
    const option = value;
    setCurrency(option);
    setCheckingGuest((prevState) => ({ ...prevState, currency: option }));
  };

  function calculateTotalAmount(
    arrivalDateString: any,
    departureDateString: any,
    roomRate: any
  ) {
    // Convert the date strings to Date objects
    console.log("Deatls Recieved: ", arrivalDateString, "depart: ", departureDateString, "Rates: ", roomRate);
    let arrivalDate = new Date(arrivalDateString);
    let departureDate = new Date(departureDateString);

    // Calculate the difference in time (in milliseconds)
    let timeDifference = departureDate.getTime() - arrivalDate.getTime();

    // Convert the time difference to days
    let numberOfNights = Math.abs(timeDifference / (1000 * 3600 * 24));
    setNumberofNight(numberOfNights);

    // Calculate the total amount
    let totalAmount = numberOfNights * roomRate;
    setCheckingGuest((prevState) => ({ ...prevState, amount: totalAmount }));
    return totalAmount;
  }

  useEffect(() => {
    GetAdjusterDetails(user.email);
    console.log("Details Received: ", departureDate, "depart: ", arrivalDate, "Rates: ", roomRates);
    const selectedRoom = roomResults.find((room) => room.roomNumber === userSelectedRoomNumber);

      if (selectedRoom) {
        setRoomRate(selectedRoom.rate);
        setUserSelectedRoomNumber(selectedRoom.roomNumber!);
        setUserSelectedRoomName(selectedRoom.roomName!);
      }

    if (departureDate && arrivalDate && roomRates) {
      // Calculate total amount
      let amounts = calculateTotalAmount(departureDate, arrivalDate, roomRates);
      setRoomAmmount(amounts);
  
      // Update other state properties
      setCheckingGuest((prevState) => ({ ...prevState, amount: amounts }));
      setCheckingGuest((prevState) => ({ ...prevState, NumberOfNight: numberofNight }));
  
      // Fetch other data (e.g., fetchTobeCheckedOutitems3hours)
      fetchTobeCheckedOutitems3hours();
    }
  
    console.log("Booking ID: ", getBookingId);
  }, [GetAdjusterDetails, arrivalDate, departureDate, fetchTobeCheckedOutitems3hours, getBookingId, numberofNight, roomAmmount, roomRates, user.email]);
  
  const handleCurrencyChange = (event: any) => {
    const option = event.target.value;
    setCurrency(option);
  };

  const handleTabClick = (tabName: any) => {
    setIsChecking(tabName);
    const isCheckoutTab = tabName === 'Checked Out';
    setHideIsCheckOut(!isCheckoutTab);
    setIsCheckedOutItems(isCheckoutTab);
    if (isCheckoutTab === true) {
      setProgressValue(10);
    } else {
      setProgressValue(0);
    }
    // Add any additional actions here
  };

  const handleItemClick = async (guestId: any) => {
    try {
      setIsLoader(true);
      const response = await axios.get(`${WHITE_HOUSE_API}/Guest/${guestId}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        params: { query },
      });
      if (response.data) {
        setCheckingGuest(response.data);
        setisSearchActive(false);
        setIsLoader(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getSingleRoomHistory = useCallback(
    async (id: any) => {
      console.log('the selected Id', id);
      const response = await axios.get(`${WHITE_HOUSE_API}/RoomDetail/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setRoomDetails(response.data);
    },
    [user.token]
  );

  const fetchTitles = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/Title`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const Titles = response.data;
      setTitleMapping(Titles);
      setFilteredOptions(Titles);
    } catch (error) {

    }
  }, [user.token]);

  const fetchGenders = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/Gender`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const genders = response.data;
      setGenderMapping(genders);
      setFilteredGenderOptions(genders);
    } catch (error) {

    }
  }, [user.token]);

  useEffect(() => {
    fetchGenders();
    fetchTitles();
    if (selected.number > 0) {
      getSingleRoomHistory(selected.number);
    }
    if (roomdetails) {
      roomdetails.forEach((room) => {
        if (room.roomName !== null) {
          setRoomName(room.roomName);
        }
        if (room.roomNumber !== null) {
          setRoomNumber(room.roomNumber);
        }
      });
    }
  }, [fetchGenders, fetchTitles,returnSuccess, getSingleRoomHistory, roomdetails, selected]);

  return (
    <>
      <div className="ms-Fabric ms-Layer-content content-46">
        <div className="wc-no outline">
          <div className="ms-Modal is-open root-39">
            <div
              aria-hidden="true"
              className="ms-Overlay ms-Overlay--dark root-48"
            ></div>
            
            {isLoader && (
              <>
                <Loaders />
              </>
            )}
            {isPaymentL && (
               <>
               <PaymentLoaders startInSeconds={60} close={closeLoad}
                />
             </>
            )}
            {transactionStatus && (
              <>
                <TransactionState ResponseMessage={returnSuccess} close={closeLoad}
                 />
              </>
            )}
            <div className="ms-Dialog-main eEiGx Form-main-652">
              {dispatchToaster && <DispatchToast message={responseMessage} />}
              <div aria-hidden="true" data-is-visible="true"></div>
              <div className="ms-Modal-scrollableContent scrollableContent-528 customScrollBar">
                <div className="ogLns">
                  <div className="HfSye" role="tablist">
                    <span className="cCOIa" role="heading">
                      <div className="templateTitleWithLogo-max">
                        <Avatar
                          icon={<PersonAdd24Regular />}
                          aria-label="Group"
                        />
                        <span className="createList-headerTitle wc-margin">
                          Checking Guest
                        </span>
                      </div>
                    </span>
                    <div className="ms-FocusZone css-171">
                      <div className="MY8rO Popup-content-636">
                        <TabList defaultSelectedValue="tab1" vertical>
                          <Tab value="tab1">
                            <Button
                              className="rvBQq-Tab"
                              icon={<Check24Filled />}
                              onClick={() => handleTabClick('Check In')}
                            >
                              Check In
                            </Button>
                          </Tab>
                          <Tab value="tab3">
                            <Button
                              className="rvBQq-Tab"
                              icon={<AccessibilityCheckmark24Filled />}
                              onClick={() => handleTabClick('Checked Out')}
                            >
                              Checking Out
                            </Button>
                          </Tab>
                          <Tab value="tab4">
                            <Button
                              disabled={true}
                              className="rvBQq-Tab"
                              icon={<PeopleCommunity24Filled />}
                              onClick={() => handleTabClick('Group Booking')}
                            >
                              Group Booking
                            </Button>
                          </Tab>
                        </TabList>
                      </div>
                    </div>
                  </div>
                  <div className="pA2AO css-656">
                    <div className="OjwNa">
                      <div className="aHxfM">{selectedTab}</div>
                      <div
                        className={`ui-box Wsearch-ev-ew  wc-check-search c-search_modal__wrapper popover-little ${
                          isSearchActive ? 'mouse-over-active' : ''
                        }`}
                      >
                        <SearchBox
                          appearance="filled-lighter"
                          placeholder="Quick Link .."
                          onChange={handleInputChange}
                          onBlur={search}
                          className="sear-padd"
                        />
                        <div
                          className={`c-search_autocomplete__container c-search_autocomplete__container--query-ordered ${
                            isSearchActive ? 'serachbar-active' : ''
                          }`}
                        >
                          <div className="c-search_autocomplete c-search_autocomplete--nav-attached">
                            <div className="offscreen">
                              <span id="search-autocomplete-query-label-prefix-node">
                                Search
                              </span>
                            </div>
                            {results.map((gu) => {
                              let fullname = gu.firstName + ' ' + gu.lastName;
                              return (
                                <div
                                  key={gu.guestId}
                                  className="c-search_autocomplete__suggestion_list c-search_autocomplete__suggestion_list--taller"
                                  onClick={() => handleItemClick(gu.guestId)}
                                >
                                  <div className="curtain-a wform-css-283-right wc-ciHHEB">
                                    <div className="fui-Persona rlroi9i">
                                      <span
                                        role="img"
                                        id="avatar-rd"
                                        className="fui-Avatar r81b29z fui-Persona__avatar ___1c989qy_1o9k6tz f13ar0e0 fid048z f1hek2iy f9h729m f18q9vkd f103ycu4"
                                        aria-label="Kevin Sturgis"
                                        aria-labelledby="avatar-rd avatar-rd__badge"
                                      >
                                        <AvatarGroup>
                                          <AvatarGroupItem name={fullname} />
                                        </AvatarGroup>
                                      </span>
                                      <span className="fui-Persona__primaryText rvj41k9">
                                        {fullname}
                                      </span>
                                      <span className="fui-Persona__secondaryText rp1pf9e ___19lp2a0_1p5a1yj f1ryq6si">
                                        {gu.email}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="UKZid">
                        <div className="createList-closeBtnContainer">
                          <Button
                            icon={<i className="fi fi-sr-cross-small" />}
                            onClick={handleCloseForme}
                          ></Button>
                        </div>
                      </div>
                    </div>
                    {/* hello body popup */}

                    {isCheckedOutItems ? (
                      <>
                        <div className="checkout manage_alitle_padding">
                          <Table
                            role="grid"
                            aria-label="Table with grid keyboard navigation"
                          >
                            <TableHeader>
                              <TableRow>
                                <TableHeaderCell>Name</TableHeaderCell>
                                <TableHeaderCell>Checking Date</TableHeaderCell>
                                <TableHeaderCell>Checkout Date</TableHeaderCell>
                                <TableHeaderCell>Status</TableHeaderCell>
                                <TableHeaderCell>Checkout</TableHeaderCell>
                                <TableHeaderCell>View More</TableHeaderCell>
                              </TableRow>
                            </TableHeader>
                            <TableBody>
                              {tobeCheckedOutitems3hours.map((item, index) => (
                                <>
                                  <TableRow
                                    onClick={() => handleRowClick(index)}
                                  >
                                    <TableCell>
                                      {item.firstName} {item.lastName}
                                    </TableCell>
                                    <TableCell>{item.arrivalDate}</TableCell>
                                    <TableCell>{item.departureDate}</TableCell>
                                    <TableCell>{item.guestStatus}</TableCell>
                                    <TableCell>
                                      <Switch />
                                    </TableCell>
                                    <TableCell>
                                      <Button
                                        icon={<ChevronDown24Regular />}
                                      ></Button>
                                    </TableCell>
                                  </TableRow>
                                  {expandedRows[index] && (
                                    <TableRow>
                                      <TableCell>
                                        Order: {item.outstanding}
                                      </TableCell>
                                      <TableCell>
                                        Amount Paid: {item.amounpaid}
                                      </TableCell>
                                      <TableCell>
                                        Balance: {item.balance}
                                      </TableCell>
                                      <TableCell>
                                        Number of Children:{' '}
                                        {item.numberOfChildren}
                                      </TableCell>
                                      <TableCell>
                                        Number of Adults: {item.numberOfAdult}
                                      </TableCell>
                                      <TableCell>
                                        Number of Nights: {item.numberOfNight}
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </>
                              ))}
                            </TableBody>
                          </Table>
                        </div>
                      </>
                    ) : (
                      // Render all other items
                      <>
                        <div className="Form_container-manager customScrollBar">
                          <div className="Item-form">
                            <Label>General Information</Label>
                            <div className="wc-form-wrapper">
                              <div className="w-add-Guest-room_w Guest-management-job">
                                <div className="w-add-room-guest rms-block-left">
                                  <div className="wc-general-info make_wec-small">
                                    <Label id="Title">Title</Label>
                                  </div>
                                  <Dropdown
                                    placeholder="Select a Title"
                                    appearance="filled-darker"
                                    name="title"
                                    onOptionSelect={(item) =>
                                      handleServiceChange('title', item)
                                    }
                                  >
                                    {titleMapping.map((item) => (
                                      <Option
                                        key={item.titleId}
                                        value={item.titleName}
                                      >
                                        {item.titleName}
                                      </Option>
                                    ))}
                                  </Dropdown>
                                </div>
                                <div className="w-add-room-guest rms-block-right">
                                  <div className="wc-general-info make_wec-small">
                                    <Label id="Gender">Gender</Label>
                                  </div>
                                  <Dropdown
                                    placeholder="Select a gender"
                                    appearance="filled-darker"
                                    name="gender"
                                    onOptionSelect={(item) =>
                                      handleServiceChange('gender', item)
                                    }
                                  >
                                    {genderMapping.map((item) => (
                                      <Option
                                        key={item.genderId}
                                        value={item.genderName}
                                      >
                                        {item.genderName}
                                      </Option>
                                    ))}
                                  </Dropdown>
                                </div>
                              </div>

                              <div className="w-add-Guest-room_w Guest-management-job">
                                <div className="w-add-room-guest rms-block-left">
                                  <Label id="Title">First Name</Label>
                                  <div className="firstName-container">
                                    <Input
                                      placeholder="First Name"
                                      name="firstName"
                                      value={checkingGuest.firstName || ''}
                                      onChange={handleCheckingInputChange}
                                      appearance="filled-darker"
                                    />
                                  </div>
                                </div>
                                <div className="w-add-room-guest rms-block-right">
                                  <div className="w-add-room-guest rms-inline-property-title">
                                    <Label id="Title">Last Name</Label>
                                  </div>
                                  <div className="firstName-container">
                                    <Input
                                      name="lastName"
                                      value={checkingGuest.lastName || ''}
                                      onChange={handleCheckingInputChange}
                                      appearance="filled-darker"
                                      placeholder="Last Name"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="w-add-Guest-room_w Guest-management-job">
                                <div className="w-add-room-guest rms-block-left">
                                  <Label id="Address">Address</Label>
                                  <div className="firstName-container">
                                    <Input
                                      placeholder="Address"
                                      name="address"
                                      value={checkingGuest.address || ''}
                                      onChange={handleCheckingInputChange}
                                      appearance="filled-darker"
                                    />
                                  </div>
                                </div>
                                <div className="w-add-room-guest rms-block-right">
                                  <Label id="Country">Country</Label>

                                  <div className="firstName-container">
                                    <Input
                                      appearance="filled-darker"
                                      name="country"
                                      value={checkingGuest.country || ''}
                                      onChange={handleCheckingInputChange}
                                      placeholder="Country"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="w-add-Guest-room_w Guest-management-job">
                                <div className="w-add-room-guest rms-block-left">
                                  <Label id="State">State</Label>
                                  <div className="firstName-container">
                                    <Input
                                      placeholder="State"
                                      name="state"
                                      value={checkingGuest.state || ''}
                                      onChange={handleCheckingInputChange}
                                      appearance="filled-darker"
                                    />
                                  </div>
                                </div>
                                <div className="w-add-room-guest rms-block-right">
                                  <div className="w-add-room-guest rms-inline-property-title">
                                    <Label id="City">City</Label>
                                  </div>
                                  <div className="firstName-container">
                                    <Input
                                      appearance="filled-darker"
                                      name="city"
                                      placeholder="City"
                                      value={checkingGuest.city || ''}
                                      onChange={handleCheckingInputChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="Item-form">
                            <Label>Other Information</Label>
                            <div className="wc-form--">
                              <div className="w-add-Guest-room_w Guest-management-job">
                                <div className="w-add-room-guest rms-block-left">
                                  <div className="wc-general-info">
                                    <Label id="IdentityType">Identity</Label>
                                  </div>
                                  <Select
                                    id={`${selectId}-filledDarker`}
                                    name="identity"
                                    onChange={handleCheckingDropdowInputChange(
                                      'identity'
                                    )}
                                    appearance="filled-darker"
                                  >
                                    <option>Select ...</option>
                                    <option>National ID Card</option>
                                    <option>Driver's License</option>
                                    <option>Passport</option>
                                    <option>Employee ID Card</option>
                                    <option>Student ID Card</option>
                                    <option>Loyalty Card</option>
                                    <option>Voter ID Card</option>
                                  </Select>
                                </div>
                                <div className="w-add-room-guest rms-block-right Id-number">
                                  <Label id="Gender">ID Number</Label>
                                  <div className="firstName-container uiId-number">
                                    <Input
                                      placeholder="ID number"
                                      name="idCardNumber"
                                      appearance="filled-darker"
                                      value={checkingGuest.idCardNumber || ''}
                                      onChange={handleCheckingInputChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="w-add-Guest-room_w Guest-management-job">
                                <div className="w-add-room-guest rms-block-left">
                                  <Label id="Email">Nationality</Label>
                                  <div className="firstName-container uiId-number">
                                    <Input
                                      placeholder="Nationality"
                                      name="nationality"
                                      appearance="filled-darker"
                                      value={checkingGuest.nationality || ''}
                                      onChange={handleCheckingInputChange}
                                    />
                                  </div>
                                </div>
                                <div className="w-add-room-guest rms-block-right">
                                  <div className="w-add-room-guest rms-inline-property-title">
                                    <Label id="Status">Guest Status</Label>
                                  </div>
                                  <div className="firstName-container uiId-number">
                                    <Input
                                      appearance="filled-darker"
                                      name="guestStatus"
                                      value={checkingGuest.guestStatus || ''}
                                      onChange={handleCheckingInputChange}
                                      placeholder="Vip.."
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="w-add-Guest-room_w Guest-management-job">
                                <div className="w-add-room-guest rms-block-left">
                                  <Label id="Email">Email</Label>
                                  <div className="firstName-container uiId-number">
                                    <Input
                                      placeholder="Email Address"
                                      name="email"
                                      appearance="filled-darker"
                                      value={checkingGuest.email || ''}
                                      onChange={handleCheckingInputChange}
                                    />
                                  </div>
                                </div>
                                <div className="w-add-room-guest rms-block-right">
                                  <div className="w-add-room-guest rms-inline-property-title">
                                    <Label id="Country">Phone</Label>
                                  </div>
                                  <div className="firstName-container uiId-number">
                                    <Input
                                      appearance="filled-darker"
                                      name="phone"
                                      placeholder="Phone Number"
                                      value={checkingGuest.phone || ''}
                                      onChange={handleCheckingInputChange}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="w-add-Guest-room Guest-management-job">
                                <div className="w-add-room-guest rms-block-left">
                                  <Label id="Email">Value</Label>
                                  <div className="firstName-container uiId-number">
                                    <Input
                                      type="text"
                                      appearance="filled-darker"
                                      name="value"
                                      placeholder="Value.."
                                      value={checkingGuest.value || ''}
                                      onChange={handleCheckingInputChange}
                                    />
                                  </div>
                                </div>
                                <div className="w-add-room-guest rms-block-right">
                                  <div className="w-add-room-guest rms-inline-property-title">
                                    <Label id="Country">VOC. Number</Label>
                                  </div>
                                  <div className="firstName-container uiId-number">
                                    <Input
                                      type="number"
                                      appearance="filled-darker"
                                      name="vocNumber"
                                      placeholder="VOC.."
                                      value={checkingGuest.vocNumber || ''}
                                      onChange={handleCheckingInputChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="Item-form">
                            <Label>Stay Information</Label>
                            <div className="wc-form-wrapper">
                              <div className="w-add-Guest-room_w Guest-management-job">
                                <div className="w-add-room-guest rms-block-left">
                                  <div className="wc-general-info">
                                    <Label id="IdentityType">Room Number</Label>
                                  </div>
                                  <Combobox
                                    aria-label="autocomplete"
                                    name="roomNumber"
                                    onChange={handleInputChangeName}
                                    onSelect={(event) => {
                                      const target = event.target as HTMLInputElement;
                                      const selectedRoomNumber = parseInt(target.value, 10); // Parse as an integer
                                    
                                      if (!isNaN(selectedRoomNumber)) {
                                        // If it's a valid integer, update the room number
                                        setUserSelectedRoomNumber(selectedRoomNumber);
                                        setCheckingGuest((prevState) => ({
                                          ...prevState,
                                          roomNumber: selectedRoomNumber,
                                        }));
                                       
                                        // Find the room with the selected room number
                                        const selectedRoom = roomResults.find((room) => room.roomNumber === selectedRoomNumber);
                                    
                                        if (selectedRoom) {
                                          setRoomRate(selectedRoom.rate);
                                          setUserSelectedRoomNumber(selectedRoom.roomNumber!);
                                          setUserSelectedRoomName(selectedRoom.roomName!);
                                        }
                                      } else {
                                        // Use the existing format to split the value
                                        let match = target.value.match(/\((\d+)\)/);
                                        if (match) {
                                          const value = parseInt(match[1]);
                                          setCheckingGuest((prevState) => ({
                                            ...prevState,
                                            roomNumber: value,
                                          }));
                                    
                                          // Find the room with the selected room number
                                          const selectedRoom = roomResults.find((room) => room.roomNumber === value);
                                    
                                          if (selectedRoom) {
                                            setRoomRate(selectedRoom.rate);
                                            setUserSelectedRoomNumber(selectedRoom.roomNumber!);
                                            setUserSelectedRoomName(selectedRoom.roomName!);
                                          }
                                        }
                                      }
                                    }}
                                    
                                    onBlur={handleBlur}
                                  >
                                    {roomResults.map((result) => {
                                      if (
                                        !userSelectedRoomNumber ||
                                        result.roomNumber ===
                                          userSelectedRoomNumber
                                      ) {
                                        return (
                                          <Option
                                            key={result.roomNumber}
                                            text={`ROOM (${result.roomNumber})`}
                                          >
                                            <Persona
                                              avatar={{
                                                color: 'colorful',
                                                'aria-hidden': true,
                                              }}
                                              name={`${result.roomNumber}`}
                                              presence={{
                                                status: 'available',
                                              }}
                                              secondaryText={result.roomType}
                                            />
                                          </Option>
                                        );
                                      }
                                    })}
                                  </Combobox>
                                </div>
                                <div className="w-add-room-guest rms-block-right Id-number">
                                  <div className="wc-general-info">
                                    <Label id="Gender">Room Name</Label>
                                  </div>

                                  <div className="firstName-container uiId-number">
                                    <Combobox
                                      aria-label="autocomplete"
                                      name="roomName"
                                      onChange={handleInputChangeName}
                                      onBlur={handleBlur}
                                      onSelect={(event) => {
                                        const target =
                                          event.target as HTMLInputElement;
                                        const selectedOption = target.value;
                                        const selectedRoom = roomResults.find(
                                          (room) =>
                                            room.roomName === selectedOption
                                        );
                                        if (selectedRoom) {
                                          setUserSelectedRoomNumber(
                                            selectedRoom.roomNumber!
                                          );
                                          setUserSelectedRoomName(
                                            selectedRoom.roomName!
                                          );
                                          setCheckingGuest((prevState) => ({
                                            ...prevState,
                                            RoomName: selectedOption,
                                            RoomNumber: selectedRoom.roomNumber,
                                          }));                                         
                                          
                                        }
                                      }}
                                    >
                                      {roomResults.map((result) => {
                                        if (
                                          !userSelectedRoomName ||
                                          result.roomName ===
                                            userSelectedRoomName
                                        ) {
                                          return (
                                            <Option
                                              key={result.roomNumber}
                                              text={`${result.roomName}`}
                                            >
                                              <Persona
                                                avatar={{
                                                  color: 'colorful',
                                                  'aria-hidden': true,
                                                }}
                                                name={`${result.roomName}`}
                                                presence={{
                                                  status: 'available',
                                                }}
                                                secondaryText={result.roomType}
                                              />
                                            </Option>
                                          );
                                        }
                                      })}
                                    </Combobox>
                                  </div>
                                </div>
                              </div>
                              <div className="w-add-Guest-room_w Guest-management-job">
                                <div className="w-add-room-guest rms-block-left">
                                  <Label id="Email">Arrival Date</Label>
                                  <div className="firstName-container">
                                    <Field>
                                      <DatePicker
                                        showWeekNumbers={true}
                                        firstWeekOfYear={1}
                                        showMonthPickerAsOverlay={true}
                                        name="arrivalDate"
                                        value={checkingGuest?.arrivalDate? new Date(checkingGuest.arrivalDate): null}
                                        placeholder="Date of birth..."
                                        className={styles.control}
                                        onSelectDate={(date) => {
                                          handleCheckingDateInputChange(
                                            'arrivalDate',
                                            date
                                          );
                                          if (date) {
                                            setArrivalDate(date);
                                          }
                                        }}
                                      />
                                    </Field>
                                  </div>
                                </div>
                                <div className="w-add-room-guest rms-block-right">
                                  <div className="w-add-room-guest rms-inline-property-title">
                                    <Label id="Country">Number of Night</Label>
                                  </div>
                                  <div className="firstName-container">
                                    <Input
                                      type="number"
                                      disabled={isDisabled}
                                      appearance="filled-darker"
                                      name="numberOfNight"                                     
                                      value={numberofNight}
                                      placeholder="Please selecte arrival and departure date.."
                                      onChange={handleCheckingInputChange}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="w-add-Guest-room_w Guest-management-job">
                                <div className="w-add-room-guest rms-block-left">
                                  <Label id="Email">Departure Date</Label>
                                  <div className="firstName-container">
                                    <Field>
                                      <DatePicker
                                        name="departureDate"
                                        showWeekNumbers={true}
                                        firstWeekOfYear={1}
                                        value={checkingGuest?.departureDate ? new Date(checkingGuest.departureDate) : null}
                                        showMonthPickerAsOverlay={true}
                                        placeholder="Date of birth..."
                                        className={styles.control}
                                        onSelectDate={(date) => {
                                          handleCheckingDateInputChange(
                                            'departureDate',
                                            date
                                          );
                                          if (date) {
                                            setDepartureDate(date);
                                          } // Set the arrival date here
                                        }}
                                      />
                                    </Field>
                                  </div>
                                </div>
                                <div className="w-add-room-guest rms-block-right Id-number">
                                  <div className="wc-general-info">
                                    <Label id="Gender">
                                      Search Reservation Type
                                    </Label>
                                  </div>
                                  <div className="firstName-container combo-parent-reservation-type">
                                    <Combobox
                                      aria-label="autocomplete"
                                      onChange={handleReservationChange}
                                      onBlur={handleBlurRST}
                                    >
                                      {reservationType.map((result) => {
                                        return (
                                          <Option
                                            key={result.reservationTypeId}
                                            text={`${result.typeName}`}
                                          >
                                            <Persona
                                              avatar={{
                                                color: 'colorful',
                                                'aria-hidden': true,
                                              }}
                                              name={`${result.typeName}`}
                                              presence={{
                                                status: 'available',
                                              }}
                                              secondaryText={result.description}
                                            />
                                          </Option>
                                        );
                                      })}
                                    </Combobox>
                                  </div>
                                </div>
                              </div>
                              <div className="w-add-Guest-room_w Guest-management-job">
                                <div className="w-add-room-guest rms-block-left">
                                  <Label id="Email">Number of Children</Label>
                                  <div className="firstName-container">
                                    <Input
                                      type="number"
                                      appearance="filled-darker"
                                      name="numberOfChildren"
                                      value={ checkingGuest?.numberOfChildren?
                                        checkingGuest?.numberOfChildren.toString(): ''
                                      }
                                      placeholder="Number of Children.."
                                      onChange={handleCheckingInputChange}
                                    />
                                  </div>
                                </div>
                                <div className="w-add-room-guest rms-block-right">
                                  <div className="w-add-room-guest rms-inline-property-title">
                                    <Label id="Country">Number of Adult</Label>
                                  </div>
                                  <div className="firstName-container">
                                    <Input
                                      type="number"
                                      appearance="filled-darker"
                                      name="numberOfAdult"
                                      value={checkingGuest?.numberOfAdult?checkingGuest?.numberOfAdult.toString(): ''}
                                      placeholder="Number of Adult.."
                                      onChange={handleCheckingInputChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="w-add-Guest-room_w Guest-management-job">
                                <div className="w-add-room-guest rms-block-left">
                                  <Label id="Email">Number of Room</Label>
                                  <div className="firstName-container">
                                    <Input
                                      type="number"
                                      appearance="filled-darker"
                                      name="numberOfRooms"
                                      value={checkingGuest?.numberOfRooms?
                                        checkingGuest?.numberOfRooms.toString(): ''
                                      }
                                      placeholder="Number of room.."
                                      onChange={handleCheckingInputChange}
                                    />
                                  </div>
                                </div>
                                <div className="w-add-room-guest rms-block-right">
                                  <div className="w-add-room-guest rms-inline-property-title">
                                    <Label id="Country"></Label>
                                  </div>
                                  <div className="firstName-container">
                                    {/* <Input
                                      type="number"
                                      appearance="filled-darker"
                                      name="numberOfAdult"
                                      value={checkingGuest?.numberOfAdult?checkingGuest?.numberOfAdult.toString(): ''}
                                      placeholder="Number of Adult.."
                                      onChange={handleCheckingInputChange}
                                    /> */}
                                  </div>
                                </div>
                              </div>
                              <div role="group" aria-labelledby="hobbiesLabel">
                                <RadioGroup
                                  defaultValue="Normal"
                                  name="isBooked"
                                  aria-labelledby="ageLabel"
                                  className="wc-flex"
                                  onChange={(event, value) => {
                                    if (value.value === 'IsReserved') {
                                      setCheckingGuest((prevState) => ({
                                        ...prevState,
                                        isReserved: true,
                                        isBooked: false,
                                        isCheckin:false,
                                      }));
                                    } else if (value.value === 'IsBooked') {
                                      setCheckingGuest((prevState) => ({
                                        ...prevState,
                                        isBooked: true,
                                        isReserved: false,
                                        isCheckin:false,
                                      }));

                                    }else if (value.value === 'IsCheckin') {
                                      setCheckingGuest((prevState) => ({
                                        ...prevState,
                                        isCheckin:true,
                                        isBooked: false,
                                        isReserved: false,
                                      }));
                                  }}
                                }>
                                  <Radio
                                    value="IsReserved"
                                    label="Reservation"
                                  />
                                  <Radio value="IsBooked" label="Is Booking" />
                                  <Radio value="IsCheckin" label="Is Check in" />
                                </RadioGroup>
                              </div>
                            </div>
                          </div>
                          <div className="Item-form">
                            <div>
                              <Label className="wc-pmargin-b">
                                Mode of payment:
                              </Label>
                              <div>
                                <div
                                  role="group"
                                  aria-labelledby="hobbiesLabel"
                                >
                                  <Checkbox
                                    label="Direct Credit"
                                    name="Direct Credit"
                                    onChange={(event, isChecked) => {
                                      if (isChecked) {
                                        setCheckingGuest((prevState) => ({
                                          ...prevState,
                                          ModeOfPayment: event.target.name,
                                          paymentTerms: event.target.name,
                                        }));                                     
                                      }
                                    }}
                                  />
                                  <Checkbox
                                    label="After Sells"
                                    name="After Sells"
                                    onChange={(event, isChecked) => {
                                      if (isChecked) {
                                        setCheckingGuest((prevState) => ({
                                          ...prevState,
                                          ModeOfPayment: event.target.name,
                                          paymentTerms: event.target.name,
                                        }));                                       
                                      }
                                    }}
                                  />
                                  <Checkbox
                                    label="Invoice"
                                    name="Invoice"
                                    onChange={(event, isChecked) => {
                                      if (isChecked) {
                                        setCheckingGuest((prevState) => ({
                                          ...prevState,
                                          ModeOfPayment: event.target.name,
                                          paymentTerms: event.target.name,
                                        }));                                        
                                      }
                                    }}
                                  />
                                  <Checkbox
                                    label="Cash deposit"
                                    name="Cash deposit"
                                    onChange={(event, isChecked) => {
                                      if (isChecked) {
                                        setCheckingGuest((prevState) => ({
                                          ...prevState,
                                          ModeOfPayment: event.target.name,
                                          paymentTerms: event.target.name,
                                        }));                                       
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              <Label className="wc-pmargin-b">Rates:</Label>
                              <div>
                                <div
                                  role="group"
                                  aria-labelledby="hobbiesLabel"
                                >
                                  <RadioGroup
                                    defaultValue="Normal"
                                    name="rates"
                                    aria-labelledby="ageLabel"
                                    className="wc-flex"
                                    onChange={(event, value) => {
                                      setCheckingGuest((prevState) => ({
                                        ...prevState,
                                        Rates: value,
                                      }));                                      
                                    }}
                                  >
                                    <Radio value="Normal" label="Normal" />
                                    <Radio value="Contract" label="Contract" />
                                    <Radio value="Manual" label="Manual" />
                                  </RadioGroup>
                                </div>
                              </div>
                              <div className="w-add-Guest-room_w Guest-management-job">
                                <div className="w-add-room-guest rms-block-left">
                                  <div className="w-add-room-guest Guest-inline-property-title">
                                    Bill To:
                                  </div>
                                  <div className="firstName-container uiId-number">
                                    <Select
                                      id={`${selectId}-filledDarker`}
                                      appearance="filled-darker"
                                      onChange={handleCheckingDropdowInputChange(
                                        'billTo'
                                      )}
                                    >
                                      <option>select...</option>
                                      <option>Wema Bank Plc</option>
                                      <option>Guarantee Trust Bank Plca</option>
                                      <option>Access Bank Plc</option>
                                    </Select>
                                  </div>
                                </div>

                                <div className="w-add-room-guest rms-block-right">
                                  <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                    Tax Exemption Id
                                  </div>
                                  <div className="firstName-container uiId-number">
                                    <Input
                                      appearance="filled-darker"
                                      placeholder="Tax exemption Id"
                                      name="taxExemptionId"
                                      onChange={handleCheckingInputChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                Currency Used
                              </div>
                              <RadioGroup
                                defaultValue="NGN"
                                name="currency"
                                aria-labelledby="ageLabel"
                                className="wc-flex"
                                onChange={handleCurrencyRadioChange}
                              >
                                <Radio value="NGN" label="NGN" />
                                <Radio value="USD" label="USD" />
                              </RadioGroup>
                              <div className="w-add-Guest-room_w Guest-management-job">
                                <div className="w-add-room-guest rms-block-left">
                                  <Label id="Email">Release Date</Label>
                                  <div className="firstName-container uiId-number">
                                    <Field>
                                      <DatePicker
                                        showWeekNumbers={true}
                                        firstWeekOfYear={1}
                                        showMonthPickerAsOverlay={true}
                                        placeholder="Release Date..."
                                        className={styles.control}
                                        value={checkingGuest?.releaseDate ? new Date(checkingGuest.releaseDate) : null}
                                        onSelectDate={(date) =>
                                          handleCheckingDateInputChange(
                                            'releaseDate',
                                            date
                                          )
                                        }
                                      />
                                    </Field>
                                  </div>
                                </div>

                                <div className="w-add-room-guest rms-block-right wc-margin">
                                  <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                    Discounted Price in %
                                  </div>
                                  <div className="firstName-container uiId-number">
                                    <Input
                                      placeholder="% discount price .."
                                      type="number"
                                      name="discount"
                                      value={checkingGuest.discount?checkingGuest.discount.toString() : ''}
                                      contentBefore={
                                        <Text size={400} id="beforeLabelId">
                                          {currencySymbol}
                                        </Text>
                                      }
                                      contentAfter={
                                        <Text size={400} id="afterLabelId">
                                          %
                                        </Text>
                                      }
                                      appearance="filled-darker"
                                      id="filledDarkerId"
                                      onChange={handleCheckingInputChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="w-add-Guest-room_w Guest-management-job">
                                {/* <div className="w-add-room-guest rms-block-left">
                                            <Label>Company</Label>
                                            <div className="firstName-container uiId-number">
                                              <Input
                                                placeholder="Company"
                                                type="text"
                                                appearance="filled-darker"
                                                id="filledDarkerId"
                                                name="Company"
                                                onChange={
                                                  handleCheckingInputChange
                                                }
                                              />
                                            </div>
                                          </div> */}
                                <div className="w-add-room-guest rms-block-left">
                                  <Label>Company</Label>
                                  <div className="firstName-container uiId-number">
                                    <Input
                                      appearance="filled-darker"
                                      placeholder="Company"
                                      name="company"
                                      value={checkingGuest.company || ''}
                                      onChange={handleCheckingInputChange}
                                    />
                                  </div>
                                </div>

                                {/* <div className="w-add-room-guest rms-block-right wc-margin">
                                            <Label></Label>
                                            <div className="firstName-container uiId-number">
                                              <Input
                                                placeholder="Business sources"
                                                type="text"
                                                name="BusinessSources"
                                                appearance="filled-darker"
                                                id="filledDarkerId"
                                                onChange={
                                                  handleCheckingInputChange
                                                }
                                              />
                                            </div>
                                          </div> */}
                                <div className="w-add-room-guest rms-block-right wc-margin">
                                  <Label>Business Sources</Label>
                                  <div className="firstName-container uiId-number">
                                    <Input
                                      appearance="filled-darker"
                                      placeholder="Business sources"
                                      name="businessSources"
                                      value={
                                        checkingGuest.businessSources || ''
                                      }
                                      onChange={handleCheckingInputChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="footer-form-content ">
                          <Divider />
                          <div className="createList-pageFooter WC-form-controle pigin-hole">
                            <div className="createList-leftActions">
                              <Button
                                data-automationid="createListGoBackButton"
                                className=""
                                icon={<ChevronLeft24Regular />}
                                onClick={goToPrevTab}
                              >
                                Back
                              </Button>
                            </div>
                            <div className="createList-rightActions margin-Rig">
                              <Button
                                className="wc-first-root-68"
                                onClick={SubmitCheckinAction}
                              >
                                Submit
                              </Button>
                              <Button className="" onClick={handleCloseForme}>
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {Loaders && <Loaders />}
    </>
  );
};

export default ReuseAble;

