import { Employee } from "./Employee";
import { EmployeeRole } from "./EmployeeRole";

export interface IDepartment {
    departmentId: number;
    departmentName: string;
    description?: string;
    managerId?: number;
    location?: string;
    locationId?:number;
    phone?: string;
    email?: string;
    isActive?: boolean;
    unit?: string;
    unitId?: number;
    dateadded:Date;
}


export const initializeDepartment:IDepartment ={
    departmentId: 0,
    departmentName: "",
    description: "",
    managerId: 0,
    location: "",
    locationId:0,
    phone: "",
    email: "",
    isActive: true,
    unit: "",
    unitId: 0,
    dateadded: new Date()
}


export interface DeletedDepartment {
    Reason:string;
}

export const InitializeDeleted:DeletedDepartment ={
    Reason: ""
}