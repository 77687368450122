import React from 'react';
import Footer from './Footer/Footer';
import "./mobile.scss";
import Header from './Header/Header';
import MobileLanding from './Landing/Landing';
import { useAuth } from '../Components/Authentication/PrivateAuth';


interface Message {
  message: string;
  id: number;
}

interface User {
  email: string;
  phoneNumber: string;
  token: string;

  // other properties...
}
type Props = {
  message: Message;
  user: User;
};


const Mobile:React.FC<Props> = (props) => {
  const { message, user } = props;
  return (
    <>
    <div className="BY9zN">
    <Header />
    <MobileLanding message={message} user={user} />
     <Footer />
     </div>
    </>
  );
}

export default Mobile;
