export interface IHotelProduct {
    id: number;
    productName: string;
    productType: string;
    company:string;
    price: number;
    oldPrice?: number;
    servingSize?: string;
    vat?: string;
    dateAdded: Date;
}


export const hotelProduct: IHotelProduct = {
    id: 0,
    productName: "",
    productType: "",
    price: 0,
    oldPrice: 0,
    servingSize: "",
    company: "",
    vat: "",
    dateAdded: new Date()
};

export interface IProductRequisition {
    id: number;
    productName: string;
    productType: string;
    productStatus:string;
    price: number;
    oldPrice?: number;
    servingSize?: string;
    vat?: string;
    dateAdded: Date;
}

