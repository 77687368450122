import axios from 'axios';
import { SECRETE_KEY } from '../../../Constants/Constant';

export type TransactionData = {
  email: string;
  amount: string;
  subaccount: string;
  transaction_charge: number;
};
let intervalId: NodeJS.Timeout;

export const initializeTransaction = async (data: TransactionData) => {
  const url = "https://api.paystack.co/transaction/initialize";
  const headers = {
    "Authorization": `${SECRETE_KEY}`,
    "Content-Type": "application/json"
  };

  const payload = {
    ...data,
    transaction_charge_type: 'flat_subaccount',
    bearer: 'subaccount'
  };

  try {
    const response = await axios.post(url, payload, { headers });
    return response.data; // This will return the response from the function
  } catch (error) {
    console.error(error);
  }
}

export const checkUntilComplete = async (reference: string) => {
  let response = await checkTransaction(reference);
  console.log("The returned response", response);
  return response;
}

export const calculateTotalChargeAmount = async (amt: number, cilpronFess: number) => {
  let paystackChargePercent = 1.5;
  let paystackFixedCharge = 100;
  let paystackCap = 2000;

  // Calculate your charge
  let myCharge = amt * cilpronFess;

  // Calculate Paystack charge
  let paystackCharge = amt * (paystackChargePercent / 100) + paystackFixedCharge;

  // Apply Paystack cap if necessary
  if (paystackCharge > paystackCap) {
    paystackCharge = paystackCap;
  }

  // Waive Paystack fixed charge if necessary
  if (amt < 2500) {
    paystackCharge -= paystackFixedCharge;
  }

  // Calculate total amount
  let totalAmount = amt + myCharge + paystackCharge;
  return totalAmount;
}

export const  redirectToAuthorizationUrl = async (response: {data: {authorization_url: string, access_code: string, reference: string}}) => {
  let url = response.data.authorization_url;
  window.open(url, '_blank');
}

export const  checkTransaction = async (reference: string) =>{
  const url = `https://api.paystack.co/transaction/verify/${reference}`;
  const headers = {
    "Authorization": `${SECRETE_KEY}`,
    "Content-Type": "application/json"
  };

  try {
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (error) {
    console.error(error);
  }
}