import React, { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
  Label,
  useId,
  Input,
  makeStyles,
  Divider,
  Field,
  Combobox,
  Option,
  Text,
  Persona,
  Dropdown,
  Toolbar,
  ToolbarButton,
  Tooltip,
  TabList,
  Tab,
  InteractionTag,
  InteractionTagPrimary,
  Tag,
  Avatar,
} from '@fluentui/react-components';
import './GuestView.scss';
import axios from 'axios';
import { WHITE_HOUSE_API } from '../../../Constants/Constant';
import { ICreateGuest } from '../../../Data/General/Guest';
import { BedFilled, CalendarMonthRegular, CallRegular, ChatRegular, Location24Regular, MailRegular, WalletRegular } from '@fluentui/react-icons';

type Adjuster = {
  firstname?: string;
  lastname?: string;
  phoneNumber?: string;
  userid?: number;
};

interface User {
  email: string;
  phoneNumber: string;
  token: string;
}
interface Message {
  message: string;
  id: number;
}
// other properties...
interface BarCatsProps {
  onClose: () => void;
  message: Message;
  user: User;
}
const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '400px',
  },
  control: {
    maxWidth: '300px',
  },
});

const GuestView: React.FC<BarCatsProps> = ({ onClose, message, user }) => {
  const [dispatchToaster, setDispatchToaster] = useState<boolean>(false);
  const [selectedInvoicee, setSelectedInvoicee] = useState<number>();
  const [fetchGuest, setFetchedGuest] = useState<ICreateGuest>();
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [input, setInput] = useState('');
  const inputId = useId('input');
  const styles = useStyles();
  const [currency, setCurrency] = useState('NGN');
  const currencySymbol = currency === 'NGN' ? '₦' : '$';
  const [selectedTab, setSelectedTab] = useState('tab1');

  const handleTabClick = (tab:any) => {
    setSelectedTab(tab);
  };
  const handleFetchGuest = useCallback(async (id:number) => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/Guest/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setFetchedGuest(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [message, user.token]);

  useEffect(() => {
    console.log("ReturnedId: ", message.id);
    if (message.id > 0) {
      handleFetchGuest(message.id);
    }
  }, [handleFetchGuest, message.id]); //

  return (
    <>
      <div className="fui-FluentProvider fui-FluentProvider361 ___1bsm0mz f19n0e5 fxugw4r f1o700av fk6fouc fkhj508 figsok6 f1i3iumi f1euv43f f15twtuk f1vgc2s3 f1e31b4d f494woh">
        <div
          aria-hidden="true"
          className="fui-DialogSurface__backdrop ___adeqq90 f19dog8a fju19wo f113wtx2 f10k790i f5gq2j6 f1xynx9j"
        ></div>

        <div className="fui-DialogSurface LKEYB-90__ ___1g5txne ftgm304 f1c515w f1bitti f15twtuk f1e31b4d f1yab3r1 f1vgc2s3 fuq56rw f15kemlc fl2zwns fdgang7 fgr6219 f1ujusj6 f10jk5vf fcgxt0o fzkkow9 fcdblym fg706s2 fjik90z f1ln0qer fa2wlxz f120kxnn f19dog8a f3052tw fvgz9i8 f6a9g1z f1ewtqcl f10nrhrw fxugw4r f19n0e5 f5ogflp f1hqa2wf f1f09k3d finvdd3 fghlq4f f1gn591s fb073pr fjscplz fnivh3a fc7yr5o f1el4m67 f8yange ff54dml ftqa4ok f2hkw1w f8hki3x f1d2448m f1bjia2o ffh67wi f15bsgw9 f14e48fq f18yb2kv fd6o370 fh1cnn4 fy7oxxb fpukqih f184ne2d frrh606 f1v5zibi ful5kiu fo2hd23 f1jqcqds ftffrms f2e7qr6 fsr1zz6 f1dvezut fd0oaoj fjvm52t f1cwg4i8 f57olzd f4stah7 f480a47 fs1por5">
          <div className="WHER-HEADER ejhe HHEsjhe">
            <div className="container-611">
              <Persona
                presence={{ status: 'available' }}
                size="huge"
                name={`${fetchGuest?.firstName} ${fetchGuest?.lastName}`}
                avatar={{ color: 'colorful' }}
                secondaryText={`${fetchGuest?.phone}`}
              />
              <div className="ms-FocusZone css-168 actions-737">
                <Toolbar aria-label="with Popover" size="small">
                  <Tooltip
                    withArrow
                    content={`Call ${fetchGuest?.firstName} ${fetchGuest?.lastName}`}
                    relationship="label"
                  >
                    <Button className="call Button" icon={<CallRegular />}>
                      Call
                    </Button>
                  </Tooltip>
                  <Tooltip
                    withArrow
                    content={`Email ${fetchGuest?.firstName} ${fetchGuest?.lastName}`}
                    relationship="label"
                  >
                    <ToolbarButton
                      color="primary"
                      icon={<MailRegular />}
                    ></ToolbarButton>
                  </Tooltip>
                  <Tooltip
                    withArrow
                    content={`Chat with ${fetchGuest?.firstName} ${fetchGuest?.lastName}`}
                    relationship="label"
                  >
                    <ToolbarButton
                      color="primary"
                      icon={<ChatRegular />}
                    ></ToolbarButton>
                  </Tooltip>
                </Toolbar>
              </div>
            </div>
          </div>
          <div className="TabegeJE">
            <TabList defaultSelectedValue="tab1">
              <Tab onClick={() => handleTabClick('tab1')} value="tab1">OverView</Tab>
              <Tab onClick={() => handleTabClick('tab2')} value="tab2">Contacts</Tab>
              <Tab onClick={() => handleTabClick('tab3')} value="tab3">Organization</Tab>
              <Tab onClick={() => handleTabClick('tab4')} value="tab4">Messages</Tab>
            </TabList>
          </div>
          <div className="body-enTEBmjh panel-832">
            <div className="ms-FocusZone css-168">
              <div className="">
                
                {selectedTab === 'tab1' && (
                  <>
                   <h2 className="inactiveTitleLarge-839">Billing information</h2>
                <div className="container-844">
           
                  <div className="list-640 container-846" style={{marginBottom: '10px'}}>
                  {/* starts here  */}
                  <Tag secondaryText={`${fetchGuest?.address}, ${fetchGuest?.city} ${fetchGuest?.state}, ${fetchGuest?.country}`} media={<Avatar icon={<Location24Regular />} />}>
                  Credit Amount
                  </Tag>
                  <Tag secondaryText={ `${fetchGuest?.phone}`} media={<Avatar icon={<CallRegular  />}  />}>
                 Debit Amount
                  </Tag>
                  <Tag secondaryText={`${fetchGuest?.email}`} media={<Avatar icon={<MailRegular  />} />}>
                  Total  Orders
                  </Tag>
                  <Tag secondaryText={`${fetchGuest?.paymentAmount?.toLocaleString()}`} media={<Avatar icon={<WalletRegular />}  />}>
                    Outstanding Balance
                  </Tag>
                  <Tag secondaryText={`${fetchGuest?.preferences}`} media={<Avatar icon={<CalendarMonthRegular  />}  />}>
                    Preferences
                  </Tag>
                  <Tag secondaryText={`${fetchGuest?.roomNumber}`} media={<Avatar icon={<BedFilled  />}  />}>
                     Room Checked In
                  </Tag>
                </div>
                </div>
                  </>
                 
                )}
                {selectedTab === 'tab2' && (
                  <>
                  
                  <h2 className="inactiveTitleLarge-839">Billing information</h2>
                <div className="container-844">
                <div className="list-640 container-846" style={{marginBottom: '10px'}}>
                  {/* starts here  */}
                  <Tag secondaryText={`${fetchGuest?.address}, ${fetchGuest?.city} ${fetchGuest?.state}, ${fetchGuest?.country}`} media={<Avatar icon={<Location24Regular />} />}>
                  Home Address
                  </Tag>
                  <Tag secondaryText={ `${fetchGuest?.phone}`} media={<Avatar icon={<CallRegular  />}  />}>
                 Phone Number
                  </Tag>
                  <Tag secondaryText={`${fetchGuest?.email}`} media={<Avatar icon={<MailRegular  />} />}>
                 E-Mail
                  </Tag>
                  <Tag secondaryText={`${fetchGuest?.paymentAmount?.toLocaleString()}`} media={<Avatar icon={<WalletRegular />}  />}>
                    Outstanding Balance
                  </Tag>
                  <Tag secondaryText={`${fetchGuest?.preferences}`} media={<Avatar icon={<CalendarMonthRegular  />}  />}>
                 Preferences
                  </Tag>
                  <Tag secondaryText={`${fetchGuest?.roomNumber}`} media={<Avatar icon={<BedFilled  />}  />}>
                     Room Checked In
                  </Tag>
                </div>

                </div>
                  </>
                  
                )}
                {selectedTab === 'tab3' && (
                  <>
                   <h2 className="inactiveTitleLarge-839">General information</h2>
                <div className="container-844">
                <div className="list-640 container-846" style={{marginBottom: '10px'}}>
                  {/* starts here  */}
                  <Tag secondaryText={`${fetchGuest?.address}, ${fetchGuest?.city} ${fetchGuest?.state}, ${fetchGuest?.country}`} media={<Avatar icon={<Location24Regular />} />}>
                  Home Address
                  </Tag>
                  <Tag secondaryText={ `${fetchGuest?.phone}`} media={<Avatar icon={<CallRegular  />}  />}>
                 Phone Number
                  </Tag>
                  <Tag secondaryText={`${fetchGuest?.email}`} media={<Avatar icon={<MailRegular  />} />}>
                 E-Mail
                  </Tag>
                  <Tag secondaryText={`${fetchGuest?.paymentAmount?.toLocaleString()}`} media={<Avatar icon={<WalletRegular />}  />}>
                    Outstanding Balance
                  </Tag>
                  <Tag secondaryText={`${fetchGuest?.preferences}`} media={<Avatar icon={<CalendarMonthRegular  />}  />}>
                 Preferences
                  </Tag>
                  <Tag secondaryText={`${fetchGuest?.roomNumber}`} media={<Avatar icon={<BedFilled  />}  />}>
                     Room Checked In
                  </Tag>
                </div>
                </div>
                  </>
                 
                )}
                {selectedTab === 'tab4' && (
                  <>
                  <h2 className="inactiveTitleLarge-839">Billing information</h2>
                <div className="container-844">
                <div className="list-640 container-846" style={{marginBottom: '10px'}}>
                  {/* starts here  */}
                  <Tag secondaryText={`${fetchGuest?.address}, ${fetchGuest?.city} ${fetchGuest?.state}, ${fetchGuest?.country}`} media={<Avatar icon={<Location24Regular />} />}>
                  Home Address
                  </Tag>
                  <Tag secondaryText={ `${fetchGuest?.phone}`} media={<Avatar icon={<CallRegular  />}  />}>
                 Phone Number
                  </Tag>
                  <Tag secondaryText={`${fetchGuest?.email}`} media={<Avatar icon={<MailRegular  />} />}>
                 E-Mail
                  </Tag>
                  <Tag secondaryText={`${fetchGuest?.paymentAmount?.toLocaleString()}`} media={<Avatar icon={<WalletRegular />}  />}>
                    Outstanding Balance
                  </Tag>
                  <Tag secondaryText={`${fetchGuest?.preferences}`} media={<Avatar icon={<CalendarMonthRegular  />}  />}>
                 Preferences
                  </Tag>
                  <Tag secondaryText={`${fetchGuest?.roomNumber}`} media={<Avatar icon={<BedFilled  />}  />}>
                     Room Checked In
                  </Tag>
                </div>
                </div>
                  </>
                 
                )}

                </div>
              </div>
            </div>
            <Divider />
          <div className="fui-DialogActions ___1mdwomm f3052tw f1ewtqcl f22iagw f1cztu7b fcu02xx f4px1ci fn67r4l f6glcwc f1e3st1r f1a7i8kp fd46tj4 fsyjsko f1f41i0t f1jaqex3 f2ao6jk">
            <Button className="Button-close" onClick={onClose}>
              cancel
            </Button>
          </div>
        </div>
        </div>
      
    </>
  );
};

export default GuestView;
