import React, { useCallback, useEffect, useState } from 'react';
import './EVTPayment.scss';
import {
  Button,
  Field,
  Input,
  InputOnChangeData,
  Label,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Radio,
  RadioGroup,
  Select,
  Tab,
  TabList,
  TableHeader,
  Text,
} from '@fluentui/react-components';
import visaLogo from '../../../../Themes/Images/Visa.svg';
import mastercardLogo from '../../../../Themes/Images/Master.svg';
import verveLogo from '../../../../Themes/Images/Verve-logo-dark.svg';
import None from '../../../../Themes/Images/None.svg';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import Loaders from '../../../../Common/Loader/Loader';
import DispatchToast from '../../../../Common/Toast/DispatchToast';
import {
  ChevronLeft24Regular,
  ChevronRight24Regular,
  WalletCreditCard24Regular,
} from '@fluentui/react-icons';
import {
  Avatar,
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeaderCell,
  TableCellLayout,
} from '@fluentui/react-components';
import { SearchBox } from '@fluentui/react-search-preview';
import { ITransaction } from '../../../../../Data/General/Transactions';
import axios from 'axios';
import { WHITE_HOUSE_API } from '../../../../../Constants/Constant';
import { IEventReservation } from '../../../../../Data/General/EventReservation';
import { HotelEventCenter } from '../../../../../Data/General/HotelEventCenter';




type Adjuster = {
  firstname?:string;
  lastname?:string;
  phoneNumber?:string;
  userid?: number;
}

interface User {
  email: string,
  phoneNumber:string,
  token:string,
}

  // other properties...
interface paymentsProps {
  onBarClose: () => void;
  user: User;
  customerid: any;
  servicesid:any;
}
const itemsPerPage = 10;

const EVTPayment: React.FC<paymentsProps> = ({onBarClose, user, customerid, servicesid}) => {
  const [currency, setCurrency] = useState('NGN');
  const currencySymbol = currency === 'NGN' ? '₦' : 'US$';
  const [expiryDate, setExpiryDate] = useState('');
  const [amount, setAmount] = useState<number>(0);
  const [total, setTotal] = useState<any>(0);
  const [adminFess, setAdminFess] = useState<any>(0);
  const [vateFees, setvateFees] = useState<any>(0);
  const [cardType, setCardType] = useState(None);
  const [responseMessage, setResponseMessage] = useState('');
  const [selectedTab, setSelectedTab] = useState('BankCard');
  const [transaction, setTransactions] = useState('Credit');
  const [customer, setCustomer] = useState<IEventReservation>();
  const [bookingEvent, setBookingEvent] = useState<HotelEventCenter>();
  const [historicalData, setHistricalData] = useState<ITransaction[]>([]);
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [dispatchToaster, setDispatchToaster] = useState<boolean>(false);
  const pageCount = Math.ceil(historicalData.length / itemsPerPage);
  const currentData = historicalData.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );
  const handleTabClick = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    setSelectedTab(value);
  };
  const handleAmountChange = (event:any) => {
    
    const newAmount = parseFloat(event);
    if (!isNaN(newAmount)) {
      setAmount(newAmount);
      const vat = newAmount * 0.075; // 7.5% VAT
      const fees = newAmount * 0.025; // 2.5% fees
      setAdminFess(fees);
      setvateFees(vat);
      setTotal(newAmount + fees);
    } else {
      setAmount(0);
      setTotal(0);
    }
  };

  const handleExpiryDateChange = (event: any) => {
    let value = event.target.value;
    if (value.length === 2 && expiryDate.length === 1) {
      value += '/';
    }
    if (value.length <= 7) {
      setExpiryDate(value);
    }
  };
  const handleCurrencyChange = (event: any) => {
    const option = event.target.value;
    setCurrency(option);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    const cardNumber = data.value || '';
    let type = '';
    if (cardNumber.startsWith('4')) {
      type = visaLogo;
    } else if (cardNumber.startsWith('5')) {
      type = mastercardLogo;
    } else if (cardNumber.startsWith('6')) {
      type = verveLogo;
    } else {
      type = None;
    }
    setCardType(type);
  };

  const getdetailsofBookingCustomer = useCallback(
    async (customerid:any, servicesid:any) => {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Payment/servicesid?servicesid=${servicesid}&customerid=${customerid}`,
        {
          headers: {
            'Authorization': `Bearer ${user.token}`
          },
        }
      );
      setCustomer(response.data);
    },
    [user.token]
  );

  const getdetailsofServices = useCallback(
    async (serviceid:any) => {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/EventServices/${serviceid}`,
        {
          headers: {
            'Authorization': `Bearer ${user.token}`
          },
        }
      );
      setBookingEvent(response.data);
    },
    [user.token]
  );

  const handleLeftTabClick = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    setTransactions(value);
  };


  const HandlePayment = () => {
    console.log("Just made a payment");
  }

  useEffect(() => {
    getdetailsofBookingCustomer(customerid, servicesid);
    getdetailsofServices(servicesid);
    console.log("Just made a payment serviceid: ", servicesid + "customerid: " + customerid);
    handleAmountChange(bookingEvent?.basePrice);
  }, [bookingEvent?.basePrice, customerid, getdetailsofBookingCustomer, getdetailsofServices, servicesid]);

  return (
    <>
       <div className="fui-FluentProvider fui-FluentProvider361 ___1bsm0mz f19n0e5 fxugw4r f1o700av fk6fouc fkhj508 figsok6 f1i3iumi f1euv43f f15twtuk f1vgc2s3 f1e31b4d f494woh">
      <div
        aria-hidden="true"
        className="fui-DialogSurface__backdrop payment-box ___adeqq90 f19dog8a fju19wo f113wtx2 f10k790i f5gq2j6 f1xynx9j"
      ></div>

      <div className="fui-DialogSurface payment-box EVT___sv ___1g5txne ftgm304 f1c515w f1bitti f15twtuk f1e31b4d f1yab3r1 f1vgc2s3 fuq56rw f15kemlc fl2zwns fdgang7 fgr6219 f1ujusj6 f10jk5vf fcgxt0o fzkkow9 fcdblym fg706s2 fjik90z f1ln0qer fa2wlxz f120kxnn f19dog8a f3052tw fvgz9i8 f6a9g1z f1ewtqcl f10nrhrw fxugw4r f19n0e5 f5ogflp f1hqa2wf f1f09k3d finvdd3 fghlq4f f1gn591s fb073pr fjscplz fnivh3a fc7yr5o f1el4m67 f8yange ff54dml ftqa4ok f2hkw1w f8hki3x f1d2448m f1bjia2o ffh67wi f15bsgw9 f14e48fq f18yb2kv fd6o370 fh1cnn4 fy7oxxb fpukqih f184ne2d frrh606 f1v5zibi ful5kiu fo2hd23 f1jqcqds ftffrms f2e7qr6 fsr1zz6 f1dvezut fd0oaoj fjvm52t f1cwg4i8 f57olzd f4stah7 f480a47 fs1por5">
        <div className="fui-DialogBody ___6dxt060 f13qh94s f120kxnn flnwrvu f1ewtqcl f1ln0qer fa2wlxz f4px1ci fn67r4l f1x46k84 fjj47a5 ff54dml f1t97isy">
          {isLoader && (
            <div className="restrict-the-inner">
              <Loaders />
            </div>
          )}
          {dispatchToaster && <DispatchToast message={responseMessage} />}
          <div className="header-content pay">
            <TabList defaultSelectedValue="BankCard">
                <Tab
                  value="BankCard"
                  onClick={(event) => handleTabClick(event, 'BankCard')}
                >
                  Bank Card
                </Tab>
                <Tab
                  value="BankAccount"
                  onClick={(event) => handleTabClick(event, 'BankAccount')}
                >
                  Bank Account
                </Tab>
                <Tab
                  value="BankTransfer"
                  onClick={(event) => handleTabClick(event, 'BankTransfer')}
                >
                  Bank Transfer
                </Tab>
                {/* <Tab
                  value="Paypal"
                  onClick={(event) => handleTabClick(event, 'Paypal')}
                >
                  Paypal
                </Tab> */}
              </TabList>
          </div>

          <div className="fui-DialogContent ___1dce55l f5zp4f f1nxs5xn f1ewtqcl faunodf f88nxoq fwpfdsa fsyjsko fr4fn7s f171n1a0 f1ean75l f1jwbs5a fxlnl9e ffs23cn f2uu7rc f1v5tmjd fk6fouc fkhj508 figsok6 f1i3iumi">
            <h3 className="user-initiating">{''}</h3>
            <div className="m-deposit-card m-deposit-way">
                {selectedTab === 'BankCard' && (
                  <div className="deposit-content">
                    <div className="curency-payment-method">
                      <div className="currency-rate">
                        <Label>Choose a currency</Label>
                        <RadioGroup
                          defaultValue="NGN"
                          onChange={handleCurrencyChange}
                          aria-labelledby="ageLabel"
                          className="wc-flex"
                        >
                          <Radio value="NGN" label="NGN" />
                          <Radio value="USD" label="USD" />
                        </RadioGroup>
                      </div>
                      <div className="methos-of">
                        <Label>Your payment method</Label>
                        <div className="methods-of-payments">
                          <span>
                            <span className="" data-testid="VISA">
                              <img src={visaLogo} alt="" />
                            </span>
                          </span>
                          <span>
                            <span className="" data-testid="MASTERCARD">
                              <img src={mastercardLogo} alt="" />
                            </span>
                          </span>
                          <span>
                            <span className="" data-testid="Verve">
                              <img src={verveLogo} alt="" />
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="w-add-Guest-room Guest-management-job ">
                      <div className="w-add-room-guest rms-block-left">
                        <Label> Card Number</Label>
                        <div className="firstName-container">
                          <Input
                            placeholder="Card Number"
                            type="number"
                            onChange={handleInputChange}
                            contentAfter={<img src={cardType} alt="" />}
                          />
                        </div>
                      </div>

                      <div className="w-add-room-guest rms-block-right wc-margin">
                        <div className="w-add-room-guest rms-inline-property-title wc-margin">
                          Expiring Date
                        </div>
                        <div className="firstName-container">
                          <Input
                            type="text"
                            name="expiryDate"
                            placeholder="MM/YYYY"
                            value={expiryDate}
                            onChange={handleExpiryDateChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-add-Guest-room Guest-management-job">
                      <div className="w-add-room-guest rms-block-left">
                        <div className="w-add-room-guest Guest-inline-property-title">
                          CVV
                        </div>
                        <div className="firstName-container">
                          <Input
                            placeholder="CVV"
                            appearance="filled-darker"
                            type="password"
                            id="filledDarkerId"
                          />
                        </div>
                      </div>

                      <div className="w-add-room-guest rms-block-right wc-margin">
                        <div className="w-add-room-guest rms-inline-property-title wc-margin">
                          Amount
                        </div>
                        <div className="firstName-container">
                          <Input
                            placeholder="Amount to Credit"
                            type="number"
                            value={amount.toString()}
                            disabled={true}
                            contentBefore={
                              <Text size={400} id="beforeLabelId-400">
                                {currencySymbol}
                              </Text>
                            }
                            contentAfter={
                              <Text size={400} id="afterLabelId-400">
                                .00
                              </Text>
                            }
                            appearance="filled-darker"
                            id="filledDarkerId-02"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {selectedTab === 'BankAccount' && (
                  <div className="deposit-content">
                    <div className="curency-payment-method">
                      <div className="currency-rate">
                        <Label>Choose a currency</Label>
                        <RadioGroup
                          defaultValue="NGN"
                          onChange={handleCurrencyChange}
                          aria-labelledby="ageLabel"
                          className="wc-flex"
                        >
                          <Radio value="NGN" label="NGN" />
                          <Radio value="USD" label="USD" />
                        </RadioGroup>
                      </div>
                      <div className="methos-of">
                        <Label>Your payment method</Label>
                        <div className="methods-of-payments">
                          <span>
                            <span className="" data-testid="VISA">
                              <img src={visaLogo} alt="" />
                            </span>
                          </span>
                          <span>
                            <span className="" data-testid="MASTERCARD">
                              <img src={mastercardLogo} alt="" />
                            </span>
                          </span>
                          <span>
                            <span className="" data-testid="Verve">
                              <img src={verveLogo} alt="" />
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="select-ALl m-card-default-form">
                      <div className="deposit-form-item">
                        <div
                          data-v-4666fd94=""
                          className="deposit-form-item-label"
                        >
                          Select bank
                        </div>
                        <div className="section-item-wrap">
                          <Select id="selectId">
                            <option>Select Bank....</option>
                            <option>Kuda Bank</option>
                            <option>GTBank PlC</option>
                            <option>Access Bank</option>
                          </Select>
                        </div>
                      </div>

                      <div className="deposit-form-item">
                        <div
                          data-v-4666fd94=""
                          className="deposit-form-item-label amount-marked"
                        >
                          Amount
                        </div>
                        <div className="section-item-wrap border-style">
                          <Input
                            placeholder="Amount to Credit"
                            type="number"
                            value={amount.toString()}
                            disabled={true}
                            onChange={handleAmountChange}
                            contentBefore={
                              <Text size={400} id="beforeLabelId-400">
                                {currencySymbol}
                              </Text>
                            }
                            contentAfter={
                              <Text size={400} id="afterLabelId-400">
                                .00
                              </Text>
                            }
                            appearance="filled-darker"
                            id="filledDarkerId-02"
                          />
                        </div>
                      </div>
                    </div>
                   
                  </div>
                )}

                {selectedTab === 'BankTransfer' && (
                  <div className="deposit-content">
                    <div className="curency-payment-method">
                      <div className="currency-rate">
                        <Label>Choose a currency</Label>
                        <RadioGroup
                          defaultValue="NGN"
                          onChange={handleCurrencyChange}
                          aria-labelledby="ageLabel"
                          className="wc-flex"
                        >
                          <Radio value="NGN" label="NGN" />
                          <Radio value="USD" label="USD" />
                        </RadioGroup>
                      </div>
                      <div className="methos-of">
                        <Label>Your payment method</Label>
                        <div className="methods-of-payments">
                          <span>
                            <span className="" data-testid="VISA">
                              <img src={visaLogo} alt="" />
                            </span>
                          </span>
                          <span>
                            <span className="" data-testid="MASTERCARD">
                              <img src={mastercardLogo} alt="" />
                            </span>
                          </span>
                          <span>
                            <span className="" data-testid="Verve">
                              <img src={verveLogo} alt="" />
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="select-ALl m-card-default-form">
                      <div className="deposit-form-item">
                        <div
                          data-v-4666fd94=""
                          className="deposit-form-item-label amount-marked"
                        >
                          Amount
                        </div>
                        <div className="section-item-wrap border-style">
                          <Input
                            placeholder="Amount to Credit"
                            type="number"
                            value={amount.toString()}
                            disabled={true}
                            onChange={handleAmountChange}
                            contentBefore={
                              <Text size={400} id="beforeLabelId-400">
                                {currencySymbol}
                              </Text>
                            }
                            contentAfter={
                              <Text size={400} id="afterLabelId-400">
                                .00
                              </Text>
                            }
                            appearance="filled-darker"
                            id="filledDarkerId-02"
                          />
                        </div>
                      </div>
                    </div>
                   
                  </div>
                )}
                <div className="m-powered-pay rspayment-seenir">
                 
                  <div className="amount-frc">
                    <div className="customer-container">
                    <div className="billed-user">{customer?.firstName} {customer?.lastName}</div>
                    <span>is paying for <strong>({bookingEvent?.eventCenterName})</strong></span>
                    <span className="bottom-margin"></span>
                    </div>
                
                    <div>
                      <b>VAT</b> <small>7.5% = </small> N/A
                    </div>
                    <div className="Process-fees">
                      <b>Process Fee</b> <small>2.5% =</small> {adminFess}
                    </div>
                    <div className="Were-status">
                      Total: {currencySymbol}{' '}
                      {new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(total)}
                    </div>
                  </div>
                </div>
              </div>
          </div>
      
          <div className="fui-DialogActions ___1mdwomm f3052tw f1ewtqcl f22iagw f1cztu7b fcu02xx f4px1ci fn67r4l f6glcwc f1e3st1r f1a7i8kp fd46tj4 fsyjsko f1f41i0t f1jaqex3 f2ao6jk">
            <Button className="Button-Submit" icon={<WalletCreditCard24Regular />} onClick={HandlePayment}>
            Pay : {currencySymbol}
                        {new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(total)}
            </Button>
            <Button className="Button-close" onClick={onBarClose}>
              cancel
            </Button>
          </div>
         
        </div>
      </div>
    </div>
    
    </>
  );
};

export default EVTPayment;
