import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import '../AppHome.scss';
import './Activity.scss';
import '../../../Themes/Fonts/Icon/csc.scss';
import {
  Invent,
  Inventory,
  Settups,
  Workflow,
} from '../../../../Data/ServicesMenu';
import {
  Button,
  Field,
  Input,
  makeStyles,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  SplitButton,
} from '@fluentui/react-components';
import { SearchBox } from '@fluentui/react-search-preview';
import { Breadcrumb } from '@fluentui/react-breadcrumb-preview';
import {
  PeopleCommunityAdd24Filled,
  CalendarToday24Regular,
  PeopleCommunityAdd24Regular,
  PeopleCommunityAddRegular,
  List20Regular,
} from '@fluentui/react-icons';

import { Employee, GetEmployee } from '../../../../Data/General/Employee';
import { DateTime } from 'luxon';
import {
  IContextualMenuProps,
  IIconProps,
  initializeIcons,
} from '@fluentui/react';
import { Reservation } from '../../../../Data/General/Reservation';
import axios, { AxiosError } from 'axios';
import { Room } from '../../../../Data/General/Room';
import { WHITE_HOUSE_API } from '../../../../Constants/Constant';
import OnlineRequest from '../../Book/Menu/OnlineRequest';
import RegisterEmployee from './RegistrationDialog/NewEmployee';
import StaffManagement from './StaffManagement/StaffManagement';
import Roles from '../../RolesManagement/Roles';
import ShiftTime from '../../CreateShift/ShiftTime';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import { SearchBoxProps } from '@fluentui/react-search-preview';
import TitleForm from '../../Title/TitleForm';
import GenderForm from '../../Gender/GenderForm';
import Invoice from '../../Invoice/Invoice';
import RaiseInvoice from '../../Invoice/RaiseInvoice/RaiseInvoice';
import BarServices from '../../BarServices/Barservices';
import CreateBarService from '../../BarServices/BarFiorms/CreateBarServices';
import BarCategoryForm from '../../BarCategory/BarCategoryForm/BarCategoryForm';
import BarCategory from '../../BarCategory/BarCategory';
import Amenity from '../../Amenity/Amenity';
import AmenityForm from '../../Amenity/AmenityForm/AmenityForm';
import EventServices from '../../EventServices/EventServices';
import EventServiceForm from '../../EventServices/EventServiceForm/EventServiceForm';
import AmenityTypesForm from '../../AmenityTypes/AmenityTypesForm/AmenityTypeForm';
import AmenityTypes from '../../AmenityTypes/AmenityTypes';
import EmployeBoard from '../../EmployeBoards/EmployeBoard';
import TitleManagement from '../../TitleManagement/TitleManagement';
import GenderManagement from '../../GenderManagement/GenderManagement';
import TaskManagement from '../../TaskManagement/TaskManagement';
import DeletingEmployee from '../../DeletingEmployee/DeleteingEmploye';
import TaskManagementForm from '../../TaskMangerForm/TaskManagerForm';
import ReservationTypeService from '../../ReservationServices/ReserviceTypeServices';
import ReservationType from '../../ReservationTypes/ReservationType';
import RestaurantServices from '../../RestaurantServices/RestaurantServices';
import RestaurantMenuForm from '../../RestaurantMenuForm/RestaurantMenuForm';
import Department from '../../Departments/Department';
import Units from '../../Units/Units';
import BusinesLocation from '../../Location/Location';
import DepartmentForm from '../../Departments/DepartmentForm/DepartmentForm';
import UnitsForm from '../../Units/UnitsForm/UnitsForm';
import LocationForm from '../../Location/LocationForm/LocationForm';
import DeletingLocation from '../../Location/DeletingLocation/DeletingLocation';
import DeletingDepartment from '../../Departments/DeletingDepartment/DeletingDepartment';
import DeletingUnit from '../../Units/DeletingUnit/DeletingUnit';
import LoundryServices from '../../LoundryServices/LoundryServices';
import LaundryServiceForm from '../../LoundryServices/LaundryServiceForm/LaundryServiceForm';
import HotelProducts from '../../HotelProducts/HotelProducts';
import HotelProductForm from '../../HotelProducts/HotelProductsForm/HotelProductForm';
import RoleManager from '../../RoleManage/RoleManager';
import RoomManagement from '../../RoomManagement/RoomManagement';
import RoomForm from '../../RoomManagement/RoomForm/RoomRegisterationForm';
import RoomRegisterationForm from '../../RoomManagement/RoomForm/RoomRegisterationForm';
const useStyles = makeStyles({
  control: {
    maxWidth: '300px',
  },
  tooltip: {
    backgroundColor: 'rgba(0, 120, 212)',
    color: '#F5F5F5',
  },
});
// Suppress icon warnings.

interface User {
  email: string;
  phoneNumber: string;
  token: string;
}
interface Message {
  message: string;
  id: number;
}
type Adjuster = {
  firstname?: string;
  lastname?: string;
  phoneNumber?: string;
  userid?: number;
};
type Props = {
  items: Inventory[];
  settings: Workflow[];
  selectedOption?: string;
  user: User;
  message: Message;
};
const initialEmployeeData: Employee = {
  HireDate: null,
  DateOfBirth: null,
  Passport: new File([], ''),
  Credentials: new File([], ''),
  PhoneNumber: null,
  EmegergencyContactPhone: null,
  Title: null,
  Gender: 'Male',
  EmployeeId: null,
  Phone: null,
};
const initialReservationData: Reservation = {
  roomId: null,
  guestFirstName: null,
  guestLastName: null,
  guestPhone: null,
  guestEmail: null,
  CancellationPolicy: null,
  checkInDate: null,
  checkOutDate: null,
  specialRequests: null,
  numberOfGuests: null,
  checkInTime: null,
  checkOutTime: null,
  additionalGuests: null,
  reservationType: null,
  assignedRoomNumber: null,
  numberOfNights: null,
  notes: null,
  roomType: null,
};
interface RoleselectedState {
  selectedRole: string;
}

interface IsFocused {
  [key: string]: boolean;
}

interface Titles {
  titleId: number;
  titleName: string;
}

interface Roles {
  roleId: number;
  roleName: string;
}

interface Departments {
  departmendId: number;
  departmentName: string;
}

const ActivityApp: React.FC<Props> = ({ user, message }) => {
  const [getemployee, setGetEmployee] = useState<Employee>(initialEmployeeData);
  const [getReservation, setGetReservation] = useState<Reservation>(
    initialReservationData
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const [availableRooms, setavailableRooms] = useState<Room[]>([]);
  const [availableemployees, setavailableemployees] = useState<GetEmployee[]>(
    []
  );
  const [isActive, setActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isCheckActive, setIsCheckActive] = useState(false);
  const [showOnlineReservation, setShowOnlineReservation] =
    useState<boolean>(true);
  const [showManageStaff, setShowManageStaff] = useState<boolean>(false);
  const [showStaffSchedule, setShowStaffSchedule] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = React.useState('Choose role');
  const [selectedRoom, setSelectedRoom] = React.useState('Choose Room');
  const [selectedTitle, setSelectedTitle] = React.useState('Choose Title');
  const [selectedDepartment, setSelectedDepartment] =
    React.useState('Choose Department');
  const [romeMapping, setRomeMapping] = useState<IContextualMenuProps>({
    items: [],
  });
  const [visibleItem, setVisibleItem] = useState<number | null>(null);
  const [roleMapping, setRoleMapping] = useState<Roles[]>([]);
  const [departmentMapping, setdepartmentMapping] = useState<Departments[]>([]);
  const [eventMapping, setEventMapping] = useState<IContextualMenuProps>({
    items: [],
  });
  const [titleMapping, setTitleMapping] = useState<Titles[]>([]);
  const [selectedEvent, setSelectedEvent] = React.useState('Choose Event');
  const [selectedDetails, setSelectedDetails] = React.useState<
    Record<string, boolean>
  >({});
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [selectedEmployeeFile, setselectedEmployeeFile] =
    React.useState<File | null>(null);
  const [isFocused, setIsFocused] = useState<IsFocused>({});
  const [eventVisible, setEventVisible] = useState<Boolean>(false);
  const [roomVisible, setRoomVisible] = useState<Boolean>(true);
  const [showAutocomplete, setShowAutocomplete] = useState<Boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setsuccessMessage] = useState('');
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [saveLaoding, setSaveLaoding] = useState<boolean>(false);
  const [showdropdown, setshowdropdown] = useState<IsFocused>({});
  const [showdRolesropdown, setshowRolesdropdown] = useState<IsFocused>({});
  const [showDepartmentsdropdown, setshowDepartmentsdropdown] =
    useState<IsFocused>({});
  const [visibleItems, setVisibleItems] = useState(5);
  const [visibleActivityItem, setVisibleActivityItem] = useState<number>(0);
  const [filteredOptions, setFilteredOptions] = useState(titleMapping);
  const [filteredRolesOptions, setFilteredRolesOptions] = useState(roleMapping);
  const [filteredDepartmentOptions, setfilteredDepartmentOptions] =
    useState(departmentMapping);
  const [isRoleOpen, setIsRoleOpen] = useState<boolean>(false);
  const [isTitleOpen, setIsTitleOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Inventory | null>(null);
  const [selectedWorkflow, setSelectedWorkflow] = useState<Workflow | null>(
    null
  );
  const [closeRegister, setCloseRegister] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
  const [OnlineReservation, SetOnlineReservation] = useState<[]>([]);
  const [closeInvoice, setCloseInvoice] = useState<boolean>(false);
  const [isToggledState, setToggleState] = useState<boolean>(false);
  const [closeMenuBar, setCloseMenuBar] = useState<boolean>(false);
  const [closeMenuBarCat, setCloseMenuBarCat] = useState<boolean>(false);
  const [closeEventService, setCloseEventServices] = useState<boolean>(false);
  const [closeAmenity, setCloseAmenity] = useState<boolean>(false);
  const [amenityTypes, sectAmenityTypes] = useState<boolean>(false);
  const [amenities, setAmenities] = useState<boolean>(false);
  const [newEmployee, setNewEmployee] = useState<boolean>(false);
  const [newTitle, setNewTitle] = useState<boolean>(false);
  const [newGender, setNewGender] = useState<boolean>(false);
  const [newTask, setNewTask] = useState<boolean>(false);
  const [openDeletedEmployee, setOpenDeleteEmployee] = useState<boolean>(false);
  const [openDeleteLocation, setOpenDeleteLocation] = useState<boolean>(false);
  const [reservationType, setReservationType] = useState<boolean>(false);
  const [restaurantServices, setRestaurantServices] = useState<boolean>(false);
  const [openDeleteDepartment, setOpenDeleteDepartment] =
    useState<boolean>(false);
  const [openDeleteUnit, setOpenDeleteUnit] = useState<boolean>(false);
  const [locationForm, setLocationForm] = useState<boolean>(false);
  const [loundryForm, setLoundryForm] = useState<boolean>(false);
  const [loundryDeleteForm, setLoundryDeleteForm] = useState<boolean>(false);
  const [unitForm, setUnitForm] = useState<boolean>(false);
  const [departMentForm, DepartMentForm] = useState<boolean>(false);
  const [laundryServicesForm, SetLaundryServicesForm] =
    useState<boolean>(false);
  const [hotelProductForm, setHotelProductForm] = useState<boolean>(false);
  const [manageRoomForm, setManageRoomForm] = useState<boolean>(false);




  const styles = useStyles();
  const handleOptionClick = (option: any, inputName: any) => {
    setSelectedTitle(option.titleName);
    setGetEmployee((prevInputValues) => {
      return {
        ...prevInputValues,
        Title: option.titleName,
      };
    });
    setFilteredOptions(titleMapping);
    setshowdropdown((prevState) => ({ ...prevState, [inputName]: false }));
  };

  const togglePopupWindow = (value: string) => {
    setSelectedDetails((prevState) => ({
      ...prevState,
      [value]: !prevState[value],
    }));
  };

  const handleRolesOptionClick = (ro: any, inputs: any) => {
    setSelectedRole(ro.roleName);
    setGetEmployee((prevInputValues) => {
      return {
        ...prevInputValues,
        Role: ro.roleName,
      };
    });
    setFilteredRolesOptions(roleMapping);
    setshowRolesdropdown((prevState) => ({ ...prevState, [inputs]: false }));
  };

  const handleDepartmentOptionClick = (dep: any, depinputs: any) => {
    setSelectedDepartment(dep.departmentName);
    setGetEmployee((prevInputValues) => {
      return {
        ...prevInputValues,
        Department: dep.departmentName,
      };
    });
    setfilteredDepartmentOptions(departmentMapping);
    setshowDepartmentsdropdown((prevState) => ({
      ...prevState,
      [depinputs]: false,
    }));
  };

  const handleFocus = (inputName: any) => {
    setIsFocused((prevState) => ({ ...prevState, [inputName]: true }));
  };

  const handleDropdownFocus = (name: any) => {
    setIsFocused((prevState) => ({ ...prevState, [name]: true }));
    setshowdropdown((prevState) => ({ ...prevState, [name]: true }));
  };

  const handleRoleDropdownFocus = (name: any) => {
    setshowRolesdropdown((prevState) => ({ ...prevState, [name]: true }));
  };

  const handleDepartmentDropdownFocus = (name: any) => {
    setshowDepartmentsdropdown((prevState) => ({ ...prevState, [name]: true }));
  };

  const handleDropdownBlur = (name: any) => {
    setIsFocused((prevState) => ({ ...prevState, [name]: false }));
  };

  const handleBlur = (inputName: any) => {
    setIsFocused((prevState) => ({ ...prevState, [inputName]: false }));
  };

  // const handleRoleClick = (role: string) => {
  //     setSelectedRole(role);
  // };
  const HandleAllSaveChanges = async (names: string) => {
    switch (names) {
      case 'New Employee':
        handleSubmitEmployeeClick();
        break;
      case 'New  Task':
        handleSubmitReservationClick();
        break;
      // add more cases here to handle other names
      default:
        break;
    }
  };

  const onFormatDatePlaceholder = (date: any) => {
    return date
      ? date.toLocaleDateString('en-US', {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        })
      : '';
  };
  const handleScroll = (event: any) => {
    const target = event.target;
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      setVisibleItems((prevVisibleItems) => prevVisibleItems + 5);
    }
  };
  const handleRoleClick = (role: string) => {
    setSelectedRole(role);
    setGetEmployee((prevInputValues) => ({
      ...prevInputValues,
      Role: role,
    }));
  };

  const handleTitleClick = (title: string) => {
    setSelectedTitle(title);
    setGetEmployee((prevInputValues) => ({
      ...prevInputValues,
      Title: title,
    }));
  };
  const handleDepartmentClick = (dep: string) => {
    setSelectedDepartment(dep);
    setGetEmployee((prevInputValues) => ({
      ...prevInputValues,
      Department: dep,
    }));
  };

  const handleEventClick = (role: string) => {
    setSelectedEvent(role);
  };
  const handleRooomClick = (role: string) => {
    setSelectedRoom(role);
  };

  const handleAnyClick = () => {
    togglePopupWindow('New Employee');
  };

  initializeIcons();
  function _onChange(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
    setEventVisible(!eventVisible);
    setRoomVisible(!roomVisible);

    const gender = checked ? 'Male' : 'Female';
    setGetEmployee((prevInputValues) => ({
      ...prevInputValues,
      Gender: gender,
    }));
  }

  const formatEmployeesDate = (date: Date) => {
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
  };

  function getFirstCharacters(firstName: string, lastName: string): string {
    let result = '';
    let words = [firstName, lastName];
    for (let i = 0; i < words.length; i++) {
      result += words[i].charAt(0);
    }
    return result.toUpperCase();
  }

  const todaysDate = formatEmployeesDate(new Date());

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };
  const handleEmployeeFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      setselectedEmployeeFile(event.target.files[0]);
    }
  };

  const handleSubmitReservationClick = async () => {
    try {
      const formData = new FormData();
      formData.append('data', JSON.stringify(''));
      if (selectedFile) {
        formData.append('image', selectedFile);
      }

      const response = await fetch('', {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitEmployeeClick = async () => {
    setSaveLaoding(true);
    let isValid = true;
    if (!getemployee.PhoneNumber) {
      isValid = false;
      message.message += 'Phone number is required. ';
      setTimeout(() => {
        setSaveLaoding(false);
      }, 3000);
    }
    setErrorMessage(message.message);
    if (isValid) {
      try {
        setIsLoader(true);
        const employee = new FormData();

        for (const [key, value] of Object.entries(getemployee)) {
          switch (key) {
            case 'Credentials':
              employee.append('Credentials', value);
              break;
            case 'Passport':
              employee.append('Passport', value);
              break;
            default:
              if (value instanceof Date) {
                const outputFormat = 'yyyy-MM-dd';
                const formattedDate =
                  DateTime.fromJSDate(value).toFormat(outputFormat);
                employee.append(key, formattedDate);
              } else {
                employee.append(key, value.toString());
              }
          }
        }

        const response = await axios.post(
          `${WHITE_HOUSE_API}/Employee/register`,
          employee,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setTimeout(() => {
          if (response.status === 200) {
            message += response.data.message;
            setsuccessMessage(message.message);
            setIsLoader(false);
            setSaveLaoding(false);
          }
        }, 3000);
      } catch (error) {
        console.error(error);
        setIsLoader(false);
        setSaveLaoding(false);
        if (axios.isAxiosError(error)) {
          setTimeout(() => {
            const axiosError = error as AxiosError<Message>;
            if (
              axiosError.response &&
              axiosError.response.data &&
              axiosError.response.data.message
            ) {
              message.message += axiosError.response.data.message;
              setErrorMessage(message.message);
              setIsLoader(false);
              setSaveLaoding(false);
            }
          }, 1000);
        }
      }
    }
  };
  const handleDateChangeUsers = (date: any) => {
    if (date) {
      const formattedDate = date.toISOString().split('T')[0]; // Format date as 'yyyy-MM-dd'
      setSelectedDate(formattedDate);
    }
  };
  const addFriendIcon: IIconProps = { iconName: 'AddFriend' };

  function handleDateChangeA(date: Date | null): void {
    if (date) {
      const outputFormat = 'yyyy-MM-dd';
      const outputDate = DateTime.fromJSDate(date).toFormat(outputFormat);
      setGetEmployee((prevData: Employee) => ({
        ...prevData,
        HireDate: DateTime.fromFormat(outputDate, outputFormat).toJSDate(),
      }));
    }
  }

  function handleReservationDateChangeA(date: Date | null): void {
    if (date) {
      const outputFormat = 'yyyy-MM-dd';
      const outputDate = DateTime.fromJSDate(date).toFormat(outputFormat);
      setGetReservation((prevData: Reservation) => ({
        ...prevData,
        checkInDate: DateTime.fromFormat(outputDate, outputFormat).toJSDate(),
      }));
    }
  }
  function handleReservationDateChangeB(date: Date | null): void {
    if (date) {
      const outputFormat = 'yyyy-MM-dd';
      const outputDate = DateTime.fromJSDate(date).toFormat(outputFormat);
      setGetReservation((prevData: Reservation) => ({
        ...prevData,
        checkOutDate: DateTime.fromFormat(outputDate, outputFormat).toJSDate(),
      }));
    }
  }
  function handleDateChangeB(date: Date | null): void {
    if (date) {
      const outputFormat = 'yyyy-MM-dd';
      const outputDate = DateTime.fromJSDate(date).toFormat(outputFormat);
      setGetEmployee((prevData: Employee) => ({
        ...prevData,
        DateOfBirth: DateTime.fromFormat(outputDate, outputFormat).toJSDate(),
      }));
    }
  }
  const handleInputChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setGetEmployee((prevInputValues) => ({
        ...prevInputValues,
        [field]: value,
      }));
      setFilteredOptions(
        titleMapping.filter((option) =>
          option.titleName.toLowerCase().includes(value.toLowerCase())
        )
      );
    };
  const handleRolesInputChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value;
      setGetEmployee((prevInputValues) => ({
        ...prevInputValues,
        [field]: inputValue,
      }));
      setFilteredRolesOptions(
        roleMapping.filter((ro) =>
          ro.roleName.toLowerCase().includes(inputValue.toLowerCase())
        )
      );
    };

  const handleDepartmentInputChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const department = event.target.value;
      setGetEmployee((prevInputValues) => ({
        ...prevInputValues,
        [field]: department,
      }));
      setfilteredDepartmentOptions(
        departmentMapping.filter((dep) =>
          dep.departmentName.toLowerCase().includes(department.toLowerCase())
        )
      );
    };
  const handleReservationChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setGetReservation((prevInputValues) => ({
        ...prevInputValues,
        [field]: event.target.value,
      }));
    };
  const onFormatDate = (date?: Date): string => {
    return !date
      ? ''
      : date.getDate() +
          '/' +
          (date.getMonth() + 1) +
          '/' +
          (date.getFullYear() % 100);
  };
  const handleClick = () => {
    setIsCheckActive(!isActive);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const handleTextChange =
    (field: string) => (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setGetReservation((prevInputValues) => ({
        ...prevInputValues,
        [field]: event.target.value,
      }));
    };

  const toggleClass = () => {
    setActive(!isActive);
  };
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const filteredItems = Invent.filter((item: any) => {
    return item.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const filteredWorkflows = Settups.filter((item: any) => {
    return item.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const fetchEmployees = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/Employee`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setavailableemployees(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);

  const fetchRomes = useCallback(async () => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Rooms/AllAvailable`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const romes = response.data;
      setavailableRooms(response.data);
      const romeItems = romes.map((rome: any) => ({
        key: rome.roomId,
        text: rome.roomName,
        iconProps: { iconName: 'Person' },
        onClick: () =>
          handleRooomClick(rome.roomName + '(' + rome.roomNumber + ')'),
      }));
      setRomeMapping({ items: romeItems });
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);

  const fetchEvents = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/Event`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const events = response.data;
      const eventItems = events.map((evnt: any) => ({
        key: evnt.EventId,
        text: evnt.EventName,
        iconProps: { iconName: 'Person' },
        onClick: () => handleEventClick(evnt.EventName),
      }));
      setEventMapping({ items: eventItems });
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);

  const fetchRoles = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/Role`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const roles = response.data;
      setRoleMapping(roles);
      setFilteredRolesOptions(roles);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  }, [user.token]);

  const fetchTitles = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/Title`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const Titles = response.data;
      setTitleMapping(Titles);
      setFilteredOptions(Titles);
    } catch (error) {}
  }, [user.token]);

  const fetchDepartments = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/Department`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const departs = response.data;
      setdepartmentMapping(departs);
      setfilteredDepartmentOptions(departs);
    } catch (error) {}
  }, [user.token]);

  const fetchOnlineReservation = useCallback(async () => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/FetchOnlineRequest/Fetch
      `,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (response.data) {
        SetOnlineReservation(response.data);
      }
    } catch (error) {}
  }, [user.token]);

  const handleItemClick = (item: any) => {
    setVisibleActivityItem(item.id);
    setSelectedWorkflow(item);
    setSelectedItem(item);
  };

  const sortedItems = filteredItems.sort((a: any, b: any) =>
    a.name.localeCompare(b.name)
  );
  if (selectedItem) {
    sortedItems.unshift(
      sortedItems.splice(sortedItems.indexOf(selectedItem), 1)[0]
    );
  }

  const sortedWorkflows = filteredWorkflows.sort((a: any, b: any) =>
    a.name.localeCompare(b.name)
  );
  if (selectedWorkflow) {
    sortedWorkflows.unshift(
      sortedWorkflows.splice(sortedWorkflows.indexOf(selectedWorkflow), 1)[0]
    );
  }

  const HandleRoleClose = () => {
    setIsRoleOpen(false);
  };

  // const uniqueItems = Array.from(
  //   new Set(sortedItems.map((item) => item.name))
  // ).map((name) => {
  //   return sortedItems.find((item) => item.name === name);
  // });
  const uniqueItems = Array.from(
    new Set(
      sortedItems
        .filter((item) => item !== undefined && item !== null)
        .map((item) => item.name)
    )
  ).map((name) => {
    return sortedItems.find((item) => item && item.name === name);
  });

  const uniqueWorkflows = Array.from(
    new Set(
      sortedWorkflows
        .filter((item) => item !== undefined && item !== null)
        .map((item) => item.name)
    )
  ).map((name) => {
    return sortedWorkflows.find((item) => item && item.name === name);
  });

  useEffect(() => {
    fetchEvents();
    fetchRomes();
    fetchRoles();
    fetchTitles();
    fetchDepartments();
    fetchEmployees();
  }, [
    fetchEvents,
    fetchRomes,
    fetchRoles,
    fetchTitles,
    fetchDepartments,
    fetchEmployees,
    user.token,
    fetchOnlineReservation,
  ]);

  const OpenRaiseInvoice = () => {
    setCloseInvoice(true);
  };

  const OpenMenuLoungBar = () => {
    setCloseMenuBar(true);
  };
  const closeMenuLoungBar = () => {
    setCloseMenuBar(false);
  };

  const CloseRaiseInvoice = () => {
    setCloseInvoice(false);
  };


  const handleCloseForm = () => {
    setManageRoomForm(false);
  }
  const findMatchingEmployee = (
    user: User,
    availableEmployees: GetEmployee[]
  ): GetEmployee | null => {
    for (let employee of availableEmployees) {
      if (
        employee.email === user.email &&
        employee.phoneNumber === user.phoneNumber
      ) {
        return employee; // This line returns the matching employee
      }
    }
    return null; // This line returns null if no matching employee is found
  };
  let matchingEmployee = findMatchingEmployee(user, availableemployees);

  const adjuster = {
    firstname: matchingEmployee?.firstName || '',
    lastname: matchingEmployee?.lastName || '',
    phoneNumber: matchingEmployee?.phoneNumber || '',
    userid: matchingEmployee?.employeeId,
  };

  const handleStateClick = () => {
    setToggleState((stu) => !stu);
  };
  const handleCateclose = () => {
    setCloseMenuBarCat(false);
  };

  const handleOpenCat = () => {
    setCloseMenuBarCat(true);
  };
  const handleOpenAmenity = () => {
    setCloseAmenity(true);
  };
  const handlecloseAmenity = () => {
    setCloseAmenity(false);
  };

  const handleOpenEventService = () => {
    setCloseEventServices(true);
  };
  const handleCloseEventService = () => {
    setCloseEventServices(false);
  };

  const handleOpenAmenityTypes = () => {
    sectAmenityTypes(true);
  };

  const handleCloseAmenityTypes = () => {
    sectAmenityTypes(false);
  };

  const handleOpenEmployeeForm = () => {
    setNewEmployee(true);
  };

  const handleRoomFormOpen = () => {
    setManageRoomForm(true);
  }


  const handleRoomFormClose = () => {
    setManageRoomForm(false);
  }
  const handleOpenEmployeeDeletionForm = () => {
    setOpenDeleteEmployee(true);
  };

  const handleOpenUnitDeletionForm = () => {
    setOpenDeleteUnit(true);
  };

  const handleCloseUnitDeletionForm = () => {
    setOpenDeleteUnit(false);
  };
  const handleOpenLocationDeletionForm = () => {
    setOpenDeleteLocation(true);
  };

  const handleCloseLocationDeletionForm = () => {
    setOpenDeleteLocation(false);
  };
  const handleOpenDepartMentDeletionForm = () => {
    setOpenDeleteDepartment(true);
  };

  const handleCloseDepartMentDeletionForm = () => {
    setOpenDeleteDepartment(false);
  };
  const handleCloseEmployeeDeletionForm = () => {
    setOpenDeleteEmployee(false);
  };
  const handleCloseEmployeeForm = () => {
    setNewEmployee(false);
  };

  const handleOpenTitleForm = () => {
    setNewTitle(true);
  };

  const handleOpenGenderForm = () => {
    setNewGender(true);
  };
  const handleOpenTaskForm = () => {
    setNewTask(true);
  };
  const handleCloseTitle = () => {
    setNewTitle(false);
  };

  const handleCloseGender = () => {
    setNewGender(false);
  };

  const handleTaskCloseForm = () => {
    setNewTask(false);
  };

  const handleOpenReservationType = () => {
    setReservationType(true);
  };

  const handleCloseReservationType = () => {
    setReservationType(false);
  };

  const handleOpenLocationForm = () => {
    setLocationForm(true);
  };

  const handleCloseLocationForm = () => {
    setLocationForm(false);
  };

  const handleOpenRestuarantService = () => {
    setRestaurantServices(true);
  };
  const handleCloseRestuarantService = () => {
    setRestaurantServices(false);
  };

  const handleOpenUnitForm = () => {
    setUnitForm(true);
  };
  const handleCloseUnitForm = () => {
    setUnitForm(false);
  };

  const handleOpenDeprtmentForm = () => {
    DepartMentForm(true);
  };

  const handleCloseDeprtmentForm = () => {
    DepartMentForm(false);
  };

  const handleOpenLoundryForm = () => {
    setLoundryForm(true);
  };

  const handleCloseLoundryForm = () => {
    setLoundryForm(false);
  };

  const handleOpenLoundryDeletionForm = () => {
    setLoundryDeleteForm(true);
  };

  const OpenHotelProduct = () => {
    setHotelProductForm(true);
  };

  const OpenRolePop = () => {
    setIsRoleOpen(true);
  };

  const CloseHotelProduct = () => {
    setHotelProductForm(false);
  };
  return (
    <>
      <div className="navAndContent_feed LSX89j">
        <div className="ts-left-rail-wrapper ts-main-flex app-full-viewport-height pull-left left-rail-z-index MHR99">
          <div className="ts-left-rail app-rail-common left-rail-box-shadow-overlay left-rail-header-v2-common">
            <div className="left-rail-header-v2">
              <div className="active-rail">
                <div className="show-rail">
                  <div className="left-rail-header-chat">
                    <div className="left-rail-header constant-border MSY67">
                      <div className="WNWYB_827">
                        <h1 className="ui-header cl es eh et eu ev ew ex ey ez cp wci-activitie">
                          {' '}
                          Activity
                        </h1>
                      </div>
                      <Menu>
                        <MenuTrigger disableButtonEnhancement>
                          <MenuButton
                            icon={<List20Regular />}
                            appearance="transparent"
                          ></MenuButton>
                        </MenuTrigger>

                        <MenuPopover>
                          {/* <MenuList>
                            <MenuItem>Task</MenuItem>
                            <MenuItem>Restuarants</MenuItem>
                            <MenuItem>Laundry</MenuItem>
                          </MenuList> */}
                        </MenuPopover>
                      </Menu>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="LKEH_UIE">
            <div className="HTEB_OE4">
              <SearchBox
                appearance="filled-darker"
                className="ui-box wkwih_uu6 fh fp es fq fr fs ft fu fv fw fx fy fz ga gb gc gd ge gf gg gh gi gj ap gk gl gm gn go gp gq gr gs cm gt gu gv gw gx gy gz ha ui-input__input"
                placeholder="Search"
                type="text"
                id="ui-input-1"
                value={searchTerm}
                onChange={handleSearchChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="ui-flex cl ee cn cq hn" style={{ width: '100%' }}>
            <div
              className=" ui-flex cl ee ho hp hq cq cr cs ct cu cv cw cx cy cz da db dc dd de df dg dh di dj dk dl dmdn do dp dq dr ds dt du dv dw dx dy dz ea eb ec ed customScrollBar"
              role="navigation"
              aria-label="Store Navigation"
            >
              {isToggledState && (
                <div
                  role="tree"
                  aria-label="Apps"
                  className="ms-FocusZone ui-tree hi rx ry rz sa cm sb"
                >
                  {uniqueItems.map((item: any) => {
                    const nameWords = item.name.split(' ');
                    const firstLetter = nameWords[0].charAt(0).toUpperCase();
                    const secondLetter =
                      nameWords.length > 1
                        ? nameWords[1].charAt(0).toUpperCase()
                        : '';
                    const firstAndSecondLetter = firstLetter + secondLetter;

                    return (
                      <div
                        key={item.id}
                        className="zNwRz dqwodwd border-ts-wcci"
                        onClick={() => handleItemClick(item)}
                      >
                        <div className="Inn-ciisnhe">
                          <div className="DXpY5 HMjOq Ehg6h ZkeoC lTdWY">
                            <div
                              className={`ZZ6xo ${
                                selectedItem && selectedItem.id === item.id
                                  ? 'isSelected'
                                  : ''
                              }`}
                            >
                              <div className="qHDHd dX9R5">
                                <div className="oLqMX HMjOq Ehg6h">
                                  <div>
                                    <span className="fui-Avatar r81b29z BQOiO">
                                      {selectedItem &&
                                      selectedItem.id === item.id ? (
                                        <i className=" toogle-circle-checked check-ic fi fi-tr-check-circle"></i>
                                      ) : (
                                        <>
                                          <span
                                            id="avatar-67__initials"
                                            className="fui-Avatar__initials rip04vsrtyyvfe ___1fshorb f1emykk5 f12rj61f"
                                          >
                                            {firstAndSecondLetter}
                                          </span>

                                          <i className="ci-name toogle-circle check-icx fi fi-ts-circle"></i>
                                        </>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="nIqnS">
                                <div>
                                  <div className="CUQZC HMjOq Ehg6h">
                                    {item.name}
                                  </div>
                                </div>
                                <div className="SvlU3">
                                  <div className="YtqGL">
                                    <div>
                                      <div className="SRbHg">{item.desc}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              <div
                className="ui-flex cl ee ho hp hq "
                role="navigation"
                aria-label="Store Navigation"
              >
                <div
                  role="tree"
                  className="ms-FocusZone ui-tree hi rx ry rz sa cm sb  "
                >
                  <div
                    className="ui-tree__item pz jl sc sd se sf sg sh si sj sk sl sm sn so sp sq sr ss st su sv sw sx sy sz ta tb tc td te tf tg th ti tj tk tl tm tn to tp tq tr ts tt tu tv tw tx ty tz ua ub uc ud ix nj iy nk UI_OWUN"
                    id="wf:pr:id"
                  >
                    <span
                      className="UIRN-98"
                      data-tid="store-provider-wf:pr:id"
                      aria-selected="false"
                      data-track="false"
                    >
                      <div
                        className="ui-flex cl et cm cn"
                        aria-label="Workflows collapsed"
                        onClick={handleStateClick}
                      >
                        <span
                          role="img"
                          aria-hidden="true"
                          className="ui-icon COenywik hf ff hg of lp ux hq lv uy uz"
                        >
                          <i className="workflo hi hj hk hl hmfi fi-tr-tools"></i>
                        </span>
                        Workflows
                        <span role="img" className="ui-icon hf ff hg hh va vc">
                          <i
                            className={`hi hj hk hl hm w-ci-incresed hm fi fi-tr-angle-small-${
                              isToggledState ? 'down' : 'up'
                            }`}
                          ></i>
                        </span>
                      </div>
                    </span>
                  </div>
                  {!isToggledState &&
                    uniqueWorkflows.map((item: any) => {
                      const nameWorks = item.name.split(' ');
                      const firstWorkLetter = nameWorks[0]
                        .charAt(0)
                        .toUpperCase();
                      const secondWorkLetter =
                        nameWorks.length > 1
                          ? nameWorks[1].charAt(0).toUpperCase()
                          : '';
                      const firstAndSecondWorkLetter =
                        firstWorkLetter + secondWorkLetter;

                      return (
                        <div
                          key={item.id}
                          className="zNwRz dqwodwd border-ts-wcci"
                          onClick={() => handleItemClick(item)}
                        >
                          <div className="Inn-ciisnhe">
                            <div className="DXpY5 HMjOq Ehg6h ZkeoC lTdWY">
                              <div
                                className={`ZZ6xo ${
                                  selectedWorkflow &&
                                  selectedWorkflow.id === item.id
                                    ? 'isSelected'
                                    : ''
                                }`}
                              >
                                <div className="qHDHd dX9R5">
                                  <div className="oLqMX HMjOq Ehg6h">
                                    <div>
                                      <span className="fui-Avatar r81b29z BQOiO">
                                        {selectedWorkflow &&
                                        selectedWorkflow.id === item.id ? (
                                          <i className=" toogle-circle-checked check-ic fi fi-tr-check-circle"></i>
                                        ) : (
                                          <>
                                            <span
                                              id="avatar-67__initials"
                                              className="fui-Avatar__initials rip04vsrtyyvfe ___1fshorb f1emykk5 f12rj61f"
                                            >
                                              {firstAndSecondWorkLetter}
                                            </span>

                                            <i className="ci-name toogle-circle check-icx fi fi-ts-circle"></i>
                                          </>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="nIqnS">
                                  <div>
                                    <div className="CUQZC HMjOq Ehg6h">
                                      {item.name}
                                    </div>
                                  </div>
                                  <div className="SvlU3">
                                    <div className="YtqGL">
                                      <div>
                                        <div className="SRbHg">{item.desc}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <div className="ui-flex cl ap ik fv il im in io ip iq ir">
            <button
              className="ui-button cietron fi is ao it iu iv fd iw et ap ix ex iy iz ff ja jb jc fq fr fs ft jd je jf jg jh ji jj jk jl jm jn jo jp jq jr js jt ju jv jw jx jy jz ka kb kc kd ke kf kg kh ki kj kk kl km kn ko kp kq kr ks kt ku kv kw kx ky kz la lb lc ld le lf lg lh li lj lk ll lm ln cm fz ga lo gc lp lq lr ls lt lu"
              data-track-action-scenario="storeManageAppsClick"
              data-track-action-scenario-type="store"
              data-track-module-name="store"
            >
              <div className="ui-box fd wci-ci et fb hj hk lp ij hq lv">
                <span
                  role="img"
                  aria-hidden="true"
                  className="ui-icon hf ff hg"
                >
                  <i className="cilsco-wcs hi hj hk hl hm fi fi-tr-command"></i>
                </span>
              </div>
              <span dir="auto" className="ui-button__content cq lw lx fm ly lz">
                Powered by cilpron
              </span>
            </button>
          </div>
        </div>
      </div>

      <div className="ts-middle ts-main-flex pointer-events-auto hybrid-passthrough LSX89j">
        <div className="flex-fill">
          <div className="ts-middle-pane flex-fill">
            <div className="messages-header flex-fill">
              {visibleActivityItem === 23 && (
                <>
                  <div className="ts-messages-header messages-header-v2">
                    <div className="app-messages-header ts-section-divider">
                      <div className="app-chat-header-content">
                        <div className="wc-OverflowSet-item wc-ribbonOverflowItem wc-item-170 Activity-date-picker">
                          <Breadcrumb
                            aria-label="Small Breadcrumb"
                            size="small"
                          >
                            <Button
                              icon={<CalendarToday24Regular />}
                              type="button"
                              id="8505"
                              className="wc-Button wc-root-257 cw-bvc"
                            >
                              <span className="wc-Button-flexContainer wc-flexContainer-159">
                                <span className="wc-Button-textContainer wc-textContainer-173">
                                  <span
                                    className="wci-restire-text"
                                    id="id__1132"
                                  >
                                    Today
                                  </span>
                                </span>
                              </span>
                            </Button>
                            <Field>
                              <DatePicker
                                showWeekNumbers={true}
                                firstWeekOfYear={1}
                                showMonthPickerAsOverlay={true}
                                placeholder={onFormatDatePlaceholder(
                                  new Date()
                                )}
                                className={styles.control}
                                onSelectDate={handleDateChangeUsers}
                              />
                            </Field>
                          </Breadcrumb>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="ts-tab-content flex-fill">
                    <div className="ts-embedded-container flex-fill hybrid-passthrough">
                      <div className="content-child flex-fill">                  
                          <div className="defaultV2 calendar-dialog-layout_layout_88mobF">           
                                  <StaffManagement
                                    selectedDate={selectedDate}
                                    user={user}
                                  />
                          </div>
                 
                      </div>
                    </div>
                  </div>
                </>
              )}

              {visibleActivityItem === 0 && (
                <>
                  <div className="ts-messages-header messages-header-v2">
                    <div className="app-messages-header ts-section-divider">
                      <div className="app-chat-header-content WHTE-ANH67">
                        <div className="ms-StackItem ww-css-314">
                          <div className="ms-Stack ww-css-286">
                            <h2 className="MyContentControl-module__my-content__title__XJ0YA">
                              Online Request
                            </h2>
                          </div>
                        </div>
                        <div className="ms-StackItem MyContentControl-module__my-content__list-header__1pClK ww-css-315s">
                          <div
                            className="MyContentControl-module__my-content__filter-search__2hc7h"
                            role="status"
                            aria-live="polite"
                            aria-label="Displaying 56 out of 56 files."
                          >
                            <span className="fui-Input r1jtohuq ___1rmparb fvcxoqz f1ub3y4t f1l4zc64 f1m52nbi f8vnjqi fz1etlk f1klwx88 f1hc16gm f1d2rq10 f1zwgmj f5ogflp f1hqa2wf f1f09k3d finvdd3 fzkkow9 fcdblym fg706s2 fjik90z fj3muxo f1akhkt f1aperda f1lxtadh fff7au0 f1bjk9e1 fwsfkhu f8wkphi fxugw4r f10b5716 fsxrzjo fkonh8g fz14cf4 f1gd2g5g f1enkdio fbaokl5 f1v3rxqc f1lik41s fhyuxa5 f12kbfjx ff41seh">
                              <input
                                type="text"
                                placeholder="Filter by keyword"
                                className="fui-Input__input rvp2gzh"
                                value=""
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="ts-tab-content flex-fill">
                    <div className="ts-embedded-container flex-fill hybrid-passthrough">
                      <div className="content-child flex-fill">
                        <OnlineRequest user={user} />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {visibleActivityItem === 11 && <ShiftTime user={user} />}
              {visibleActivityItem === 4 && (
                <Invoice
                  onOpen={OpenRaiseInvoice}
                  user={user}
                  message={message}
                  adjuster={adjuster}
                />
              )}
              {visibleActivityItem === 30 && (
                <BarServices
                  user={user}
                  onOpenForm={OpenMenuLoungBar}
                  message={message}
                  adjuster={adjuster}
                />
              )}
              {visibleActivityItem === 37 && (
                <BarCategory
                  onOpenForm={handleOpenCat}
                  user={user}
                  adjuster={adjuster}
                  message={message}
                />
              )}
              {visibleActivityItem === 38 && (
                <Amenity
                  onOpenForm={handleOpenAmenity}
                  user={user}
                  adjuster={adjuster}
                  message={message}
                />
              )}

              {visibleActivityItem === 31 && (
                <EventServices
                  onOpenForm={handleOpenEventService}
                  user={user}
                  adjuster={adjuster}
                  message={message}
                />
              )}

              {visibleActivityItem === 39 && (
                <AmenityTypes
                  onOpenForm={handleOpenAmenityTypes}
                  user={user}
                  adjuster={adjuster}
                  message={message}
                />
              )}

              {visibleActivityItem === 24 && (
                <EmployeBoard
                  onOpenForm={handleOpenEmployeeForm}
                  onOpenDeleteForm={handleOpenEmployeeDeletionForm}
                  user={user}
                  adjuster={adjuster}
                  message={message}
                />
              )}
              {visibleActivityItem === 32 && (
                 <RoomManagement
                  onOpenForm={handleRoomFormOpen}
                  user={user}
                  message={message}    />
              )}
              {visibleActivityItem === 29 && (
                <TitleManagement
                  onOpenForm={handleOpenTitleForm}
                  user={user}
                  adjuster={adjuster}
                  message={message}
                />
              )}

              {visibleActivityItem === 28 && (
                <GenderManagement
                  onOpenForm={handleOpenGenderForm}
                  user={user}
                  adjuster={adjuster}
                  message={message}
                />
              )}

              {visibleActivityItem === 25 && (
                <TaskManagement
                  onOpenForm={handleOpenTaskForm}
                  user={user}
                  adjuster={adjuster}
                  message={message}
                />
              )}

              {visibleActivityItem === 33 && (
                <ReservationTypeService
                  onOpenForm={handleOpenReservationType}
                  user={user}
                  adjuster={adjuster}
                  message={message}
                />
              )}

              {visibleActivityItem === 34 && (
                <RestaurantServices
                  onOpenForm={handleOpenRestuarantService}
                  user={user}
                  adjuster={adjuster}
                  message={message}
                />
              )}

              {visibleActivityItem === 40 && (
                <Department
                  onOpenForm={handleOpenDeprtmentForm}
                  user={user}
                  adjuster={adjuster}
                  message={message}
                  onOpenDeleteForm={handleOpenDepartMentDeletionForm}
                />
              )}
              {visibleActivityItem === 41 && (
                <Units
                  onOpenForm={handleOpenUnitForm}
                  user={user}
                  adjuster={adjuster}
                  message={message}
                  onOpenDeleteForm={handleOpenUnitDeletionForm}
                />
              )}
              {visibleActivityItem === 27 && (
                <BusinesLocation
                  onOpenForm={handleOpenLocationForm}
                  user={user}
                  adjuster={adjuster}
                  message={message}
                  onOpenDeleteForm={handleOpenLocationDeletionForm}
                />
              )}
              {visibleActivityItem === 42 && (
                <LoundryServices
                  onOpenForm={handleOpenLoundryForm}
                  user={user}
                  adjuster={adjuster}
                  message={message}
                  onOpenDeleteForm={handleOpenLoundryDeletionForm}
                />
              )}

              {visibleActivityItem === 43 && (
                <HotelProducts
                  user={user}
                  onOpenForm={OpenHotelProduct}
                  message={message}
                />
              )}

              {visibleActivityItem === 44 && (
                <RoleManager
                  user={user}
                  onOpenForm={OpenRolePop}
                  message={message}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {isLoader ? (
        <div className="spin-shadow">
          <div className="c-spinner"></div>
        </div>
      ) : null}

      {newEmployee && (
        <div className="fluent-default-layer-host">
          <div className="ms-Layer ms-Layer--fixed root-44">
            <RegisterEmployee
              close={handleCloseEmployeeForm}
              message={message}
              adjuster={adjuster}
              user={user}
            />
          </div>
        </div>
      )}
      {manageRoomForm && (
          <div className="fluent-default-layer-host">
                <div className="ms-Layer ms-Layer--fixed root-44">
                  <RoomRegisterationForm
              close={handleCloseForm}
              message={message}
              user={user}               />
                </div>
              </div>
        )
      }
      {isRoleOpen && (
        <Roles onClose={HandleRoleClose} message={message} user={user} />
      )}

      {closeInvoice && (
        <RaiseInvoice
          onClose={CloseRaiseInvoice}
          message={message}
          user={user}
          adjuster={adjuster}
        />
      )}

      {closeMenuBar && (
        <CreateBarService
          onBarClose={closeMenuLoungBar}
          message={message}
          user={user}
          adjuster={adjuster}
        />
      )}

      {closeMenuBarCat && (
        <BarCategoryForm
          onCatClose={handleCateclose}
          message={message}
          user={user}
          adjuster={adjuster}
        />
      )}

      {closeAmenity && (
        <AmenityForm
          onCatClose={handlecloseAmenity}
          message={message}
          user={user}
          adjuster={adjuster}
        />
      )}

      {closeEventService && (
        <EventServiceForm
          onCatClose={handleCloseEventService}
          message={message}
          user={user}
          adjuster={adjuster}
        />
      )}
      {amenityTypes && (
        <AmenityTypesForm
          onCatClose={handleCloseAmenityTypes}
          message={message}
          user={user}
          adjuster={adjuster}
        />
      )}

      {newTitle && (
        <TitleForm
          onClose={handleCloseTitle}
          message={message}
          user={user}
          adjuster={adjuster}
        />
      )}

      {newGender && (
        <GenderForm
          onClose={handleCloseGender}
          message={message}
          user={user}
          adjuster={adjuster}
        />
      )}

      {openDeletedEmployee && (
        <DeletingEmployee
          onClose={handleCloseEmployeeDeletionForm}
          message={message}
          user={user}
          adjuster={adjuster}
        />
      )}
      {openDeleteLocation && (
        <DeletingLocation
          onClose={handleCloseLocationDeletionForm}
          message={message}
          user={user}
          adjuster={adjuster}
        />
      )}

      {openDeleteDepartment && (
        <DeletingDepartment
          onClose={handleCloseDepartMentDeletionForm}
          message={message}
          user={user}
          adjuster={adjuster}
        />
      )}

      {openDeleteUnit && (
        <DeletingUnit
          onClose={handleCloseUnitDeletionForm}
          message={message}
          user={user}
          adjuster={adjuster}
        />
      )}
      {newTask && (
        <TaskManagementForm
          onClose={handleTaskCloseForm}
          message={message}
          user={user}
          adjuster={adjuster}
        />
      )}

      {reservationType && (
        <ReservationType
          onClose={handleCloseReservationType}
          message={message}
          user={user}
        />
      )}


      {restaurantServices && (
        <RestaurantMenuForm
          onClose={handleCloseRestuarantService}
          message={message}
          user={user}
        />
      )}

      {departMentForm && (
        <DepartmentForm
          onClose={handleCloseDeprtmentForm}
          message={message}
          user={user}
        />
      )}
      {unitForm && (
        <UnitsForm
          onClose={handleCloseUnitForm}
          message={message}
          user={user}
        />
      )}

      {locationForm && (
        <LocationForm
          onClose={handleCloseLocationForm}
          message={message}
          user={user}
        />
      )}

      {hotelProductForm && (
        <HotelProductForm
          onClose={CloseHotelProduct}
          message={message}
          user={user}
        />
      )}

      {loundryForm && (
        <LaundryServiceForm
          onClose={handleCloseLoundryForm}
          message={message}
          user={user}
        />
      )}
    </>
  );
};

export default ActivityApp;
