import React, { useCallback, useEffect, useState } from "react";
import "./Landing.scss";
import { makeStyles, Persona, Tab, TabList } from "@fluentui/react-components";
import {
    Button,
    tokens,
    typographyStyles,
  } from "@fluentui/react-components";
  import {
    CarouselButton,
    CarouselCard,
    CarouselSlider,
  } from "@fluentui/react-carousel-preview";
import { ChannelSlide, ChannelSlideOut } from "../../Data/General/ChannelSlide";
import ImageOutgoing from '../../Components/Themes/Images/Stock_down_orange_72x72.png';
import ImageIncoming from '../../Components/Themes/Images/Finance_stock_up_green_72x72.png';
import Carousel from "../../Components/Common/Carousel/Sliders";
import axios from "axios";
import { WHITE_HOUSE_API } from "../../Constants/Constant";
  
  const useClasses = makeStyles({
    carousel: {
      display: "grid",
      gridTemplateColumns: "1fr max-content 1fr",
      gap: "10px",
      placeItems: "center",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },
    test: {
      ...typographyStyles.largeTitle,
      alignContent: "center",
      borderRadius: tokens.borderRadiusLarge,
      height: "200px",
      textAlign: "center",
    },
    footer: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: "10px",
    },
    code: {
      justifyContent: "end",
      backgroundColor: tokens.colorNeutralBackground4,
      borderRadius: tokens.borderRadiusLarge,
      fontFamily: tokens.fontFamilyMonospace,
      fontSize: tokens.fontSizeBase400,
      padding: "10px",
      placeSelf: "end",
    },
    controls: {
      alignSelf: "center",
      display: "flex",
      gap: "10px",
    },
    control: {
      minWidth: "32px",
    },
  });
const useStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    rowGap: "20px",
  },
});

interface Message {
  message: string;
  id: number;
}

interface User {
  email: string;
  phoneNumber: string;
  token: string;

  // other properties...
}
type Props = {
  message: Message;
  user: User;
};


const MobileLanding:React.FC<Props> = (props) => {
    const [channelcheckedOutData, setChannelCheckOutData] = useState<ChannelSlideOut[]>([]);
    const [channelCheckedInData, setChannelCheckedInData] = useState<ChannelSlide[]>([]);
    const styles = useStyles();
    const [activeIndex, setActiveIndex] = React.useState(0);
    const classes = useClasses();
    const autoSlideInterval = 3000;
    const { message, user } = props;



    const fetchCheckinSliaderall = useCallback(async () => {
      try {
        const response = await axios.get(
          `${WHITE_HOUSE_API}/Checkin/CheckinSliders`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        console.log("Mobile slides: ", response.data);
        setChannelCheckedInData(response.data);
      } catch (error) {
        console.error(error);
      }
    }, [user.token]);
  
  
    const fetchCheckOutSliders = useCallback(async () => {
      try {
        const response = await axios.get(
          `${WHITE_HOUSE_API}/Checkin/CheckOutliders`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        console.log("Mobile slides: ", response.data);
        setChannelCheckOutData(response.data);
      } catch (error) {
        console.error(error);
      }
    }, [user.token]);
  
  
    useEffect(() => {

      fetchCheckOutSliders();
      fetchCheckinSliaderall();
    }, []);
  


    const checkedInItems = channelCheckedInData ? channelCheckedInData.map((i:any, index:any) => {
        const date = new Date(i?.arrivalDate);
        const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const weekday = weekdays[date.getDay()];
        const day = date.getDate();
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedDate = `${weekday} ${day}, ${year} ${hours}:${minutes}`;
    return (
     <div className="quote-item">
     <div className="left">
       <div className="symb">
         {i.name}
         <img className="notf-icon" src={ImageIncoming} alt="" />
       </div>
       <div className="left-bottom">
         <div className="notf-text">{`${i.roomName} (${i.roomNumber})`}</div>
       </div>
     </div>
     <div className="right">
       <div className="prct-change">{formattedDate}</div>
       <div className="price">Amount paid: {`${i.amount?.toLocaleString()}`}</div>
     </div>
    </div>
    )}) : [];
      
      const checkedOutItems = channelcheckedOutData ? channelcheckedOutData.map((m:any, ind:any) => {
        const date = new Date(m?.departureDate);
        const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const weekday = weekdays[date.getDay()];
        const day = date.getDate();
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedDate = `${weekday} ${day}, ${year} ${hours}:${minutes}`;
    return (
     <div key={ind} className="quote-item">
     <div className="left">
       <div className="symb">
         {m.name}
         <img className="notf-icon" src={ImageOutgoing} alt="" />
       </div>
       <div className="left-bottom">
         <div className="notf-text">{`${m.roomName} (${m.roomNumber})`}</div>
       </div>
     </div>
     <div className="right">
       <div className="prct-change">{formattedDate}</div>
       <div className="price">Amount paid: {`${m.amount?.toLocaleString()}`}</div>
     </div>
    </div>
    )}) : [];
       const items = [...checkedInItems, ...checkedOutItems];
      
     

    return (
        <>
        <div className="xK7As J_3wQ">
            <main className="JbtCF">
              <div className="HKfrs">
                <div className="kuefK">
                  <div className="YyYjM">
                  <header  className="ahVTn XHRM4 VLlSA">
                    <div className="L4evP eNS3j">
                      <div className="h4VJy FOfM6 GDfhy">
                        <span className="ziWEf">Available</span></div>
                        <div className="h4VJy GEtjr">
                          <span className="ziWEf">Occupied</span></div>
                        </div>
                        <div  className="u_aIX Y7HI9 X9yrJ">
                            <div className="Wp99v LJnge">Online</div>
                        </div>
                        </header>
                  </div>
                  <div className="MEBEINie">
                    <div className="uYekO">
                      <div className="w2QhO">
                        <div role="button">
                          <div className="luji2">
                          <div className="vertical-watchlist ">
                            <Carousel items={items} />
                          </div>
                            </div>
                          </div>
                      </div>                 
                </div>
                <div id="cc4nt-ids" className="cc4nt">
                  <div>
                    <article className="nFowK oB90K yq5lH lq0_p aGI5I GAxFP DvDmK">
                    <Persona
                          textPosition="after"
                          name="Standard Room"
                          secondaryText="Available"
                        />
                    </article>
                  </div>
                </div>
            </div>


                </div>
              </div>
           </main>
           
        </div>
        </>
    )
}

export default MobileLanding;