import React from "react";

const Loaders: React.FC = () => {
    return (
        <div className="spin-shadow">
            <div className="c-spinner"></div>
        </div>
    )
}

export default Loaders;