import * as React from 'react';
import {
    TextBold24Regular,
    TextItalic24Regular,
    TextUnderline24Regular,
    TextStrikethrough24Regular,
    TextColor24Regular,
    TextColor24Filled,
    ArrowUndo24Regular,
    ArrowRedo24Regular,
    TextFontSize24Regular,
    TextHeader124Regular,
    TextIndentDecreaseLtr24Regular,
    TextIndentIncreaseLtr24Regular,
    TextBulletListLtr24Regular,
    TextNumberListLtr24Regular,
    Attach24Regular,
    LocationAdd24Regular,
    Mention24Regular,
    AddCircle24Filled,
    Emoji24Regular
} from '@fluentui/react-icons';
import {
    Toolbar, ToolbarToggleButton, ToolbarDivider, makeStyles,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger, ToolbarProps
} from '@fluentui/react-components';


interface SubtleProps extends Partial<ToolbarProps> { }

const ToolSubtle: React.FC<SubtleProps> = (props) => (
    <Toolbar aria-label="Subtle" {...props}>
        <ToolbarToggleButton
            appearance="subtle"
            aria-label="Bold"
            icon={<TextBold24Regular className="wc-add-style" />}
            name="textOptions"
            value="bold"
        />

        <ToolbarToggleButton
            appearance="subtle"
            aria-label="Italic"
            icon={<TextItalic24Regular className="wc-add-style" />}
            name="textOptions"
            value="italic"
        />

        <ToolbarToggleButton
            appearance="subtle"
            aria-label="Underline"
            icon={<TextUnderline24Regular className="wc-add-style" />}
            name="textOptions"
            value="underline"
        />
        <ToolbarToggleButton
            appearance="subtle"
            aria-label="strikethrough"
            icon={<TextStrikethrough24Regular className="wc-add-style" />}
            name="textOptions"
            value="strikethrough"
        />
        <ToolbarDivider />
        <ToolbarToggleButton
            appearance="subtle"
            aria-label="strikethrough"
            icon={<TextColor24Regular className="wc-add-style" />}
            name="textOptions"
            value="color"
        />

        <ToolbarToggleButton
            appearance="subtle"
            aria-label="strikethrough"
            icon={<TextFontSize24Regular className="wc-add-style" />}
            name="textOptions"
            value="Fontsize"
        />
        <Menu>
            <MenuTrigger disableButtonEnhancement>
                <MenuButton className="wc-add-style" appearance="subtle" icon={<TextHeader124Regular className="wc-add-style" />}>
                    Headind
                </MenuButton>
            </MenuTrigger>

            <MenuPopover>
                <MenuList>
                    <MenuItem>Heading 1</MenuItem>
                    <MenuItem>Heading 2</MenuItem>
                </MenuList>
            </MenuPopover>
        </Menu>
        <ToolbarDivider />
        <ToolbarToggleButton
            appearance="subtle"
            aria-label="IndentDecrease"
            icon={<TextIndentDecreaseLtr24Regular className="wc-add-style" />}
            name="textOptions"
            value="IndentDecrease"
        />
        <ToolbarToggleButton
            appearance="subtle"
            aria-label="IndentIncrease"
            icon={<  TextIndentIncreaseLtr24Regular className="wc-add-style" />}
            name="textOptions"
            value="IndentIncrease"
        />
        <ToolbarToggleButton
            appearance="subtle"
            aria-label="BulletedList"
            icon={<  TextBulletListLtr24Regular className="wc-add-style" />}
            name="textOptions"
            value="BulletedList"
        />
        <ToolbarToggleButton
            appearance="subtle"
            aria-label="NumberedList"
            icon={<  TextNumberListLtr24Regular className="wc-add-style" />}
            name="textOptions"
            value="NumberedList"
        />
        <ToolbarDivider />
        <ToolbarToggleButton
            appearance="subtle"
            aria-label="Emoji"
            icon={<Emoji24Regular className="wc-add-style" />}
            name="textOptions"
            value="Emoji"
        />
        <ToolbarToggleButton
            appearance="subtle"
            aria-label="AttatchFile"
            icon={<Attach24Regular className="wc-add-style" />}
            name="textOptions"
            value="AttatchFile"
        />
        <ToolbarToggleButton
            appearance="subtle"
            aria-label="Location"
            icon={<LocationAdd24Regular className="wc-add-style" />}
            name="textOptions"
            value="Location"
        />
        <ToolbarToggleButton
            appearance="subtle"
            aria-label="Mention"
            icon={<Mention24Regular className="wc-add-style" />}
            name="textOptions"
            value="Mention"
        />
        <ToolbarToggleButton
            appearance="subtle"
            aria-label="AddMember"
            icon={<AddCircle24Filled className="wc-add-style" />}
            name="textOptions"
            value="AddMember"
        />
        <ToolbarDivider />
        <ToolbarToggleButton
            appearance="subtle"
            aria-label="Undo"
            icon={<ArrowUndo24Regular className="wc-add-style" />}
            name="textOptions"
            value="Undo"
        />

        <ToolbarToggleButton
            appearance="subtle"
            aria-label="Redo"
            icon={<ArrowRedo24Regular className="wc-add-style" />}
            name="textOptions"
            value="Redo"
        />
    </Toolbar>
);

export default ToolSubtle;
