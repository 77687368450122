import React, { useCallback, useEffect, useState } from 'react';

import './SingleRoomViewDialog.scss';
import {
  Avatar,
  AvatarGroup,
  AvatarGroupItem,
  Button,
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
} from '@fluentui/react-components';
import { ChevronLeft24Regular } from '@fluentui/react-icons';
import { RoomDataListItem } from '../../Data/General/RoomDetail';
import GuestForm from '../Hms/Book/BookingForm/GuestForm';
import { SearchBox } from '@fluentui/react-search-preview';
import { Field } from '@fluentui/react-components';
import type { SearchBoxProps } from '@fluentui/react-search-preview';
import axios from 'axios';
import {
  ReturnMessage,
  SECRETE_KEY,
  SUBACCOUNT_CODE,
  WHITE_HOUSE_API,
} from '../../Constants/Constant';
import { Room } from '../../Data/General/Room';
import { Persona, PersonaSize } from '@fluentui/react';
import { BookingContext } from '../Common/Context/BookContext';
import { IBooking, InitializeBooking } from '../../Data/General/CheckinGuest';
import Loaders from '../Common/Loader/Loader';
import { TransactionData, calculateTotalChargeAmount, checkUntilComplete, initializeTransaction, redirectToAuthorizationUrl } from '../Hms/Paystack/payStack';
import { InitiateTransactions } from '../../Data/General/InitiateTransaction';
import { InternalTransaction } from '../Hms/Transactions/InternalTransaction';
import PaymentLoaders from '../Common/Loader/PayMentLoder';
import TransactionState from '../Common/Loader/TransactionState';

interface SelectedRoom {
  number: any;
  name: any;
}

interface User {
  email: string;
  phoneNumber: string;
  token: string;

  // other properties...
}
interface GuestId {
  number: number;
}
interface ViewRoomProps extends SearchBoxProps {
  selected: SelectedRoom;
  onClose: () => void;
  onPaymentClick: (guestid: any, roomid: any) => void;
  user: User;
}


let Initmessage: ReturnMessage = {
  header: '',
  body: '',
};



const SingleRoomViewDialog: React.FC<ViewRoomProps> = ({
  selected,
  user,
  onClose,
  onPaymentClick,
  ...searchBoxProps
}) => {
  const [showForm, setShowForm] = useState(false);
  const [showDetails, setshowDetails] = useState(true);
  const ActionText = showForm ? 'Submit' : 'Book Now';
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<Room[]>([]);
  const [roomdetails, setRoomDetails] = useState<RoomDataListItem[]>([]);
  const [bookRoom, setBookRoom] = useState<IBooking>(InitializeBooking);
  const [roomName, setRoomName] = useState<string>('');
  const [roomNumber, setRoomNumber] = useState<number>(0);
  const [chargeAmount, setChargeAmount] = useState<number>(0);
  const [responseMessage, setResponseMessage] = useState('');
  const [guestEmail, setGuestEmail] = useState<string>('');
  const [rNumber, setRNumber] = useState<SelectedRoom>();
  const [isPaymentL, setIsPaymentL] = useState<boolean>(false);
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [isSearchActive, setisSearchActive] = useState(false);
  const [makePayment, setPayments] = useState<boolean>(false);
  const [transactionStatus, setTransactionStatus] = useState<boolean>(false);
 const [returnSuccess, setReturnSuccess] = useState<ReturnMessage>(Initmessage);
 const [theGuestEmail, setTheGuestEmail] = useState<string>("");
  const [getBookingId, setGetBookingId] = useState<number>(0);
  const point005prcent:any = 0.007;
  let checkinDate: any;
  let IcheckoutDates: any;
  let formattedDate: any;
  let formatedCheckoutDate: any;
  let guestwitholdingbalance: any;
  let formatedBalance: any;
  let roomNber: number = 0;
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short' as const,
    day: 'numeric' as const,
    month: 'short' as const,
    year: 'numeric' as const,
  };
  let rates: any;
  let formattedRate: any;

  const handleInputChange = (event: any) => {
    const que = event.target.value;
    setisSearchActive(event.target.value !== '');
    setQuery(que);
    if (que.length >= 1) {
      search(que);
    }
  };
  const toggleForm = () => {
    if (ActionText === 'Submit') {
      SubmitBookingRequest();
    } else {
      setShowForm((c) => !c);
      setshowDetails((c) => !c);
    }
  };
  const returnBack = () => {
    setShowForm((c) => !c);
    setshowDetails((c) => !c);
  };

  const search = async (query: any) => {
    try {
      console.log('search item', query);
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Booking/search?rm=${query}`,
        {
          params: { query },
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setResults(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getSingleRoomHistory = useCallback(
    async (id: any) => {
      const response = await axios.get(`${WHITE_HOUSE_API}/RoomDetail/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setRoomDetails(response.data);
    },
    [user.token]
  );
  const handleItemClick = (id: any, name: any) => {
    let des: SelectedRoom = {
      number: id,
      name: name,
    };
    setRNumber(des);
    getSingleRoomHistory(rNumber?.number);
    setisSearchActive(false);
    setQuery('');
  };
  useEffect(() => {
    if (!rNumber?.number || rNumber?.number < 1) {
      setRNumber(selected);
    }
    if (selected.number) {
      getSingleRoomHistory(selected.number);
    }
    if (roomdetails) {
      roomdetails.forEach((room) => {
        if (room.roomName !== null) {
          setRoomName(room.roomName);
        }
        if (room.roomNumber !== null) {
          setRoomNumber(room.roomNumber);
        }
      });
    }
  }, [getSingleRoomHistory,returnSuccess, rNumber, roomNber, roomdetails, selected]);

  const SubmitBookingRequest = async () => {
    try {
      setIsLoader(true);
      const response = await axios.post(
        `${WHITE_HOUSE_API}/Booking/Guid`,
        bookRoom,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      // onPaymentClick(response.data.eGuest, response.data.eRoom);
      console.log("Response Data: ", response.data);
      if (response.data.status === 'Success') {
        let amt:any = response.data.eAmount;
        let emai:any = response.data.eEmail;
        setGetBookingId(response.data.eBookinId);
        setTheGuestEmail(response.data.eEmail);
        setResponseMessage(response.data.eMessage);
        let charge:any = await calculateTotalChargeAmount(amt, point005prcent); 
        const transactionData: TransactionData = {
          email: emai,
          amount: (charge * 100).toString(),
          subaccount: SUBACCOUNT_CODE,
          transaction_charge:  charge * point005prcent * 100
        };
  
        let resTransaction = await initializeTransaction(transactionData);
        redirectToAuthorizationUrl(resTransaction);
  
        let verification = await checkUntilComplete(resTransaction.data.reference);
        while (verification.data.status !== 'success' && verification.data.status !== 'failed') {
          await new Promise(resolve => setTimeout(resolve, 500));
          verification = await checkUntilComplete(resTransaction.data.reference);
          console.log("I am called: ", new Date());
        }
  
        console.log("Returned Verification: ", verification);
        if(verification.data.status === 'success'){
          const T:InitiateTransactions = {
            customerEmail: verification.data.customer.email,
            roomNumber: bookRoom.roomNumber,
            bookingid: response.data.eBookinId,
            transactionId: verification.data.id,
            Amount: verification.data.amount,
            customerPaymentId: verification.data.customer.id,
            paymentChannel: verification.data.channel,
            Souces: 'Front Office',
            gatewayid: 1,
            gatewayresponse: verification.data.gateway_response,
            referencenumber: verification.data.reference,
            clientAccountName: verification.data.subaccount.business_name,
            Currency: verification.data.currency,
            clientId: verification.data.subaccount.id,
            clientBankName: verification.data.subaccount.settlement_bank,
            clientAccountNumber: verification.data.subaccount.account_number
          }
          let internal = await InternalTransaction(T, user.token);
          if(internal){
            Initmessage = {
              header: internal.header,
              body: internal.body,
            };
            setReturnSuccess(Initmessage)
          };
          setIsPaymentL(false);
          setTransactionStatus(true);
          setIsLoader(false);
          console.log("The reponse message of Transaction: ", returnSuccess);
        }else if(verification.data.status === 'failed'){
          Initmessage = {
            header: "Payment " + verification.data.status,
            body: "Room Was not booked",
          };
          setReturnSuccess(Initmessage)
          setIsPaymentL(false);
          setTransactionStatus(true);
        }
      }
    } catch (error) {
      console.error('Error:', error);
      console.log('Error in onPaymentClick:', error);
      setIsLoader(false);
    }
  };
  const closeLoad = () => {
    setIsLoader(false);
  }



  return (
    <>
      <div className="ms-Fabric ms-Layer-content content-46">
        <div className="wc-no outline">
          <div className="ms-Modal is-open root-39">
            <div
              aria-hidden="true"
              className="ms-Overlay ms-Overlay--dark root-48"
            ></div>
            <div
              id="cilsco-focuszone"
              className="ms-Dialog-main createListModalMain_979b2e47 main-40"
            >
              <div
                aria-hidden="true"
                id="Ariel-fixed"
                data-is-visible="true"
                data-is-focus-trap-zone-bumper="true"
              ></div>
              <div className="ms-Modal-scrollableContent scrollableContent-54 customScrollBar">
                <div className="Merging-34">
                  <div className="createList-root templatePreviewPage">
                    <div className="templatePreview">
                      <div className="createList-pageRoot templatePreviewContainer">
                        {isLoader && <Loaders />}
                        {isPaymentL && (
                          <>
                          <PaymentLoaders startInSeconds={60} close={closeLoad}
                            />
                        </>
                        )}
                        {transactionStatus && (
                          <>
                            <TransactionState ResponseMessage={returnSuccess} close={closeLoad}
                            />
                          </>
                        )}
                        <div className="createList-header">
                          <div className="templateTitleWithLogo">
                            <div className="templateLogo-container sp-List-color6">
                              <span className="WRoomNumbers">
                                R{rNumber?.number}
                              </span>
                            </div>
                            <h1 className="createList-headerTitle">
                              {rNumber?.name}
                            </h1>
                          </div>
                          <div
                            className={`ui-box Wsearch-ev-ew wc-check-search
                                                c-search_modal__wrapper popover-little ${
                                                  isSearchActive
                                                    ? 'mouse-over-active'
                                                    : ''
                                                }`}
                          >
                            <SearchBox
                              appearance="filled-lighter"
                              placeholder="Quick Link .."
                              value={query}
                              onChange={handleInputChange}
                              onBlur={search}
                              className="sear-padd"
                            />
                            <div
                              className={`c-search_autocomplete__container customScrollBar
                                                  c-search_autocomplete__container--query-ordered ${
                                                    isSearchActive
                                                      ? 'serachbar-active'
                                                      : ''
                                                  }`}
                            >
                              <div className="c-search_autocomplete c-search_autocomplete--nav-attached">
                                <div className="offscreen">
                                  <span id="search-autocomplete-query-label-prefix-node">
                                    Search
                                  </span>
                                </div>
                                {results.map((room) => {
                                  let availabilityText = room.availability
                                    ? 'Available'
                                    : 'Not Available';
                                  let fullname = `R${room?.roomNumber}`;
                                  let roomName = room?.roomName;
                                  return (
                                      <div key={room.roomId} className="c-search_autocomplete__suggestion_list c-search_autocomplete__suggestion_list--taller"
                                  onClick={() => handleItemClick(
                                      room.roomNumber,
                                      room.roomName
                                    )
                                  }
                                >
                                  <div className="curtain-a wform-css-283-right wc-ciHHEB">
                                    <div className="fui-Persona rlroi9i">
                                      <span
                                        role="img"
                                        id="avatar-rd"
                                        className="fui-Avatar r81b29z fui-Persona__avatar ___1c989qy_1o9k6tz f13ar0e0 fid048z f1hek2iy f9h729m f18q9vkd f103ycu4"
                                        aria-label={room.roomName}
                                        aria-labelledby="avatar-rd avatar-rd__badge"
                                      >
                                        <AvatarGroup>
                                          <AvatarGroupItem  color='colorful' initials={fullname} name={fullname} />
                                        </AvatarGroup>
                                      </span>
                                      <span className="fui-Persona__primaryText rvj41k9">
                                        {roomName}
                                      </span>
                                      <span className="fui-Persona__secondaryText rp1pf9e ___19lp2a0_1p5a1yj f1ryq6si">
                                        {availabilityText}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <div className="createList-closeBtnContainer">
                            <Button
                              icon={<i className="fi fi-sr-cross-small" />}
                              onClick={onClose}
                            ></Button>
                          </div>
                        </div>
                        {showForm ? (<>
                          <BookingContext.Provider
                            value={{ bookRoom, setBookRoom }}
                          >
                            {showForm && (
                              <div className="createList-pageContent includeBottomPadding customScrollBar">
                                <GuestForm
                                  selected={selected}
                                  user={user}
                                  onClose={onClose}
                                />
                              </div>
                            )}
                          </BookingContext.Provider>
                        </>) : ( <div className="templateScrollArea">
                          <div className="createList-pageContent templatePreviewContent">
                            <div className="previewContainer customScrollBar">
                              <div>
                                <div className="ms-FocusZone css-44">
                                  <div className="root_f67c6556">
                                    <div className="layoutPositiningContainer_d59752a3 root_f67c6556">
                                      <div className="layoutSwitcherContainer_d59752a3 root_f67c6556">
                                        <div className="root-82">
                                          <div className="ms-Viewport">
                                            <div className="ms-DetailsList is-fixed spMsListCellMinHeight_e3febe52 root-87">
                                              <div className="">
                                                <div className="ms-DetailsList-headerWrapper">
                                                  <div className="ms-FocusZone css-44 ms-DetailsHeader msc-root89">
                                                    <div className="ms-DetailsHeader-cell is-actionable root-105 cilsco-width">
                                                      <div className="ms-TooltipHostwx cellTooltip-113">
                                                        <div className="ms-DetailsHeader-cellTitle cellTitle-111">
                                                          <span
                                                            id="header43-title_1269-name"
                                                            className="ms-DetailsHeader-cellName cellName-112"
                                                          >
                                                            Guest Name
                                                          </span>
                                                          <div className="clmix"></div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="ms-DetailsHeader-cellSizer cellSizer-96 cellSizerStart-97"></div>
                                                    <div className="ms-DetailsHeader-cell is-actionable root-105 wc-width-150">
                                                      <div
                                                        className="ms-TooltipHostwx cellTooltip-113"
                                                        role="none"
                                                      >
                                                        <span
                                                          id="header43-ReasonForTravel"
                                                          className="ms-DetailsHeader-cellTitle cellTitle-111"
                                                        >
                                                          <span
                                                            id="header43-ReasonForTravel-name"
                                                            className="ms-DetailsHeader-cellName cellName-112"
                                                          >
                                                            Checked In
                                                          </span>
                                                        </span>
                                                        <div id="header43-ReasonForTravel-tooltip"></div>
                                                      </div>
                                                    </div>
                                                    <div className="ms-DetailsHeader-cellSizer cellSizer-96 cellSizerStart-97"></div>
                                                    <div className="ms-DetailsHeader-cell is-actionable root-105 wc-width-150">
                                                      <div
                                                        className="ms-TooltipHostwx cellTooltip-113"
                                                        role="none"
                                                      >
                                                        <span
                                                          id="header43-ReasonForTravel"
                                                          className="ms-DetailsHeader-cellTitle cellTitle-111"
                                                        >
                                                          <span
                                                            id="header43-ReasonForTravel-name"
                                                            className="ms-DetailsHeader-cellName cellName-112"
                                                          >
                                                            Checked Out
                                                          </span>
                                                        </span>
                                                        <div id="header43-ReasonForTravel-tooltip"></div>
                                                      </div>
                                                    </div>
                                                    <div className="ms-DetailsHeader-cellSizer cellSizer-96 cellSizerStart-97"></div>
                                                    <div className="ms-DetailsHeader-cell is-actionable root-105 wc-width-120">
                                                      <div
                                                        className="ms-TooltipHostwx cellTooltip-113"
                                                        role="none"
                                                      >
                                                        <span
                                                          id="header43-ReasonForTravel"
                                                          className="ms-DetailsHeader-cellTitle cellTitle-111"
                                                        >
                                                          <span
                                                            id="header43-ReasonForTravel-name"
                                                            className="ms-DetailsHeader-cellName cellName-112"
                                                          >
                                                            Order Id
                                                          </span>
                                                        </span>
                                                        <div id="header43-ReasonForTravel-tooltip"></div>
                                                      </div>
                                                    </div>
                                                    <div className="ms-DetailsHeader-cellSizer cellSizer-96 cellSizerStart-97"></div>
                                                    <div className="ms-DetailsHeader-cell is-actionable root-105 wc-width-123">
                                                      <div
                                                        className="ms-TooltipHostwx cellTooltip-113"
                                                        role="none"
                                                      >
                                                        <span
                                                          id="header43-ReasonForTravel"
                                                          className="ms-DetailsHeader-cellTitle cellTitle-111"
                                                        >
                                                          <span
                                                            id="header43-ReasonForTravel-name"
                                                            className="ms-DetailsHeader-cellName cellName-112"
                                                          >
                                                            Amount
                                                          </span>
                                                        </span>
                                                        <div id="header43-ReasonForTravel-tooltip"></div>
                                                      </div>
                                                    </div>
                                                    <div className="ms-DetailsHeader-cellSizer cellSizer-96 cellSizerStart-97"></div>
                                                    <div className="ms-DetailsHeader-cell is-actionable root-105 wc-width-150">
                                                      <div
                                                        className="ms-TooltipHostwx cellTooltip-113"
                                                        role="none"
                                                      >
                                                        <span
                                                          id="header43-ReasonForTravel"
                                                          className="ms-DetailsHeader-cellTitle cellTitle-111"
                                                        >
                                                          <span
                                                            id="header43-ReasonForTravel-name"
                                                            className="ms-DetailsHeader-cellName cellName-112"
                                                          >
                                                            Balance
                                                          </span>
                                                        </span>
                                                        <div id="header43-ReasonForTravel-tooltip"></div>
                                                      </div>
                                                    </div>
                                                    <div className="ms-DetailsHeader-cellSizer cellSizer-96 cellSizerStart-97"></div>
                                                    <div className="ms-DetailsHeader-cell is-actionable root-105 wc-width-150">
                                                      <div
                                                        className="ms-TooltipHostwx cellTooltip-113"
                                                        role="none"
                                                      >
                                                        <span
                                                          id="header43-ReasonForTravel"
                                                          className="ms-DetailsHeader-cellTitle cellTitle-111"
                                                        >
                                                          <span
                                                            id="header43-ReasonForTravel-name"
                                                            className="ms-DetailsHeader-cellName cellName-112"
                                                          >
                                                            Sources
                                                          </span>
                                                        </span>
                                                        <div id="header43-ReasonForTravel-tooltip"></div>
                                                      </div>
                                                    </div>
                                                    <div className="ms-DetailsHeader-cellSizer cellSizer-96 cellSizerStart-97"></div>
                                                    <div className="ms-DetailsHeader-cell is-actionable root-105 wc-width-150">
                                                      <div
                                                        className="ms-TooltipHostwx cellTooltip-113"
                                                        role="none"
                                                      >
                                                        <span
                                                          id="header43-ReasonForTravel"
                                                          className="ms-DetailsHeader-cellTitle cellTitle-111"
                                                        >
                                                          <span
                                                            id="header43-ReasonForTravel-name"
                                                            className="ms-DetailsHeader-cellName cellName-112"
                                                          >
                                                            Status
                                                          </span>
                                                        </span>
                                                        <div id="header43-ReasonForTravel-tooltip"></div>
                                                      </div>
                                                    </div>
                                                    <div className="ms-DetailsHeader-cellSizer cellSizer-96 cellSizerStart-97"></div>
                                                    <div className="ms-DetailsHeader-cell is-actionable root-105 wc-width-143">
                                                      <div
                                                        className="ms-TooltipHostwx cellTooltip-113"
                                                        role="none"
                                                      >
                                                        <span
                                                          id="header43-ReasonForTravel"
                                                          className="ms-DetailsHeader-cellTitle cellTitle-111"
                                                        >
                                                          <span
                                                            id="header43-ReasonForTravel-name"
                                                            className="ms-DetailsHeader-cellName cellName-112"
                                                          >
                                                            Self Checked In
                                                          </span>
                                                        </span>
                                                        <div id="header43-ReasonForTravel-tooltip"></div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {/* STart here */}
                                                  {showDetails &&
                                                    roomdetails.map((rmd) => {
                                                      let checkinDate,
                                                        formattedDate,
                                                        checkoutDates,
                                                        formattedCheckoutDate,
                                                        rates,
                                                        formattedRate,
                                                        guestwitholdingbalance,
                                                        formattedBalance =
                                                          '0.00',
                                                        isavailable,
                                                        isSelChecking;

                                                      if (
                                                        rmd &&
                                                        rmd.checkedInTime
                                                      ) {
                                                        checkinDate = new Date(
                                                          rmd.checkedInTime
                                                        );
                                                        formattedDate =
                                                          checkinDate.toLocaleDateString(
                                                            'en-US',
                                                            options
                                                          );
                                                      }

                                                      if (
                                                        rmd &&
                                                        rmd.checkedOutTime
                                                      ) {
                                                        checkoutDates =
                                                          new Date(
                                                            rmd.checkedOutTime
                                                          );
                                                        formattedCheckoutDate =
                                                          checkoutDates.toLocaleDateString(
                                                            'en-US',
                                                            options
                                                          );
                                                      }

                                                      if (rmd?.rate) {
                                                        rates = rmd.rate;
                                                        formattedRate =
                                                          new Intl.NumberFormat(
                                                            'en-US'
                                                          ).format(rates);
                                                      }

                                                      if (rmd?.balance) {
                                                        guestwitholdingbalance =
                                                          rmd.balance;
                                                        formattedBalance =
                                                          new Intl.NumberFormat(
                                                            'en-US'
                                                          ).format(
                                                            guestwitholdingbalance
                                                          );
                                                      }

                                                      isavailable =
                                                        rmd?.availability
                                                          ? 'Available'
                                                          : 'Not Available';
                                                      isSelChecking =
                                                        rmd?.sources
                                                          ? 'Self Checking'
                                                          : 'No Self checking';

                                                      return (
                                                        <div className="ms-DetailsList-contentWrapper">
                                                          <div className="ms-SelectionZone">
                                                            <div className="ms-FocusZone css-44 focusZone-88">
                                                              <div className="ms-List">
                                                                <div className="ms-List-surface">
                                                                  <div className="ms-List-page">
                                                                    <div className="ms-List-cell">
                                                                      <div className="root_f67c6556">
                                                                        <div className="ms-FocusZone css-44 ms-DetailsRow is-contentUnselectable root-119">
                                                                          <div className="ms-DetailsRow-cell cell-122 cellUnpadded-120 cilsco-width">
                                                                            <div className="fieldRendererRendererWithCommands_3aa03b7c">
                                                                              <div className="nameFieldWidthOnHover_3aa03b7c">
                                                                                <span className="signalField_b9e74371 wide_b9e74371">
                                                                                  <span className="signalFieldValue_b9e74371">
                                                                                    <button
                                                                                      type="button"
                                                                                      data-automationid="FieldRenderer-name"
                                                                                      data-selection-invoke="true"
                                                                                      className="ms-Link nameField_36b20067 clickable_36b20067 overflow_85ed223c pre_85ed223c root-128"
                                                                                      title="Breakfast meet &amp; greet"
                                                                                      role="link"
                                                                                    >
                                                                                      {`${rmd?.guestFirstName}  ${rmd?.guestLastName}`}
                                                                                    </button>
                                                                                  </span>
                                                                                </span>
                                                                              </div>
                                                                              <div className="fieldRendererHeroCommandContainer_3aa03b7c">
                                                                                <div
                                                                                  className="root_15af61c7"
                                                                                  data-selection-touch-invoke="false"
                                                                                >
                                                                                  <button
                                                                                    type="button"
                                                                                    title="Show more actions for this item"
                                                                                    data-automationid="FieldRender-DotDotDot"
                                                                                    className="ms-Button ms-Button--icon heroButton_15af61c7 root-53"
                                                                                    aria-label="Show more actions for this item"
                                                                                    data-is-focusable="true"
                                                                                  >
                                                                                    <span
                                                                                      className="ms-Button-flexContainer flexContainer-46"
                                                                                      data-automationid="splitbuttonprimary"
                                                                                    >
                                                                                      <i className="ms-Icon root-32 css-41 ms-Button-icon icon-48">
                                                                                        
                                                                                      </i>
                                                                                    </span>
                                                                                  </button>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                          <div
                                                                            role="gridcell"
                                                                            className="ms-DetailsRow-cell cell-122 cellUnpadded-120 wc-cell-fixed-154"
                                                                            data-automationid="DetailsRowCell"
                                                                            data-automation-key="Capacity"
                                                                          >
                                                                            <div className="customField_6b0520e2">
                                                                              <div className="sp-field-customFormatter">
                                                                                <span className="wc-mark-position">
                                                                                  {
                                                                                    formattedDate
                                                                                  }
                                                                                </span>
                                                                                <div className="wc-mini"></div>
                                                                                <div className="sp-css-backgroundColor-blueBackground07 sp-css-borderTop-blueBorder wc-spark"></div>
                                                                                <div className="wc-selfless"></div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                          <div
                                                                            role="gridcell"
                                                                            className="ms-DetailsRow-cell cell-122 cellUnpadded-120 wc-cell-fixed-154"
                                                                            data-automationid="DetailsRowCell"
                                                                            data-automation-key="Capacity"
                                                                          >
                                                                            <div className="customField_6b0520e2">
                                                                              <div className="sp-field-customFormatter">
                                                                                <span className="wc-mark-position">
                                                                                  {
                                                                                    formattedCheckoutDate
                                                                                  }
                                                                                </span>
                                                                                <div className="wc-mini"></div>
                                                                                <div className="sp-css-backgroundColor-blueBackground07 sp-css-borderTop-blueBorder wc-spark"></div>
                                                                                <div className="wc-selfless"></div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                          <div
                                                                            role="gridcell"
                                                                            className="ms-DetailsRow-cell cell-122 cellUnpadded-120 wc-width-120"
                                                                            data-automationid="DetailsRowCell"
                                                                            data-automation-key="SessionCode"
                                                                          >
                                                                            <div
                                                                              className="od-FieldRenderer-text fieldText_dc7ba0fe"
                                                                              dir="auto"
                                                                            >
                                                                              {
                                                                                rmd?.orderId
                                                                              }
                                                                            </div>
                                                                          </div>
                                                                          <div
                                                                            role="gridcell"
                                                                            className="ms-DetailsRow-cell cell-122 cellUnpadded-120 wc-width-120"
                                                                          >
                                                                            <div className="customField_6b0520e2">
                                                                              <div className="sp-field-customFormatter">
                                                                                <div className="wc-amount">
                                                                                  <span className=" "></span>
                                                                                  <span className="WC-MONEY">
                                                                                    &#8358;
                                                                                    {
                                                                                      formattedRate
                                                                                    }
                                                                                  </span>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                          <div
                                                                            role="gridcell"
                                                                            className="ms-DetailsRow-cell cell-122 cellUnpadded-120 wc-width-120"
                                                                          >
                                                                            <div className="customField_6b0520e2">
                                                                              <div className="sp-field-customFormatter">
                                                                                <div className="wc-Curent-amount">
                                                                                  <span className=" "></span>
                                                                                  <span className="WC-MONEY">
                                                                                    &#8358;{' '}
                                                                                    {
                                                                                      formattedBalance
                                                                                    }
                                                                                  </span>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                          <div
                                                                            role="gridcell"
                                                                            className="ms-DetailsRow-cell cell-109 cellUnpadded-107 wc-width-150"
                                                                          >
                                                                            <div className="customField_6b0520e2">
                                                                              <div className="sp-field-customFormatter">
                                                                                <div className="sp-field-borderAllRegular sp-field-borderAllSolid sp-css-borderColor-neutralPrimary">
                                                                                  <span className="css-116"></span>
                                                                                  <span className="wc-Sources">
                                                                                    {
                                                                                      rmd?.sources
                                                                                    }
                                                                                  </span>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                          <div
                                                                            role="gridcell"
                                                                            className="ms-DetailsRow-cell cell-109 cellUnpadded-107 wc-width-150"
                                                                          >
                                                                            <div className="customField_6b0520e2">
                                                                              <div className="sp-field-customFormatter">
                                                                                <div className="sp-field-borderAllRegular sp-field-borderAllSolid sp-css-borderColor-neutralPrimary">
                                                                                  <span className="css-116"></span>
                                                                       <span className="wc-Sources">
                                                                     {isavailable}
                                                                                  </span>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                          <div
                                                                            role="gridcell"
                                                                            className="ms-DetailsRow-cell cell-109 m-8px cellUnpadded-107 wc-width-150"
                                                                          >
                                                                            <div className="customField_6b0520e2">
                                                                              <div className="sp-field-customFormatter">
                                                                                <div className="sp-field-borderAllRegular sp-field-borderAllSolid sp-css-borderColor-neutralPrimary">
                                                                                  <span className="css-116"></span>
                                                                                  <span className="wc-Sources">
                                                                                    {
                                                                                      isSelChecking
                                                                                    }
                                                                                  </span>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      );
                                                    })}
                                                  {/* End Template here */}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>)}
                  
                       

                        
                      </div>
                    </div>
                    <div className="createList-pageFooter manage-roomview-dialog">
                      <div className="createList-leftActions">
                        <Button
                          data-automationid="createListGoBackButton"
                          className=""
                          icon={<ChevronLeft24Regular />}
                          onClick={returnBack}
                        >
                          Back
                        </Button>
                      </div>
                      <div className="createList-rightActions">
                        <Button
                          className="wc-first-root-68"
                          onClick={toggleForm}
                        >
                          {ActionText}
                        </Button>
                        <Button className="" onClick={onClose}>
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleRoomViewDialog;
