
export interface Inventory {
    id: number | null;
    name: string | null;
    desc: string | null;
}


export interface Workflow {
    id: number | null;
    name: string | null;
    desc: string | null;
}


export const Invent: Inventory[] = [
    {
        id: 1,
        name: 'Reservation Management',
        desc: 'Manage and control your reservation',
    },
    {
        id: 2,
        name: 'Room Inventory Management',
        desc: 'Manage room inventory and more',
    },
    {
        id: 3,
        name: 'Guest Profile Management',
        desc: 'Manage Guest profiles',
    },
    {
        id: 4,
        name: 'Billing and Invoicing',
        desc: 'Manage your billing and invoice',
    },
    {
        id: 5,
        name: 'Point of Sale (POS)',
        desc: 'Manage your point of sells and services',
    },
    {
        id: 6,
        name: 'Housekeeping and Maintenance',
        desc: 'Manage and control house keeping schedule',
    },
    {
        id: 7,
        name: 'Reporting and Analytics',
        desc: 'Manage Report and analysis',
    },
    {
        id: 8,
        name: 'Online Booking',
        desc: 'Online Booking Management and services',
    },
    {
        id: 9,
        name: 'Front Desk Operations',
        desc: 'Manage Front desk operation',
    },
    {
        id: 10,
        name: 'Loyalty Program',
        desc: 'Update your room royality program',
    },
    {
        id: 11,
        name: 'Staff Management and Scheduling',
        desc: 'schedule staff Activities',
    },
    {
        id: 12,
        name: 'Guest Communication & Messaging',
        desc: 'Control guest Communication',
    },
    {
        id: 13,
        name: 'Revenue and Rate Optimizationg',
        desc: 'Revenue Optimization engine',
    },
    {
        id: 14,
        name: 'Security and Access Control',
        desc: 'Manage security and roles',
    },
    {
        id: 15,
        name: 'Mobile Apps',
        desc: 'Manage Hotel Mobile services',
    },
    {
        id: 17,
        name: 'Concierge Services',
        desc: 'Manage concierge services',
    },
    {
        id: 18,
        name: 'Spa and Wellness Management',
        desc: 'Manage spa and Wellness',
    },
    {
        id: 19,
        name: 'Banquet and Event Planning',
        desc: 'Banquet and event planning',
    },
    {
        id: 20,
        name: 'Accounting and Financial',
        desc: 'Accounting and Financial services',
    },
    {
        id: 21,
        name: 'Inventory and Procurement',
        desc: 'Inventory and procurement services',
    },
    {
        id: 22,
        name: 'Maintenance & Asset Management',
        desc: 'Maintenance and Asset Management'
    },
    {
        id: 23,
        name: 'Staff Management',
        desc: 'Control and manage enployees'
    }
];


export const Settups: Workflow[] = [
    {
        id: 24,
        name: 'Employee Management',
        desc: 'Register New Employee',
    },
    {
        id: 25,
        name: 'Business Task',
        desc: 'Register Business Task',
    },
    {
        id: 26,
        name: 'Event Planing',
        desc: 'New Event Planing',
    },
    {
        id: 27,
        name: 'Business & Location',
        desc: 'Register New Location',
    },
    {
        id: 28,
        name: 'Genders Mnagement',
        desc: 'Register new Genders',
    },
    {
        id: 29,
        name: 'Title Management',
        desc: 'Register New Title',
    },
    {
        id: 30,
        name: 'Bar Service',
        desc: 'Register New Bar Services',
    },
    {
        id: 31,
        name: 'Event Service',
        desc: 'Register New Event Service',
    },
    {
        id: 32,
        name: 'Room Service',
        desc: 'Register new service',
    },
    {
        id: 33,
        name: 'Reservation Service',
        desc: 'New Reservation Service',
    },
    {
        id: 34,
        name: 'Restuarant Services',
        desc: 'New Restuarant Service',
    },
    {
        id: 35,
        name: 'Gift Shop Services',
        desc: 'Register New Gift shops',
    },
    {
        id: 36,
        name: 'Spa & Wellnes',
        desc: 'New Spa & wellness',
    },
    {
        id: 37,
        name: 'Bar Category',
        desc: 'New Bar Category',
    },

    {
        id: 38,
        name: 'Amenity',
        desc: 'New Amenity',
    },
    {
        id: 39,
        name: 'Amenity Types',
        desc: 'New Amenity Types',
    },
    {
        id: 40,
        name: 'Department',
        desc: 'New Department',
    },
    {
        id: 41,
        name: 'Unit',
        desc: 'New Unit',
    },

    {
        id: 42,
        name: 'Laundry',
        desc: 'New Laundry Services',
    },

    {
        id: 43,
        name: 'Products',
        desc: 'New Product',
    },

    {
        id: 44,
        name: 'Role Management',
        desc: 'New Role Management',
    },
];