export interface IBarCategory {
    categoryId: number;
    categoryName: string | null;
    description: string | null;
    updatedby:string | null;
    imageUrl: string | null;
    isActive: boolean | null;
}


export const InitialIBarCat :IBarCategory = {
    categoryId: 0,
    categoryName: '',
    description: '',
    imageUrl: '',
    isActive: false,
    updatedby: '',
}

export interface BarLoungeCategory {
    categoryId: number;
    categoryName: string;
    description: string;
    imageUrl: string;
    isActive: boolean;
    dateadded: Date;
    updatedby:string;
}