import React, { useState, ChangeEvent, FormEvent, useEffect, useCallback } from "react";
import "./AIUI.scss";
import { Button, Field, Spinner, Textarea } from "@fluentui/react-components";
import { Dismiss16Regular, Send16Filled } from "@fluentui/react-icons";
import Loaders from "../Common/Loader/Loader";
import { GetEmployee } from "../../Data/General/Employee";
import axios from "axios";
import { WHITE_HOUSE_API } from "../../Constants/Constant";

interface User {
    email: string,
    phoneNumber:string,
    token:string,
  
    // other properties...
  }

type controlProps = {
    close: () => void;
    user:User;
}

const AI: React.FC<controlProps> = ({close, user}) => {
    const [focus, setFocus] = useState(false);
    const [AIResult, setAIResult] = useState<[]>([]);
    const [text, setText] = useState('');
    const [isLoader, setLoader] = useState<boolean>(false);
    const [loggedInUser, setLoggedInUser] = useState<GetEmployee>();
    const handleFocus = () => {
        setFocus(true);
    };

    const handleBlur = () => {
        if (text === '') {
            setFocus(false);
        }
    };

    const handleChange = (event:any) => {
        setText(event.target.value);
    };
    const fetchLoggedInUser = useCallback(async () => {
        try {
          const response = await axios.get(`${WHITE_HOUSE_API}/User/ActiveUser?phoneNumber=${user.phoneNumber}&email=${user.email}`, {
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
          });
          setLoggedInUser(response.data);
          console.error('Error fetching clock-out status', response.data);
        } catch (error) {
          console.error('Error fetching clock-out status', error);
        }
      }, [user.email, user.phoneNumber, user.token]);


const handleSubmitQuery = () => {
    /****ai interraction */
    setLoader(true);
}

useEffect(() => {
    fetchLoggedInUser();
  }, [fetchLoggedInUser]);


    return (
        <>

<div className="ms-Fabric ms-Layer-content content-46">
    <div className="wc-no outline">
        <div className="ms-Modal is-open root-39" style={{zIndex: '10878993'}}>
            <div aria-hidden="true" className="ms-Overlay ms-Overlay--dark root-48"></div>
            <div className="ms-Dialog-main YONwB main-571">
                <div className="GETFET_377H">
                <Button onClick={close} icon={<Dismiss16Regular />}></Button>
                </div>
                <div className="ms-Modal-scrollableContent scrollableContent-351">
                    <div className="ENdu4 wjnYEBB">
                        <div className={`MERYBR_836 EGME_Full ${focus ? 'B673_ACTIBWU': ''}`}>             
                        <textarea className={`TXT_873BE`}  
                         placeholder={`Welcome! ${loggedInUser?.firstName}. What can I answer for you now?`}
                         onFocus={handleFocus}
                         onBlur={handleBlur} 
                         onChange={handleChange}
                         value={text}/>
                        <Button onClick={handleSubmitQuery} className={`MBETY_37T63G ${text !== '' ? 'H93_YEB': ''}`} icon={<Send16Filled  />}></Button>
                        
                    </div>
                    {isLoader ? (
                        <div className="EJTR5_U76">
                            <Spinner appearance="primary"/>
                        </div>                   
                    ) : (
                        AIResult && AIResult.length > 0 && (
                            <div className="WERUWN ease-in-out">
                                <div className="customScrollBar nnwgTHEN">
                                    <div className="UENG293N">
                                        <div className="JMWKKUE">
                                            <div className="EHHBG_278"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    )}

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
                
        </>
    )
}
export default AI;