export interface IHotelBarBill {
    billId: number;
    customerId: number;
    adjusterId:number;
    dateadded: Date;
    itemId: number;
    itemName?: string | null;
    quantity: number;
    price: number;
    totalAmount: number;
    orderId: number;
  }
  
  export const InitHotelBarBill: IHotelBarBill = {
    billId: 0,
    customerId: 0,
    itemId: 0,
    itemName: "",
    quantity: 0,
    price: 0,
    totalAmount: 0,
    orderId: 0,
    dateadded: new Date(),
    adjusterId: 0
  }