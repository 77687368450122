import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';

import './AmenityTypes.scss';
import {
  Button,
  Combobox,
  Input,
  Label,
  Option,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Persona,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Toolbar,
  ToolbarButton,
  ToolbarDivider,
  TableSelectionCell,
  useTableSelection,
  useTableSort,
  useTableFeatures,
  Spinner,
  Checkbox,
  TableCellLayout,
} from '@fluentui/react-components';
import {
  Add24Regular,
  ArrowBetweenDown24Regular,
  ArrowCounterclockwise24Regular,
  ArrowDownload24Filled,
  Delete24Regular,
  DeleteRegular,
  Edit24Regular,
  EditRegular,
  Money24Regular,
  PersonVoice24Regular,
  PresenceAvailable24Filled,
} from '@fluentui/react-icons';
import { WHITE_HOUSE_API } from '../../../Constants/Constant';
import axios from 'axios';
import { BarLoungeCategory } from '../../../Data/General/IBarCategory';
import { IAmenity } from '../../../Data/General/Amenities';
import { AmenityType } from '../../../Data/General/AmenityTypes';

interface User {
  email: string;
  phoneNumber: string;
  token: string;
}

type Adjuster = {
  firstname?: string;
  lastname?: string;
  phoneNumber?: string;
  userid?: number;
};
interface Message {
  message: string;
  id: number;
}
interface MRaiseInvoice {
  onOpenForm: () => void;
  user: User;
  adjuster: Adjuster;
  message: Message;
}

const AmenityTypes: React.FC<MRaiseInvoice> = ({
  onOpenForm,
  adjuster,
  message,
  user,
}) => {
  const [refreshKey, setRefreshKey] = useState(0);
  const [isRotated, setIsRotated] = useState(false);
  const [getAmenity, setGetAmenity] = useState<AmenityType[]>([]);
  const [selectedCateItem, setSelectedCatItem] = useState<number | null>(null);
  const [isHovered, setIsHovered] = useState<Record<number, boolean>>({});
  const [searchTerm, setSearchTerm] = useState('');

  const handleRefresh = () => {
    setIsRotated(true);
    setRefreshKey((prevKey) => prevKey + 1);
    setTimeout(() => {
      setIsRotated(false);
    }, 3000);
  };

  const fetchAmenityTypes = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/AmenityTypes`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setGetAmenity(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);

  const handleDeleteItem = async (id: any) => {
    try {
      const response = await axios.delete(
        `${WHITE_HOUSE_API}/AmenityTypes/${id}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (response.data.status === 'Success') {
        message.message = response.data.eMessage;
        fetchAmenityTypes();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleUpdateitem = (u: any) => {
    onOpenForm();
    message.id = u;
    console.log('id oss the amenity', u);
  };
  useEffect(() => {
    fetchAmenityTypes();
  }, [fetchAmenityTypes, user.token]);

  let options: {
    weekday: 'long' | 'short' | 'narrow';
    hour: 'numeric' | '2-digit';
    minute: 'numeric' | '2-digit';
    hour12: boolean;
  } = {
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const handleOpenAmenity = () => {
    message.id = 0;
    onOpenForm();
  };

  return (
    <>
      <div className="ts-messages-header messages-header-v2">
        <div className="app-messages-header ts-section-divider">
          <div className="app-chat-header-content">
            <Toolbar aria-label="with Popover" size="small">
              <ToolbarButton
                onClick={handleOpenAmenity}
                aria-label="Add"
                icon={<Add24Regular />}
              >
                Create Amenity Types
              </ToolbarButton>
              <ToolbarDivider />
              <ToolbarButton icon={<PersonVoice24Regular />}>
                Feed back
              </ToolbarButton>
            </Toolbar>
          </div>
        </div>
      </div>
      {/* home display */}
      <div className="ts-tab-content flex-fill">
        <div className="ts-embedded-container flex-fill hybrid-passthrough">
          <div className="content-child flex-fill">
            <div className="ts-embedded-container flex-fill hybrid-passthrough">
              <div className="content-child flex-fill">
                <div className="ext-info-section fxc-base fxc-section fxc-section-wrapper"></div>
                <div className="ext-search-pill-container">
                  <div className="azc-inputbox-wrapper azc-textBox-wrapper input-qty">
                    <Input
                      placeholder="Search category"
                      value={searchTerm}
                      onChange={(event) => setSearchTerm(event.target.value)}
                    />
                  </div>
                  <div className="ext-pill-control fxc-base">
                    <div className="fxc-pill">
                      <span className="fxc-pill-content fxc-pill-content-no-remove-button fxc-pill-read-mode">
                        <span
                          className="fxc-pill-inner-content"
                          id="_weave_e_1183"
                        >
                          <div
                            className="fxc-pill-read-container"
                            id="_weave_e_1179"
                          >
                            <span
                              className="fxc-pill-content-key"
                              id="_weave_e_1180"
                            >
                              Adjuster:{' '}
                            </span>
                            <span
                              className="fxc-pill-content-operator"
                              id="_weave_e_1181"
                            >
                              :
                            </span>
                            <b
                              className="fxc-pill-content-value"
                              id="_weave_e_1182"
                            >{`${adjuster.lastname} ${adjuster.firstname}`}</b>
                          </div>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="ext-pill-control fxc-base">
                    <div className="fxc-pill">
                      <span className="fxc-pill-content fxc-pill-content-no-remove-button fxc-pill-read-mode">
                        <span
                          className="fxc-pill-inner-content"
                          id="_weave_e_1183"
                        >
                          <div
                            className="fxc-pill-read-container"
                            id="_weave_e_1179"
                          >
                            <span
                              className="fxc-pill-content-key"
                              id="_weave_e_1180"
                            >
                              Feed
                            </span>
                            <span
                              className="fxc-pill-content-operator"
                              id="_weave_e_1181"
                            >
                              :
                            </span>
                            <b
                              className="fxc-pill-content-value"
                              id="_weave_e_1182"
                            >
                              No feed back from adjuster
                            </b>
                          </div>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="sbdocs-preview">
                  <div className="ext-azure-datagrid-container paddin-table ext-azure-subscription-grid-cell fxc-base fxc-gc-vscroll fxc-gc-hscroll fxc-gc-fixture fxs-vivaresize">
                    <div className="fxc-gc azc-fabric fxc-gc-dataGrid">
                      <div className="fxc-gc azc-fabric fxc-gc-dataGrid Overflow-exgrid customScrollBar">
                        <Table
                          size="small"
                          role="grid"
                          aria-label="Table with paddin-table grid keyboard navigation"
                        >
                          <TableHeader>
                            <TableRow>
                              <TableHeaderCell className="autoTableCell">
                                <Checkbox /> ID
                              </TableHeaderCell>
                              <TableHeaderCell className="autoTableCell">
                                Name
                              </TableHeaderCell>
                              <TableHeaderCell className="autoTableCell">
                                Discription
                              </TableHeaderCell>
                              <TableHeaderCell className="autoTableCell">
                                Registration Date
                              </TableHeaderCell>
                              <TableHeaderCell className="autoTableCell">
                                Last Updated
                              </TableHeaderCell>
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            {getAmenity
                              .filter((item) =>
                                item.amenityTypeName
                                  .toLowerCase()
                                  .includes(searchTerm.toLowerCase())
                              )
                              .map((item) => {
                                let dates = new Date(item.dateAdded);
                                let formattedDate = dates.toLocaleString(
                                  'en-US',
                                  options
                                );

                                return (
                                  <TableRow
                                    key={item.amenityTypeId}
                                    className={
                                      item.amenityTypeId === selectedCateItem
                                        ? 'cat-nhxbackground'
                                        : ''
                                    }
                                    onClick={() =>
                                      setSelectedCatItem((prev) =>
                                        prev === item.amenityTypeId
                                          ? null
                                          : item.amenityTypeId
                                      )
                                    }
                                  >
                                    <TableCell className="autoTableCell">
                                      <Checkbox
                                        checked={
                                          item.amenityTypeId ===
                                          selectedCateItem
                                        }
                                      />{' '}
                                      {item.amenityTypeId}
                                    </TableCell>
                                    <TableCell className="autoTableCell">
                                      {item.amenityTypeName}
                                    </TableCell>
                                    <TableCell className="autoTableCell">
                                      {item.description}
                                    </TableCell>
                                    <TableCell className="autoTableCell">
                                      {new Date(item.dateAdded).toLocaleString(
                                        'en-US',
                                        {
                                          year: 'numeric',
                                          month: 'short',
                                          day: 'numeric',
                                          hour: 'numeric',
                                          minute: 'numeric',
                                          hour12: true,
                                        }
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <TableCellLayout
                                        className="Curspointer-event"
                                        onMouseEnter={() =>
                                          setIsHovered((prevState) => ({
                                            ...prevState,
                                            [item.amenityTypeId]: true,
                                          }))
                                        }
                                        onMouseLeave={() =>
                                          setIsHovered((prevState) => ({
                                            ...prevState,
                                            [item.amenityTypeId]: false,
                                          }))
                                        }
                                      >
                                        {isHovered[item.amenityTypeId] && (
                                          <>
                                            <DeleteRegular
                                              className="delete-icon-spacing"
                                              onClick={() =>
                                                handleDeleteItem(
                                                  item.amenityTypeId
                                                )
                                              }
                                            />
                                            <EditRegular
                                              className="delete-icon-spacing"
                                              onClick={() =>
                                                handleUpdateitem(
                                                  item.amenityTypeId
                                                )
                                              }
                                            />
                                          </>
                                        )}
                                        {formattedDate ?? 'No recent update'}
                                      </TableCellLayout>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AmenityTypes;
