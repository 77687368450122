import { SearchBox } from '@fluentui/react-search-preview';
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Avatar,
  AvatarGroup,
  AvatarGroupItem,
  Checkbox,
  Field,
  Label,
  Radio,
  RadioGroup,
  Select,
  useToastController,
} from '@fluentui/react-components';
import axios, { AxiosError } from 'axios';
import { Persona, PersonaSize, initializeIcons } from '@fluentui/react';
import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Input } from '@fluentui/react-components';
import {
  ChevronLeft24Regular,
  PersonAdd24Regular,
  PeopleRegular,
  WeatherRainShowersNight24Regular,
} from '@fluentui/react-icons';

import {
  Dropdown,
  makeStyles,
  Option,
  shorthands,
  useId,
  Caption1,
  tokens,
  Text,
  Link,
  Button,
  Toaster,
  Toast,
  ToastTitle,
  ToastBody,
  ToastFooter,
} from '@fluentui/react-components';
import type { DropdownProps, SelectOnChangeData } from '@fluentui/react-components';
import {
  IDropdownOption,
  DetailsList,
  IColumn,
  CheckboxVisibility,
} from '@fluentui/react';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import './RoomRegisterationForm.scss';
import { IRoomManagement, InitRoomManagement } from '../../../../Data/General/RoomManagement';
import { WHITE_HOUSE_API } from '../../../../Constants/Constant';
import Loaders from '../../../Common/Loader/Loader';
import DispatchToast from '../../../Common/Toast/DispatchToast';




interface Titles {
  titleId: number;
  titleName: string;
}

interface Message {
  message: string,
  id:number,
}

type Adjuster = {
  firstname?:string;
  lastname?:string;
  phoneNumber?:string;
  userid?: number;
}
interface RoomResponse {
    message: string;
  }
interface Genders {
  genderId: number;
  genderName: string;
}
const useStyles = makeStyles({
  main: {
    ...shorthands.gap('16px'),
    display: 'flex',
    flexWrap: 'wrap',
  },

  card: {
    width: '400px',
    maxWidth: '100%',
    minHeight: '250px',
    height: 'fit-content',
  },

  caption: {
    color: tokens.colorNeutralForeground3,
  },

  smallRadius: {
    ...shorthands.borderRadius(tokens.borderRadiusSmall),
  },

  grayBackground: {
    backgroundColor: tokens.colorNeutralBackground3,
  },

  control: {
    maxWidth: '300px',
  },
  wrapper: {
    columnGap: '15px',
    display: 'flex',
    marginBottom: '10px',
  },
});
interface User {
  email: string,
  phoneNumber:string,
  token:string,

  // other properties...
}
type registerNewRoomProps = {
  close: () => void;
  user: User; 
  message:Message;
};

const RoomRegisterationForm: React.FC<registerNewRoomProps> = ({ close, message ,user }) => {
  const [showForm, setShowForm] = useState(false);
  const [showDetails, setshowDetails] = useState(true);
  const ActionText = showForm ? 'Submit' : 'Book Now';
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<IRoomManagement[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [showList, setShowList] = useState(false);
  const [hideAll, setHideAll] = useState(true);
  const [titleMapping, setTitleMapping] = useState<Titles[]>([]);
  const [filteredOptions, setFilteredOptions] = useState(titleMapping);
  const outlineId = useId('input-outline');
  const underlineId = useId('input-underline');
  const filledLighterId = useId('input-filledLighter');
  const filledDarkerId = useId('input-filledDarker');
  const [isSearchActive, setisSearchActive] = useState(false);
  const [registerNewRoom, setRegisterNewRoom] = useState<IRoomManagement>(InitRoomManagement);
  const [genders, setGenderMapping] = useState<Genders[]>([]);
  const [isRoleOpen, setIsRoleOpen] = useState<boolean>(false);
  const toasterId = useId('toaster');
  const { dispatchToast } = useToastController(toasterId);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setsuccessMessage] = useState('');
  const [saveLaoding, setSaveLaoding] = useState<boolean>(false);
  const [dispatchToaster, setDispatchToaster] = useState<boolean>(false);
    const [isLoader, setIsLoader] = useState<boolean>(false);
  const styles = useStyles();
  initializeIcons();
  const beforeLabelId = useId('before-label');
  const afterLabelId = useId('after-label');
  const [currency, setCurrency] = useState('NGN');
  const currencySymbol = currency === 'NGN' ? '₦' : '$';

  const handleCurrencyChange = (event: any) => {
    const option = event.target.value;
    setCurrency(option);
  };

  const handleCloseRoom = () => {
    close();
  };

  const items = [
    { key: '1', name: 'Name', value: 'Flat1 Deluxy' },
    { key: '2', name: 'Floor', value: 2 },
    { key: '3', name: 'Area', value: 28 },
    { key: '4', name: 'Capacity', value: '2 + 1, Max. 3' },
    { key: '5', name: 'Cleaning Required', value: 'Yes' },
    { key: '6', name: 'Smoking Allowed', value: 'No' },
  ];

  const columns: IColumn[] = [
    {
      key: 'column1',
      name: 'Name',
      fieldName: 'name',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column2',
      name: 'Value',
      fieldName: 'value',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  const handleBrowseAllClick = () => {
    setShowList((prevShowList) => !prevShowList);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(`Searching for: ${searchTerm}`);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  const fetchTitles = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/Title`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
      const Titles = response.data;
      setTitleMapping(Titles);
      setFilteredOptions(Titles);
      console.log('Title items:', Titles);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  }, [user.token]);
  

  const fetchgenders = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/Gender`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
      const genders = response.data;
      setGenderMapping(genders);
      console.log('Business items:', genders);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  }, [user.token]);
  

  const fetchExistingRoom = useCallback(async (sid:any) => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/Rooms/CreatedRoom?id=${sid}`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
      setRegisterNewRoom(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);
  
  
  

  useEffect(() => {
    if(message.id > 0)
    {
        fetchExistingRoom(message.id);
    }
  }, [fetchTitles, currency, currencySymbol, fetchgenders, message.id, fetchExistingRoom, registerNewRoom]);

  const handleInputChange = (event: any) => {
    const que = event.target.value;
    setisSearchActive(event.target.value !== '');
    setQuery(que);
    if (que.length >= 1) {
      search(que);
    }
  };
  const toggleForm = () => {
    setShowForm((c) => !c);
    setshowDetails((c) => !c);
  };


const handleRoomInputChange = (
  event: React.ChangeEvent<HTMLInputElement>
) => {
  const { name, value, files } = event.target;
  if (files) {
    console.log("The file exist here: ", files);
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        setRegisterNewRoom((prevState) => ({ ...prevState, [name]: base64String }));
      };
      reader.readAsDataURL(files[0]);
  } else {
    setRegisterNewRoom((prevState) => ({ ...prevState, [name]: value }));
  }
};





  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    if (checked) {
        setRegisterNewRoom((prevState) => ({ ...prevState, modeofPayment: name }));
    } else if (registerNewRoom.roomName === name) {
        setRegisterNewRoom((prevState) => ({
        ...prevState,
        ModeofPayment: name,
      }));
    }
  };

  const HandleMultipleRadio = (
    event: React.FormEvent<HTMLDivElement>,
    data: any
  ) => {
    const { name, value } = data;
    setRegisterNewRoom((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleEmployeeRadioChange = (
    event: React.FormEvent<HTMLDivElement>,
    data: any
  ) => {
    const { value } = data;
    setRegisterNewRoom((prevState) => ({ ...prevState, Currency: value }));
    setCurrency(value);
  };
   
  const handleRelationshipRadioChange = (
    event: React.FormEvent<HTMLDivElement>,
    data: any
  ) => {
    const { value } = data;
    setRegisterNewRoom((prevState) => ({ ...prevState, bedType: value }));
  };


  const handleInputRadioChange = (event: React.FormEvent<HTMLDivElement>,data: any) => {
      const { value } = data;
      const name = value;
      console.log("The selected value: ", name);
      if (value === 'availability') {
        setRegisterNewRoom((prevState) => ({ ...prevState, [name]: true }));
      } else {
        setRegisterNewRoom((prevState) => ({ ...prevState, [name]: false }));
      }
  };
  
  
  const handleEmplyeeDateInputChange =
    (fieldName: string) => (date: Date | null | undefined) => {
      if (date) {
        console.log(`The Date onSelectDate for ${fieldName}`, date);
        setRegisterNewRoom((prevState) => ({
          ...prevState,
          [fieldName]: date,
        }));
      }
    };


    const search = async (query: any) => {
      console.log("Bearer: ", user.token);
      try {
        const response = await axios.get(
          `${WHITE_HOUSE_API}/Rooms/SearchCreatedRoom?value=${query}`,
          {
            headers: {
              'Authorization': `Bearer ${user.token}`
            },
            params: { query },
          }
        );
        console.log("Response data: ", results);
        setResults(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    


const handleSubmitEmployeeClick = async () => {
  setIsLoader(true);
    try { 
      const response = await axios.post(
        `${WHITE_HOUSE_API}/Rooms/Create`,
        registerNewRoom,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.token}`
        },        
        }
      );
      if (response.data.status === "Success") {
        setsuccessMessage(response.data.eMessage);
        setDispatchToaster(true);
        setIsLoader(false);
        console.log("message", message);
        setTimeout(() => {
          setDispatchToaster(false);
          setRegisterNewRoom({...InitRoomManagement});
          
        }, 1500);
      }
      
    } catch (error) {
      console.error(error);
      setIsLoader(false);
      setSaveLaoding(false);
      if (axios.isAxiosError(error)) {
       
          const axiosError = error as AxiosError<RoomResponse>;
          if (
            axiosError.response &&
            axiosError.response.data &&
            axiosError.response.data.message
          ) {
          setDispatchToaster(true);
          setIsLoader(false);
          setTimeout(() => {
            setDispatchToaster(false);
          }, 1500);
          }
       
      }
    }
  
};


const handleEmployeeSelectInputChange = (event: React.ChangeEvent<HTMLSelectElement>, data: SelectOnChangeData) => {
  const name = event.target.name;
  const value = data.value;
  console.log("the selected item name: ", name +"The value: " + value);
  setRegisterNewRoom((prevState) => ({ ...prevState, [name]: value }));
};

const handleUserSelect = (uid:any) => {
  message.id = 0;
  fetchExistingRoom(uid);
  setisSearchActive(false);
}

  return (
    <>
      <div className="ms-Fabric ms-Layer-content content-46">
        <div className="wc-no outline">
          <div className="ms-Modal is-open root-39">
            <div
              aria-hidden="true"
              className="ms-Overlay ms-Overlay--dark root-48"
            ></div>
            <div
              id="cilsco-focuszone"
              className="ms-Dialog-main createListModalMain_979b2e47 main-40"
            >
              <div
                aria-hidden="true"
                id="Ariel-fixed"
                data-is-visible="true"
                data-is-focus-trap-zone-bumper="true"
              ></div>
               
             {isLoader && (
            <div className="restrict-the-inner">
                <Loaders />

            </div>
            )}
          
              <div className="ms-Modal-scrollableContent scrollableContent-54 customScrollBar">
                <div className="Merging-34">
                  <div className="createList-root templatePreviewPage">
                    <div className="templatePreview">
                      <div className="createList-pageRoot templatePreviewContainer">
                        <div className="createList-header">
                        {dispatchToaster && (
                            <DispatchToast message={successMessage}/>
                        )}
                          <div className="templateTitleWithLogo">
                            <Avatar
                              icon={<PersonAdd24Regular />}
                              aria-label="Group"
                            />
                            <h1 className="createList-headerTitle wc-margin">
                              Room Form
                            </h1>
                          </div>
                          {/* use this to update all my search */}
                          <div
                            className={`ui-box Wsearch-ev-ew c-search_modal__wrapper popover-little ${
                              isSearchActive ? 'mouse-over-active' : ''
                            }`}
                          >

                            <SearchBox
                              placeholder="Search and update existing Rooms .."
                              value={query}
                              onChange={handleInputChange}
                              onBlur={search}
                            
                              className="sear-padd"

                            />
                            <div
                              className={`c-search_autocomplete__container c-search_autocomplete__container--query-ordered ${
                                isSearchActive ? 'serachbar-active' : ''
                              }`}
                            >
                              <div className="c-search_autocomplete c-search_autocomplete--nav-attached">
                                <div className="offscreen">
                                  <span id="search-autocomplete-query-label-prefix-node">
                                    Search
                                  </span>
                                </div>
                                {results.map((r) => {
                                 
                                  return (
                                    <div
                                      key={r.roomId}
                                      className="c-search_autocomplete__suggestion_list c-search_autocomplete__suggestion_list--taller"
                                      onClick={() => handleUserSelect(r.roomId)}
                                    >
                                      <div className="curtain-a wform-css-283-right remove-bos-shadow">
                                        <div className="fui-Persona rlroi9i remove-bos-shadow">
                                          <span
                                            role="img"
                                            id="avatar-rd"
                                            className="fui-Avatar r81b29z fui-Persona__avatar ___1c989qy_1o9k6tz f13ar0e0 fid048z f1hek2iy f9h729m f18q9vkd f103ycu4 remove-bos-shadow"
                                            aria-label="Kevin Sturgis"
                                            aria-labelledby="avatar-rd avatar-rd__badge"
                                          >
                                            <AvatarGroup>
                                              <AvatarGroupItem
                                                name={r.roomName}
                                              />
                                            </AvatarGroup>
                                          </span>
                                          <span className="fui-Persona__primaryText rvj41k9">
                                            {r.roomName}
                                          </span>
                                          <span className="fui-Persona__secondaryText rp1pf9e ___19lp2a0_1p5a1yj f1ryq6si">
                                            {r.rate?.toLocaleString()}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          {/* use this to update all my search */}
                          <div className="createList-closeBtnContainer">
                            <Button
                              icon={<i className="fi fi-sr-cross-small" />}
                              onClick={handleCloseRoom}
                            ></Button>
                          </div>
                        </div>
                        <>
                          <div className="createList-pageContent includeBottomPadding customScrollBar">
                            <div className="previewContainer  wc-add-paddin">
                              <form action="" className="Form-content">
                                <div className="wform-MyContentControl-module__edgeworth-stack-item-container-refresh__3mUvv wform-css-283 ">
                                  <div className="ui-divider  ua-gi-ub-uc">
                                    <div className="wc-form-wrapper">
                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                            Room Name
                                          </div>
                                          <div className="firstName-container">
                                          <Input
                                              placeholder="Room name"
                                              name="roomName"
                                              value={registerNewRoom.roomName || ""}
                                              appearance="filled-darker"
                                              id={filledDarkerId}
                                              onChange={
                                                handleRoomInputChange
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                            Room Number
                                          </div>
                                          <div className="firstName-container">
                                          <Input
                                              placeholder="Room Number"
                                              name="roomNumber"
                                              value={registerNewRoom.roomNumber?.toString() || ""}
                                              appearance="filled-darker"
                                              id={filledDarkerId}
                                              onChange={
                                                handleRoomInputChange
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                          Accessibility Features
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              placeholder="Accessibility Features"
                                              name="roomAccessibilityFeatures"
                                              value={registerNewRoom.roomAccessibilityFeatures || ""}
                                              appearance="filled-darker"
                                              id={filledDarkerId}
                                              onChange={
                                                handleRoomInputChange
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                          Restrictions
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              appearance="filled-darker"
                                              name="restrictions"
                                              value={registerNewRoom.restrictions || ""}
                                              placeholder="Restrictions"
                                              onChange={
                                                handleRoomInputChange
                                              }
                                              id={filledDarkerId}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                          Room Features
                                          </div>
                                          <div className="firstName-container">
                                          <Input
                                              appearance="filled-darker"
                                              name="roomFeatures"
                                              value={registerNewRoom.roomFeatures || ""}
                                              placeholder=" Room Features"
                                              onChange={
                                                handleRoomInputChange
                                              }
                                              id={filledDarkerId}
                                            />
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                            Available Date
                                          </div>
                                          <div className="firstName-container">
                                            <Field>
                                              <DatePicker
                                                name="availableDate"
                                                showWeekNumbers={true}
                                                firstWeekOfYear={1}
                                                showMonthPickerAsOverlay={true}
                                                value={registerNewRoom.availableDate ? new Date(registerNewRoom.availableDate) : undefined}
                                                placeholder="Available Date..."
                                                className={styles.control}
                                                onSelectDate={handleEmplyeeDateInputChange(
                                                  'availableDate'
                                                )}
                                              />
                                            </Field>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                            Room Rate:
                                          </div>
                                          <div className="firstName-container">
                                          <Input
                                            placeholder="Rate Of Room Per Night"
                                            name='rate'
                                            onChange={ handleRoomInputChange}
                                            value={registerNewRoom?.rate?registerNewRoom?.rate.toLocaleString(): ""}
                                            contentBefore={
                                            <Text size={400} id="beforeLabelId">
                                                {currencySymbol}
                                            </Text>
                                            }
                                            contentAfter={
                                            <Text size={400} id="wafterLabelId">
                                                .00
                                            </Text>
                                            }
                                            appearance="filled-darker"
                                            id="filledDarkerIdk"
                                        />
                                       </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                          call Requests 
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              appearance="filled-darker"
                                              name="callRequests"
                                              value={registerNewRoom.callRequests || ""}
                                              placeholder="Call Requests"
                                              id={filledDarkerId}
                                              onChange={
                                                handleRoomInputChange
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                             Room Description
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              appearance="filled-darker"
                                              name="description"
                                              value={registerNewRoom.description || ""}
                                              placeholder="Description Of Room"
                                              onChange={
                                                handleRoomInputChange
                                              }
                                              id={filledDarkerId}
                                            />
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                          Connecting Room Information
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              placeholder="Connecting Room Information"
                                              name="connectingRoomInformation"
                                              value={registerNewRoom.connectingRoomInformation || ""}
                                              appearance="filled-darker"
                                              onChange={
                                                handleRoomInputChange
                                              }
                                              id={filledDarkerId}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                          Do Not Disturb Status
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              placeholder="Do Not Disturb Status"
                                              name="doNotDisturbStatus"
                                              value={registerNewRoom.doNotDisturbStatus || ""}
                                              appearance="filled-darker"
                                              onChange={
                                                handleRoomInputChange
                                              }
                                              id={filledDarkerId}
                                            />
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                          Housekeeping Requests
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              appearance="filled-darker"
                                              name="housekeepingRequests"
                                              value={registerNewRoom.housekeepingRequests || ""}
                                              onChange={
                                                handleRoomInputChange
                                              }
                                              placeholder="Housekeeping Requests"
                                              id={filledDarkerId}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                          Laundry Status
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              placeholder="Laundry Status"
                                              name="laundryStatus"
                                              appearance="filled-darker"
                                              value={registerNewRoom.laundryStatus || ""}
                                              id={filledDarkerId}
                                              onChange={
                                                handleRoomInputChange
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                          Events Attractions
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              appearance="filled-darker"
                                              name="localEventsAttractions"
                                              type="text"
                                              value={registerNewRoom.localEventsAttractions || ""}
                                              placeholder="Events Attractions"
                                              id={filledDarkerId}
                                              onChange={
                                                handleRoomInputChange
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                         Check in Time
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              placeholder="check in Time"
                                              name="checkedInTime"
                                              appearance="filled-darker"
                                              value={registerNewRoom.checkedInTime || ""}
                                              id={filledDarkerId}
                                              onChange={
                                                handleRoomInputChange
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                         Check out Time
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              appearance="filled-darker"
                                              name="checkedOutTime"
                                              type="text"
                                              value={registerNewRoom.checkedOutTime || ""}
                                              placeholder="Checking Out Time"
                                              id={filledDarkerId}
                                              onChange={
                                                handleRoomInputChange
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                          noise Level
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              placeholder="noise Level"
                                              name="noiseLevel"
                                              value={registerNewRoom.noiseLevel || ""}
                                              appearance="filled-darker"
                                              id={filledDarkerId}
                                              onChange={
                                                handleRoomInputChange
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                          Accessibility Features
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              placeholder="Accessibility Features"
                                              name="roomAccessibilityFeatures"
                                              value={registerNewRoom.roomAccessibilityFeatures || ""}
                                              appearance="filled-darker"
                                              onChange={
                                                handleRoomInputChange
                                              }
                                              id={filledDarkerId}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                          Availability Calendar
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              placeholder="Availability Calendar"
                                              name="roomAvailabilityCalendar"
                                              value={registerNewRoom.roomAvailabilityCalendar || ""}
                                              appearance="filled-darker"
                                              id={filledDarkerId}
                                              onChange={
                                                handleRoomInputChange
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                          Cleaning Schedule
                                          </div>
                                          <div className="firstName-container">
                                          <Field>
                                              <DatePicker
                                                name="roomCleaningSchedule"
                                                showWeekNumbers={true}
                                                firstWeekOfYear={1}
                                                showMonthPickerAsOverlay={true}
                                                value={registerNewRoom.roomCleaningSchedule ? new Date(registerNewRoom.roomCleaningSchedule) : undefined}
                                                placeholder="Available Date..."
                                                className={styles.control}
                                                onSelectDate={handleEmplyeeDateInputChange(
                                                  'roomCleaningSchedule'
                                                )}
                                              />
                                            </Field>
                                          </div>
                                        </div>
                                      </div>
                                     

                                    </div>
                                  </div>
                                </div>
                                <div className="wform-MyContentControl-module__edgeworth-stack-item-container-refresh__3mUvv wform-css-283-right">
                                  <Accordion defaultOpenItems="basicInfo">
                                    <AccordionItem value="basicInfo">
                                      <AccordionHeader as="h2">
                                        More information
                                      </AccordionHeader>
                                      <AccordionPanel>
                                        <Label className="wc-pmargin-b">
                                          Mode of payment
                                        </Label>
                                        <AccordionPanel>
                                          <div
                                            role="group"
                                            aria-labelledby="hobbiesLabel"
                                          >
                                            <Checkbox
                                              label="Weekly /Hourly Paycheck"
                                              name="Weekly /Hourly Paycheck"
                                              onChange={handleCheckboxChange}
                                            />
                                            <Checkbox
                                              label="Direct Credit"
                                              name="Direct Credit"
                                              onChange={handleCheckboxChange}
                                            />
                                            <Checkbox
                                              label="Invoice"
                                              name="Invoice"
                                              onChange={handleCheckboxChange}
                                            />
                                          </div>
                                        </AccordionPanel>
                                        <div className="w-add-Guest-room Guest-management-job">
                                          <div className="w-add-room-guest rms-block-right">
                                            <div className="w-add-room-guest rms-inline-property-title">
                                            Room In Safe Status
                                            </div>
                                            <div className="firstName-container">
                                            <Input
                                              placeholder=" Room In Safe Status"
                                              name="roomInRoomSafeStatus"
                                              value={registerNewRoom.roomInRoomSafeStatus || ""}
                                              appearance="filled-darker"
                                              id={filledDarkerId}
                                              onChange={
                                                handleRoomInputChange
                                              }
                                            />
                                            </div>
                                          </div>

                                          <div className="w-add-room-guest rms-block-right">
                                            <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                            Room Maintenance
                                            </div>
                                            <div className="firstName-container wc-margin">
                                              <Input
                                                appearance="filled-darker"
                                                placeholder="Room Maintenance"
                                                name="roomMaintenance"
                                                value={registerNewRoom.roomMaintenance || ""}
                                                onChange={
                                                  handleRoomInputChange
                                                }
                                                id={filledDarkerId}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-inline-property-title wc-margin flex-width-content">
                                         <span> Currency Used</span>
                                        </div>
                                        <div className="Flex--inline">
                                        <RadioGroup
                                          defaultValue="NGN"
                                          name="Currency"
                                          onChange={handleEmployeeRadioChange}
                                          aria-labelledby="ageLabel"
                                          className="wc-flex"
                                        >

                                          <Radio value="NGN" label="NGN" />
                                          <Radio value="USD" label="USD" />
                                        </RadioGroup>
                                        <RadioGroup
                                          defaultValue="Single Bed"
                                          name="bedType"
                                          onChange={handleRelationshipRadioChange}
                                          aria-labelledby="ageLabel"
                                          className="wc-flex"
                                        >
                                          <Radio value="Single Bed" label="Single Bed" />
                                          <Radio value="Double Bed" label="Double Bed" />
                                        </RadioGroup>
                                        </div>
                                      
                                        <div className="w-add-Guest-room Guest-management-job">
                                          <div className="w-add-room-guest rms-block-left">
                                            <div className="w-add-room-guest Guest-inline-property-title">
                                            Maintenance History
                                            </div>
                                            <div className="firstName-container">
                                              <Input
                                                name="roomMaintenanceHistory"
                                                placeholder="Maintenance History"
                                                value={registerNewRoom.roomMaintenanceHistory || ""}
                                                type="text"
                                                appearance="filled-darker"
                                                onChange={
                                                  handleRoomInputChange
                                                }
                                                id={filledDarkerId}
                                              />
                                            </div>
                                          </div>

                                          <div className="w-add-room-guest rms-block-right wc-margin">
                                            <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                            Room Location
                                            </div>
                                            <div className="firstName-container">
                                              <Input
                                                placeholder="Room Location"
                                                name="roomLocation"
                                                value={registerNewRoom.roomLocation || ""}
                                                appearance="filled-darker"
                                                onChange={
                                                  handleRoomInputChange
                                                }
                                                id={filledDarkerId}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="w-add-Guest-room Guest-management-job">
                                          <div className="w-add-room-guest rms-block-left">
                                            <div className="w-add-room-guest Guest-inline-property-title">
                                            Room Temperature
                                            </div>
                                            <div className="firstName-container">
                                              <Input
                                                name="roomTemperature"
                                                placeholder="Temperature"
                                                value={registerNewRoom.roomTemperature?.toLocaleString() || ""}
                                                type="number"
                                                appearance="filled-darker"
                                                onChange={
                                                  handleRoomInputChange
                                                }
                                                id={filledDarkerId}
                                              />
                                            </div>
                                          </div>

                                          <div className="w-add-room-guest rms-block-right wc-margin">
                                            <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                            Wake Up Call Requests
                                            </div>
                                            <div className="firstName-container">
                                              <Input
                                                placeholder="Wake Up Call Requests"
                                                name="wakeUpCallRequests"
                                                value={registerNewRoom.wakeUpCallRequests?.toLocaleString() || ""}
                                                appearance="filled-darker"
                                                onChange={
                                                  handleRoomInputChange
                                                }
                                                id={filledDarkerId}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="w-add-Guest-room Guest-management-job">
                                          <div className="w-add-room-guest rms-block-left">
                                            <div className="w-add-room-guest Guest-inline-property-title">
                                            Status
                                            </div>
                                            <div className="firstName-container">
                                              <Input
                                                placeholder="Room status"
                                                name="status"
                                                appearance="filled-darker"
                                                value={registerNewRoom.status?.toLocaleString() || ""}
                                                type="text"
                                                onChange={
                                                  handleRoomInputChange
                                                }
                                                id={filledDarkerId}
                                              />
                                            </div>
                                          </div>

                                          <div className="w-add-room-guest rms-block-right wc-margin">
                                            <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                            Room Temperature Control
                                            </div>
                                            <div className="firstName-container">
                                              <Input
                                                placeholder="Room Temperature Control"
                                                name="roomTemperatureControl"
                                                value={registerNewRoom.roomTemperatureControl?.toLocaleString() || ""}
                                                onChange={
                                                  handleRoomInputChange
                                                }                                             
                                                appearance="filled-darker"
                                                id={filledDarkerId}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="w-add-Guest-room Guest-management-job">
                                          <div className="w-add-room-guest rms-block-left">
                                            <div className="w-add-room-guest Guest-inline-property-title">
                                            Room Packages
                                            </div>
                                            <div className="firstName-container">
                                              <Input
                                                name="roomPackages"
                                                placeholder="Room Packages"
                                                value={registerNewRoom.roomPackages?.toLocaleString() || ""}
                                                appearance="filled-darker"
                                                onChange={
                                                  handleRoomInputChange
                                                }
                                                id={filledDarkerId}
                                              />
                                            </div>
                                          </div>

                                          <div className="w-add-room-guest rms-block-right wc-margin">
                                            <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                            Room Type
                                            </div>
                                            <div className="firstName-container">
                                              <Input
                                                placeholder="Room Type"
                                                name="roomType"
                                                value={registerNewRoom.roomType?.toLocaleString() || ""}
                                                appearance="filled-darker"
                                                onChange={
                                                  handleRoomInputChange
                                                }
                                                id={filledDarkerId}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                          Room Service Orders
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              placeholder="Room Service Orders"
                                              name="roomServiceOrders"
                                              value={registerNewRoom.roomServiceOrders || ""}
                                              appearance="filled-darker"
                                              onChange={
                                                handleRoomInputChange
                                              }
                                              id={filledDarkerId}
                                            />
                                            {/* <Field>
                                              <DatePicker
                                                showWeekNumbers={true}
                                                firstWeekOfYear={1}
                                                showMonthPickerAsOverlay={true}
                                                placeholder="Issues Date..."
                                                className={styles.control}
                                              />
                                            </Field> */}
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                          Minibar Inventory
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              placeholder="Minibar Inventory"
                                              name="minibarInventory"
                                              appearance="filled-darker"
                                              value={registerNewRoom.minibarInventory || ""}
                                              onChange={
                                                handleRoomInputChange
                                              }
                                              id={filledDarkerId}
                                            />
                                            {/* <Field>
                                              <DatePicker
                                                showWeekNumbers={true}
                                                firstWeekOfYear={1}
                                                showMonthPickerAsOverlay={true}
                                                placeholder="Expire Date..."
                                                className={styles.control}
                                              />
                                            </Field> */}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-right margin-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                          Room Inventory
                                          </div>
                                          <div className="firstName-container Max-width-Employee">
                                            <span className="fui-Input r1jtohuq ___8dx9ou0_np9bry0 f16xq7d1 ftmjh5b f17blpuu f1tpwn32 fsrcdbj fghlq4f f1gn591s fb073pr fjscplz">
                                            <Input
                                              placeholder="Room Inventory"
                                              name="roomInventory"
                                              appearance="filled-darker"
                                              value={registerNewRoom.roomInventory || ""}
                                              onChange={
                                                handleRoomInputChange
                                              }
                                              id={filledDarkerId}
                                            />

                                            </span>
                                          </div>
                                        </div>

                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                            Upload Room Photo
                                          </div>
                                          <div className="firstName-container Max-width-Employee">
                                            <span className="fui-Input r1jtohuq ___8dx9ou0_np9bry0 f16xq7d1 ftmjh5b f17blpuu f1tpwn32 fsrcdbj fghlq4f f1gn591s fb073pr fjscplz">
                                              <input
                                                placeholder="Upload Passport photograph"
                                                id={filledDarkerId}
                                                className="fui-Input__input rvp2gzh"
                                                type="file"
                                                name="roomPhotos"
                                                onChange={
                                                  handleRoomInputChange
                                                }
                                                accept="image/*"
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                        <Label id="ageLabel">
                                          Availability Infomation:
                                        </Label>
                                        <RadioGroup
                                          aria-labelledby="availability"
                                          className="wc-flex"
                                          onChange={handleInputRadioChange}
                                        >
                                          <Radio
                                            value="availability"
                                            label="Is Available"
                                           
                                          />
                                        </RadioGroup>
                                      </AccordionPanel>
                                    </AccordionItem>
                                   
                                  </Accordion>
                                </div>
                              </form>
                            </div>
                          </div>
                        </>
                      </div>
                    </div>
                    <div className="createList-pageFooter">
                      <div className="createList-leftActions">
                        <Button
                          data-automationid="createListGoBackButton"
                          className=""
                          icon={<ChevronLeft24Regular />}
                          onClick={handleCloseRoom}
                        >
                         
                          Back
                        </Button>
                      </div>
                      <div className="createList-rightActions">
                        <Button
                          className="wc-first-root-68"
                          onClick={handleSubmitEmployeeClick}
                        >
                          Register
                        </Button>
                        <Button className="" onClick={handleCloseRoom}>
                         
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoomRegisterationForm;
