import React, { useCallback, useEffect, useState } from "react";
import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Button,
    Label,
    useId,
    Input,
    makeStyles,
    Divider,
    Field,
    Combobox,
    Option,
    Text,
    Persona,
    Dropdown,
  } from '@fluentui/react-components';
import "./BarCategoryForm.scss";
import { IDynamic } from "../../../../Data/General/IDynamic";
import { IBarLoungeMenu, InitialIBar } from "../../../../Data/General/BarMenuLounge";
import Loaders from "../../../Common/Loader/Loader";
import DispatchToast from "../../../Common/Toast/DispatchToast";
import { BarLoungeCategory, IBarCategory, InitialIBarCat } from "../../../../Data/General/IBarCategory";
import axios from "axios";
import { WHITE_HOUSE_API } from "../../../../Constants/Constant";


type Adjuster = {
    firstname?:string;
    lastname?:string;
    phoneNumber?:string;
    userid?: number;
  }

  interface User {
    email: string,
    phoneNumber:string,
    token:string,
  }
  interface Message {
    message: string,
    id:number,
  }
    // other properties...
  interface BarCatsProps {
    onCatClose: () => void;
    message: Message;
    user: User;
    adjuster:Adjuster;
  }
  const useStyles = makeStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '400px',
    },
    control: {
      maxWidth: '300px',
    },
  });
  
  

const BarCategoryForm:React.FC<BarCatsProps> = ({ onCatClose, message, user,adjuster}) =>{
    const [dispatchToaster, setDispatchToaster] = useState<boolean>(false);
    const [selectedInvoicee, setSelectedInvoicee] = useState<number>();
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [results, setResults] = useState<IDynamic[]>([]);
    const [selectedService, setSelectedService] = useState<string | null>(null);
    const [apiData, setApiData] = useState<string[]>([]);
    const [createBarCat, setCreatedBarCat] = useState<IBarCategory>(InitialIBarCat)
    const [getExistingBarcategory, setgetExistingBarcategory] = useState<BarLoungeCategory>();
    const [input, setInput] = useState('');
    const inputId = useId('input');
    const styles = useStyles();
    const [currency, setCurrency] = useState('NGN');
    const currencySymbol = currency === 'NGN' ? '₦' : '$';



    const handleBarInputChange = (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        const { name, value } = event.target;
        setCreatedBarCat((prevState) => ({ ...prevState, [name]: value }));
      };

      const HandleSubmitData = async () => {
       try {
          setIsLoader(true);
          const response = await axios.post(
            `${WHITE_HOUSE_API}/BarLoungeCategory`,
            createBarCat,
            {
              headers: {
                'Authorization': `Bearer ${user.token}`
              }
            }
          );
          if (response.data) {
            setTimeout(() => {
              setIsLoader(false);
            }, 1200);
      
            if (response.data.status === 'Success') {
              setResponseMessage(response.data.eMessage);
              setDispatchToaster(true);
              setTimeout(() => {
                setDispatchToaster(false);
              }, 1500);
            }
      
            setCreatedBarCat(createBarCat);
            let fullname = adjuster.firstname  + " " + adjuster.lastname;
            setCreatedBarCat((prevState) => ({ ...prevState, updatedby: fullname}));
          }
        } catch (error) {
          setResponseMessage('Registration ' + error);
          console.error('Error fetching roles:', error);
        }
      };

      const fetchExistingCategory = useCallback(async () => {
        try {
          const response = await axios.get(`${WHITE_HOUSE_API}/BarLoungeCategory/${message.id}`, {
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
          });
          setCreatedBarCat(response.data);
        } catch (error) {
          console.error(error);
        }
      }, [message, user.token]);
      
      useEffect(() => {
        console.log("Message Id", message.id);
        if(message.id > 0)
        {
          fetchExistingCategory();
        }
      }, [fetchExistingCategory, message.id]); //
     
    return (
        <>
          <div className="fui-FluentProvider fui-FluentProvider361 ___1bsm0mz f19n0e5 fxugw4r f1o700av fk6fouc fkhj508 figsok6 f1i3iumi f1euv43f f15twtuk f1vgc2s3 f1e31b4d f494woh">
      <div
        aria-hidden="true"
        className="fui-DialogSurface__backdrop ___adeqq90 f19dog8a fju19wo f113wtx2 f10k790i f5gq2j6 f1xynx9j"
      ></div>

      <div className="fui-DialogSurface ___1g5txne ftgm304 f1c515w f1bitti f15twtuk f1e31b4d f1yab3r1 f1vgc2s3 fuq56rw f15kemlc fl2zwns fdgang7 fgr6219 f1ujusj6 f10jk5vf fcgxt0o fzkkow9 fcdblym fg706s2 fjik90z f1ln0qer fa2wlxz f120kxnn f19dog8a f3052tw fvgz9i8 f6a9g1z f1ewtqcl f10nrhrw fxugw4r f19n0e5 f5ogflp f1hqa2wf f1f09k3d finvdd3 fghlq4f f1gn591s fb073pr fjscplz fnivh3a fc7yr5o f1el4m67 f8yange ff54dml ftqa4ok f2hkw1w f8hki3x f1d2448m f1bjia2o ffh67wi f15bsgw9 f14e48fq f18yb2kv fd6o370 fh1cnn4 fy7oxxb fpukqih f184ne2d frrh606 f1v5zibi ful5kiu fo2hd23 f1jqcqds ftffrms f2e7qr6 fsr1zz6 f1dvezut fd0oaoj fjvm52t f1cwg4i8 f57olzd f4stah7 f480a47 fs1por5">
        <div className="fui-DialogBody ___6dxt060 f13qh94s f120kxnn flnwrvu f1ewtqcl f1ln0qer fa2wlxz f4px1ci fn67r4l f1x46k84 fjj47a5 ff54dml f1t97isy">
          {isLoader && (
            <div className="restrict-the-inner">
              <Loaders />
            </div>
          )}
          {dispatchToaster && <DispatchToast message={responseMessage} />}
          <div className="header-content">
            <h2
              id="dialog-title-363"
              className="fui-DialogTitle ___wfyv5z0 f16hsg94 f1amvztq fwpfdsa fsyjsko fk6fouc f1pp30po fl43uef f106mvju f1hu3pq6 f11qmguv f19f4twv f1tyq0we"
            >
              Create Bar Category
            </h2>
          </div>

          <div className="fui-DialogContent ___1dce55l f5zp4f f1nxs5xn f1ewtqcl faunodf f88nxoq fwpfdsa fsyjsko fr4fn7s f171n1a0 f1ean75l f1jwbs5a fxlnl9e ffs23cn f2uu7rc f1v5tmjd fk6fouc fkhj508 figsok6 f1i3iumi">
            <h3 className="user-initiating">{adjuster.lastname} {adjuster.firstname}</h3>
            <div className="First-form-flex-inoice">
              <div className={styles.root}>
                <Label htmlFor={inputId}>Category Name</Label>
                <Input
                  id={inputId}
                  value={createBarCat.categoryName || ""}
                  name="categoryName"
                  placeholder="category name"
                  onChange={handleBarInputChange}
                />
              </div>
              <div className={styles.root}>
                    <Label htmlFor={inputId}>Discriptions</Label>
                    <Input
                  id={inputId}
                  value={createBarCat.description || ""}
                  name="description"
                  placeholder="Enter  discription"
                  onChange={handleBarInputChange}
                />
                </div>
            </div>
          </div>
          <div className="fui-DialogActions ___1mdwomm f3052tw f1ewtqcl f22iagw f1cztu7b fcu02xx f4px1ci fn67r4l f6glcwc f1e3st1r f1a7i8kp fd46tj4 fsyjsko f1f41i0t f1jaqex3 f2ao6jk">
            <Button className="Button-Submit" onClick={HandleSubmitData}>
              Add Service
            </Button>
            <Button className="Button-close" onClick={onCatClose}>
              cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
</>
    )
}

export default BarCategoryForm;