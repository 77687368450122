import { SearchBox } from '@fluentui/react-search-preview';
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Avatar,
  AvatarGroup,
  AvatarGroupItem,
  Checkbox,
  Field,
  Label,
  Radio,
  RadioGroup,
  Select,
  useToastController,
} from '@fluentui/react-components';
import axios, { AxiosError } from 'axios';
import { Persona, PersonaSize, initializeIcons } from '@fluentui/react';
import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Input } from '@fluentui/react-components';
import {
  ChevronLeft24Regular,
  PersonAdd24Regular,
  PeopleRegular,
  WeatherRainShowersNight24Regular,
} from '@fluentui/react-icons';

import {
  Dropdown,
  makeStyles,
  Option,
  shorthands,
  useId,
  Caption1,
  tokens,
  Text,
  Link,
  Button,
  Toaster,
  Toast,
  ToastTitle,
  ToastBody,
  ToastFooter,
} from '@fluentui/react-components';
import type { DropdownProps, SelectOnChangeData } from '@fluentui/react-components';
import {
  IDropdownOption,
  DetailsList,
  IColumn,
  CheckboxVisibility,
} from '@fluentui/react';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import './RegisterEmployee.scss';
import { WHITE_HOUSE_API } from '../../../../../Constants/Constant';
import {
  Employee,
  GetEmployee,
  InitialIEmployee,
} from '../../../../../Data/General/Employee';
import { IBusinessTypes } from '../../../../../Data/General/BusinessType';
import Loaders from '../../../../Common/Loader/Loader';
import { IRoles } from '../../../../../Data/General/Roles';
import Roles from '../../../RolesManagement/Roles';
import { DateTime } from 'luxon';
import DispatchToast from '../../../../Common/Toast/DispatchToast';





interface Titles {
  titleId: number;
  titleName: string;
}

interface Message {
  message: string,
  id:number,
}

type Adjuster = {
  firstname?:string;
  lastname?:string;
  phoneNumber?:string;
  userid?: number;
}
interface RoomResponse {
    message: string;
  }
interface Genders {
  genderId: number;
  genderName: string;
}
const useStyles = makeStyles({
  main: {
    ...shorthands.gap('16px'),
    display: 'flex',
    flexWrap: 'wrap',
  },

  card: {
    width: '400px',
    maxWidth: '100%',
    minHeight: '250px',
    height: 'fit-content',
  },

  caption: {
    color: tokens.colorNeutralForeground3,
  },

  smallRadius: {
    ...shorthands.borderRadius(tokens.borderRadiusSmall),
  },

  grayBackground: {
    backgroundColor: tokens.colorNeutralBackground3,
  },

  control: {
    maxWidth: '300px',
  },
  wrapper: {
    columnGap: '15px',
    display: 'flex',
    marginBottom: '10px',
  },
});
interface User {
  email: string,
  phoneNumber:string,
  token:string,

  // other properties...
}
type RegisterEmployeeProps = {
  close: () => void;
  user: User; 
  adjuster:Adjuster;
  message:Message;
};

const RegisterEmployee: React.FC<RegisterEmployeeProps> = ({ close,adjuster, message ,user }) => {
  const [showForm, setShowForm] = useState(false);
  const [showDetails, setshowDetails] = useState(true);
  const ActionText = showForm ? 'Submit' : 'Book Now';
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<GetEmployee[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [showList, setShowList] = useState(false);
  const [hideAll, setHideAll] = useState(true);
  const [titleMapping, setTitleMapping] = useState<Titles[]>([]);
  const [filteredOptions, setFilteredOptions] = useState(titleMapping);
  const outlineId = useId('input-outline');
  const underlineId = useId('input-underline');
  const filledLighterId = useId('input-filledLighter');
  const filledDarkerId = useId('input-filledDarker');
  const [isSearchActive, setisSearchActive] = useState(false);
  const [registerEmployee, setRegisterEmployee] = useState<GetEmployee>(InitialIEmployee);
  const [genders, setGenderMapping] = useState<Genders[]>([]);
  const [positionMapping, setpositionMapping] = useState<IRoles[]>([]);
  const [isRoleOpen, setIsRoleOpen] = useState<boolean>(false);
  const toasterId = useId('toaster');
  const { dispatchToast } = useToastController(toasterId);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setsuccessMessage] = useState('');
  const [saveLaoding, setSaveLaoding] = useState<boolean>(false);
  const [dispatchToaster, setDispatchToaster] = useState<boolean>(false);
    const [isLoader, setIsLoader] = useState<boolean>(false);
  const styles = useStyles();
  initializeIcons();
  const beforeLabelId = useId('before-label');
  const afterLabelId = useId('after-label');
  const [currency, setCurrency] = useState('NGN');
  const currencySymbol = currency === 'NGN' ? '₦' : '$';

  const handleCurrencyChange = (event: any) => {
    const option = event.target.value;
    setCurrency(option);
  };

  const handleCloseEmployee = () => {
    close();
  };

  const items = [
    { key: '1', name: 'Name', value: 'Flat1 Deluxy' },
    { key: '2', name: 'Floor', value: 2 },
    { key: '3', name: 'Area', value: 28 },
    { key: '4', name: 'Capacity', value: '2 + 1, Max. 3' },
    { key: '5', name: 'Cleaning Required', value: 'Yes' },
    { key: '6', name: 'Smoking Allowed', value: 'No' },
  ];

  const columns: IColumn[] = [
    {
      key: 'column1',
      name: 'Name',
      fieldName: 'name',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column2',
      name: 'Value',
      fieldName: 'value',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  const handleBrowseAllClick = () => {
    setShowList((prevShowList) => !prevShowList);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(`Searching for: ${searchTerm}`);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  const fetchTitles = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/Title`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
      const Titles = response.data;
      setTitleMapping(Titles);
      setFilteredOptions(Titles);
      console.log('Title items:', Titles);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  }, [user.token]);
  

  const fetchgenders = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/Gender`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
      const genders = response.data;
      setGenderMapping(genders);
      console.log('Business items:', genders);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  }, [user.token]);
  
  const fetchRoles = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/Role`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
      const roles = response.data;
      setpositionMapping(response.data);
      console.log('Role items:', roles);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  }, [user.token]);

  const fetchExistingEmployee = useCallback(async (sid:any) => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/Employee/Id/${sid}`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
      setRegisterEmployee(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);
  
  
  

  useEffect(() => {
    if(message.id > 0)
    {
      fetchExistingEmployee(message.id);
    }
    fetchTitles();
    fetchgenders();
    fetchRoles();
  }, [fetchTitles, currency, currencySymbol, fetchgenders, fetchRoles, message.id, fetchExistingEmployee]);

  const handleInputChange = (event: any) => {
    const que = event.target.value;
    setisSearchActive(event.target.value !== '');
    setQuery(que);
    if (que.length >= 1) {
      search(que);
    }
  };
  const toggleForm = () => {
    setShowForm((c) => !c);
    setshowDetails((c) => !c);
  };


const handleEmployeeInputChange = (
  event: React.ChangeEvent<HTMLInputElement>
) => {
  const { name, value, files } = event.target;
  if (files) {
    console.log("The file exist here: ", files);
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        setRegisterEmployee((prevState) => ({ ...prevState, [name]: base64String }));
      };
      reader.readAsDataURL(files[0]);
  } else {
    setRegisterEmployee((prevState) => ({ ...prevState, [name]: value }));
  }
};





  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    if (checked) {
      setRegisterEmployee((prevState) => ({ ...prevState, PaymentTerms: name }));
    } else if (registerEmployee.paymentTerms === name) {
      setRegisterEmployee((prevState) => ({
        ...prevState,
        PaymentTerms: name,
      }));
    }
  };

  const HandleMultipleRadio = (
    event: React.FormEvent<HTMLDivElement>,
    data: any
  ) => {
    const { name, value } = data;
    setRegisterEmployee((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleEmployeeRadioChange = (
    event: React.FormEvent<HTMLDivElement>,
    data: any
  ) => {
    const { value } = data;
    setRegisterEmployee((prevState) => ({ ...prevState, Currency: value }));
    setCurrency(value);
  };
   
  const handleRelationshipRadioChange = (
    event: React.FormEvent<HTMLDivElement>,
    data: any
  ) => {
    const { value } = data;
    setRegisterEmployee((prevState) => ({ ...prevState, MaritalStatus: value }));
  };


  const handleInputRadioChange = (event: React.FormEvent<HTMLDivElement>,data: any) => {
      const { value } = data;
      const name = value;
      console.log("The selected value: ", name);
      if (value === 'isManager' || value === 'isAdmin') {
        setRegisterEmployee((prevState) => ({ ...prevState, [name]: true }));
      } else {
        setRegisterEmployee((prevState) => ({ ...prevState, [name]: false }));
      }
  };
  
  
  const handleEmplyeeDateInputChange =
    (fieldName: string) => (date: Date | null | undefined) => {
      if (date) {
        console.log(`The Date onSelectDate for ${fieldName}`, date);
        setRegisterEmployee((prevState) => ({
          ...prevState,
          [fieldName]: date,
        }));
      }
    };


    const search = async (query: any) => {
      console.log("Bearer: ", user.token);
      try {
        const response = await axios.get(
          `${WHITE_HOUSE_API}/Employee/Search?query=${query}`,
          {
            headers: {
              'Authorization': `Bearer ${user.token}`
            },
            params: { query },
          }
        );
        console.log("Response data: ", results);
        setResults(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    

const handleRolesPosition = () => {
    setIsRoleOpen(true);
}

const HandleRoleClose = () => {
    setIsRoleOpen(false);
}


const handleSubmitEmployeeClick = async () => {
  setIsLoader(true);
    try { 
      const response = await axios.post(
        `${WHITE_HOUSE_API}/Employee/register`,
        registerEmployee,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.token}`
        },        
        }
      );
      if (response.data.status === "Success") {
        setsuccessMessage(response.data.eMessage);
        setDispatchToaster(true);
        setIsLoader(false);
        console.log("message", message);
        setTimeout(() => {
          setDispatchToaster(false);
        }, 1500);
      }
      
    } catch (error) {
      console.error(error);
      setIsLoader(false);
      setSaveLaoding(false);
      if (axios.isAxiosError(error)) {
       
          const axiosError = error as AxiosError<RoomResponse>;
          if (
            axiosError.response &&
            axiosError.response.data &&
            axiosError.response.data.message
          ) {
          setDispatchToaster(true);
          setIsLoader(false);
          setTimeout(() => {
            setDispatchToaster(false);
          }, 1500);
          }
       
      }
    }
  
};


const handleEmployeeSelectInputChange = (event: React.ChangeEvent<HTMLSelectElement>, data: SelectOnChangeData) => {
  const name = event.target.name;
  const value = data.value;
  console.log("the selected item name: ", name +"The value: " + value);
  setRegisterEmployee((prevState) => ({ ...prevState, [name]: value }));
};

const handleUserSelect = (uid:any) => {
  message.id = 0;
  fetchExistingEmployee(uid);
  setisSearchActive(false);
}

  return (
    <>
      <div className="ms-Fabric ms-Layer-content content-46">
        <div className="wc-no outline">
          <div className="ms-Modal is-open root-39">
            <div
              aria-hidden="true"
              className="ms-Overlay ms-Overlay--dark root-48"
            ></div>
            <div
              id="cilsco-focuszone"
              className="ms-Dialog-main createListModalMain_979b2e47 main-40"
            >
              <div
                aria-hidden="true"
                id="Ariel-fixed"
                data-is-visible="true"
                data-is-focus-trap-zone-bumper="true"
              ></div>
               {isRoleOpen &&
                (
                    <Roles onClose={HandleRoleClose} message={message} user={user} />
                )
            }
             {isLoader && (
            <div className="restrict-the-inner">
                <Loaders />

            </div>
            )}
          
              <div className="ms-Modal-scrollableContent scrollableContent-54 customScrollBar">
                <div className="Merging-34">
                  <div className="createList-root templatePreviewPage">
                    <div className="templatePreview">
                      <div className="createList-pageRoot templatePreviewContainer">
                        <div className="createList-header">
                        {dispatchToaster && (
                            <DispatchToast message={successMessage}/>
                        )}
                          <div className="templateTitleWithLogo">
                            <Avatar
                              icon={<PersonAdd24Regular />}
                              aria-label="Group"
                            />
                            <h1 className="createList-headerTitle wc-margin">
                              Employee Form
                            </h1>
                          </div>
                          {/* use this to update all my search */}
                          <div
                            className={`ui-box Wsearch-ev-ew c-search_modal__wrapper popover-little ${
                              isSearchActive ? 'mouse-over-active' : ''
                            }`}
                          >
                            <SearchBox
                              placeholder="Search and update existing employee .."
                              value={query}
                              onChange={handleInputChange}
                              onBlur={search}
                            
                              className="sear-padd"

                            />
                            <div
                              className={`c-search_autocomplete__container c-search_autocomplete__container--query-ordered ${
                                isSearchActive ? 'serachbar-active' : ''
                              }`}
                            >
                              <div className="c-search_autocomplete c-search_autocomplete--nav-attached">
                                <div className="offscreen">
                                  <span id="search-autocomplete-query-label-prefix-node">
                                    Search
                                  </span>
                                </div>
                                {results.map((g) => {
                                  let emailorphone = g.phoneNumber ?? g.email;
                                  let fullname = g.firstName + ' ' + g.lastName;
                                  return (
                                    <div
                                      key={g.employeeId}
                                      className="c-search_autocomplete__suggestion_list c-search_autocomplete__suggestion_list--taller"
                                      onClick={() => handleUserSelect(g.employeeId)}
                                    >
                                      <div className="curtain-a wform-css-283-right remove-bos-shadow">
                                        <div className="fui-Persona rlroi9i remove-bos-shadow">
                                          <span
                                            role="img"
                                            id="avatar-rd"
                                            className="fui-Avatar r81b29z fui-Persona__avatar ___1c989qy_1o9k6tz f13ar0e0 fid048z f1hek2iy f9h729m f18q9vkd f103ycu4 remove-bos-shadow"
                                            aria-label="Kevin Sturgis"
                                            aria-labelledby="avatar-rd avatar-rd__badge"
                                          >
                                            <AvatarGroup>
                                              <AvatarGroupItem
                                                name={fullname}
                                              />
                                            </AvatarGroup>
                                          </span>
                                          <span className="fui-Persona__primaryText rvj41k9">
                                            {fullname}
                                          </span>
                                          <span className="fui-Persona__secondaryText rp1pf9e ___19lp2a0_1p5a1yj f1ryq6si">
                                            {emailorphone}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          {/* use this to update all my search */}
                          <div className="createList-closeBtnContainer">
                            <Button
                              icon={<i className="fi fi-sr-cross-small" />}
                              onClick={handleCloseEmployee}
                            ></Button>
                          </div>
                        </div>
                        <>
                          <div className="createList-pageContent includeBottomPadding customScrollBar">
                            <div className="previewContainer  wc-add-paddin">
                              <form action="" className="Form-content">
                                <div className="wform-MyContentControl-module__edgeworth-stack-item-container-refresh__3mUvv wform-css-283 ">
                                  <div className="ui-divider  ua-gi-ub-uc">
                                    <div className="wc-form-wrapper">
                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                            Title
                                          </div>
                                          <div className="firstName-container">
                                            <Select
                                              appearance="filled-darker"
                                              name="title"
                                              id={filledDarkerId}
                                              onChange={
                                                handleEmployeeSelectInputChange
                                              }
                                            >
                                              <option value="">
                                                Select Title
                                              </option>
                                              {titleMapping.map((titles) => {
                                                return (
                                                  <option
                                                    key={titles.titleId}
                                                    value={titles.titleName}
                                                  >
                                                    {titles.titleName}
                                                  </option>
                                                );
                                              })}
                                            </Select>
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                            Gender
                                          </div>
                                          <div className="firstName-container">
                                            <Select
                                              appearance="filled-darker"
                                              name="gender"
                                              id={filledDarkerId}
                                              onChange={
                                                handleEmployeeSelectInputChange
                                              }
                                            >
                                              <option value="">
                                                Select Gender
                                              </option>
                                              {genders.map((genders) => {
                                                return (
                                                  <option
                                                    key={genders.genderId}
                                                    value={genders.genderName}
                                                  >
                                                    {genders.genderName}
                                                  </option>
                                                );
                                              })}
                                            </Select>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                            First Name
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              placeholder="Employee First Name"
                                              name="firstName"
                                              value={registerEmployee.firstName || ""}
                                              appearance="filled-darker"
                                              id={filledDarkerId}
                                              onChange={
                                                handleEmployeeInputChange
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                            Last Name
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              appearance="filled-darker"
                                              name="lastName"
                                              value={registerEmployee.lastName || ""}
                                              placeholder="Employee Last Name"
                                              onChange={
                                                handleEmployeeInputChange
                                              }
                                              id={filledDarkerId}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                            Date Of Birth
                                          </div>
                                          <div className="firstName-container">
                                          <Field>
                                              <DatePicker
                                                name="dateOfBirth"
                                                showWeekNumbers={true}
                                                firstWeekOfYear={1}
                                                showMonthPickerAsOverlay={true}
                                                value={registerEmployee.dateOfBirth ? new Date(registerEmployee.dateOfBirth) : undefined}
                                                placeholder="Date of Birth"
                                                className={styles.control}
                                                onSelectDate={handleEmplyeeDateInputChange(
                                                  'dateOfBirth'
                                                )}
                                              />
                                            </Field>
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                            Hire Date
                                          </div>
                                          <div className="firstName-container">
                                            <Field>
                                              <DatePicker
                                                name="hireDate"
                                                showWeekNumbers={true}
                                                firstWeekOfYear={1}
                                                showMonthPickerAsOverlay={true}
                                                value={registerEmployee.hireDate ? new Date(registerEmployee.hireDate) : undefined}
                                                placeholder="Hired Date..."
                                                className={styles.control}
                                                onSelectDate={handleEmplyeeDateInputChange(
                                                  'hireDate'
                                                )}
                                              />
                                            </Field>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                            Phone Number:
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              placeholder="Contact Phone"
                                              name="phoneNumber"
                                              appearance="filled-darker"
                                              value={registerEmployee.phoneNumber || ""}
                                              id={filledDarkerId}
                                              onChange={
                                                handleEmployeeInputChange
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                            Email
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              appearance="filled-darker"
                                              name="email"
                                              type="email"
                                              value={registerEmployee.email || ""}
                                              placeholder="E-Email Address"
                                              id={filledDarkerId}
                                              onChange={
                                                handleEmployeeInputChange
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                           First Contact  Phone
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              appearance="filled-darker"
                                              name="firstContactPhone1"
                                              value={registerEmployee.firstContactPhone1 || ""}
                                              placeholder="First Contact Person"
                                              onChange={
                                                handleEmployeeInputChange
                                              }
                                              id={filledDarkerId}
                                            />
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                            First Contact Person Email
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              placeholder="First Contact Person Email"
                                              name="firstContactEmail1"
                                              value={registerEmployee.firstContactEmail1 || ""}
                                              appearance="filled-darker"
                                              onChange={
                                                handleEmployeeInputChange
                                              }
                                              id={filledDarkerId}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                            Address
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              placeholder="Home Address"
                                              name="address"
                                              value={registerEmployee.address || ""}
                                              appearance="filled-darker"
                                              onChange={
                                                handleEmployeeInputChange
                                              }
                                              id={filledDarkerId}
                                            />
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                            First Contact Person Name
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              appearance="filled-darker"
                                              name="firstContactFullName1"
                                              value={registerEmployee.firstContactFullName1 || ""}
                                              onChange={
                                                handleEmployeeInputChange
                                              }
                                              placeholder="Enter First Contact Full Name"
                                              id={filledDarkerId}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                            SSN OR NIN
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              placeholder="NIN OR SSN"
                                              name="socialSecurityNumber"
                                              appearance="filled-darker"
                                              value={registerEmployee.socialSecurityNumber || ""}
                                              id={filledDarkerId}
                                              onChange={
                                                handleEmployeeInputChange
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                            Country
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              appearance="filled-darker"
                                              name="country"
                                              type="text"
                                              value={registerEmployee.country || ""}
                                              placeholder="Country"
                                              id={filledDarkerId}
                                              onChange={
                                                handleEmployeeInputChange
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                           State
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              placeholder="State"
                                              name="state"
                                              value={registerEmployee.state || ""}
                                              appearance="filled-darker"
                                              id={filledDarkerId}
                                              onChange={
                                                handleEmployeeInputChange
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                          City
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              placeholder="City"
                                              name="city"
                                              value={registerEmployee.city || ""}
                                              appearance="filled-darker"
                                              onChange={
                                                handleEmployeeInputChange
                                              }
                                              id={filledDarkerId}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                            Bank Account Number:
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              placeholder="Bank Account Number"
                                              name="bankAccountNumber"
                                              value={registerEmployee.bankAccountNumber || ""}
                                              appearance="filled-darker"
                                              id={filledDarkerId}
                                              onChange={
                                                handleEmployeeInputChange
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                            Bank Name
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              placeholder="Bank Name"
                                              name="bankName"
                                              value={registerEmployee.bankName || ""}
                                              appearance="filled-darker"
                                              onChange={
                                                handleEmployeeInputChange
                                              }
                                              id={filledDarkerId}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                     

                                    </div>
                                  </div>
                                </div>
                                <div className="wform-MyContentControl-module__edgeworth-stack-item-container-refresh__3mUvv wform-css-283-right">
                                  <Accordion defaultOpenItems="basicInfo">
                                    <AccordionItem value="basicInfo">
                                      <AccordionHeader as="h2">
                                        General information
                                      </AccordionHeader>
                                      <AccordionPanel>
                                        <Label className="wc-pmargin-b">
                                          Mode of payment
                                        </Label>
                                        <AccordionPanel>
                                          <div
                                            role="group"
                                            aria-labelledby="hobbiesLabel"
                                          >
                                            <Checkbox
                                              label="Weekly /Hourly Paycheck"
                                              name="Weekly /Hourly Paycheck"
                                              onChange={handleCheckboxChange}
                                            />
                                            <Checkbox
                                              label="Direct Credit"
                                              name="Direct Credit"
                                              onChange={handleCheckboxChange}
                                            />
                                            <Checkbox
                                              label="Invoice"
                                              name="Invoice"
                                              onChange={handleCheckboxChange}
                                            />
                                          </div>
                                        </AccordionPanel>
                                        <div className="w-add-Guest-room Guest-management-job">
                                          <div className="w-add-room-guest rms-block-right">
                                            <div className="w-add-room-guest rms-inline-property-title">
                                              Role
                                            </div>
                                            <div className="firstName-container">
                                              <Select
                                                appearance="filled-darker"
                                                name="role"
                                                id={filledDarkerId}
                                                onChange={
                                                  handleEmployeeSelectInputChange
                                                }
                                              >
                                                <option value="">
                                                  Select Role
                                                </option>
                                                {positionMapping.map((roles) => {
                                                  return (
                                                    <option
                                                      key={roles.roleId}
                                                      value={roles.roleName || ""}
                                                    >
                                                      {roles.roleName}
                                                    </option>
                                                  );
                                                })}
                                              </Select>
                                            </div>
                                          </div>

                                          <div className="w-add-room-guest rms-block-right">
                                            <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                              Second Contact Person Email
                                            </div>
                                            <div className="firstName-container wc-margin">
                                              <Input
                                                appearance="filled-darker"
                                                placeholder="Second Contact Person"
                                                type="email"
                                                name="firstContactEmail2"
                                                value={registerEmployee.firstContactEmail2 || ""}
                                                onChange={
                                                  handleEmployeeInputChange
                                                }
                                                id={filledDarkerId}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-inline-property-title wc-margin flex-width-content">
                                         <span> Currency Used</span>
                                         <span className="WC-Add-roles"> <Button shape="circular" onClick={handleRolesPosition}>Add roles</Button></span>
                                        </div>
                                        <div className="Flex--inline">
                                        <RadioGroup
                                          defaultValue="NGN"
                                          name="Currency"
                                          onChange={handleEmployeeRadioChange}
                                          aria-labelledby="ageLabel"
                                          className="wc-flex"
                                        >
                                          <Radio value="NGN" label="NGN" />
                                          <Radio value="USD" label="USD" />
                                        </RadioGroup>
                                        <RadioGroup
                                          defaultValue="Single"
                                          name="MaritalStatus"
                                          onChange={handleRelationshipRadioChange}
                                          aria-labelledby="ageLabel"
                                          className="wc-flex"
                                        >
                                          <Radio value="Single" label="Single" />
                                          <Radio value="Married" label="Maried" />
                                        </RadioGroup>
                                        </div>
                                      
                                        <div className="w-add-Guest-room Guest-management-job">
                                          <div className="w-add-room-guest rms-block-left">
                                            <div className="w-add-room-guest Guest-inline-property-title">
                                              Second contact Person Name
                                            </div>
                                            <div className="firstName-container">
                                              <Input
                                                name="firstContactFullName2"
                                                placeholder="Second Contact person name"
                                                value={registerEmployee.firstContactFullName2 || ""}
                                                type="text"
                                                appearance="filled-darker"
                                                onChange={
                                                  handleEmployeeInputChange
                                                }
                                                id={filledDarkerId}
                                              />
                                            </div>
                                          </div>

                                          <div className="w-add-room-guest rms-block-right wc-margin">
                                            <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                              Second Conatact Person phone 
                                            </div>
                                            <div className="firstName-container">
                                              <Input
                                                placeholder="Second contact Person Phone"
                                                name="firstContactPhone2"
                                                value={registerEmployee.firstContactPhone2 || ""}
                                                appearance="filled-darker"
                                                onChange={
                                                  handleEmployeeInputChange
                                                }
                                                id={filledDarkerId}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="w-add-Guest-room Guest-management-job">
                                          <div className="w-add-room-guest rms-block-left">
                                            <div className="w-add-room-guest Guest-inline-property-title">
                                            Vacation Days
                                            </div>
                                            <div className="firstName-container">
                                              <Input
                                                name="vacationDays"
                                                placeholder="Vacation Days"
                                                value={registerEmployee.vacationDays?.toLocaleString() || ""}
                                                type="number"
                                                appearance="filled-darker"
                                                onChange={
                                                  handleEmployeeInputChange
                                                }
                                                id={filledDarkerId}
                                              />
                                            </div>
                                          </div>

                                          <div className="w-add-room-guest rms-block-right wc-margin">
                                            <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                            Sick Leave Days
                                            </div>
                                            <div className="firstName-container">
                                              <Input
                                                placeholder="Sick Leave Days"
                                                type='number'
                                                name="sickLeaveDays"
                                                value={registerEmployee.sickLeaveDays?.toLocaleString() || ""}
                                                appearance="filled-darker"
                                                onChange={
                                                  handleEmployeeInputChange
                                                }
                                                id={filledDarkerId}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="w-add-Guest-room Guest-management-job">
                                          <div className="w-add-room-guest rms-block-left">
                                            <div className="w-add-room-guest Guest-inline-property-title">
                                              Training Records
                                            </div>
                                            <div className="firstName-container">
                                              <Input
                                                placeholder="Training Records"
                                                name="trainingRecords"
                                                appearance="filled-darker"
                                                value={registerEmployee.trainingRecords?.toLocaleString() || ""}
                                                type="text"
                                                onChange={
                                                  handleEmployeeInputChange
                                                }
                                                id={filledDarkerId}
                                              />
                                            </div>
                                          </div>

                                          <div className="w-add-room-guest rms-block-right wc-margin">
                                            <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                              Allowance
                                            </div>
                                            <div className="firstName-container">
                                              <Input
                                                placeholder="Credit Allowance"
                                                name="salary"
                                                value={registerEmployee.salary?.toLocaleString() || ""}
                                                type="number"
                                                onChange={
                                                  handleEmployeeInputChange
                                                }
                                                contentBefore={
                                                  <Text
                                                    size={400}
                                                    id={beforeLabelId}
                                                  >
                                                    {currencySymbol}
                                                  </Text>
                                                }
                                                contentAfter={
                                                  <Text
                                                    size={400}
                                                    id={afterLabelId}
                                                  >
                                                    .00
                                                  </Text>
                                                }
                                                appearance="filled-darker"
                                                id={filledDarkerId}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                            Employment Status
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              placeholder="Employment Status"
                                              name="employmentStatus"
                                              value={registerEmployee.employmentStatus || ""}
                                              appearance="filled-darker"
                                              onChange={
                                                handleEmployeeInputChange
                                              }
                                              id={filledDarkerId}
                                            />
                                            {/* <Field>
                                              <DatePicker
                                                showWeekNumbers={true}
                                                firstWeekOfYear={1}
                                                showMonthPickerAsOverlay={true}
                                                placeholder="Issues Date..."
                                                className={styles.control}
                                              />
                                            </Field> */}
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                            Performance Evaluations
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              placeholder="KPI Evaluations"
                                              name="performanceEvaluations"
                                              appearance="filled-darker"
                                              value={registerEmployee.performanceEvaluations || ""}
                                              onChange={
                                                handleEmployeeInputChange
                                              }
                                              id={filledDarkerId}
                                            />
                                            {/* <Field>
                                              <DatePicker
                                                showWeekNumbers={true}
                                                firstWeekOfYear={1}
                                                showMonthPickerAsOverlay={true}
                                                placeholder="Expire Date..."
                                                className={styles.control}
                                              />
                                            </Field> */}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-right margin-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                            Upload Reference File
                                          </div>
                                          <div className="firstName-container Max-width-Employee">
                                            <span className="fui-Input r1jtohuq ___8dx9ou0_np9bry0 f16xq7d1 ftmjh5b f17blpuu f1tpwn32 fsrcdbj fghlq4f f1gn591s fb073pr fjscplz">
                                            <input
                                              placeholder="Upload Credentials"
                                              id={filledDarkerId}
                                              className="fui-Input__input rvp2gzh"
                                              type="file"
                                              name="employeeFile"                                         
                                             onChange={handleEmployeeInputChange}
                                              accept="*/*"
                                            />

                                            </span>
                                          </div>
                                        </div>

                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                            Passport Photo
                                          </div>
                                          <div className="firstName-container Max-width-Employee">
                                            <span className="fui-Input r1jtohuq ___8dx9ou0_np9bry0 f16xq7d1 ftmjh5b f17blpuu f1tpwn32 fsrcdbj fghlq4f f1gn591s fb073pr fjscplz">
                                              <input
                                                placeholder="Upload Passport photograph"
                                                id={filledDarkerId}
                                                className="fui-Input__input rvp2gzh"
                                                type="file"
                                                name="photos"
                                                onChange={
                                                  handleEmployeeInputChange
                                                }
                                                accept="image/*"
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                        <Label id="ageLabel">
                                          More Infomation:
                                        </Label>
                                        <RadioGroup
                                          aria-labelledby="IsAdmin"
                                          className="wc-flex"
                                          onChange={handleInputRadioChange}
                                        >
                                          <Radio
                                            value="isManager"
                                            label="Is Manager"
                                           
                                          />
                                          <Radio
                                            value="isAdmin"
                                            label="Is Admin"
                                           
                                          />
                                        </RadioGroup>
                                      </AccordionPanel>
                                    </AccordionItem>
                                   
                                  </Accordion>
                                </div>
                              </form>
                            </div>
                          </div>
                        </>
                      </div>
                    </div>
                    <div className="createList-pageFooter">
                      <div className="createList-leftActions">
                        <Button
                          data-automationid="createListGoBackButton"
                          className=""
                          icon={<ChevronLeft24Regular />}
                          onClick={handleCloseEmployee}
                        >
                         
                          Back
                        </Button>
                      </div>
                      <div className="createList-rightActions">
                        <Button
                          className="wc-first-root-68"
                          onClick={handleSubmitEmployeeClick}
                        >
                          Register
                        </Button>
                        <Button className="" onClick={handleCloseEmployee}>
                         
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterEmployee;
