export interface IReservation {
    titleid: number;
    genderid: number;
    firstName: string;
    lastName: string;
    guestId: number;
    address: string;
    phone: string;
    guestIdCard: string;
    country: string;
    state: string;
    city: string;
    roomNumber: number;
    identity:string;
    roomName: string;
    checkInDate: Date;
    checkOutDate: Date;
    epirdate:Date;
    reservationType: string;
    source: string
    roomType: string;
    numberOfRooms: number;
    numberOfChildren: number;
    numberOfAdult: number;
    numberOfRoom: number;
    email: string;
    isTravelAgent: boolean;
    dateOfBirth: Date;
    issueDateofId: Date;
    arrivalDate:Date;
    maritalStatus?: string;
    dateadded: Date;
}
 export const InitIReservation: IReservation = {
     titleid: 0,
     genderid: 0,
     firstName: "",
     lastName: "",
     guestId: 0,
     address: "",
     phone: "",
     guestIdCard: "",
     country: "",
     state: "",
     city: "",
     roomNumber: 0,
     roomName: "",
     checkInDate: new Date(),
     checkOutDate: new Date(),
     epirdate: new Date(),
     reservationType: "",
     source: "",
     roomType: "",
     numberOfRooms: 0,
     numberOfChildren: 0,
     numberOfAdult: 0,
     numberOfRoom: 0,
     email: "",
     isTravelAgent: false,
     dateOfBirth: new Date(),
     issueDateofId: new Date(),
     maritalStatus: "",
     dateadded: new Date(),
     identity: "",
     arrivalDate: new Date()
 }

