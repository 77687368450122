import React, { useState } from "react";
import { Cbar, SidebarMore, SidebarBottom } from "../../../Data/Cbar";
import SbarOptions from "./SbarOptions";
import "./Sidebar.scss";
import "../../Themes/Fonts/Icon/csc.scss";
import { DefaultButton } from '@fluentui/react/lib/Button';
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';
import { useId } from '@fluentui/react-hooks';
import { Button, CounterBadge, Tooltip } from "@fluentui/react-components";
import { Briefcase24Filled, BroadActivityFeed24Filled, WalletCreditCard24Filled, MailUnread24Filled, People24Regular, CalendarLtr24Regular, CloudEdit24Regular, Compose24Regular, AlignBottom24Filled, TaskListSquareLtr24Regular, CallCheckmark24Regular, TasksApp24Regular, Poll24Regular, ChatAdd24Regular, ChannelShareFilled } from "@fluentui/react-icons";



interface SidebarProps {
    SiderbarShowOptionClick?: (optionName: string | undefined) => void;
}
interface SidebarProps {
    SiderbarShowOptionClick?: (optionName: string | undefined) => void;
}
const Sidebar: React.FC<SidebarProps> = ({ SiderbarShowOptionClick }) => {
    const [isActive, setIsActive] = useState(false);
    const [selectedOption, setSelectedOption] = React.useState<string | undefined>();
    const tooltipId = useId('tooltip');
    const calloutProps = { gapSpace: 0 };
    const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };
    const [activeStates, setActiveStates] = useState([false, false, false, false, false, false, false, false, false]);

    const handleClick = (index: string | number, title: string | undefined) => {
        setActiveStates((prevActiveStates) =>
            prevActiveStates.map((state, i) => (i === index ? !state : false))
        );
        if (SiderbarShowOptionClick) {
            SiderbarShowOptionClick(title);
        }
    };

    // const handleClick = (title: string) => {
    //     setIsActive(!isActive);
    //     if (SiderbarShowOptionClick) {
    //         SiderbarShowOptionClick(title);
    //     }
    // };

    const handleOptionClick = (name: string | undefined) => {
        setSelectedOption(prevSelectedOption => prevSelectedOption === name ? undefined : name);
        if (SiderbarShowOptionClick) {
            if (Cbar.some(option => option.name === name) || SidebarBottom.some(option => option.name === name)) {
                SiderbarShowOptionClick(name);
            } else {
                SiderbarShowOptionClick(undefined);
            }
        }
    };

    function onOptionClick(id: number | undefined): void {
        console.log(`Clicked option id: ${id}`);
    }

    return (
        <>
            <div className="fui-FluentProvider  fui-FluentProvider2 wcujdtuxv0 f19n0e5 fxugw4r f1o700av fk6fouc fkhj508 figsok6 f1i3iumi">
                <div className=" wside-CJNGUE ___lluazi0 f22iagw f122n59 f1ctqxl6 f1l02sjl f124akge f1vx9l62">
                    <i className="wc-ifix"></i>
                    <div className="___1cp4gt1 f22iagw MO783-ingHEN f122n59 f17wyjut f1vx9l62 f540s0x f1ja72i3" role="navigation"
                        aria-label="left-rail-appbar">
                        <div>
                            <div className={`ec77lcry0  wc-cmh-46 Side-Side-f10pi13n ${activeStates[0] ? 'wc-Isactive' : ''}`}
                                role="none"
                                onClick={() => handleClick(0, "Activities")}>
                                <Tooltip
                                    withArrow
                                    positioning="after"
                                    content="Manage hotel acitivities"
                                    relationship="label">
                                    <Button className="fui-Button cvsgxpvc0 wc-b-activity" icon={<People24Regular
                                        className="wc-icon-sidebar wc-add-color" />} aria-describedby={tooltipId} >
                                            Activity
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                        <div>
                            <div className={`ec77lcry0 wc-cmh-46 Side-Side-f10pi13n  ${activeStates[1] ? 'wc-Isactive' : ''}`} role="none" onClick={() => handleClick(1, "Booking")}>
                                <Tooltip
                                    withArrow
                                    positioning="after"
                                    relationship="label"
                                    content="Book room and Reservation"
                                >
                                    <Button className="fui-Button cvsgxpvc0 wc-b-activity" icon={<i
                                        className="wc-icon-sidebar not-height fi fi-tr-book-open-reader wc-add-color"> </i>} aria-describedby={tooltipId} >
                                            Booking
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                        <div>
                            <div className={`ec77lcry0 wc-cmh-46 Side-Side-f10pi13n ${activeStates[2] ? 'wc-Isactive' : ''}`} role="none" onClick={() => handleClick(2, "Finance")}>
                                <Tooltip content="Accounting and Finance"
                                    withArrow
                                    positioning="after"
                                    relationship="label">
                                    <Button className="fui-Button cvsgxpvc0 wc-b-activity" icon={<i
                                        className="wc-icon-sidebar not-height fi fi-tr-wallet wc-add-color"></i>} aria-describedby={tooltipId} >
                                        Finance
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                        <div>
                            <div className={`ec77lcry0 wc-cmh-46 Side-Side-f10pi13n ${activeStates[3] ? 'wc-Isactive' : ''}`} role="none" onClick={() => handleClick(3, "Booking Calender")}>
                                <Tooltip content="Booking Calender"
                                  positioning="after"
                                    withArrow
                                    relationship="label">
                                    <Button className="fui-Button cvsgxpvc0 wc-b-activity" icon={<CalendarLtr24Regular
                                        className="wc-icon-sidebar wc-add-color" />} aria-describedby={tooltipId} >
                                        Calender
                                    </Button>

                                </Tooltip>

                            </div>
                        </div>
                        
                        <div>
                            <div className={`ec77lcry0 wc-cmh-46 Side-Side-f10pi13n ${activeStates[5] ? 'wc-Isactive' : ''}`} role="none" onClick={() => handleClick(5, "Task List")}>

                                <Tooltip content="Task List"
                                  positioning="after"
                                    withArrow
                                    relationship="label">
                                    <Button className="fui-Button cvsgxpvc0 wc-b-activity" icon={<TasksApp24Regular
                                        className="wc-icon-sidebar wc-add-color" />} aria-describedby={tooltipId} >
                                      CRM
                                    </Button>

                                </Tooltip>
                            </div>
                        </div>
                        <div>
                            <div className={`ec77lcry0 wc-cmh-46 Side-Side-f10pi13n ${activeStates[6] ? 'wc-Isactive' : ''}`} role="none" onClick={() => handleClick(6, "Manage Sells & Requisition")}>

                                <Tooltip content="Manage Sells & Requisition"
                                  positioning="after"
                                    withArrow
                                    relationship="label">
                                    <Button className="fui-Button cvsgxpvc0 wc-b-activity" icon={<Poll24Regular
                                        className="wc-icon-sidebar wc-add-color" />} aria-describedby={tooltipId} >
                                      Rquisition
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                        <div>
                            <div className={`ec77lcry0 wc-cmh-46 Side-Side-f10pi13n ${activeStates[7] ? 'wc-Isactive' : ''}`} role="none" onClick={() => handleClick(7, "Audit Log")}>
                                <Tooltip content="Manage Audit Log"
                                    withArrow
                                    positioning="after"
                                    relationship="label">
                                    <Button className="fui-Button cvsgxpvc0 wc-b-activity" icon={<Compose24Regular
                                        className="wc-icon-sidebar wc-add-color" />} aria-describedby={tooltipId} >
                                        Audit
                                    </Button>

                                </Tooltip>
                            </div>
                        </div>
                        <div>
                            <div className={`ec77lcry0 wc-cmh-46 Side-Side-f10pi13n ${activeStates[8] ? 'wc-Isactive' : ''}`} role="none" onClick={() => handleClick(8, "Check request")}>

                                <Tooltip content="Check request"
                                 positioning="after"
                                    withArrow
                                    relationship="label">
                                    <Button className="fui-Button cvsgxpvc0 wc-b-activity" icon={<ChannelShareFilled
                                        className="wc-icon-sidebar wc-add-color" />} aria-describedby={tooltipId} >
                                        Request
                                    </Button>                                  
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sidebar;
