

export interface IReservationType {
    TypeName?: any | null;
    Description?: any | null;
    IsRefundable?: any | null;
    RequiresCreditCard?: any| null;
    Price?: any | null;
    CancellationPolicy?: any;
    MinStayLength?: any;
    MaxStayLength?: any;

}
export interface InterfaceReservationType {
    reservationTypeId: number;
    typeName?: string;
    description?: string;
    isRefundable?: boolean;
    requiresCreditCard?: boolean;
    price?: number;
    cancellationPolicy?: string;
    minStayLength?: number;
    maxStayLength?: number;
    dateadded: Date;
}


export const InitialReservationTypes: InterfaceReservationType = {
    typeName: "",
    description: "",
    isRefundable: false,
    requiresCreditCard: false,
    price: 0.00,
    cancellationPolicy: "",
    minStayLength: 0,
    maxStayLength: 0,
    reservationTypeId: 0,
    dateadded: new Date(),
}

export interface IRType {
    reservationTypeId:any;
    typeName?: any;
    description?: any;
    isRefundable?: any;
    requiresCreditCard?: any;
    price?: any;
    cancellationPolicy?: any;
    minStayLength?: any;
    maxStayLength?: any;
}



