import { useState } from 'react';
import Live from '../LiveStream/Live';
import '../LiveStream/Live.scss';
import { Button } from '@fluentui/react-components';
import signal from "../../Themes/Images/e00ce905.png";


interface User {
  email: string,
  phoneNumber:string,
  token:string,
  
  // other properties...
  }
  type Props = {
  user:User;
  }
const CCTVPage: React.FC<Props> = ({user}) => {
  // Replace these with your actual YouTube Live Stream URLs
  const rooms = [
    {
      id: 1,
      name: 'Living Room',
      streamUrl: 'https://www.youtube.com/watch?v=vYDyGxoq9JU',
    },
    {
      id: 2,
      name: 'Kitchen',
      streamUrl: 'https://www.youtube.com/watch?v=5qap5aO4i9A',
    },
    {
      id: 3,
      name: 'Bedroom',
      streamUrl: 'https://www.youtube.com/watch?v=5qap5aO4i9A',
    },
    {
      id: 9,
      name: 'Bedroom',
      streamUrl: 'https://www.youtube.com/watch?v=5qap5aO4i9A',
    },
    {
      id: 4,
      name: 'Medview',
      streamUrl: 'https://www.youtube.com/watch?v=5qap5aO4i9A',
    },
    {
      id: 5,
      name: 'Maintain',
      streamUrl: 'https://www.youtube.com/watch?v=5qap5aO4i9A',
    },
    {
      id: 6,
      name: 'Control',
      streamUrl: 'https://www.youtube.com/watch?v=5qap5aO4i9A',
    },
    {
      id: 7,
      name: 'User For',
      streamUrl: 'https://www.youtube.com/watch?v=5qap5aO4i9A',
    },
    {
      id: 8,
      name: 'Management',
      streamUrl: 'https://www.youtube.com/watch?v=5qap5aO4i9A',
    },
    // Add more rooms here...
  ];

  const [selectedRoom, setSelectedRoom] = useState<any | null>(null);

  return (
    <>
       <div className="fJsklc ZmuLbd Didmac G03iKb">
       <div className="o0X9vc o2vY1c">
              <span className="VfPpkd-suEOdc-sM5MNb-OWXEXe-nzrxxc">
                <Button className="VfPpkd-Bz112c-LgbsSe yHy1rc eT1oJ mN1ivc UIFKT">
                  <span className="live-text"> Live <small className="space-out">CCTV</small></span>              
                  <p className="tab-signal anim">
                    <span className="light1"></span> 
                    <span className="light2"></span> 
                    <img src={signal} alt="" /></p>
                </Button>               
              </span>
            </div>
      <div className="innercontent-and-scrollable customScrollBar">
        <div className="style-scope ytd-rich-grid-row">
        {rooms.map((room) => (
          <div className='dkjMxf' key={room.id} onClick={() => setSelectedRoom(room.id)}>
            <div>
              <div className="koV58 Zi94Db S7urwe">
                <div className="koV58 Zi94Db S7urwe">
                  <div className="knW4sf OvyUed">
                    <div className="qRU4mf uSECwd JTEhGf">
              <Live streamUrl={room.streamUrl} roomName={room.name} isSelected={selectedRoom === room.id}
            />
            </div>
            </div>
            </div>
              </div>
            </div>
            
          </div>
        ))}
      </div>
      </div>
    </div>
        </>
    
  );
};

export default CCTVPage;
