import axios from 'axios';
import React from 'react';
import { WHITE_HOUSE_API } from '../../../Constants/Constant';
import { InitiateTransactions } from '../../../Data/General/InitiateTransaction';

export const  InternalTransaction = async (T:InitiateTransactions, token: string) =>{
    try {
        const response = await axios.post(
            `${WHITE_HOUSE_API}/Payment`,
            T,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
       return {header: response.data.status, body:response.data.eMessage};
    } catch (error) {
      console.error(error);
    }
  }