export interface ICocktailsandDrink {
    ProductName?: string | null;
    ProductDescription?: string | null;
    Quantity?: string | null;
    unitofmeasurement?: string | null;
    Price?: number | null;
    TimeStamps?: number | null;
    Purchasingprice?: number | null;
    Increaseinsalespercent?: string | null;
    Decreaseinsalespercent?: string | null;
    Amountcompounded?: number | null;
    AllPercentageSells?: string | null;
    Currency?: string | null;
    MethodOfPayment?: string | null;
    MembershipId?: string | null;
}



export const InitialCockTailItems: ICocktailsandDrink = {
    ProductName: null,
    ProductDescription: null,
    Quantity: null,
    unitofmeasurement: null,
    Price: null,
    TimeStamps: null,
    Purchasingprice: null,
    Increaseinsalespercent: null,
    Decreaseinsalespercent: null,
    Amountcompounded: null,
    AllPercentageSells: null,
    Currency: "NGN",
    MethodOfPayment: null,
    MembershipId: null,
};
