
// constants.ts
// export const WHITE_HOUSE_API = 'https://localhost:44302/api';
// export const CHANNEL_ENPOINT = 'https://localhost:44302';

export const CHANNEL_ENPOINT = 'https://cilpronhms.azurewebsites.net/';
export const WHITE_HOUSE_API ='https://cilpronhms.azurewebsites.net/';
export const SUBACCOUNT_CODE = 'ACCT_a1t1qbj6g4j7wy5';
export const SECRETE_KEY = 'Bearer sk_test_fadb539b7b334f4ba5d3f5807af3f7d98f6acc01';




export const WHITE_HOUSE_S3 = 'https://whitehouse-statics-files.s3.amazonaws.com/'


export const generateUniqueID = () => {
    return Math.floor(Date.now() % 2147483647);
}


export interface ReturnMessage {
    header:string;
    body:string;
  }



  export interface User {
    email: string,
    phoneNumber:string,
    token:string,
  
    // other properties...
  }
  
  export interface Message {
    message: string;
    id: number;
  }

  export const formatDateinput = (date: any) => {
    let year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-based
    let day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
}

