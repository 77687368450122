import React, { ChangeEvent, FormEvent, ReactEventHandler, useState } from "react";
import "./AppHome.scss";
import "../../Themes/Fonts/Icon/csc.scss";
import ActivityApp from "./Activities/Activity";
import Booking from "../Book/Booking";
import Landing from "../Landing/Landing";
import Finance from "../Finance/Finance";
import SingleProductChart from "../Finance/ProductCharts/SingleProductChartLine";
import LineChart from "../Finance/ProductCharts/LineChart";
import Loaders from "../../Common/Loader/Loader";
import { provideFluentDesignSystem, fluentCalendar } from "@fluentui/web-components";
import Live from "../../Common/LiveStream/Live";
import CCTVPage from "../../Common/CCTV/CctvRoom";
import SellsPage from "../Sells/SellsPage";
import { SearchBoxProps } from "@fluentui/react-search-preview";
import Calender from "../Calender/Calender";
import Calenders from "../Calender/Calender";
import CRM from "../CRM/CRM";
import AuditLog from "../AuditLog/AuditLog";
import CheckRequest from "../CheckRequest/CheckRequest";
declare global {
    namespace JSX {
      interface IntrinsicElements {
        fluentCalendar: any; // Use the appropriate type here
      }
    }
  }
  
  interface Message {
    message: string,
    id:number,
  }

interface User {
    email: string,
    phoneNumber:string,
    token:string,
  
    // other properties...
  }



  interface Usercontroles {
    user: User;
    selectedOption: string | undefined;
    message:Message;
  }
  


const AppHome: React.FC<Usercontroles> = ({ selectedOption,message, user}) => {
    const [isInnerSidebarVisible, setIsInnerSidebarVisible] = useState(false);
    const [showViewRoom, setShowViewRoom] = useState(false);
    const [showGuestForm, setShowGuestForm] = useState(false);
    provideFluentDesignSystem().register(fluentCalendar());
    const handleClose = () => {
        console.log('handleClose called');
        setShowViewRoom(false);
    };
    const handleGuestOpenForm = () => {
        setShowGuestForm(true);
    }
    const handleOpen = () => {
        setShowViewRoom(true);
    };
    const handleInnerSidebarToggle = () => {
        setIsInnerSidebarVisible((prevState) => !prevState);
    };
    const handleGuestForm = () => {
        setShowGuestForm(false);
    }
    return (
        <>
            {selectedOption === "Landing" && (
                    <div className="TaskList">
                        <Landing message={message} user={user}/>
                    </div>
               
            )}
            {selectedOption === "Activities" && (
                <div className="TaskList">
                    <ActivityApp items={[]} user={user} settings={[]} message={message} />
                </div>
            )}
            {selectedOption === "Booking" && (
                <div className="w-ci-booking ts-main-flex ts-middle hybrid-passthroug hybrid-layout-enabled border-radious-8">
                    <Booking showViewRoom={showViewRoom} onOpen={handleOpen} onClose={handleClose} OnCloseGuest={handleGuestForm} items={[]} showGuestForm={showGuestForm} OnOpenGuest={handleGuestOpenForm} user={user}/>
                </div>
            )}

            {selectedOption === "Finance" && (
                 <div className="TaskList">
                        <Finance message={""} user={user} />
                    </div>
            )}
            {selectedOption === "Booking Calender" && (
                <div className="gU1Tf Cells-Calender">
                <Calenders />
                </div>
            )}
            
            {selectedOption === "Task List" && (
                <div className="TaskList">
                    <CRM message={message} user={user}  />
                </div>
            )}
            {selectedOption === "Manage Sells & Requisition" && (
                <div className="guest-calls">
                    <SellsPage message={message} user={user} />
                </div>
            )}

            {selectedOption === "Audit Log" && (
                <div className="AMiuHTG">
                    <AuditLog message={message} user={user} />
                </div>
            )}
             {selectedOption === "Check request" && (
                <div className="AMiuHTG AMiuHTG__CHai">
                    <CheckRequest message={message} user={user} />
                </div>
            )}
{/* MCcOAc IqBfM ecJEib EWZcud cjGgHb d8Etdd LcUz9d */}
            {selectedOption === "video" && (
                <div className="fJsklc-CCTV customScrollBar"> 
                    <CCTVPage user={user} />
                </div>
            )}
        </>
    );
};

export default AppHome;