import React, { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
  Label,
  useId,
  Input,
  makeStyles,
  Divider,
  Field,
  Combobox,
  Option,
  Text,
  Persona,
  Dropdown,
} from '@fluentui/react-components';
import './RaiseInvoice.scss';
import {
  IBusinessTypes,
  InitializeBusinessTypes,
} from '../../../../Data/General/BusinessType';
import { WHITE_HOUSE_API } from '../../../../Constants/Constant';
import axios from 'axios';
import Loaders from '../../../Common/Loader/Loader';
import DispatchToast from '../../../Common/Toast/DispatchToast';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import { IDynamic } from '../../../../Data/General/IDynamic';
import { IDropdownOption } from '@fluentui/react';
import { ICreateInvoice, initializeInvoice } from '../../../../Data/General/ICreateInvoice';
import { Reservation } from '../../../../Data/General/Reservation';
import { Restaurant } from '../../../../Data/General/Restaurant';
import { IRoom } from '../../../../Data/General/Room';
import { BarLoungeMenu } from '../../../../Data/General/BarMenuLounge';
import { Event } from '../../../../Data/General/Event';
import { Vendor } from '../../../../Data/General/Vendor';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '400px',
  },
  control: {
    maxWidth: '300px',
  },
});

type Adjuster = {
  firstname?:string;
  lastname?:string;
  phoneNumber?:string;
  userid?: number;
}

interface User {
  email: string,
  phoneNumber:string,
  token:string,

  // other properties...
}
interface Message {
  message: string,
  id:number,
}
interface BusinessTypesProps {
  onClose: () => void;
  message: Message;
  user: User;
  adjuster:Adjuster;
}

type ApiDataItem = Vendor | BarLoungeMenu | Event | Reservation | IRoom | Restaurant;

const RaiseInvoice: React.FC<BusinessTypesProps> = ({ onClose, message, user,adjuster }) => {
  const [dispatchToaster, setDispatchToaster] = useState<boolean>(false);
  const [selectedInvoicee, setSelectedInvoicee] = useState<number>();
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [results, setResults] = useState<IDynamic[]>([]);
  const [selectedService, setSelectedService] = useState<string | null>(null);
  const [apiData, setApiData] = useState<ApiDataItem[]>([]);
  const [createInvoice, setCreatedInvoice] = useState<ICreateInvoice>(initializeInvoice)
  const [input, setInput] = useState('');
  const inputId = useId('input');
  const styles = useStyles();
  const [singleItemPrice, setSingleItemPrice] = useState<any>(0);
  const [responseMessage, setResponseMessage] = useState('');
  const [currency, setCurrency] = useState('NGN');
  const currencySymbol = currency === 'NGN' ? '₦' : '$';
  const [quantityPrice, setQuentityPrice] = useState();


  const handleInvoiceInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setCreatedInvoice((prevState) => ({ ...prevState, [name]: value }));
    console.log('the data to be posted', createInvoice);
  };

  const HandleRaiseInvoice = async () => {
    try {
      setIsLoader(true);
      const response = await axios.post(
        `${WHITE_HOUSE_API}/Invoice`,
        createInvoice,
        {
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        }
      );
      if (response.data) {
        setTimeout(() => {
          setIsLoader(false);
        }, 1500);
  
        if (response.data.status === 'Success') {
          setResponseMessage(response.data.eMessage);
          setDispatchToaster(true);
          setTimeout(() => {
            setDispatchToaster(false);
          }, 1000);
        }
  
  
      }
    } catch (error) {
      setResponseMessage('Registration ' + error);
      console.error('Error fetching roles:', error);
      setTimeout(() => {
        setIsLoader(false);
      }, 1500);

    }
  };
  

  const handleInputChange = (event: any) => {
    setInput(event.target.value);
  };

const getSelectedInvoicess = (e:any) => {
  const selectedName = e.optionValue;
  const selectedincocee = results.find(result => Number(result.guestId) === Number(selectedName) || Number(result.vendorId) === Number(selectedName));

  console.log("The selected Name:", selectedName);
  if (selectedincocee) {
    let selectedId = selectedincocee.vendorId ?? selectedincocee.guestId;
    setSelectedInvoicee(selectedId);
    let idName = selectedincocee.vendorId ? 'vendorid' : 'guestId';
    let idVal = Number(e.optionValue);
    setCreatedInvoice((prevState) => ({ ...prevState, [idName]: idVal }));
    console.log("The selected Name",idName);
  }
}
 

  
  // const handleBlur = (event:any) => {
  //   const selectedName = event.target.value;
  //   const selectedincocee = results.find(result => `${result.firstName} ${result.lastName }${result.companyName} ${result.vendorName}` === selectedName);
    
  //   if (selectedincocee) {
  //     setSelectedInvoicee(selectedincocee.vendorId ?? selectedincocee.guestId );
  //     setCreatedInvoice((prevState) => ({ ...prevState, [name]: selectedInvoicee }));
  //   }
  // };
  function formatDate(dateString: Date): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero indexed, so +1 and pad with 0
    const day = ("0" + date.getDate()).slice(-2); // Pad with 0
    return `${year}-${month}-${day}`;
  }


  const handleInvoiceDateInputChange = (name: string, date: Date | null | undefined) => {
    if (date) {
      console.log(`The Date onSelectDate for ${name}`, date);
      let formatedDate = formatDate(date);
      setCreatedInvoice((prevState) => ({
        ...prevState,
        [name]: formatedDate,
      }));
    }
  };
  

  const fetchAutocompleteResults = useCallback(async () => {
    if (input.length < 2) {
      return; 
    }

    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Invoice/Query?prefix=${input}`,
        {
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        }
      );
      setResults(response.data);
    } catch (error) {
      console.error('Error fetching autocomplete results:', error);
    }
  }, [input, user.token]); 




const handleServiceChange = async (event:any) => {
  const services = event.target.innerText;
  if (services) {
    setSelectedService(services);
    setCreatedInvoice((prevState) => ({ ...prevState, service: services }));
    let response;
    switch (services) {
      case 'Bar service':
        response = await axios.get(
          `${WHITE_HOUSE_API}/BarLoungeMenu`,
          {
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
          }
        );
        break;
      case 'Event service':
        response = await axios.get(
          `${WHITE_HOUSE_API}/Event`,
          {
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
          }
        );
        break;
      case 'Room service':
          response = await axios.get(
            `${WHITE_HOUSE_API}/Rooms/getRooms`,
            {
              headers: {
                'Authorization': `Bearer ${user.token}`
              }
            }
          );
          break;
      case 'Reservation service':
            response = await axios.get(
              `${WHITE_HOUSE_API}/Reservation`,
              {
                headers: {
                  'Authorization': `Bearer ${user.token}`
                }
              }
            );
          break;
      case 'Restuarant service':
              response = await axios.get(
                `${WHITE_HOUSE_API}/Restuarant`,
                {
                  headers: {
                    'Authorization': `Bearer ${user.token}`
                  }
                }
              );
            break;
      case 'Gift Shop':
                response = await axios.get(
                  `${WHITE_HOUSE_API}/GiftShop`,
                  {
                    headers: {
                      'Authorization': `Bearer ${user.token}`
                    }
                  }
                );
            break;
      case 'Spa & Wellness':
                response = await axios.get(
                  `${WHITE_HOUSE_API}/SpaandWellness`,
                  {
                    headers: {
                      'Authorization': `Bearer ${user.token}`
                    }
                  }
                );
            break;
      default:
        return;
    }
  
    setApiData(response.data); // assuming the API returns an array of strings
    console.log("Response data", apiData);
    if(selectedService){
     
      console.log("Response data", selectedService);
    }
  }
};


const setSelectedId = (name: any) => {
  console.log("name: ", name);
  console.log("name.innerText: ", name.target.innerText);
  
  let value = apiData.find(item => 
    'eventName' in item ? item.eventName === name.target.innerText :
    'reservationName' in item ? item.reservationName === name.target.innerText :
    'roomName' in item ? item.roomName === name.target.innerText :
    'restaurantName' in item ? item.restaurantName === name.target.innerText :
    'vendorName' in item ? item.vendorName === name.target.innerText :
    'itemName' in item ? item.itemName === name.target.innerText :
    'WellnessName' in item ? item.WellnessName === name.target.innerText :
    'giftItemName' in item ? item.giftItemName === name.target.innerText :
    false
  );
  
  console.log("The value Item: ", value);
  
  if (value) {
    let id:any;
    let name:string;
    if ('eventId' in value) {
      id = value.eventId;
      name = "EventId";
    } else if ('reservationId' in value) {
      id = value.reservationId;
      name= "ReservationId";
    } else if ('roomId' in value) {
      id = value.roomId;
      name = "RoomId";
    } else if ('vendorId' in value) {
      id = value.vendorId;
      name = "VendorId";
    }else if ("restuarantId" in value){
      id = value.restuarantId;
      name = "RestuarantId";
    } else if ('itemId' in value) {
      id = value.itemId;
      name= "itemId";
      setSingleItemPrice(value.price?.toLocaleString());
      console.log("The Price", value.price);
    } else if ("wellnessId" in value){
      id = value.wellnessId;
      name = "wellnessId";
    }else if ("giftId" in value){
      id = value.giftId;
      name = "giftId";
    }
    if (id) {
      setCreatedInvoice((prevState) => ({ ...prevState, [name]: id }));
    }
  } else {
    console.log("No match found in apiData for name.innerText");
  }
}




const HandlePriceqty = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { name, value } = event.target;

  // Convert singleItemPrice to a string
  let singlePrice = Number(singleItemPrice).toString().replace(/,/g, '');
  let amounts = Number(singlePrice) * Number(value);

  // Update the state
  setCreatedInvoice((prevState) => ({ ...prevState, amount: amounts }));
  setCreatedInvoice((prevState) => ({ ...prevState, [name]: value }));

  console.log('Amount to pay', createInvoice);
};


useEffect(() => {
  fetchAutocompleteResults();
}, [fetchAutocompleteResults]);

  return (
    <div className="fui-FluentProvider fui-FluentProvider361 ___1bsm0mz f19n0e5 fxugw4r f1o700av fk6fouc fkhj508 figsok6 f1i3iumi f1euv43f f15twtuk f1vgc2s3 f1e31b4d f494woh">
      <div
        aria-hidden="true"
        className="fui-DialogSurface__backdrop ___adeqq90 f19dog8a fju19wo f113wtx2 f10k790i f5gq2j6 f1xynx9j"
      ></div>

      <div className="fui-DialogSurface ___1g5txne ftgm304 f1c515w f1bitti f15twtuk f1e31b4d f1yab3r1 f1vgc2s3 fuq56rw f15kemlc fl2zwns fdgang7 fgr6219 f1ujusj6 f10jk5vf fcgxt0o fzkkow9 fcdblym fg706s2 fjik90z f1ln0qer fa2wlxz f120kxnn f19dog8a f3052tw fvgz9i8 f6a9g1z f1ewtqcl f10nrhrw fxugw4r f19n0e5 f5ogflp f1hqa2wf f1f09k3d finvdd3 fghlq4f f1gn591s fb073pr fjscplz fnivh3a fc7yr5o f1el4m67 f8yange ff54dml ftqa4ok f2hkw1w f8hki3x f1d2448m f1bjia2o ffh67wi f15bsgw9 f14e48fq f18yb2kv fd6o370 fh1cnn4 fy7oxxb fpukqih f184ne2d frrh606 f1v5zibi ful5kiu fo2hd23 f1jqcqds ftffrms f2e7qr6 fsr1zz6 f1dvezut fd0oaoj fjvm52t f1cwg4i8 f57olzd f4stah7 f480a47 fs1por5">
        <div className="fui-DialogBody ___6dxt060 f13qh94s f120kxnn flnwrvu f1ewtqcl f1ln0qer fa2wlxz f4px1ci fn67r4l f1x46k84 fjj47a5 ff54dml f1t97isy">
          {isLoader && (
            <div className="restrict-the-inner">
              <Loaders />
            </div>
          )}
          {dispatchToaster && <DispatchToast message={message.message} />}
          <div className="header-content">
            <h2
              id="dialog-title-363"
              className="fui-DialogTitle ___wfyv5z0 f16hsg94 f1amvztq fwpfdsa fsyjsko fk6fouc f1pp30po fl43uef f106mvju f1hu3pq6 f11qmguv f19f4twv f1tyq0we"
            >
              Raise Invoice
            </h2>
          </div>

          <div className="fui-DialogContent ___1dce55l f5zp4f f1nxs5xn f1ewtqcl faunodf f88nxoq fwpfdsa fsyjsko fr4fn7s f171n1a0 f1ean75l f1jwbs5a fxlnl9e ffs23cn f2uu7rc f1v5tmjd fk6fouc fkhj508 figsok6 f1i3iumi">
            <h3 className="user-initiating">{adjuster.lastname} {adjuster.firstname}</h3>
            <div className="First-form-flex-inoice">
              <div className={styles.root}>
                <Label htmlFor={inputId}>Choose Invoicee</Label>
                <Combobox
                  aria-labelledby="autocomplete"
                  onOptionSelect={(event, option) => {
                    getSelectedInvoicess(option);
                }}
                  onChange={handleInputChange}
                  placeholder='Search Guest/Vendors'
                >
                 {results.map((result) => {
                      let fullname = result.firstName + " " + result.lastName;
                      let choosename =  fullname ?? result.vendorName;
                      let id:any = result.guestId ?? result.vendorId;
                      return (
                          <Option
                              key={id}
                              text={choosename}
                              value={id.toString()}
                          >
                              <Persona
                                  avatar={{ color: 'colorful', 'aria-hidden': true }}
                                  name={choosename}
                                  presence={{
                                      status: 'available',
                                  }}
                                  secondaryText={result.companyName}
                              />
                          </Option>
                      );
                  })}

                </Combobox>
              </div>
              <div className={styles.root}>
                <Label htmlFor={inputId}>Select a Services</Label>
                <Dropdown
            placeholder="Select service"
            appearance="filled-lighter"
            onOptionSelect={handleServiceChange}
          >
            <Option value='Bar service'>Bar service</Option>
            <Option value='Event service'>Event service</Option>
            <Option value='Room service'>Room service</Option>
            <Option value='Reservation service'>Reservation service</Option>
            <Option value='Restuarant service'>Restuarant service</Option>
            <Option value='Gift Shop'>Gift Shop</Option>
            <Option value='Spa & Wellness'>Spa & Wellness</Option>
          </Dropdown>
              </div>
            </div>
            <div className="First-form-flex-inoice">
              {selectedService && (
                <div className={styles.root}>
                <Label htmlFor={inputId}>Enter Quantity</Label>
                <Input
                  name="quantity"
                  id={inputId}
                  placeholder="Quantity "
                  onChange={HandlePriceqty}
                />

              </div>
              )}
              
              {apiData.length > 0 && selectedService && (
                <div className={styles.root}>
                    <Label htmlFor={inputId}>{`Add ${selectedService}`}</Label>
                    <Dropdown
                        placeholder={`Choose ${selectedService}`}
                        appearance="filled-lighter"
                        onOptionSelect={(id) => {
                          setSelectedId(id);}}
                    >
                       {apiData.map((item) => {
                        if ('itemName' in item) {
                          return <Option key={item.itemId}>{item.itemName || ""}</Option>; // item is an Event
                        } else if ('productName' in item) {
                          return <Option key={item.productId}>{item.productName || ""}</Option>; // item is a Reservation
                        } else if ('roomName' in item) {
                          return <Option key={item.roomNumber}>{item.roomName || ""}</Option>; // item is an IRoom
                        } else if ('eventName' in item) {
                          return <Option key={item.eventId}>{item.eventName || ""}</Option>; // item is a Restaurant
                        } else if ('vendorName' in item) {
                        return <Option key={item.vendorId}>{item.vendorName || ""}</Option>; // item is a Restaurant
                      }
                      })}

                    </Dropdown>
                </div>
            )}
            </div>
            <div className="First-form-flex-inoice">
              <div className={styles.root}>
                <Label htmlFor={inputId}>Invoice Type</Label>
                <Input
                  id={inputId}
                  name="invoiceType"
                  placeholder="Invoice type"
                  onChange={handleInvoiceInputChange}
                />
              </div>
              <div className={styles.root}>
                <Label htmlFor={inputId}>Price</Label>
                <Input
                    readOnly
                    placeholder="Amount to Credit"
                    type="text"
                    value={createInvoice?.amount.toLocaleString() || ""}
                    contentBefore={
                      <Text size={400} id="beforeLabelId">
                        {currencySymbol}
                      </Text>
                    }
                    contentAfter={
                      <Text size={400} id="wafterLabelId">
                        .00
                      </Text>
                    }
                    appearance="filled-darker"
                    id="filledDarkerIdk"
                  />


              </div>
            </div>
            <div className="First-form-flex-inoice">
              <div className={styles.root}>
                <Label htmlFor={inputId}>Invoice Start</Label>
                <Field>
                  <DatePicker
                    showWeekNumbers={true}
                    firstWeekOfYear={1}
                    name="InvoceStart"
                    showMonthPickerAsOverlay={true}
                    placeholder="Start Date..."
                    className={styles.control}
                    onSelectDate={(date) => handleInvoiceDateInputChange('InvoceStart', date)}
                  />
                </Field>
              </div>
              <div className={styles.root}>
                <Label htmlFor={inputId}>Invoice End</Label>
                <Field>
                  <DatePicker
                    showWeekNumbers={true}
                    firstWeekOfYear={1}
                    name="InvoceEnd"
                    showMonthPickerAsOverlay={true}
                    placeholder="End Date..."
                    className={styles.control}
                    onSelectDate={(date) => handleInvoiceDateInputChange('InvoceEnd', date)}
                  />
                </Field>
              </div>
            </div>
          </div>
          <div className="fui-DialogActions ___1mdwomm f3052tw f1ewtqcl f22iagw f1cztu7b fcu02xx f4px1ci fn67r4l f6glcwc f1e3st1r f1a7i8kp fd46tj4 fsyjsko f1f41i0t f1jaqex3 f2ao6jk">
            <Button className="Button-Submit" onClick={HandleRaiseInvoice}>
              Raise Invoice
            </Button>
            <Button className="Button-close" onClick={onClose}>
              cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RaiseInvoice;
