export interface ISpaandWellness {
    WellnessName?: string | null;
    WellnessDescription?: string | null;
    ClientName?: string | null;
    ServiceType?: string | null;
    Therapist?: string | null;
    PaymentMethod?: string | null;
    PookingSource?: string | null;
    SpecialRequests?: string | null;
    Guestsatisfaction?: string | null;
    InventoryUsage?: string | null;
    InventoryReplenishment?: string | null;
    TreatmentSchedule?: Date | null;
    CancellationLog?: Date | null;
    NoShowRecords?: Date | null;
    ClientContactInformation?: string | null;
    StaffScheduling?: Date | null;
    Price?: number | null;
    TimeStamps?: number | null;
    AccessControl?: string | null;
    Inventory?: string | null;
    UserDemographics?: string | null;
    Purchasingprice?: number | null;
    DailyRevenueAnalysis?: string | null;
    RevenueStatistics?: string | null;
    FeedbackReviews?: string | null;
    RevenuewCompounded?: number | null;
    MarketingPromotions?: string | null;
    AllPercentageRevenues?: string | null;
    Currency?: string | null;
    MethodOfPayment?: string | null;
    AppointmentTime?: Date | null; // Replace TimeOnly with the correct type
    TreatmentDuration?: Date | null; // Replace TimeOnly with the correct type
    MembershipId?: string | null;
}




export const InitialSpaAndWellness: ISpaandWellness = {
    WellnessName: null,
    WellnessDescription: null,
    ClientName: null,
    ServiceType: null,
    Therapist: null,
    PaymentMethod: null,
    PookingSource: null,
    SpecialRequests: null,
    Guestsatisfaction: null,
    InventoryUsage: null,
    InventoryReplenishment: null,
    TreatmentSchedule: null,
    CancellationLog: null,
    NoShowRecords: null,
    ClientContactInformation: null,
    StaffScheduling: null,
    Price: null,
    TimeStamps: null,
    AccessControl: null,
    Inventory: null,
    UserDemographics: null,
    Purchasingprice: null,
    DailyRevenueAnalysis: null,
    RevenueStatistics: null,
    FeedbackReviews: null,
    RevenuewCompounded: null,
    MarketingPromotions: null,
    AllPercentageRevenues: null,
    Currency: "NGN",
    MethodOfPayment: null,
    AppointmentTime: null,
    TreatmentDuration: null,
    MembershipId: null
};