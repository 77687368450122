import React, { useEffect, useState } from 'react';

import './App.scss';
import WhiteHouseApps from './Components/Hms/WhiteHouseApps';
import { ThemeProvider, PartialTheme } from '@fluentui/react';
import {BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { AuthProvider } from './Components/Authentication/Auth';
import Login  from './Components/Authentication/Login';
import { ProvideAuth, useAuth } from './Components/Authentication/PrivateAuth';
import { PrivateRoute } from './Components/Authentication/PrivaterRout';
import Mobile from './Mobile/Mobile';


const appTheme: PartialTheme = {
  palette: {
    themePrimary: 'blue',
    // ...
  },
};


function App() {
  const [isMobile, setIsMobile] = useState(false);
  const storedIsLoggedIn = sessionStorage.getItem('isLoggedIn');
  const isLoggedIn = storedIsLoggedIn ? JSON.parse(storedIsLoggedIn) : false;


  useEffect(() => {
    setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    const storedIsLoggedIn = sessionStorage.getItem('isLoggedIn');
    console.log("SESSSION STATUS: ", storedIsLoggedIn);
  }, []);

  return (
    <ThemeProvider theme={appTheme}>
        <Router>
          <Routes>
          <Route
          path="/chms"
          element={isLoggedIn ? <PrivateRoute /> : <Navigate to="/" />}/>
            <Route path="/" element={<Login />} />
          </Routes>
        </Router>
    </ThemeProvider>
  );
}

export default App;


