import React, { useCallback, useEffect, useState } from "react";
import "./StaffManagement.scss";
import { Avatar, Table, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow, useTableFeatures, useTableSort, TableColumnDefinition, createTableColumn, TableBody } from '@fluentui/react-components';
import { DocumentRegular, EditRegular, FolderRegular, OpenRegular, PeopleRegular, DocumentPdfRegular, VideoRegular, Timer24Regular } from '@fluentui/react-icons';
import { WHITE_HOUSE_API } from "../../../../../Constants/Constant";
import axios from "axios";
import moment from 'moment';
import { GetEmployee } from "../../../../../Data/General/Employee";


  const getStatusColor = (statusLabel:any) => {
    switch (statusLabel) {
      case 'Working':
        return { backgroundColor: '#bdd99b', color: '#294903' };
      case 'Vacation':
        return { backgroundColor: '#4a9d3f2', color: '#004377' };
      case 'Sick Leave':
        return { backgroundColor: '#d9a7e0', color: '#4c0d55' };
      case 'Closed':
        return { backgroundColor: '#efc4ad', color: '#712d09' };
      default:
        return { backgroundColor: 'transparent', color: 'black' };
    }
};


type UserClockedInInfo = {
    employeeName: { label: string; icon: any };
    status: { label: string };
    start: { label: string };
    end: { label: any };
    Total: { label: string };
    // ... other properties
  };


  interface User {
    email: string,
    phoneNumber:string,
    token:string,
  }
type UserSelectedDate ={
  selectedDate?:Date; 
  user:User;
}


const StaffManagement: React.FC<UserSelectedDate> = ({selectedDate, user}) => {
  const [loggedInUser, setLoggedInUser] = useState<GetEmployee>();
    const [clockedInUser, setClockedInUser] = useState<UserClockedInInfo[]>([]);
    const TotalHoursofwork = "9hrs daily, 40hrs weekly";
    const LoggedInImage = loggedInUser?.photos;
    const columns: TableColumnDefinition<typeof clockedInUser[0]>[] = [
        createTableColumn({
            columnId: 'employeeName',
            compare: (a, b) => a.employeeName?.label?.localeCompare(b.employeeName?.label),
            renderCell: (item) => (
              <TableCellLayout media={item.employeeName?.icon}>
                {item.employeeName?.label}
              </TableCellLayout>
            ),
        }),
        
        
        createTableColumn({
          columnId: 'status',
          compare: (a, b) => a?.status?.label?.localeCompare(b?.status?.label),
          renderCell: (item) => {
            const colors = getStatusColor(item.status?.label);
            return (
              <span style={{ backgroundColor: colors.backgroundColor, color: colors.color }}>
                {item.status?.label}
              </span>
            );
          },
      }),
      
      createTableColumn({
        columnId: 'start',
        compare: (a, b) => a.start?.label?.localeCompare(b.start?.label),
        renderCell: (item) => (
          <TableCellLayout>
            {item.start?.label}
          </TableCellLayout>
        ),
      }),
      createTableColumn({
        columnId: 'end',
        compare: (a, b) => a.end?.label?.localeCompare(b.end?.label),
        renderCell: (item) => (
          <TableCellLayout>
            {item.end?.label || <Timer24Regular />}
          </TableCellLayout>
        ),
    }),
    
      createTableColumn({
        columnId: 'Total',
        compare: (a, b) => a.Total?.label?.localeCompare(b.Total?.label),
        renderCell: (item) => (
          <TableCellLayout>
            {item.Total?.label}
          </TableCellLayout>
        ),
      }),
      // ... other columns
    ];
    
    const fetchLoggedInUser = useCallback(async () => {
      try {
        const response = await axios.get(`${WHITE_HOUSE_API}/User/ActiveUser?phoneNumber=${user.phoneNumber}&email=${user.email}`, {
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        });
        setLoggedInUser(response.data);
        console.error('Error fetching clock-out status', response.data);
      } catch (error) {
        console.error('Error fetching clock-out status', error);
      }
    }, [user.email, user.phoneNumber, user.token]);

  const { getRows, sort: { getSortDirection, toggleColumnSort, sort } } = useTableFeatures({ columns, items: clockedInUser }, [useTableSort({ defaultSortState: { sortColumn: 'employeeName', sortDirection: 'ascending' } })]);
  const sortedRows = sort(getRows());

  const calculateTotalTimeWorked = (clockInTime:any, clockOutTime:any) => {
    if (clockInTime && clockOutTime) {
      const clockIn = moment(clockInTime, 'h:mm:ss A');
      const clockOut = moment(clockOutTime, 'h:mm:ss A');
      
      const difference = clockOut.diff(clockIn, 'hours', true);
      return difference.toFixed(2) + ' hours';
    } else {
      return null;
    }
  };

  const fetchUsers = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/ClockedInUsers/Clocked`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
      const users = response.data;
      const transformedUsers = users.map((user:any) => {
        const totalTimeworked = calculateTotalTimeWorked(user.clockInTime, user.clockOutTime);
        
        return {
          employeeName: { label: `${user.firstName} ${user.lastName}`, icon:  <Avatar name={`${user.firstName} ${user.lastName}`}
          image={{
            src: LoggedInImage,
          }}aria-label="Guest"/> },
          status: { label: user.status },
          start: { label: user.clockInTime },
          end: { label: user.clockOutTime },
          Total: { label: totalTimeworked ?? TotalHoursofwork },
        };
      });
  
      setClockedInUser(transformedUsers);
      console.log('Clocked In Users are:', transformedUsers);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  }, [LoggedInImage, user.token]);
  

  useEffect(() => {
    console.log("Select Date", selectedDate);
    fetchLoggedInUser();
    const fetchUserClockedInHistory = async () => {
      if(selectedDate && typeof selectedDate !== 'undefined'){
        try{
          const response = await axios.get(`${WHITE_HOUSE_API}/ClockedInUsers/history?date=${selectedDate}`, {
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
          });
          const users = response.data;
          const transformedUsers = users.map((user:any) => {
            const totalTimeworked = calculateTotalTimeWorked(user.clockInTime, user.clockOutTime);
         
            return {
              employeeName: { label: `${user.firstName} ${user.lastName}`, icon:  <Avatar name={`${user.firstName} ${user.lastName}`}
              image={{
                src: LoggedInImage,
              }}aria-label="Guest"/> },
              status: { label: user.status },
              start: { label: user.clockInTime },
              end: { label: user.clockOutTime },
              Total: { label: totalTimeworked ?? TotalHoursofwork },
            };
          });
      
          setClockedInUser(transformedUsers);
          console.log('Clocked In Users are:', transformedUsers);
        } catch (error) {
          console.error('Error fetching roles:', error);
        }
      } else {
        fetchUsers();
        const intervalId = setInterval(() => {
          fetchUsers();
        }, 60000); // 600000 milliseconds = 10 minutes
        // Clear interval on component unmount
        return () => clearInterval(intervalId);
      }
    }
    
  
    fetchUserClockedInHistory();
  }, [LoggedInImage, fetchLoggedInUser, fetchUsers, selectedDate, user.token]);
  
  

  return (
    <div className="docs-story css-kdwx3d">
      <div className="css-1wjen9k">
        <div className="css-90uvv4">
          <div className="innerZoomElementWrapper">
            
            <Table sortable aria-label="Sortable table">
              <TableHeader >
                <TableRow>
                  {columns.map(column => (
                    <TableHeaderCell key={column.columnId} onClick={(e) => toggleColumnSort(e, column.columnId)} sortDirection={getSortDirection(column.columnId)}>
                    {column.columnId === 'employeeName' ? 'FULL NAME' :
                     column.columnId === 'Total' ? 'SCHEDULED' :
                     column.columnId === 'end' ? 'CLOCKED OUT' :
                     column.columnId === 'start' ? 'CLOCKED IN' :
                     column.columnId === 'status' ? 'POSITION' :
                     column.columnId}
                  </TableHeaderCell>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {sortedRows.map(({ item }) => (
                  <TableRow key={item.employeeName.label}>
                    {columns.map(column => (
                      <TableCell key={column.columnId.toString()}>
                        {column.renderCell(item)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffManagement;
