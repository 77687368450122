import { IAssignment } from "./IAssignment";

export interface ITasksManager {
    taskId: number;
    taskName: string;
    taskDescription?: string;
    startDate?: Date;
    endDate?: Date;
    status?: string;
    priority?: string;
    dateadded?: Date;
    assignments: IAssignment[];
}

export const tasksManager: ITasksManager = {
    taskId: 0,
    taskName: "",
    taskDescription: "",
    startDate: new Date(),
    endDate: new Date(),
    status: "",
    priority: "",
    assignments: [] as IAssignment[],
};