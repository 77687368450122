export interface Restaurant {
    productId:number;
    productName: string;
    productDescription: string;
    ingredients: string;
    unitofmeasurement: string; 
    preparationTime: string;
    pervingSize: number;
    price: number;
    methodOfPayment: string;
    currency: string;
    timeStamps: number;
    purchasingprice: number;
    increaseinsalespercent: string;
    decreaseinsalespercent: string;
    amountcompounded: number;
    allPercentageSells: string;


}



export const initialState = {
    productName: '',
    productDescription: '',
    ingredients: '',
    unitofmeasurement: '',
    preparationTime: '',
    ServingSize: '',
    Price: '',
    MethodOfPayment: '',
    Currency: '',
    timeStamps: '',
    purchasingprice: '',
    increaseinsalespercent: '',
    decreaseinsalespercent: '',
    amountcompounded: '',
    allPercentageSells: '',

};

export const RestaurantItems: Restaurant = {
    productId: 0,
    productName: "",
    productDescription: "",
    ingredients: "",
    unitofmeasurement: "",
    preparationTime: "",
    price: 0,
    timeStamps: 0,
    purchasingprice: 0,
    increaseinsalespercent: "",
    decreaseinsalespercent: "",
    amountcompounded: 0,
    allPercentageSells: "",
    methodOfPayment: "",
    currency: "",
    pervingSize: 0
}

export interface KitchenProduct {
    productId: number;
    productName: string;
    productDescription: string;
    ingredients: string;
    quantity: string;
    servingSize: number;
    price: number;
    timeStamps: number;
    purchasingprice: number;
    increaseinsalespercent: string;
    decreaseinsalespercent: string;
    amountcompounded: number;
    allPercentageSells: string;
    currency: string;
    methodOfPayment: string;
    preparationTime: string;
    dateadded: Date;
}


export const InitKitchenProduct: KitchenProduct = {
    productId: 0,
    productName: "",
    productDescription: "",
    ingredients: "",
    quantity: "",
    servingSize: 0,
    price: 0,
    timeStamps: 0,
    purchasingprice: 0,
    increaseinsalespercent: "",
    decreaseinsalespercent: "",
    amountcompounded: 0,
    allPercentageSells: "",
    currency: "",
    methodOfPayment: "",
    preparationTime: "",
    dateadded: new Date()
};

