import React, { useCallback, useEffect, useState } from "react";
import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Button,
    Label,
    useId,
    Input,
    makeStyles,
    Divider,
    Field,
    Combobox,
    Option,
    Text,
    Persona,
    Dropdown,
  } from '@fluentui/react-components';
import "./DepartmentForm.scss";
import { IDynamic } from "../../../../Data/General/IDynamic";
import { IBarLoungeMenu, InitialIBar } from "../../../../Data/General/BarMenuLounge";
import Loaders from "../../../Common/Loader/Loader";
import DispatchToast from "../../../Common/Toast/DispatchToast";
import { BarLoungeCategory, IBarCategory, InitialIBarCat } from "../../../../Data/General/IBarCategory";
import axios from "axios";
import { WHITE_HOUSE_API } from "../../../../Constants/Constant";
import { HotelEventCenter, hotelEventCenter } from "../../../../Data/General/HotelEventCenter";
import { Amenity } from "../../../../Data/General/Amenities";
import { AmenityType } from "../../../../Data/General/AmenityTypes";
import { DatePicker } from '@fluentui/react-datepicker-compat';
import { IDepartment, initializeDepartment } from "../../../../Data/General/Department";
import { IUnit } from "../../../../Data/General/Unit";
import { ILocation } from "../../../../Data/General/BusinesLocation";
import { GetEmployee } from "../../../../Data/General/Employee";



  interface User {
    email: string,
    phoneNumber:string,
    token:string,
  }
  interface Message {
    message: string,
    id:number,
  }
    // other properties...
  interface BarCatsProps {
    onClose: () => void;
    message: Message;
    user: User;
  }
  const useStyles = makeStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '400px',
    },
    control: {
      maxWidth: '300px',
    },
  });
  
  

const DepartmentForm:React.FC<BarCatsProps> = ({ onClose, message, user}) =>{
    const [dispatchToaster, setDispatchToaster] = useState<boolean>(false);
    const [selectedInvoicee, setSelectedInvoicee] = useState<number>();
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [results, setResults] = useState<IDynamic[]>([]);
    const [selectedService, setSelectedService] = useState<string | null>(null);
    const [unitMapping, setUnitMapping] = useState<IUnit[]>([]);
    const [locationMapping, setLocationMapping] = useState<ILocation[]>([]);
    const [apiData, setApiData] = useState<string[]>([]);
    const [registerDepartment, setRegisterDepartment] = useState<IDepartment>(initializeDepartment)
    const [getExistingBarcategory, setgetExistingBarcategory] = useState<HotelEventCenter>();
    const [adjuster, setAdjuster] = useState<GetEmployee>();
    const [input, setInput] = useState('');
    const inputId = useId('input');
    const styles = useStyles();
    const [currency, setCurrency] = useState('NGN');
    const currencySymbol = currency === 'NGN' ? '₦' : '$';



    // const handleDepartmentEventChange = (
    //     event: React.ChangeEvent<HTMLInputElement>
    //   ) => {
    //     const { name, value } = event.target;
    //     setCreatedEventService((prevState) => ({ ...prevState, [name]: value }));
    //   };
     



    const handleDepartmentEventChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const { name, value } = event.target;
      setRegisterDepartment((prevState) => ({ ...prevState, [name]: value }));
    };
    
      const HandleSubmitData = async () => {
       try {
          setIsLoader(true);
          const response = await axios.post(
            `${WHITE_HOUSE_API}/Department`,
            registerDepartment,
            {
              headers: {
                'Authorization': `Bearer ${user.token}`
              }
            }
          );
          if (response.data) {
            setTimeout(() => {
              setIsLoader(false);
            }, 1200);
      
            if (response.data.status === 'Success') {
              setResponseMessage(response.data.eMessage);
              setDispatchToaster(true);
              setTimeout(() => {
                setDispatchToaster(false);
              }, 1500);
            }
      
            setRegisterDepartment(registerDepartment);
          }
        } catch (error) {
          setResponseMessage('Registration ' + error);
          setDispatchToaster(true);
          setTimeout(() => {
            setDispatchToaster(false);
            setIsLoader(false);
          }, 1200);
          console.error('Error fetching roles:', error);
        }
      };

      function formatDate(dateString: Date): string {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero indexed, so +1 and pad with 0
        const day = ("0" + date.getDate()).slice(-2); // Pad with 0
        return `${year}-${month}-${day}`;
      }
      
      const handleEventCenterDateInputChange = (name:any, date: Date | null | undefined) => {
        if (date) {
          let formatedDate = formatDate(date);
          setRegisterDepartment((prevState) => ({
            ...prevState,
            [name]: formatedDate,
          }));
        }
      };

      const fetchExistingDeapartment = useCallback(async () => {
        try {
          const response = await axios.get(`${WHITE_HOUSE_API}/Department/${message.id}`, {
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
          });
          setRegisterDepartment(response.data);
        } catch (error) {
          console.error(error);
        }
      }, [message, user.token]);
      
     

      const handleUnitChange = async (name:string, event:any) => {
        //get the id
        const service = event.target.innerText;
        const typeid = unitMapping.find(c => c.unitName === service);
        setRegisterDepartment((prevState) => ({ ...prevState, [name]: typeid?.unitName }));
        setRegisterDepartment((prevState) => ({ ...prevState, unitId: typeid?.unitId }));
      }
  


      const handleLocationChange = async (name:string, event:any) => {
        //get the id
        const service = event.target.innerText;
        const typeid = locationMapping.find(c => c.city+ " " + c.state+ " " + c.country === service);
        if(typeid){
          setRegisterDepartment((prevState) => ({ ...prevState, [name]: service }));
          setRegisterDepartment((prevState) => ({ ...prevState, locationId: typeid?.locationId }));
        }
    
      }
      const handleCurrencyChange = async (name:string, event:any) => {
        const currency = event.target.innerText;
        setCurrency(currency);
        setRegisterDepartment((prevState) => ({ ...prevState, [name]: currency }));
      }

      const fetchUnts = useCallback(async () => {
        try {
            const response = await axios.get(`${WHITE_HOUSE_API}/Unit`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                },
            });
            const units = response.data;
            setUnitMapping(units);
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    }, [user.token]);


    const fetchLocations = useCallback(async () => {
      try {
          const response = await axios.get(`${WHITE_HOUSE_API}/Location`, {
              headers: {
                  'Authorization': `Bearer ${user.token}`
              },
          });
          const locate = response.data;
          setLocationMapping(locate);
      } catch (error) {
          console.error('Error fetching roles:', error);
      }
  }, [user.token]);
    
    useEffect(() => {
        fetchUnts();
        fetchLocations();
        if(message.id > 0)
        {
          fetchExistingDeapartment();
        }
      }, [fetchLocations, fetchUnts, message.id, fetchExistingDeapartment]); //
     
    return (
        <>
          <div className="fui-FluentProvider fui-FluentProvider361 ___1bsm0mz f19n0e5 fxugw4r f1o700av fk6fouc fkhj508 figsok6 f1i3iumi f1euv43f f15twtuk f1vgc2s3 f1e31b4d f494woh">
      <div
        aria-hidden="true"
        className="fui-DialogSurface__backdrop ___adeqq90 f19dog8a fju19wo f113wtx2 f10k790i f5gq2j6 f1xynx9j"
      ></div>

      <div className="fui-DialogSurface ___1g5txne ftgm304 f1c515w f1bitti f15twtuk f1e31b4d f1yab3r1 f1vgc2s3 fuq56rw f15kemlc fl2zwns fdgang7 fgr6219 f1ujusj6 f10jk5vf fcgxt0o fzkkow9 fcdblym fg706s2 fjik90z f1ln0qer fa2wlxz f120kxnn f19dog8a f3052tw fvgz9i8 f6a9g1z f1ewtqcl f10nrhrw fxugw4r f19n0e5 f5ogflp f1hqa2wf f1f09k3d finvdd3 fghlq4f f1gn591s fb073pr fjscplz fnivh3a fc7yr5o f1el4m67 f8yange ff54dml ftqa4ok f2hkw1w f8hki3x f1d2448m f1bjia2o ffh67wi f15bsgw9 f14e48fq f18yb2kv fd6o370 fh1cnn4 fy7oxxb fpukqih f184ne2d frrh606 f1v5zibi ful5kiu fo2hd23 f1jqcqds ftffrms f2e7qr6 fsr1zz6 f1dvezut fd0oaoj fjvm52t f1cwg4i8 f57olzd f4stah7 f480a47 fs1por5">
        <div className="fui-DialogBody ___6dxt060 f13qh94s f120kxnn flnwrvu f1ewtqcl f1ln0qer fa2wlxz f4px1ci fn67r4l f1x46k84 fjj47a5 ff54dml f1t97isy ">
          {isLoader && (
            <div className="restrict-the-inner">
              <Loaders />
            </div>
          )}
          {dispatchToaster && <DispatchToast message={responseMessage} />}
          <div className="header-content">
            <h2
              id="dialog-title-363"
              className="fui-DialogTitle  ___wfyv5z0 f16hsg94 f1amvztq fwpfdsa fsyjsko fk6fouc f1pp30po fl43uef f106mvju f1hu3pq6 f11qmguv f19f4twv f1tyq0we"
            >
              Register Department
            </h2>
          </div>

          <div className="fui-DialogContent  ___1dce55l f5zp4f f1nxs5xn f1ewtqcl faunodf f88nxoq fwpfdsa fsyjsko fr4fn7s f171n1a0 f1ean75l f1jwbs5a fxlnl9e ffs23cn f2uu7rc f1v5tmjd fk6fouc fkhj508 figsok6 f1i3iumi customScrollBar">
            <h3 className="user-initiating">Adjuster here</h3>
            <div className="First-form-flex-inoice">
              <div className={styles.root}>
                <Label htmlFor={inputId}>Department Name</Label>
                <Input
                  id="departmentName"
                  value={registerDepartment.departmentName || ""}
                  name="departmentName"
                  placeholder="Name of Department"
                  onChange={handleDepartmentEventChange}
                />
              </div>
              <div className={styles.root}>
                    <Label htmlFor={inputId}>Discriptions</Label>
                    <Input
                  id={inputId}
                  value={registerDepartment.description || ""}
                  name="description"
                  placeholder="Enter  discription"
                  onChange={handleDepartmentEventChange}
                />
                </div>
            </div>
            <div className="First-form-flex-inoice event-services">
              <div className={styles.root}>
                <Label htmlFor={inputId}>Location</Label>
                <Dropdown
                    placeholder="Select a Location"
                    appearance="filled-darker"
                    name="location"
                    onOptionSelect={(item) => handleLocationChange("location", item)}
                >
                    {locationMapping?.map((item) => (
                        <Option key={item.locationId} value={`${item.city} ${item.state}  ${item.country}`} text={`${item.city} ${item.state} ${item.country}`}>{`${item.city}  ${item.state} ${item.country}`}</Option>
                    ))}
                </Dropdown>

              </div>
              <div className={styles.root}>
                    <Label htmlFor={inputId}>Unit</Label>
                    <Dropdown
                    placeholder="Select a Unit"
                    appearance="filled-darker"
                    name="unit"
                    onOptionSelect={(item) => handleUnitChange("unit", item)}
                >
                    {unitMapping?.map((item) => (
                        <Option key={item.unitId} value={item.unitName} text={item.unitName}>{item.unitName}</Option>
                    ))}
                </Dropdown>

                                      
                </div>
            </div>
           
              {/* <div className="First-form-flex-inoice event-services">
              <div className={styles.root}>
                <Label htmlFor={inputId}>Price</Label>
                <Input
                  id={inputId}
                  value={registerDepartment. || ""}
                  name="categoryName"
                  placeholder="category name"
                  onChange={handleDepartmentEventChange}
                />
                 
              </div>
              <div className={styles.root}>
              <Label htmlFor={inputId}>Currency</Label>
              <Dropdown
                  placeholder="Select a Currency"
                  appearance="filled-darker"
                  name="currency"
                  id="currency"
                  onOptionSelect={(item) => handleCurrencyChange("currency", item)}
              >
                  <Option key="1" value="USD">USD</Option>
                  <Option key="2" value="NGN">NGN</Option>
              </Dropdown>

                </div>
            </div> */}
            {/* <div className="First-form-flex-inoice">
              <div className={styles.root}>
                <Label htmlFor={inputId}>Booking Start Date</Label>
                <Field>
                                                  <DatePicker
                                                    name="bookingStartDate"
                                                    showWeekNumbers={true}
                                                    firstWeekOfYear={1}
                                                    showMonthPickerAsOverlay={
                                                      true
                                                    }
                                                    placeholder="Start Date..."
                                                    className={styles.control}
                                                    onSelectDate={(event) => 
                                                      handleEventCenterDateInputChange("bookingStartDate", event)
                                                    }
                                                  />
                                                </Field>
              </div>
              <div className={styles.root}>
                    <Label htmlFor={inputId}>Booking End Date</Label>
                    <Field>
                       <DatePicker
                           name="bookingEndDate"
                               showWeekNumbers={true}
                                   firstWeekOfYear={1}
                                      showMonthPickerAsOverlay={
                                         true
                                          }
                                           placeholder="End Date..."
                                                    className={styles.control}
                                                    onSelectDate={(event) => 
                                                      handleEventCenterDateInputChange("bookingEndDate", event)
                                                    }
                                                  />
                                                </Field>
                </div>
            </div> */}
            <div className="First-form-flex-inoice">
              <div className={styles.root}>
                <Label htmlFor={inputId}>Contact Email</Label>
                <Input
                  id="email"
                  value={registerDepartment.email || ""}
                  name="email"
                  placeholder="Contact person email"
                  onChange={handleDepartmentEventChange}
                />
              </div>
              <div className={styles.root}>
                    <Label htmlFor={inputId}>Contact Phone</Label>
                    <Input
                  id={inputId}
                  value={registerDepartment.phone || ""}
                  name="phone"
                  placeholder="Contact Person phone"
                  onChange={handleDepartmentEventChange}
                />
                </div>
            </div>
            {/* <div className="First-form-flex-inoice">
              <div className={styles.root}>
                <Label htmlFor={inputId}>Contact  Email</Label>
                <Input
                 type="email"
                  id="contactEmail"
                  value={registerDepartment.contactEmail || ""}
                  name="contactEmail"
                  placeholder="Contact person email"
                  onChange={handleDepartmentEventChange}
                />
              </div>
              <div className={styles.root}>
                    <Label htmlFor={inputId}>Contact phone</Label>
                    <Input
                  id={inputId}
                  value={registerDepartment.contactPhone || ""}
                  name="contactPhone"
                  placeholder="Contact Person phone"
                  onChange={handleDepartmentEventChange}
                />
                </div>
            </div> */}
        {/*      <div className="First-form-flex-inoice">
             <div className={styles.root}>
                <Label htmlFor={inputId}>Upload Image</Label>
                <span className="fui-Input r1jtohuq ___1v9icnz_137yv9i fvcxoqz f1ub3y4t f1l4zc64 f1m52nbi f8vnjqi fz1etlk f1klwx88 f1hc16gm">
                <input
                    id={inputId}
                    name="photos"
                    type="file"
                    placeholder="choose a file"
                    onChange={handleDepartmentEventChange}
                    accept=".jpg,.jpeg,.png"
                  />
              </span>
              </div> */}
                {/* <div className={styles.root}>
                  <Label htmlFor={inputId}>Discriptions</Label>
                    <Input
                  id={inputId}
                  value={registerDepartment.eventt || ""}
                  name="eventType"
                  placeholder="Enter event type"
                  onChange={handleDepartmentEventChange}
                /> 
                </div>
            </div>*/}
          </div>
          <div className="fui-DialogActions ___1mdwomm f3052tw f1ewtqcl f22iagw f1cztu7b fcu02xx f4px1ci fn67r4l f6glcwc f1e3st1r f1a7i8kp fd46tj4 fsyjsko f1f41i0t f1jaqex3 f2ao6jk">
            <Button className="Button-Submit" onClick={HandleSubmitData}>
              Add Service
            </Button>
            <Button className="Button-close" onClick={onClose}>
              cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
</>
    )
}

export default DepartmentForm;