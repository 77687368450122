export interface Amenity {
    amenityId: number;
    amenityName: string;
    description?:string;
    adjuster?:string;
    roomNumber?: number;
    roomId?: number;
    eventCenterId?: number;
    amenityTypeId?:number;
}

export interface IAmenity {
    amenityId: number;
    amenityName: string;
    description?:string;
    adjuster?:string;
    roomNumber?: number;
    roomId?: number;
    eventCenterId?: number;
    amenityTypeId?:number;
    dateadded: Date;
    updated?:Date;
}

export const InitialAmenity:Amenity = {
    amenityId: 0,
    amenityName: "",
    description: "",
    adjuster: "",
    roomNumber: 0,
    roomId: 0,
    amenityTypeId:0,
    eventCenterId: 0,
}