import { ApexOptions } from 'apexcharts';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { WHITE_HOUSE_API } from '../../../../Constants/Constant';


interface User {
  email: string;
  phoneNumber: string;
  token: string;
  // other properties...
}
type ApexChartProps = {
  loadAdultCounts: number;
  loadCheckedOutCounts: number;
  loadNotSpacifiedCounts: number;
  loadChildrenCounts: number;
  loadJustArrivedCounts: number;
  user: User;
};

const Donuts: React.FC<ApexChartProps> = (props) => {
  // const [loadAdultCounts, setLoadAdultCounts] = useState<number>(0);
  // const [loadChildrenCounts, setLoadChildrenCounts] = useState<number>(0);
  // const [loadCheckedOutCounts, setLoadCheckedOutCounts] = useState<number>(0);
  // const [loadJustArrivedCounts, setLoadJustArrivedCounts] = useState<number>(0);
  // const [loadNotSpacifiedCounts, setLoadNotSpacifiedCounts] = useState<number>(0);
  const [series, setSeries] = React.useState([props.loadAdultCounts, props.loadCheckedOutCounts, props.loadNotSpacifiedCounts, props.loadChildrenCounts, props.loadJustArrivedCounts]);

/*****
  const fetchAdulCounts = useCallback(async () => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Checkin/WithAdultsTodayCount`,
        {
          headers: {
            Authorization: `Bearer ${props.user.token}`,
          },
        }
      );
      setLoadAdultCounts(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [props.user.token]);

  const fetchChildrenCounts = useCallback(async () => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Checkin/WithChildrenTodayCount`,
        {
          headers: {
            Authorization: `Bearer ${props.user.token}`,
          },
        }
      );
      setLoadChildrenCounts(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [props.user.token]);

  const fetchJustDepartedCounts = useCallback(async () => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Checkin/JustDepartedCount`,
        {
          headers: {
            Authorization: `Bearer ${props.user.token}`,
          },
        }
      );
      setLoadCheckedOutCounts(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [props.user.token]);

  const fetchJustArrivedCount = useCallback(async () => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Checkin/JustArrivedCount`,
        {
          headers: {
            Authorization: `Bearer ${props.user.token}`,
          },
        }
      );
      setLoadJustArrivedCounts(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [props.user.token]);

  const fetchNotSpecifiedTodayCount = useCallback(async () => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Checkin/NotSpecifiedTodayCount`,
        {
          headers: {
            Authorization: `Bearer ${props.user.token}`,
          },
        }
      );
      setLoadNotSpacifiedCounts(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [props.user.token]);
***** */


  const total = series.reduce((a, b) => a + b, 0);

  useEffect(() => {
    console.log("Total Donot", props.loadAdultCounts, props.loadCheckedOutCounts, props.loadNotSpacifiedCounts, props.loadChildrenCounts, props.loadJustArrivedCounts);
    setSeries([props.loadAdultCounts, props.loadCheckedOutCounts, props.loadNotSpacifiedCounts, props.loadChildrenCounts, props.loadJustArrivedCounts]);
  }, [props.loadAdultCounts, props.loadCheckedOutCounts, props.loadChildrenCounts, props.loadJustArrivedCounts, props.loadNotSpacifiedCounts]);

  const [options, setOptions] = React.useState<ApexOptions>({
    chart: {
      type: 'donut',
      background: 'transparent',
    },
    labels: ['Adult', 'Checked Out', 'Not Spacified', 'Children', 'Just Arrived'],
    colors: ['rgb(69, 84, 111)', 'rgb(234, 163, 0)', 'rgb(79, 82, 178)', 'rgb(59, 226, 59)', 'rgb(236, 81, 85)'], // add custom colors here
    dataLabels: {
      enabled: false
    },
    fill: {
      type: 'gradient',
    },
    stroke: {
      show: false,
      width: 0
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              //label: total.toString(), // display the total in the center of the chart
              formatter: function (w: any) {
                return w.globals.seriesTotals.reduce((a: any, b: any) => a + b, 0);
              }
            }
          }
        }
      }
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ]
  });

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="donut" />
    </div>
  );
};

export default Donuts;
