export interface ITitle {
  titleId:number;
  titleName: string;
  }
  
  
  export interface Title {
    titleId: number;
    titleName: string;
    description:string;
    dateadded: Date | null;
}

export const initializeTitles: Title = {
  titleId: 0,
  titleName: "",
  description: "",
  dateadded: new Date(),
};
