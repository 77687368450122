export interface IGender {
    GenderId: number;
    GenderName: string | null;
    Description: string | null;
}


export interface Gender {
    genderId: number;
    genderName: string;
    description: string;
    dateadded:Date;
}

export const initializeGenders: Gender = {
    genderId: 0,
    genderName: "",
    description: "",
    dateadded: new Date(),
  };