import React, { useCallback, useEffect, useState } from 'react';
import './Payment.scss';
import {
  Button,
  Field,
  Input,
  InputOnChangeData,
  Label,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Radio,
  RadioGroup,
  Select,
  Tab,
  TabList,
  TableHeader,
  Text,
} from '@fluentui/react-components';
import visaLogo from '../../Themes/Images/Visa.svg';
import mastercardLogo from '../../Themes/Images/Master.svg';
import verveLogo from '../../Themes/Images/Verve-logo-dark.svg';
import None from '../../Themes/Images/None.svg';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import {
  ChevronLeft24Regular,
  ChevronRight24Regular,
  WalletCreditCard24Regular,
} from '@fluentui/react-icons';
import {
  Avatar,
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeaderCell,
  TableCellLayout,
} from '@fluentui/react-components';
import { SearchBox } from '@fluentui/react-search-preview';
import { ITransaction } from '../../../Data/General/Transactions';
import { WHITE_HOUSE_API } from '../../../Constants/Constant';
import axios from 'axios';
import { GetEmployee } from '../../../Data/General/Employee';

const itemsPerPage = 10;
interface User {
  email: string;
  phoneNumber: string;
  token: string;

  // other properties...
}

type PayProps = {
  user: User;
};
const Payment: React.FC<PayProps> = ({user}) => {
  const [currency, setCurrency] = useState('NGN');
  const currencySymbol = currency === 'NGN' ? '₦' : 'US$';
  const [expiryDate, setExpiryDate] = useState('');
  const [amount, setAmount] = useState<number>(0);
  const [total, setTotal] = useState<any>(0);
  const [adminFess, setAdminFess] = useState<any>(0);
  const [vateFees, setvateFees] = useState<any>(0);
  const [cardType, setCardType] = useState(None);
  const [selectedTab, setSelectedTab] = useState('BankCard');
  const [transaction, setTransactions] = useState('Credit');
  const [historicalData, setHistricalData] = useState<ITransaction[]>([]);
  const [adjuster, setAdjuster] = useState<GetEmployee>();
  const [currentPage, setCurrentPage] = useState(0);

  const pageCount = Math.ceil(historicalData.length / itemsPerPage);
  const currentData = historicalData.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );



  const fetchAdjuster = useCallback(async () => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Adjuster/${user.phoneNumber}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const adjuster = response.data;
      setAdjuster(adjuster);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  }, [user.phoneNumber, user.token]);



  const handleTabClick = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    setSelectedTab(value);
  };
  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAmount = parseFloat(event.target.value);
    if (!isNaN(newAmount)) {
      setAmount(newAmount);
      const vat = newAmount * 0.075; // 7.5% VAT
      const fees = newAmount * 0.025; // 2.5% fees
      setAdminFess(fees);
      setvateFees(vat);
      setTotal(newAmount + fees);
    } else {
      setAmount(0);
      setTotal(0);
    }
  };

  const handleExpiryDateChange = (event: any) => {
    let value = event.target.value;
    if (value.length === 2 && expiryDate.length === 1) {
      value += '/';
    }
    if (value.length <= 7) {
      setExpiryDate(value);
    }
  };
  const handleCurrencyChange = (event: any) => {
    const option = event.target.value;
    setCurrency(option);
  };

  
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    const cardNumber = data.value || '';
    let type = '';
    if (cardNumber.startsWith('4')) {
      type = visaLogo;
    } else if (cardNumber.startsWith('5')) {
      type = mastercardLogo;
    } else if (cardNumber.startsWith('6')) {
      type = verveLogo;
    } else {
      type = None;
    }
    setCardType(type);
  };

  const handleLeftTabClick = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    setTransactions(value);
  };



  useEffect(() => {
    fetchAdjuster();
  }, [fetchAdjuster]);

  
  return (
    <>
      <div className="aside-left">
        <div className="aside-account">
          <div className="block-user">
            <div className="user">
              <span data-v-f9fe4bbc="" className="label">
                Hi,
              </span>
              <span data-v-f9fe4bbc="" className="value">
                {`${adjuster?.firstName}  ${adjuster?.lastName}`}
              </span>
            </div>
            <div data-v-f9fe4bbc="" className="balance">
              <div data-v-f9fe4bbc="" className="label">
                Total Transactions
              </div>
              <div data-v-f9fe4bbc="" className="value">
              &#8358; 0.00
              </div>
            </div>
          </div>
          <div data-v-f9fe4bbc="" className="block">
            <span
              onClick={(event) => handleLeftTabClick(event, 'Credit')}
              data-v-574df748=""
              data-v-f9fe4bbc=""
              aria-current="page"
              className={`link-item router-link-exact-active router-link-active ${
                transaction === 'Credit' ? 'active' : ''
              } `}
            >
              <div data-v-f9fe4bbc="">Credit Transactions</div>
              <Button
                onClick={(event) => handleLeftTabClick(event, 'Credit')}
                icon={<ChevronRight24Regular />}
                className="link-item-right"
              ></Button>
            </span>
            <span
              onClick={(event) => handleLeftTabClick(event, 'Debit')}
              data-v-574df748=""
              data-v-f9fe4bbc=""
              aria-current="page"
              className={`link-item router-link-exact-active router-link-active ${
                transaction === 'Debit' ? 'active' : ''
              } `}
            >
              <div data-v-f9fe4bbc="">Debit Transactions</div>
              <Button
                onClick={(event) => handleLeftTabClick(event, 'Debit')}
                icon={<ChevronRight24Regular />}
                className="link-item-right"
              ></Button>
            </span>
          </div>
          <div data-v-f9fe4bbc="" className="block">
            <span
              onClick={(event) => handleLeftTabClick(event, 'MyTransaction')}
              data-v-574df748=""
              data-v-f9fe4bbc=""
              aria-current="page"
              className={`link-item router-link-exact-active router-link-active ${
                transaction === 'MyTransaction' ? 'active' : ''
              } `}
            >
              <div data-v-f9fe4bbc="">My Transactions</div>
              <Button
                onClick={(event) => handleLeftTabClick(event, 'History')}
                icon={<ChevronRight24Regular />}
                className="link-item-right"
              ></Button>
            </span>
            <span
              data-v-574df748=""
              data-v-f9fe4bbc=""
              aria-current="page"
              className={`link-item router-link-exact-active router-link-active ${
                transaction === 'History' ? 'active' : ''
              } `}
            >
              <div data-v-f9fe4bbc="">Transaction History</div>
              <Button
                icon={<ChevronRight24Regular />}
                className="link-item-right"
              ></Button>
            </span>
          </div>
          <div data-v-f9fe4bbc="" className="block">
            <span
              data-v-574df748=""
              data-v-f9fe4bbc=""
              aria-current="page"
              className="link-item router-link-exact-active router-link-active"
            >
              <div data-v-f9fe4bbc="">My Transactions</div>
              <Button
                icon={<ChevronRight24Regular />}
                className="link-item-right"
              ></Button>
            </span>
            <span
              data-v-574df748=""
              data-v-f9fe4bbc=""
              aria-current="page"
              className="link-item router-link-exact-active router-link-active"
            >
              <div data-v-f9fe4bbc="">Transaction History</div>
              <Button
                icon={<ChevronRight24Regular />}
                className="link-item-right"
              ></Button>
            </span>
          </div>
          <div data-v-f9fe4bbc="" className="block wcm">
            <span
              data-v-574df748=""
              data-v-f9fe4bbc=""
              aria-current="page"
              className="link-item router-link-exact-active router-link-active"
            >
              <div data-v-f9fe4bbc="">Settings</div>
              <Button
                icon={<ChevronRight24Regular />}
                className="link-item-right"
              ></Button>
            </span>
          </div>
        </div>
      </div>
      <div className="wc-fragment">
        {transaction === 'Credit' && (
          <div className="deposit-warp main">
            <div className="nav-bar-wrap top-tabs white">
              <TabList defaultSelectedValue="BankCard">
                <Tab
                  value="BankCard"
                  onClick={(event) => handleTabClick(event, 'BankCard')}
                >
                  Bank Card
                </Tab>
                <Tab
                  value="BankAccount"
                  onClick={(event) => handleTabClick(event, 'BankAccount')}
                >
                  Bank Account
                </Tab>
                <Tab
                  value="BankTransfer"
                  onClick={(event) => handleTabClick(event, 'BankTransfer')}
                >
                  Bank Transfer
                </Tab>
                <Tab
                  value="Paypal"
                  onClick={(event) => handleTabClick(event, 'Paypal')}
                >
                  Paypal
                </Tab>
              </TabList>
            </div>
            <div className="position-relative">
              <div className="m-deposit-card m-deposit-way">
                {selectedTab === 'BankCard' && (
                  <div className="deposit-content">
                    <div className="curency-payment-method">
                      <div className="currency-rate">
                        <Label>Choose a currency</Label>
                        <RadioGroup
                          defaultValue="NGN"
                          onChange={handleCurrencyChange}
                          aria-labelledby="ageLabel"
                          className="wc-flex"
                        >
                          <Radio value="NGN" label="NGN" />
                          <Radio value="USD" label="USD" />
                        </RadioGroup>
                      </div>
                      <div className="methos-of">
                        <Label>Your payment method</Label>
                        <div className="methods-of-payments">
                          <span>
                            <span className="cardimage" data-testid="VISA">
                              <img src={visaLogo} alt="" />
                            </span>
                          </span>
                          <span>
                            <span className="cardimage" data-testid="MASTERCARD">
                              <img src={mastercardLogo} alt="" />
                            </span>
                          </span>
                          <span>
                            <span className="cardimage" data-testid="Verve">
                              <img src={verveLogo} alt="" />
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="w-add-Guest-room Guest-management-job ">
                      <div className="w-add-room-guest rms-block-left">
                        <Label> Card Number</Label>
                        <div className="firstName-container">
                          <Input
                            placeholder="Card Number"
                            type="number"
                            onChange={handleInputChange}
                            contentAfter={<img src={cardType} alt="" />}
                          />
                        </div>
                      </div>

                      <div className="w-add-room-guest rms-block-right wc-margin">
                        <div className="w-add-room-guest rms-inline-property-title wc-margin">
                          Expiring Date
                        </div>
                        <div className="firstName-container">
                          <Input
                            type="text"
                            name="expiryDate"
                            placeholder="MM/YYYY"
                            value={expiryDate}
                            onChange={handleExpiryDateChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-add-Guest-room Guest-management-job">
                      <div className="w-add-room-guest rms-block-left">
                        <div className="w-add-room-guest Guest-inline-property-title">
                          CVV
                        </div>
                        <div className="firstName-container">
                          <Input
                            placeholder="CVV"
                            appearance="filled-darker"
                            type="password"
                            id="filledDarkerId"
                          />
                        </div>
                      </div>

                      <div className="w-add-room-guest rms-block-right wc-margin">
                        <div className="w-add-room-guest rms-inline-property-title wc-margin">
                          Amount
                        </div>
                        <div className="firstName-container">
                          <Input
                            placeholder="Amount to Credit"
                            type="number"
                            value={amount.toString()}
                            onChange={handleAmountChange}
                            contentBefore={
                              <Text size={400} id="beforeLabelId-400">
                                {currencySymbol}
                              </Text>
                            }
                            contentAfter={
                              <Text size={400} id="afterLabelId-400">
                                .00
                              </Text>
                            }
                            appearance="filled-darker"
                            id="filledDarkerId-02"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="Button-content-container">
                      <Button icon={<WalletCreditCard24Regular />}>
                        Pay : {currencySymbol}{' '}
                        {new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(total)}
                      </Button>
                    </div>
                  </div>
                )}
                {selectedTab === 'BankAccount' && (
                  <div className="deposit-content">
                    <div className="curency-payment-method">
                      <div className="currency-rate">
                        <Label>Choose a currency</Label>
                        <RadioGroup
                          defaultValue="NGN"
                          onChange={handleCurrencyChange}
                          aria-labelledby="ageLabel"
                          className="wc-flex"
                        >
                          <Radio value="NGN" label="NGN" />
                          <Radio value="USD" label="USD" />
                        </RadioGroup>
                      </div>
                      <div className="methos-of">
                        <Label>Your payment method</Label>
                        <div className="methods-of-payments">
                          <span>
                            <span className="" data-testid="VISA">
                              <img src={visaLogo} alt="" />
                            </span>
                          </span>
                          <span>
                            <span className="" data-testid="MASTERCARD">
                              <img src={mastercardLogo} alt="" />
                            </span>
                          </span>
                          <span>
                            <span className="" data-testid="Verve">
                              <img src={verveLogo} alt="" />
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="select-ALl m-card-default-form">
                      <div className="deposit-form-item">
                        <div
                          data-v-4666fd94=""
                          className="deposit-form-item-label"
                        >
                          Select bank
                        </div>
                        <div className="section-item-wrap">
                          <Select id="selectId">
                            <option>Select Bank....</option>
                            <option>Kuda Bank</option>
                            <option>GTBank PlC</option>
                            <option>Access Bank</option>
                          </Select>
                        </div>
                      </div>

                      <div className="deposit-form-item">
                        <div
                          data-v-4666fd94=""
                          className="deposit-form-item-label amount-marked"
                        >
                          Amount
                        </div>
                        <div className="section-item-wrap border-style">
                          <Input
                            placeholder="Amount to Credit"
                            type="number"
                            value={amount.toString()}
                            onChange={handleAmountChange}
                            contentBefore={
                              <Text size={400} id="beforeLabelId-400">
                                {currencySymbol}
                              </Text>
                            }
                            contentAfter={
                              <Text size={400} id="afterLabelId-400">
                                .00
                              </Text>
                            }
                            appearance="filled-darker"
                            id="filledDarkerId-02"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="Button-content-container">
                      <Button icon={<WalletCreditCard24Regular />}>
                        Pay : {currencySymbol}{' '}
                        {new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(total)}
                      </Button>
                    </div>
                  </div>
                )}

                {selectedTab === 'BankTransfer' && (
                  <div className="deposit-content">
                    <div className="curency-payment-method">
                      <div className="currency-rate">
                        <Label>Choose a currency</Label>
                        <RadioGroup
                          defaultValue="NGN"
                          onChange={handleCurrencyChange}
                          aria-labelledby="ageLabel"
                          className="wc-flex"
                        >
                          <Radio value="NGN" label="NGN" />
                          <Radio value="USD" label="USD" />
                        </RadioGroup>
                      </div>
                      <div className="methos-of">
                        <Label>Your payment method</Label>
                        <div className="methods-of-payments">
                          <span>
                            <span className="" data-testid="VISA">
                              <img src={visaLogo} alt="" />
                            </span>
                          </span>
                          <span>
                            <span className="" data-testid="MASTERCARD">
                              <img src={mastercardLogo} alt="" />
                            </span>
                          </span>
                          <span>
                            <span className="" data-testid="Verve">
                              <img src={verveLogo} alt="" />
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="select-ALl m-card-default-form">
                      <div className="deposit-form-item">
                        <div
                          data-v-4666fd94=""
                          className="deposit-form-item-label amount-marked"
                        >
                          Amount
                        </div>
                        <div className="section-item-wrap border-style">
                          <Input
                            placeholder="Amount to Credit"
                            type="number"
                            value={amount.toString()}
                            onChange={handleAmountChange}
                            contentBefore={
                              <Text size={400} id="beforeLabelId-400">
                                {currencySymbol}
                              </Text>
                            }
                            contentAfter={
                              <Text size={400} id="afterLabelId-400">
                                .00
                              </Text>
                            }
                            appearance="filled-darker"
                            id="filledDarkerId-02"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="Button-content-container">
                      <Button icon={<WalletCreditCard24Regular />}>
                        Pay : {currencySymbol}{' '}
                        {new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(total)}
                      </Button>
                    </div>
                  </div>
                )}
                <div className="m-powered">
                  <div className="amount-frc">
                    <div>
                      <b>VAT</b> <small>7.5% = </small> "N/A"
                    </div>
                    <div className="">
                      <b>Process Fee</b> <small>2.5% =</small> {adminFess}
                    </div>
                    <div className="Were-status">
                      Total: {currencySymbol}{' '}
                      {new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(total)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {transaction === 'Debit' && (
          <div className="m-trade-list main main">
            <div className="m-trade-list--header">
              <div className="m-search-tip">
                <div className="v-input-wrap m-search-input">
                  <Field>
                    <SearchBox
                      appearance="outline"
                      placeholder="Amount/Invoice ID/Invoice No"
                    />
                  </Field>
                </div>
              </div>
              <div className="trade-header-right"></div>
            </div>
            <div className="m-trade-list--filters">
              <div className="x-filter-item">
                <Menu>
                  <MenuTrigger disableButtonEnhancement>
                    <MenuButton size="small" shape="circular">
                      Credit History
                    </MenuButton>
                  </MenuTrigger>

                  <MenuPopover>
                    <MenuList>
                      <MenuItem>Credit History</MenuItem>
                      <MenuItem>Debit History</MenuItem>
                    </MenuList>
                  </MenuPopover>
                </Menu>
              </div>
              <div className="x-filter-item">
                <Menu>
                  <MenuTrigger disableButtonEnhancement>
                    <MenuButton size="small" shape="circular">
                      All Satus
                    </MenuButton>
                  </MenuTrigger>

                  <MenuPopover>
                    <MenuList>
                      <MenuItem>All Satus</MenuItem>
                      <MenuItem>Successful</MenuItem>
                      <MenuItem>Pending</MenuItem>
                      <MenuItem>Failed</MenuItem>
                    </MenuList>
                  </MenuPopover>
                </Menu>
              </div>
              <div className="date-picker">
                <Field>
                  <DatePicker
                    showWeekNumbers={true}
                    firstWeekOfYear={1}
                    showMonthPickerAsOverlay={true}
                    placeholder="Select start date..."
                    className=""
                  />
                </Field>

                <Field>
                  <DatePicker
                    showWeekNumbers={true}
                    firstWeekOfYear={1}
                    showMonthPickerAsOverlay={true}
                    placeholder="Select end date..."
                    className=""
                  />
                </Field>
              </div>
            </div>

            <div className="m-trade-list--list">
              <Table size="small" aria-label="Table with small size">
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>Time</TableHeaderCell>
                    <TableHeaderCell>Type</TableHeaderCell>
                    <TableHeaderCell>Amount</TableHeaderCell>
                    <TableHeaderCell>Status</TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {currentData.map((item) => (
                    <TableRow key={item.time}>
                      <TableCell>{item.time}</TableCell>
                      <TableCell>{item.type}</TableCell>
                      <TableCell>{item.amount}</TableCell>
                      <TableCell><span className="m-status">{item.status}</span></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              {/* Pagination controls */}
              <div className="pagination">
                <div className={`page-item prev ${currentPage === 0 ? 'disabled' : ''}`}>
                  <Button icon={<ChevronLeft24Regular />} onClick={() => setCurrentPage((page) => Math.max(page - 1, 0))}></Button>
                </div>
                <div className="pager">
                  {Array.from({ length: pageCount }, (_, i) => (
                    <span key={i} className={`page-item ${currentPage === i ? 'active' : ''}`} onClick={() => setCurrentPage(i)}>
                      {i + 1}
                    </span>
                  ))}
                </div>
                <div className={`page-item next ${currentPage === pageCount - 1 ? 'disabled' : ''}`}>
                  <Button icon={<ChevronRight24Regular />} onClick={() => setCurrentPage((page) => Math.min(page + 1, pageCount - 1))}></Button>
                </div>
              </div>

            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Payment;
