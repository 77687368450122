import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';

interface RoomProps {
  streamUrl: string;
  roomName: string;
  isSelected: boolean;
}

const Live: React.FC<RoomProps> = ({ streamUrl, roomName, isSelected }) => {
  return (
    <div style={{ flex: isSelected ? 1 : 0 }}>
      {isSelected && (
        <ReactPlayer url={streamUrl} controls={true} width="100%" />
      )}
      <div className="ZY8hPc gtgjre">
        <div className="Djiqwe sqgFe">
          <div className=" JIru8e"></div>
          <div className="LqxiJe">
            <div className="Ncfvpd">
              <div className="OFfHfd urlhDe">
                <div className="XEazBc adnwBd">
                  <div tt-id="ucc-6"></div>
                  <span data-is-tooltip-wrapper="true">
                    <span>
                      <div>
                        <div className="overflow-hiden">{roomName}</div>
                      </div>
                    </span>
                    <div
                      className="EY8ABd-OWXEXe-TAWMXe"
                      role="tooltip"
                      aria-hidden="true"
                      id="ucc-6"
                    >
                    {roomName}
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Live;
