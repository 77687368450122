import React, { ChangeEvent, FormEvent, useCallback, useContext, useEffect, useState } from 'react';
import { Button, Checkbox, Combobox, Field, Input, InputOnChangeData, Label, Persona, Radio, RadioGroup, Select } from '@fluentui/react-components';
import { AlignSpaceEvenlyHorizontal24Regular, AppRecent24Regular, ArrowAutofitWidth24Regular, ChevronLeft24Regular, WeatherRainShowersNight24Regular } from '@fluentui/react-icons';

import "./RoomReservation.scss";

import {
    Dropdown,
    makeStyles,
    Option,
    shorthands,
    useId,
    Caption1,
    tokens,
    Text,
} from "@fluentui/react-components";
import { SearchBox } from '@fluentui/react-search-preview';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import axios from 'axios';
import { initializeIcons } from '@fluentui/react';
import { MoreHorizontal20Regular } from '@fluentui/react-icons';
import {
    Card,
    CardHeader,
    CardPreview,
    CardProps,
} from '@fluentui/react-components';
import Base_456Image from "../../Themes/Images/meetings_light.svg";
import { Gender } from '../../../Data/General/IGender';
import {ReturnMessage, SUBACCOUNT_CODE, WHITE_HOUSE_API, WHITE_HOUSE_S3 } from '../../../Constants/Constant';
import { BookingContext } from '../../Common/Context/BookContext';
import { SelectedRoomDetail } from '../../../Data/General/RoomDetails';
import Loaders from '../../Common/Loader/Loader';
import {IRoom, Room} from '../../../Data/General/Room';
import { RoomDataListItem } from '../../../Data/General/RoomDetail';
import { reservation, IEventReservation } from '../../../Data/General/EventReservation';
import { HotelEventCenter } from '../../../Data/General/HotelEventCenter';
import DispatchToast from '../../Common/Toast/DispatchToast';
import { debounce } from 'lodash';
import { IReservation, InitIReservation } from '../../../Data/General/RoomReservation';
import { Title } from '../../../Data/General/ITitle';
import { InterfaceReservationType } from '../../../Data/General/ReservationType';
import { TransactionData, calculateTotalChargeAmount, checkUntilComplete, initializeTransaction, redirectToAuthorizationUrl } from '../Paystack/payStack';
import { InitiateTransactions } from '../../../Data/General/InitiateTransaction';
import { InternalTransaction } from '../Transactions/InternalTransaction';
import PaymentLoaders from '../../Common/Loader/PayMentLoder';
import TransactionState from '../../Common/Loader/TransactionState';
const resolveAsset = (asset: string) => {
    const ASSET_URL =
        `${WHITE_HOUSE_S3}`;

    return `${ASSET_URL}${asset}`;
};

const useStyles = makeStyles({
    main: {
        ...shorthands.gap('16px'),
        display: 'flex',
        flexWrap: 'wrap',
    },

    card: {
        width: '100%',
        maxWidth: '100%',
        minHeight: '250px',
        height: 'fit-content',
    },

    caption: {
        color: tokens.colorNeutralForeground3,
    },

    smallRadius: {
        ...shorthands.borderRadius(tokens.borderRadiusSmall),
    },

    grayBackground: {
        backgroundColor: tokens.colorNeutralBackground3,
    },


    control: {
        maxWidth: "300px",
    },
    wrapper: {
        columnGap: "15px",
        display: "flex",
        marginBottom: '10px',
    },
});


interface User {
    email: string,
    phoneNumber:string,
    token:string,
  
    // other properties...
  }
  interface SelectedEvent{
    number:any;
    name:any;
  }

interface Bookingguest {
    user:User;
    onClose: () => void;
    onPaymentOpen: (customerId: any, ServiceId:any) => void;
}

interface Titles {
    titleId: number;
    titleName: string;
}


let Initmessage: ReturnMessage = {
  header: '',
  body: '',
};

const RoomReservation: React.FC<Bookingguest> = ({user, onPaymentOpen, onClose}) => {
    const [availableEvent, setAvailableEvent] = useState<HotelEventCenter>();
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [isSearchActive, setisSearchActive] = useState(false);
    const [name, setName] = useState<string>('');
    const [results, setResults] = useState<HotelEventCenter[]>([]);
    const [reserverRoom, setReserveRoom] = useState<IReservation>(InitIReservation);
    const [payment, setPayment] = useState<boolean>(false);
    const [titleMapping, setTitleMapping] = useState<Title[]>([]);
    const [filteredOptions, setFilteredOptions] = useState(titleMapping);
    const [genderMapping, setGenderMapping] = useState<Gender[]>([]);
    const [roomResults, setRoomResults] = useState<IRoom[]>([]);
   const [roomRates, setRoomRate] = useState<any | null>('');
   const [userSelectedRoomNumber, setUserSelectedRoomNumber] = useState<any>();
    const [userSelectedRoomName, setUserSelectedRoomName] = useState<any>();
    const [reservationType, setReservationType] = useState<InterfaceReservationType[]>([]);
   const [filteredGenderOptions, setFilteredGenderOptions] = useState(genderMapping);
   const [selectedEmployeeId, setSelectedEmployeeId] = useState<number>();
   const [selectedTypeId, setSelectedTypeId] = useState<number>();
   const [transactionStatus, setTransactionStatus] = useState<boolean>(false);
   const [returnSuccess, setReturnSuccess] = useState<ReturnMessage>(Initmessage);
    const [getBookingId, setGetBookingId] = useState<number>(0);
    const [isPaymentL, setIsPaymentL] = useState<boolean>(false);
    const point005prcent:any = 0.007;
    const [query, setQuery] = useState('');
    const styles = useStyles();
    initializeIcons();
    const [dispatchToaster, setDispatchToaster] = useState<boolean>(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [theGuestEmail, setTheGuestEmail] = useState<string>("");

      const handleRoomReservationInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setReserveRoom(prevState => ({ ...prevState, [name]: value }));
      };


    const fetchEventsById = useCallback(async (id:any) => {
        try {
            const response = await axios.get(`${WHITE_HOUSE_API}/EventServices/${id}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });
            setAvailableEvent(response.data);
            if(availableEvent){
              setReserveRoom((prevState) => ({ ...prevState, eventId: availableEvent?.eventCenterId }));
             
              setName(availableEvent?.eventCenterName);
              }
          } catch (error) {
            console.error('Error fetching roles:', error);
        }
    }, [availableEvent, user.token]);

   


    const handleDateInputChange =
    (name: string) => (e: Date | null | undefined) => {
      if (e) {
        setReserveRoom((prevState) => ({ ...prevState, [name]: e }));
      }
    };

    
    function formatDate(date:any) {
        const options = { weekday: 'short' as const, year: 'numeric' as const, day: 'numeric' as const };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }
    const returnBack = ()=> {
       
        }

    const search = async (query: any) => {
       
    };

    const fetchResults = async (query:any) => {
      setResults([]);
      try {
          const response = await axios.get(
              `${WHITE_HOUSE_API}/EventServices/Query?query=${query}`,
              {
                  params: { query },
                  headers: {
                      'Authorization': `Bearer ${user.token}`
                  }
              }
          );
          console.log("response data: ", response.data);
          setResults(response.data);
          
      } catch (error) {
          console.error(error);
      }
  };
  
  const debouncedFetchResults = debounce(fetchResults, 500);
  
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const que = event.target.value;
    console.log("search term", que);
    setisSearchActive(que !== '');
    setInputValue(que);
  
    // Call fetchResults if the input value has at least 2 characters
    if (que.length >= 2) {
      debouncedFetchResults(que);
  }
  };
  const fetchTitles = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/Title`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const Titles = response.data;
      setTitleMapping(Titles);
      setFilteredOptions(Titles);
    } catch (error) {

    }
  }, [user.token]);

  const fetchGenders = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/Gender`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const genders = response.data;
      setGenderMapping(genders);
      setFilteredGenderOptions(genders);
    } catch (error) {

    }
  }, [user.token]);
  const fetchAutocompleteResults = async (inputs: any) => {
    if (inputs.length < 0) {
      return; // don't call the API if the input is less than 2 characters
    }
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Rooms/search/${inputs}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setRoomResults(response.data);
    } catch (error) {

    }
  };

  const handleCheckingDropdowInputChange =
    (name: string) => (event: React.ChangeEvent<HTMLSelectElement>) => {
      const newValue = event.target.value;
      setReserveRoom((prevState) => ({ ...prevState, [name]: newValue }));
    };


  const handleInputChangeName = (event: any) => {
    fetchAutocompleteResults(event.target.value);
  };
  const handleServiceChange = async (name: string, event: any) => {
    const service = event.target.innerText;
    setReserveRoom((prevState) => ({ ...prevState, [name]: service }));
  };

  const handleReservationChange = (event: any) => {
    fetchAutocompleteRSTs(event.target.value);
  };


  const fetchAutocompleteRSTs = async (inputs: any) => {
    if (inputs.length < 3) {
      return; // don't call the API if the input is less than 2 characters
    }
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/ReservationType/search/${inputs}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setReservationType(response.data);
    } catch (error) {

    }
  };
  const handleBlurRST = (event: any) => {
    const selectedName = event.target.value;
    const selectedTypes = reservationType.find(
      (reservationType) => `${reservationType.typeName}` === selectedName
    );

    if (selectedTypes) {
      setSelectedTypeId(selectedTypes.reservationTypeId);
      setReserveRoom((prevState) => ({
        ...prevState,
        reservationType: selectedTypes?.typeName || ''
    }));
    
    }
  };

   const handleItemClick =(id:any) => {
              fetchEventsById(id);
              setisSearchActive(false);
              setQuery('');

}

              const ReserveEvent = async () => {
                try {
                   setIsLoader(true);
                   const response = await axios.post(
                     `${WHITE_HOUSE_API}/Reservation/Create`,
                     reserverRoom,
                     {
                       headers: {
                         'Authorization': `Bearer ${user.token}`
                       }
                     }
                   );
                    // eslint-disable-next-line no-lone-blocks
                    {/* pay statck payment integration
                      onPaymentOpen(response.data.eCustomerid, response.data.eServiceId)
                      */}
                      console.log("Data Response: ", response.data);
                      if (response.data.status === 'Success') {
                        let amt:any = response.data.eAmount;
                        let emai:any = response.data.eEmail;
                        setGetBookingId(response.data.eBookinId);
                        setTheGuestEmail(response.data.eEmail);
                        setResponseMessage(response.data.eMessage);
                        console.log("Just add data: ", getBookingId);
                        let charge:any = await calculateTotalChargeAmount(amt, point005prcent); 
                        const transactionData: TransactionData = {
                          email: emai,
                          amount: (charge * 100).toString(),
                          subaccount: SUBACCOUNT_CODE,
                          transaction_charge:  charge * point005prcent * 100
                        };
                  
                        let resTransaction = await initializeTransaction(transactionData);
                        console.log("Authorization URL: ", resTransaction);
                        redirectToAuthorizationUrl(resTransaction);
                  
                        let verification = await checkUntilComplete(resTransaction.data.reference);
                        while (verification.data.status !== 'success' && verification.data.status !== 'failed') {
                          await new Promise(resolve => setTimeout(resolve, 500));
                          verification = await checkUntilComplete(resTransaction.data.reference);
                          console.log("I am called: ", new Date());
                        }
                  
                        console.log("Returned Verification: ", verification);
                        if(verification.data.status === 'success'){
                          const T:InitiateTransactions = {
                            customerEmail: verification.data.customer.email,
                            roomNumber: reserverRoom.roomNumber,
                            bookingid: response.data.eBookinId,
                            transactionId: verification.data.id,
                            Amount: verification.data.amount,
                            customerPaymentId: verification.data.customer.id,
                            paymentChannel: verification.data.channel,
                            Souces: 'Front Office',
                            gatewayid: 1,
                            gatewayresponse: verification.data.gateway_response,
                            referencenumber: verification.data.reference,
                            clientAccountName: verification.data.subaccount.business_name,
                            Currency: verification.data.currency,
                            clientId: verification.data.subaccount.id,
                            clientBankName: verification.data.subaccount.settlement_bank,
                            clientAccountNumber: verification.data.subaccount.account_number
                          }
                          let internal = await InternalTransaction(T, user.token);
                          if(internal){
                            Initmessage = {
                              header: internal.header,
                              body: internal.body,
                            };
                            setReturnSuccess(Initmessage)
                          };
                          setIsPaymentL(false);
                          setTransactionStatus(true);
                          setIsLoader(false);
                          setReserveRoom({...reserverRoom});
                        }else if(verification.data.status === 'failed'){
                          Initmessage = {
                            header: "Payment " + verification.data.status,
                            body: "Room Was not booked",
                          };
                          setReturnSuccess(Initmessage)
                          setIsPaymentL(false);
                          setTransactionStatus(true);
                          setIsLoader(false);
                        }
                      }
                 } catch (error) {
                   setResponseMessage('Registration ' + error);
                   console.error('Error fetching roles:', error);
                   setIsLoader(false);
                 }
               };

               const closeLoad = () => {
                setIsLoader(false);
              }


 useEffect(() => { 
 fetchGenders();
fetchTitles();  
console.log("Success Message: ", Initmessage);       
}, [availableEvent,debouncedFetchResults, fetchGenders,returnSuccess, fetchTitles, inputValue, name]);
           

    return (
<>
        <div className="ms-Fabric ms-Layer-content content-46">
        <div className="wc-no outline">
          <div className="ms-Modal is-open root-39">
            <div aria-hidden="true" className="ms-Overlay ms-Overlay--dark root-48"></div>
            <div id="cilsco-focuszone" className="ms-Dialog-main createListModalMain_979b2e47 main-40">
              <div aria-hidden="true" id="Ariel-fixed" data-is-visible="true"
                data-is-focus-trap-zone-bumper="true">
              </div>
              <div className="ms-Modal-scrollableContent scrollableContent-54 customScrollBar">
                <div className="Merging-34">
                  <div className="createList-root templatePreviewPage">
                    <div className="templatePreview">
                      <div className="createList-pageRoot templatePreviewContainer">
                        {isLoader && (
                        <Loaders />
                        )}
                        {isPaymentL && (
                        <>
                        <PaymentLoaders startInSeconds={60} close={closeLoad}
                          />
                      </>
                      )}
                      {transactionStatus && (
                        <>
                          <TransactionState ResponseMessage={returnSuccess} close={closeLoad}
                          />
                        </>
                      )}
                         {dispatchToaster && <DispatchToast message={responseMessage} />}
                        <div className="createList-header">
                          <div className="templateTitleWithLogo">
                            <div className="templateLogo-container sp-List-color6">
                              <span className="WRoomNumbers">
                                {name.substring(0, 2).toUpperCase() || 'EC'}
                              </span>
                            </div>
                            <h1 className="createList-headerTitle">
                             {name || 'RESERVE ROOM'}
                            </h1>
                          </div>
                          <div className={`ui-box Wsearch-ev-ew wc-check-search c-search_modal__wrapper
                            popover-little ${ isSearchActive ? 'mouse-over-active' : '' }`}>
                            <SearchBox 
                            appearance="filled-lighter" 
                            placeholder="Search Event .." 
                            value={inputValue}
                            onChange={handleInputChange}
                            onBlur={search} className="sear-padd" />
                            <div className={`c-search_autocomplete__container
                              c-search_autocomplete__container--query-ordered ${ isSearchActive
                              ? 'serachbar-active' : '' }`}>
                              <div className="c-search_autocomplete c-search_autocomplete--nav-attached">
                                <div className="offscreen">
                                  <span id="search-autocomplete-query-label-prefix-node">
                                    Search
                                  </span>
                                </div>
                                {results.map((envt) => {
                                let availabilityText = envt.bookingStatus
                                ? 'Available'
                                : 'Not Available';
                                let fullname = `${envt.eventCenterName}`;
                                let initials = fullname.substring(0, 2).toUpperCase();
                               
                                return (
                                <div key={envt.eventCenterId}
                                  className="c-search_autocomplete__suggestion_list c-search_autocomplete__suggestion_list--taller event-reservation"
                                  onClick={()=> handleItemClick(envt.eventCenterId)}
                                  >
                                  <div className="curtain-a wform-css-283-right wc-ciHHEB">
                                    <div className="fui-Persona rlroi9i">
                                      <span role="img" id="avatar-rd"
                                        className="fui-Avatar r81b29z fui-Persona__avatar ___1c989qy_1o9k6tz f13ar0e0 fid048z f1hek2iy f9h729m f18q9vkd f103ycu4"
                                        aria-label="Kevin Sturgis"
                                        aria-labelledby="avatar-rd avatar-rd__badge">
                                        {/* <AvatarGroup>
                                          <AvatarGroupItem name={fullname} />
                                        </AvatarGroup> */}
                              
                                        <div className="fui-Persona rlroi9i"><span role="img" id="avatar-rd"
                                            className="fui-Avatar r81b29z fui-Persona__avatar ___1c989qy_1o9k6tz f13ar0e0 fid048z f1hek2iy f9h729m f18q9vkd f103ycu4"
                                            aria-label="Kevin Sturgis"
                                            aria-labelledby="avatar-rd avatar-rd__badge">
                                            <div role="group"
                                              className="fui-AvatarGroup ___19hqz8g_10mhep2 ftuwxu6 f10pi13n">
                                              <div
                                                className="fui-AvatarGroupItem ___liu8hg0_1wjcx8a f122n59 ftuwxu6 fi64zpg f10pi13n f8fbkgy f1nfllo7 f1djnp8u f1s8kh49 f1l333zn f1szoe96 f1d2rq10">
                                                <span role="img" id="avatar-r6h"
                                                  className="fui-Avatar r81b29z fui-AvatarGroupItem__avatar ___mgf8q40_1ccxybz f1euv43f"
                                                  aria-label=""><span id="avatar-r6h__initials"
                                                    className="fui-Avatar__initials rip04v ___1jrfxqc_4vgnv00 f135dsb4 f6hvv1p">{initials}</span></span>
                                              </div>
                                            </div>
                                          </span><span
                                            className="fui-Persona__primaryText rvj41k9">{envt.eventCenterName}</span><span
                                            className="fui-Persona__secondaryText rp1pf9e ___19lp2a0_1p5a1yj f1ryq6si">
                                            {availabilityText}</span></div>
                                      </span>
                                    </div>
                                
                                  </div>
                                </div>
                                );
                                })}
                              </div>
                            </div>
                          </div>                       
                          <div className="createList-closeBtnContainer">
                            <Button icon={<i className="fi fi-sr-cross-small" />}
                            onClick={onClose}
                            ></Button>
                          </div>
                        </div>
                        <div className="createList-pageContent includeBottomPadding customScrollBar">
            <div className="previewContainer  wc-add-paddin">
                <form action="" className='Form-content'>
                    <div className="wform-MyContentControl-module__edgeworth-stack-item-container-refresh__3mUvv wform-css-283 ">
                        <div className="ui-divider  ua-gi-ub-uc">
                            <div className="wc-form-wrapper">                  
                            <div className="w-add-Guest-room_w Guest-management-job">
                                <div className="w-add-room-guest rms-block-left">
                                  <div className="wc-general-info make_wec-small">
                                    <Label id="Title">Title</Label>
                                  </div>
                                  <Dropdown
                                    placeholder="Select a Title"
                                    appearance="filled-darker"
                                    name="title"
                                    onOptionSelect={(item) =>
                                      handleServiceChange('title', item)
                                    }
                                  >
                                    {titleMapping.map((item) => (
                                      <Option
                                        key={item.titleId}
                                        value={item.titleName}
                                      >
                                        {item.titleName}
                                      </Option>
                                    ))}
                                  </Dropdown>
                                </div>
                                <div className="w-add-room-guest rms-block-right" style={{marginRight: '10px'}}>
                                  <div className="wc-general-info make_wec-small">
                                    <Label id="Gender">Gender</Label>
                                  </div>
                                  <Dropdown
                                    placeholder="Select a gender"
                                    appearance="filled-darker"
                                    name="gender"
                                    onOptionSelect={(item) =>
                                      handleServiceChange('gender', item)
                                    }
                                  >
                                    {genderMapping.map((item) => (
                                      <Option
                                        key={item.genderId}
                                        value={item.genderName}
                                      >
                                        {item.genderName}
                                      </Option>
                                    ))}
                                  </Dropdown>
                                </div>
                              </div>
                                <div className="w-add-Guest-room Guest-management-job">
                                    <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">First Name</div>
                                        <div className="firstName-container">
                                            <Input placeholder='First Name'
                                             appearance="filled-darker"
                                              id="firstName"
                                              name="firstName"
                                              onChange={handleRoomReservationInputChange} />
                                        </div>
                                    </div>
                                    <div className="w-add-room-guest rms-block-right">
                                        <div className="w-add-room-guest rms-inline-property-title">Last Name</div>
                                        <div className="firstName-container">
                                            <Input appearance="filled-darker"
                                             placeholder='Last Name'
                                              id="lastName"
                                              name="lastName"
                                              onChange={handleRoomReservationInputChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-add-Guest-room Guest-management-job">
                                <div className="w-add-room-guest rms-block-right">
                                        <div className="w-add-room-guest rms-inline-property-title">Email</div>
                                        <div className="firstName-container">
                                            <Input appearance="filled-darker"
                                             placeholder='Email' 
                                             id="email"
                                             name="email"
                                             onChange={handleRoomReservationInputChange} />
                                        </div>
                                    </div>
                                   
                                    <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">Phone Number</div>
                                        <div className="firstName-container">
                                            <Input placeholder='phone'
                                             appearance="filled-darker"
                                              id="phone"
                                              name="phone"
                                              onChange={handleRoomReservationInputChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-add-Guest-room Guest-management-job">
                                <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">Address</div>
                                        <div className="firstName-container">
                                            <Input placeholder='Address'
                                             appearance="filled-darker"
                                              id="address"
                                              name="address"
                                              onChange={handleRoomReservationInputChange} />
                                        </div>
                                    </div>
                                    <div className="w-add-room-guest rms-block-right">
                                        <div className="w-add-room-guest rms-inline-property-title">Country</div>
                                        <div className="firstName-container">
                                        <Input placeholder='Country' 
                                            appearance="filled-darker"
                                             id="country"
                                             name="country"
                                             onChange={handleRoomReservationInputChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-add-Guest-room Guest-management-job">
                                <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">State</div>
                                        <div className="firstName-container">
                                            <Input placeholder='State'
                                             appearance="filled-darker"
                                              id="state"
                                              name="state"
                                              onChange={handleRoomReservationInputChange} />
                                        </div>
                                    </div>
                                    <div className="w-add-room-guest rms-block-right">
                                        <div className="w-add-room-guest rms-inline-property-title">City</div>
                                        <div className="firstName-container">
                                        <Input placeholder='City' 
                                            appearance="filled-darker"
                                             id="city"
                                             name="city"
                                             onChange={handleRoomReservationInputChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-add-Guest-room Guest-management-job">
                                    <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">Room Type</div>
                                        <div className="firstName-container">
                                            <Input placeholder='Room Type'
                                             appearance="filled-darker"
                                              id="roomType"
                                              name="roomType"
                                              onChange={handleRoomReservationInputChange} />
                                        </div>
                                    </div>
                                    <div className="w-add-room-guest rms-block-right">
                                        <div className="w-add-room-guest rms-inline-property-title">Number of Rooms</div>
                                        <div className="firstName-container">
                                        <Input placeholder='Number Of Rooms' 
                                            appearance="filled-darker"
                                             id="numberOfRooms"
                                             name="numberOfRooms"
                                             onChange={handleRoomReservationInputChange} />
                                        </div>
                                    </div>
                                </div>
                                
                              <div className="w-add-Guest-room_w Guest-management-job">
                                <div className="w-add-room-guest rms-block-left">
                                  <div className="wc-general-info">
                                    <Label id="IdentityType">Room Number</Label>
                                  </div>
                                  <Combobox
                                    aria-label="autocomplete"
                                    name="roomNumber"
                                    onChange={handleInputChangeName}
                                    onSelect={(event) => {
                                      const target =
                                        event.target as HTMLInputElement;
                                      const selectedRoomNumber = target.value;
                                      let match = selectedRoomNumber.match(/\((\d+)\)/);
                                      let value:any;
                                      if(match){
                                        value = parseInt(match[1]);
                                        setReserveRoom((prevState) => ({
                                          ...prevState,
                                          roomNumber: value,
                                        }));
                                      }
                                      // Find the room with the selected room number
                                      const selectedRoom = roomResults.find(
                                        (room) =>
                                        room.roomNumber === value
                                      );

                                      if (selectedRoom) {
                                        setRoomRate(selectedRoom.rate);
                                        setUserSelectedRoomNumber(
                                          selectedRoom.roomNumber!
                                        );
                                        setUserSelectedRoomName(
                                          selectedRoom.roomName!
                                        );
                                      }
                                    }}
                                
                                  >
                                    {roomResults.map((result) => {
                                      if (
                                        !userSelectedRoomNumber ||
                                        result.roomNumber ===
                                          userSelectedRoomNumber
                                      ) {
                                        return (
                                          <Option
                                            key={result.roomNumber}
                                            text={`ROOM (${result.roomNumber})`}
                                          >
                                            <Persona
                                              avatar={{
                                                color: 'colorful',
                                                'aria-hidden': true,
                                              }}
                                              name={`${result.roomNumber}`}
                                              presence={{
                                                status: 'available',
                                              }}
                                              secondaryText={result.roomType}
                                            />
                                          </Option>
                                        );
                                      }
                                    })}
                                  </Combobox>
                                </div>
                                <div className="w-add-room-guest rms-block-right Id-number" style={{marginRight: '10px'}}>
                                  <div className="wc-general-info">
                                    <Label id="Gender">Room Name</Label>
                                  </div>

                                  <div className="firstName-container uiId-number">
                                    <Combobox
                                      aria-label="autocomplete"
                                      name="roomName"
                                      onChange={handleInputChangeName}
                                 
                                      onSelect={(event) => {
                                        const target =
                                          event.target as HTMLInputElement;
                                        const selectedOption = target.value;
                                        const selectedRoom = roomResults.find(
                                          (room) =>
                                            room.roomName === selectedOption
                                        );
                                        if (selectedRoom) {
                                          setUserSelectedRoomNumber(
                                            selectedRoom.roomNumber!
                                          );
                                          setUserSelectedRoomName(
                                            selectedRoom.roomName!
                                          );
                                          setReserveRoom((prevState) => ({
                                            ...prevState,
                                            RoomName: selectedOption,
                                            RoomNumber: selectedRoom.roomNumber,
                                          }));                                         
                                          
                                        }
                                      }}
                                    >
                                      {roomResults.map((result) => {
                                        if (
                                          !userSelectedRoomName ||
                                          result.roomName ===
                                            userSelectedRoomName
                                        ) {
                                          return (
                                            <Option
                                              key={result.roomNumber}
                                              text={`${result.roomName}`}
                                            >
                                              <Persona
                                                avatar={{
                                                  color: 'colorful',
                                                  'aria-hidden': true,
                                                }}
                                                name={`${result.roomName}`}
                                                presence={{
                                                  status: 'available',
                                                }}
                                                secondaryText={result.roomType}
                                              />
                                            </Option>
                                          );
                                        }
                                      })}
                                    </Combobox>
                                  </div>
                                </div>
                              </div>
                                <div className="w-add-Guest-room Guest-management-job">
                                    <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">Number of Adults</div>
                                        <div className="firstName-container">
                                            <Input placeholder='Number OF Adult'
                                             appearance="filled-darker"
                                              id="numberOfAdult"
                                              name="numberOfAdult"
                                              onChange={handleRoomReservationInputChange} />
                                        </div>
                                    </div>
                                    <div className="w-add-room-guest rms-block-right">
                                        <div className="w-add-room-guest rms-inline-property-title">Number Of Children</div>
                                        <div className="firstName-container">
                                            <Input appearance="filled-darker"
                                             placeholder='Number Of Children'
                                              id="numberOfChildren"
                                              name="numberOfChildren"
                                              onChange={handleRoomReservationInputChange}/>
                                        </div>
                                    </div>
                                </div> 
                             
                                <div className="w-add-Guest-room Guest-management-job">
                                <div className="w-add-room-guest rms-block-right Id-number">
                                  <div className="wc-general-info">
                                    <Label id="Gender">
                                      Search Reservation Type
                                    </Label>
                                  </div>
                                  <div className="firstName-container combo-parent-reservation-type">
                                    <Combobox
                                      aria-label="autocomplete"
                                      onChange={handleReservationChange}
                                      onBlur={handleBlurRST}
                                    >
                                      {reservationType.map((result) => {
                                        return (
                                          <Option
                                            key={result.reservationTypeId}
                                            text={`${result.typeName}`}
                                          >
                                            <Persona
                                              avatar={{
                                                color: 'colorful',
                                                'aria-hidden': true,
                                              }}
                                              name={`${result.typeName}`}
                                              presence={{
                                                status: 'available',
                                              }}
                                              secondaryText={result.description}
                                            />
                                          </Option>
                                        );
                                      })}
                                    </Combobox>
                                  </div>
                                </div>
                                <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">Number of Rooms</div>
                                        <div className="firstName-container">
                                        <Input placeholder='Number of Rooms'
                                        type='number'
                                             appearance="filled-darker"
                                              id="numberOfRooms"
                                              name="numberOfRooms"
                                              onChange={handleRoomReservationInputChange} />
                                        </div>
                                    </div>
                                </div>
                                  <div className="w-add-Guest-room Guest-management-job">
                                  <div className="w-add-room-guest rms-block-left">
                                  <div className="wc-general-info">
                                    <Label id="IdentityType">Identity</Label>
                                  </div>
                                  <Select
                                    id="_filledDarker"
                                    name="identity"
                                    onChange={handleCheckingDropdowInputChange(
                                      'identity'
                                    )}
                                    appearance="filled-darker"
                                  >
                                    <option>Select ...</option>
                                    <option>National ID Card</option>
                                    <option>Driver's License</option>
                                    <option>Passport</option>
                                    <option>Employee ID Card</option>
                                    <option>Student ID Card</option>
                                    <option>Loyalty Card</option>
                                    <option>Voter ID Card</option>
                                  </Select>
                                </div>
                                    <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">Id Card Number</div>
                                        <div className="firstName-container">
                                            <Input placeholder='ID Number' 
                                            appearance="filled-darker"
                                             id="guestIdCard"
                                             name="guestIdCard"
                                             onChange={handleRoomReservationInputChange} />
                                        </div>
                                    </div>
                                   
                                </div>
                                <div className="w-add-Guest-room Guest-management-job">
                                    <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">Reservation Start Date</div>
                                        <div className="firstName-container">
                                            <Field>
                                                <DatePicker
                                                    name="startDate"
                                                    showWeekNumbers={true}
                                                    firstWeekOfYear={1}
                                                    showMonthPickerAsOverlay={true}
                                                    placeholder="Reservation start Date..."
                                                    className={styles.control}
                                                    onSelectDate={(date) => handleDateInputChange('startDate')(date)}   
                                                  
                                                />
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="w-add-room-guest rms-block-right">
                                        <div className="w-add-room-guest rms-inline-property-title">Reservation End Date</div>
                                        <div className="firstName-container">
                                            <Field>
                                                <DatePicker
                                                    name="checkOutDate"
                                                    showWeekNumbers={true}
                                                    firstWeekOfYear={1}
                                                    showMonthPickerAsOverlay={true}
                                                    placeholder="Check out date ..."
                                                    className={styles.control}
                                                    onSelectDate={(date) => handleDateInputChange('checkOutDate')(date)}   
                                                />
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-add-Guest-room Guest-management-job">
                                    <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">Expire Date of Id</div>
                                        <div className="firstName-container">
                                            <Field>
                                                <DatePicker
                                                    name="issueDateofId"
                                                    showWeekNumbers={true}
                                                    firstWeekOfYear={1}
                                                    showMonthPickerAsOverlay={true}
                                                    placeholder="Expire Date of Id..."
                                                    className={styles.control}
                                                    onSelectDate={(date) => handleDateInputChange('issueDateofId')(date)}   
                                                  
                                                />
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="w-add-room-guest rms-block-right">
                                        <div className="w-add-room-guest rms-inline-property-title">Date of Birth</div>
                                        <div className="firstName-container">
                                            <Field>
                                                <DatePicker
                                                    name="dateOfBirth"
                                                    showWeekNumbers={true}
                                                    firstWeekOfYear={1}
                                                    showMonthPickerAsOverlay={true}
                                                    placeholder="Checking out..."
                                                    className={styles.control}
                                                    onSelectDate={(date) => handleDateInputChange('dateOfBirth')(date)}   
                                                />
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-add-Guest-room Guest-management-job">
                                    <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">Issue Date of Id</div>
                                        <div className="firstName-container">
                                            <Field>
                                                <DatePicker
                                                    name="issueDateofId"
                                                    showWeekNumbers={true}
                                                    firstWeekOfYear={1}
                                                    showMonthPickerAsOverlay={true}
                                                    placeholder="Issue Date of Id..."
                                                    className={styles.control}
                                                    onSelectDate={(date) => handleDateInputChange('issueDateofId')(date)}   
                                                  
                                                />
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="w-add-room-guest rms-block-right">
                                        <div className="w-add-room-guest rms-inline-property-title">Arrival Date</div>
                                        <div className="firstName-container">
                                            <Field>
                                                <DatePicker
                                                    name="arrivalDate"
                                                    showWeekNumbers={true}
                                                    firstWeekOfYear={1}
                                                    showMonthPickerAsOverlay={true}
                                                    placeholder="Arrival Date..."
                                                    className={styles.control}
                                                    onSelectDate={(date) => handleDateInputChange('arrivalDate')(date)}   
                                                />
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-add-Guest-room Guest-management-job">
                                <Field label="Reservation Type">
                                <RadioGroup
                                  defaultValue="Normal"
                                  name="isBooked"
                                  aria-labelledby="ageLabel"
                                  className="wc-flex"
                                  onChange={(event, value) => {
                                    if (value.value === 'IsReserved') {
                                      setReserveRoom((prevState) => ({
                                        ...prevState,
                                        isReserved: true,
                                        isBooked: false,
                                        isCheckin:false,
                                      }));
                                    } else if (value.value === 'IsBooked') {
                                      setReserveRoom((prevState) => ({
                                        ...prevState,
                                        isBooked: true,
                                        isReserved: false,
                                        isCheckin:false,
                                      }));

                                    }else if (value.value === 'IsCheckin') {
                                      setReserveRoom((prevState) => ({
                                        ...prevState,
                                        isCheckin:true,
                                        isBooked: false,
                                        isReserved: false,
                                      }));
                                  }}
                                }>
                                  <Radio
                                    value="IsReserved"
                                    label="Reservation"
                                  />
                                  <Radio value="IsBooked" label="Booking" />
                                  <Radio value="IsCheckin" label="Check in" />
                                </RadioGroup>
                              </Field>
                                </div>
                                <div className="w-add-Guest-room Guest-management-job">
                                <Field label="Marital Status">
                                <RadioGroup
                                  defaultValue="Normal"
                                  name="maritalStatus"
                                  aria-labelledby="ageLabel"
                                  className="wc-flex"
                                  onChange={(event, value) => {
                                    if (value.value === 'IsReserved') {
                                      setReserveRoom((prevState) => ({
                                        ...prevState,
                                        isReserved: true,
                                        isBooked: false,
                                        isCheckin:false,
                                      }));
                                    } else if (value.value === 'Single') {
                                      setReserveRoom((prevState) => ({
                                        ...prevState,
                                        maritalStatus: "Single",
                                      }));

                                    }else if (value.value === 'Married') {
                                      setReserveRoom((prevState) => ({
                                        ...prevState,
                                        maritalStatus: "Married",
                                      }));
                                  }}
                                }>
                                  <Radio value="Married" label="Married" />
                                  <Radio value="Single" label="Single" />
                                </RadioGroup>
                                <Checkbox 
                                shape="circular" 
                                label="Is Travel Agent" 
                                onChange={(event) => {
                                  const isChecked = event.target.checked;
                                  setReserveRoom((prevState) => ({
                                    ...prevState,
                                    isTravelAgent: isChecked,
                                  }));
                                }} 
                              />

                              </Field>
                             
                                </div>
                            </div>



                        </div>

                    </div>
                    <div className="wform-MyContentControl-module__edgeworth-stack-item-container-refresh__3mUvv wform-css-283-right">
                    <div className="sbdocs-preview wc-room-details">
                       <div className="xt-azure-datagrid-container REX-Guest paddin-table ext-azure-subscription-grid-cell fxc-base fxc-gc-vscroll fxc-gc-hscroll fxc-gc-fixture fxs-vivaresize">
                        <Card className={styles.card}>
                            <CardPreview
                                className={styles.grayBackground}
                                logo={
                                    <img
                                        className='wc-imanage-image'
                                        // src={resolveAsset('9015_whitehouseimage?AWSAccessKeyId=AKIAWD6U5GWRA5SIAJWA&Expires=1724692914&Signature=BNspr%2FdClwQiZFUsMbUc0r6reZs%3D')}
                                        src={availableEvent?.photos || Base_456Image}
                                        alt="event"
                                        width="600px"
                                        height="300px" />
                                }
                            >
                            </CardPreview>
                        </Card>
                        <div className="grid-container">
                        <div className="grid-item"><strong>Hall ID:</strong>{availableEvent?.eventCenterId}</div>
                        <div className="grid-item"><strong>Hall Name:</strong>{availableEvent?.eventCenterName}</div>
                        <div className="grid-item"><strong>Hall Capacity:</strong>{availableEvent?.capacity}</div>
                        <div className="grid-item"><strong>Description:</strong>{availableEvent?.description}</div>
                        <div className="grid-item"><strong>Price:</strong>{availableEvent?.basePrice.toLocaleString()}</div>
                        <div className="grid-item"><strong>Payment Currency:</strong>{availableEvent?.currency}</div>
                        <div className="grid-item"><strong>Contact Person:</strong>{availableEvent?.contactFirstName} {availableEvent?.contactLastName}</div>
                        <div className="grid-item"><strong>Contact Email:</strong>{availableEvent?.contactEmail}</div>
                        <div className="grid-item"><strong>Contact Phone:</strong>{availableEvent?.contactPhone}</div>

                    </div>

                        </div>
                        </div>
                    </div>
                </form>
            </div >

            </div>
                      </div>
                    </div>
                    <div className="createList-pageFooter padd-tops wsc-wiesle-slide">
                      <div className="createList-leftActions margin-both-side">
                        <Button data-automationid="createListGoBackButton" className=""
                          icon={<ChevronLeft24Regular />}
                        onClick={returnBack}
                        >
                        Back
                        </Button>
                      </div>
                      <div className="createList-rightActions margin-rights">
                        <Button className="wc-first-root-68" onClick={ReserveEvent}>
                          Submit
                        </Button>
                        <Button className="" onClick={onClose}>
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


        
            
        </>

    )
}


export default RoomReservation;

