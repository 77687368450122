export interface ILocation {
    locationId: number;
    address: string;
    phoneNumber: string;
    email: string;
    dateAdded: Date;
    updatedBy: string;
    country?: string;
    state?: string;
    city?: string;
}


export const initLocation:ILocation= {
    locationId: 0,
    address: '',
    phoneNumber: '',
    email: '',
    dateAdded: new Date(),
    updatedBy: '',
    country: '',
    state: '',
    city: '',

}

export interface DeletedLocation {
    Reason:string;
}

export const InitializeDeleted:DeletedLocation ={
    Reason: ""
}