
import { useEffect, useState } from "react";
import { Navigate, RouteProps, useLocation } from "react-router-dom";
import { useAuth } from "./PrivateAuth";
import WhiteHouseApps from "../Hms/WhiteHouseApps";
import Mobile from "../../Mobile/Mobile";

interface Message {
  message: string;
  id: number;
}




export function PrivateRoute({ children, ...rest }: RouteProps) {
  const { user, signOut } = useAuth(); 
  const location = useLocation();
  let auth = useAuth();
  const [lastActiveTime, setLastActiveTime] = useState<number>(Date.now());
  let message: Message = auth.message || { message: '', id: 0 };

  const [isMobile, setIsMobile] = useState(false);
  const storedIsLoggedIn = sessionStorage.getItem('isLoggedIn');
  const isLoggedIn = storedIsLoggedIn ? JSON.parse(storedIsLoggedIn) : false;


  useEffect(() => {
    setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    const storedIsLoggedIn = sessionStorage.getItem('isLoggedIn');
    console.log("SESSSION STATUS: ", storedIsLoggedIn);
  }, []);


  useEffect(() => {
    const inactivityTimeout = setTimeout(() => {
      if (Date.now() - lastActiveTime > 30 * 60 * 1000) { 
        signOut({ email: '', PhoneNumber: '', status: 'Success' }); // Simulate a logout
      }
    }, 30 * 60 * 1000); // 30 minutes

    const activityHandler = () => {
      setLastActiveTime(Date.now());
    };

    // Attach event listeners for user activity
    window.addEventListener("mousemove", activityHandler);
    window.addEventListener("keydown", activityHandler);

    return () => {
      clearTimeout(inactivityTimeout);
      window.removeEventListener("mousemove", activityHandler);
      window.removeEventListener("keydown", activityHandler);
    };
  }, [lastActiveTime, signOut]); // Dependency on signOut

  return user ? (
    isMobile ? (
      <Mobile user={user} message={message} />
    ) : (
      <WhiteHouseApps user={user} message={message} />
    )
  ) : (
    <Navigate to="/" replace state={{ from: location }} />
  );
}