export interface IRoles {
    roleId: number | null;
    roleName: string | null;
    description: string | null;
    employeeId: number | null;
    userId: number | null;
}

export interface ICreateRoles {
    roleId: number;
    roleName: string | null;
    description: string | null;
    employeeId: number | null;
    userId: number | null;
    adjusterid:number | null;
    datedAdded:Date;
}

export const Initailizes: ICreateRoles = {
    roleId: 0,
    roleName: "",
    description: "",
    employeeId: 0,
    userId: 0,
    adjusterid:0,
    datedAdded: new Date(),
}
