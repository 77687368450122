export interface laundryService {
    serviceId: number;
    serviceType: string;
    description:string;
    servicePrice: number;
    deliveryTime:string;
    pickUpTime:string;
    normalTime:string;
    expressTime:string;
    employeeId: number;
    dateadded: Date;
}

export const initLaundryService: laundryService  ={
    serviceId: 0,
    serviceType: '',
    pickUpTime: '',
    deliveryTime: '',
    expressTime: '',
    description: '',
    normalTime: '',
    servicePrice: 0,
    employeeId: 0,
    dateadded: new Date (),
}