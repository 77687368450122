import React, { useCallback, useEffect, useRef, useState } from 'react';
import '../Landing/Landing.scss';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbDivider,
  BreadcrumbButton,
} from "@fluentui/react-breadcrumb-preview";
import './Sells.scss';
import ProductChart from '../Finance/ProductCharts/SingleProductChartLine';
import RegisterProduct from '../Vendors/RegisterProduct';
import { Button, Combobox,Option,Persona, Divider, Field, Input, Menu, MenuButton, MenuItem, MenuList, MenuPopover, MenuTrigger, Popover, PopoverSurface, PopoverTrigger, Toolbar, ToolbarButton, ToolbarDivider, Label, Table, TableHeader, TableRow, TableHeaderCell, TableBody, TableCell } from '@fluentui/react-components';
import { Add24Regular, ArrowBetweenDown24Regular, ArrowDownload24Regular, Edit24Regular, Image24Regular, MathFormatLinear24Regular, Money24Regular, PersonAccounts24Regular, PersonVoice24Regular, QuizNew24Regular, Table24Filled } from '@fluentui/react-icons';
import { GetEmployee } from '../../../Data/General/Employee';
import { WHITE_HOUSE_API } from '../../../Constants/Constant';
import axios from 'axios';
import { IHotelProduct } from '../../../Data/General/IHotelProduct';
import { IGeneralRequisition } from '../../../Data/General/GeneralRequisition';
import RQSForms from '../RQSForms/RQSForms';
interface Message {
  message: string,
  id:number,
  }
  
  interface User {
  email: string,
  phoneNumber:string,
  token:string,
  }

  interface Proids{
    id:number,
  }

  type Props = {
  message:Message;
  user:User;
  }


const SellsPage: React.FC<Props> = ({message, user}) => {
  const containerRef = useRef<HTMLUListElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [dates, setDates] = useState<Date[]>([]);
  const [isToday, setIsToday] = useState<boolean>(false);
  const [isOccupancy, setIsOccupancy] = useState<boolean>(true);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [isRequisitionItem, setIsRequisitionItem] = useState<number | null>(null);
  const [requisistions, setRequisistions] = useState<string | null>("Store Requisition");
  const [adjuster, setAdjuster] = useState<GetEmployee>();
  const [hotelProduct, setHotelProducts] = useState<IHotelProduct>();
  const [hotelRequisition, setHotelRequisition] = useState<IGeneralRequisition>();
  const [getGotelProduct, setGetHotelProducts] = useState<IHotelProduct[]>([]);
  const [productID, setProductID] = useState<number|null>(null);
  const [open, setOpen] = useState<"first" | "second" | "third" | "fourth" | undefined>();
  const [openReqForm, setOpenReqForm] = useState<boolean>(false);
  const handleOccupancyClick = () => {
    setIsOccupancy(true);
    
  };

  const handleBarAndMealClick = () => {
    setIsOccupancy(false);
  };

  useEffect(() => {
    const today = new Date();
    const dates = Array.from({ length: 10 }, (_, i) => {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      return date;
    });
    setDates(dates);
  }, []);

  function isSameDay(date1: Date, date2: Date) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  const handleScroll = (direction: any) => {
    if (containerRef.current) {
      const scrollAmount = direction === 'left' ? -100 : 100;
      containerRef.current.style.transform = `translateX(${
        scrollPosition + scrollAmount
      }px)`;
      setScrollPosition(scrollPosition + scrollAmount);
    }
  };


  const fetchAdjuster = useCallback(async () => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Adjuster/${user.phoneNumber}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const adjuster = response.data;
      setAdjuster(adjuster);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  }, [user.phoneNumber, user.token]);


  const fetchExistingRequisitionProduct = useCallback(async (id:any) => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/GeneralRequisition/${id}`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
      setHotelRequisition(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);
  

  const handleHotelProductChangeName = (event: any) => {
    fetchAutocompleteProduct(event.target.value);
  };

  const handleHotelProductsSelection = async (event: any) => {
    const selectedItems = getGotelProduct.find(m => m.id === event);
    if(selectedItems){
      setProductID(selectedItems.id);
      setHotelProducts(selectedItems);
      fetchExistingRequisitionProduct(selectedItems.id);
      console.log("The RequisitionItem: ", hotelRequisition);
    }

  };

  const fetchAutocompleteProduct = async (inputs: any) => {
    if (inputs.length < 0) {
      return; // don't call the API if the input is less than 2 characters
    }
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/HotelProducts/${inputs}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setGetHotelProducts(response.data);
    } catch (error) {
      console.error('Error fetching autocomplete results:', error);
    }
  };

const HandleOpenRequisitionForm = () => {
  if(productID === null){
    alert("Please select a Product to continue");
  }else{
    setOpenReqForm(true);
  }

}

const HandleCloseRequisitionForm = () => {
  setOpenReqForm(false);
  handleHotelProductsSelection(productID);
}

  const handleClick = (requi: string) => () => {
    console.log("The selected is ", requi);
    setRequisistions(requi);
  }
  useEffect(() => {
    fetchAdjuster();
  }, [fetchAdjuster, message.id]);

  return (
    <>
   <div className="fxs-journey-target fxs-journey fxs-vivaresize">
   <Breadcrumb aria-label="Breadcrumb default example">
        <BreadcrumbItem>
          <BreadcrumbButton>
            Home
          </BreadcrumbButton>
        </BreadcrumbItem>
        <BreadcrumbDivider />
      </Breadcrumb>
      <div className="fxs-journey-layout fxs-stacklayout fxs-stacklayout-horizontal">
        <section className="fxs-blade msportalfx-shadow-level3 fxs-portal-bg-txt-br fxs-vivaresize fxs-stacklayout-child fxs-blade-shows-pending fxs-blade-maximized fxs-blade-firstblade fxs-blade-display-in-journey fxs-menublade fxs-blade-lastblade">
        <div className="fxs-blade-header fxs-header fxs-portal-svg fxs-portal-background fxs-blade-loaded">
          <div className="fxs-blade-title-content">
            <Button icon={<PersonAccounts24Regular />} className="fxs-blade-header-icon"></Button>
            <div className="fxs-blade-title">
              <div className="fxs-blade-title-toprow">
              <h2 className="fxs-blade-title-titleText msportalfx-tooltip-overflow" id="bf842ae7-fc60-4552-9333-5aa29c3a637d">{adjuster?.firstName} {adjuster?.lastName}<span className="msportalfx-font-regular" id="_weave_e_3478"> | Overview</span></h2>
              </div>
              <div className="fxs-blade-title-subtitle" id="_weave_e_3499">
                <div className="fxs-blade-title-subtitleText msportalfx-tooltip-overflow fxs-portal-subtext" id="bf842ae7-fc60-4552-9333-5aa29c3a637c">Cost Management + Billing | {requisistions}</div></div>
            </div>
          </div>
        </div>
        <div className="fxs-blade-content-container">
          <div className="fxs-blade-content-container-default fxs-bladecontent fxs-mode-locked fxs-blade-locked fx-rightClick fxs-bladestyle-default fxs-bladesize-menu fxs-vivaresize fxs-blade-layout-sm">
            <div className="fxs-blade-content-wrapper fxs-menu-show-search">
            <div className="fxs-blade-summary-container fxs-portal-border fxs-display-none"></div>
            <div className="fxs-blade-content fxs-vivaresize fxs-bladesize-menu">
              <div className="fxs-blade-stacklayout fxs-stacklayout fxs-stacklayout-vertical">
                <div className="fxs-lens fxs-stacklayout-child fxs-lens-fit">
                  <div className="fxs-lens-layout">
                    <div className="fxs-part">
                      <div className="fxc-menu-header">
                        <div className="fxc-menu-search fxc-base fxc-search azc-fabric"></div>
                      </div>
                      <div className="fxc-menu-scrollarea">
                        <div className="fxc-menu-grid fxc-base fxc-listView fxc-list-activateonselected azc-control azc-listView-focusable azc-listView-selectable azc-listView-contextMenu azc-listView-groupable azc-listView">
                          <div className="azc-listView-list">
                            <div className="azc-listView-group">
                              <div className="azc-listView-groupheader azc-br-muted">

                              </div>
                              <div className={`azc-listView-item fxs-portal-hover azc-br-muted fxs-portal-focus ${requisistions === "Store Requisition"? 'fxs-portal-activated': ''}`}>
                                <div className="ext-fxc-menu-item" onClick={handleClick("Store Requisition")}>
                                  
                                Store Requisition
                                </div>
                              </div>
                              <div className={`azc-listView-item fxs-portal-hover azc-br-muted fxs-portal-focus ${requisistions === "Kitchen Requisition"? 'fxs-portal-activated': ''}`}>
                                <div className="ext-fxc-menu-item" onClick={handleClick("Kitchen Requisition")}>
                                Kitchen Requisition
                                  
                                </div>
                              </div>
                              <div className={`azc-listView-item fxs-portal-hover azc-br-muted fxs-portal-focus ${requisistions === "Bar Requisition"? 'fxs-portal-activated': ''}`}>
                                <div className="ext-fxc-menu-item" onClick={handleClick("Bar Requisition")}>
                                Bar Requisition
                                  
                                </div>
                              </div>
                              <Divider appearance="strong" className="manage-sells">Manage Sells</Divider>
                              <div className={`azc-listView-item fxs-portal-hover azc-br-muted fxs-portal-focus ${requisistions === "Receiving Stock"? 'fxs-portal-activated': ''}`}>
                                <div className="ext-fxc-menu-item" onClick={handleClick("Receiving Stock")}>
                                Receiving Stock
                                  
                                </div>
                              </div>
                              <div className={`azc-listView-item WYEJ-LAGET fxs-portal-hover azc-br-muted fxs-portal-focus ${requisistions === "Opening Stock"? 'fxs-portal-activated': ''}`}>
                                <div className="ext-fxc-menu-item" onClick={handleClick("Opening Stock")}>
                                Opening Stock
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
          <div className="fxs-blade-content-container-default-details">
            <div className="fxs-blade-content-container-details fxs-blade-content-container-details-primary fxs-blade-layout-sm fxs-blade-layout-md fxs-blade-layout-lg fxs-blade-size-reflow fxs-bladecontent fxs-mode-locked fxs-blade-locked fx-rightClick fxs-bladestyle-basicwithcommands">
            <Toolbar aria-label="with Popover" size="small">
            <ToolbarButton aria-label="update sells" icon={<Add24Regular />} onClick={HandleOpenRequisitionForm}>
              Receive Stock
            </ToolbarButton>
            <ToolbarButton aria-label="update sells" icon={<Add24Regular />}>
              Update Stock
            </ToolbarButton>
            <ToolbarButton
              icon={<Edit24Regular />}
              aria-label="edit">
                Edit Stock
            </ToolbarButton>
            <ToolbarDivider />
            <ToolbarButton
              aria-label="canceal"
              icon={<ArrowBetweenDown24Regular  />}>
                Close Sells
            </ToolbarButton>
            <ToolbarDivider />
            <ToolbarButton  icon={<PersonVoice24Regular  />}>Feed back</ToolbarButton>
            <Menu>
        <MenuTrigger disableButtonEnhancement>
          <MenuButton appearance="transparent" icon={<Money24Regular />}>
            Curency
          </MenuButton>
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            <MenuItem>&#36; USD</MenuItem>
            <MenuItem>&#8358; NGN</MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
          </Toolbar>
          {/* home display */}
              <>
              {requisistions === "Store Requisition" && (
                <div className="fxs-blade-content-wrapper">
                <div className="fxs-blade-content fxs-vivaresize fxs-blade-size-reflow">
                  <div className="fxs-blade-stacklayout fxs-stacklayout fxs-stacklayout-vertical">
                    <div className="fxs-part-content">
                          <div className="msportalfx-padding ext-azure-datagrid-blade">
                            <div className='ext-info-section fxc-base fxc-section fxc-section-wrapper'></div>
                            <div className="ext-search-pill-container">
                            <div className="azc-inputbox-wrapper azc-textBox-wrapper input-qty">
                            <Combobox
                      placeholder="Search Product"
                      aria-label="autocomplete"
                      name="productid"
                      onChange={handleHotelProductChangeName}
                      onSelect={(event) => {
                        const target = event.target as HTMLInputElement;
                        const selectedValue = target.value;                 
                        let empid = getGotelProduct.find((c) =>  c.productName === selectedValue
                        );     
                        handleHotelProductsSelection(empid?.id);
                      }}
                    >
                      {getGotelProduct.map((result) => (
                        <Option
                          key={result.id}
                          value={`${result.productName}`}
                          text={`${result.productName}`}
                        >
                          <Persona
                            avatar={{
                              color: 'colorful',
                              'aria-hidden': true,
                            }}
                            name={`${result.productName}`}
                            presence={{
                              status: 'available',
                            }}
                            secondaryText={result.productType}
                          />
                        </Option>
                      ))}
                    </Combobox>
                              </div>
                              <div className="ext-pill-control fxc-base">
                                      <div className="fxc-pill">
                                        <span className="fxc-pill-content fxc-pill-content-no-remove-button fxc-pill-read-mode">
                                        <span className="fxc-pill-inner-content" id="_weave_e_1183"><div className="fxc-pill-read-container"id="_weave_e_1179"><span className="fxc-pill-content-key" id="_weave_e_1180">Product Name: </span><span className="fxc-pill-content-operator" id="_weave_e_1181">:</span><b className="fxc-pill-content-value" id="_weave_e_1182">{hotelProduct?.productName}</b></div>
                                        </span>
                                        </span>
                                      </div>
                                </div>
                                <div className="ext-pill-control fxc-base">
                                      <div className="fxc-pill">
                                        <span className="fxc-pill-content fxc-pill-content-no-remove-button fxc-pill-read-mode">
                                        <span className="fxc-pill-inner-content" id="_weave_e_1183"><div className="fxc-pill-read-container"id="_weave_e_1179"><span className="fxc-pill-content-key" id="_weave_e_1180">Status</span><span className="fxc-pill-content-operator" id="_weave_e_1181">:</span><b className="fxc-pill-content-value" id="_weave_e_1182">{hotelRequisition?.productStatus}</b></div>
                                        </span>
                                        </span>
                                      </div>
                                </div>
                               
                            </div>
                            <div className="ext-pre-sub-section fxc-base fxc-section fxc-section-wrapper">
                              <div className="fxt-tabs-inner">
                                <div className="ext-inline-flex">
                                  <div className="pcControl: metrics">
                                    <ul className="azc-metrics-container">
                                    <li>
                                    <div className="msportalfx-chart-color-3 azc-metrics-barColor">
                                        <svg focusable="false" role="presentation">
                                        <rect role="presentation" className="azc-metrics-bar-rectangle" width="5" data-bind="attr:{height:$ctl._metricSize}" height="35"></rect>
                                        </svg>
                                        </div>
                                          <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting" data-bind="azcSingleSetting:$data" data-control="true">
                                            <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Total Amount</div>
                                            <div className="azc-singleSetting-data"><div className="azc-singleSetting-value" data-bind="text: data.value">&#8358; {hotelRequisition?.totalAmount?.toLocaleString()}</div>
                                            </div>
                                            <div className="azc-singleSetting-live fxs-hide-accessible-label"></div>
                                        </div>
                                    </li>
                                    <li>
                                  
                                        <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting">
                                          <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Total Quantity</div>
                                          <div className="azc-singleSetting-data">
                                            <div className="azc-singleSetting-value">{hotelRequisition?.totalQuantity}</div>
                                            </div>
                                            <div className="azc-singleSetting-live fxs-hide-accessible-label" role="status">
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="msportalfx-chart-color-0 azc-metrics-barColor"> 
                                      <svg focusable="false" role="presentation"><rect role="presentation" className="azc-metrics-bar-rectangle" width="5" height="35"></rect></svg>                                     
                                      </div>
                                          <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting" data-bind="azcSingleSetting:$data" data-control="true">
                                            <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Quantity Received</div>
                                            <div className="azc-singleSetting-data"><div className="azc-singleSetting-value" data-bind="text: data.value">{hotelRequisition?.quantityReceived}</div>
                                            </div>
                                            <div className="azc-singleSetting-live fxs-hide-accessible-label"></div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting">
                                          <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Amount</div>
                                          <div className="azc-singleSetting-data">
                                            <div className="azc-singleSetting-value">&#8358; {hotelRequisition?.amountofQuantityRecieved?.toLocaleString()}</div>
                                            </div>
                                            <div className="azc-singleSetting-live fxs-hide-accessible-label" role="status">
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="msportalfx-chart-color-10 azc-metrics-barColor"> 
                                      <svg focusable="false" role="presentation"><rect role="presentation" className="azc-metrics-bar-rectangle" width="5" height="35"></rect></svg>                                     
                                      </div>
                                          <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting" data-bind="azcSingleSetting:$data" data-control="true">
                                            <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Quantity In Stock</div>
                                            <div className="azc-singleSetting-data"><div className="azc-singleSetting-value" data-bind="text: data.value">{hotelRequisition?.quantityInStock}</div>
                                            </div>
                                            <div className="azc-singleSetting-live fxs-hide-accessible-label"></div>
                                        </div>
                                    </li>
                                    <li>
                                      
                                        <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting">
                                          <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Amount</div>
                                          <div className="azc-singleSetting-data">
                                            <div className="azc-singleSetting-value">&#8358; {hotelRequisition?.amountOfQuantityInStock?.toLocaleString()}</div>
                                            </div>
                                            <div className="azc-singleSetting-live fxs-hide-accessible-label" role="status">
                                          </div>
                                      </div>
                                  </li>
                                    </ul>
                                  </div>
                                 
                                </div>
                              </div>
                            </div>
                            {/* <div className="ext-griddefinition-container wsx-flex-content">
                            <div className="Form-date-content">
                              <Label >Enter Quantity Received</Label>
                              <Input type="number" placeholder="Quantity Received" appearance="filled-darker" id="" />
                            </div>
                            <div className="Button-content">
                              <Button appearance="primary">Save</Button>
                            </div>
                            </div> */}

                            <div className="ext-azure-datagrid-container ext-azure-subscription-grid-cell fxc-base fxc-gc-vscroll fxc-gc-hscroll fxc-gc-fixture fxs-vivaresize">
                              <div className="fxc-gc azc-fabric fxc-gc-dataGrid">
                                <div className="fxc-gc azc-fabric fxc-gc-dataGrid">
                                <Table role="grid" aria-label="Table with grid keyboard navigation">
                                <TableHeader>
                                  <TableRow>
                                    <TableHeaderCell className="WHEIIWNB_EUBE WH_first">Product Name</TableHeaderCell>
                                    <TableHeaderCell className="WHEIIWNB_EUBE">Product type</TableHeaderCell>
                                    <TableHeaderCell className="WHEIIWNB_EUBE">Old Price</TableHeaderCell>
                                    <TableHeaderCell className="WHEIIWNB_EUBE">VAT %</TableHeaderCell>
                                    <TableHeaderCell className="WHEIIWNB_EUBE">Serving Size</TableHeaderCell>
                                    <TableHeaderCell className="WHEIIWNB_EUBE">Base Price</TableHeaderCell>
                                    <TableHeaderCell className="WHEIIWNB_EUBE WH_Last">Date Added</TableHeaderCell>
                                  </TableRow>
                                </TableHeader>
                                <TableBody>
                                   {hotelProduct && (
                                    <TableRow>
                                      <TableCell>{hotelProduct.productName}</TableCell>
                                      <TableCell>{hotelProduct.productType}</TableCell>
                                      <TableCell>&#8358; {hotelProduct.oldPrice}</TableCell>
                                      <TableCell>{hotelProduct.vat}</TableCell>
                                      <TableCell>{hotelProduct.servingSize}</TableCell>
                                      <TableCell>&#8358; {hotelProduct.price.toLocaleString()}</TableCell>
                                      <TableCell> {new Date(hotelProduct.dateAdded || "").toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</TableCell>
                                    </TableRow>
                         
                                   )}
                                    
                                </TableBody>
                              </Table>

                                </div>
                              </div>
                            </div>
                          </div>
                    </div>
                  </div>
                </div>
            </div>
              )}
               {requisistions === "Kitchen Requisition" && (
                <div className="fxs-blade-content-wrapper">
                <div className="fxs-blade-content fxs-vivaresize fxs-blade-size-reflow">
                  <div className="fxs-blade-stacklayout fxs-stacklayout fxs-stacklayout-vertical">
                    <div className="fxs-part-content">
                          <div className="msportalfx-padding ext-azure-datagrid-blade">
                            <div className='ext-info-section fxc-base fxc-section fxc-section-wrapper'></div>
                            <div className="ext-search-pill-container">
                            <div className="azc-inputbox-wrapper azc-textBox-wrapper input-qty">
                            <Combobox
                      placeholder="Search Product"
                      aria-label="autocomplete"
                      name="productid"
                      onChange={handleHotelProductChangeName}
                      onSelect={(event) => {
                        const target = event.target as HTMLInputElement;
                        const selectedValue = target.value;                 
                        let empid = getGotelProduct.find((c) =>  c.productName === selectedValue
                        );     
                        handleHotelProductsSelection(empid?.id);
                      }}
                    >
                      {getGotelProduct.map((result) => (
                        <Option
                          key={result.id}
                          value={`${result.productName}`}
                          text={`${result.productName}`}
                        >
                          <Persona
                            avatar={{
                              color: 'colorful',
                              'aria-hidden': true,
                            }}
                            name={`${result.productName}`}
                            presence={{
                              status: 'available',
                            }}
                            secondaryText={result.productType}
                          />
                        </Option>
                      ))}
                    </Combobox>
                              </div>
                              <div className="ext-pill-control fxc-base">
                                      <div className="fxc-pill">
                                        <span className="fxc-pill-content fxc-pill-content-no-remove-button fxc-pill-read-mode">
                                        <span className="fxc-pill-inner-content" id="_weave_e_1183"><div className="fxc-pill-read-container"id="_weave_e_1179"><span className="fxc-pill-content-key" id="_weave_e_1180">Product Name: </span><span className="fxc-pill-content-operator" id="_weave_e_1181">:</span><b className="fxc-pill-content-value" id="_weave_e_1182">{hotelProduct?.productName}</b></div>
                                        </span>
                                        </span>
                                      </div>
                                </div>
                                <div className="ext-pill-control fxc-base">
                                      <div className="fxc-pill">
                                        <span className="fxc-pill-content fxc-pill-content-no-remove-button fxc-pill-read-mode">
                                        <span className="fxc-pill-inner-content" id="_weave_e_1183"><div className="fxc-pill-read-container"id="_weave_e_1179"><span className="fxc-pill-content-key" id="_weave_e_1180">Status</span><span className="fxc-pill-content-operator" id="_weave_e_1181">:</span><b className="fxc-pill-content-value" id="_weave_e_1182">{hotelRequisition?.productStatus}</b></div>
                                        </span>
                                        </span>
                                      </div>
                                </div>
                               
                            </div>
                            <div className="ext-pre-sub-section fxc-base fxc-section fxc-section-wrapper">
                              <div className="fxt-tabs-inner">
                                <div className="ext-inline-flex">
                                  <div className="pcControl: metrics">
                                    <ul className="azc-metrics-container">
                                    <li>
                                    <div className="msportalfx-chart-color-3 azc-metrics-barColor">
                                        <svg focusable="false" role="presentation">
                                        <rect role="presentation" className="azc-metrics-bar-rectangle" width="5" data-bind="attr:{height:$ctl._metricSize}" height="35"></rect>
                                        </svg>
                                        </div>
                                          <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting" data-bind="azcSingleSetting:$data" data-control="true">
                                            <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Total Amount</div>
                                            <div className="azc-singleSetting-data"><div className="azc-singleSetting-value" data-bind="text: data.value">&#8358; {hotelRequisition?.totalAmount?.toLocaleString()}</div>
                                            </div>
                                            <div className="azc-singleSetting-live fxs-hide-accessible-label"></div>
                                        </div>
                                    </li>
                                    <li>
                                  
                                        <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting">
                                          <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Total Quantity</div>
                                          <div className="azc-singleSetting-data">
                                            <div className="azc-singleSetting-value">{hotelRequisition?.totalQuantity}</div>
                                            </div>
                                            <div className="azc-singleSetting-live fxs-hide-accessible-label" role="status">
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="msportalfx-chart-color-0 azc-metrics-barColor"> 
                                      <svg focusable="false" role="presentation"><rect role="presentation" className="azc-metrics-bar-rectangle" width="5" height="35"></rect></svg>                                     
                                      </div>
                                          <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting" data-bind="azcSingleSetting:$data" data-control="true">
                                            <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Quantity Received</div>
                                            <div className="azc-singleSetting-data"><div className="azc-singleSetting-value" data-bind="text: data.value">{hotelRequisition?.quantityReceived}</div>
                                            </div>
                                            <div className="azc-singleSetting-live fxs-hide-accessible-label"></div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting">
                                          <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Amount</div>
                                          <div className="azc-singleSetting-data">
                                            <div className="azc-singleSetting-value">&#8358; {hotelRequisition?.amountofQuantityRecieved?.toLocaleString()}</div>
                                            </div>
                                            <div className="azc-singleSetting-live fxs-hide-accessible-label" role="status">
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="msportalfx-chart-color-10 azc-metrics-barColor"> 
                                      <svg focusable="false" role="presentation"><rect role="presentation" className="azc-metrics-bar-rectangle" width="5" height="35"></rect></svg>                                     
                                      </div>
                                          <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting" data-bind="azcSingleSetting:$data" data-control="true">
                                            <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Quantity In Stock</div>
                                            <div className="azc-singleSetting-data"><div className="azc-singleSetting-value" data-bind="text: data.value">{hotelRequisition?.quantityInStock}</div>
                                            </div>
                                            <div className="azc-singleSetting-live fxs-hide-accessible-label"></div>
                                        </div>
                                    </li>
                                    <li>
                                      
                                        <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting">
                                          <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Amount</div>
                                          <div className="azc-singleSetting-data">
                                            <div className="azc-singleSetting-value">&#8358; {hotelRequisition?.amountOfQuantityInStock?.toLocaleString()}</div>
                                            </div>
                                            <div className="azc-singleSetting-live fxs-hide-accessible-label" role="status">
                                          </div>
                                      </div>
                                  </li>
                                    </ul>
                                  </div>
                                 
                                </div>
                              </div>
                            </div>
                            {/* <div className="ext-griddefinition-container wsx-flex-content">
                            <div className="Form-date-content">
                              <Label >Enter Quantity Received</Label>
                              <Input type="number" placeholder="Quantity Received" appearance="filled-darker" id="" />
                            </div>
                            <div className="Button-content">
                              <Button appearance="primary">Save</Button>
                            </div>
                            </div> */}

                            <div className="ext-azure-datagrid-container ext-azure-subscription-grid-cell fxc-base fxc-gc-vscroll fxc-gc-hscroll fxc-gc-fixture fxs-vivaresize">
                              <div className="fxc-gc azc-fabric fxc-gc-dataGrid">
                                <div className="fxc-gc azc-fabric fxc-gc-dataGrid">
                                <Table role="grid" aria-label="Table with grid keyboard navigation">
                                <TableHeader>
                                  <TableRow>
                                    <TableHeaderCell className="WHEIIWNB_EUBE WH_first">Product Name</TableHeaderCell>
                                    <TableHeaderCell className="WHEIIWNB_EUBE">Product type</TableHeaderCell>
                                    <TableHeaderCell className="WHEIIWNB_EUBE">Old Price</TableHeaderCell>
                                    <TableHeaderCell className="WHEIIWNB_EUBE">VAT %</TableHeaderCell>
                                    <TableHeaderCell className="WHEIIWNB_EUBE">Serving Size</TableHeaderCell>
                                    <TableHeaderCell className="WHEIIWNB_EUBE">Base Price</TableHeaderCell>
                                    <TableHeaderCell className="WHEIIWNB_EUBE WH_Last">Date Added</TableHeaderCell>
                                  </TableRow>
                                </TableHeader>
                                <TableBody>
                                   {hotelProduct && (
                                    <TableRow>
                                      <TableCell>{hotelProduct.productName}</TableCell>
                                      <TableCell>{hotelProduct.productType}</TableCell>
                                      <TableCell>&#8358; {hotelProduct.oldPrice}</TableCell>
                                      <TableCell>{hotelProduct.vat}</TableCell>
                                      <TableCell>{hotelProduct.servingSize}</TableCell>
                                      <TableCell>&#8358; {hotelProduct.price.toLocaleString()}</TableCell>
                                      <TableCell> {new Date(hotelProduct.dateAdded || "").toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</TableCell>
                                    </TableRow>
                         
                                   )}
                                    
                                </TableBody>
                              </Table>

                                </div>
                              </div>
                            </div>
                          </div>
                    </div>
                  </div>
                </div>
            </div>
              )}

              {requisistions === "Bar Requisition" && (
                <div className="fxs-blade-content-wrapper">
                <div className="fxs-blade-content fxs-vivaresize fxs-blade-size-reflow">
                  <div className="fxs-blade-stacklayout fxs-stacklayout fxs-stacklayout-vertical">
                    <div className="fxs-part-content">
                          <div className="msportalfx-padding ext-azure-datagrid-blade">
                            <div className='ext-info-section fxc-base fxc-section fxc-section-wrapper'></div>
                            <div className="ext-search-pill-container">
                            <div className="azc-inputbox-wrapper azc-textBox-wrapper input-qty">
                            <Combobox
                      placeholder="Search Product"
                      aria-label="autocomplete"
                      name="productid"
                      onChange={handleHotelProductChangeName}
                      onSelect={(event) => {
                        const target = event.target as HTMLInputElement;
                        const selectedValue = target.value;                 
                        let empid = getGotelProduct.find((c) =>  c.productName === selectedValue
                        );     
                        handleHotelProductsSelection(empid?.id);
                      }}
                    >
                      {getGotelProduct.map((result) => (
                        <Option
                          key={result.id}
                          value={`${result.productName}`}
                          text={`${result.productName}`}
                        >
                          <Persona
                            avatar={{
                              color: 'colorful',
                              'aria-hidden': true,
                            }}
                            name={`${result.productName}`}
                            presence={{
                              status: 'available',
                            }}
                            secondaryText={result.productType}
                          />
                        </Option>
                      ))}
                    </Combobox>
                              </div>
                              <div className="ext-pill-control fxc-base">
                                      <div className="fxc-pill">
                                        <span className="fxc-pill-content fxc-pill-content-no-remove-button fxc-pill-read-mode">
                                        <span className="fxc-pill-inner-content" id="_weave_e_1183"><div className="fxc-pill-read-container"id="_weave_e_1179"><span className="fxc-pill-content-key" id="_weave_e_1180">Product Name: </span><span className="fxc-pill-content-operator" id="_weave_e_1181">:</span><b className="fxc-pill-content-value" id="_weave_e_1182">{hotelProduct?.productName}</b></div>
                                        </span>
                                        </span>
                                      </div>
                                </div>
                                <div className="ext-pill-control fxc-base">
                                      <div className="fxc-pill">
                                        <span className="fxc-pill-content fxc-pill-content-no-remove-button fxc-pill-read-mode">
                                        <span className="fxc-pill-inner-content" id="_weave_e_1183"><div className="fxc-pill-read-container"id="_weave_e_1179"><span className="fxc-pill-content-key" id="_weave_e_1180">Status</span><span className="fxc-pill-content-operator" id="_weave_e_1181">:</span><b className="fxc-pill-content-value" id="_weave_e_1182">{hotelRequisition?.productStatus}</b></div>
                                        </span>
                                        </span>
                                      </div>
                                </div>
                               
                            </div>
                            <div className="ext-pre-sub-section fxc-base fxc-section fxc-section-wrapper">
                              <div className="fxt-tabs-inner">
                                <div className="ext-inline-flex">
                                  <div className="pcControl: metrics">
                                    <ul className="azc-metrics-container">
                                    <li>
                                    <div className="msportalfx-chart-color-3 azc-metrics-barColor">
                                        <svg focusable="false" role="presentation">
                                        <rect role="presentation" className="azc-metrics-bar-rectangle" width="5" data-bind="attr:{height:$ctl._metricSize}" height="35"></rect>
                                        </svg>
                                        </div>
                                          <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting" data-bind="azcSingleSetting:$data" data-control="true">
                                            <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Total Amount</div>
                                            <div className="azc-singleSetting-data"><div className="azc-singleSetting-value" data-bind="text: data.value">&#8358; {hotelRequisition?.totalAmount?.toLocaleString()}</div>
                                            </div>
                                            <div className="azc-singleSetting-live fxs-hide-accessible-label"></div>
                                        </div>
                                    </li>
                                    <li>
                                  
                                        <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting">
                                          <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Total Quantity</div>
                                          <div className="azc-singleSetting-data">
                                            <div className="azc-singleSetting-value">{hotelRequisition?.totalQuantity}</div>
                                            </div>
                                            <div className="azc-singleSetting-live fxs-hide-accessible-label" role="status">
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="msportalfx-chart-color-0 azc-metrics-barColor"> 
                                      <svg focusable="false" role="presentation"><rect role="presentation" className="azc-metrics-bar-rectangle" width="5" height="35"></rect></svg>                                     
                                      </div>
                                          <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting" data-bind="azcSingleSetting:$data" data-control="true">
                                            <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Quantity Received</div>
                                            <div className="azc-singleSetting-data"><div className="azc-singleSetting-value" data-bind="text: data.value">{hotelRequisition?.quantityReceived}</div>
                                            </div>
                                            <div className="azc-singleSetting-live fxs-hide-accessible-label"></div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting">
                                          <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Amount</div>
                                          <div className="azc-singleSetting-data">
                                            <div className="azc-singleSetting-value">&#8358; {hotelRequisition?.amountofQuantityRecieved?.toLocaleString()}</div>
                                            </div>
                                            <div className="azc-singleSetting-live fxs-hide-accessible-label" role="status">
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="msportalfx-chart-color-10 azc-metrics-barColor"> 
                                      <svg focusable="false" role="presentation"><rect role="presentation" className="azc-metrics-bar-rectangle" width="5" height="35"></rect></svg>                                     
                                      </div>
                                          <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting" data-bind="azcSingleSetting:$data" data-control="true">
                                            <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Quantity In Stock</div>
                                            <div className="azc-singleSetting-data"><div className="azc-singleSetting-value" data-bind="text: data.value">{hotelRequisition?.quantityInStock}</div>
                                            </div>
                                            <div className="azc-singleSetting-live fxs-hide-accessible-label"></div>
                                        </div>
                                    </li>
                                    <li>
                                      
                                        <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting">
                                          <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Amount</div>
                                          <div className="azc-singleSetting-data">
                                            <div className="azc-singleSetting-value">&#8358; {hotelRequisition?.amountOfQuantityInStock?.toLocaleString()}</div>
                                            </div>
                                            <div className="azc-singleSetting-live fxs-hide-accessible-label" role="status">
                                          </div>
                                      </div>
                                  </li>
                                    </ul>
                                  </div>
                                 
                                </div>
                              </div>
                            </div>
                            {/* <div className="ext-griddefinition-container wsx-flex-content">
                            <div className="Form-date-content">
                              <Label >Enter Quantity Received</Label>
                              <Input type="number" placeholder="Quantity Received" appearance="filled-darker" id="" />
                            </div>
                            <div className="Button-content">
                              <Button appearance="primary">Save</Button>
                            </div>
                            </div> */}

                            <div className="ext-azure-datagrid-container ext-azure-subscription-grid-cell fxc-base fxc-gc-vscroll fxc-gc-hscroll fxc-gc-fixture fxs-vivaresize">
                              <div className="fxc-gc azc-fabric fxc-gc-dataGrid">
                                <div className="fxc-gc azc-fabric fxc-gc-dataGrid">
                                <Table role="grid" aria-label="Table with grid keyboard navigation">
                                <TableHeader>
                                  <TableRow>
                                    <TableHeaderCell className="WHEIIWNB_EUBE WH_first">Product Name</TableHeaderCell>
                                    <TableHeaderCell className="WHEIIWNB_EUBE">Product type</TableHeaderCell>
                                    <TableHeaderCell className="WHEIIWNB_EUBE">Old Price</TableHeaderCell>
                                    <TableHeaderCell className="WHEIIWNB_EUBE">VAT %</TableHeaderCell>
                                    <TableHeaderCell className="WHEIIWNB_EUBE">Serving Size</TableHeaderCell>
                                    <TableHeaderCell className="WHEIIWNB_EUBE">Base Price</TableHeaderCell>
                                    <TableHeaderCell className="WHEIIWNB_EUBE WH_Last">Date Added</TableHeaderCell>
                                  </TableRow>
                                </TableHeader>
                                <TableBody>
                                   {hotelProduct && (
                                    <TableRow>
                                      <TableCell>{hotelProduct.productName}</TableCell>
                                      <TableCell>{hotelProduct.productType}</TableCell>
                                      <TableCell>&#8358; {hotelProduct.oldPrice}</TableCell>
                                      <TableCell>{hotelProduct.vat}</TableCell>
                                      <TableCell>{hotelProduct.servingSize}</TableCell>
                                      <TableCell>&#8358; {hotelProduct.price.toLocaleString()}</TableCell>
                                      <TableCell> {new Date(hotelProduct.dateAdded || "").toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</TableCell>
                                    </TableRow>
                         
                                   )}
                                    
                                </TableBody>
                              </Table>

                                </div>
                              </div>
                            </div>
                          </div>
                    </div>
                  </div>
                </div>
            </div>
              )}
               {requisistions === "Receiving Stock" && (
                <div className="fxs-blade-content-wrapper">
                <div className="fxs-blade-content fxs-vivaresize fxs-blade-size-reflow">
                  <div className="fxs-blade-stacklayout fxs-stacklayout fxs-stacklayout-vertical">
                    <div className="fxs-part-content">
                          <div className="msportalfx-padding ext-azure-datagrid-blade">
                            <div className='ext-info-section fxc-base fxc-section fxc-section-wrapper'></div>
                            <div className="ext-search-pill-container">
                            <div className="azc-inputbox-wrapper azc-textBox-wrapper input-qty">
                            <Combobox
                      placeholder="Search Product"
                      aria-label="autocomplete"
                      name="productid"
                      onChange={handleHotelProductChangeName}
                      onSelect={(event) => {
                        const target = event.target as HTMLInputElement;
                        const selectedValue = target.value;                 
                        let empid = getGotelProduct.find((c) =>  c.productName === selectedValue
                        );     
                        handleHotelProductsSelection(empid?.id);
                      }}
                    >
                      {getGotelProduct.map((result) => (
                        <Option
                          key={result.id}
                          value={`${result.productName}`}
                          text={`${result.productName}`}
                        >
                          <Persona
                            avatar={{
                              color: 'colorful',
                              'aria-hidden': true,
                            }}
                            name={`${result.productName}`}
                            presence={{
                              status: 'available',
                            }}
                            secondaryText={result.productType}
                          />
                        </Option>
                      ))}
                    </Combobox>
                              </div>
                              <div className="ext-pill-control fxc-base">
                                      <div className="fxc-pill">
                                        <span className="fxc-pill-content fxc-pill-content-no-remove-button fxc-pill-read-mode">
                                        <span className="fxc-pill-inner-content" id="_weave_e_1183"><div className="fxc-pill-read-container"id="_weave_e_1179"><span className="fxc-pill-content-key" id="_weave_e_1180">Product Name: </span><span className="fxc-pill-content-operator" id="_weave_e_1181">:</span><b className="fxc-pill-content-value" id="_weave_e_1182">{hotelProduct?.productName}</b></div>
                                        </span>
                                        </span>
                                      </div>
                                </div>
                                <div className="ext-pill-control fxc-base">
                                      <div className="fxc-pill">
                                        <span className="fxc-pill-content fxc-pill-content-no-remove-button fxc-pill-read-mode">
                                        <span className="fxc-pill-inner-content" id="_weave_e_1183"><div className="fxc-pill-read-container"id="_weave_e_1179"><span className="fxc-pill-content-key" id="_weave_e_1180">Status</span><span className="fxc-pill-content-operator" id="_weave_e_1181">:</span><b className="fxc-pill-content-value" id="_weave_e_1182">{hotelRequisition?.productStatus}</b></div>
                                        </span>
                                        </span>
                                      </div>
                                </div>
                               
                            </div>
                            <div className="ext-pre-sub-section fxc-base fxc-section fxc-section-wrapper">
                              <div className="fxt-tabs-inner">
                                <div className="ext-inline-flex">
                                  <div className="pcControl: metrics">
                                    <ul className="azc-metrics-container">
                                    <li>
                                    <div className="msportalfx-chart-color-3 azc-metrics-barColor">
                                        <svg focusable="false" role="presentation">
                                        <rect role="presentation" className="azc-metrics-bar-rectangle" width="5" data-bind="attr:{height:$ctl._metricSize}" height="35"></rect>
                                        </svg>
                                        </div>
                                          <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting" data-bind="azcSingleSetting:$data" data-control="true">
                                            <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Total Amount</div>
                                            <div className="azc-singleSetting-data"><div className="azc-singleSetting-value" data-bind="text: data.value">&#8358; {hotelRequisition?.totalAmount?.toLocaleString()}</div>
                                            </div>
                                            <div className="azc-singleSetting-live fxs-hide-accessible-label"></div>
                                        </div>
                                    </li>
                                    <li>
                                  
                                        <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting">
                                          <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Total Quantity</div>
                                          <div className="azc-singleSetting-data">
                                            <div className="azc-singleSetting-value">{hotelRequisition?.totalQuantity}</div>
                                            </div>
                                            <div className="azc-singleSetting-live fxs-hide-accessible-label" role="status">
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="msportalfx-chart-color-0 azc-metrics-barColor"> 
                                      <svg focusable="false" role="presentation"><rect role="presentation" className="azc-metrics-bar-rectangle" width="5" height="35"></rect></svg>                                     
                                      </div>
                                          <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting" data-bind="azcSingleSetting:$data" data-control="true">
                                            <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Quantity Received</div>
                                            <div className="azc-singleSetting-data"><div className="azc-singleSetting-value" data-bind="text: data.value">{hotelRequisition?.quantityReceived}</div>
                                            </div>
                                            <div className="azc-singleSetting-live fxs-hide-accessible-label"></div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting">
                                          <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Amount</div>
                                          <div className="azc-singleSetting-data">
                                            <div className="azc-singleSetting-value">&#8358; {hotelRequisition?.amountofQuantityRecieved?.toLocaleString()}</div>
                                            </div>
                                            <div className="azc-singleSetting-live fxs-hide-accessible-label" role="status">
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="msportalfx-chart-color-10 azc-metrics-barColor"> 
                                      <svg focusable="false" role="presentation"><rect role="presentation" className="azc-metrics-bar-rectangle" width="5" height="35"></rect></svg>                                     
                                      </div>
                                          <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting" data-bind="azcSingleSetting:$data" data-control="true">
                                            <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Quantity In Stock</div>
                                            <div className="azc-singleSetting-data"><div className="azc-singleSetting-value" data-bind="text: data.value">{hotelRequisition?.quantityInStock}</div>
                                            </div>
                                            <div className="azc-singleSetting-live fxs-hide-accessible-label"></div>
                                        </div>
                                    </li>
                                    <li>
                                      
                                        <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting">
                                          <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Amount</div>
                                          <div className="azc-singleSetting-data">
                                            <div className="azc-singleSetting-value">&#8358; {hotelRequisition?.amountOfQuantityInStock?.toLocaleString()}</div>
                                            </div>
                                            <div className="azc-singleSetting-live fxs-hide-accessible-label" role="status">
                                          </div>
                                      </div>
                                  </li>
                                    </ul>
                                  </div>
                                 
                                </div>
                              </div>
                            </div>
                            {/* <div className="ext-griddefinition-container wsx-flex-content">
                            <div className="Form-date-content">
                              <Label >Enter Quantity Received</Label>
                              <Input type="number" placeholder="Quantity Received" appearance="filled-darker" id="" />
                            </div>
                            <div className="Button-content">
                              <Button appearance="primary">Save</Button>
                            </div>
                            </div> */}

                            <div className="ext-azure-datagrid-container ext-azure-subscription-grid-cell fxc-base fxc-gc-vscroll fxc-gc-hscroll fxc-gc-fixture fxs-vivaresize">
                              <div className="fxc-gc azc-fabric fxc-gc-dataGrid">
                                <div className="fxc-gc azc-fabric fxc-gc-dataGrid">
                                <Table role="grid" aria-label="Table with grid keyboard navigation">
                                <TableHeader>
                                  <TableRow>
                                    <TableHeaderCell className="WHEIIWNB_EUBE WH_first">Product Name</TableHeaderCell>
                                    <TableHeaderCell className="WHEIIWNB_EUBE">Product type</TableHeaderCell>
                                    <TableHeaderCell className="WHEIIWNB_EUBE">Old Price</TableHeaderCell>
                                    <TableHeaderCell className="WHEIIWNB_EUBE">VAT %</TableHeaderCell>
                                    <TableHeaderCell className="WHEIIWNB_EUBE">Serving Size</TableHeaderCell>
                                    <TableHeaderCell className="WHEIIWNB_EUBE">Base Price</TableHeaderCell>
                                    <TableHeaderCell className="WHEIIWNB_EUBE WH_Last">Date Added</TableHeaderCell>
                                  </TableRow>
                                </TableHeader>
                                <TableBody>
                                   {hotelProduct && (
                                    <TableRow>
                                      <TableCell>{hotelProduct.productName}</TableCell>
                                      <TableCell>{hotelProduct.productType}</TableCell>
                                      <TableCell>&#8358; {hotelProduct.oldPrice}</TableCell>
                                      <TableCell>{hotelProduct.vat}</TableCell>
                                      <TableCell>{hotelProduct.servingSize}</TableCell>
                                      <TableCell>&#8358; {hotelProduct.price.toLocaleString()}</TableCell>
                                      <TableCell> {new Date(hotelProduct.dateAdded || "").toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</TableCell>
                                    </TableRow>
                         
                                   )}
                                    
                                </TableBody>
                              </Table>

                                </div>
                              </div>
                            </div>
                          </div>
                    </div>
                  </div>
                </div>
            </div>
              )}
               {requisistions === "Opening Stock" && (
                 <div className="fxs-blade-content-wrapper">
                 <div className="fxs-blade-content fxs-vivaresize fxs-blade-size-reflow">
                   <div className="fxs-blade-stacklayout fxs-stacklayout fxs-stacklayout-vertical">
                     <div className="fxs-part-content">
                           <div className="msportalfx-padding ext-azure-datagrid-blade">
                             <div className='ext-info-section fxc-base fxc-section fxc-section-wrapper'></div>
                             <div className="ext-search-pill-container">
                             <div className="azc-inputbox-wrapper azc-textBox-wrapper input-qty">
                             <Combobox
                       placeholder="Search Product"
                       aria-label="autocomplete"
                       name="productid"
                       onChange={handleHotelProductChangeName}
                       onSelect={(event) => {
                         const target = event.target as HTMLInputElement;
                         const selectedValue = target.value;                 
                         let empid = getGotelProduct.find((c) =>  c.productName === selectedValue
                         );     
                         handleHotelProductsSelection(empid?.id);
                       }}
                     >
                       {getGotelProduct.map((result) => (
                         <Option
                           key={result.id}
                           value={`${result.productName}`}
                           text={`${result.productName}`}
                         >
                           <Persona
                             avatar={{
                               color: 'colorful',
                               'aria-hidden': true,
                             }}
                             name={`${result.productName}`}
                             presence={{
                               status: 'available',
                             }}
                             secondaryText={result.productType}
                           />
                         </Option>
                       ))}
                     </Combobox>
                               </div>
                               <div className="ext-pill-control fxc-base">
                                       <div className="fxc-pill">
                                         <span className="fxc-pill-content fxc-pill-content-no-remove-button fxc-pill-read-mode">
                                         <span className="fxc-pill-inner-content" id="_weave_e_1183"><div className="fxc-pill-read-container"id="_weave_e_1179"><span className="fxc-pill-content-key" id="_weave_e_1180">Product Name: </span><span className="fxc-pill-content-operator" id="_weave_e_1181">:</span><b className="fxc-pill-content-value" id="_weave_e_1182">{hotelProduct?.productName}</b></div>
                                         </span>
                                         </span>
                                       </div>
                                 </div>
                                 <div className="ext-pill-control fxc-base">
                                       <div className="fxc-pill">
                                         <span className="fxc-pill-content fxc-pill-content-no-remove-button fxc-pill-read-mode">
                                         <span className="fxc-pill-inner-content" id="_weave_e_1183"><div className="fxc-pill-read-container"id="_weave_e_1179"><span className="fxc-pill-content-key" id="_weave_e_1180">Status</span><span className="fxc-pill-content-operator" id="_weave_e_1181">:</span><b className="fxc-pill-content-value" id="_weave_e_1182">{hotelRequisition?.productStatus}</b></div>
                                         </span>
                                         </span>
                                       </div>
                                 </div>
                                
                             </div>
                             <div className="ext-pre-sub-section fxc-base fxc-section fxc-section-wrapper">
                               <div className="fxt-tabs-inner">
                                 <div className="ext-inline-flex">
                                   <div className="pcControl: metrics">
                                     <ul className="azc-metrics-container">
                                     <li>
                                     <div className="msportalfx-chart-color-3 azc-metrics-barColor">
                                         <svg focusable="false" role="presentation">
                                         <rect role="presentation" className="azc-metrics-bar-rectangle" width="5" data-bind="attr:{height:$ctl._metricSize}" height="35"></rect>
                                         </svg>
                                         </div>
                                           <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting" data-bind="azcSingleSetting:$data" data-control="true">
                                             <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Total Amount</div>
                                             <div className="azc-singleSetting-data"><div className="azc-singleSetting-value" data-bind="text: data.value">&#8358; {hotelRequisition?.totalAmount?.toLocaleString()}</div>
                                             </div>
                                             <div className="azc-singleSetting-live fxs-hide-accessible-label"></div>
                                         </div>
                                     </li>
                                     <li>
                                   
                                         <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting">
                                           <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Total Quantity</div>
                                           <div className="azc-singleSetting-data">
                                             <div className="azc-singleSetting-value">{hotelRequisition?.totalQuantity}</div>
                                             </div>
                                             <div className="azc-singleSetting-live fxs-hide-accessible-label" role="status">
                                           </div>
                                       </div>
                                   </li>
                                   <li>
                                       <div className="msportalfx-chart-color-0 azc-metrics-barColor"> 
                                       <svg focusable="false" role="presentation"><rect role="presentation" className="azc-metrics-bar-rectangle" width="5" height="35"></rect></svg>                                     
                                       </div>
                                           <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting" data-bind="azcSingleSetting:$data" data-control="true">
                                             <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Quantity Received</div>
                                             <div className="azc-singleSetting-data"><div className="azc-singleSetting-value" data-bind="text: data.value">{hotelRequisition?.quantityReceived}</div>
                                             </div>
                                             <div className="azc-singleSetting-live fxs-hide-accessible-label"></div>
                                         </div>
                                     </li>
                                     <li>
                                         <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting">
                                           <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Amount</div>
                                           <div className="azc-singleSetting-data">
                                             <div className="azc-singleSetting-value">&#8358; {hotelRequisition?.amountofQuantityRecieved?.toLocaleString()}</div>
                                             </div>
                                             <div className="azc-singleSetting-live fxs-hide-accessible-label" role="status">
                                           </div>
                                       </div>
                                   </li>
                                   <li>
                                       <div className="msportalfx-chart-color-10 azc-metrics-barColor"> 
                                       <svg focusable="false" role="presentation"><rect role="presentation" className="azc-metrics-bar-rectangle" width="5" height="35"></rect></svg>                                     
                                       </div>
                                           <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting" data-bind="azcSingleSetting:$data" data-control="true">
                                             <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Quantity In Stock</div>
                                             <div className="azc-singleSetting-data"><div className="azc-singleSetting-value" data-bind="text: data.value">{hotelRequisition?.quantityInStock}</div>
                                             </div>
                                             <div className="azc-singleSetting-live fxs-hide-accessible-label"></div>
                                         </div>
                                     </li>
                                     <li>
                                       
                                         <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting">
                                           <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Amount</div>
                                           <div className="azc-singleSetting-data">
                                             <div className="azc-singleSetting-value">&#8358; {hotelRequisition?.amountOfQuantityInStock?.toLocaleString()}</div>
                                             </div>
                                             <div className="azc-singleSetting-live fxs-hide-accessible-label" role="status">
                                           </div>
                                       </div>
                                   </li>
                                     </ul>
                                   </div>
                                  
                                 </div>
                               </div>
                             </div>
                             {/* <div className="ext-griddefinition-container wsx-flex-content">
                             <div className="Form-date-content">
                               <Label >Enter Quantity Received</Label>
                               <Input type="number" placeholder="Quantity Received" appearance="filled-darker" id="" />
                             </div>
                             <div className="Button-content">
                               <Button appearance="primary">Save</Button>
                             </div>
                             </div> */}
 
                             <div className="ext-azure-datagrid-container ext-azure-subscription-grid-cell fxc-base fxc-gc-vscroll fxc-gc-hscroll fxc-gc-fixture fxs-vivaresize">
                               <div className="fxc-gc azc-fabric fxc-gc-dataGrid">
                                 <div className="fxc-gc azc-fabric fxc-gc-dataGrid">
                                 <Table role="grid" aria-label="Table with grid keyboard navigation">
                                 <TableHeader>
                                   <TableRow>
                                     <TableHeaderCell className="WHEIIWNB_EUBE WH_first">Product Name</TableHeaderCell>
                                     <TableHeaderCell className="WHEIIWNB_EUBE">Product type</TableHeaderCell>
                                     <TableHeaderCell className="WHEIIWNB_EUBE">Old Price</TableHeaderCell>
                                     <TableHeaderCell className="WHEIIWNB_EUBE">VAT %</TableHeaderCell>
                                     <TableHeaderCell className="WHEIIWNB_EUBE">Serving Size</TableHeaderCell>
                                     <TableHeaderCell className="WHEIIWNB_EUBE">Base Price</TableHeaderCell>
                                     <TableHeaderCell className="WHEIIWNB_EUBE WH_Last">Date Added</TableHeaderCell>
                                   </TableRow>
                                 </TableHeader>
                                 <TableBody>
                                    {hotelProduct && (
                                     <TableRow>
                                       <TableCell>{hotelProduct.productName}</TableCell>
                                       <TableCell>{hotelProduct.productType}</TableCell>
                                       <TableCell>&#8358; {hotelProduct.oldPrice}</TableCell>
                                       <TableCell>{hotelProduct.vat}</TableCell>
                                       <TableCell>{hotelProduct.servingSize}</TableCell>
                                       <TableCell>&#8358; {hotelProduct.price.toLocaleString()}</TableCell>
                                       <TableCell> {new Date(hotelProduct.dateAdded || "").toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</TableCell>
                                     </TableRow>
                          
                                    )}
                                     
                                 </TableBody>
                               </Table>
 
                                 </div>
                               </div>
                             </div>
                           </div>
                     </div>
                   </div>
                 </div>
             </div>
              )}
              </>
            </div>
          </div>
        </div>
        </section>
      </div>
    </div>
    {openReqForm && (
      <RQSForms onClose={HandleCloseRequisitionForm} productid={productID} message={message} user={user} />
    )}
    </>
 
  );
};

export default SellsPage;
