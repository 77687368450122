import React, { useState } from "react";
import "./Calender.scss";
import { Button, Divider, Menu, MenuButton, MenuButtonProps, MenuItem, MenuList, MenuPopover, MenuTrigger, PresenceBadge, SplitButton, Tab, TabList, Toolbar, ToolbarButton, ToolbarDivider, ToolbarToggleButton } from "@fluentui/react-components";
import { CalendarAddFilled, CalendarAddRegular, CalendarAgendaRegular, CalendarArrowCounterclockwiseFilled, CalendarDayRegular, CalendarEmptyRegular, CalendarFilled, CalendarMonthRegular, ChevronDownRegular, ChevronUpRegular, DiversityFilled, LocationAddRegular, PrintFilled, PrintRegular, ShareRegular } from "@fluentui/react-icons";
import NOBOOKEDINGITEM from "../../Themes/Images/illustration_balloon-hash.svg";
import { Calendar } from "@fluentui/react-calendar-compat";
import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
  } from "@fluentui/react-components";

  import {Field, makeStyles } from "@fluentui/react-components";
  import { DatePicker } from "@fluentui/react-datepicker-compat";
  const useStyles = makeStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      rowGap: "15px",
    },
    control: {
      maxWidth: "300px",
    },
    button: {
      marginRight: "10px",
    },
  });

  const CilpronCalenderClass = [
    'FIU_1', 'FIU_2', 'FIU_3', 'FIU_4', 'FIU_5', 'FIU_6', 'FIU_7', 'FIU_8', 'FIU_9', 'FIU_10',
    'FIU_11', 'FIU_44', 'FIU_12', 'FIU_13', 'FIU_14', 'FIU_15', 'FIU_16', 'FIU_17', 'FIU_18', 'FIU_19',
    'FIU_20', 'FIU_21', 'FIU_22', 'FIU_23', 'FIU_24', 'FIU_25', 'FIU_26', 'FIU_27', 'FIU_28', 'FIU_29',
    'FIU_30', 'FIU_31', 'FIU_Next_1', 'FIU_Next_2', 'FIU_Next_3'
  ];

  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


const Calenders: React.FC = () => {
    const [selectedDate, setSelectedDate] = useState<Date>();
    const [selectedSecondDate, setSelectedSecondDate] = React.useState<
    Date | null | undefined
  >(null);
  const [date, setDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const onSelectDate = React.useCallback((date: Date, _: any): void => {
      setSelectedDate(date);
    }, []);
    const styles = useStyles();
   

 
    const today = new Date();
  
  
    const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    const lastDayOfLastMonth = new Date(date.getFullYear(), date.getMonth(), 0).getDate();
  
    let days = [];
  
    for (let i = firstDayOfMonth - 1; i >= 0; i--) {
      days.push(
        <div className={`xXnRw DYu5t ${CilpronCalenderClass[days.length]}`}>
          <div className="j8ViM lxYBp AmDXP eaZQn">
            <div id={`header-${date.getFullYear()}`} className="vkvJ8 mbvuN">
              {`${monthNames[(date.getMonth() - 1 + 12) % 12]} ${lastDayOfLastMonth - i}`}
            </div>
            <div className="jtvnC"></div>
          </div>
        </div>
      );
    }
  
    for (let i = 1; i <= daysInMonth; i++) {
      days.push(
        <div className={`xXnRw DYu5t Jg_Xw ${CilpronCalenderClass[days.length]}`}>
          <div className={`j8ViM ${date.getMonth() === today.getMonth() && i === today.getDate() ? 'LtiaT' : 'lxYBp AmDXP'}`}>
            <div className="vkvJ8 mbvuN">
              {i === 1 ? `${monthNames[date.getMonth()]} ` : ''}
              {date.getMonth() === today.getMonth() && i === today.getDate() ? <div className="dNGgF">{i}</div> : i}
            </div>
            <div className="jtvnC">
              <Button icon={<LocationAddRegular />}></Button>
            </div>
          </div>
        </div>
      );
    }
  
    while (days.length < 35) {
      let nextDay = days.length - firstDayOfMonth - daysInMonth + 1;
      days.push(
        <div className={`xXnRw DYu5t Jg_Xw ${CilpronCalenderClass[days.length]}`}>
          <div className="j8ViM lxYBp AmDXP">
            <div className="vkvJ8 mbvuN">
              {nextDay === 1 ? `${monthNames[(date.getMonth() + 1) % 12]} ${nextDay}` : nextDay}
            </div>
            <div className="jtvnC">
              <Button icon={<LocationAddRegular />}></Button>
            </div>
          </div>
        </div>
      );
    }
  
    const nextMonth = () => {
      setDate(new Date(date.setMonth(date.getMonth() + 1)));
    };
  
    const prevMonth = () => {
      setDate(new Date(date.setMonth(date.getMonth() - 1)));
    };
  

    return (
        <>
        <div className="p8iVF">
            <div className="sYndo">
                <div className="O0VOp">
                    <div className="ms-TooltipHost root-157">
                    <Button icon={<DiversityFilled />}>
                    </Button>
                    </div>
                    <div className="VvU3M">
                        <div>
                            <div>
                                <div>
                                    <div className="root-166">
                                    <TabList defaultSelectedValue="tab1">
                                        <Tab value="tab1">Home</Tab>
                                        <Tab value="tab2">View</Tab>
                                        <Tab value="tab3">Help</Tab>
                                    </TabList>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="qVOpN">
                    <div className="Mezkw">
                        <div>
                            <div>
                                <div className="ms-FocusZone css-168 MKEYBE">
                                    <div className="kGvEO root-180">
                                        <div className="A9JLN tabContentContainer-181">
                                        <Toolbar className="ui-toolbar" aria-label="with Popover" size="small">
                                            <ToolbarButton>
                                            <Menu positioning="below-end">
                                            <MenuTrigger disableButtonEnhancement>
                                            {(triggerProps: MenuButtonProps) => (
                                                <SplitButton className="Managebuton-splited" icon={<CalendarEmptyRegular />} menuButton={triggerProps} appearance="primary">
                                               Email Client
                                                </SplitButton>
                                            )}
                                            </MenuTrigger>

                                            <MenuPopover>
                                            <MenuList>
                                                <MenuItem>Email Client</MenuItem>
                                                <MenuItem>Book Event</MenuItem>
                                            </MenuList>
                                            </MenuPopover>
                                        </Menu>
                                            </ToolbarButton>
                                            <Menu>
                                            <MenuTrigger disableButtonEnhancement>
                                            <MenuButton icon={<CalendarDayRegular
 />}>Day</MenuButton>
                                            </MenuTrigger>

                                            <MenuPopover>
                                            <MenuList>
                                                <MenuItem>Item a</MenuItem>
                                                <MenuItem>Item b</MenuItem>
                                            </MenuList>
                                            </MenuPopover>
                                        </Menu>
                                        <ToolbarToggleButton
                                        aria-label="Week"
                                        icon={<CalendarAgendaRegular
                                            />}
                                        name="textOptions"
                                        value="week">Week</ToolbarToggleButton>
                                        <ToolbarToggleButton
                                        aria-label="Month"
                                        icon={<CalendarMonthRegular
                                            />}
                                        name="textOptions"
                                        value="Month">Month</ToolbarToggleButton>

                                        <ToolbarDivider />
                                        <ToolbarToggleButton
                                        aria-label="share"
                                        icon={<ShareRegular
                                            />}
                                        name="textOptions"
                                        value="Share">Share</ToolbarToggleButton>
                                         <ToolbarToggleButton
                                        aria-label="Print"
                                        icon={<PrintRegular
                                            />}
                                        name="textOptions"
                                        value="Print">Print</ToolbarToggleButton>
                                     </Toolbar>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                <div className="CILPRON-CONTAINER"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ptiWl">
                        <Button icon={<ChevronDownRegular
 />}></Button>
                    </div>
                </div>
                <span className="ms-layer"></span>
            </div>
        </div>
        <div className="rD9B8 RBJZN">
            <div className="I1FTF">
                <div className="EBXzq">
                    <div className="rxV0N a7Ps_">
                        <div className="UJUO4">
                            <div className="lp63L">
                            <Calendar
                    showMonthPickerAsOverlay
                    highlightSelectedMonth
                    showGoToToday={false}
                    onSelectDate={onSelectDate}
                    value={selectedDate}
                />
                            </div>
                        </div>
                  <div className="Oc1Jy">
                    <div className="SFJ5T">
                        <div className="MY30o add-bordertop">
                        <Button icon={<CalendarAddRegular />}> Add To Calender</Button>
                        </div>
                        <div className="AMOVa customScrollBar">
                            <div className="h96U3 bzgJa">
                                <div className="h96U3">
                                    <ul>
                                        <li className="FVY0q MY30o">
                                            <div className="GsziR">
                                            <Accordion collapsible>
                                                <AccordionItem value="1">
                                                <AccordionHeader>My Calenders</AccordionHeader>
                                                <AccordionPanel>
                                                    <div className="ms-FocusZone css-168 q1a_R">
                                                        <div className="GsziR">
                                                            <div className="feQ6T yxmiB Calender-color">
                                                                <Button icon={<PresenceBadge  />}>Calender</Button>
                                                            </div>
                                                        </div>
                                                        <div className="GsziR">
                                                            <div className="feQ6T yxmiB checkedIn-color">
                                                                <Button icon={<PresenceBadge status="available" />}>Checked In </Button>
                                                            </div>
                                                        </div>
                                                        <div className="GsziR">
                                                            <div className="feQ6T yxmiB checkedOut-color">
                                                                <Button icon={<PresenceBadge  />}>Cheacking Out </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </AccordionPanel>
                                                </AccordionItem>
                                                </Accordion>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                    
                    </div>
                </div>
            </div>
            <div className="zzOrI t1VdD"></div>
            <div className="Ugldg css-297">
                <div className="ZlutZ">
                    <div className="PddJU">
                        <div className="d8LIg">
                        <Toolbar aria-label="Default">
                        <ToolbarButton
                        aria-label="Increase Font Size"
                        >Today</ToolbarButton>
                         <ToolbarButton
                        aria-label="Increase Font Size"
                        onClick={prevMonth}

                        icon={<ChevronUpRegular />}
                        />
                         <ToolbarButton
                        aria-label="Increase Font Size"
                        onClick={nextMonth}
                        icon={<ChevronDownRegular />}
                        />
                         <ToolbarButton className="remove_Background">
                         <Menu>
                            <MenuTrigger disableButtonEnhancement>
                            <MenuButton>January 2024</MenuButton>
                            </MenuTrigger>

                            <MenuPopover>
                            <MenuList>
                                <MenuItem>Item a</MenuItem>
                                <MenuItem>Item b</MenuItem>
                            </MenuList>
                            </MenuPopover>
                        </Menu>
                           {/* <span className="ms-Button-flexContainer flexContainer-314">
                            <div className="zVmbM eekLH">
                            <div className="AUyxT zVmbM">January 2024</div>
                            <ChevronDownRegular />
                            </div>
                           </span> */}
                        </ToolbarButton>
                        
                        </Toolbar>

                        </div>
                        <div className="iaCtK">
                            <div className="ONUhz">
                                <div className="BbwdT">
                                    <div>
                                        <div>
                                            <div className="VvYef Wth9e">
                                                <div className="vuk60">
                                                    <div className="jJuEc">
                                                        <div className="B5Pd6 Xky5j">
                                                        Sunday
                                                        </div>
                                                        <div className="B5Pd6 Xky5j">
                                                        Monday
                                                        </div>
                                                        <div className="B5Pd6 Xky5j">
                                                        Tuesday
                                                        </div>
                                                        <div className="B5Pd6 Xky5j">
                                                        Wednesday
                                                        </div>
                                                        <div className="B5Pd6 Xky5j">
                                                        Thursday
                                                        </div>
                                                        <div className="B5Pd6 Xky5j">
                                                        Friday
                                                        </div>
                                                        <div className="B5Pd6 Xky5j">
                                                        Saturday
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="DBT5H Wth9e rClUV">
                                                    <div className="Uiovw-cilp">
                                                        {/* <div className="Sx48y rClUV">
                                                            <div className="xXnRw DYu5t FIRS_1">
                                                            <div className="j8ViM lxYBp AmDXP eaZQn">
                                                                <div id="header-2023" className="vkvJ8 mbvuN">
                                                                Dec 31
                                                                </div>
                                                                <div className="jtvnC"></div>
                                                            </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_2">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                    Jan 1
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_3">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                    2
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_4">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                    3
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="xXnRw DYu5t Jg_Xw FIU_5">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                    4
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_6">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                    5
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_7">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                    6
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_8">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                   7
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_9">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                   8
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_10">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                   9
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_11">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                    10
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_44">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                    11
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_12">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                    12
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_13">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                   13
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_14">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                   14
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_15">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                   15
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_16">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                    16
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_17">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                    17
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_18">
                                                                <div className="j8ViM LtiaT">
                                                                    <div className="vkvJ8 mbvuN">
                                                                   <div className="dNGgF">18</div> 
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_19">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                    19
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_20">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                    20
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_21">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                   21
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_22">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                   22
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_23">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                    23
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_24">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                    24
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_25">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                    25
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_26">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                    26
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_27">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                    27
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_28">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                    28
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_29">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                    29
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_29">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                    29
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_30">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                    30
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_31">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                    31
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_Next_1">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                    Feb 1
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_Next_2">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                    2
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xXnRw DYu5t Jg_Xw FIU_Next_3">
                                                                <div className="j8ViM lxYBp AmDXP">
                                                                    <div className="vkvJ8 mbvuN">
                                                                    3
                                                                    </div>
                                                                    <div className="jtvnC">
                                                                        <Button icon={<LocationAddRegular />}></Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        <div className="Sx48y rClUV">
                                                        {days}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="IWkMv IMv_Overlay">
                        <div className="MoZcH">
                            <div className="ow0Pu">
                            <div className="INsig">
                                <div className="otKC_ r6Bqo">
                                    <div className="ti0lx">
                                    Thu, Jan 18
                                    </div>
                                </div>
                                <div className="ywuFU customScrollBar">
                                    <div className="z_NaR">
                                        <div className="ms-Shimmer-container Jc2NK root-326">
                                            <div className="ms-Shimmer-dataWrapper dataWrapper-362">
                                                <div className="w7Dk5">
                                                    <div className="ms-FocusZone css-169 UFEPK">
                                                        <div className="ei__1">
                                                            <div className="B1_rI">
                                                                <div className="w1MGJ p7ngA F2jxh">
                                                                <img width="120px" height="120px" src={NOBOOKEDINGITEM} alt="" />
                                                                <span className="ksePc pXbOE" id="EmptyState_MainMessage">No body checked In for the day</span>
                                                                <span className="TrKke NZBAE">Enjoy!</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                       
                        </div>
                        <div className="AZjDG X7l1H"></div>
                    </div>
                </div>
            </div>
            <div className="body-156"></div>
        </div>

        </>
    )
}

export default Calenders;