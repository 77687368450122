export interface ILaundry {
    laundryId: number;
    guestName: string;
    orderId:number;
    guestId: number;
    adjusterId:number;
    roomName: string;
    roomNumber: number;
    serviceID:number;
    serviceType: string;
    itemstoBeCleaned: string;
    quantity: number;
    specialInstructions: string;
    paymentMethod: string;
    bookingSource: string;
    staffName: string;
    staffId: number;
    pickUpDeliveryRecord: Date;
    inventoryTracking: string;
    qualityControl: string;
    guestFeedback: string;
    regularMaintenance: Date;
    environmentalPractices: string;
    specialRequests: string;
    guestsatisfaction: string;
    inventoryUsage: string;
    guestSignature: string;
    inventoryReplenishment: string;
    cleaningStatus: string;
    clientContactInformation: string;
    cleaningCharges?: number;
    timeStamps: number;
    accessControl: string;
    invoiceNumber: string;
    userDemographics: string;
    purchasingprice: number;
    dailyRevenueAnalysis: string;
    revenueStatistics: string;
    feedbackReviews: string;
    revenuewCompounded: number;
    marketingPromotions: string;
    allPercentageRevenues: string;
    currency: string;
    methodOfPayment: string;
    dateadded: Date;
    deliveryTime: string;
    pickUpTime: string;
    expressTime:string;
    normalTime:string;
    membershipId: string;
    isDeleted: boolean;
    deletionReason: string;
}

export const initLaundry: ILaundry = {
    laundryId: 0,
    guestName: "",
    guestId: 0,
    adjusterId: 0,
    roomName: "",
    roomNumber: 0,
    orderId:0,
    serviceID:0,
    serviceType: "",
    itemstoBeCleaned: "",
    quantity: 0,
    specialInstructions: "",
    paymentMethod: "",
    bookingSource: "",
    staffName: "",
    staffId: 0,
    pickUpDeliveryRecord: new Date(),
    inventoryTracking: "",
    qualityControl: "",
    guestFeedback: "",
    regularMaintenance: new Date(),
    environmentalPractices: "",
    specialRequests: "",
    guestsatisfaction: "",
    inventoryUsage: "",
    guestSignature: "",
    inventoryReplenishment: "",
    cleaningStatus: "",
    clientContactInformation: "",
    cleaningCharges: 0,
    timeStamps: 0,
    accessControl: "",
    invoiceNumber: "",
    userDemographics: "",
    purchasingprice: 0,
    dailyRevenueAnalysis: "",
    revenueStatistics: "",
    feedbackReviews: "",
    revenuewCompounded: 0,
    marketingPromotions: "",
    allPercentageRevenues: "",
    expressTime:"",
    normalTime:"",
    currency: "",
    methodOfPayment: "",
    dateadded: new Date(),
    deliveryTime: "",
    pickUpTime: "",
    membershipId: "",
    isDeleted: false,
    deletionReason: ""
};
