import React from "react";
import "./Footer.scss";
import { Button, makeStyles, Tab } from "@fluentui/react-components";
import { AddCircleRegular, AppsRegular, CalendarLtrRegular, CloudRegular, HomeFilled } from "@fluentui/react-icons";



const Footer:React.FC = () => {
  

    return (
      <>
        <div className="bottom-tray-container">
        <div className="bottom-tray-container" role="navigation">
          <Button icon={<HomeFilled   />} className="bottom-tray__tab-button ms-fcl-nd" id="bottom-tray-tab-home">
          Home
        </Button>
        <Button icon={<CalendarLtrRegular />} className="bottom-tray__tab-button ms-fcl-nd" id="bottom-tray-tab-search">
          Calender
          </Button>
        <Button icon={ <AddCircleRegular />} className="bottom-tray__tab-button ms-fcl-nd ms-fcl-tp" id="bottom-tray-tab-apps">     
        Create
      </Button>
      <Button icon={<AppsRegular />} className="bottom-tray__tab-button ms-fcl-nd ms-fcl-tp" id="bottom-tray-tab-apps">
       Apps
      </Button>
    </div>
       
        </div>
      </>
    );
  }
  
  export default Footer;
  