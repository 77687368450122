export interface Assignment {
    description: string;
    department: string;
    priority: string;
    requestor: string;
    responsible: string;
    employeeId: number;
    startDate: Date;
    endDate: Date;
    progressRate: string;
    completed: string;
    remarks: string;
    expectedProgress: string;
    status: string;
    daysRemaining: string;
    daysRange: string;
    dateAdded:Date;
}

export interface Assigning {
    taskId: number;
    employeeId: number;
    startDate: Date;
    endDate: Date;
    departmentId: number;
    unitId?: number;
    requestorId: number;
}
export const initAssigning: Assigning = {
    taskId: 0,
    employeeId: 0,
    startDate: new Date(),
    endDate: new Date(),
    departmentId: 0,
    unitId: 0,
    requestorId: 0,
}