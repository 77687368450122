export interface IShops {
    SalesTax?: number | null;
    CustomerName?: string | null;
    CustomerId?: number | null;
    RoomName?: string | null;
    RoomNumber?: number | null;
    ServiceType?: string | null;
    InventoryAdjustments?: string | null;
    InventoryAudit?: string | null;
    Quantity?: number | null;
    ProductCategories?: string | null;
    PaymentMethod?: string | null;
    BestSellingProducts?: string | null;
    StaffName?: string | null;
    StaffId?: number | null;
    PickUpDeliveryRecord?: Date | null;
    InventoryTracking?: string | null;
    QualityControl?: string | null;
    GuestFeedback?: string | null;
    RegularMaintenance?: Date | null;
    EnvironmentalPractices?: string | null;
    SpecialRequests?: string | null;
    Guestsatisfaction?: string | null;
    InventoryUsage?: string | null;
    GuestSignature?: string | null;
    VendorPerformance?: string | null;
    GiftShopLayout?: string | null;
    SalesTrends?: string | null;
    Price?: number | null;
    TimeStamps?: number | null;
    Purchasingprice?: number | null;
    DailyRevenueAnalysis?: string | null;
    RevenueStatistics?: string | null;
    FeedbackReviews?: string | null;
    RevenuewCompounded?: number | null;
    MarketingPromotions?: string | null;
    AllPercentageRevenues?: string | null;
    Currency?: string | null;
    MethodOfPayment?: string | null;
    MembershipId?: string | null;
    ProductName?: string | null;
    ProductDescription?: string | null;
    CategoryId?: number | null;
    Sku?: string | null;
}



export const InitializedShopItem: IShops = {
    SalesTax: null,
    CustomerName: null,
    CustomerId: null,
    RoomName: null,
    RoomNumber: null,
    ServiceType: null,
    InventoryAdjustments: null,
    InventoryAudit: null,
    Quantity: null,
    ProductCategories: null,
    PaymentMethod: null,
    BestSellingProducts: null,
    StaffName: null,
    StaffId: null,
    PickUpDeliveryRecord: null,
    InventoryTracking: null,
    QualityControl: null,
    GuestFeedback: null,
    RegularMaintenance: null,
    EnvironmentalPractices: null,
    SpecialRequests: null,
    Guestsatisfaction: null,
    InventoryUsage: null,
    GuestSignature: null,
    VendorPerformance: null,
    GiftShopLayout: null,
    SalesTrends: null,
    Price: null,
    TimeStamps: null,
    Purchasingprice: null,
    DailyRevenueAnalysis: null,
    RevenueStatistics: null,
    FeedbackReviews: null,
    RevenuewCompounded: null,
    MarketingPromotions: null,
    AllPercentageRevenues: null,
    Currency: "NGN",
    MethodOfPayment: null,
    MembershipId: null,
    ProductName: null,
    ProductDescription: null,
    CategoryId: null,
    Sku: null
};

