export interface FitnessCenter {
    FirnessId?: number | null;
    FirtnessName?: string | null;
    FitnessDescription?: string | null;
    TimeIn?: any;
    TimeOut?: any;
    DateOfEntry?: any;
    UsageDuration?: Date | null;
    EquipmentUsed?: string | null;
    StaffPresence?: string | null;
    MembershipId?: string | null;
    Notes?: string | null;
    Price?: number | null;
    AccessControl?: string | null;
    Inventory?: string | null;
    UserDemographics?: string | null;
    Purchasingprice?: number | null;
    DailyUsageStatistics?: string | null;
    UsageStatistics?: string | null;
    Amountcompounded?: number | null;
    AllPercentageSells?: string | null;
    Currency?: string | null;
    MethodOfPayment?: string | null;
    Dateadded?: Date | null;


}

export const FitNessItems: FitnessCenter = {
    FirtnessName: null,
    FitnessDescription: null,
    TimeIn: null,
    TimeOut: null,
    DateOfEntry: null,
    UsageDuration: null,
    EquipmentUsed: null,
    StaffPresence: null,
    MembershipId: null,
    Notes: null,
    Price: null,
    AccessControl: null,
    Inventory: null,
    UserDemographics: null,
    Currency: "NGN",
    MethodOfPayment: null,
}