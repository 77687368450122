import React from "react";
import "./Header.scss";
import { Avatar, Button } from "@fluentui/react-components";
import { AlertBadgeRegular, DiversityFilled, SearchRegular } from "@fluentui/react-icons";


const Header:React.FC = () => {
    return (
        <>
        <div className="Cig8V">
                <header className="ahVTn XHRM4 rBByX GUxUH">
                    <div className="ZNYdy">
                        <Button icon={<DiversityFilled className="RWIBGU" />} className="fQQxh jXhi4 KdShc wjCqh" role="button" aria-label="Folder navigation" aria-haspopup="true">
                        </Button>
                    </div>
                    <div className="YbOwx Cxntm">
                        <div className="vH45C UPwMO" role="heading">Home</div></div>
                    <div className="AXfI_">
                        <div className="ZNYdy dhqKT">
                            <Button icon={<AlertBadgeRegular className="RWIBGU" />} className="fQQxh jXhi4 KdShc wjCqh" role="button"></Button>
                            <Button icon={<SearchRegular className="RWIBGU" />} className="fQQxh jXhi4 KdShc Ng5Bs wjCqh"></Button>
                        </div>
                        <div id="me-control-container">
                            <div className="mectrl_root mectrl_theme_light_header">
                                <div className="mectrl_dropdown mectrl_mainDropdown">
                                    <Button id="mectrl_main_trigger" type="button" className="mectrl_resetStyle mectrl_trigger" aria-label="Account manager for Jude Iloelunachi" aria-expanded="false" aria-controls="mectrl_main_body" data-noclose="true">
                                    <div>
                                    <Avatar
                                   
                                            name="Jude Iloelunachi"
                                            image={{
                                            src: "",
                                            }}
                                        />
                                        {/* <span className="mectrl_screen_reader_text">Account manager for Jude Iloelunachi</span>
                                        <div className="mectrl_topHeader">
                                            <div id="mectrl_headerPicture" className="mectrl_profilepic mectrl_profilepic_initials" aria-hidden="true" role="presentation">JI</div>
                                      </div> */}
                              </div>
                                    </Button>
                                    <div id="mectrl_main_body" className="mectrl_dropdownbody"><div className="mectrl_body">
                                        <div className="mectrl_currentAccount"><div id="mectrl_signedInTitle" className="mectrl_company mectrl_truncate">Federal University of Technology, Owerri</div>
                                        <div className="header_spacer"></div>
                                    <div className="mectrl_accountInfo"><div id="mectrl_currentAccount_picture">
                                        <div className="mectrl_profilepic mectrl_profilepic_initials" aria-hidden="true" role="presentation">JI</div></div><div className="mectrl_accountDetails"><div id="mectrl_currentAccount_primary" className="mectrl_name mectrl_truncate" aria-label="Jude Iloelunachi">Jude Iloelunachi</div><div id="mectrl_currentAccount_secondary" className="mectrl_truncate">jude.iloelunachi@futo.edu.ng</div><div><a id="mectrl_viewAccount" className="mectrl_resetStyle mectrl_link mectrl_truncate" aria-describedby="mectrl_viewAccount_desc" href="https://myaccount.microsoft.com/?ref=MeControl&amp;login_hint=jude.iloelunachi%40futo.edu.ng" target="_blank">View account</a><span id="mectrl_viewAccount_desc" style={{ display: 'none' }}>Review and manage your work or school account information</span></div><div></div></div></div><a id="mectrl_body_signOut" className="mectrl_resetStyle mectrl_signout mectrl_truncate" aria-label="Sign out of this account" role="button" href="/owa/logoff.owa" target="_top">Sign out</a></div><div className="mectrl_scrollShadow mectrl_accountList_container"><ul className="mectrl_accountList"><li><div className="mectrl_accountItem"><a id="mectrl_rememberedAccount_0_switch" className="mectrl_resetStyle primaryAction switchTo" aria-label="Switch to Chigozie Iloelunachi ciloeh@outlook.com account" href="https://outlook.live.com/mail/?login_hint=ciloeh%40outlook.com&amp;actSwt=true" target="_top"><div id="mectrl_rememberedAccount_0_picture" className="mectrl_profilepic mectrl_glyph glyph_account_circle" aria-hidden="true" role="presentation">
                                </div>
                            <div className="mectrl_accountItemInfo">
                        <div id="mectrl_rememberedAccount_0_primary" className="signedIn mectrl_truncate">Chigozie Iloelunachi</div>
                    <div id="mectrl_rememberedAccount_0_secondary" className="mectrl_truncate">ciloeh@outlook.com</div></div></a><div className="mectrl_menu mectrl_accountActions"><button id="mectrl_rememberedAccount_0_actions_trigger" type="button" className="mectrl_resetStyle mectrl_trigger" aria-label="Open sign out menu for Chigozie Iloelunachi ciloeh@outlook.com" aria-haspopup="true" aria-controls="mectrl_rememberedAccount_0_actions_menu" aria-expanded="false" data-noclose="true"><div className="mectrl_glyph glyph_more"></div></button><ul id="mectrl_rememberedAccount_0_actions_menu" role="menu" aria-labelledby="mectrl_rememberedAccount_0_actions_trigger" className=""><li role="none"><Button id="mectrl_rememberedAccount_0_signOutAndForgetFromIdp" className="mectrl_resetStyle mectrl_truncate">Sign out and forget</Button></li></ul></div></div></li><li><div className="mectrl_accountItem">
                                        <a id="mectrl_rememberedAccount_1_switch" className="mectrl_resetStyle primaryAction switchTo" aria-label="Switch to Σκίτσο Ιιοεη ciloh@outlook.com account" href="https://outlook.live.com/mail/?login_hint=ciloh%40outlook.com&amp;actSwt=true" target="_top"><div id="mectrl_rememberedAccount_1_picture" className="mectrl_profilepic mectrl_glyph glyph_account_circle" aria-hidden="true" role="presentation"></div><div className="mectrl_accountItemInfo"><div id="mectrl_rememberedAccount_1_primary" className="signedIn mectrl_truncate">Σκίτσο Ιιοεη</div><div id="mectrl_rememberedAccount_1_secondary" className="mectrl_truncate">ciloh@outlook.com</div></div></a><div className="mectrl_menu mectrl_accountActions"><button id="mectrl_rememberedAccount_1_actions_trigger" type="button" className="mectrl_resetStyle mectrl_trigger" aria-label="Open sign out menu for Σκίτσο Ιιοεη ciloh@outlook.com" aria-haspopup="true" aria-controls="mectrl_rememberedAccount_1_actions_menu" aria-expanded="false" data-noclose="true"><div className="mectrl_glyph glyph_more"></div></button><ul id="mectrl_rememberedAccount_1_actions_menu" role="menu" aria-labelledby="mectrl_rememberedAccount_1_actions_trigger" className=""><li role="none">
                                            <Button id="mectrl_rememberedAccount_1_signOutAndForgetFromIdp" className="mectrl_resetStyle mectrl_truncate">Sign out and forget
                                            </Button>
                                        </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        <li>
                        <div className="mectrl_accountItem">
                            <a id="mectrl_rememberedAccount_2_switch" className="mectrl_resetStyle primaryAction switchTo" aria-label="Switch to Cilpron LTD cilpron@outlook.com account" href="https://outlook.live.com/mail/?login_hint=cilpron%40outlook.com&amp;actSwt=true" target="_top">
                        <div id="mectrl_rememberedAccount_2_picture" className="mectrl_profilepic mectrl_glyph glyph_account_circle" aria-hidden="true" role="presentation"></div><div className="mectrl_accountItemInfo"><div id="mectrl_rememberedAccount_2_primary" className="signedIn mectrl_truncate">Cilpron LTD</div><div id="mectrl_rememberedAccount_2_secondary" className="mectrl_truncate">cilpron@outlook.com</div></div></a><div className="mectrl_menu mectrl_accountActions"><button id="mectrl_rememberedAccount_2_actions_trigger" type="button" className="mectrl_resetStyle mectrl_trigger" aria-label="Open sign out menu for Cilpron LTD cilpron@outlook.com" aria-haspopup="true" aria-controls="mectrl_rememberedAccount_2_actions_menu" aria-expanded="false" data-noclose="true"><div className="mectrl_glyph glyph_more"></div></button><ul id="mectrl_rememberedAccount_2_actions_menu" role="menu" aria-labelledby="mectrl_rememberedAccount_2_actions_trigger" className=""><li role="none"><Button id="mectrl_rememberedAccount_2_signOutAndForgetFromIdp" className="mectrl_resetStyle mectrl_truncate">Sign out and forget</Button></li></ul></div></div></li><li><div className="mectrl_accountItem"><a id="mectrl_signInItem" className="mectrl_resetStyle primaryAction signIn" aria-label="Sign in with a different account" href="https://outlook.office.com/mail/?prompt=select_account&amp;actSwt=true" target="_top"><div id="mectrl_signInItem_picture" className="mectrl_profilepic mectrl_glyph mectrl_signIn_circle_glyph" aria-hidden="true" role="presentation"></div><div className="mectrl_accountItemInfo"><div className="mectrl_truncate">Sign in with a different account</div></div></a></div></li></ul></div></div></div></div></div></div></div>
                </header>
            </div>
        </>
    )
}

export default Header;