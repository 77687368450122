import React, { useCallback, useEffect, useState } from "react";
import "./AuditLog.scss";

import {
TableBody,
TableCell,
TableRow,
Table,
TableHeader,
TableHeaderCell,
TableCellActions,
TableCellLayout,
PresenceBadgeStatus,
Avatar,
Button,
} from "@fluentui/react-components";
import axios from "axios";
import { IAuditLog } from "../../../Data/General/AuditLog";
import { WHITE_HOUSE_API } from "../../../Constants/Constant";
interface Message {
message: string,
id:number,
}

interface User {
email: string,
phoneNumber:string,
token:string,

// other properties...
}
type Props = {
message:Message;
user:User;
}

const AuditLog:React.FC<Props> = ({message, user}) => {
  const [showRoom, setShowRoom] = useState<string | null>("Room");
 const [getAuditLog, setGetAuditLog] = useState<IAuditLog[]>([]);
 const [hoveredRow, setHoveredRow] = useState<number | null>(null);


 const fetchAuditAll = useCallback(async () => {
  try {
    const response = await axios.get(`${WHITE_HOUSE_API}/AuditLog`, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
    setGetAuditLog(response.data);
    console.log("Bar data: ", response.data);
  } catch (error) {
    console.error(error);
  }
}, [user.token]);


const fetchRoomAudit = useCallback(async () => {
  try {
    const response = await axios.get(`${WHITE_HOUSE_API}/AuditLog/Room`, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
    setGetAuditLog(response.data);
    console.log("Bar data: ", response.data);
  } catch (error) {
    console.error(error);
  }
}, [user.token]);


const fetchKitchenAudit = useCallback(async () => {
  try {
    const response = await axios.get(`${WHITE_HOUSE_API}/AuditLog/Kitchen`, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
    setGetAuditLog(response.data);
    console.log("Bar data: ", response.data);
  } catch (error) {
    console.error(error);
  }
}, [user.token]);

const fetchBarAudit = useCallback(async () => {
  try {
    const response = await axios.get(`${WHITE_HOUSE_API}/AuditLog/Bar`, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
    setGetAuditLog(response.data);
    console.log("Bar data: ", response.data);
  } catch (error) {
    console.error(error);
  }
}, [user.token]);

const fetchLaundryAudit = useCallback(async () => {
  try {
    const response = await axios.get(`${WHITE_HOUSE_API}/AuditLog/Laundry`, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
    setGetAuditLog(response.data);
    console.log("Bar data: ", response.data);
  } catch (error) {
    console.error(error);
  }
}, [user.token]);


    const handleClick = (room: string) => () => {
      if(room === "Laundry"){
        setGetAuditLog([]);
        fetchLaundryAudit();
      }else if(room === "Bar & Lounge")
      {
        setGetAuditLog([]);
        fetchBarAudit();
      }else if(room === "Room"){
        setGetAuditLog([]);
        fetchRoomAudit();
      } else if (room === "Kitchen")
      {
        setGetAuditLog([]);
        fetchKitchenAudit();
      }else if(room === "General")
      {
        setGetAuditLog([]);
        fetchAuditAll();
      }
      setShowRoom(room);
    };



    useEffect(() => {
      
    }, [fetchAuditAll, fetchBarAudit, fetchKitchenAudit, fetchLaundryAudit, user.token]);
  
  


      return (
      <>
        <div className="navAndContent_feed">
          <div className="g_nav_container">
            <div className="GEN_HEaD">
              <div className="contentSized navSized contentSizedOneColumn">
                <div className="nav-bar">
                  <span className="content">
                    <ul className="list defaultItemList ">
                      {showRoom === "Room" ? (
                      <span className="list homeItemList_EGR ">
                        <li onClick={handleClick("Room")}>Room</li>
                      </span>
                      ) : (
                      <li onClick={handleClick("Room")}>Room</li>
                      )}
                      {showRoom === "Kitchen" ? (
                      <span className="list homeItemList_EGR ">
                        <li onClick={handleClick("Kitchen")}>Kitchen</li>
                      </span>
                      ) : (
                      <li onClick={handleClick("Kitchen")}>Kitchen</li>
                      )}
                      {showRoom === "Bar & Lounge" ? (
                      <span className="list homeItemList_EGR ">
                        <li onClick={handleClick("Bar & Lounge")}>Bar & Lounge</li>
                      </span>
                      ) : (
                      <li onClick={handleClick("Bar & Lounge")}>Bar & Lounge</li>
                      )}
                      {showRoom === "Laundry" ? (
                      <span className="list homeItemList_EGR ">
                        <li onClick={handleClick("Laundry")}>Laundry</li>
                      </span>
                      ) : (
                      <li onClick={handleClick("Laundry")}>Laundry</li>
                      )}
                      {showRoom === "General" ? (
                      <span className="list homeItemList_EGR ">
                        <li onClick={handleClick("General")}>General</li>
                      </span>
                      ) : (
                      <li onClick={handleClick("General")}>General</li>
                      )}
                    </ul>
                    {/* <ul className="list defaultItemList ">
                      <span className="list homeItemList_EGR ">
                        <li onClick={handleClick("Room")}>Room</li>
                      </span>
                      <li onClick={handleClick("Kitchen")}>Kitchen</li>
                      <li onClick={handleClick("Bar & Lounge")}>Bar & Lounge</li>
                      <li onClick={handleClick("Laundry")}>Laundry</li>
                      <li onClick={handleClick("General")}>General</li>

                    </ul> */}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <>
            {showRoom === "Room" &&(
            <div className="YUEnsTH">
              <Table role="grid" aria-label="Table with grid keyboard navigation">
                <TableHeader className="summary-table">
                  <TableRow>
                    <TableHeaderCell className="table-header col-first">Action Types</TableHeaderCell>
                    <TableHeaderCell className="table-header">Auditors Name</TableHeaderCell>
                    <TableHeaderCell className="table-header">Requestor's Name</TableHeaderCell>
                    <TableHeaderCell className="table-header">Maintenance Date</TableHeaderCell>
                    <TableHeaderCell className="table-header">Action Date</TableHeaderCell>
                    <TableHeaderCell className="table-header">Changes Made</TableHeaderCell>
                    <TableHeaderCell className="table-header">Experiences</TableHeaderCell>
                    <TableHeaderCell className="table-header col-last">Safety Checks</TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                {getAuditLog.map((items, index) => (
                  <React.Fragment key={index}>
                    <TableRow className="TYRBG-UIT"
                      >
                         {/* onMouseEnter={()=> setHoveredRow(index)}
                      onMouseLeave={() => setHoveredRow(null)} */}
                      <TableCell className="WEEN-BODYCEL_1">{items.actionType}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.auditorsName}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.guestName}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.maintenanceDate?.toLocaleDateString()}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.actionDate?.toLocaleDateString()}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.changes}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.guestExperience}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.saftyCheck}</TableCell>
                    </TableRow>
                    {/* {hoveredRow === index && (
                    <TableRow className="inner-row">
                      <TableCell className="WEEN-BODYCEL_1">Checked In Today</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Chigozie Iloelunachi</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Jude Iloeh</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">SAT 27, 2024</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Cleaned</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Good</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Fair</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Checked</TableCell>
                    </TableRow>
                    )} */}
                  </React.Fragment>
                  ))}
                </TableBody>

              </Table>

            </div>
            )}

            {showRoom === "Kitchen" &&(
            <div className="YUEnsTH">
              <Table role="grid" aria-label="Table with grid keyboard navigation">
                <TableHeader className="summary-table">
                  <TableRow>
                  <TableHeaderCell className="table-header col-first">Action Types</TableHeaderCell>
                    <TableHeaderCell className="table-header">Auditors Name</TableHeaderCell>
                    <TableHeaderCell className="table-header">Requestor's Name</TableHeaderCell>
                    <TableHeaderCell className="table-header">Maintenance Date</TableHeaderCell>
                    <TableHeaderCell className="table-header">Action Date</TableHeaderCell>
                    <TableHeaderCell className="table-header">Changes Made</TableHeaderCell>
                    <TableHeaderCell className="table-header">Experiences</TableHeaderCell>
                    <TableHeaderCell className="table-header col-last">Safety Checks</TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                {getAuditLog.map((items, index) => (
                  <React.Fragment key={index}>
                    <TableRow className="TYRBG-UIT" 
                      >
                      <TableCell className="WEEN-BODYCEL_1">{items.actionType}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.auditorsName}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.guestName}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.maintenanceDate?.toLocaleDateString()}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.actionDate?.toLocaleDateString()}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.changes}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.guestExperience}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.saftyCheck}</TableCell>
                    </TableRow>
                    {/* {hoveredRow === index && (
                    <TableRow className="inner-row">
                      <TableCell className="WEEN-BODYCEL_1">Checked In Today</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Chigozie Iloelunachi</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Jude Iloeh</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">SAT 27, 2024</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Cleaned</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Good</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Fair</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Checked</TableCell>
                    </TableRow>
                    )} */}
                  </React.Fragment>
                  ))}
                </TableBody>

              </Table>

            </div>
            )}
            {showRoom === "Bar & Lounge" &&(
            <div className="YUEnsTH">
              <Table role="grid" aria-label="Table with grid keyboard navigation">
                <TableHeader className="summary-table">
                  <TableRow>
                  <TableHeaderCell className="table-header col-first">Action Types</TableHeaderCell>
                    <TableHeaderCell className="table-header">Auditors Name</TableHeaderCell>
                    <TableHeaderCell className="table-header">Requestor's Name</TableHeaderCell>
                    <TableHeaderCell className="table-header">Maintenance Date</TableHeaderCell>
                    <TableHeaderCell className="table-header">Action Date</TableHeaderCell>
                    <TableHeaderCell className="table-header">Changes Made</TableHeaderCell>
                    <TableHeaderCell className="table-header">Experiences</TableHeaderCell>
                    <TableHeaderCell className="table-header col-last">Safety Checks</TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                {getAuditLog.map((items, index) => (
                  <React.Fragment key={index}>
                    <TableRow className="TYRBG-UIT" onMouseEnter={()=> setHoveredRow(index)}
                      onMouseLeave={() => setHoveredRow(null)}
                      >
                      <TableCell className="WEEN-BODYCEL_1">{items.actionType}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.auditorsName}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.guestName}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.maintenanceDate?.toLocaleDateString()}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.actionDate?.toLocaleDateString()}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.changes}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.guestExperience}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.saftyCheck}</TableCell>
                    </TableRow>
                    {/* {hoveredRow === index && (
                    <TableRow className="inner-row">
                      <TableCell className="WEEN-BODYCEL_1">Checked In Today</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Chigozie Iloelunachi</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Jude Iloeh</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">SAT 27, 2024</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Cleaned</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Good</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Fair</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Checked</TableCell>
                    </TableRow>
                    )} */}
                  </React.Fragment>
                  ))}
                </TableBody>

              </Table>

            </div>
            )}
            {showRoom === "Laundry" &&(
            <div className="YUEnsTH">
              <Table role="grid" aria-label="Table with grid keyboard navigation">
                <TableHeader className="summary-table">
                  <TableRow>
                  <TableHeaderCell className="table-header col-first">Action Types</TableHeaderCell>
                    <TableHeaderCell className="table-header">Auditors Name</TableHeaderCell>
                    <TableHeaderCell className="table-header">Requestor's Name</TableHeaderCell>
                    <TableHeaderCell className="table-header">Maintenance Date</TableHeaderCell>
                    <TableHeaderCell className="table-header">Action Date</TableHeaderCell>
                    <TableHeaderCell className="table-header">Changes Made</TableHeaderCell>
                    <TableHeaderCell className="table-header">Experiences</TableHeaderCell>
                    <TableHeaderCell className="table-header col-last">Safety Checks</TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                {getAuditLog.map((items, index) => (
                  <React.Fragment key={index}>
                    <TableRow className="TYRBG-UIT"
                      >
                      <TableCell className="WEEN-BODYCEL_1">{items.actionType}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.auditorsName}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.guestName}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.maintenanceDate?.toLocaleDateString()}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.actionDate?.toLocaleDateString()}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.changes}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.guestExperience}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.saftyCheck}</TableCell>
                    </TableRow>
                    {/* {hoveredRow === index && (
                    <TableRow className="inner-row">
                      <TableCell className="WEEN-BODYCEL_1">Checked In Today</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Chigozie Iloelunachi</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Jude Iloeh</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">SAT 27, 2024</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Cleaned</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Good</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Fair</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Checked</TableCell>
                    </TableRow>
                    )} */}
                  </React.Fragment>
                  ))}
                </TableBody>

              </Table>

            </div>
            )}
            {showRoom === "General" &&(
            <div className="YUEnsTH">
              <Table role="grid" aria-label="Table with grid keyboard navigation">
                <TableHeader className="summary-table">
                  <TableRow>
                  <TableHeaderCell className="table-header col-first">Action Types</TableHeaderCell>
                    <TableHeaderCell className="table-header">Auditors Name</TableHeaderCell>
                    <TableHeaderCell className="table-header">Requestor's Name</TableHeaderCell>
                    <TableHeaderCell className="table-header">Maintenance Date</TableHeaderCell>
                    <TableHeaderCell className="table-header">Action Date</TableHeaderCell>
                    <TableHeaderCell className="table-header">Changes Made</TableHeaderCell>
                    <TableHeaderCell className="table-header">Experiences</TableHeaderCell>
                    <TableHeaderCell className="table-header col-last">Safety Checks</TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {getAuditLog.map((items, index) => (
                  <React.Fragment key={index}>
                    <TableRow className="TYRBG-UIT" 
                      >
                        {/* onMouseEnter={()=> setHoveredRow(index)}
                      onMouseLeave={() => setHoveredRow(null)} */}
                      <TableCell className="WEEN-BODYCEL_1">{items.actionType}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.auditorsName}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.guestName}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.maintenanceDate?.toLocaleDateString()}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.actionDate?.toLocaleDateString()}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.changes}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.guestExperience}</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">{items.saftyCheck}</TableCell>
                    </TableRow>
                    {/* {hoveredRow === index && (
                    <TableRow className="inner-row">
                      <TableCell className="WEEN-BODYCEL_1">Checked In Today</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Chigozie Iloelunachi</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Jude Iloeh</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">SAT 27, 2024</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Cleaned</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Good</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Fair</TableCell>
                      <TableCell className="WEEN-BODYCEL_1">Checked</TableCell>
                    </TableRow>
                    )} */}
                  </React.Fragment>
                  ))}
                </TableBody>

              </Table>

            </div>
            )}
          </>

        </div>
      </>
      )
      }

      export default AuditLog;