
import React, {
    ChangeEvent,
    FormEvent,
    useCallback,
    useEffect,
    useState,
  } from 'react';
import {
    FolderRegular,
    EditRegular,
    OpenRegular,
    DocumentRegular,
    PeopleRegular,
    DocumentPdfRegular,
    VideoRegular,
} from "@fluentui/react-icons";
import {
    TableBody,
    TableCell,
    TableRow,
    Table,
    TableHeader,
    TableHeaderCell,
    TableCellLayout,
    PresenceBadgeStatus,
    Avatar,
} from "@fluentui/react-components";
import { WHITE_HOUSE_API } from "../../../../Constants/Constant";
import { GetAllGuest } from "../../../../Data/General/Guest";
import axios from "axios";


interface User {
    email: string,
    phoneNumber:string,
    token:string,
  
    // other properties...
  }
interface Item {
    author: { label: string };
    lastUpdate: { label: string; icon: JSX.Element };
}
type Props = {
    user:User;
 }

const items: Item[] = [
    {
        author: { label: "Mustermann" },
        lastUpdate: {
            label: "You edited this",
            icon: <EditRegular />,
        },
    },

];


const RightMenuItem: React.FC<Props> = ({user}) => {

    const [newlyRegisteredGuestMapping, setNewlyRegisteredGuestMapping] = useState<GetAllGuest[]>([]);
    
    const fetchGuest = useCallback(async () => {
        try {
          const response = await axios.get(`${WHITE_HOUSE_API}/Guest`, {
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
          });
          const guest = response.data;
          setNewlyRegisteredGuestMapping(guest);
        } catch (error) {
          console.error('Error fetching roles:', error);
        }
      }, [user.token]);
      

      useEffect(() => {
        fetchGuest();
      }, [fetchGuest]);
    return (
        <Table size="small" aria-label="Table with small size">
            <TableBody>
            {newlyRegisteredGuestMapping.map((item) => {
                const fullname = `${item.firstName} ${item.lastName}`;
                return(
                <TableRow key={item.guestId}>
                    <TableCell>
                    <TableCellLayout
                        media={<Avatar aria-label={fullname} name={fullname} />}
                    >
                        {fullname}
                    </TableCellLayout>
                    </TableCell>
                    <TableCell>
                    <TableCellLayout media={item.dateadded?.toLocaleDateString()}>
                        {item.dateadded?.toLocaleDateString()}
                    </TableCellLayout>
                    </TableCell>
                </TableRow>
)})}

            </TableBody>
        </Table>
    );
};

export default RightMenuItem;