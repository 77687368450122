import React, { FC, useCallback, useEffect, useState } from 'react';
import './ShiftTime.scss';
import { Tag } from '@fluentui/react-tags-preview';
import {
  Add24Regular,
  CalendarMonthRegular,
  ChevronDown24Regular,
  CalendarToday24Regular,
} from '@fluentui/react-icons';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import {
  Button,
  Divider,
  Field,
  Label,
  Persona,
  Tooltip,
  makeStyles,
  tokens,
} from '@fluentui/react-components';
import { InfoLabel, InfoLabelProps } from '@fluentui/react-components/unstable';
import ShiftTimeForm from '../RolesManagement/ShiftTimeForm';
import axios from 'axios';
import { WHITE_HOUSE_API } from '../../../Constants/Constant';
import { ITimeShift, TimeShift } from '../../../Data/General/ShiftItem';
import { type } from 'os';
import AddUserToShift from '../RolesManagement/AddUserToShift';
import { GetEmployee } from '../../../Data/General/Employee';
const useStyles = makeStyles({
  control: {
    maxWidth: '300px',
  },
  tooltip: {
    backgroundColor: 'rgba(0, 120, 212)',
    color: '#F5F5F5',
  },
});

// Custom hook for handling hover state
const useHover = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return { isHovered, handleMouseEnter, handleMouseLeave };
};

export const HoverableDiv: FC = () => {
  const { isHovered, handleMouseEnter, handleMouseLeave } = useHover();

  return (
    <div
      className="ui-flex bf shift-flex nf nl nj nm nh bj ia dh gy nn Wc-make-active"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isHovered ? (
        <div className="btn-add-staff">
          <Button appearance="outline" icon={<Add24Regular />}>
            Add Staff
          </Button>
        </div>
      ) : (
        <div
          role="gridcell"
          className="ms-DetailsRow-cell cell-122-siftTime cellUnpadded-120 wc-cell-fixed-154-Shift"
          data-automationid="DetailsRowCell"
          data-automation-key="Capacity"
        >
          <div className="customField_6b0520e2">
            <div className="sp-field-customFormatter-shift">
              <div className="wc-mini"></div>
              <div className="sp-css-backgroundColor-blueBackground07-WE sp-css-borderTop-blueBorder-WE wc-spark WC-Shift">
                <span className="wc-mark-position Shift-Timer">
                  Sun, Sep 24, 2023
                </span>
              </div>
              <div className="wc-selfless"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};



interface User {
  email: string,
  phoneNumber:string,
  token:string,
}
interface UsersObject {
  shiftID: number | null;
  firstName: string;
  lastName: string;
  photos: string;
  shiftdate: Date;
}

type ShiftProps ={
  user:User;
}

const ShiftTime: React.FC<ShiftProps> = ({user}) => {
  const styles = useStyles();
  //const [isHovered, setIsHovered] = useState(false);
  const [dates, setDates] = useState<string[]>([]);
  const [createShiteForm, setCreateShiteForm] = useState<boolean>(false);
  const [addUsersToShift, setUsersToShift] = useState<boolean>(false);
  const [availableTimeShifts, setAvailableTimeShift] = useState<TimeShift[]>(
    []
  );
  const [newAvailableTimeShift, setNewAvailableTimeShift] = useState<
    TimeShift[]
  >([]);
  const { isHovered, handleMouseEnter, handleMouseLeave } = useHover();
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [userObject, setUserObject] = useState<UsersObject>();
  const [arrayOfObject, setArrayOfObject] = useState<UsersObject[]>([]);
  const [searchItems, setSearchItems] = useState<boolean>(false);
  const [employeeUsers, setEmployeeUsers] = useState<GetEmployee>()

 
  const fetchShifts = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/TimeShift/AllShift`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
      setAvailableTimeShift(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);
  

  const handleAddUserToShift = () => {
    setUsersToShift(true);
  };

  const handleAddCloseUserToShift = () => {
    setUsersToShift(false);
  };
  function formatDate(dateString: any): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero indexed, so +1 and pad with 0
    const day = ('0' + date.getDate()).slice(-2); // Pad with 0
    return `${year}-${month}-${day}`;
  }

  const fetchUsersShifts = useCallback(async (id:any) => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/TimeShift/TimedUsers?userId=${id}`, {
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        }
      );
      setUserObject(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);
  

  const fetchEmployee = useCallback(async (id:any) => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Employee/identifier?identifier=${id}`, {
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        }
      );
      setEmployeeUsers(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);
  
  const handleSearchByDateChange = (date: any) => {
    if (date) {
      let formattedDate = formatDate(date);
      console.log('date selected', formattedDate);
      SearchShiftUsersByDate(formattedDate);
    }
  };

  const SearchShiftUsersByDate = useCallback(async (choosenDates: any) => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/TimeShift/TimedUsersHistory?choosenDates=${choosenDates}`, {
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        }
      );
      if (response.data) {
        console.log('The returned search', response.data);
        setNewAvailableTimeShift(response.data);
        setSearchItems(true);
      }
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);
  
  const fetchReturnedUsersShifts = useCallback(async () => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/TimeShift/ReturnUsers`, {
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        }
      );
      setArrayOfObject(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);
  

  //const [weekd, setWeekDay] = useState("");
  useEffect(() => {
    const weekdays = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const nextDates: string[] = [];
    let currentDay = new Date();
  
    while (nextDates.length < 7) {
      nextDates.push(
        `${currentDay.getDate()} ${weekdays[currentDay.getDay()]}`
      );
      currentDay.setDate(currentDay.getDate() + 1);
    }
  
    setDates(nextDates);
  }, []);
  
  const HandleShiftForm = () => {
    setCreateShiteForm(true);
  };

  const handleShiftFormClosed = () => {
    setCreateShiteForm(false);
  };
  let message = '';
  const onFormatDate = (date: any) => {
    return date
      ? date.toLocaleDateString('en-US', {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        })
      : '';
  };

  useEffect(() => {
    fetchShifts();
    fetchUsersShifts(employeeUsers?.employeeId);
    fetchEmployee(user.phoneNumber);
    fetchReturnedUsersShifts();
  }, [availableTimeShifts, employeeUsers?.employeeId, fetchEmployee, fetchReturnedUsersShifts, fetchShifts, fetchUsersShifts, user.phoneNumber, userObject]);

  const [hoveredId, setHoveredId] = useState(null);

  const items = availableTimeShifts.map((shift, index) => (
    <div
      key={shift.shiftId}
      className="ui-flex bf shift-flex nf nl nj nm nh bj ia dh gy nn "
      onMouseEnter={() => setHoveredIndex(shift.shiftId)}
      onMouseLeave={() => setHoveredIndex(null)}
    >
      {hoveredIndex === shift.shiftId ? (
        arrayOfObject.find(
          (userObject) => shift.shiftId === userObject.shiftID
        ) ? (
          arrayOfObject.map(
            (userObject) =>
              shift.shiftId === userObject.shiftID && (
                <div className="ui-flex bf nf nl nj nm nh bj ia dh gy nn">
                  <div className="ms-DetailsRow-cell cell-122-siftTime-users cellUnpadded-120 m1-width">
                    <div className="fieldRendererRendererWithCommands_3aa03b7c">
                      <div className="nameFieldWidthOnHover_3aa03b7c">
                        <span className="signalField_b9e74371 wide_b9e74371">
                          <span className="signalFieldValue_b9e74371">
                            <button
                              type="button"
                              data-automationid="FieldRenderer-name"
                              data-selection-invoke="true"
                              className="ms-Link nameField_36b20067 clickable_36b20067 overflow_85ed223c pre_85ed223c root-128"
                              title="Breakfast meet & greet"
                              role="link"
                            >
                              <Persona
                                name={`${userObject.firstName} ${userObject.lastName}`}
                                secondaryText="7hrs 43min ..."
                                presence={{
                                  status: 'available',
                                }}
                                avatar={{
                                  image: {
                                    src:
                                      userObject.photos ||
                                      'https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/office-ui-fabric-react-assets/persona-male.png',
                                  },
                                }}
                              />
                            </button>
                          </span>
                        </span>
                      </div>
                      <div className="fieldRendererHeroCommandContainer_3aa03b7c">
                        <div
                          className="root_15af61c7"
                          data-selection-touch-invoke="false"
                        >
                          <button
                            type="button"
                            title="Show more actions for this item"
                            data-automationid="FieldRender-DotDotDot"
                            className="ms-Button ms-Button--icon heroButton_15af61c7 root-53"
                            aria-label="Show more actions for this item"
                            data-is-focusable="true"
                          >
                            <span
                              className="ms-Button-flexContainer flexContainer-46"
                              data-automationid="splitbuttonprimary"
                            >
                              <i className="ms-Icon root-32 css-41 ms-Button-icon icon-48">
                                
                              </i>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
          )
        ) : (
          <div className="btn-add-staff">
            <Button
              onClick={handleAddUserToShift}
              appearance="outline"
              icon={<Add24Regular />}
            >
              Add Staff
            </Button>
          </div>
        )
      ) : (
        <div
          role="gridcell"
          className="ms-DetailsRow-cell cell-122-siftTime cellUnpadded-120 wc-cell-fixed-154-Shift"
          data-automationid="DetailsRowCell"
          data-automation-key="Capacity"
        >
          <div className="customField_6b0520e2">
            <div className="sp-field-customFormatter-shift">
              <div className="wc-mini"></div>
              <div
                className={`wc-spark WC-Shift ${
                  shift.shiftdate === userObject?.shiftdate
                    ? 'sp-css-backgroundColor-blueBackground07-WE sp-css-borderTop-blueBorder-WE'
                    : 'sp-css-backgroundColor-blueBackground07 sp-css-borderTop-blueBorder'
                }`}
              >
                <span className="wc-mark-position Shift-Timer">
                  {shift.shiftStart} - {shift.shiftEnd}
                  <small> .9hrs</small>
                </span>
                <span className="wc-mark-position Shift-Timer">
                  <InfoLabel>{shift.position}- Sunday</InfoLabel>
                </span>
              </div>
              <div className="wc-selfless"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  ));

  const Gitems = newAvailableTimeShift.map((shift, index) => (
    <div
      key={shift.shiftId}
      className="ui-flex bf shift-flex nf nl nj nm nh bj ia dh gy nn "
      onMouseEnter={() => setHoveredIndex(shift.shiftId)}
      onMouseLeave={() => setHoveredIndex(null)}
    >
      {hoveredIndex === shift.shiftId ? (
        arrayOfObject.find(
          (userObject) => shift.shiftId === userObject.shiftID
        ) ? (
          arrayOfObject.map(
            (userObject) =>
              shift.shiftId === userObject.shiftID && (
                <div className="ui-flex bf nf nl nj nm nh bj ia dh gy nn">
                  <div className="ms-DetailsRow-cell cell-122-siftTime-users cellUnpadded-120 m1-width">
                    <div className="fieldRendererRendererWithCommands_3aa03b7c">
                      <div className="nameFieldWidthOnHover_3aa03b7c">
                        <span className="signalField_b9e74371 wide_b9e74371">
                          <span className="signalFieldValue_b9e74371">
                            <button
                              type="button"
                              data-automationid="FieldRenderer-name"
                              data-selection-invoke="true"
                              className="ms-Link nameField_36b20067 clickable_36b20067 overflow_85ed223c pre_85ed223c root-128"
                              title="Breakfast meet & greet"
                              role="link"
                            >
                              <Persona
                                name={`${userObject.firstName} ${userObject.lastName}`}
                                secondaryText="7hrs 43min ..."
                                presence={{
                                  status: 'available',
                                }}
                                avatar={{
                                  image: {
                                    src:
                                      userObject.photos ||
                                      'https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/office-ui-fabric-react-assets/persona-male.png',
                                  },
                                }}
                              />
                            </button>
                          </span>
                        </span>
                      </div>
                      <div className="fieldRendererHeroCommandContainer_3aa03b7c">
                        <div
                          className="root_15af61c7"
                          data-selection-touch-invoke="false"
                        >
                          <button
                            type="button"
                            title="Show more actions for this item"
                            data-automationid="FieldRender-DotDotDot"
                            className="ms-Button ms-Button--icon heroButton_15af61c7 root-53"
                            aria-label="Show more actions for this item"
                            data-is-focusable="true"
                          >
                            <span
                              className="ms-Button-flexContainer flexContainer-46"
                              data-automationid="splitbuttonprimary"
                            >
                              <i className="ms-Icon root-32 css-41 ms-Button-icon icon-48">
                                
                              </i>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
          )
        ) : (
          <div className="btn-add-staff">
            <Button
              onClick={handleAddUserToShift}
              appearance="outline"
              icon={<Add24Regular />}
            >
              Add Staff
            </Button>
          </div>
        )
      ) : (
        <div
          role="gridcell"
          className="ms-DetailsRow-cell cell-122-siftTime cellUnpadded-120 wc-cell-fixed-154-Shift"
          data-automationid="DetailsRowCell"
          data-automation-key="Capacity"
        >
          <div className="customField_6b0520e2">
            <div className="sp-field-customFormatter-shift">
              <div className="wc-mini"></div>
              <div
                className={`wc-spark WC-Shift ${
                  shift.shiftdate === userObject?.shiftdate
                    ? 'sp-css-backgroundColor-blueBackground07-WE sp-css-borderTop-blueBorder-WE'
                    : 'sp-css-backgroundColor-blueBackground07 sp-css-borderTop-blueBorder'
                }`}
              >
                <span className="wc-mark-position Shift-Timer">
                  {shift.shiftStart} - {shift.shiftEnd}
                  <small> .9hrs</small>
                </span>
                <span className="wc-mark-position Shift-Timer">
                  <InfoLabel>{shift.position}- Sunday</InfoLabel>
                </span>
              </div>
              <div className="wc-selfless"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  ));

  const Iitems = availableTimeShifts.map((shift, index) => (
    <div
      key={index}
      className="ui-flex bf shift-flex nf nl nj nm nh bj ia dh gy nn "
      onMouseEnter={() => setHoveredIndex(index)}
      onMouseLeave={() => setHoveredIndex(null)}
    >
      {hoveredIndex === index ? (
        userObject && shift.shiftdate === userObject.shiftdate ? (
          <div className="ui-flex bf nf nl nj nm nh bj ia dh gy nn">
            <div className="ms-DetailsRow-cell cell-122-siftTime-users cellUnpadded-120 m1-width">
              <div className="fieldRendererRendererWithCommands_3aa03b7c">
                <div className="nameFieldWidthOnHover_3aa03b7c">
                  <span className="signalField_b9e74371 wide_b9e74371">
                    <span className="signalFieldValue_b9e74371">
                      <button
                        type="button"
                        data-automationid="FieldRenderer-name"
                        data-selection-invoke="true"
                        className="ms-Link nameField_36b20067 clickable_36b20067 overflow_85ed223c pre_85ed223c root-128"
                        title="Breakfast meet & greet"
                        role="link"
                      >
                        <Persona
                          name={`${userObject.firstName} ${userObject.lastName}`}
                          secondaryText="7hrs 43min ..."
                          presence={{
                            status: 'available',
                          }}
                          avatar={{
                            image: {
                              src:
                                userObject.photos ||
                                'https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/office-ui-fabric-react-assets/persona-male.png',
                            },
                          }}
                        />
                      </button>
                    </span>
                  </span>
                </div>
                <div className="fieldRendererHeroCommandContainer_3aa03b7c">
                  <div
                    className="root_15af61c7"
                    data-selection-touch-invoke="false"
                  >
                    <button
                      type="button"
                      title="Show more actions for this item"
                      data-automationid="FieldRender-DotDotDot"
                      className="ms-Button ms-Button--icon heroButton_15af61c7 root-53"
                      aria-label="Show more actions for this item"
                      data-is-focusable="true"
                    >
                      <span
                        className="ms-Button-flexContainer flexContainer-46"
                        data-automationid="splitbuttonprimary"
                      >
                        <i className="ms-Icon root-32 css-41 ms-Button-icon icon-48">
                          
                        </i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="btn-add-staff">
            <Button appearance="outline" icon={<Add24Regular />}>
              Add Staff
            </Button>
          </div>
        )
      ) : (
        <div
          role="gridcell"
          className="ms-DetailsRow-cell cell-122-siftTime cellUnpadded-120 wc-cell-fixed-154-Shift"
          data-automationid="DetailsRowCell"
          data-automation-key="Capacity"
        >
          <div className="customField_6b0520e2">
            <div className="sp-field-customFormatter-shift">
              <div className="wc-mini"></div>
              <div
                className={`wc-spark WC-Shift ${
                  shift.shiftdate === userObject?.shiftdate
                    ? 'sp-css-backgroundColor-blueBackground07-WE sp-css-borderTop-blueBorder-WE'
                    : 'sp-css-backgroundColor-blueBackground07 sp-css-borderTop-blueBorder'
                }`}
              >
                <span className="wc-mark-position Shift-Timer">
                  {shift.shiftStart} - {shift.shiftEnd}
                  <small> .9hrs</small>
                </span>
                <span className="wc-mark-position Shift-Timer">
                  <InfoLabel>{shift.position}- Sunday</InfoLabel>
                </span>
              </div>
              <div className="wc-selfless"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  ));

  return (
    <>
     <div className="ts-messages-header messages-header-v2">
                <div className="app-messages-header ts-section-divider">
                  <div className="app-chat-header-content MEHBE_U36B">
                  <div className="left-header">
            <Button icon={<CalendarToday24Regular />}>Today</Button>

            <Field>
              <DatePicker
                showWeekNumbers={true}
                firstWeekOfYear={1}
                showMonthPickerAsOverlay={true}
                placeholder={onFormatDate(new Date())}
                className={styles.control}
                onSelectDate={handleSearchByDateChange}
              />
            </Field>
          </div>
          <div className="right-header-contents shift-max-height">
            <Button icon={<Add24Regular />} onClick={HandleShiftForm}>
              Create TimeShift
            </Button>
          </div>
                  </div>
                </div>
              </div>


        <div className="container-body">
          <div className="ui-flex bf shift-flex ni ch nj nk hz dg gx">
            {dates.map((date, index) => {
              const [day, weekday] = date.split(' ');
              // setWeekDay(weekday);
              return (
                <div
                  key={index}
                  className="ui-flex bf shift-flex nf nl nj nm nh bj ia dh gy nn"
                >
                  <div className="ui-flex bf nf kg kh">
                    <span className="ui-text my no np ir" dir="auto">
                      {day}
                    </span>
                    <span className="ui-text my jg np ir" dir="auto">
                      {weekday}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="ui-flex bf  shift-flex ni ch nj nk hz dg gx">
            {searchItems ? (
              <>
                {Gitems.map((item, index) => (
                  <React.Fragment key={index}>{item}</React.Fragment>
                ))}
              </>
            ) : (
              <>
                {items.map((item, index) => (
                  <React.Fragment key={index}>{item}</React.Fragment>
                ))}
              </>
            )}
          </div>
        </div>
     
      {createShiteForm && (
        <ShiftTimeForm onClose={handleShiftFormClosed} message={message} />
      )}

      {addUsersToShift && (
        <AddUserToShift
          onClose={handleAddCloseUserToShift}
          message={''}
          shiftId={hoveredIndex || 0}
        />
      )}
    </>
  );
};

export default ShiftTime;
