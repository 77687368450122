import React, { useEffect, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import { IHConnection } from '../../Data/General/HubConnaction';
import { CMessage } from '../../Data/General/ChannelMessage';



export const useSignalRConnection = (url: string, token: string) => {
  const [connection, setConnection] = useState<signalR.HubConnection | null>(null);
  const [channelData, setChannelData] = useState<string>("");

  useEffect(() => {
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(url,{ 
          withCredentials: false,
          accessTokenFactory: async () => token,
          transport: signalR.HttpTransportType.WebSockets,
          skipNegotiation: true,
       })
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  }, [token, url]);

  useEffect(() => {
      if (connection) {
        connection.start()
          .then(() => {
            console.log('Connection started!');
            // Invoke the ChannelMessage method on the server
            connection.invoke("ChannelMessage")
              .catch(err => console.error(err.toString()));
          })
          .catch(err => console.log('Error while establishing connection :(', err));
    
        // connection.on("ChannelMessage", (user, data) => {
        //   console.log(`Users: ${user}  and the Data:  "${data}"`);
       
        // });
        connection.on("ChannelMessage", (data) => {
          console.log(`Channel: ${data.channel}`);
          console.log('Data:', data);
          setChannelData(data.channel);
        });
        connection.onreconnecting(error => {
          console.log(`Connection lost due to error "${error}". Reconnecting.`);
        });
    
        connection.onreconnected(connectionId => {
          console.log(`Connection reestablished. Connected with connectionId "${connectionId}".`);
        });
      }
    
      return () => {
        if (connection) {
          connection.stop();
        }
      };
    }, [connection]);
    
  return { connection, channelData };
};
