import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
  Label,
  useId,
  Input,
  makeStyles,
  Select,
  Field,
  shorthands,
  Combobox,
  Option,
  Persona,
  Radio,
  Text,
} from '@fluentui/react-components';

import { WHITE_HOUSE_API } from '../../../Constants/Constant';
import axios from 'axios';
import Loaders from '../../Common/Loader/Loader';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import './RestaurantMenuForm.scss';
import { KitchenProduct, InitKitchenProduct } from '../../../Data/General/Restaurant';
import DispatchToast from '../../Common/Toast/DispatchToast';
const useStyles = makeStyles({
  root: {
    // Stack the label above the field with a gap
    display: 'grid',
    gridTemplateRows: 'repeat(1fr)',
    justifyItems: 'start',
    ...shorthands.gap('2px'),
    maxWidth: '400px',
  },

  control: {
    maxWidth: '300px',
  },
});

interface User {
  email: string,
  phoneNumber:string,
  token:string,
}
interface Message {
  message: string;
  id: number;
}
interface RolesProps {
  onClose: () => void;
  message: Message;
  user:User;
}



const RestaurantMenuForm: React.FC<RolesProps> = ({ onClose, message,user }) => {
  const [dispatchToaster, setDispatchToaster] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const inputId = useId('input');
  const styles = useStyles();
  const [saveRestaurantProduct, setSaveRestaurantProduct] = useState<KitchenProduct>(InitKitchenProduct);
  const comboId = useId('combo-default');
  const [input, setInput] = useState('');
  const [currency, setCurrency] = useState('NGN');
  const currencySymbol = currency === 'NGN' ? '₦' : '$';
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<number>();

  const handleReservationTypeInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setSaveRestaurantProduct((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleShiftDateInputChange =
    (fieldName: string) => (date: Date | null | undefined) => {
      if (date) {
        setSaveRestaurantProduct((prevState) => ({
          ...prevState,
          [fieldName]: date,
        }));
      }
    };

  const handleReservationTypeRadioChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    if (value === 'NGN') {
        setCurrency("NGN");
    } else if (value === 'USD') {
        setCurrency("USD");
    }

    setSaveRestaurantProduct((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const HandleSubmitReservationType = async () => {
    try {
      setIsLoader(true);
      const response = await axios.post(
        `${WHITE_HOUSE_API}/Restaurant`,
        saveRestaurantProduct,
        {
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        }
      );
      if (response.data) {
        setTimeout(() => {
          setIsLoader(false);
          onClose();
        }, 500);

        if (response.data.message === 'Success') {
          setResponseMessage(response.data.eMessage);
          setDispatchToaster(true);
          setTimeout(() => {
            setDispatchToaster(false);
          }, 1000);
        }

        setSaveRestaurantProduct(InitKitchenProduct);
      }
    } catch (error) {
      message.message += 'Registration ' + error;
      setResponseMessage(message.message);
      setTimeout(() => {
        setIsLoader(false);
      }, 1500);

      console.error('Error fetching roles:', error);
    }
  };

  const fetchExistingReservationTypes = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/Restaurant/${message.id}`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
      setSaveRestaurantProduct(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [message, user.token]);
  
  useEffect(() => {
  if(message.id > 0){
    fetchExistingReservationTypes();
  }
  }, [fetchExistingReservationTypes, message.id]); 

  return (
    <div className="fui-FluentProvider fui-FluentProvider361 ___1bsm0mz f19n0e5 fxugw4r f1o700av fk6fouc fkhj508 figsok6 f1i3iumi f1euv43f f15twtuk f1vgc2s3 f1e31b4d f494woh">
      <div
        aria-hidden="true"
        className="fui-DialogSurface__backdrop ___adeqq90 f19dog8a fju19wo f113wtx2 f10k790i f5gq2j6 f1xynx9j"
      ></div>
       
      <div className="fui-DialogSurface ___1g5txne ftgm304 f1c515w f1bitti f15twtuk f1e31b4d f1yab3r1 f1vgc2s3 fuq56rw f15kemlc fl2zwns fdgang7 fgr6219 f1ujusj6 f10jk5vf fcgxt0o fzkkow9 fcdblym fg706s2 fjik90z f1ln0qer fa2wlxz f120kxnn f19dog8a f3052tw fvgz9i8 f6a9g1z f1ewtqcl f10nrhrw fxugw4r f19n0e5 f5ogflp f1hqa2wf f1f09k3d finvdd3 fghlq4f f1gn591s fb073pr fjscplz fnivh3a fc7yr5o f1el4m67 f8yange ff54dml ftqa4ok f2hkw1w f8hki3x f1d2448m f1bjia2o ffh67wi f15bsgw9 f14e48fq f18yb2kv fd6o370 fh1cnn4 fy7oxxb fpukqih f184ne2d frrh606 f1v5zibi ful5kiu fo2hd23 f1jqcqds ftffrms f2e7qr6 fsr1zz6 f1dvezut fd0oaoj fjvm52t f1cwg4i8 f57olzd f4stah7 f480a47 fs1por5">
        <div className="fui-DialogBody ___6dxt060 f13qh94s f120kxnn flnwrvu f1ewtqcl f1ln0qer fa2wlxz f4px1ci fn67r4l f1x46k84 fjj47a5 ff54dml f1t97isy">
        {isLoader && (
            <div className="restrict-the-inner">
                <Loaders />

            </div>
            )}
          <h2
            id="dialog-title-363"
            className="fui-DialogTitle ___wfyv5z0 f16hsg94 f1amvztq fwpfdsa fsyjsko fk6fouc f1pp30po fl43uef f106mvju f1hu3pq6 f11qmguv f19f4twv f1tyq0we"
          >
            Add Restuarant Service
          </h2>
          {dispatchToaster && (
              <DispatchToast message={responseMessage}/>
          )}
          <div className="fui-DialogContent ___1dce55l f5zp4f f1nxs5xn f1ewtqcl faunodf f88nxoq fwpfdsa fsyjsko fr4fn7s f171n1a0 f1ean75l f1jwbs5a fxlnl9e ffs23cn f2uu7rc f1v5tmjd fk6fouc fkhj508 figsok6 f1i3iumi customScrollBar">
            <div className="First-form-flex-shift">
              <div className="space-inflex-AddUsers">
                <div className="First-form-flex">
                  <div className={styles.root}>
                    <Label htmlFor={inputId}>Product Name</Label>
                    <Input
                      id={inputId}
                      name="productName"
                      placeholder="Product Name"
                      value={saveRestaurantProduct.productName || ""}
                      onChange={handleReservationTypeInputChange}
                    />
                  </div>
                  <div className={styles.root}>
                    <Label htmlFor={inputId}>
                      Product Description
                    </Label>
                    <Input
                      id={inputId}
                      name="productDescription"
                      value={saveRestaurantProduct.productDescription || ""}
                      placeholder="Product Description"
                      onChange={handleReservationTypeInputChange}
                    />
                  </div>
                </div>
                <div className="First-form-flex">
                  <div className={styles.root}>
                    <Label htmlFor={inputId}>Price</Label>
                    <Input
                      placeholder="Product Price .."
                      name="price"
                      value={saveRestaurantProduct.price ? saveRestaurantProduct.price.toString() : ""}
                      contentBefore={
                        <Text size={400} id="beforeLabelId">
                          {currencySymbol}
                        </Text>
                      }
                      contentAfter={
                        <Text size={400} id="afterLabelId">
                          .00
                        </Text>
                      }
                      appearance="filled-darker"
                      id="filledDarkerId"
                      onChange={handleReservationTypeInputChange}
                    />
                  </div>
                  <div className={styles.root}>
                    <Label htmlFor={inputId}>Preparation Time</Label>
                    <Input
                      id={inputId}
                      name="preparationTime"
                      placeholder="4hrs 15 munites"
                      value={saveRestaurantProduct.preparationTime || ""}
                      onChange={handleReservationTypeInputChange}
                    />
                  </div>
                </div>
                <div className="First-form-flex">
                  <div className={styles.root}>
                    <Label htmlFor={inputId}>Ingredients </Label>
                    <Input
                      id={inputId}
                      name="ingredients"
                      placeholder="Rice Tomatos"
                      value={saveRestaurantProduct.ingredients|| ""}
                      type="text"
                      onChange={handleReservationTypeInputChange}
                    />
                  </div>
                  <div className={styles.root}>
                    <Label htmlFor={inputId}>Serving Size</Label>
                    <Input
                      id={inputId}
                      name="servingSize"
                      value={saveRestaurantProduct.servingSize?.toString()|| ""}
                      placeholder="Number of spoon"

                      onChange={handleReservationTypeInputChange}
                    />
                  </div>
                </div>
                <div className="footer-container-ui">
                  <div className={styles.root}>
                    <Label>Currency</Label>
                    <div className="align-radio-button" role="radiogroup">
                      <Radio
                        name="currency"
                        value="NGN"
                        label="NGN"
                        onChange={handleReservationTypeRadioChange}
                      />
                      <Radio
                        name="currency"
                        value="USD"
                        label="USD"
                        onChange={handleReservationTypeRadioChange}
                      />
                    </div>
                  </div>
                  <div className={styles.root}>
                    <Label>Method of  apayment</Label>
                    <div className="align-radio-button" role="radiogroup">
                      <Radio
                        name="methodOfPayment"
                        value="Direct Credit"
                        label="Direct Credit"
                        onChange={handleReservationTypeRadioChange}
                      />
                      <Radio
                        name="methodOfPayment"
                        value="Invoice"
                        label="Invoice"
                        onChange={handleReservationTypeRadioChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fui-DialogActions ___1mdwomm f3052tw f1ewtqcl f22iagw f1cztu7b fcu02xx f4px1ci fn67r4l f6glcwc f1e3st1r f1a7i8kp fd46tj4 fsyjsko f1f41i0t f1jaqex3 f2ao6jk">
          <Button
            className="Button-Submit"
            onClick={HandleSubmitReservationType}
          >
            Submit
          </Button>
          <Button className="Button-close" onClick={onClose}>
            cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RestaurantMenuForm;
