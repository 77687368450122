import React, { useEffect, useState } from 'react';
import "./Room.scss";
import { RoomData } from "../../Data/General/Room";
import { RoomDetail } from "../../Data/General/RoomDetail";
import { WHITE_HOUSE_API } from '../../Constants/Constant';
import axios, { AxiosError } from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DateTime } from 'luxon';
import { Divider, makeStyles, Radio, tokens, useId } from "@fluentui/react-components";

const initialRoomData: RoomData = {
    roomId: 0,
    availability: true,
    rate: 0,
    restrictions: null,
    roomNumber: 0,
    roomName: null,
    smoking: false
}
const initialRoomDetailData: RoomDetail = {
    RoomId: 0,
    RoomName: "",
    RoomNumber: 0,
    RoomLocation: "",
    RoomType: "",
    Description: "",
    RoomLevel: 0,
    RoomStatus: "",
    RoomMaintenance: "",
    RoomTemperature: "",
    RoomSafeStatus: "",
    CallRequests: "",
    LaundryStatus: "",
    DoNotDisturbStatus: "",
    GuestMessages: "",
    HousekeepingRequests: "",
    GuestPreferences: "",
    RoomFeatures: "",
    RoomPackages: "",
    RoomServiceOrders: "",
    MinibarInventory: "",
    ConnectingRoomInformation: "",
    SpecialOccasionDetails: "",
    RoomCleaningSchedule: null,
    LocalEventsAttractions: "",
    NoiseLevel: "",
    RoomAvailabilityCalendar: "",
    RoomStatusTimestamps: null,
    RoomPhotos: "",
    Photos: new File([], ''),
    RoomMaintenanceHistory: "",
    RoomTemperatureControl: "",
    RoomInRoomSafeStatus: "",
    WakeUpCallRequests: "",
    RoomAccessibilityFeatures: "",
    GuestFeedbackReviews: "",
    RoomInventory: "",

};
  
interface User {
    email: string,
    phoneNumber:string,
    token:string,
  
    // other properties...
  }

interface DialogProps {
    onClose: () => void;
    user: User;
}
interface RoomResponse {
    message: string;
}

const RoomForm: React.FC<DialogProps> = ({ onClose, user }) => {
    const [roomData, setRoomData] = useState<RoomData>(initialRoomData);
    const [roomDetailData, setRoomDetailData] = useState<RoomDetail>(initialRoomDetailData);
    const [isCreateRoomVisible, setisCreateRoomVisible] = useState(true);
    const [isCreateRoomDetailVisible, setisCreateRoomDetailVisible] = useState(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setsuccessMessage] = useState('');
    const [createHeader, setcreateHeader] = useState('');
    const [createName, setcreateName] = useState('');
    const [createId, setcreateId] = useState('');
    const [createNumber, setcreateNumber] = useState('');
    let message = '';
    const radioName = useId("radio");


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target
        setRoomData(prevState => ({ ...prevState, [name]: value }))
    }
    const handleRoomDetailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, files } = event.target;
        if (name === 'Photos' && files) {
            setRoomDetailData(prevData => ({
                ...prevData,
                [name]: files[0],
            }));
        } else {
            setRoomDetailData(prevData => ({
                ...prevData,
                [name]: value,
            }));
        }
    };





    const handleToggleVisibility = () => {
        setisCreateRoomVisible(prevIsVisible => {
            const newIsVisible = !prevIsVisible;
            setisCreateRoomDetailVisible(!newIsVisible);
            return newIsVisible;
        });
    };




    function handleDateChangeA(date: Date | null): void {
        if (date) {
            const outputFormat = 'yyyy-MM-dd';
            const outputDate = DateTime.fromJSDate(date).toFormat(outputFormat);

            setRoomDetailData((prevData: RoomDetail) => ({
                ...prevData,
                RoomCleaningSchedule: DateTime.fromFormat(outputDate, outputFormat).toJSDate(),
            }));
        }
    }

    function handleDateChangeB(date: Date | null): void {
        if (date) {
            const outputFormat = 'yyyy-MM-dd';
            const outputDate = DateTime.fromJSDate(date).toFormat(outputFormat);

            setRoomDetailData((prevData: RoomDetail) => ({
                ...prevData,
                RoomStatusTimestamps: DateTime.fromFormat(outputDate, outputFormat).toJSDate(),
            }));
        }
    }


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>, formId: string) => {
        event.preventDefault();
        switch (formId) {
            case 'RoomForm':
                let isValid = true;
                if (!roomData.roomNumber) {
                    isValid = false;
                    message += 'Room number is required. ';
                }
                setErrorMessage(message);
                if (isValid) {
                    try {
                        setIsLoader(true);
                        const response = await axios.post(`${WHITE_HOUSE_API}/Rooms`, roomData, {
                            headers: {
                                'Authorization': `Bearer ${user.token}`
                            }
                        });
                        console.log(response.data.message);
                        console.log(response);
                        setTimeout(() => {
                            if (response.status === 200) {
                                message += response.data.message;
                                setsuccessMessage(message);
                                setcreateName(response.data.roomName);
                                setcreateId(response.data.roomId);
                                setcreateNumber(response.data.roomNumber);
                                setIsLoader(false);
                                handleToggleVisibility();
                            }
                        }, 3000);
                    }catch (error) {
                        console.error(error);
                        if (axios.isAxiosError(error)) {
                            setTimeout(() => {
                                const axiosError = error as AxiosError<RoomResponse>;
                                if (axiosError.response && axiosError.response.data && axiosError.response.data.message) {
                                    console.log(axiosError.response.data.message);
                                    message += axiosError.response.data.message;
                                    setErrorMessage(message);
                                    setIsLoader(false);
                                }
                            }, 1000);
                        }


                    }
                }
                break;
            case 'RoomDetailForm':
                try {
                    setIsLoader(true);

                    const formData = new FormData();
                    /********** 
                    for (const [key, value] of Object.entries(roomDetailData)) {
                        if (key === 'Photos') {
                            formData.append(key, value);
                        } else if (value instanceof Date) {
                            const outputFormat = 'yyyy-MM-dd';
                            const formattedDate = DateTime.fromJSDate(value).toFormat(outputFormat);
                            formData.append(key, formattedDate);
                        } else {
                            formData.append(key, value.toString());
                        }
                    }
                    *****************/
                    for (const [key, value] of Object.entries(roomDetailData)) {
                        switch (key) {
                            case 'Photos':
                                formData.append(key, value);
                                break;
                            case 'RoomName':
                                formData.append(key, createName);
                                break;
                            case 'RoomNumber':
                                formData.append(key, createNumber);
                                break;
                            case 'RoomId':
                                formData.append(key, createId);
                                break;
                            default:
                                if (value instanceof Date) {
                                    const outputFormat = 'yyyy-MM-dd';
                                    const formattedDate = DateTime.fromJSDate(value).toFormat(outputFormat);
                                    formData.append(key, formattedDate);
                                } else {
                                    formData.append(key, value.toString());
                                }
                        }
                    }


                    const response = await axios.post(`${WHITE_HOUSE_API}/RoomDetail`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${user.token}`,
                        }
                    });
                    console.log(response.data.message);
                    console.log(response);
                    setTimeout(() => {
                        if (response.status === 200) {
                            message += response.data.message;
                            setsuccessMessage(message);
                            setcreateName(response.data.roomName);
                            setcreateId(response.data.roomId);
                            setcreateNumber(response.data.roomNumber);
                            setIsLoader(false);
                            handleToggleVisibility();
                        }
                    }, 3000);

                } catch (error: any) {
                    console.error(error);
                    if (axios.isAxiosError(error)) {
                        setTimeout(() => {
                            const axiosError = error as AxiosError<RoomResponse>;
                            if (axiosError.response && axiosError.response.data && axiosError.response.data.message) {
                                console.log(axiosError.response.data.message);
                                message += axiosError.response.data.message;
                                setErrorMessage(message);
                                setIsLoader(false);
                            }
                        }, 1000);
                    } else {
                        console.error("An error occurred: ", error.message);
                        message += error.message;
                        setErrorMessage(message);
                        setIsLoader(false);
                    }
                }

                break;
            // ...
        }


    }

    const handleFocus = () => {
        setIsFocused(true);
    };
    const handleOverlayClick = () => {
        onClose();
    }




    return (
        <>
            {isCreateRoomVisible && (
                <div className='w-add-room rms-add-number-speed-room'>
                    <div className='w-add-room rms-modal-dialog-container rms-vdi-occlusion'>
                        <div className="w-add-room rms-analytics-panel">
                            <div className="w-add-room rms-modal-dialog-header">
                                <h1 className="w-add-room rms-app-font-family-base rms-modal-dialog-title">Add to Room</h1>
                            </div>
                            <span className="w-add-room rms-w-c-error">{errorMessage}</span>
                            <span className="w-add-room rms-w-c-suceess">{successMessage}</span>
                            <div className="w-add-room rms-modal-dialog-content rms-modal-dialog-content-suppress-default-footer">
                                <form onSubmit={event => handleSubmit(event, 'RoomForm')}>
                                    <div className="w-add-room rms-modal-dialog-content-container">
                                        <div className="w-add-room rms-w-c-form-contain customScrollBar">
                                            <div className="w-add-room rms-company-job">
                                                <div className="w-add-room rms-block-left">
                                                    <div className="w-add-room rms-inline-property-title">Room Name</div>
                                                    <input
                                                        className={`w-add-room rms-edit-content rms-w--pristine rms-ng-untouched rms-ng-valid rms-ng-empty rms-ng-valid-maxlength ${isFocused ? 'rms-wc' : ''}`}
                                                        type="text"
                                                        id="roomName"
                                                        name="roomName"
                                                        placeholder=""
                                                        value={roomData.roomName || ''}
                                                        onChange={handleChange}
                                                        onFocus={handleFocus}
                                                    />
                                                </div>
                                                <div className="w-add-room rms-block-right">
                                                    <div className="w-add-room rms-inline-property-title">Room Number</div>
                                                    <input
                                                        className={`w-add-room rms-edit-content rms-w--pristine rms-ng-untouched rms-ng-valid rms-ng-empty rms-ng-valid-maxlength ${isFocused ? 'rms-wc' : ''}`}
                                                        type="number"
                                                        id="roomNumber"
                                                        name="roomNumber"
                                                        value={roomData.roomNumber}
                                                        onChange={handleChange}
                                                        onFocus={handleFocus}

                                                    />
                                                </div>
                                            </div>


                                            <div className="w-add-room rms-company-job">
                                                <div className="w-add-room rms-block-left">
                                                    <div className="w-add-room rms-inline-property-title">Room Rate</div>
                                                    <input
                                                        className={`w-add-room rms-edit-content rms-w--pristine rms-ng-untouched rms-ng-valid rms-ng-empty rms-ng-valid-maxlength ${isFocused ? 'rms-wc' : ''}`}
                                                        type="number" id="rate"
                                                        name="rate"
                                                        value={roomData.rate || ''}
                                                        onChange={handleChange}
                                                        onFocus={handleFocus}
                                                    />
                                                </div>
                                                <div className="w-add-room rms-block-right">
                                                    <div className="w-add-room rms-inline-property-title">Restrictions</div>
                                                    <input
                                                        className={`w-add-room rms-edit-content rms-w--pristine rms-ng-untouched rms-ng-valid rms-ng-empty rms-ng-valid-maxlength ${isFocused ? 'rms-wc' : ''}`}
                                                        type="text" id="restrictions"
                                                        name="restrictions"
                                                        placeholder="Smoking / No Smoking"
                                                        value={roomData.restrictions || ''}
                                                        onChange={handleChange}
                                                        onFocus={handleFocus}

                                                    />
                                                </div>

                                            </div>
                                            <div className="w-add-room rms-email-phone cecker">
                                                <div className="w-add-room rms-availbility-checked">
                                                    <input type="checkbox"
                                                        id="availability"
                                                        name="availability"
                                                        className="w-add-room rms-check-style"
                                                        checked={roomData.availability}
                                                        onChange={e =>
                                                            setRoomData(prevState => ({ ...prevState, availability: e.target.checked }))} />

                                                    <div className="w-add-room rms-title-display">
                                                        <div className="w-add-room rms-title-ck"> Availability*</div>

                                                    </div>
                                                </div>
                                                <div className="w-add-room rms-availbility-checked">
                                                    <input type="checkbox"
                                                        className="w-add-room rms-check-style"
                                                        id="smoking"
                                                        name="smoking"
                                                        checked={roomData.smoking} onChange={e =>
                                                            setRoomData(prevState => ({ ...prevState, smoking: e.target.checked }))} />

                                                    <div className="rms-title-display">
                                                        <div className="rms-title-ck">Smoking*</div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="footer-button" className="w-add-room rms-ws-modal-dialog-footer-buttons">
                                            <div className="w-add-room rms-ws-modal-button-div">
                                                <button id="close-speed-dial-button" className="w-add-room rms-ws-btn rms-ws-btn-fluent rms-ws-btn-fluent-secondary" onClick={handleOverlayClick}>Cancel</button>
                                            </div>
                                            <div className="w-add-room ws-modal-button-div">
                                                <button id="add-speed-dial-button" className='w-add-room rms-ws-btn-fluent rms-ws-btn rms-ws-btn-fluent-primary'>Add</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            )}
            {isCreateRoomDetailVisible && (
                <div className='add-number-speed-dial'>
                    <div className='ts-modal-dialog-container vdi-occlusion'>
                        <div className="analytics-panel">
                            <div className="ts-modal-dialog-header">
                                <h1 className="app-font-family-base ts-modal-dialog-title">Add Room Details</h1>
                            </div>
                            <span className="w-c-error">{errorMessage}</span>
                            <span className="w-c-suceess">{successMessage}</span>
                            <div className="ts-modal-dialog-content ts-modal-dialog-content-suppress-default-footer">


                                <form onSubmit={event => handleSubmit(event, 'RoomDetailForm')}>
                                    <div className="ts-modal-dialog-content-container customScrollBar">
                                        <div className="w-c-form-contain">

                                            <div className="company-job">
                                                <div className="block-left">
                                                    <div className="inline-property-title">Room Name</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'rms-wc' : ''}`}
                                                        type="text"
                                                        id="roomName"
                                                        name="roomName"
                                                        placeholder=""
                                                        value={createName}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="block-right">
                                                    <div className="inline-property-title">Room Number</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'rms-wc' : ''}`}
                                                        type="number"
                                                        id="RoomNumber"
                                                        name="RoomNumber"
                                                        value={createNumber}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="company-job">
                                                <div className="block-left">
                                                    <div className="inline-property-title">Description</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'rms-wc' : ''}`}
                                                        type="text"
                                                        id="Description"
                                                        name="Description"
                                                        placeholder="room Description"
                                                        value={roomDetailData.Description || ''}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}

                                                    />
                                                </div>
                                                <div className="block-right">
                                                    <div className="inline-property-title">Room Level</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'rms-wc' : ''}`}
                                                        type="number"
                                                        id="RoomLevel"
                                                        name="RoomLevel"
                                                        value={roomDetailData.RoomLevel || ''}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}
                                                    />
                                                </div>
                                            </div>
                                            <div className="company-job">
                                                <div className="block-left">
                                                    <div className="inline-property-title">Do not disturb status</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'rms-wc' : ''}`}
                                                        type="DoNotDisturbStatus"
                                                        id="DoNotDisturbStatus"
                                                        name="DoNotDisturbStatus"
                                                        placeholder='Off'
                                                        value={roomDetailData.DoNotDisturbStatus}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}
                                                    />
                                                </div>
                                                <div className="block-right">
                                                    <div className="inline-property-title">House keeping requests</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'rms-wc' : ''}`}
                                                        type="HousekeepingRequests"
                                                        id="HousekeepingRequests"
                                                        name="HousekeepingRequests"
                                                        placeholder='None'
                                                        value={roomDetailData.HousekeepingRequests}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}

                                                    />
                                                </div>
                                            </div>
                                            <div className="company-job">
                                                <div className="block-left">
                                                    <div className="inline-property-title">Room type</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'rms-wc' : ''}`}
                                                        type="text" id="RoomType"
                                                        name="RoomType"
                                                        placeholder="Single/Double"
                                                        value={roomDetailData.RoomType || ''}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}
                                                    />
                                                </div>
                                                <div className="block-right">
                                                    <div className="inline-property-title">Room Id</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'rms-wc' : ''}`}
                                                        type="number"
                                                        id="RoomId"
                                                        name="RoomId"
                                                        value={createId}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="company-job">
                                                <div className="block-left">
                                                    <div className="inline-property-title">Room location</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'rms-wc' : ''}`}
                                                        type="text" id="RoomLocation"
                                                        name="RoomLocation"
                                                        placeholder="floor number"
                                                        value={roomDetailData.RoomLocation || ''}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}
                                                    />
                                                </div>
                                                <div className="block-right">
                                                    <div className="inline-property-title">Room safe status</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'rms-wc' : ''}`}
                                                        type="text" id="RoomSafeStatus"
                                                        name="RoomSafeStatus"
                                                        placeholder="Locked"
                                                        value={roomDetailData.RoomSafeStatus || ''}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}

                                                    />
                                                </div>

                                            </div>
                                            <div className="company-job">
                                                <div className="block-left">
                                                    <div className="inline-property-title">Room Temperature</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'wc' : ''}`}
                                                        type="text"
                                                        id="RoomTemperature"
                                                        name="RoomTemperature"
                                                        placeholder="72°F"
                                                        value={roomDetailData.RoomTemperature || ''}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}

                                                    />
                                                </div>
                                                <div className="block-right">
                                                    <div className="inline-property-title">Call Requests</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'wc' : ''}`}
                                                        type="text"
                                                        id="CallRequests"
                                                        name="CallRequests"
                                                        value={roomDetailData.CallRequests || ''}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}
                                                    />
                                                </div>
                                            </div>
                                            <div className="company-job">
                                                <div className="block-left">
                                                    <div className="inline-property-title">Laundry Status</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'wc' : ''}`}
                                                        type="text"
                                                        id="LaundryStatus"
                                                        name="LaundryStatus"
                                                        placeholder="None"
                                                        value={roomDetailData.LaundryStatus || ''}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}
                                                    />
                                                </div>
                                                <div className="block-right">
                                                    <div className="inline-property-title">Room Features</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'wc' : ''}`}
                                                        type="text"
                                                        id="RoomFeatures"
                                                        name="RoomFeatures"
                                                        placeholder='Balcony, King-sized bed, City view'
                                                        value={roomDetailData.RoomFeatures || ''}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}
                                                    />
                                                </div>
                                            </div>
                                            <div className="company-job">
                                                <div className="block-left">
                                                    <div className="inline-property-title">Room packages</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'wc' : ''}`}
                                                        type="text"
                                                        id="RoomPackages"
                                                        name="RoomPackages"
                                                        placeholder="None"
                                                        value={roomDetailData.RoomPackages || ''}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}

                                                    />
                                                </div>
                                                <div className="block-right">
                                                    <div className="inline-property-title">Room Service Orders</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'wc' : ''}`}
                                                        type="text"
                                                        id="RoomServiceOrders"
                                                        name="RoomServiceOrders"
                                                        placeholder='None'
                                                        value={roomDetailData.RoomServiceOrders || ''}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}
                                                    />
                                                </div>
                                            </div>
                                            <div className="company-job">
                                                <div className="block-left">
                                                    <div className="inline-property-title">Minibar Inventory</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'wc' : ''}`}
                                                        type="text"
                                                        id="MinibarInventory"
                                                        name="MinibarInventory"
                                                        placeholder="Fully stocked"
                                                        value={roomDetailData.MinibarInventory || ''}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}

                                                    />
                                                </div>
                                                <div className="block-right">
                                                    <div className="inline-property-title">Connecting Room Information</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'wc' : ''}`}
                                                        type="text"
                                                        id="ConnectingRoomInformation"
                                                        name="ConnectingRoomInformation"
                                                        placeholder='None'
                                                        value={roomDetailData.ConnectingRoomInformation || ''}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}
                                                    />
                                                </div>
                                            </div>
                                            <div className="company-job">
                                                <div className="block-left">
                                                    <div className="inline-property-title">Special Occasion Details</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'wc' : ''}`}
                                                        type="text"
                                                        id="SpecialOccasionDetails"
                                                        name="SpecialOccasionDetails"
                                                        placeholder="None"
                                                        value={roomDetailData.SpecialOccasionDetails || ''}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}

                                                    />
                                                </div>
                                                <div className="block-right">
                                                    <div className="inline-property-title">Room Cleaning Schedule</div>
                                                    <DatePicker
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'wc' : ''}`}
                                                        id="RoomCleaningSchedule"
                                                        name="RoomCleaningSchedule"
                                                        selected={roomDetailData.RoomCleaningSchedule || null}
                                                        onChange={handleDateChangeA}
                                                        onFocus={handleFocus}
                                                        dateFormat="yyyy-MM-dd"
                                                        placeholderText="yyyy-mm-dd"
                                                    />
                                                </div>
                                            </div>
                                            <div className="company-job">
                                                <div className="block-left">
                                                    <div className="inline-property-title">Room Availability Calendar</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'wc' : ''}`}
                                                        type="text"
                                                        id="RoomAvailabilityCalendar"
                                                        name="RoomAvailabilityCalendar"
                                                        placeholder="Available all month"
                                                        value={roomDetailData.RoomAvailabilityCalendar || ''}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}

                                                    />
                                                </div>
                                                <div className="block-right">
                                                    <div className="inline-property-title">Room Status Timestamps</div>
                                                    <DatePicker
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'wc' : ''}`}
                                                        id="RoomStatusTimestamps"
                                                        name="RoomStatusTimestamps"
                                                        selected={roomDetailData.RoomStatusTimestamps || null}
                                                        onChange={handleDateChangeB}
                                                        onFocus={handleFocus}
                                                        dateFormat="yyyy-MM-dd"
                                                        placeholderText="yyyy-mm-dd"
                                                    />
                                                </div>
                                            </div>
                                            <div className="company-job">
                                                <div className="block-left">
                                                    <div className="inline-property-title">Room Photos</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'wc' : ''}`}
                                                        type="file"
                                                        id="Photos"
                                                        name="Photos"
                                                        accept="image/*"
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}
                                                        style={{ padding: '0.3rem' }}

                                                    />

                                                </div>
                                                <div className="block-right">
                                                    <div className="inline-property-title">Room Temperature Control</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'wc' : ''}`}
                                                        type="text"
                                                        id="RoomTemperatureControl"
                                                        name="RoomTemperatureControl"
                                                        placeholder="Adjustable thermostat"
                                                        value={roomDetailData.RoomTemperatureControl || ''}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}
                                                    />
                                                </div>
                                            </div>
                                            <div className="company-job">
                                                <div className="block-left">
                                                    <div className="inline-property-title">Room In Room Safe Status</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'wc' : ''}`}
                                                        type="text"
                                                        id="RoomInRoomSafeStatus"
                                                        name="RoomInRoomSafeStatus"
                                                        placeholder="Locked"
                                                        value={roomDetailData.RoomInRoomSafeStatus || ''}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}

                                                    />
                                                </div>
                                                <div className="block-right">
                                                    <div className="inline-property-title">Wake Up Call Requests</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'wc' : ''}`}
                                                        type="text"
                                                        id="WakeUpCallRequests"
                                                        name="WakeUpCallRequests"
                                                        placeholder='None'
                                                        value={roomDetailData.WakeUpCallRequests || ''}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}
                                                    />
                                                </div>
                                            </div>
                                            <div className="company-job">
                                                <div className="block-left">
                                                    <div className="inline-property-title">Room Accessibility Features</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'wc' : ''}`}
                                                        type="text"
                                                        id="RoomAccessibilityFeatures"
                                                        name="RoomAccessibilityFeatures"
                                                        placeholder="Wheelchair accessible, Handrails in bathroom --"
                                                        value={roomDetailData.RoomAccessibilityFeatures || ''}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}

                                                    />
                                                </div>
                                                <div className="block-right">
                                                    <div className="inline-property-title">Guest Feedback Reviews</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'wc' : ''}`}
                                                        type="text"
                                                        id="GuestFeedbackReviews"
                                                        name="GuestFeedbackReviews"
                                                        placeholder='None'
                                                        value={roomDetailData.GuestFeedbackReviews || ''}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}
                                                    />
                                                </div>
                                            </div>
                                            <div className="company-job">
                                                <div className="block-left">
                                                    <div className="inline-property-title">Noise Level</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'wc' : ''}`}
                                                        type="text"
                                                        id="NoiseLevel"
                                                        name="NoiseLevel"
                                                        placeholder="near elevator / street-facing / Quiet"
                                                        value={roomDetailData.NoiseLevel || ''}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}

                                                    />
                                                </div>
                                                <div className="block-right">
                                                    <div className="inline-property-title">Room Status</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'wc' : ''}`}
                                                        type="text"
                                                        id="RoomStatus"
                                                        name="RoomStatus"
                                                        placeholder='Available'
                                                        value={roomDetailData.RoomStatus || ''}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}
                                                    />
                                                </div>
                                            </div>
                                            <div className="company-job">
                                                <div className="block-left">
                                                    <div className="inline-property-title">Local Events Attractions</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'wc' : ''}`}
                                                        type="text"
                                                        id="LocalEventsAttractions"
                                                        name="LocalEventsAttractions"
                                                        placeholder="City tour, Museum visit, Shopping district"
                                                        value={roomDetailData.LocalEventsAttractions || ''}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}

                                                    />
                                                </div>
                                                <div className="block-right">
                                                    <div className="inline-property-title">Guest Preferences</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'wc' : ''}`}
                                                        type="text"
                                                        id="GuestPreferences"
                                                        name="GuestPreferences"
                                                        placeholder='None'
                                                        value={roomDetailData.GuestPreferences || ''}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}
                                                    />
                                                </div>
                                            </div>
                                            <div className="company-job">
                                                <div className="block-left">
                                                    <div className="inline-property-title">Guest Messages</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'wc' : ''}`}
                                                        type="text"
                                                        id="GuestMessages"
                                                        name="GuestMessages"
                                                        placeholder="None"
                                                        value={roomDetailData.GuestMessages || ''}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}

                                                    />
                                                </div>
                                                <div className="block-right">
                                                    <div className="inline-property-title">Room Maintenance History</div>
                                                    <input
                                                        className={`edit-content w--pristine ng-untouched ng-valid ng-empty ng-valid-maxlength ${isFocused ? 'wc' : ''}`}
                                                        type="text"
                                                        id="RoomMaintenanceHistory"
                                                        name="RoomMaintenanceHistory"
                                                        placeholder='None'
                                                        value={roomDetailData.RoomMaintenanceHistory || ''}
                                                        onChange={handleRoomDetailChange}
                                                        onFocus={handleFocus}
                                                    />
                                                </div>
                                            </div>
                                            <div className="email-phone cecker">
                                                <div className="availbility-checked">
                                                    <Radio
                                                        name={radioName}
                                                        value='Availability'
                                                        label='Availability*'
                                                        checked={roomData.availability}
                                                        onChange={(e: any) =>
                                                            setRoomData((prevState) => ({
                                                                ...prevState,
                                                                availability: e.target.checked,
                                                            }))
                                                        }

                                                    />
                                                    {/* <input type="checkbox"
                                                        id="availability"
                                                        name="availability"
                                                        className="check-style"
                                                        checked={roomData.availability}
                                                        onChange={e =>
                                                            setRoomData(prevState => ({ ...prevState, availability: e.target.checked }))} />

                                                    <div className="title-display">
                                                        <div className="title-ck"> Availability*</div>

                                                    </div> */}
                                                </div>
                                                <div className="availbility-checked">
                                                    <Radio
                                                        name={radioName}
                                                        value='smoking'
                                                        label='Smoking*'
                                                        checked={roomData.smoking}
                                                        onChange={(e: any) =>
                                                            setRoomData((prevState) => ({
                                                                ...prevState,
                                                                smoking: e.target.checked,
                                                            }))
                                                        }

                                                    />

                                                    {/* <input type="checkbox"
                                                        className="check-style"
                                                        id="smoking"
                                                        name="smoking"
                                                        checked={roomData.smoking} onChange={e =>
                                                            setRoomData(prevState => ({ ...prevState, smoking: e.target.checked }))} />

                                                    <div className="title-display">
                                                        <div className="title-ck">Smoking*</div>

                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="footer-button" className="ws-modal-dialog-footer-buttons">
                                        <div className="ws-modal-button-div">
                                            <button id="close-speed-dial-button" className="ws-btn ws-btn-fluent ws-btn-fluent-secondary" onClick={handleOverlayClick}>Cancel</button>
                                        </div>
                                        <div className="ws-modal-button-div">
                                            <button id="add-speed-dial-button" className='ws-btn-fluent ws-btn ws-btn-fluent-primary'>Add</button>
                                        </div>
                                    </div>
                                </form>


                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isLoader ? (
                <div className="spin-shadow">
                    <div className="c-spinner"></div>
                </div>
            ) : null}

        </>
    );
}

export default RoomForm;

