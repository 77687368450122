import * as React from 'react';
import "./Message.scss";
import { Callout, DirectionalHint, List, TextField } from '@fluentui/react';
import {
    Button,
    makeStyles,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    SplitButton,
    Avatar,
    Tooltip,
    Persona,
    Divider,
    Field,
    mergeClasses,
    shorthands,
    tokens,
    Textarea,
    ToolbarGroup,
    Toolbar,
    ToolbarButton,
    ToolbarToggleButton,
    ToolbarDivider,
    ToolbarProps
} from "@fluentui/react-components";
import {
    bundleIcon,
    CalendarMonthFilled,
    CalendarMonthRegular,
    PersonAccounts24Regular,
    MailArrowDoubleBack24Regular,
    MailArrowDoubleBack24Filled,
    PeopleRegular,
    ChevronDown24Regular,
    Send24Regular,
    Send24Filled,
} from "@fluentui/react-icons";
import CilscoEditor from '../TextAreaEditor/CilscoEditor';
import { ChatMessage } from '../../../Data/General/Chat';
import axios from 'axios';
import { WHITE_HOUSE_API } from '../../../Constants/Constant';
import { useEffect, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import ToolSubtle from '../ToolBar/ToolBar';
import { GetAllGuest } from '../../../Data/General/Guest';

type GuestProps = {
    guest:GetAllGuest | undefined;
}

const SingleMessage: React.FC<GuestProps> = ({guest}) => {
    const [receiveMessages, setReceiveMessages] = useState<ChatMessage[]>([]);
    const [senderIds, setsenderIds] = React.useState('');
    const [receiverId, setReceiverId] = React.useState('');
    const [content, setContent] = React.useState('');
    const [isPrivate, setIsPrivate] = React.useState(false);
    const [chats, setChats] = useState<ChatMessage[]>([]);
    const [newMessage, setNewMessage] = useState<string>('');
    const [editMode, setEditMode] = useState(false);
    const [editChat, setEditChat] = useState<ChatMessage | null>(null);
    const [connection, setConnection] = useState<HubConnection | null>(null);
    const [isActive, setIsActive] = useState<boolean>(false);
    const [senderChat, setSenderChat] = useState<ChatMessage[]>([])

    const handleSubmit = async () => {
        await sendMessage(content);
    };


    const sendMessage = async (message: any) => {
        if (editMode && editChat) {
            try {

                await axios.put(`${WHITE_HOUSE_API}/chat/${editChat.id}`, {
                    ...editChat,
                    message,
                });
                setEditMode(false);
                setEditChat(null);
            } catch (error) {
                console.log('Updating message failed:', error);
            }
        } else {
            const chat: ChatMessage = {
                id: 0,
                SenderId: 1004,
                ReceiverId: 1002,
                GuidId: "Guidid1004",
                Message: message,
                Timestamp: new Date().toISOString(),
                IsPrivate: true,
                IsEmployee: false,
                IsGuest: false
            };

            try {
                const response = await axios.post(`${WHITE_HOUSE_API}/chat/send`, chat);
                if (response.data) {
                    setSenderChat(response.data);
                    console.log(response.data);
                    setContent("");
                }

            } catch (error) {
                console.log('Sending message failed:', error);
            }
        }
    };

    const handleEdit = (chat: ChatMessage) => {
        setEditMode(true);
        setEditChat(chat);
    };




    const getOrdinalSuffix = (date: Date) => {
        const day = date.getDate();
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
        }
    };

    const getFormattedDate = (dateString: string) => {
        const date = new Date(dateString);
        const dayOfWeek = date.toLocaleString('en-US', { weekday: 'long' });
        const month = date.toLocaleString('en-US', { month: 'long' });
        const dayOfMonth = date.getDate();
        const ordinalSuffix = getOrdinalSuffix(date);
        return `${dayOfWeek} ${month} ${dayOfMonth}${ordinalSuffix}`;
    };






    const someDate = new Date(); // September 2nd, 2023
    let currentDate = getFormattedDate(someDate.toDateString());


    return (
        <div className="p-view_contents p-view_contents--primary p-view_contents--channel-list-pry">
            <div className="display-content-chat">
                <div className="p-view_header p-view_header--tiles p-view_header--with-bookmarks-bar">
                    <div className="p-view_header__text">
                        <div className="c-coachmark-anchor p-view_header__coachmark_anchor">
                            <Menu>
                                <MenuTrigger disableButtonEnhancement>
                                    <MenuButton>
                                        <Avatar className="wc-margin-right" />
                                       {`${guest?.firstName || ''} ${guest?.language || ''}`}
                                    </MenuButton>
                                </MenuTrigger>

                                <MenuPopover>
                                    <MenuList>
                                        <MenuItem></MenuItem>
                                        <MenuItem></MenuItem>
                                    </MenuList>
                                </MenuPopover>
                            </Menu>
                        </div>
                    </div>
                    <div className="p-view_header__actions">
                        <div className="c-coachmark-anchor">
                            <div className="p-huddle_channel_header_button__container">
                                <div className="p-huddle_channel_header_button__slider"></div>
                                <Menu>
                                    <MenuTrigger disableButtonEnhancement>
                                        <SplitButton className="Sliting-wca" icon={<MailArrowDoubleBack24Filled className="color-icon12" />}>
                                            Email
                                        </SplitButton>
                                    </MenuTrigger>

                                    <MenuPopover>
                                        <MenuList>
                                            <MenuItem>Send Email to guest</MenuItem>
                                            <MenuItem>Call a guest</MenuItem>
                                        </MenuList>
                                    </MenuPopover>
                                </Menu>

                            </div>

                        </div>
                        <span className="channel_canvas_hover_preview_trigger wci-paddin-left">
                            <Tooltip
                                withArrow
                                content="Add Note, Schedule, and To-do"
                                relationship="label"
                            >
                                <Button icon={<i className="fi fi-tr-note-medical"></i>}></Button>
                            </Tooltip>
                        </span>
                    </div>
                </div>
                {/* bookmark bar here */}
                <div className="p-file_drag_drop__container customScrollBar">
                    <div className="p-workspace__primary_view_body">
                        <div className="c-virtual_list__scroll_container">
                            <div className="c-message_list__day_divider">
                                <Divider alignContent="center" className="wc-Floating"> <Menu>
                                    <MenuTrigger disableButtonEnhancement>
                                        <MenuButton shape="circular">{currentDate}</MenuButton>
                                    </MenuTrigger>

                                    <MenuPopover>
                                        <MenuList>
                                            <MenuItem>Most Recent Message</MenuItem>
                                            <MenuItem>Last week</MenuItem>
                                            <MenuItem>Last Month</MenuItem>
                                            <Divider alignContent="center" className="wc-Floating"></Divider>
                                            <MenuItem>Jump to a spacific date</MenuItem>
                                        </MenuList>
                                    </MenuPopover>
                                </Menu></Divider>
                            </div>
                            <div className="c-virtual_list__item c-virtual_list__item--initial-activeitem">
                                <div role="presentation" className="c-message_kit__background p-message_pane_message__message c-message_kit__message"
                                    data-qa="message_container" data-qa-unprocessed="false" data-qa-placeholder="false">
                                    <div role="document" aria-roledescription="message" className="c-message_kit__hover" data-qa-hover="true">
                                        {receiveMessages.map((message, index) => {
                                            let fullname = message.FirstName + " " + message.LastName;
                                            return (
                                                <div key={index} className="c-message_kit__actions c-message_kit__actions--default">
                                                    <div className="c-message_kit__gutter">
                                                        <Persona
                                                            textAlignment="start"
                                                            name={fullname}
                                                            presence={{ status: "offline" }}
                                                            tertiaryText={message.Message}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        {senderChat.map((m: any, i: any) => {
                                            let fullname = m.FirstName + " " + m.LastName;
                                            return (
                                                <div key={i} className="c-message_kit__actions c-message_kit__actions--default">
                                                    <div className="c-message_kit__gutter">
                                                        <Persona
                                                            textAlignment="start"
                                                            name={fullname}
                                                            presence={{ status: "available" }}
                                                            tertiaryText={m.Message}

                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}

                                    </div>
                                </div>
                            </div>        </div>

                    </div>
                </div>
                <div className="workspace__primary_view_footer p-workspace__primary_view_footer--float">
                    <div className="c-basic_container__body">
                        <>

                            <div className="Texarea-wc-control-Origin">
                                <div>
                                    <ToolSubtle />
                                </div>
                                <Textarea
                                    className="wc-textarea"
                                    placeholder="type here..."
                                    resize="both"
                                    value={content}
                                    onChange={(event) => {
                                        setContent(event.target.value);
                                        setIsActive(ms => !ms);
                                        console.log(content);
                                    }}
                                />

                                <div className="alignContent-right">
                                    <Toolbar>
                                        <ToolbarToggleButton
                                            appearance="subtle"
                                            aria-label="Send"
                                            icon={content === '' ? <Send24Regular className="wc-add-style" /> : <Send24Filled className="send-button-color" onClick={handleSubmit} />}
                                            name="textOptions"
                                            value="Send"
                                            disabled={content === ''}
                                        />
                                        <ToolbarDivider />
                                        <Menu>
                                            <MenuTrigger disableButtonEnhancement>
                                                <MenuButton
                                                    className="wc-add-style"
                                                    appearance="subtle"
                                                    icon={<ChevronDown24Regular className="wc-add-style" />}
                                                    disabled={content === ''}
                                                >
                                                </MenuButton>
                                            </MenuTrigger>

                                            <MenuPopover>
                                                <MenuList>
                                                    <MenuItem>Schedule Message</MenuItem>
                                                    <MenuItem>Send as an email</MenuItem>
                                                </MenuList>
                                            </MenuPopover>
                                        </Menu>
                                    </Toolbar>
                                </div>


                            </div>
                        </>
                    </div>
                </div>

            </div>
        </div>
    );
};


export default SingleMessage;