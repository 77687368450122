export interface AmenityType {
    amenityTypeId: number;
    amenityTypeName: string;
    description?: string;
    dateAdded: Date;
    adjuster?: string;
    typeId?: number;
}


export const InitalizeAmenityTpes: AmenityType = {
    amenityTypeId: 0,
    amenityTypeName: "",
    description: "",
    adjuster: "",
    typeId: 0,
    dateAdded: new Date(),
}
