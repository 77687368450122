export interface IUnit {
    unitId: number;
    unitName: string;
    description?: string;
    floorNumber?: number;
    maxCapacity?: number;
    departmentName?: string;
    departmentId?: number;
    roleId?: number;
    userId?: number;
    employeeId?: number;
    dateadded: Date;
}


export const initializeUbnits: IUnit = {
    unitId: 0,
    unitName: '',
    description: '',
    floorNumber: 0,
    maxCapacity: 0,
    departmentName: '',
    departmentId: 0,
    roleId: 0,
    userId: 0,
    employeeId: 0,
    dateadded: new Date(),
}


export interface DeletedUnit {
    Reason:string;
}

export const InitializeDeleted:DeletedUnit ={
    Reason: ""
}