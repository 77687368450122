

export interface RestaurantBill {
    billId: number;
    adjusterId:number;
    customerId: number;
    tableNumber: number;
    itemId: number;
    itemName: string;
    quantity: number;
    price: number;
    totalAmount: number;
    dateadded: Date;
    orderId: number;
}


export const initialRestaurantbill:RestaurantBill = {
    billId: 0,
    customerId: 0,
    adjusterId: 0,
    tableNumber: 0,
    itemId: 0,
    itemName: '',
    quantity: 0,
    price: 0,
    totalAmount: 0,
    dateadded: new Date(),
    orderId: 0,
}