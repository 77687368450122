import { select, line, transition, easeLinear } from 'd3';


import React, { FC, useEffect, useRef } from 'react';

interface ChartProps {
    cx: number;
    cy: number;
}
const data: ChartProps[] = [
    {
        cx: 0,
        cy: 12.033505856714706,
    },
    {
        cx: 2,
        cy: 9.769817488422705,
    },
    {
        cx: 4,
        cy: 9.528738763279632,
    },
    {
        cx: 6,
        cy: 13.406428766003593,
    },
    {
        cx: 8,
        cy: 12.397166984472943,
    },
    {
        cx: 10,
        cy: 12.916099155543302,
    },
    {
        cx: 12,
        cy: 24.606374284935693,
    },
    {
        cx: 14,
        cy: 25.62789430672812,
    },
    {
        cx: 16,
        cy: 25.811767910651053,
    },
    {
        cx: 18,
        cy: 26.379733042767405,
    },
    {
        cx: 20,
        cy: 29.967311359302673,
    },
    {
        cx: 22,
        cy: 27.89975483519464,
    },
    {
        cx: 24,
        cy: 30,
    },
    {
        cx: 26,
        cy: 25.456278943067332,
    },
    {
        cx: 28,
        cy: 28.884500136202476,
    },
    {
        cx: 30,
        cy: 25.488967583764662,
    },
    {
        cx: 32,
        cy: 22.40397711795146,
    },
    {
        cx: 34,
        cy: 17.933805502587596,
    },
    {
        cx: 36,
        cy: 17.1574502860255,
    },
    {
        cx: 38,
        cy: 17.353582130209467,
    },
    {
        cx: 40,
        cy: 17.888858621628863,
    },
    {
        cx: 42,
        cy: 20.152546989920864,
    },
    {
        cx: 44,
        cy: 20.540724598201912,
    },
    {
        cx: 46,
        cy: 19.192318169435836,
    },
    {
        cx: 48,
        cy: 17.153364205938242,
    },
    {
        cx: 50,
        cy: 14.18278398256606,
    },
    {
        cx: 52,
        cy: 13.116317079814529,
    },
    {
        cx: 54,
        cy: 13.157177880686374,
    },
    {
        cx: 56,
        cy: 12.474802506129004,
    },
    {
        cx: 58,
        cy: 12.654590029964307,
    },
    {
        cx: 60,
        cy: 12.360392263688356,
    },
    {
        cx: 62,
        cy: 14.485153909016528,
    },
    {
        cx: 64,
        cy: 12.250068101334596,
    },
    {
        cx: 66,
        cy: 8.637973304276517,
    },
    {
        cx: 68,
        cy: 4.649959139199169,
    },
    {
        cx: 70,
        cy: 4.56006537728133,
    },
    {
        cx: 72,
        cy: 4.83791882320899,
    },
    {
        cx: 74,
        cy: 1.7978752383545213,
    },
    {
        cx: 76,
        cy: 0,
    },
    {
        cx: 78,
        cy: 0.2492508853172182,
    },
    {
        cx: 80,
        cy: 0.40860800871697045,
    },
    {
        cx: 82,
        cy: 20.40860800871697045,
    },
    {
        cx: 84,
        cy: 40.40860800871697045,
    },
    {
        cx: 86,
        cy: 0.40860800871697045,
    },
    {
        cx: 88,
        cy: 0.40860800871697045,
    },
];


const ProductChart: FC = () => {
    const ref = useRef<SVGSVGElement>(null);

    useEffect(() => {
        if (ref.current) {
            const svg = select(ref.current);

            // Append circles for each data point
            svg.selectAll('circle')
                .data(data)
                .enter()
                .append('circle')
                .attr('cx', ({ cx }) => cx)
                .attr('cy', ({ cy }) => cy)
                .attr('r', 2)
                .style('stroke', 'none')
                .style('stroke-width', '0')
                .style('fill-opacity', '0.1')
                .style('fill', 'none')
                .style('pointer-events', 'auto');

            // Create a line generator function
            const lineGenerator = line<ChartProps>()
                .x(({ cx }) => cx)
                .y(({ cy }) => cy);

            // Append a path for the line
            const path = svg.append('path')
                .datum(data)
                .attr('d', lineGenerator)
                .attr('fill', 'none')
                .attr('stroke', 'rgb(16, 124, 16)')
                .attr('stroke-width', 1)
                .attr('stroke-linejoin', 'round')
                .attr('stroke-linecap', 'round');

            // Get the length of the path
            const totalLength = path.node()?.getTotalLength();

            if (totalLength) {
                // Animate the path
                path.attr("stroke-dasharray", totalLength + " " + totalLength)
                    .attr("stroke-dashoffset", totalLength)
                    .transition()
                    .duration(2000) // Set the duration of the animation in milliseconds
                    .ease(easeLinear) // Set the easing function
                    .attr("stroke-dashoffset", 0);

            }
        }
    }, []);

    return <svg ref={ref} viewBox="0 0 80 30" preserveAspectRatio="none" />;
};

export default ProductChart;


// const ProductChart: React.FC = () => {
//     return (
//         <svg viewBox="0 0 80 30" preserveAspectRatio="none">
//             <g>
//                 {data.map((point, index) => (
//                     <circle
//                         key={index}
//                         cx={point.cx}
//                         cy={point.cy}
//                         r="2"
//                         style={{
//                             stroke: 'none',
//                             strokeWidth: 0,
//                             fillOpacity: 0.1,
//                             fill: 'none',
//                             pointerEvents: 'auto',
//                         }}
//                     />
//                 ))}
//                 <polyline
//                     points={data.map((point) => `${point.cx} ${point.cy}`).join(' ')}
//                     style={{
//                         stroke: 'none',
//                         strokeWidth: 0,
//                         fillOpacity: 0.1,
//                         fill: 'none',
//                         pointerEvents: 'auto',
//                     }}
//                 />
//                 <polyline
//                     points={data.map((point) => `${point.cx} ${point.cy}`).join(' ')}
//                     style={{
//                         stroke: 'rgb(16, 124, 16)',
//                         strokeWidth: 1,
//                         strokeLinejoin: 'round',
//                         strokeLinecap: 'round',
//                         fill: 'none',
//                     }}
//                 />
//             </g>
//         </svg>
//     );
// };

// export default ProductChart;
