import React, { useCallback, useState } from 'react';
import './GuestProfile.scss';
import {
  Button,
  Divider,
  makeStyles,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  shorthands,
  Tab,
  TabList,
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  useId,
  TabListProps,
  PresenceBadgeStatus,
  Avatar,
  Combobox,
  Option,
  Persona,
  Field,
} from "@fluentui/react-components";

import { SearchBox } from '@fluentui/react-search-preview';
import {
  Chat20Regular,
  Clock20Regular,
  List20Regular,
  MailFilled,
  MoreHorizontal24Regular,
  SearchRegular,
  Settings20Regular,
} from '@fluentui/react-icons';
import {
  FolderRegular,
  EditRegular,
  OpenRegular,
  DocumentRegular,
  PeopleRegular,
  DocumentPdfRegular,
  VideoRegular,
} from '@fluentui/react-icons';
import Signals from '../../Themes/Images/Finance_stock_up_green_72x72.png';
import PromoCode from '../../Themes/Images/createBusinessProcessFlow.dd05207d.svg';
import ReactApexChart from 'react-apexcharts';
import GuestCharts from '../../Common/Chart/GuestChart';
import { SeriesData } from '../../../Constants/ChartData';
import SingleMessage from '../../Common/Message/Message';
import { GetAllGuest, GuestActivities } from '../../../Data/General/Guest';
import axios from 'axios';
import { WHITE_HOUSE_API } from '../../../Constants/Constant';
import Accounting from '../Accounting/Accounting';
import { ManageAccounts } from '../../../Data/General/Accounting';
import { IOrder } from '../../../Data/General/Order';
const useStyles = makeStyles({
  root: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    ...shorthands.padding('50px', '20px'),
    rowGap: '20px',
  },
});

interface Item {
  author: { label: string };
  lastUpdate: { label: string };
}

const items: Item[] = [
  {
    author: { label: 'Checked In' },
    lastUpdate: {
      label: 'Friday 5, 2023',
    },
  },

  {
    author: { label: 'Checked Out' },
    lastUpdate: {
      label: 'Friday 5, 2023',
    },
  },
];

interface User {
  email: string;
  phoneNumber: string;
  token: string;

  // other properties...
}

type ExtraProps = {
  user: User;
};

const GuestProfile: React.FC<ExtraProps & Partial<TabListProps>> = (props) => {
  const [showChat, setShowChat] = useState<boolean>(false);
  const [selectedActiveGuestTab, setActiveGuestTab] = React.useState('tab1');
  const comboboxId = useId("combobox");
  const [getGuest, setGetGuest] = useState<GetAllGuest>();
  const [autocomplete, setAutoComplete] = useState<GetAllGuest[]>([]);
  const [accounting, setAccounting] = useState<ManageAccounts>();
  const [order, setOrderList] = useState<IOrder[]>([]);
 const [numberofNight, setNumberofNight] = useState<number>(0);
  const handleAutoCompleteChangeName = (event: any) => {
    fetchAutoCompleteGuest(event.target.value);
  };



  const fetchGuestAccount = useCallback(async (id:any) => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/Accounting/${id}`, {
        headers: {
          'Authorization': `Bearer ${props.user.token}`
        }
      });
      setAccounting(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [props.user.token]);
  
  const fetchGuestOrders = useCallback(async (id:any) => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/Order/${id}`, {
        headers: {
          'Authorization': `Bearer ${props.user.token}`
        }
      });
      setOrderList(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [props.user.token]);
  


  const handleActionSelection = async (name:string, event:any) => {
    let empid = autocomplete.find(c => (c.firstName + " " + c.lastName).replace(/\s+/g, ' ').trim() === event.replace(/\s+/g, ' ').trim());
    setGetGuest(empid);
    if(empid?.checkInTime && empid?.checkOutTime){
      console.log("Value: ", empid?.guestId);
      let checkingtimeDate = new Date(empid?.checkInTime);
      let checkoutTimeDate = new Date(empid.checkOutTime);
      let differenceInTime = checkoutTimeDate.getTime() - checkingtimeDate.getTime();
      let numberOfNights = differenceInTime / (1000 * 3600 * 24);
      setNumberofNight(numberOfNights);
    }
    fetchGuestAccount(empid?.guestId);
    fetchGuestOrders(empid?.guestId);
  }


  const fetchAutoCompleteGuest = useCallback(async (uus:any) => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Query/guest?query=${uus}`,
        {
          headers: {
            Authorization: `Bearer ${props.user.token}`,
          },
        }
      );
      setAutoComplete(response.data);
      console.log("Guest details", response.data);
    } catch (error) {
      console.error('Failed to fetch guests:', error);
    }
  }, [props.user.token]);

  const handleGuestTabClick = (
    event: React.MouseEvent<HTMLElement>,
    tabValue: string
  ) => {
    setActiveGuestTab(tabValue);
  };

  let FullNames = getGuest 
  ? `${getGuest.firstName} ${getGuest.lastName}` 
  : 'Search Guest';
  const styles = useStyles();
  return (
    <>
      <div className="wc-container-holder">
        <div className={styles.root}>
          <TabList defaultSelectedValue="tab1">
            <Tab
              value="tab1"
              onClick={(event: any) => handleGuestTabClick(event, 'tab1')}
              icon={<Settings20Regular />}
            >
              General
            </Tab>
            <Tab
              value="tab2"
              onClick={(event: any) => handleGuestTabClick(event, 'tab2')}
              icon={<Chat20Regular />}
            >
              Chat
            </Tab>
            <Tab
              value="tab3"
              onClick={(event: any) => handleGuestTabClick(event, 'tab3')}
              icon={<Clock20Regular />}
            >
              Reminders
            </Tab>
            <Tab
              value="tab4"
              onClick={(event: any) => handleGuestTabClick(event, 'tab4')}
              icon={<List20Regular />}
            >
              Activity Log
            </Tab>
          </TabList>
          <Field className="SearchField">
            <Combobox
             clearable
              aria-labelledby={comboboxId}
              placeholder="Search Guest..."
              autoComplete="both"
              expandIcon={<SearchRegular />}
              onChange={handleAutoCompleteChangeName}
              onSelect={(event) => {
                const target = event.target as HTMLInputElement;
                const selectedValue = target.value; 
                handleActionSelection("guestId", selectedValue);
               
            }}
            >
              {autocomplete.map((result) =>(
                <Option
                  key={result.guestId}
                  value={`${result.firstName}  ${result.lastName}`}
                  text={`${result.firstName}  ${result.lastName}`}
                >
                  <Persona
                    avatar={{
                      color: 'colorful',
                      'aria-hidden': true,
                    }}
                    name={`${result.firstName} ${result.lastName}`}
                    presence={{
                      status: `${result?.guestStatus as PresenceBadgeStatus}`,
                    }}
                    secondaryText={result.email}
                  />
                </Option>
              ))}
            </Combobox>
          </Field>
        </div>
        <div className="wc-ui-content customScrollBar">
          {selectedActiveGuestTab === 'tab1' && (
            <>
              <div className="wc-container-flex">
                <div className="wc-content-pad-left">
                  <div className="wc-flex-content-header">
                    <Persona
                      name={FullNames}
                      secondaryText={getGuest?.email}
                      presence={{ status: `${getGuest?.guestStatus}` as PresenceBadgeStatus }}
                      {...props}
                    />
                    <div className="wc-flex-end">
                      <Menu hasIcons>
                        <MenuTrigger disableButtonEnhancement>
                          <Button
                            className="wc-no-backgroung"
                            icon={<MoreHorizontal24Regular />}
                          ></Button>
                        </MenuTrigger>
                        <MenuPopover>
                          <MenuList>
                            <MenuItem>More Info</MenuItem>
                            {/*
                                                        <MenuItem icon={<PasteIcon />}>Paste</MenuItem> */}
                            <MenuItem icon={<MailFilled />}>Email Guest</MenuItem>
                          </MenuList>
                        </MenuPopover>
                      </Menu>
                    </div>
                  </div>
                  <div className="Wc-Geust-stay">
                    <span className="ms-LinkTile root-302">
                      <div className="ms-LinkTile-iconBox iconBox-303">
                        <div className="ms-Image ms-LinkTile-icon icon-410">
                          <img
                            role="presentation"
                            className="ms-Image-image is-loaded ms-Image-image--portrait is-fadeIn image-411"
                            src={PromoCode}
                            alt=""
                          />
                        </div>
                        <span>code: WH-UHRTHER</span>
                      </div>
                      <div className="ms-LinkTile-descriptionSection descriptionSection-306">
                        <div
                          className="ms-LinkTile-title title-305"
                          title="Improve business performance with AI Builder"
                        >
                          Guest date of stays
                        </div>
                        <div className="ms-LinkTile-descriptionSubsection descriptionSubsection-307">
                          <div className="ms-LinkTile-tag tag-308">
                            Checked In
                          </div>
                          <div className="ms-LinkTile-time time-309">
                            <div>
                              
                            {getGuest?.checkInTime ? new Date(getGuest?.checkInTime).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }) : "Not Checked"}

                            
                            </div>
                          </div>
                        </div>
                        <Divider />
                        <div className="ms-LinkTile-descriptionSubsection descriptionSubsection-307">
                          <div className="ms-LinkTile-tag tag-308">
                            Checked Out
                          </div>
                          <div className="ms-LinkTile-time time-309">
                            <div>
                              
                            {getGuest?.checkOutTime ? new Date(getGuest?.checkOutTime).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }) : "Not Checked"}

                            </div>
                          </div>
                        </div>
                        <Divider />
                        <div className="ms-LinkTile-descriptionSubsection descriptionSubsection-307">
                          <div className="ms-LinkTile-tag tag-308">
                            Night Spent
                          </div>
                          <div className="ms-LinkTile-time time-309">
                            <div>{numberofNight}</div>
                          </div>
                        </div>
                        <Divider />
                        <div className="ms-LinkTile-descriptionSubsection descriptionSubsection-307">
                          <div className="ms-LinkTile-tag tag-308">
                            Booking Source
                          </div>
                          <div className="ms-LinkTile-time time-309">
                            <div>{getGuest?.sources}</div>
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
                <div className="wc-content-pad-right">
                  <div className="wc-header-slot">
                    <div className="cs-sd-card-header">
                      <div className="header-icon" slot="icon">
                        <img src={Signals} alt="" />
                      </div>
                      Finance Watchlist
                    </div>
                    <div className="wc-flex-end">
                      <Menu hasIcons>
                        <MenuTrigger disableButtonEnhancement>
                          <Button
                            className="wc-no-backgroung"
                            icon={<MoreHorizontal24Regular />}
                          ></Button>
                        </MenuTrigger>
                        <MenuPopover>
                          <MenuList>
                            <MenuItem>View More</MenuItem>
                            {/*
                              <MenuItem icon={<PasteIcon />}>Paste</MenuItem> */}
                            <MenuItem>Edit</MenuItem>
                          </MenuList>
                        </MenuPopover>
                      </Menu>
                    </div>
                  </div>
                  <div className="wc-money-info-content">
                    <div className="vertical-quote-list">
                      <div className="quote-item">
                        <div className="left">
                          <div className="symb">Amount Spent</div>
                          <div className="bottom-line">
                            <div className="disp-name">Pending Balance</div>
                          </div>
                        </div>
                        <div className="right">
                          <div className="prct-change spent-amount">
                            ‎- &#8358;{accounting?.totalDebitAmount.toLocaleString()}‎
                          </div>
                          <div className="price">&#8358; {accounting?.balance.toLocaleString()}</div>
                        </div>
                      </div>
                        <Divider>Orders</Divider>
                        <div className="BasicInfor_467 customScrollBar">
                            <div className="ETYHN73">
                            {order.map(item => {
                            // You can access each property of the item here. For example:
                            return (
                              <div className="quote-item">
                              <div className="left">
                                <div className="symb">
                                 {item.productName}
                                </div>
                                <div className="bottom-line">
                                  <div className="disp-name">Price</div>
                                </div>
                              </div>
                              <div className="right">
                                <div className="prct-change spent-amount">
                                  ‎-  &#8358; {item.totalAmount?.toLocaleString()}‎
                                </div>
                                <div className="price">&#8358;{item.price.toLocaleString()}</div>
                              </div>
                            </div>
                            );
                        })}

                            </div>
                       
                          
                          </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Horizontal-quote-list">
                <Accordion defaultOpenItems="basicInfo">
                  <AccordionItem value="basicInfo">
                    <AccordionHeader as="h2">
                      <Divider>Statistics and Analysis</Divider>
                    </AccordionHeader>
                    <AccordionPanel>
                      <div className="Statistics-quote-item">
                        <GuestCharts series={SeriesData} />
                        {/* <div className="left">
                                                    <div className="symb">White Rice and chicken</div>
                                                    <div className="bottom-line">
                                                        <div className="disp-name">Price</div>
                                                    </div>
                                                </div>
                                                <div className="right">
                                                    <div className="prct-change spent-amount">‎- $ 29.19‎</div>
                                                    <div className="price">$ 29.19</div>
                                                </div> */}
                      </div>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </>
          )}

          {selectedActiveGuestTab === 'tab2' && (
            <>
              <div className="display-content-chat">
                <SingleMessage guest={getGuest} />
              </div>
            </>
          )}
          {selectedActiveGuestTab === 'tab3' && <>Reminders content</>}
          {selectedActiveGuestTab === 'tab4' && <>Activity Log content</>}
        </div>
      </div>
    </>
  );
};

export default GuestProfile;
