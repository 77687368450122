import * as React from "react";
import {
    Button,
    Menu,
    MenuTrigger,
    MenuList,
    MenuItem,
    MenuPopover,
} from "@fluentui/react-components";
import {
    bundleIcon,
    ClipboardPasteRegular,
    ClipboardPasteFilled,
    CutRegular,
    CutFilled,
    CopyRegular,
    CopyFilled,
    MoreHorizontal24Regular,
    DocumentEdit24Regular,
    AlignSpaceEvenlyHorizontal24Regular,
    AlbumAddFilled,
    ApprovalsAppRegular
} from "@fluentui/react-icons";
import type { MenuProps } from "@fluentui/react-components";

const PasteIcon = bundleIcon(ClipboardPasteFilled, ClipboardPasteRegular);
const CopyIcon = bundleIcon(CopyFilled, CopyRegular);
const CutIcon = bundleIcon(CutFilled, CutRegular);

interface InteractionProps extends Partial<MenuProps> { 
    name?: string;
    onInteractionClick?: (name:any) => void;
}

export const Interaction: React.FC<InteractionProps> = ({ onInteractionClick, ...props }) => {

      const handleClick = (name:any) => {
        if(name === 'EventReservation' && onInteractionClick) {
            onInteractionClick(name);
        } else if(name === 'RoomReservation' && onInteractionClick) {
            onInteractionClick(name);
        }
      };
    return (
        <Menu {...props}>
            <MenuTrigger disableButtonEnhancement>
                <Button icon={<MoreHorizontal24Regular className="color-item" />}></Button>
            </MenuTrigger>

            <MenuPopover>
                <MenuList>
                    <MenuItem
                        icon={<ApprovalsAppRegular />}
                        onClick={() => handleClick("EventReservation")}
                    >
                        Event Center
                    </MenuItem>
                    {/* <MenuItem
                        icon={<CopyIcon />}
                        onClick={() => alert("Copied to clipboard")}
                    >
                      
                    </MenuItem> */}
                    <MenuItem
                        icon={<AlbumAddFilled />}
                        onClick={() => handleClick("RoomReservation")}
                    >
                        Room Reservation
                    </MenuItem>
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};
