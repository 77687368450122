export interface IClockedIn {
    UserId?: number;
    FirstName?: string;
    LastName?: string ;
    ClockInTime?: string ;
    ClockOutTime?: string ;
    ClockedInDate?: Date ;
    OnLeave: boolean;
    Status?: string ;
    TypeofLeave?: string ;
    Note?: string ;
}
 export const initializeIclock: IClockedIn = {
    UserId: 0,
    FirstName: "", 
    LastName: "", 
    ClockInTime: "", 
    ClockOutTime: "", 
    ClockedInDate: new Date(), 
    OnLeave: false,
    Status: "Working", 
    TypeofLeave: "", 
    Note: "", 
 }
 export const InitIclockout:IClockedIn = {
    UserId: 0,
    FirstName: "", 
    LastName: "", 
    ClockInTime: "", 
    ClockOutTime: "", 
    ClockedInDate: new Date(), 
    OnLeave: false,
    Status: "Closed",
 }