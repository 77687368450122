import { SearchBox } from '@fluentui/react-search-preview';
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Avatar,
  AvatarGroup,
  AvatarGroupItem,
  Checkbox,
  Field,
  Label,
  Radio,
  RadioGroup,
  Select,
  useToastController,
} from '@fluentui/react-components';
import type { SearchBoxProps } from '@fluentui/react-search-preview';
import axios from 'axios';
import { Persona, PersonaSize, initializeIcons } from '@fluentui/react';
import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Input } from '@fluentui/react-components';
import {
  ChevronLeft24Regular,
  PersonAdd24Regular,
  BoardSplit24Filled,
  PeopleRegular,
  WeatherRainShowersNight24Regular,
} from '@fluentui/react-icons';
import { TimePicker, ITimePickerProps } from '@fluentui/react';
import {
  Dropdown,
  makeStyles,
  Option,
  shorthands,
  useId,
  Caption1,
  tokens,
  Text,
  Link,
  Button,
  Toaster,
  Toast,
  ToastTitle,
  ToastBody,
  ToastFooter,
} from '@fluentui/react-components';
import type { DropdownProps } from '@fluentui/react-components';
import {
  IDropdownOption,
  DetailsList,
  IColumn,
  CheckboxVisibility,
} from '@fluentui/react';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import { WHITE_HOUSE_API } from '../../../Constants/Constant';
import './RegisterVendor.scss';
import { Vendor } from '../../../Data/General/Vendor';
import Loaders from '../../Common/Loader/Loader';
import { Restaurant, RestaurantItems } from '../../../Data/General/Restaurant';
import {
  FitNessItems,
  FitnessCenter,
} from '../../../Data/General/FitnessCenter';
import { isDate } from 'date-fns';
import DispatchToast from '../../Common/Toast/DispatchToast';
import {
  ICocktailsandDrink,
  InitialCockTailItems,
} from '../../../Data/General/CocktailsandDrink';
import {
  ISpaandWellness,
  InitialSpaAndWellness,
} from '../../../Data/General/SpaAndWellness';
import { IShops, InitializedShopItem } from '../../../Data/General/Shops';
interface Titles {
  titleId: number;
  titleName: string;
}
const useStyles = makeStyles({
  main: {
    ...shorthands.gap('16px'),
    display: 'flex',
    flexWrap: 'wrap',
  },

  card: {
    width: '400px',
    maxWidth: '100%',
    minHeight: '250px',
    height: 'fit-content',
  },

  caption: {
    color: tokens.colorNeutralForeground3,
  },

  smallRadius: {
    ...shorthands.borderRadius(tokens.borderRadiusSmall),
  },

  grayBackground: {
    backgroundColor: tokens.colorNeutralBackground3,
  },

  control: {
    maxWidth: '300px',
  },
  wrapper: {
    columnGap: '15px',
    display: 'flex',
    marginBottom: '10px',
  },
});

type RegisterCompanyProps = {
  close: () => void;
  message: string;
};
const RegisterProduct: React.FC<RegisterCompanyProps> = ({ close, message }) => {
  const [showForm, setShowForm] = useState(false);
  const [showDetails, setshowDetails] = useState(true);
  const ActionText = showForm ? 'Submit' : 'Book Now';
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<Vendor[]>([]);
  const [roomName, setRoomName] = useState('');
  const [roomNumber, setRoomNumber] = useState('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [showList, setShowList] = useState(false);
  const [hideAll, setHideAll] = useState(true);
  const [titleMapping, setTitleMapping] = useState<Titles[]>([]);
  const [filteredOptions, setFilteredOptions] = useState(titleMapping);
  const outlineId = useId('input-outline');
  const underlineId = useId('input-underline');
  const filledLighterId = useId('input-filledLighter');
  const filledDarkerId = useId('input-filledDarker');
  const [isSearchActive, setisSearchActive] = useState(false);
  const [registerRestuarant, setRegisterRestuarant] =
    useState<Restaurant>(RestaurantItems);
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [registerFitness, setRegisterFitness] =
    useState<FitnessCenter>(FitNessItems);
  const [registerCockTail, setRegisterCockTail] =
    useState<ICocktailsandDrink>(InitialCockTailItems);
  const [registerSpaWellness, setRegisterSpaWellness] =
    useState<ISpaandWellness>(InitialSpaAndWellness);
  const [registerShopProducts, setregisterShopProducts] =
    useState<IShops>(InitializedShopItem);
  const toasterId = useId('toaster');
  const [isOption, setIsOptions] = useState('Dietary & Restaurants');
  const [dispatchToaster, setDispatchToaster] = useState<boolean>(false);
  let submitresponse: any;
  const styles = useStyles();
  initializeIcons();

  const beforeLabelId = useId('before-label');
  const afterLabelId = useId('after-label');
  const [currency, setCurrency] = useState('NGN');
  const currencySymbol = currency === 'NGN' ? '₦' : '$';

  const handleCurrencyChange = (event: any) => {
    const option = event.target.value;
    setCurrency(option);
  };

  const items = [
    { key: '1', name: 'Name', value: 'Flat1 Deluxy' },
    { key: '2', name: 'Floor', value: 2 },
    { key: '3', name: 'Area', value: 28 },
    { key: '4', name: 'Capacity', value: '2 + 1, Max. 3' },
    { key: '5', name: 'Cleaning Required', value: 'Yes' },
    { key: '6', name: 'Smoking Allowed', value: 'No' },
  ];

  const columns: IColumn[] = [
    {
      key: 'column1',
      name: 'Name',
      fieldName: 'name',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column2',
      name: 'Value',
      fieldName: 'value',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  const handleBrowseAllClick = () => {
    setShowList((prevShowList) => !prevShowList);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(`Searching for: ${searchTerm}`);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  const fetchTitles = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/Title`);
      const Titles = response.data;
      setTitleMapping(Titles);
      setFilteredOptions(Titles);
      console.log('Title items:', Titles);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  }, []);
  useEffect(() => {}, [currency]);

  useEffect(() => {
    console.log(currencySymbol);
    fetchTitles();
  }, [fetchTitles, currency, currencySymbol]);

  const handleInputChange = (event: any) => {
    const que = event.target.value;
    setisSearchActive(event.target.value !== '');
    setQuery(que);
    if (que.length >= 1) {
      search(que);
    }
  };
  const toggleForm = () => {
    setShowForm((c) => !c);
    setshowDetails((c) => !c);
  };

  const SubmitMultipleRequest = async (category: any) => {
    try {
      setIsLoader(true);
      console.log('the Category is:', category);
      let apiUrl;
      switch (category) {
        case 'Dietary & Restaurants':
          apiUrl = `${WHITE_HOUSE_API}/Restuarant`;
          submitresponse = await axios.post(apiUrl, registerRestuarant);
          if (submitresponse.data) {
            setTimeout(() => {
              setIsLoader(false);
            }, 5000);

            if (submitresponse.data.message === 'Success') {
              message += ("Registration " + submitresponse.data.message);
              setDispatchToaster(true);

              setTimeout(() => {
                setDispatchToaster(false);
              }, 5000);
            }

            setRegisterRestuarant(RestaurantItems);
          }
          break;
        case 'Cocktails and Drinks':
          apiUrl = `${WHITE_HOUSE_API}/CocktailsandDrink`;
          submitresponse = await axios.post(apiUrl, registerCockTail);
          if (
            submitresponse.data !== null &&
            submitresponse.data !== typeof undefined
          ) {
            setTimeout(() => {
              setIsLoader(false);
            }, 5000);

            console.log('response message', submitresponse.data.message);
            if (submitresponse.data) {
              if (submitresponse.data.message === 'Success') {
                message += ("Registration " + submitresponse.data.message);
                setDispatchToaster(true);

                setTimeout(() => {
                  setDispatchToaster(false);
                }, 5000);
              }

              setRegisterCockTail(InitialCockTailItems);
              //setRegisterRestuarant(RestaurantItems);
            }
          }
          break;
        case 'New Fitness Center':
          apiUrl = `${WHITE_HOUSE_API}/FitnessCenter`;
          submitresponse = await axios.post(apiUrl, registerFitness);
          if (
            submitresponse.data !== null &&
            submitresponse.data !== typeof undefined
          ) {
            setTimeout(() => {
              setIsLoader(false);
            }, 2000);
            console.log('response message', submitresponse.data.message);
            if (submitresponse.data) {
              if (submitresponse.data.message === 'Success') {
                message += ("Registration " + submitresponse.data.message);
                setDispatchToaster(true);

                setTimeout(() => {
                  setDispatchToaster(false);
                }, 5000);
              }

              setRegisterFitness(FitNessItems);
              //setRegisterRestuarant(RestaurantItems);
            }
          }
          break;
        // Add more cases as needed
        case 'Spa and Wellness Services':
          apiUrl = `${WHITE_HOUSE_API}/SpaandWellness`;
          submitresponse = await axios.post(apiUrl, registerSpaWellness);
          if (
            submitresponse.data !== null &&
            submitresponse.data !== typeof undefined
          ) {
            setTimeout(() => {
              setIsLoader(false);
            }, 1500);

            console.log('response message', submitresponse.data.message);
            if (submitresponse.data) {
              if (submitresponse.data.message === 'Success') {
                message += ("Registration " + submitresponse.data.message);
                setDispatchToaster(true);

                setTimeout(() => {
                  setDispatchToaster(false);
                }, 5000);
              }

              setRegisterSpaWellness(registerSpaWellness);
              //setRegisterRestuarant(RestaurantItems);
            }
          }
          break;
        case 'Gift Shops':
          apiUrl = `${WHITE_HOUSE_API}/GiftShop`;
          submitresponse = await axios.post(apiUrl, registerShopProducts);
          if (
            submitresponse.data !== null &&
            submitresponse.data !== typeof undefined
          ) {
            setTimeout(() => {
              setIsLoader(false);
            }, 1500);

            console.log('response message', submitresponse.data.message);
            if (submitresponse.data) {
              if (submitresponse.data.message === 'Success') {
                message += ("Registration " + submitresponse.data.message);
                setDispatchToaster(true);

                setTimeout(() => {
                  setDispatchToaster(false);
                }, 5000);
              }

              setregisterShopProducts(registerShopProducts);
              //setRegisterRestuarant(RestaurantItems);
            }
          }
          break;

        default:
          apiUrl = `${WHITE_HOUSE_API}/defaultApi`; // replace with your default API   //
      }

      console.log(submitresponse.data);
    } catch (error) {
      message += ("Registration " + error);
      setIsLoader(false);
      console.error('Error:', error);
    }
  };

  /************** 
    const SubmitVendorsItem = async () => {
        try {
            setIsLoader(true);
            const response = await axios.post(`${WHITE_HOUSE_API}/Restuarant`, registerVendor);
            if (response.status === 200) {
                setIsLoader(false);
                notify();
                setRegisterVendor({ VendorId: 0 });
            }
            console.log(response.data);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    *************/


    function formatDate(dateString: Date): string {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero indexed, so +1 and pad with 0
      const day = ("0" + date.getDate()).slice(-2); // Pad with 0
      return `${year}-${month}-${day}`;
    }

  const handleVendorInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setRegisterRestuarant((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleCockTailInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setRegisterCockTail((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFitnessInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setRegisterFitness((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleSpaWellnessInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setRegisterSpaWellness((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleShopsInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setregisterShopProducts((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFitnessTextAreaInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setRegisterFitness((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSpaWellnessTextAreaInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setRegisterSpaWellness((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFitnessDateInputChange = (date: Date | null | undefined) => {
    if (date) {
      console.log('The Date onSelectDate', date);
      let formatedDate = formatDate(date);
      setRegisterFitness((prevState) => ({
        ...prevState,
        DateOfEntry: formatedDate,
      }));
    }
  };

  const handleSPaWwllnessDateInputChange =
    (fieldName: string) => (date: Date | null | undefined) => {
      if (date) {
        let formatedDate = formatDate(date);
        console.log(`The Date onSelectDate for ${fieldName}`, date);
        setRegisterSpaWellness((prevState) => ({
          ...prevState,
          [fieldName]: formatedDate,
        }));
      }
    };

  const handleShopsDateInputChange =
    (fieldName: string) => (date: Date | null | undefined) => {
      if (date) {
        let formatedDate = formatDate(date);
        console.log(`The Date onSelectDate for ${fieldName}`, date);
        setregisterShopProducts((prevState) => ({
          ...prevState,
          [fieldName]: formatedDate,
        }));
      }
    };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    if (checked) {
      setRegisterRestuarant((prevState) => ({
        ...prevState,
        MethodOfPayment: name,
      }));
    } else if (RestaurantItems.methodOfPayment === name) {
      setRegisterRestuarant((prevState) => ({
        ...prevState,
        MethodOfPayment: null,
      }));
    }
  };
  const handleFitnessCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = event.target;

    if (checked) {
      setRegisterFitness((prevState) => ({
        ...prevState,
        MethodOfPayment: name,
      }));
    } else if (RestaurantItems.methodOfPayment === name) {
      setRegisterFitness((prevState) => ({
        ...prevState,
        MethodOfPayment: null,
      }));
    }
  };

  const handleCockTailCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = event.target;

    if (checked) {
      setRegisterCockTail((prevState) => ({
        ...prevState,
        MethodOfPayment: name,
      }));
    } else if (RestaurantItems.methodOfPayment === name) {
      setRegisterCockTail((prevState) => ({
        ...prevState,
        MethodOfPayment: null,
      }));
    }
  };
  const handleSpaWellnessCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = event.target;

    if (checked) {
      setRegisterSpaWellness((prevState) => ({
        ...prevState,
        MethodOfPayment: name,
      }));
    } else if (RestaurantItems.methodOfPayment === name) {
      setRegisterSpaWellness((prevState) => ({
        ...prevState,
        MethodOfPayment: null,
      }));
    }
  };
  const handleShopesCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = event.target;

    if (checked) {
      setregisterShopProducts((prevState) => ({
        ...prevState,
        MethodOfPayment: name,
      }));
    } else if (RestaurantItems.methodOfPayment === name) {
      setregisterShopProducts((prevState) => ({
        ...prevState,
        MethodOfPayment: null,
      }));
    }
  };
  const handleCurrencyRadioChange = (
    event: React.FormEvent<HTMLDivElement>,
    data: any
  ) => {
    const { value } = data;
    const option = value;
    setCurrency(option);
    setRegisterRestuarant((prevState) => ({ ...prevState, Currency: value }));
  };

  
  const handleFitnessCurrencyRadioChange = (
    event: React.FormEvent<HTMLDivElement>,
    data: any
  ) => {
    const { value } = data;
    const option = value;
    setCurrency(option);
    setRegisterFitness((prevState) => ({ ...prevState, Currency: value }));
  };
  const handleSpaWellnessCurrencyRadioChange = (
    event: React.FormEvent<HTMLDivElement>,
    data: any
  ) => {
    const { value } = data;
    const option = value;
    setCurrency(option);
    setRegisterSpaWellness((prevState) => ({ ...prevState, Currency: value }));
  };
  const handleCockTailCurrencyRadioChange = (
    event: React.FormEvent<HTMLDivElement>,
    data: any
  ) => {
    const { value } = data;
    const option = value;
    setCurrency(option);
    setRegisterCockTail((prevState) => ({ ...prevState, Currency: value }));
  };

  const handleShopsCurrencyRadioChange = (
    event: React.FormEvent<HTMLDivElement>,
    data: any
  ) => {
    const { value } = data;
    const option = value;
    setCurrency(option);
    setregisterShopProducts((prevState) => ({ ...prevState, Currency: value }));
  };

  const handleFitnessTimepickerChange = (
    selectedTime: any,
    inputName: string
  ) => {
    setRegisterRestuarant((prevState) => ({
      ...prevState,
      [inputName]: selectedTime,
    }));
  };

  const search = async (query: any) => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Query/guest?query=${query}`,
        {
          params: { query },
        }
      );
      setResults(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const toggleOtion = (componentName: any) => {
    setIsOptions((currentComponent) =>
      currentComponent === componentName ? componentName : componentName
    );
  };

  return (
    <>
      <div className="ms-Fabric ms-Layer-content content-46">
        <div className="wc-no outline">
          <div className="ms-Modal is-open root-39">
            <div
              aria-hidden="true"
              className="ms-Overlay ms-Overlay--dark root-48"
            ></div>
            <div
              id="cilsco-focuszone"
              className="ms-Dialog-main createListModalMain_979b2e47 main-40"
            >
              <div
                aria-hidden="true"
                id="Ariel-fixed"
                data-is-visible="true"
                data-is-focus-trap-zone-bumper="true"
              ></div>
              <div className="ms-Modal-scrollableContent scrollableContent-54 customScrollBar">
                <div className="Merging-34">
                  <div className="createList-root templatePreviewPage">
                    <div className="templatePreview">
                      <div className="createList-pageRoot templatePreviewContainer">
                        {isLoader && (
                          <>
                            <Loaders />
                          </>
                        )}
                        {dispatchToaster && <DispatchToast message={message} />}
                        <div className="createList-header">
                          <div className="templateTitleWithLogo">
                            <Avatar
                              icon={<BoardSplit24Filled />}
                              aria-label="Group"
                            />
                            <h1 className="createList-headerTitle wc-margin">
                              New Product
                            </h1>
                          </div>
                          {/* use this to update all my search */}
                          <div
                            className={`ui-box Wsearch-ev-ew c-search_modal__wrapper popover-little ${
                              isSearchActive ? 'mouse-over-active' : ''
                            }`}
                          >
                            <Select
                              appearance="outline"
                              onChange={(e) => toggleOtion(e.target.value)}
                            >
                              <option selected>Dietary & Restaurants</option>
                              <option>Cocktails and Drinks</option>
                              <option>New Fitness Center</option>
                              <option>Spa and Wellness Services</option>
                              <option>Gift Shops</option>
                            </Select>
                           
                          </div>
                          {/* use this to update all my search */}
                          <div className="createList-closeBtnContainer">
                            <Button
                              icon={<i className="fi fi-sr-cross-small" />}
                              onClick={close}
                            ></Button>
                          </div>
                        </div>
                        <>
                          <div className="createList-pageContent includeBottomPadding customScrollBar">
                            <div className="previewContainer  wc-add-paddin">
                              <>
                                {isOption === 'Dietary & Restaurants' && (
                                  <form action="" className="Form-content">
                                    <div className="wform-MyContentControl-module__edgeworth-stack-item-container-refresh__3mUvv wform-css-283 ">
                                      <div className="ui-divider  ua-gi-ub-uc">
                                        <div className="wc-form-wrapper">
                                          <div className="w-add-Guest-room Guest-management-job">
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Product Name
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Product Name"
                                                  name="ProductName"
                                                  appearance="filled-darker"
                                                  id={filledDarkerId}
                                                  onChange={
                                                    handleVendorInputChange
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="w-add-room-guest rms-block-right">
                                              <div className="w-add-room-guest rms-inline-property-title">
                                                Product Description
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  appearance="filled-darker"
                                                  name="ProductDescription"
                                                  placeholder="A little about the product"
                                                  onChange={
                                                    handleVendorInputChange
                                                  }
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="w-add-Guest-room Guest-management-job">
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Ingredients
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Ingredients"
                                                  name="Ingredients"
                                                  appearance="filled-darker"
                                                  id={filledDarkerId}
                                                  onChange={
                                                    handleVendorInputChange
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="w-add-room-guest rms-block-right">
                                              <div className="w-add-room-guest rms-inline-property-title">
                                               Unit of Measurement
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  appearance="filled-darker"
                                                  name="unitofmeasurement"
                                                  type="number"
                                                  placeholder="unit of measurement"
                                                  id={filledDarkerId}
                                                  onChange={
                                                    handleVendorInputChange
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="w-add-Guest-room Guest-management-job">
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Serving Size
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Serving Size"
                                                  appearance="filled-darker"
                                                  type="text"
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>

                                            <div className="w-add-room-guest rms-block-right wc-margin">
                                              <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                                Product Price
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Pricing"
                                                  type="number"
                                                  name="Price"
                                                  contentBefore={
                                                    <Text
                                                      size={400}
                                                      id={beforeLabelId}
                                                    >
                                                      {currencySymbol}
                                                    </Text>
                                                  }
                                                  contentAfter={
                                                    <Text
                                                      size={400}
                                                      id={afterLabelId}
                                                    >
                                                      .00
                                                    </Text>
                                                  }
                                                  appearance="filled-darker"
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="w-add-Guest-room Guest-management-job">
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Preparation Time
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Preperation time"
                                                  name="PreparationTime"
                                                  type="text"
                                                  appearance="filled-darker"
                                                  onChange={
                                                    handleVendorInputChange
                                                  }
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title"></div>
                                              <div className="firstName-container">
                                               
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="wform-MyContentControl-module__edgeworth-stack-item-container-refresh__3mUvv wform-css-283-right">
                                      <Accordion defaultOpenItems="basicInfo">
                                        <AccordionItem value="basicInfo">
                                          <AccordionHeader as="h2">
                                            More information
                                          </AccordionHeader>
                                          <AccordionPanel>
                                            <Label className="wc-pmargin-b">
                                              Mode of payment
                                            </Label>
                                            <AccordionPanel>
                                              <div
                                                role="group"
                                                aria-labelledby="hobbiesLabel"
                                              >
                                                <Checkbox
                                                  label="Direct Credit"
                                                  name="MethodOfPayment"
                                                  onChange={
                                                    handleCheckboxChange
                                                  }
                                                />
                                                <Checkbox
                                                  label="After Sells"
                                                  name="MethodOfPayment"
                                                  onChange={
                                                    handleCheckboxChange
                                                  }
                                                />
                                                <Checkbox
                                                  label="Invoice"
                                                  name="MethodOfPayment"
                                                  onChange={
                                                    handleCheckboxChange
                                                  }
                                                />
                                              </div>
                                            </AccordionPanel>
                                            <div className="w-add-Guest-room Guest-management-job">
                                              <div className="w-add-room-guest rms-block-right">
                                                <div className="w-add-room-guest rms-inline-property-title wc-margin"></div>
                                                <div className="firstName-container wc-margin">
                                                 
                                                </div>
                                              </div>
                                            </div>
                                            <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                              Currency Used
                                            </div>
                                            <RadioGroup
                                              defaultValue="NGN"
                                              name="Currency"
                                              onChange={
                                                handleCurrencyRadioChange
                                              }
                                              aria-labelledby="ageLabel"
                                              className="wc-flex"
                                            >
                                              <Radio value="NGN" label="NGN" />
                                              <Radio value="USD" label="USD" />
                                            </RadioGroup>
                                          </AccordionPanel>
                                        </AccordionItem>
                                      </Accordion>
                                    </div>
                                  </form>
                                )}

                                {isOption === 'New Fitness Center' && (
                                  <form action="" className="Form-content">
                                    <div className="wform-MyContentControl-module__edgeworth-stack-item-container-refresh__3mUvv wform-css-283 ">
                                      <div className="ui-divider  ua-gi-ub-uc">
                                        <div className="wc-form-wrapper">
                                          <div className="w-add-Guest-room Guest-management-job">
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Fitness Name
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Fitness Name"
                                                  name="FirtnessName"
                                                  appearance="filled-darker"
                                                  id={filledDarkerId}
                                                  onChange={
                                                    handleFitnessInputChange
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="w-add-room-guest rms-block-right">
                                              <div className="w-add-room-guest rms-inline-property-title">
                                                Fitness Description
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  appearance="filled-darker"
                                                  name="FitnessDescription"
                                                  placeholder="A little about the product"
                                                  onChange={
                                                    handleFitnessInputChange
                                                  }
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>
                                          </div>

                                          <div className="w-add-Guest-room Guest-management-job">
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Time In
                                              </div>
                                              <div className="firstName-container">
                                                <div className="firstName-container">
                                                  <Input
                                                    appearance="filled-darker"
                                                    type="text"
                                                    name="TimeIn"
                                                    placeholder="Time In e.g: HH:mm:ss"
                                                    onChange={
                                                      handleFitnessInputChange
                                                    }
                                                    id={filledDarkerId}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="w-add-room-guest rms-block-right">
                                              <div className="w-add-room-guest rms-inline-property-title">
                                                Time Out
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  appearance="filled-darker"
                                                  type="text"
                                                  name="TimeOut"
                                                  placeholder="TimeOut e.g: HH:mm:ss"
                                                  onChange={
                                                    handleFitnessInputChange
                                                  }
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="w-add-Guest-room Guest-management-job">
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Date Of Entry
                                              </div>
                                              <div className="firstName-container">
                                                <Field>
                                                  <DatePicker
                                                    name="DateOfEntry"
                                                    showWeekNumbers={true}
                                                    firstWeekOfYear={1}
                                                    showMonthPickerAsOverlay={
                                                      true
                                                    }
                                                    placeholder="Entry Date..."
                                                    className={styles.control}
                                                    onSelectDate={
                                                      handleFitnessDateInputChange
                                                    }
                                                  />
                                                </Field>
                                              </div>
                                            </div>
                                            <div className="w-add-room-guest rms-block-right">
                                              <div className="w-add-room-guest rms-inline-property-title">
                                                Usage Duration
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  appearance="filled-darker"
                                                  name="UsageDuration"
                                                  type="text"
                                                  placeholder="Usage Duration"
                                                  id={filledDarkerId}
                                                  onChange={
                                                    handleFitnessInputChange
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>

                                          <div className="w-add-Guest-room Guest-management-job">
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Equipment Used
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Equipment Used"
                                                  name="EquipmentUsed"
                                                  appearance="filled-darker"
                                                  onChange={
                                                    handleFitnessInputChange
                                                  }
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>
                                            <div className="w-add-room-guest rms-block-right">
                                              <div className="w-add-room-guest rms-inline-property-title">
                                                Staff Presence
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  appearance="filled-darker"
                                                  name="StaffPresence"
                                                  onChange={
                                                    handleFitnessInputChange
                                                  }
                                                  placeholder="Name of staff presence"
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="w-add-Guest-room Guest-management-job">
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Membership Id
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Membership Id"
                                                  name="MembershipId"
                                                  appearance="filled-darker"
                                                  onChange={
                                                    handleFitnessInputChange
                                                  }
                                                  type="text"
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>

                                            <div className="w-add-room-guest rms-block-right wc-margin">
                                              <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                                Price
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Pricing"
                                                  type="number"
                                                  name="Price"
                                                  contentBefore={
                                                    <Text
                                                      size={400}
                                                      id={beforeLabelId}
                                                    >
                                                      {currencySymbol}
                                                    </Text>
                                                  }
                                                  contentAfter={
                                                    <Text
                                                      size={400}
                                                      id={afterLabelId}
                                                    >
                                                      .00
                                                    </Text>
                                                  }
                                                  appearance="filled-darker"
                                                  id={filledDarkerId}
                                                  onChange={
                                                    handleFitnessInputChange
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="w-add-Guest-room Guest-management-job">
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Access Control
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Preperation time"
                                                  name="AccessControl"
                                                  type="text"
                                                  appearance="filled-darker"
                                                  onChange={
                                                    handleFitnessInputChange
                                                  }
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Inventory
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Inventory"
                                                  name="Inventory"
                                                  appearance="filled-darker"
                                                  onChange={
                                                    handleFitnessInputChange
                                                  }
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="wform-MyContentControl-module__edgeworth-stack-item-container-refresh__3mUvv wform-css-283-right">
                                      <Accordion defaultOpenItems="basicInfo">
                                        <AccordionItem value="basicInfo">
                                          <AccordionHeader as="h2">
                                            More information
                                          </AccordionHeader>
                                          <AccordionPanel>
                                            <Label className="wc-pmargin-b">
                                              Mode of payment
                                            </Label>
                                            <AccordionPanel>
                                              <div
                                                role="group"
                                                aria-labelledby="hobbiesLabel"
                                              >
                                                <Checkbox
                                                  label="Direct Credit"
                                                  name="Direct Credit"
                                                  onChange={
                                                    handleFitnessCheckboxChange
                                                  }
                                                />
                                                <Checkbox
                                                  label="After Sells"
                                                  name="After Sells"
                                                  onChange={
                                                    handleFitnessCheckboxChange
                                                  }
                                                />
                                                <Checkbox
                                                  label="Invoice"
                                                  name="Invoice"
                                                  onChange={
                                                    handleFitnessCheckboxChange
                                                  }
                                                />
                                              </div>
                                            </AccordionPanel>
                                            <div className="w-add-Guest-room Guest-management-job">
                                              <div className="w-add-room-guest rms-block-right">
                                                <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                                  User Demographics
                                                </div>
                                                <div className="firstName-container wc-margin">
                                                  <Input
                                                    appearance="filled-darker"
                                                    placeholder="User Demographics"
                                                    type="text"
                                                    name="UserDemographics"
                                                    onChange={
                                                      handleFitnessInputChange
                                                    }
                                                    id={filledDarkerId}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                              Currency Used
                                            </div>
                                            <RadioGroup
                                              defaultValue="NGN"
                                              name="Currency"
                                              onChange={
                                                handleFitnessCurrencyRadioChange
                                              }
                                              aria-labelledby="ageLabel"
                                              className="wc-flex"
                                            >
                                              <Radio value="NGN" label="NGN" />
                                              <Radio value="USD" label="USD" />
                                            </RadioGroup>
                                            <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                              Notes:
                                            </div>
                                            <div className="w-add-Guest-room Guest-management-job">
                                              <div className="meeting-subject_inputBoxWrapper_8x9syJ wc-input text-area Fitness-text-note">
                                                <div className="text-area">
                                                  <div className="text-area">
                                                    <textarea
                                                      name="Notes"
                                                      className="wc-ruene text-area"
                                                      id="cv2-sf-meeting-subject-input"
                                                      placeholder="Add Notes"
                                                      onChange={
                                                        handleFitnessTextAreaInputChange
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </AccordionPanel>
                                        </AccordionItem>
                                      </Accordion>
                                    </div>
                                  </form>
                                )}

                                {isOption === 'Cocktails and Drinks' && (
                                  <form action="" className="Form-content">
                                    <div className="wform-MyContentControl-module__edgeworth-stack-item-container-refresh__3mUvv wform-css-283 ">
                                      <div className="ui-divider  ua-gi-ub-uc">
                                        <div className="wc-form-wrapper">
                                          <div className="w-add-Guest-room Guest-management-job">
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Product Name
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Product Name"
                                                  name="ProductName"
                                                  appearance="filled-darker"
                                                  id={filledDarkerId}
                                                  onChange={
                                                    handleCockTailInputChange
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="w-add-room-guest rms-block-right">
                                              <div className="w-add-room-guest rms-inline-property-title">
                                                product Description
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  appearance="filled-darker"
                                                  name="ProductDescription"
                                                  placeholder="A little about the product"
                                                  onChange={
                                                    handleCockTailInputChange
                                                  }
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>
                                          </div>

                                          <div className="w-add-Guest-room Guest-management-job">
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Quantity
                                              </div>
                                              <div className="firstName-container">
                                                <div className="firstName-container">
                                                  <Input
                                                    appearance="filled-darker"
                                                    type="text"
                                                    name="Quantity"
                                                    placeholder="Quantity"
                                                    onChange={
                                                      handleCockTailInputChange
                                                    }
                                                    id={filledDarkerId}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="w-add-room-guest rms-block-right">
                                              <div className="w-add-room-guest rms-inline-property-title">
                                              Metric System
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  appearance="filled-darker"
                                                  type="text"
                                                  name="unitofmeasurement"
                                                  placeholder="75cl/900g/120kg"
                                                  onChange={
                                                    handleCockTailInputChange
                                                  }
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                         
                                          <div className="w-add-Guest-room Guest-management-job">
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Product Owner
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Product owner  Or name"
                                                  name="MembershipId"
                                                  appearance="filled-darker"
                                                  onChange={
                                                    handleCockTailInputChange
                                                  }
                                                  type="text"
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>

                                            <div className="w-add-room-guest rms-block-right wc-margin">
                                              <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                                Price
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Pricing"
                                                  type="number"
                                                  name="Price"
                                                  contentBefore={
                                                    <Text
                                                      size={400}
                                                      id={beforeLabelId}
                                                    >
                                                      {currencySymbol}
                                                    </Text>
                                                  }
                                                  contentAfter={
                                                    <Text
                                                      size={400}
                                                      id={afterLabelId}
                                                    >
                                                      .00
                                                    </Text>
                                                  }
                                                  appearance="filled-darker"
                                                  id={filledDarkerId}
                                                  onChange={
                                                    handleCockTailInputChange
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                         
                                        </div>
                                      </div>
                                    </div>
                                    <div className="wform-MyContentControl-module__edgeworth-stack-item-container-refresh__3mUvv wform-css-283-right">
                                      <Accordion defaultOpenItems="basicInfo">
                                        <AccordionItem value="basicInfo">
                                          <AccordionHeader as="h2">
                                            More information
                                          </AccordionHeader>
                                          <AccordionPanel>
                                            <Label className="wc-pmargin-b">
                                              Mode of payment
                                            </Label>
                                            <AccordionPanel>
                                              <div
                                                role="group"
                                                aria-labelledby="hobbiesLabel"
                                              >
                                                <Checkbox
                                                  label="Direct Credit"
                                                  name="Direct Credit"
                                                  onChange={
                                                    handleCockTailCheckboxChange
                                                  }
                                                />
                                                <Checkbox
                                                  label="After Sells"
                                                  name="After Sells"
                                                  onChange={
                                                    handleCockTailCheckboxChange
                                                  }
                                                />
                                                <Checkbox
                                                  label="Invoice"
                                                  name="Invoice"
                                                  onChange={
                                                    handleCockTailCheckboxChange
                                                  }
                                                />
                                              </div>
                                            </AccordionPanel>
                                            <div className="w-add-Guest-room Guest-management-job">
                                              <div className="w-add-room-guest rms-block-right">
                                               
                                              </div>
                                            </div>
                                            <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                              Currency Used
                                            </div>
                                            <RadioGroup
                                              defaultValue="NGN"
                                              name="Currency"
                                              onChange={
                                                handleCockTailCurrencyRadioChange
                                              }
                                              aria-labelledby="ageLabel"
                                              className="wc-flex"
                                            >
                                              <Radio value="NGN" label="NGN" />
                                              <Radio value="USD" label="USD" />
                                            </RadioGroup>
                                           
                                          </AccordionPanel>
                                        </AccordionItem>
                                      </Accordion>
                                    </div>
                                  </form>
                                )}

                                {isOption === 'Spa and Wellness Services' && (
                                  <form action="" className="Form-content">
                                    <div className="wform-MyContentControl-module__edgeworth-stack-item-container-refresh__3mUvv wform-css-283 ">
                                      <div className="ui-divider  ua-gi-ub-uc">
                                        <div className="wc-form-wrapper">
                                          <div className="w-add-Guest-room Guest-management-job">
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Wellness Name
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Wellness & Spa Name"
                                                  name="WellnessName"
                                                  appearance="filled-darker"
                                                  id={filledDarkerId}
                                                  onChange={
                                                    handleSpaWellnessInputChange
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="w-add-room-guest rms-block-right">
                                              <div className="w-add-room-guest rms-inline-property-title">
                                                Wellness Description
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  appearance="filled-darker"
                                                  name="WellnessDescription"
                                                  placeholder="Wellness and spa Description"
                                                  onChange={
                                                    handleSpaWellnessInputChange
                                                  }
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>
                                          </div>

                                          <div className="w-add-Guest-room Guest-management-job">
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Appointment Time
                                              </div>
                                              <div className="firstName-container">
                                                <div className="firstName-container">
                                                  <Input
                                                    appearance="filled-darker"
                                                    type="text"
                                                    name="AppointmentTime"
                                                    placeholder="Appointment time e.g: HH:mm:ss"
                                                    onChange={
                                                      handleSpaWellnessInputChange
                                                    }
                                                    id={filledDarkerId}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="w-add-room-guest rms-block-right">
                                              <div className="w-add-room-guest rms-inline-property-title">
                                                Treatment Duration
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  appearance="filled-darker"
                                                  type="text"
                                                  name="TreatmentDuration"
                                                  placeholder="Treatment duration e.g: HH:mm:ss"
                                                  onChange={
                                                    handleSpaWellnessInputChange
                                                  }
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="w-add-Guest-room Guest-management-job">
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Treatment Schedule
                                              </div>
                                              <div className="firstName-container">
                                                <Field>
                                                  <DatePicker
                                                    name="TreatmentSchedule"
                                                    showWeekNumbers={true}
                                                    firstWeekOfYear={1}
                                                    showMonthPickerAsOverlay={
                                                      true
                                                    }
                                                    placeholder="Treatment schedule..."
                                                    className={styles.control}
                                                    onSelectDate={handleSPaWwllnessDateInputChange(
                                                      'TreatmentSchedule'
                                                    )}
                                                  />
                                                </Field>
                                              </div>
                                            </div>
                                            <div className="w-add-room-guest rms-block-right">
                                              <div className="w-add-room-guest rms-inline-property-title">
                                                Staff Scheduling
                                              </div>
                                              <div className="firstName-container">
                                                <Field>
                                                  <DatePicker
                                                    name="StaffScheduling"
                                                    showWeekNumbers={true}
                                                    firstWeekOfYear={1}
                                                    showMonthPickerAsOverlay={
                                                      true
                                                    }
                                                    placeholder="Treatment schedule..."
                                                    className={styles.control}
                                                    onSelectDate={handleSPaWwllnessDateInputChange(
                                                      'StaffScheduling'
                                                    )}
                                                  />
                                                </Field>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="w-add-Guest-room Guest-management-job">
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Cancellation Log
                                              </div>
                                              <div className="firstName-container">
                                                <Field>
                                                  <DatePicker
                                                    name="CancellationLog"
                                                    showWeekNumbers={true}
                                                    firstWeekOfYear={1}
                                                    showMonthPickerAsOverlay={
                                                      true
                                                    }
                                                    placeholder="Cancellation Log..."
                                                    className={styles.control}
                                                    onSelectDate={handleSPaWwllnessDateInputChange(
                                                      'CancellationLog'
                                                    )}
                                                  />
                                                </Field>
                                              </div>
                                            </div>
                                            <div className="w-add-room-guest rms-block-right">
                                              <div className="w-add-room-guest rms-inline-property-title">
                                                Client Name
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  appearance="filled-darker"
                                                  name="ClientName"
                                                  onChange={
                                                    handleSpaWellnessInputChange
                                                  }
                                                  placeholder="Client name"
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="w-add-Guest-room Guest-management-job">
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Service type
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Type of Services"
                                                  name="ServiceType"
                                                  appearance="filled-darker"
                                                  onChange={
                                                    handleSpaWellnessInputChange
                                                  }
                                                  type="text"
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>

                                            <div className="w-add-room-guest rms-block-right wc-margin">
                                              <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                                Price
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Pricing"
                                                  type="number"
                                                  name="Price"
                                                  contentBefore={
                                                    <Text
                                                      size={400}
                                                      id={beforeLabelId}
                                                    >
                                                      {currencySymbol}
                                                    </Text>
                                                  }
                                                  contentAfter={
                                                    <Text
                                                      size={400}
                                                      id={afterLabelId}
                                                    >
                                                      .00
                                                    </Text>
                                                  }
                                                  appearance="filled-darker"
                                                  id={filledDarkerId}
                                                  onChange={
                                                    handleSpaWellnessInputChange
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="w-add-Guest-room Guest-management-job">
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Therapist
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Therapist"
                                                  name="Therapist"
                                                  type="text"
                                                  appearance="filled-darker"
                                                  onChange={
                                                    handleSpaWellnessInputChange
                                                  }
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Inventory usage
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Inventory usage"
                                                  name="InventoryUsage"
                                                  appearance="filled-darker"
                                                  onChange={
                                                    handleSpaWellnessInputChange
                                                  }
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="w-add-Guest-room Guest-management-job">
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Booking Source
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Booking Source"
                                                  name="PookingSource"
                                                  type="text"
                                                  appearance="filled-darker"
                                                  onChange={
                                                    handleSpaWellnessInputChange
                                                  }
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Special Requests
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Special requests"
                                                  name="SpecialRequests"
                                                  appearance="filled-darker"
                                                  onChange={
                                                    handleSpaWellnessInputChange
                                                  }
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="w-add-Guest-room Guest-management-job">
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Inventory Replenishment
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Inventory replenishment"
                                                  name="InventoryReplenishment"
                                                  type="text"
                                                  appearance="filled-darker"
                                                  onChange={
                                                    handleSpaWellnessInputChange
                                                  }
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                No Show Records
                                              </div>
                                              <div className="firstName-container">
                                                <Field>
                                                  <DatePicker
                                                    name="NoShowRecords"
                                                    showWeekNumbers={true}
                                                    firstWeekOfYear={1}
                                                    showMonthPickerAsOverlay={
                                                      true
                                                    }
                                                    placeholder="No show records..."
                                                    className={styles.control}
                                                    onSelectDate={handleSPaWwllnessDateInputChange(
                                                      'NoShowRecords'
                                                    )}
                                                  />
                                                </Field>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="wform-MyContentControl-module__edgeworth-stack-item-container-refresh__3mUvv wform-css-283-right">
                                      <Accordion defaultOpenItems="basicInfo">
                                        <AccordionItem value="basicInfo">
                                          <AccordionHeader as="h2">
                                            More information
                                          </AccordionHeader>
                                          <AccordionPanel>
                                            <Label className="wc-pmargin-b">
                                              Mode of payment
                                            </Label>
                                            <AccordionPanel>
                                              <div
                                                role="group"
                                                aria-labelledby="hobbiesLabel"
                                              >
                                                <Checkbox
                                                  label="Direct Credit"
                                                  name="Direct Credit"
                                                  onChange={
                                                    handleSpaWellnessCheckboxChange
                                                  }
                                                />
                                                <Checkbox
                                                  label="After Sells"
                                                  name="After Sells"
                                                  onChange={
                                                    handleSpaWellnessCheckboxChange
                                                  }
                                                />
                                                <Checkbox
                                                  label="Invoice"
                                                  name="Invoice"
                                                  onChange={
                                                    handleSpaWellnessCheckboxChange
                                                  }
                                                />
                                              </div>
                                            </AccordionPanel>
                                            <div className="w-add-Guest-room Guest-management-job">
                                              <div className="w-add-room-guest rms-block-right">
                                                <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                                  Inventory
                                                </div>
                                                <div className="firstName-container wc-margin">
                                                  <Input
                                                    appearance="filled-darker"
                                                    placeholder="Inventory"
                                                    type="text"
                                                    name="UserDemographics"
                                                    onChange={
                                                      handleSpaWellnessInputChange
                                                    }
                                                    id={filledDarkerId}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                              Currency Used
                                            </div>
                                            <RadioGroup
                                              defaultValue="NGN"
                                              name="Currency"
                                              onChange={
                                                handleSpaWellnessCurrencyRadioChange
                                              }
                                              aria-labelledby="ageLabel"
                                              className="wc-flex"
                                            >
                                              <Radio value="NGN" label="NGN" />
                                              <Radio value="USD" label="USD" />
                                            </RadioGroup>
                                            <div className="w-add-Guest-room Guest-management-job">
                                              <div className="w-add-room-guest rms-block-right">
                                                <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                                  Access Control
                                                </div>
                                                <div className="firstName-container wc-margin">
                                                  <Input
                                                    appearance="filled-darker"
                                                    placeholder="Access control"
                                                    type="text"
                                                    name="AccessControl"
                                                    onChange={
                                                      handleSpaWellnessInputChange
                                                    }
                                                    id={filledDarkerId}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            {/* <div className="w-add-room-guest rms-inline-property-title wc-margin">Notes:</div>
                                                                                            <div className="w-add-Guest-room Guest-management-job">

                                                                                                <div className="meeting-subject_inputBoxWrapper_8x9syJ wc-input text-area Fitness-text-note">
                                                                                                    <div className="text-area">
                                                                                                        <div className="text-area">
                                                                                                            <textarea
                                                                                                                name="Notes"
                                                                                                                className="wc-ruene text-area"
                                                                                                                id="cv2-sf-meeting-subject-input"
                                                                                                                placeholder="Add Notes"
                                                                                                                onChange={handleFitnessTextAreaInputChange}
                                                                                                            />

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>


                                                                                            </div> */}
                                          </AccordionPanel>
                                        </AccordionItem>
                                      </Accordion>
                                    </div>
                                  </form>
                                )}
                                {isOption === 'Gift Shops' && (
                                  <form action="" className="Form-content">
                                    <div className="wform-MyContentControl-module__edgeworth-stack-item-container-refresh__3mUvv wform-css-283 ">
                                      <div className="ui-divider  ua-gi-ub-uc">
                                        <div className="wc-form-wrapper">
                                          <div className="w-add-Guest-room Guest-management-job">
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Product Name
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Product Name"
                                                  name="ProductName"
                                                  appearance="filled-darker"
                                                  id={filledDarkerId}
                                                  onChange={
                                                    handleShopsInputChange
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="w-add-room-guest rms-block-right">
                                              <div className="w-add-room-guest rms-inline-property-title">
                                                Product Description
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  appearance="filled-darker"
                                                  name="ProductDescription"
                                                  placeholder="Product Description"
                                                  onChange={
                                                    handleShopsInputChange
                                                  }
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>
                                          </div>

                                          <div className="w-add-Guest-room Guest-management-job">
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Regular Maintenance
                                              </div>
                                              <div className="firstName-container">
                                                <div className="firstName-container">
                                                  <Field>
                                                    <DatePicker
                                                      name="RegularMaintenance"
                                                      showWeekNumbers={true}
                                                      firstWeekOfYear={1}
                                                      showMonthPickerAsOverlay={
                                                        true
                                                      }
                                                      placeholder="Regular maintenance..."
                                                      className={styles.control}
                                                      onSelectDate={handleShopsDateInputChange(
                                                        'RegularMaintenance'
                                                      )}
                                                    />
                                                  </Field>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="w-add-room-guest rms-block-right">
                                              <div className="w-add-room-guest rms-inline-property-title">
                                                Environmental Practices
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  appearance="filled-darker"
                                                  type="text"
                                                  name="EnvironmentalPractices"
                                                  placeholder="Environmental Practices"
                                                  onChange={
                                                    handleShopsInputChange
                                                  }
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="w-add-Guest-room Guest-management-job">
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Vendor Performance
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  appearance="filled-darker"
                                                  type="text"
                                                  name="VendorPerformance"
                                                  placeholder="Vendor Performance"
                                                  onChange={
                                                    handleShopsInputChange
                                                  }
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>
                                            <div className="w-add-room-guest rms-block-right">
                                              <div className="w-add-room-guest rms-inline-property-title">
                                                Pick Up Delivery Record
                                              </div>
                                              <div className="firstName-container">
                                                <Field>
                                                  <DatePicker
                                                    name="PickUpDeliveryRecord"
                                                    showWeekNumbers={true}
                                                    firstWeekOfYear={1}
                                                    showMonthPickerAsOverlay={
                                                      true
                                                    }
                                                    placeholder="Pick Up Delivery Record..."
                                                    className={styles.control}
                                                    onSelectDate={handleShopsDateInputChange(
                                                      'PickUpDeliveryRecord'
                                                    )}
                                                  />
                                                </Field>
                                              </div>
                                            </div>
                                          </div>

                                          {/* <div className="w-add-Guest-room Guest-management-job">
                                                                                            <div className="w-add-room-guest rms-block-left">
                                                                                                <div className="w-add-room-guest Guest-inline-property-title">Cancellation Log</div>
                                                                                                <div className="firstName-container">
                                                                                                    <Field>
                                                                                                        <DatePicker
                                                                                                            name="CancellationLog"
                                                                                                            showWeekNumbers={true}
                                                                                                            firstWeekOfYear={1}
                                                                                                            showMonthPickerAsOverlay={true}
                                                                                                            placeholder="Cancellation Log..."
                                                                                                            className={styles.control}
                                                                                                            onSelectDate={handleSPaWwllnessDateInputChange("CancellationLog")}
                                                                                                        />
                                                                                                    </Field>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="w-add-room-guest rms-block-right">
                                                                                                <div className="w-add-room-guest rms-inline-property-title">Client Name</div>
                                                                                                <div className="firstName-container">
                                                                                                    <Input appearance="filled-darker"
                                                                                                        name="ClientName"
                                                                                                        onChange={handleSpaWellnessInputChange}
                                                                                                        placeholder='Client name'
                                                                                                        id={filledDarkerId} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div> */}
                                          <div className="w-add-Guest-room Guest-management-job">
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Quantity
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Quantity"
                                                  name="Quantity"
                                                  appearance="filled-darker"
                                                  onChange={
                                                    handleShopsInputChange
                                                  }
                                                  type="number"
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>

                                            <div className="w-add-room-guest rms-block-right wc-margin">
                                              <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                                Price
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Pricing"
                                                  type="number"
                                                  name="Price"
                                                  contentBefore={
                                                    <Text
                                                      size={400}
                                                      id={beforeLabelId}
                                                    >
                                                      {currencySymbol}
                                                    </Text>
                                                  }
                                                  contentAfter={
                                                    <Text
                                                      size={400}
                                                      id={afterLabelId}
                                                    >
                                                      .00
                                                    </Text>
                                                  }
                                                  appearance="filled-darker"
                                                  id={filledDarkerId}
                                                  onChange={
                                                    handleShopsInputChange
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="w-add-Guest-room Guest-management-job">
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Inventory Audit
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Inventory audit type"
                                                  name="InventoryAudit"
                                                  type="text"
                                                  appearance="filled-darker"
                                                  onChange={
                                                    handleShopsInputChange
                                                  }
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Inventory Adjustments
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Inventory Adjustments"
                                                  name="InventoryAdjustments"
                                                  appearance="filled-darker"
                                                  onChange={
                                                    handleShopsInputChange
                                                  }
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="w-add-Guest-room Guest-management-job">
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Product Categories
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Product Categories"
                                                  name="ProductCategories"
                                                  type="text"
                                                  appearance="filled-darker"
                                                  onChange={
                                                    handleShopsInputChange
                                                  }
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Inventory Tracking
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="InventoryTracking"
                                                  name="InventoryTracking"
                                                  type="text"
                                                  appearance="filled-darker"
                                                  onChange={
                                                    handleShopsInputChange
                                                  }
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="w-add-Guest-room Guest-management-job">
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Stock Keeping Unit
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Stock Keeping Unit Id"
                                                  name="Sku"
                                                  type="text"
                                                  appearance="filled-darker"
                                                  onChange={
                                                    handleShopsInputChange
                                                  }
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>
                                            <div className="w-add-room-guest rms-block-left">
                                              <div className="w-add-room-guest Guest-inline-property-title">
                                                Staff Name
                                              </div>
                                              <div className="firstName-container">
                                                <Input
                                                  placeholder="Stock Keeping Unit Id"
                                                  name="StaffName"
                                                  type="text"
                                                  appearance="filled-darker"
                                                  onChange={
                                                    handleShopsInputChange
                                                  }
                                                  id={filledDarkerId}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="wform-MyContentControl-module__edgeworth-stack-item-container-refresh__3mUvv wform-css-283-right">
                                      <Accordion defaultOpenItems="basicInfo">
                                        <AccordionItem value="basicInfo">
                                          <AccordionHeader as="h2">
                                            More information
                                          </AccordionHeader>
                                          <AccordionPanel>
                                            <Label className="wc-pmargin-b">
                                              Mode of payment
                                            </Label>
                                            <AccordionPanel>
                                              <div
                                                role="group"
                                                aria-labelledby="hobbiesLabel"
                                              >
                                                <Checkbox
                                                  label="Direct Credit"
                                                  name="Direct Credit"
                                                  onChange={
                                                    handleShopesCheckboxChange
                                                  }
                                                />
                                                <Checkbox
                                                  label="After Sells"
                                                  name="After Sells"
                                                  onChange={
                                                    handleShopesCheckboxChange
                                                  }
                                                />
                                                <Checkbox
                                                  label="Invoice"
                                                  name="Invoice"
                                                  onChange={
                                                    handleShopesCheckboxChange
                                                  }
                                                />
                                              </div>
                                            </AccordionPanel>
                                            <div className="w-add-Guest-room Guest-management-job">
                                              <div className="w-add-room-guest rms-block-right">
                                                <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                                  Sales Tax %
                                                </div>
                                                <div className="firstName-container wc-margin">
                                                  <Input
                                                    appearance="filled-darker"
                                                    placeholder="Sales tax i.e: 0.07"
                                                    type="number"
                                                    name="SalesTax"
                                                    onChange={
                                                      handleShopsInputChange
                                                    }
                                                    id={filledDarkerId}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                              Currency Used
                                            </div>
                                            <RadioGroup
                                              defaultValue="NGN"
                                              name="Currency"
                                              onChange={
                                                handleShopsCurrencyRadioChange
                                              }
                                              aria-labelledby="ageLabel"
                                              className="wc-flex"
                                            >
                                              <Radio value="NGN" label="NGN" />
                                              <Radio value="USD" label="USD" />
                                            </RadioGroup>
                                            <div className="w-add-Guest-room Guest-management-job">
                                              <div className="w-add-room-guest rms-block-right">
                                                <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                                  Quality Control
                                                </div>
                                                <div className="firstName-container wc-margin">
                                                  <Input
                                                    appearance="filled-darker"
                                                    placeholder="Quality control"
                                                    type="text"
                                                    name="QualityControl"
                                                    onChange={
                                                      handleShopsInputChange
                                                    }
                                                    id={filledDarkerId}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            {/* <div className="w-add-room-guest rms-inline-property-title wc-margin">Notes:</div>
                                                                                            <div className="w-add-Guest-room Guest-management-job">

                                                                                                <div className="meeting-subject_inputBoxWrapper_8x9syJ wc-input text-area Fitness-text-note">
                                                                                                    <div className="text-area">
                                                                                                        <div className="text-area">
                                                                                                            <textarea
                                                                                                                name="Notes"
                                                                                                                className="wc-ruene text-area"
                                                                                                                id="cv2-sf-meeting-subject-input"
                                                                                                                placeholder="Add Notes"
                                                                                                                onChange={handleFitnessTextAreaInputChange}
                                                                                                            />

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>


                                                                                            </div> */}
                                          </AccordionPanel>
                                        </AccordionItem>
                                      </Accordion>
                                    </div>
                                  </form>
                                )}
                              </>
                            </div>
                          </div>
                        </>
                      </div>
                    </div>
                    <div className="createList-pageFooter">
                      <div className="createList-leftActions">
                        <Button
                          data-automationid="createListGoBackButton"
                          className=""
                          icon={<ChevronLeft24Regular />}
                          onClick={toggleForm}
                        >
                          {' '}
                          Back
                        </Button>
                      </div>
                      <div className="createList-rightActions">
                        <Button
                          className="wc-first-root-68"
                          onClick={() => SubmitMultipleRequest(isOption)}
                        >
                          Register
                        </Button>
                        <Button className="" onClick={close}>
                          {' '}
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterProduct;
