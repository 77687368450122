// In Header.tsx
import React, { useCallback, useEffect, useRef, useState } from "react";
import "./Header.scss"
import "@flaticon/flaticon-uicons/css/all/all.css";
import Search from "../Search/Search";
import "../../Themes/Fonts/Icon/csc.scss";

import {
    Button,
    Menu,
    MenuTrigger,
    MenuList,
    MenuItem,
    MenuPopover,
    Tooltip,
    Switch,
    Avatar, 
  } from "@fluentui/react-components"
import axios from "axios";
import { WHITE_HOUSE_API, WHITE_HOUSE_S3 } from "../../../Constants/Constant";
import { IClockedIn, initializeIclock } from "../../../Data/General/ClockIn";
import { AlertBadge24Regular, Dismiss16Regular, DismissCircleRegular, PersonAccounts24Regular, Settings24Regular } from "@fluentui/react-icons";
import { Alert } from "@fluentui/react-components/unstable";
import Webcam from "react-webcam";
import { Employee } from "../../Authentication/ReturnedEmployee";
import { useAuth } from "../../Authentication/PrivateAuth";
import { useNavigate } from "react-router-dom";
import { GetEmployee } from "../../../Data/General/Employee";
import AI from "../../AI/AIUI";
import Typewriter from "../../AI/TypeWriter/Typewriter";
interface User {
  email: string,
  phoneNumber:string,
  token:string,

  // other properties...
}
type HeaderProps ={
    isTabActive?: boolean;
    onLeftIconClick?: () => void;
    onRightIconClick?: () => void;
    SiderbarShowOptionClick?: (optionName: string | undefined) => void;
    user: User;
};

const Header: React.FC<HeaderProps> = ({ isTabActive, onLeftIconClick, onRightIconClick, SiderbarShowOptionClick, user }) => {
    const [checked, setChecked] = React.useState(false);
    const [hasClockedIn, setHasClockedIn] = useState<boolean>(false);
    const [hasClockedOut, setHasClockedOut] = useState<boolean>(false);
    const [loggedInUser, setLoggedInUser] = useState<GetEmployee>();
    const [myAlerts, setMyAlerts] = useState<boolean>(false);
    const [clockingResponse, setClockingResponse] = useState("");
    const [clockmessg, setClockMessg] = useState("Clock Out");
    const [showAI, setShowAI] = useState<boolean>(false);
    const webcamRef = useRef(null);
    const [capturing, setCapturing] = useState(false);
    const [activeStates, setActiveStates] = useState([false, false, false, false, false, false, false, false, false]);
    const { signOut } = useAuth();
    const imageUrl = `${loggedInUser?.photos}`;
    const navigate = useNavigate();


    const handleStartCaptureClick = () => {
      setCapturing(true);
    };

    let clockmessage = "";
    const [clockedOuts, setClockedOut] = useState<IClockedIn>();
    const [clockedInsItem, setClockedInsItem] = useState<IClockedIn>(initializeIclock);
    const typewriterText = [
      'CAEL, your daily ai companion',
      'How do i communicate with cael',
      'What is the total number of room booked today?',
      'What is the total amount of revenue generated by rooms today?',
      'How many employees came to work today?',
      'How many rooms are out of service?',
      'What are your cancellation conditions?',
      'On a scale of 1 to 5, how would you rate the professionalism of our staff?'

    ];
    
    <Typewriter text={typewriterText} />
    
      const handleClick = (index: string | number, title: string | undefined) => {
        setActiveStates((prevActiveStates) =>
            prevActiveStates.map((state, i) => (i === index ? !state : false))
        );
        if (SiderbarShowOptionClick) {
            SiderbarShowOptionClick(title);
        }
    };
    const fetchClockedInStatus = useCallback(async () => {
      try {
        const response = await axios.get(
          `${WHITE_HOUSE_API}/ClockedInUsers/CheckStatus/${loggedInUser?.employeeId}`,
          {
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
          }
        );
        setHasClockedIn(response.data);
      } catch (error) {
        console.error('Error fetching clock-in status', error);
      }
    }, [loggedInUser?.employeeId, user.token]);
    
    const HandleClockIn = React.useCallback(async () => {
      try {
        const response = await axios.post(
          `${WHITE_HOUSE_API}/ClockedInUsers/ClockIn`,
          clockedInsItem,
          {
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
          }
        );
    
        if (response.data.status !== "Success") {
          console.log("Just clocked: ", response.data);
          setClockMessg("Clocked Out");
          setClockingResponse(response.data.eMessage);
          setMyAlerts(true);
          setTimeout(() => {
            setMyAlerts(false);
          }, 10000);
        }
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    }, [clockedInsItem, user.token]);
    
    const HandleClockOut = React.useCallback(async () => {
      try {
        let InitClock: IClockedIn = {
          UserId:  loggedInUser?.employeeId ? loggedInUser.employeeId : 0,
          FirstName: loggedInUser?.firstName,
          LastName: loggedInUser?.lastName,
          ClockOutTime: new Date().toLocaleTimeString(),
          ClockInTime:"",
          ClockedInDate: new Date(),
          OnLeave: false,
          Status: "Closed",
        };
        setClockedOut(InitClock);
        const response = await axios.post(
          `${WHITE_HOUSE_API}/ClockedInUsers/ClockOut`,
          clockedOuts,
          {
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
          }
        );
        console.log("Clocked In Response: ", response.data);
      
        if(response.data.status === "Success"){
          fetchClockedInStatus();
        };
        if (response.data.eMessage !== "Clocked Out Successful") {
          setClockMessg("Clocked Out");
          setClockingResponse(response.data.eMessage);
          setMyAlerts(true);
          setTimeout(() => {
            setMyAlerts(false);
          }, 10000);
        }
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    }, [clockedOuts, fetchClockedInStatus, loggedInUser?.employeeId, loggedInUser?.firstName, loggedInUser?.lastName, user.token]);
    
      const onChange = useCallback(
        (ev: any) => {
          if (ev.currentTarget.checked) {
            HandleClockIn();
            setHasClockedIn(ev.currentTarget.checked);
            setClockMessg("Clock Out");
          } else {
            HandleClockOut();
            setHasClockedIn(ev.currentTarget.checked);
            setClockMessg("Clock In");
          }
        },
        [HandleClockIn, HandleClockOut]
      );
      

      const fetchClockedOutStatus = useCallback(async () => {
        try {
          const response = await axios.get(
            `${WHITE_HOUSE_API}/ClockedInUsers/hasClockedOut?userId=${loggedInUser?.employeeId}`,
            {
              headers: {
                'Authorization': `Bearer ${user.token}`
              }
            }
          );
          setHasClockedOut(response.data);
        } catch (error) {
          console.error('Error fetching clock-out status', error);
        }
      }, [loggedInUser?.employeeId, user.token]);
      
  const lougoUserOut = async  () => {
 try {
   const users = {
    Email: user.email,
    PhoneNumber: user.phoneNumber,
    Token:user.token,
    
   }
    const response = await axios.post(`${WHITE_HOUSE_API}/Login/logout`, users, {
      headers: {
        'Authorization': `Bearer ${users.Token}`
      }
    });

    if (response.data.status === 'Success') {
      const clearUser = {
        email: response.data.Email,
        PhoneNumber:  response.data.PhoneNumber,
        status:  response.data.status,
      }
      await signOut(clearUser);
      navigate('/', { replace: true });
    } else {
      console.error('Logout failed');
    }
  } catch (error) {
    console.error('Error during logout', error);
  }
  }

      const fetchLoggedInUser = useCallback(async () => {
        try {
          const response = await axios.get(`${WHITE_HOUSE_API}/User/ActiveUser?phoneNumber=${user.phoneNumber}&email=${user.email}`, {
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
          });
          setLoggedInUser(response.data);
          console.error('Error fetching clock-out status', response.data);
        } catch (error) {
          console.error('Error fetching clock-out status', error);
        }
      }, [user.email, user.phoneNumber, user.token]);

      useEffect(() => {
        fetchLoggedInUser();
        fetchClockedInStatus();
        fetchClockedOutStatus();
       setClockedInsItem({
          UserId: loggedInUser?.employeeId,
          FirstName: loggedInUser?.firstName, 
          LastName: loggedInUser?.lastName, 
          ClockInTime: "", 
          ClockOutTime: "", 
          ClockedInDate: new Date(), 
          OnLeave: false,
          Status: "Working", 
          TypeofLeave: "", 
          Note: "",
        });
      }, [fetchClockedInStatus, fetchClockedOutStatus, fetchLoggedInUser, hasClockedIn, hasClockedOut, loggedInUser?.employeeId, loggedInUser?.firstName, loggedInUser?.lastName]);
    
 const closeAlert = () => {
  setMyAlerts(false);
 }

 const handleAIShow = () => {
  setShowAI(true);
 }

 const handleAIClose = () => {
  setShowAI(false);
 }
 let fullname = loggedInUser?.firstName + " " + loggedInUser?.lastName;
    return (
      <>
        <div id="w-ci-header" className="w-ci-header-element Wc-Light-weight-Fo">
        {myAlerts && (
          <div className="wc-position-absolute">
           <Alert intent="error"
              action={{
                icon: <DismissCircleRegular  onClick={closeAlert}/>,
            
              }}>
            {clockingResponse}
         </Alert>

         </div>
        )}

            <div className="w-ci-header-menu">
                <i className={`ci-font fi fi-ts-angle-small-left ${isTabActive ? 'w-ci-active-icon' : ''}`} onClick={onLeftIconClick}></i>
                <i className="ci-font fi fi-ts-angle-small-right" onClick={onRightIconClick}></i>
            </div>
            <div className="w-ci-header--left w-logo wc-ci-text-color-white">WHITEHOUSE</div>
            <div className="w-ci-header--right">
                <div className="w-ci-search-outer">
                <div className="w-pristine w-valid w-valid-maxlength">
                <div id="controlbox-input-group" className="w-ci-Input-group" onClick={handleAIShow}>
                    <i className="w-ci-button-group fi fi-rr-search"></i>
                    <Typewriter text={typewriterText}/>
                </div>
            </div>
                </div>
                <div className="w-ci-profile w-ci-settings">
                <div className="w-ci-stream wc-ci-text-color-white">
                <Switch checked={hasClockedIn}  onChange={onChange}
                 label={checked ? "Clock In" : clockmessg}
                />
                
                </div>
                    <div className="w-ci-stream cursur-control wc-ci-text-color-white">
                        <Tooltip                     
                            withArrow
                            content="Live stream event"
                            relationship="label">
                             <i className="fi fi-tr-video-camera-alt" onClick={()=> handleClick(9, 'video')}></i>          
                        </Tooltip>
                    </div>
                    <div className="w-ci-options cursur-control wc-ci-text-color-white">
                    <Menu>
                        <MenuTrigger disableButtonEnhancement>
                        <Tooltip
                            withArrow
                            content="Internal Meeting and more"
                            relationship="label">
                            <i className="fi fi-rr-menu-dots"></i>
                        </Tooltip>
                        </MenuTrigger>

                        <MenuPopover>
                        <MenuList>
                            <MenuItem icon={<Settings24Regular />}>Account Settings</MenuItem>
                            <MenuItem icon={<AlertBadge24Regular />}>Request for a Leave</MenuItem>
                            <MenuItem disabled>Open File</MenuItem>
                            <MenuItem>Open Folder</MenuItem>
                            <MenuItem icon={<PersonAccounts24Regular />} onClick={lougoUserOut}>Log Out</MenuItem>
                        </MenuList>
                        </MenuPopover>
                    </Menu>
                        
                    </div>
                    <div className="w-ci-userprofile cursur-control wc-ci-text-color-white">
                        {/* <i className="ci-h fi fi-tr-circle-user"></i> */}
                        <Tooltip withArrow content={fullname} relationship="label">
                            <Avatar  color="colorful" name={fullname} className="w-ci-mectrl_topHeader"
                            badge={{ status: "available" }}
                            image={{ src: imageUrl}}
                          />
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
        {showAI && (
        <div className="fluent-default-layer-host">
          <div className="ms-Layer ms-Layer--fixed root-44">
             <AI close={handleAIClose} user={user} />
          </div>
        </div>
        )}
        
       
        </>
    );
}

export default Header;


