import React, {
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Checkbox,
  Dropdown,
  Input,
  useId,
  Label,
  Radio,
  RadioGroup,
  Select,
  Option,
  Persona,
  Combobox,
  PresenceBadgeStatus,
  Field,
  tokens,
  shorthands,
  makeStyles,
  Text,
  Avatar,
  AvatarGroup,
  AvatarGroupItem,
  TabList,
  Button,
  Tab,
  Divider,
  ProgressBar,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableCellLayout,
  Switch,
} from '@fluentui/react-components';
import {
  AccessibilityCheckmark24Filled,
  Checkbox124Filled,
  ChevronLeft24Regular,
  Mail24Filled,
  Payment24Filled,
  PersonAdd24Regular,
  PersonClock24Filled,
  ChevronDown24Regular,
  PeopleCommunity24Filled,
  Check24Filled,
  PeopleTeamFilled,
  PeopleCommunityRegular,
} from '@fluentui/react-icons';
import { RoomDataListItem } from '../../../Data/General/RoomDetail';
import GuestForm from '../Book/BookingForm/GuestForm';
import { SearchBox } from '@fluentui/react-search-preview';
import type { SearchBoxProps } from '@fluentui/react-search-preview';
import axios from 'axios';
import { WHITE_HOUSE_API } from '../../../Constants/Constant';
import { IRoom, Room } from '../../../Data/General/Room';
import { PersonaSize, initializeIcons } from '@fluentui/react';
import './ViewStatus.scss';
import Loaders from '../../Common/Loader/Loader';
import CheckInForms from '../Book/Checkin/CheckInForm';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import {
  ICheckinGuest,
  InitializeCheckingGuest,
} from '../../../Data/General/CheckinGuest';
import { InfoLabel, InfoLabelProps } from '@fluentui/react-components/unstable';
import { IRType } from '../../../Data/General/ReservationType';
import Payment from '../Payment/Payment';
import RSPayment from '../Payment/Paymentrs/RSPayment';
import {
  TableHeader,
  TableHeaderCell,
  useArrowNavigationGroup,
} from '@fluentui/react-components';
import { IGuestCheckedIn } from '../../../Data/General/Guest';
import { IQuery } from '../../../Data/General/IQuery';
import { ViewEvents } from '../../../Data/General/ViewEvent';
import { IGuestChecked } from '../../../Data/General/CheckedInDays';

const useStyles = makeStyles({
  main: {
    ...shorthands.gap('16px'),
    display: 'flex',
    flexWrap: 'wrap',
  },

  card: {
    width: '400px',
    maxWidth: '100%',
    minHeight: '250px',
    height: 'fit-content',
  },

  caption: {
    color: tokens.colorNeutralForeground3,
  },

  smallRadius: {
    ...shorthands.borderRadius(tokens.borderRadiusSmall),
  },

  grayBackground: {
    backgroundColor: tokens.colorNeutralBackground3,
  },

  control: {
    maxWidth: '300px',
  },
  wrapper: {
    columnGap: '15px',
    display: 'flex',
    marginBottom: '10px',
  },
  container: {
    ...shorthands.margin('20px', '0px'),
  },
});

interface User {
  email: string;
  phoneNumber: string;
  token: string;

  // other properties...
}

type ViewProps = {
  onClose: () => void;
  selectedDate?: Date | null;
  user: User;
};

const ViewStatus: React.FC<ViewProps> = ({ onClose, selectedDate, user }) => {
  const [showForm, setShowForm] = useState(false);
  const [showDetails, setshowDetails] = useState(true);
  const ActionText = showForm ? 'Submit' : 'Book Now';
  const [query, setQuery] = useState('');
  const [isSearchActive, setisSearchActive] = useState(false);
  const [selectedTab, setSelectedTab] = useState<any>('');
  const [numberofNight, setNumberofNight] = useState<any | null>('');
  const [roomRates, setRoomRate] = useState<any | null>('');
  const [arrivalDate, setArrivalDate] = useState<Date | null>(null);
  const [departureDate, setDepartureDate] = useState<Date | null>(null);

  const [userSelectedRoomNumber, setUserSelectedRoomNumber] = useState<any>();
  const [userSelectedRoomName, setUserSelectedRoomName] = useState<any>();
  const prevTabRef = useRef<any>();
  const [input, setInput] = useState('');
  const [results, setResults] = useState<IQuery[]>([]);
  const [guest, getGuest] = useState<IGuestChecked[]>([]);
  const [roomResults, setRoomResults] = useState<IRoom[]>([]);
  const [reservationType, setReservationType] = useState<IRType[]>([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<number>();
  const [selectedTypeId, setSelectedTypeId] = useState<number>();
  const [extractShiftId, setExtractedShiftId] = useState<number>();
  const [ischecking, setIsChecking] = useState('Checking Out...');

  const [filteredData, setFilteredData] = useState(guest);

  const [checkingInput, setCheckingInput] = useState<ICheckinGuest>(
    InitializeCheckingGuest
  );
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [isDisabled, setisDisabled] = useState<boolean>(true);
  const [isCheckedOutItems, setIsCheckedOutItems] = useState<boolean>(false);
  const [roomAmmount, setRoomAmmount] = useState<any>('');
  const [hideIsCheckOut, setHideIsCheckOut] = useState<boolean>(true);
  const [currency, setCurrency] = useState('NGN');
  const currencySymbol = currency === 'NGN' ? '₦' : '$';
  const styles = useStyles();
  initializeIcons();
  const selectId = useId();
  const [progressValue, setProgressValue] = React.useState(0.16);

  const [expandedRows, setExpandedRows] = useState<Array<boolean>>([]);

  const handleRowClick = (index: number) => {
    setExpandedRows((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const intervalDelay = 100;
  const intervalIncrement = 1;

  const handleBlur = (event: any) => {
    const selectedName = event.target.value;
    const selectedEmployee = results.find(
      (result) => `${result.firstName} ${result.lastName}` === selectedName
    );

    if (selectedEmployee) {
      setSelectedEmployeeId(selectedEmployee.roomNumber);
    }
  };

  const handleBlurRST = (event: any) => {
    const selectedName = event.target.value;
    const selectedTypes = reservationType.find(
      (reservationType) => `${reservationType.typeName}` === selectedName
    );

    if (selectedTypes) {
      setSelectedTypeId(selectedTypes.reservationTypeId);
    }
  };

  const fetchAutocompleteResults = async (inputs: any) => {
    if (inputs.length < 0) {
      return; // don't call the API if the input is less than 2 characters
    }
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Rooms/search/${inputs}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setRoomResults(response.data);
    } catch (error) {
      console.error('Error fetching autocomplete results:', error);
    }
  };

  const fetchheckedInDay = useCallback(
    async (date: any) => {
      try {
        const response = await axios.get(
          `${WHITE_HOUSE_API}/CheckedInOrBooked/available/${date}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        getGuest(response.data);
      } catch (error) {
        console.error('Error fetching autocomplete results:', error);
      }
    },
    [user.token]
  ); // add dependencies if any

  const fetchAutocompleteRSTs = async (inputs: any) => {
    if (inputs.length < 0) {
      return; // don't call the API if the input is less than 2 characters
    }
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/ReservationType/search/${inputs}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setReservationType(response.data);
      console.error('Reservation type', reservationType);
    } catch (error) {
      console.error('Error fetching autocomplete results:', error);
    }
  };

  const handleInputChangeName = (event: any) => {
    fetchAutocompleteResults(event.target.value);
  };

  const handleReservationChange = (event: any) => {
    fetchAutocompleteRSTs(event.target.value);
  };

  //   const id = setInterval(() => {
  //     setProgressValue(progressValue < 42 ? intervalIncrement + progressValue : 0);
  //   }, intervalDelay);
  //   return () => {
  //     clearInterval(id);
  //   };

  const handleEvent = (tabValue: string, progressValue: number) => {
    setSelectedTab(tabValue);
    setProgressValue(progressValue);
    console.log('the details', checkingInput);
    if (
      tabValue === 'Preview information' &&
      checkingInput.firstName !== '' &&
      checkingInput.lastName !== ''
    ) {
      setisDisabled(false);
    } else {
      setisDisabled(true);
    }
  };

  const search = async (query: any) => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Query/guest?query=${query}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          params: { query },
        }
      );
      setResults(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseForme = () => {
    onClose();
  };

  const goToPrevTab = () => {
    if (prevTabRef.current) {
      setSelectedTab(prevTabRef.current);
    }
  };

  function formatDate(dateString: Date): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero indexed, so +1 and pad with 0
    const day = ('0' + date.getDate()).slice(-2); // Pad with 0
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    if (selectedDate) {
      console.log('The Current Date', filteredData);
      let date = formatDate(selectedDate);
      fetchheckedInDay(date);
    }
  }, [fetchheckedInDay, guest, selectedDate]);

  const handleCurrencyChange = (event: any) => {
    const option = event.target.value;
    setCurrency(option);
  };

  const handleTabClick = (tabName: any) => {
    setIsChecking(tabName);
    const isCheckoutTab = tabName === 'Checked Out';
    setHideIsCheckOut(!isCheckoutTab);
    setIsCheckedOutItems(isCheckoutTab);
    if (isCheckoutTab === true) {
      setProgressValue(10);
    } else {
      setProgressValue(0);
    }
    // Add any additional actions here
  };

  const handleItemClick = async (guestId: any) => {
    try {
      setIsLoader(true);
      const response = await axios.get(`${WHITE_HOUSE_API}/Guest/${guestId}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        params: { query },
      });
      if (response.data) {
        getGuest(response.data);
        setTimeout(() => {
          setIsLoader(false);
        }, 5000);
      }
      console.log('Guest: ', guest);
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (event: any) => {
    const query = event.target.value;
    console.log('Search Terms', query);
    const filteredItems = guest.filter((item) => {
      return item.fullName.toLowerCase().includes(query.toLowerCase());
    });

    setFilteredData(filteredItems);
  };

  return (
    <>
      <div className="ms-Fabric ms-Layer-content content-46">
        <div className="wc-no outline">
          <div className="ms-Modal is-open root-39">
            <div
              aria-hidden="true"
              className="ms-Overlay ms-Overlay--dark root-48"
            ></div>
            {isLoader && (
              <>
                <Loaders />
              </>
            )}
            <div className="ms-Dialog-main eEiGx Form-main-652">
              <div aria-hidden="true" data-is-visible="true"></div>
              <div className="ms-Modal-scrollableContent scrollableContent-528 customScrollBar">
                <div className="ogLns">
                  <div className="HfSye View-gehbr" role="tablist">
                    <span className="cCOIa">
                      <div className="templateTitleWithLogo-max">
                        <Avatar
                          icon={<PeopleCommunityRegular />}
                          aria-label="Group"
                        />
                        <span className="createList-headerTitle wc-margin">
                          Manage Services
                        </span>
                      </div>
                    </span>
                    <div className="ms-FocusZone css-171">
                      <div className="MY8rO Popup-content-636">
                        <TabList defaultSelectedValue="tab1" vertical>
                          <Tab value="tab1">
                            <Button
                              className="rvBQq-Tab Make_jeb"
                              icon={<PeopleTeamFilled />}
                            >
                              {`Available ${new Date(
                                selectedDate ?? new Date()
                              ).toLocaleString('en-US', {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                              })}`}
                            </Button>
                          </Tab>
                        </TabList>
                      </div>
                    </div>
                  </div>
                  <div className="pA2AO css-656 View-gehbr">
                    <div className="OjwNa">
                      <div className="aHxfM">{selectedTab}</div>
                      <div
                        className={`ui-box Wsearch-ev-ew  wc-check-search c-search_modal__wrapper popover-little ${
                          isSearchActive ? 'mouse-over-active' : ''
                        }`}
                      >
                        <SearchBox
                          appearance="filled-lighter"
                          placeholder="filter..."
                          onChange={handleInputChange}
                          onBlur={search}
                          className="sear-padd"
                        />
                        {/* <div
                            className={`c-search_autocomplete__container c-search_autocomplete__container--query-ordered ${
                              isSearchActive ? 'serachbar-active' : ''
                            }`}
                          >
                            <div className="c-search_autocomplete c-search_autocomplete--nav-attached">
                              <div className="offscreen">
                                <span id="search-autocomplete-query-label-prefix-node">
                                  Search
                                </span>
                              </div>
                              {results.map((gu) => {
                               let fullname = gu.firstName + " " + gu.lastName;
                                return (
                                  <div
                                    key={gu.guestId}
                                    className="c-search_autocomplete__suggestion_list c-search_autocomplete__suggestion_list--taller"
                                    onClick={() => handleItemClick(gu.guestId)}
                                  >
                                    <div className="curtain-a wform-css-283-right wc-ciHHEB">
                                      <div className="fui-Persona rlroi9i">
                                        <span
                                          role="img"
                                          id="avatar-rd"
                                          className="fui-Avatar r81b29z fui-Persona__avatar ___1c989qy_1o9k6tz f13ar0e0 fid048z f1hek2iy f9h729m f18q9vkd f103ycu4"
                                          aria-label="Kevin Sturgis"
                                          aria-labelledby="avatar-rd avatar-rd__badge"
                                        >
                                          <AvatarGroup>
                                            <AvatarGroupItem name={fullname} />
                                          </AvatarGroup>
                                        </span>
                                        <span className="fui-Persona__primaryText rvj41k9">
                                        {fullname}                                      
                                        </span>
                                        <span className="fui-Persona__secondaryText rp1pf9e ___19lp2a0_1p5a1yj f1ryq6si">
                                        {gu.email}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div> */}
                      </div>
                      {/* <div className="WC-progress-bar-success">
                          <Field validationState="none">
                            <ProgressBar
                              className={styles.container}
                              shape="rounded"
                              thickness="large"
                              value={progressValue}
                            />
                          </Field>
                        </div> */}
                      <div className="UKZid">
                        <div className="createList-closeBtnContainer">
                          <Button
                            icon={<i className="fi fi-sr-cross-small" />}
                            onClick={handleCloseForme}
                          ></Button>
                        </div>
                      </div>
                    </div>
                    <div className="nOZxj customScrollBar">
                      {/* <CheckInForms /> */}
                      <>
                        <div className="createList-pageContent includeBottomPadding  flex-content-file customScrollBar">
                          <div className="previewContainer  wc-add-paddin">
                            <form action="" className="Form-content">
                              <div className="wform-MyContentControl-module__edgeworth-stack-item-container-refresh__3mUvv wform-css-283 ">
                                <div className="ui-divider  ua-gi-ub-uc">
                                  <Accordion defaultOpenItems="GuestInfo">
                                    <div className="checkout">
                                      <Table
                                        role="grid"
                                        aria-label="Table with grid keyboard navigation"
                                      >
                                        <TableHeader>
                                          <TableRow className="summary-table">
                                            <TableHeaderCell className="table-header col-first">
                                              FULL Name
                                            </TableHeaderCell>
                                            <TableHeaderCell className="table-header">
                                              Start Date
                                            </TableHeaderCell>
                                            <TableHeaderCell className="table-header">
                                              End Date
                                            </TableHeaderCell>
                                            <TableHeaderCell className="table-header">
                                              Status
                                            </TableHeaderCell>
                                            <TableHeaderCell className="table-header">
                                              Room Name/Number
                                            </TableHeaderCell>
                                            <TableHeaderCell className="table-header col-last">
                                              Action
                                            </TableHeaderCell>
                                          </TableRow>
                                        </TableHeader>
                                        <TableBody>
                                          {guest.map((item, index) => (
                                            <>
                                              <TableRow
                                                onClick={() =>
                                                  handleRowClick(index)
                                                }
                                              >
                                                <TableCell>
                                                  <TableCellLayout
                                                    media={
                                                      <Avatar
                                                        aria-label={`${item.fullName}`}
                                                        name={`${item.fullName}`}
                                                        badge={{
                                                          status: 'available',
                                                        }}
                                                      />
                                                    }
                                                  >
                                                    {`${item.fullName}`}
                                                  </TableCellLayout>
                                                </TableCell>

                                                <TableCell>
                                                  {item.startDate
                                                    ? new Date(
                                                        item.startDate
                                                      ).toLocaleDateString(
                                                        'en-US',
                                                        {
                                                          year: 'numeric',
                                                          month: 'long',
                                                          day: '2-digit',
                                                        }
                                                      )
                                                    : ''}
                                                </TableCell>
                                                <TableCell>
                                                  {item.endDate
                                                    ? new Date(
                                                        item.endDate
                                                      ).toLocaleDateString(
                                                        'en-US',
                                                        {
                                                          year: 'numeric',
                                                          month: 'long',
                                                          day: '2-digit',
                                                        }
                                                      )
                                                    : ''}
                                                </TableCell>
                                                <TableCell>
                                                  <Button
                                                    size="small"
                                                    shape="circular"
                                                  >
                                                    {item.status}
                                                  </Button>
                                                </TableCell>
                                                <TableCell>{`${item.roomName} /(${item.roomNumber}) `}</TableCell>
                                                <TableCell>
                                                  <Switch />
                                                </TableCell>
                                              </TableRow>
                                            </>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </div>
                                  </Accordion>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </>
                    </div>

                    <>
                      <div className="footer-form-content ">
                        <Divider />
                        <div className="createList-pageFooter WC-form-controle">
                          <div className="createList-leftActions">
                            <Button
                              data-automationid="createListGoBackButton"
                              className=""
                              icon={<ChevronLeft24Regular />}
                              onClick={handleCloseForme}
                            >
                              Back
                            </Button>
                          </div>
                          <div className="createList-rightActions margin-Rig HENG_Y36H">
                            {/* <Button
                                disabled={isDisabled}
                                className={`wc-first-root-68 ${
                                  isDisabled ? 'wc-firs-disabled' : ''
                                }`}
                                onClick={SubmitCheckinAction}
                              >
                                {ischecking}
                              </Button> */}
                            <Button className="" onClick={handleCloseForme}>
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewStatus;
