import * as React from "react";
import {
    Button,
    Menu,
    MenuTrigger,
    MenuList,
    MenuItem,
    MenuPopover,
} from "@fluentui/react-components";
import {
    bundleIcon,
    ClipboardPasteRegular,
    ClipboardPasteFilled,
    CutRegular,
    CutFilled,
    CopyRegular,
    CopyFilled,
    MoreHorizontal24Regular,
    DocumentEdit24Regular,
    AlignSpaceEvenlyHorizontal24Regular,
    AlbumAddFilled,
    ApprovalsAppRegular,
    DrinkWine24Filled,
    Food24Filled,
    DrinkWineFilled,
    FoodFilled
} from "@fluentui/react-icons";
import type { MenuProps } from "@fluentui/react-components";

const PasteIcon = bundleIcon(ClipboardPasteFilled, ClipboardPasteRegular);
const CopyIcon = bundleIcon(CopyFilled, CopyRegular);
const CutIcon = bundleIcon(CutFilled, CutRegular);

interface InteractionProps extends Partial<MenuProps> { 
    name?: string;
    onInteractionClick?: (name:any) => void;
}

export const BarInteraction: React.FC<InteractionProps> = ({ onInteractionClick, ...props }) => {
    
    const handleClick = (name:any) => {
        if(name === 'Bar' && onInteractionClick) {
            onInteractionClick(name);
        } else if(name === 'Reastaurant' && onInteractionClick) {
            onInteractionClick(name);
        }
      };
    return (
        <Menu {...props}>
            <MenuTrigger disableButtonEnhancement>
                <Button icon={<MoreHorizontal24Regular className="color-item" />}></Button>
            </MenuTrigger>

            <MenuPopover>
                <MenuList>
                    <MenuItem
                        icon={<DrinkWineFilled />}
                        onClick={() => handleClick("Bar")}
                    >
                        Bar Request
                    </MenuItem>
                   
                    <MenuItem
                        icon={<FoodFilled />}
                        onClick={() => handleClick("Reastaurant")}
                    >
                        Restuarant Request
                    </MenuItem>
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};
