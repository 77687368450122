import React, { useCallback, useEffect, useRef, useState } from 'react';
import '../Landing/Landing.scss';
import {
  bundleIcon,
  CalendarMonthFilled,
  CalendarMonthRegular,
  StarEdit24Regular,
  PeopleTeam24Regular,
  ChevronRight24Regular,
  AccessibilityCheckmark24Filled,
  PeopleCommunityAdd24Filled,
  List20Regular,
  PeopleCommunity24Filled,
  PeopleTeam24Filled,
  PersonClock24Filled,
  PersonWalking24Filled,
  ChevronLeft24Regular,
  Share24Regular,
  CatchUp24Regular,
  Home24Filled,
  HomePerson24Filled,
  ArrowTrendingLines24Regular,
  ArrowTrendingSparkle24Regular,
  ArrowTrending24Regular,
  ArrowTrendingLines24Filled,
  StarThreeQuarter24Regular,
  ArrowTrendingDownRegular,
  ArrowTrendingRegular,
  ArrowTrendingDown24Regular,
} from '@fluentui/react-icons';
import {
  InteractionTag,
  InteractionTagPrimary,
  InteractionTagSecondary,
} from '@fluentui/react-tags-preview';

import {
  Button,
  Divider,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Persona,
  Tooltip,
  Label,
  TabList,
  Tab,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
} from '@fluentui/react-components';
import {
  Dropdown,
  makeStyles,
  Option,
  shorthands,
  tokens,
  useId,
} from '@fluentui/react-components';
import ImageOutgoing from '../../Themes/Images/Stock_down_orange_72x72.png';
import ImageIncoming from '../../Themes/Images/Finance_stock_up_green_72x72.png';
import Carousel from '../../Common/Carousel/Sliders';
import Donuts from '../../Common/Chart/DonutChart/DonutGuest';
import DonutsReservation from '../../Common/Chart/DonutChart/DonutReservation';
import RevenueChart from '../../Common/Chart/Revenue/Revebue';
import BarAndMeal from '../../Common/Chart/Revenue/BarAndMeal';
import './Finance.scss';
import SingleProductChart from './ProductCharts/SingleProductChartLine';
import LineChart from './ProductCharts/LineChart';
import ProductChart from './ProductCharts/SingleProductChartLine';
import GroupProductData from './ProductCharts/ChartForgroupdata';
import { RoomChatData } from '../../../Constants/ChartData';
import RegisterCompany from '../Vendors/RegisterVendors';
import RegisterProduct from '../Vendors/RegisterProduct';
import Loaders from '../../Common/Loader/Loader';
import Accounting from '../Accounting/Accounting';
import BusinessTypes from './BusinessTypeModel/BusinessTypes';
import { WHITE_HOUSE_API } from '../../../Constants/Constant';
import axios from 'axios';
import { IHotelProduct } from '../../../Data/General/IHotelProduct';
import { manageQuote } from '../../../Data/General/Quote';

interface User {
  email: string;
  phoneNumber: string;
  token: string;

  // other properties...
}
interface Props {
  message: string;
  user: User;
}

const Finance: React.FC<Props> = ({ user }) => {
  const containerRef = useRef<HTMLUListElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [dates, setDates] = useState<Date[]>([]);
  const [isNotAccounting, setIsNotAccounting] = useState('Finance');
  const [isOccupancy, setIsOccupancy] = useState<boolean>(true);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [closeVendor, setCloseVendor] = useState<boolean>(false);
  const [closeProductWindow, setCloseProductWindow] = useState<boolean>(false);
  const [busninessModel, setBusinessModel] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState('Financials');
  const [isBusinestypeOpen, setisBusinestypeOpen] = useState<boolean>(false);
  const [getHotelProducts, setGetHotelProducts] = useState<manageQuote[]>([]);
  const [roomQuote, setRoomQuote] = useState<manageQuote[]>([]);
  const [selectedValue, setSelectedValue] = useState<string>(
    'Performance high to low'
  );

  const handleSELECTEChange = (newValue: string) => {
    setSelectedValue(newValue);
  };

  const OpenBusinessTypes = () => {
    setisBusinestypeOpen(true);
  };

  const fetchHotelProduct = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/HotelProducts`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setGetHotelProducts(response.data);
      console.log('Bar data: ', response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);

  const fetchHotelRoomQuote = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/Quote/RoomQuote`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setRoomQuote(response.data);
      console.log('Bar data: ', response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);

  const closeBusinessTypes = () => {
    setisBusinestypeOpen(false);
  };

  const handleOccupancyClick = () => {
    setIsOccupancy(true);
  };

  const handleBarAndMealClick = () => {
    setIsOccupancy(false);
  };

  useEffect(() => {
    const today = new Date();
    const dates = Array.from({ length: 10 }, (_, i) => {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      return date;
    });
    fetchHotelRoomQuote();
    setDates(dates);
    fetchHotelProduct();
    console.log('Right Product Price', getHotelProducts);
  }, [fetchHotelProduct, fetchHotelRoomQuote, getHotelProducts]);
  useEffect(() => {
    const container = document.querySelector(
      '.sparklineContainer-DS-EntryPoint1-2'
    );
    if (container) {
      setDimensions({
        width: container.clientWidth,
        height: container.clientHeight,
      });
    }
  }, []);
  function isSameDay(date1: Date, date2: Date) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  const handleScroll = (direction: any) => {
    if (containerRef.current) {
      const scrollAmount = direction === 'left' ? -100 : 100;
      containerRef.current.style.transform = `translateX(${
        scrollPosition + scrollAmount
      }px)`;
      setScrollPosition(scrollPosition + scrollAmount);
    }
  };

  const items = [
    <div className="quote-item">
      <div className="left">
        <div className="symb">
          New Reservation{' '}
          <img className="notf-icon" src={ImageOutgoing} alt="" />
        </div>
        <div className="left-bottom">
          <div className="notf-text">Deluxy Flat (1)</div>
        </div>
      </div>
      <div className="right">
        <div className="prct-change">Thur 5, 2023 1:18 min</div>
        <div className="price">Amount paid: $112.95</div>
      </div>
    </div>,
    <div className="quote-item">
      <div className="left">
        <div className="symb">
          Checking Out <img className="notf-icon" src={ImageIncoming} alt="" />
        </div>
        <div className="left-bottom">
          <div className="notf-text">Deluxy Flat (4)</div>
        </div>
      </div>
      <div className="right">
        <div className="prct-change">Fri 6, 2023 12:18 min</div>
        <div className="price">
          Amount paid: <span className="checking-out-price">-$112.95</span>
        </div>
      </div>
    </div>,
    <div className="quote-item">
      <div className="left">
        <div className="symb">
          New Reservation{' '}
          <img className="notf-icon" src={ImageOutgoing} alt="" />
        </div>
        <div className="left-bottom">
          <div className="notf-text">Deluxy Flat (1)</div>
        </div>
      </div>
      <div className="right">
        <div className="prct-change">Thur 5, 2023 1:18 min</div>
        <div className="price">Amount paid: $ 112.95</div>
      </div>
    </div>,

    <div className="quote-item">
      <div className="left">
        <div className="symb">
          Checking Out <img className="notf-icon" src={ImageIncoming} alt="" />
        </div>
        <div className="left-bottom">
          <div className="notf-text">Deluxy Flat (4)</div>
        </div>
      </div>
      <div className="right">
        <div className="prct-change">Fri 6, 2023 12:18 min</div>
        <div className="price">
          Amount paid: <span className="checking-out-price">-$ 112.95</span>
        </div>
      </div>
    </div>,
  ];

  const registervendowWindow = () => {
    setCloseVendor(true);
  };
  const CloseVendowWindow = () => {
    setCloseVendor(false);
  };
  const NewProductWindow = () => {
    setCloseProductWindow(true);
  };

  const closeNewProductWindow = () => {
    setCloseProductWindow(false);
  };
  const toggleFinancials = (componentName: any) => {
    setIsNotAccounting((currentComponent) =>
      currentComponent === componentName ? componentName : componentName
    );
    setSelectedItem(componentName);
  };

  return (
    <>
      <div className="navAndContent_feed LSX89j">
        <div className="ts-left-rail-wrapper ts-main-flex app-full-viewport-height pull-left left-rail-z-index MHR99">
          <div className="ts-left-rail app-rail-common left-rail-box-shadow-overlay Over_67 left-rail-header-v2-common">
            <div className="left-rail-header-v2">
              <div className="active-rail">
                <div className="show-rail">
                  <div className="left-rail-header-chat">
                    <div className="left-rail-header constant-border MSY67">
                      <Menu>
                        <MenuTrigger disableButtonEnhancement>
                          <MenuButton appearance="transparent">
                            {selectedItem}
                          </MenuButton>
                        </MenuTrigger>

                        <MenuPopover>
                          <MenuList>
                            <MenuItem
                              onClick={() => toggleFinancials('Accounting')}
                            >
                              Accounting
                            </MenuItem>
                            <MenuItem
                              onClick={() => toggleFinancials('Auditing')}
                            >
                              Auditing
                            </MenuItem>
                            <MenuItem
                              onClick={() => toggleFinancials('Finance')}
                            >
                              Financials
                            </MenuItem>
                          </MenuList>
                        </MenuPopover>
                      </Menu>
                      <Menu>
                        <MenuTrigger disableButtonEnhancement>
                          <MenuButton
                            icon={<List20Regular />}
                            appearance="transparent"
                          ></MenuButton>
                        </MenuTrigger>

                        <MenuPopover>
                          <MenuList>
                            <MenuItem onClick={registervendowWindow}>
                              Register New Vendors
                            </MenuItem>
                            <MenuItem onClick={NewProductWindow}>
                              Register New Product
                            </MenuItem>
                            <MenuItem onClick={OpenBusinessTypes}>
                              Create Business Type
                            </MenuItem>
                          </MenuList>
                        </MenuPopover>
                      </Menu>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-channel_sidebar p-channel_sidebar--classic_nav p-channel_sidebar--iap1 p-channel_sidebar--dimming p-channel_sidebar--contain-Finance customScrollBar p-Finance-m">
              <div className="p-channel_sidebar__list">
                <div className="summaryView-DS-EntryPoint1-1 lightTheme">
                  <div className="selctedTab-DS-EntryPoint1-1"></div>
                  <div className="summaryContent-DS-EntryPoint1-1">
                    <div className="summaryTitle-DS-EntryPoint1-1 lightTheme">
                      <Button
                        className=""
                        icon={<ArrowTrendingSparkle24Regular />}
                      >
                        {' '}
                      </Button>
                      <span>SUMMARY</span>
                    </div>
                    <div className="summaryValue-DS-EntryPoint1-1">
                      <div className="average-profit wc-negative-direction">
                        ‎-0.05%
                      </div>
                      <div className="cy-change wc-postive-direction">
                        ‎+14.11%
                      </div>
                    </div>
                    <div className="summaryValue-DS-EntryPoint1-1">
                      <div className="Till-amount">967,866.8</div>
                      <div className="Till-amount">‎123, 876, 933.68</div>
                    </div>
                    <div className="summaryText-DS-EntryPoint1-1 lightTheme">
                      <div className="One-day-return">1-day Return</div>
                      <div className="Year-Return">1-Year Return</div>
                    </div>
                  </div>
                </div>
                <div className="Second-segment customScrollBar">
                  {getHotelProducts &&
                    getHotelProducts.map((product) => {
                      return (
                        <>
                          <div className="quoteView-DS-EntryPoint1-2 lightTheme active">
                            <div className="selctedTab-DS-EntryPoint1-1 wc-scheme-bar"></div>
                            <div className="quoteTitlesCntr-DS-EntryPoint1-3">
                              <span className="quoteTitle-DS-EntryPoint1-4">
                                {product.productName}
                              </span>
                              <span className="secTitle-DS-EntryPoint1-3 lightTheme">
                                {product.company}
                              </span>
                            </div>
                            <div className="itemPriceChart-DS-EntryPoint1-1 lightTheme">
                              <div className="sparklineContainer-DS-EntryPoint1-2">
                                <div className="Chart-of-content"></div>
                                {/* chart */}
                                <ProductChart />
                              </div>
                            </div>
                            <div className="quoteValueCntr-DS-EntryPoint1-1 ">
                              <span className="quotePrice-DS-EntryPoint1-1">
                                ₦‎ {product.productPrice.toLocaleString()}
                              </span>
                              <span
                                className={`changePcnt-DS-EntryPoint1-1 changeGreen-DS-EntryPoint1-1 lightTheme ${
                                  product.relativeChange < 0
                                    ? 'red-DS-EntryPoint1-1'
                                    : ''
                                }`}
                              >
                                {`${product.relativeChange}%`}
                              </span>
                            </div>
                          </div>
                          <Divider />
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ts-middle ts-main-flex pointer-events-auto hybrid-passthrough LSX89j">
        <div className="flex-fill">
          <div className="ts-middle-pane flex-fill">
            <div className="messages-header flex-fill">
              <div className="ts-messages-header messages-header-v2">
                <div className="app-messages-header ts-section-divider">
                  <div className="app-chat-header-content MSH_787">
                    <>
                      {isNotAccounting === 'Accounting' && (
                        <>
                          <TabList
                            defaultSelectedValue="tab1"
                            style={{ marginTop: '-6px' }}
                          >
                            <Tab value="tab1">Accountants</Tab>
                            <Tab value="tab2">Banking</Tab>
                            <Tab value="tab3">Tax Filling</Tab>
                            <Tab value="tab4">PayRoll</Tab>
                            <Tab value="tab5">Wave Advisors</Tab>
                            <Tab value="tab6">Reports</Tab>
                            <Tab value="tab7">
                              <Menu>
                                <MenuTrigger disableButtonEnhancement>
                                  <MenuButton appearance="transparent">
                                    Sales
                                  </MenuButton>
                                </MenuTrigger>

                                <MenuPopover>
                                  <MenuList>
                                    <MenuItem>Estimate</MenuItem>
                                    <MenuItem>Invoices</MenuItem>
                                    <MenuItem>Recuring Invoices</MenuItem>
                                    <MenuItem>CheckOuts</MenuItem>
                                    <MenuItem>Payments</MenuItem>
                                    <MenuItem>Guest Statement</MenuItem>
                                    <MenuItem>Guest</MenuItem>
                                  </MenuList>
                                </MenuPopover>
                              </Menu>
                            </Tab>
                            <Tab value="tab8">
                              <Menu>
                                <MenuTrigger disableButtonEnhancement>
                                  <MenuButton appearance="transparent">
                                    Purchase
                                  </MenuButton>
                                </MenuTrigger>

                                <MenuPopover>
                                  <MenuList>
                                    <MenuItem>Vendors</MenuItem>
                                    <MenuItem>Bills</MenuItem>
                                    <MenuItem>Product & Services</MenuItem>
                                  </MenuList>
                                </MenuPopover>
                              </Menu>
                            </Tab>
                          </TabList>
                        </>
                      )}
                      {isNotAccounting === 'Finance' && (
                        <>
                          <TabList
                            style={{ marginTop: '6px' }}
                            defaultSelectedValue="tab1"
                          >
                            <Tab value="tab1">Room Finance</Tab>
                            <Tab value="tab2">Kitchen Finance</Tab>
                            <Tab value="tab3">Bar Finance</Tab>
                            <Tab value="tab4">Event Finance</Tab>
                            <Tab value="tab5">Spa & Wellness</Tab>
                            <Tab value="tab6">Gift Shops</Tab>
                            <Tab value="tab7">Reliable Payrol</Tab>
                            <Tab value="tab8">Professional Invoicing</Tab>
                          </TabList>
                        </>
                      )}
                      {isNotAccounting === 'Audit' && (
                        <>
                          <span className="audit">Auitors</span>
                        </>
                      )}
                    </>
                  </div>
                </div>
              </div>
              <div className="ts-tab-content flex-fill">
                <div
                  role="main"
                  className="ts-embedded-container flex-fill hybrid-passthrough"
                >
                  <div className="content-child flex-fill">
                    <>
                      {isNotAccounting === 'Finance' && (
                        <div className="wDailyTabCarousel-DS-EntryPoint1-1">
                          <div className="chevron__left-DS-EntryPoint1-2 MEHJ_POSUBE">
                            {scrollPosition < 0 && (
                              <Button
                                onClick={() => handleScroll('right')}
                                icon={<ChevronLeft24Regular />}
                              ></Button>
                            )}
                          </div>
                          <div className="chevron__right-DS-EntryPoint1-2 MEHJ_POSUBE">
                            {scrollPosition > -(roomQuote.length * 131.6) && (
                              <Button
                                onClick={() => handleScroll('left')}
                                icon={<ChevronRight24Regular />}
                              ></Button>
                            )}
                          </div>
                          <ul
                            ref={containerRef}
                            className="wDailyTabContainer-DS-EntryPoint1-1 scrollToLeft"
                          >
                            {roomQuote.map((Q, i) => {
                              console.log('Lenght: ', roomQuote.length);
                              let absoluteChange = Q.absoluteChange < 0 ? `${Q.absoluteChange}` : `+${Q.absoluteChange}`;
                              let relativeChange = Q.relativeChange < 0 ? `${Q.relativeChange}` : `+${Q.relativeChange}`;
                              
                              return (
                                <div className="lkR3Y">
                                  <span className="Flex-product-update">
                                    <div className="vReuC GEykwb">
                                      <span className="hEj6ke">
                                        <div className="VORjKe tGqfud">
                                          <div className="n1rUf uoQxH">
                                            <span
                                              className="ZoIEk uoQxH"
                                              aria-hidden="true"
                                            >
                                              {Q.relativeChange >= 0 ? (
                                                <ArrowTrending24Regular />
                                              ) : (
                                                <ArrowTrendingDown24Regular
                                                  style={{ color: '#E37D80' }}
                                                />
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      </span>
                                      <div className="VKMjFc">
                                        <div className="pKBk1e">
                                          {Q.productName}
                                        </div>
                                        <div className="wzUQBf">
                                          <span className="lh92">
                                            <div className="s1OkXb">
                                              <div className="YMlKec">
                                                {Q.totalSum}
                                              </div>
                                            </div>
                                          </span>
                                        </div>
                                      </div>
                                      <div className="T7Akdb">
                                        <span
                                          className="NydbP  a3qxNc"
                                          aria-label="Up by 0.22%"
                                        >
                                          <div className="zWwE1 Ez2Ioe P2Luy t5mBV">
                                            <div
                                              className={`JwB6zf V7hZne ${
                                                Q.relativeChange < 0
                                                  ? 'red-DS-EntryPoint1-1'
                                                  : ''
                                              }`}
                                            >
                                              {`${relativeChange ?? '0.00'} `}
                                            </div>
                                          </div>
                                        </span>
                                        <div className="SEGxAb">
                                          <div className="BAftM">
                                            <span
                                              className={`P2Luy Ez2Ioe ${
                                                Q.absoluteChange < 0
                                                  ? 'red-DS-EntryPoint1-1'
                                                  : ''
                                              }`}
                                            >
                                              {`${absoluteChange ?? '0.00'} `}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </span>
                                </div>
                              );
                            })}
                          </ul>
                        </div>
                      )}

                      {/* Accounting headers */}

                      {isNotAccounting === 'Accounting' && (
                        <div className="wDailyTabCarousel-DS-EntryPoint1-1">
                          <>
                            <div className="chevron__left-DS-EntryPoint1-2 MEHJ_POSUBE">
                              {scrollPosition < 0 && (
                                <Button
                                  onClick={() => handleScroll('right')}
                                  icon={<ChevronLeft24Regular />}
                                ></Button>
                              )}
                            </div>
                            <div className="chevron__right-DS-EntryPoint1-2 MEHJ_POSUBE">
                              {scrollPosition > -700 && (
                                <Button
                                  onClick={() => handleScroll('left')}
                                  icon={<ChevronRight24Regular />}
                                ></Button>
                              )}
                            </div>
                            <ul
                              ref={containerRef}
                              className="wDailyTabContainer-DS-EntryPoint1-1 scrollToLeft"
                            >
                              {dates.map((date, i) => {
                                const dayOfWeek = date.toLocaleDateString(
                                  undefined,
                                  { weekday: 'short' }
                                );
                                const dayOfMonth = date.getDate();
                                const isToday = isSameDay(date, new Date());

                                return (
                                  <span className="wc-contains">
                                    <span className="quoteTitlesCntr-DS-EntryPoint1-2">
                                      <div className="quoteTitle-DS-EntryPoint1-2 lightTheme">
                                        {' '}
                                        MARKEL COCACOLA
                                      </div>
                                      <div className="price-DS-EntryPoint1-2 moneyGreenColor-DS-EntryPoint1-2 lightTheme">
                                        <span className="changeMarkerGain-DS-EntryPoint1-2">
                                          ‎▲
                                        </span>
                                        {/* <span className="changeMarkerGain-DS-EntryPoint1-2">‎▼</span>
                                         */}
                                        <span className="changeValuesPercentage-DS-EntryPoint1-2">
                                          ‎#5674
                                        </span>
                                      </div>
                                    </span>
                                  </span>
                                );
                              })}
                            </ul>
                          </>
                        </div>
                      )}

                      <div className="ywuFU customScrollBar">
                        <>
                          {isNotAccounting === 'Finance' && (
                            <div className="emji-LE89">
                              <div className="LETE907 GHET_8728">
                                {/* grif content here */}
                                <div className="root-DS-EntryPoint1-3 ___77lcry0 f10pi13n">
                                  <div className="investorHeader-DS-EntryPoint1-1">
                                    <div className="iconLarge-DS-EntryPoint1-1">
                                      <ArrowTrendingLines24Filled />
                                    </div>
                                    <div className="investorHeaderTitle-DS-EntryPoint1-1">
                                      <strong
                                        className="content-size-name"
                                        title="BAR&LOUNGE"
                                      >
                                        BAR & LOUNGE
                                      </strong>
                                      <div
                                        className="content-size-name"
                                        title="Whitehouse Hotel Limited"
                                      >
                                        Whitehouse Hotel Limited
                                      </div>
                                    </div>
                                  </div>
                                  <div className="profolioSection-DS-EntryPoint1-1">
                                    <div>&#8358; 678, 890.1069</div>
                                    <div className="Flex-direction-9">
                                      <div className="">
                                        {' '}
                                        <span className="red-DS-EntryPoint1-1">
                                          ‎-2.73%
                                        </span>
                                        <span> 1 week </span>
                                        <span></span>
                                      </div>
                                      <div className="">
                                        <span className="green-DS-EntryPoint1-1">
                                          ‎+21,300.00%
                                        </span>
                                        <span> 1 year</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="fund_stockListMain-DS-EntryPoint1-1">
                                    <div className="stockRowContainer-DS-EntryPoint1-1">
                                      <div
                                        className="stockName-DS-EntryPoint1-1"
                                        title="Market Cap"
                                      >
                                        Market Cap
                                      </div>
                                      <div title="₦26.17M" className="">
                                        ₦26.17M
                                      </div>
                                    </div>
                                    <div className="stockRowContainer-DS-EntryPoint1-1">
                                      <div
                                        className="stockName-DS-EntryPoint1-1"
                                        title="P/E"
                                      >
                                        P/E
                                      </div>
                                      <div title="6.32" className="">
                                        6.32
                                      </div>
                                    </div>
                                    <div className="stockRowContainer-DS-EntryPoint1-1">
                                      <div
                                        className="stockName-DS-EntryPoint1-1"
                                        title="Yield"
                                      >
                                        Yield
                                      </div>
                                      <div title="0.00%" className="">
                                        0.00%
                                      </div>
                                    </div>
                                    <div className="stockRowContainer-DS-EntryPoint1-1">
                                      <div
                                        className="stockName-DS-EntryPoint1-1"
                                        title="Sector"
                                      >
                                        Sector
                                      </div>
                                      <div
                                        title="Food and Beverage"
                                        className=""
                                      >
                                        Food and Beverage
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* grid content duplicate */}
                                {/* grif content here */}
                                <div className="root-DS-EntryPoint1-3 ___77lcry0 f10pi13n">
                                  <div className="investorHeader-DS-EntryPoint1-1">
                                    <div className="iconLarge-DS-EntryPoint1-1">
                                      <ArrowTrendingLines24Filled />
                                    </div>
                                    <div className="investorHeaderTitle-DS-EntryPoint1-1">
                                      <strong
                                        className="content-size-name"
                                        title=" KITCHEN"
                                      >
                                        KITCHEN
                                      </strong>
                                      <div
                                        className="content-size-name"
                                        title="Whitehouse Hotel Limited"
                                      >
                                        Whitehouse Hotel Limited
                                      </div>
                                    </div>
                                  </div>
                                  <div className="profolioSection-DS-EntryPoint1-1">
                                    <div>&#8358; 678, 890.1069</div>
                                    <div className="Flex-direction-9">
                                      <div className="">
                                        {' '}
                                        <span className="red-DS-EntryPoint1-1">
                                          ‎-2.73%
                                        </span>
                                        <span> 1 week </span>
                                        <span></span>
                                      </div>
                                      <div className="">
                                        <span className="green-DS-EntryPoint1-1">
                                          ‎+21,300.00%
                                        </span>
                                        <span> 1 year</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="fund_stockListMain-DS-EntryPoint1-1">
                                    <div className="stockRowContainer-DS-EntryPoint1-1">
                                      <div
                                        className="stockName-DS-EntryPoint1-1"
                                        title="Market Cap"
                                      >
                                        Market Cap
                                      </div>
                                      <div title="₦26.17M" className="">
                                        ₦26.17M
                                      </div>
                                    </div>
                                    <div className="stockRowContainer-DS-EntryPoint1-1">
                                      <div
                                        className="stockName-DS-EntryPoint1-1"
                                        title="P/E"
                                      >
                                        P/E
                                      </div>
                                      <div title="6.32" className="">
                                        6.32
                                      </div>
                                    </div>
                                    <div className="stockRowContainer-DS-EntryPoint1-1">
                                      <div
                                        className="stockName-DS-EntryPoint1-1"
                                        title="Yield"
                                      >
                                        Yield
                                      </div>
                                      <div title="0.00%" className="">
                                        0.00%
                                      </div>
                                    </div>
                                    <div className="stockRowContainer-DS-EntryPoint1-1">
                                      <div
                                        className="stockName-DS-EntryPoint1-1"
                                        title="Sector"
                                      >
                                        Sector
                                      </div>
                                      <div
                                        title="Food and Beverage"
                                        className=""
                                      >
                                        Food and Beverage
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* grid content duplicate */}
                              <div className="LETE907 GHET_8728">
                                <div className="root-DS-EntryPoint1-3 ___77lcry0 f10pi13n">
                                  <div className="investorHeader-DS-EntryPoint1-1">
                                    <div className="iconLarge-DS-EntryPoint1-1">
                                      <ArrowTrendingLines24Filled />
                                    </div>
                                    <div className="investorHeaderTitle-DS-EntryPoint1-1">
                                      <strong
                                        className="content-size-name"
                                        title="BAR&LOUNGE"
                                      >
                                        ROOM
                                      </strong>
                                      <div
                                        className="content-size-name"
                                        title="Whitehouse Hotel Limited"
                                      >
                                        Whitehouse Hotel Limited
                                      </div>
                                    </div>
                                  </div>
                                  <div className="profolioSection-DS-EntryPoint1-1">
                                    <div>&#8358; 678, 890.1069</div>
                                    <div className="Flex-direction-9">
                                      <div className="">
                                        {' '}
                                        <span className="red-DS-EntryPoint1-1">
                                          ‎-2.73%
                                        </span>
                                        <span> 1 week </span>
                                        <span></span>
                                      </div>
                                      <div className="">
                                        <span className="green-DS-EntryPoint1-1">
                                          ‎+21,300.00%
                                        </span>
                                        <span> 1 year</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="fund_stockListMain-DS-EntryPoint1-1">
                                    <div className="stockRowContainer-DS-EntryPoint1-1">
                                      <div
                                        className="stockName-DS-EntryPoint1-1"
                                        title="Market Cap"
                                      >
                                        Market Cap
                                      </div>
                                      <div title="₦26.17M" className="">
                                        ₦26.17M
                                      </div>
                                    </div>
                                    <div className="stockRowContainer-DS-EntryPoint1-1">
                                      <div
                                        className="stockName-DS-EntryPoint1-1"
                                        title="P/E"
                                      >
                                        P/E
                                      </div>
                                      <div title="6.32" className="">
                                        6.32
                                      </div>
                                    </div>
                                    <div className="stockRowContainer-DS-EntryPoint1-1">
                                      <div
                                        className="stockName-DS-EntryPoint1-1"
                                        title="Yield"
                                      >
                                        Yield
                                      </div>
                                      <div title="0.00%" className="">
                                        0.00%
                                      </div>
                                    </div>
                                    <div className="stockRowContainer-DS-EntryPoint1-1">
                                      <div
                                        className="stockName-DS-EntryPoint1-1"
                                        title="Sector"
                                      >
                                        Sector
                                      </div>
                                      <div
                                        title="Food and Beverage"
                                        className=""
                                      >
                                        Food and Beverage
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="root-DS-EntryPoint1-3 ___77lcry0 f10pi13n">
                                  <div className="investorHeader-DS-EntryPoint1-1">
                                    <div className="iconLarge-DS-EntryPoint1-1">
                                      <ArrowTrendingLines24Filled />
                                    </div>
                                    <div className="investorHeaderTitle-DS-EntryPoint1-1">
                                      <strong
                                        className="content-size-name"
                                        title="EVENT "
                                      >
                                        EVENT
                                      </strong>
                                      <div
                                        className="content-size-name"
                                        title="Whitehouse Hotel Limited"
                                      >
                                        Whitehouse Hotel Limited
                                      </div>
                                    </div>
                                  </div>
                                  <div className="profolioSection-DS-EntryPoint1-1">
                                    <div>&#8358; 678, 890.1069</div>
                                    <div className="Flex-direction-9">
                                      <div className="">
                                        {' '}
                                        <span className="red-DS-EntryPoint1-1">
                                          ‎-2.73%
                                        </span>
                                        <span> 1 week </span>
                                        <span></span>
                                      </div>
                                      <div className="">
                                        <span className="green-DS-EntryPoint1-1">
                                          ‎+21,300.00%
                                        </span>
                                        <span> 1 year</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="fund_stockListMain-DS-EntryPoint1-1">
                                    <div className="stockRowContainer-DS-EntryPoint1-1">
                                      <div
                                        className="stockName-DS-EntryPoint1-1"
                                        title="Market Cap"
                                      >
                                        Market Cap
                                      </div>
                                      <div title="₦26.17M" className="">
                                        ₦26.17M
                                      </div>
                                    </div>
                                    <div className="stockRowContainer-DS-EntryPoint1-1">
                                      <div
                                        className="stockName-DS-EntryPoint1-1"
                                        title="P/E"
                                      >
                                        P/E
                                      </div>
                                      <div title="6.32" className="">
                                        6.32
                                      </div>
                                    </div>
                                    <div className="stockRowContainer-DS-EntryPoint1-1">
                                      <div
                                        className="stockName-DS-EntryPoint1-1"
                                        title="Yield"
                                      >
                                        Yield
                                      </div>
                                      <div title="0.00%" className="">
                                        0.00%
                                      </div>
                                    </div>
                                    <div className="stockRowContainer-DS-EntryPoint1-1">
                                      <div
                                        className="stockName-DS-EntryPoint1-1"
                                        title="Sector"
                                      >
                                        Sector
                                      </div>
                                      <div
                                        title="Food and Beverage"
                                        className=""
                                      >
                                        Food and Beverage
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="LETE907 GHET_8728">
                                <div className="root-DS-EntryPoint1-3 ___77lcry0 f10pi13n">
                                  <div className="investorHeader-DS-EntryPoint1-1">
                                    <div className="iconLarge-DS-EntryPoint1-1">
                                      <ArrowTrendingLines24Filled />
                                    </div>
                                    <div className="investorHeaderTitle-DS-EntryPoint1-1">
                                      <strong
                                        className="content-size-name"
                                        title="BAR&LOUNGE"
                                      >
                                        BAR & LOUNGE
                                      </strong>
                                      <div
                                        className="content-size-name"
                                        title="Whitehouse Hotel Limited"
                                      >
                                        Whitehouse Hotel Limited
                                      </div>
                                    </div>
                                  </div>
                                  <div className="profolioSection-DS-EntryPoint1-1">
                                    <div>&#8358; 678, 890.1069</div>
                                    <div className="Flex-direction-9">
                                      <div className="">
                                        {' '}
                                        <span className="red-DS-EntryPoint1-1">
                                          ‎-2.73%
                                        </span>
                                        <span> 1 week </span>
                                        <span></span>
                                      </div>
                                      <div className="">
                                        <span className="green-DS-EntryPoint1-1">
                                          ‎+21,300.00%
                                        </span>
                                        <span> 1 year</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="fund_stockListMain-DS-EntryPoint1-1">
                                    <div className="stockRowContainer-DS-EntryPoint1-1">
                                      <div
                                        className="stockName-DS-EntryPoint1-1"
                                        title="Market Cap"
                                      >
                                        Market Cap
                                      </div>
                                      <div title="₦26.17M" className="">
                                        ₦26.17M
                                      </div>
                                    </div>
                                    <div className="stockRowContainer-DS-EntryPoint1-1">
                                      <div
                                        className="stockName-DS-EntryPoint1-1"
                                        title="P/E"
                                      >
                                        P/E
                                      </div>
                                      <div title="6.32" className="">
                                        6.32
                                      </div>
                                    </div>
                                    <div className="stockRowContainer-DS-EntryPoint1-1">
                                      <div
                                        className="stockName-DS-EntryPoint1-1"
                                        title="Yield"
                                      >
                                        Yield
                                      </div>
                                      <div title="0.00%" className="">
                                        0.00%
                                      </div>
                                    </div>
                                    <div className="stockRowContainer-DS-EntryPoint1-1">
                                      <div
                                        className="stockName-DS-EntryPoint1-1"
                                        title="Sector"
                                      >
                                        Sector
                                      </div>
                                      <div
                                        title="Food and Beverage"
                                        className=""
                                      >
                                        Food and Beverage
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="root-DS-EntryPoint1-3 ___77lcry0 f10pi13n">
                                  <div className="investorHeader-DS-EntryPoint1-1">
                                    <div className="iconLarge-DS-EntryPoint1-1">
                                      <ArrowTrendingLines24Filled />
                                    </div>
                                    <div className="investorHeaderTitle-DS-EntryPoint1-1">
                                      <strong
                                        className="content-size-name"
                                        title="SPAR &WELLNESS"
                                      >
                                        SPAR & WELLNESS
                                      </strong>
                                      <div
                                        className="content-size-name"
                                        title="Whitehouse Hotel Limited"
                                      >
                                        Whitehouse Hotel Limited
                                      </div>
                                    </div>
                                  </div>
                                  <div className="profolioSection-DS-EntryPoint1-1">
                                    <div>&#8358; 678, 890.1069</div>
                                    <div className="Flex-direction-9">
                                      <div className="">
                                        {' '}
                                        <span className="red-DS-EntryPoint1-1">
                                          ‎-2.73%
                                        </span>
                                        <span> 1 week </span>
                                        <span></span>
                                      </div>
                                      <div className="">
                                        <span className="green-DS-EntryPoint1-1">
                                          ‎+21,300.00%
                                        </span>
                                        <span> 1 year</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="fund_stockListMain-DS-EntryPoint1-1">
                                    <div className="stockRowContainer-DS-EntryPoint1-1">
                                      <div
                                        className="stockName-DS-EntryPoint1-1"
                                        title="Market Cap"
                                      >
                                        Market Cap
                                      </div>
                                      <div title="₦26.17M" className="">
                                        ₦26.17M
                                      </div>
                                    </div>
                                    <div className="stockRowContainer-DS-EntryPoint1-1">
                                      <div
                                        className="stockName-DS-EntryPoint1-1"
                                        title="P/E"
                                      >
                                        P/E
                                      </div>
                                      <div title="6.32" className="">
                                        6.32
                                      </div>
                                    </div>
                                    <div className="stockRowContainer-DS-EntryPoint1-1">
                                      <div
                                        className="stockName-DS-EntryPoint1-1"
                                        title="Yield"
                                      >
                                        Yield
                                      </div>
                                      <div title="0.00%" className="">
                                        0.00%
                                      </div>
                                    </div>
                                    <div className="stockRowContainer-DS-EntryPoint1-1">
                                      <div
                                        className="stockName-DS-EntryPoint1-1"
                                        title="Sector"
                                      >
                                        Sector
                                      </div>
                                      <div
                                        title="Food and Beverage"
                                        className=""
                                      >
                                        Food and Beverage
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                        {isNotAccounting === 'Accounting' && (
                          <>
                            <div className="emji-LE89">
                              <Accounting user={user} />
                            </div>
                          </>
                        )}

                        {isNotAccounting === 'Auditing' && <>Hello!</>}
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <>
        {closeVendor && (
          <div className="fluent-default-layer-host">
            <div className="ms-Layer ms-Layer--fixed root-44">
              <RegisterCompany close={CloseVendowWindow} user={user} />
            </div>
          </div>
        )}

        {closeProductWindow && (
          <div className="fluent-default-layer-host">
            <div className="ms-Layer ms-Layer--fixed root-44">
              <RegisterProduct close={closeNewProductWindow} message={''} />
            </div>
          </div>
        )}

        {isBusinestypeOpen && (
          <>
            <BusinessTypes onClose={closeBusinessTypes} message={''} />
          </>
        )}
      </>
    </>
  );
};

export default Finance;
