export interface ICreateInvoice {
    invoiceId?:number;
    reservationId?: number;
    vendorId?: number;
    restuarantId?:number;
    roomId?: number;
    eventId?: number;
    guestId?:number;
    wellnessId?:number;
    giftId?:number;
    itemId?:number;
    quantity?:number;
    service?: string;
    invoiceType?:string;
    reason?: string;
    notes?: string;
    amount: number;
    invoceStart: Date;
    invoceEnd: Date;
  }
  

  export const initializeInvoice: ICreateInvoice  ={
    invoiceId: 0,
    reservationId: 0,
    vendorId: 0,
    guestId: 0,
    roomId: 0,
    itemId:0,
    eventId: 0,
    wellnessId:0,
    giftId:0,
    invoiceType: '',
    service: '',
    reason: '',
    notes: '',
    amount: 0,
    invoceStart: new Date(),
    invoceEnd: new Date(),
  }
  