export interface IGeneralRequisition {
    id: number;
    productNo?: number;
    employeeId?: number;
    purchasePrice?: number;
    quantityReceived?: number;
    quantityInStock?: number;
    totalQuantity?: number;
    amountOfQuantityInStock?: number;
    amountofQuantityRecieved?: number;
    totalAmount?: number;
    productStatus?: string;
    dateAdded?: Date;
}


export const initializeRequisition: IGeneralRequisition = {
    id: 0,
    productNo: 0,
    employeeId: 0,
    purchasePrice: 0,
    quantityReceived: 0,
    quantityInStock: 0,
    totalQuantity: 0,
    amountOfQuantityInStock: 0,
    amountofQuantityRecieved: 0,
    totalAmount: 0,
    productStatus: "",
    dateAdded: new Date(),
};
