
import './Guest.scss';
import { SearchBox } from '@fluentui/react-search-preview';
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Avatar,
  AvatarGroup,
  AvatarGroupItem,
  Checkbox,
  Field,
  Label,
  Radio,
  RadioGroup,
} from '@fluentui/react-components';
import type { SearchBoxProps } from '@fluentui/react-search-preview';
import axios from 'axios';
import { WHITE_HOUSE_API, WHITE_HOUSE_S3 } from '../../../Constants/Constant';
import {initializeIcons } from '@fluentui/react';
import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Button, Input } from '@fluentui/react-components';
import {
  ChevronLeft24Regular,
  PeopleRegular,
  WeatherRainShowersNight24Regular,
} from '@fluentui/react-icons';

import {
  Dropdown,
  makeStyles,
  Option,
  shorthands,
  useId,
  Caption1,
  tokens,
  Text,
} from '@fluentui/react-components';
import type {InputOnChangeData } from '@fluentui/react-components';
import {
  IColumn
} from '@fluentui/react';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import { GetAllGuest, Guest, ICreateGuest, createGuest } from '../../../Data/General/Guest';
import visaLogo from '../../Themes/Images/Visa.svg';
import mastercardLogo from '../../Themes/Images/Master.svg';
import verveLogo from '../../Themes/Images/Verve-logo-dark.svg';
import None from '../../Themes/Images/None.svg';
import { Gender } from '../../../Data/General/IGender';
import { ISpacielRequest } from '../../../Data/General/SpecialRequest';
import DispatchToast from '../../Common/Toast/DispatchToast';
import Loaders from '../../Common/Loader/Loader';

const resolveAsset = (asset: string) => {
  const ASSET_URL = `${WHITE_HOUSE_S3}`;

  return `${ASSET_URL}${asset}`;
};

const useStyles = makeStyles({
  main: {
    ...shorthands.gap('16px'),
    display: 'flex',
    flexWrap: 'wrap',
  },

  card: {
    width: '400px',
    maxWidth: '100%',
    minHeight: '250px',
    height: 'fit-content',
  },

  caption: {
    color: tokens.colorNeutralForeground3,
  },

  smallRadius: {
    ...shorthands.borderRadius(tokens.borderRadiusSmall),
  },

  grayBackground: {
    backgroundColor: tokens.colorNeutralBackground3,
  },

  control: {
    maxWidth: '300px',
  },
  wrapper: {
    columnGap: '15px',
    display: 'flex',
    marginBottom: '10px',
  },
});
interface User {
  email: string,
  phoneNumber:string,
  token:string,

  // other properties...
}

interface guest {
  onClose: () => void;
  user:User;
}

interface Titles {
  titleId: number;
  titleName: string;
}

interface ViewRoomProps extends SearchBoxProps {
  onClose: () => void;
  user:User;
}

const Guests: React.FC<ViewRoomProps> = ({
  onClose,
  user,
  ...searchBoxProps
}) => {
  const [showForm, setShowForm] = useState(false);
  const [showDetails, setshowDetails] = useState(true);
  const ActionText = showForm ? 'Submit' : 'Book Now';
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<GetAllGuest[]>([]);
  const [roomName, setRoomName] = useState('');
  const [populateUser, setPopulateUser] = useState<GetAllGuest>();
  const [iCreateGuest, setICreateGuest] = useState<ICreateGuest>(createGuest)
  const [roomNumber, setRoomNumber] = useState('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [showList, setShowList] = useState(false);
  const [hideAll, setHideAll] = useState(true);
  const [titleMapping, setTitleMapping] = useState<Titles[]>([]);
  const [genderMapping, setGenderMapping] = useState<Gender[]>([]);
  const [specialRequest, setSpecialRequest] = useState<ISpacielRequest[]>([])
  const [filteredOptions, setFilteredOptions] = useState(titleMapping);
  const [headername, setHeaderName] = useState<string>('Guest');
  const [buttonName, setButtonName] = useState<string>("Register");
  const [successMessage, setResponseMessage] = useState('');
  const [dispatchToaster, setDispatchToaster] = useState<boolean>(false);
  const outlineId = useId('input-outline');
  const underlineId = useId('input-underline');
  const filledLighterId = useId('input-filledLighter');
  const styles = useStyles();
  initializeIcons();
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const beforeLabelId = useId('before-label');
  const afterLabelId = useId('after-label');
  const [currency, setCurrency] = useState('NGN');
  const currencySymbol = currency === 'NGN' ? '₦' : '$';
  const [cardType, setCardType] = useState(None);



  const handleCurrencyChange = (event: any) => {
    const option = event.target.value;
    setCurrency(option);
    setICreateGuest((prevState) => ({ ...prevState, currency: option }));
  };
  const [expiryDate, setExpiryDate] = useState('');
  const handleGuestClose = () => {
    onClose();
  };


 
  const items = [
    { key: '1', name: 'Name', value: 'Flat1 Deluxy' },
    { key: '2', name: 'Floor', value: 2 },
    { key: '3', name: 'Area', value: 28 },
    { key: '4', name: 'Capacity', value: '2 + 1, Max. 3' },
    { key: '5', name: 'Cleaning Required', value: 'Yes' },
    { key: '6', name: 'Smoking Allowed', value: 'No' },
  ];

  const columns: IColumn[] = [
    {
      key: 'column1',
      name: 'Name',
      fieldName: 'name',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: 'column2',
      name: 'Value',
      fieldName: 'value',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  const handleBrowseAllClick = () => {
    setShowList((prevShowList) => !prevShowList);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(`Searching for: ${searchTerm}`);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };


const fetchTitles = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/Title`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
      const Titles = response.data;
      setTitleMapping(Titles);
      setFilteredOptions(Titles);
      console.log('Title items:', Titles);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  }, [user.token]);


  //setSpecialRequest

  const fetchGenders = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/Gender`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
      const Titles = response.data;
      setGenderMapping(Titles);
      console.log('Title items:', Titles);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  }, [user.token]);


  const fetchSpecialRequests = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/SpecialRequest`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
      const request = response.data;
      setSpecialRequest(request);
      console.log('Request items:', request);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  }, [user.token]);


  useEffect(() => {}, [currency]);

  useEffect(() => {
    console.log(currencySymbol);
    fetchTitles();
    fetchGenders();
    fetchSpecialRequests();
  }, [fetchTitles, currency, currencySymbol, fetchGenders, fetchSpecialRequests]);
  const handleInputChange = (event: any) => {
    const que = event.target.value;
    setQuery(que);
    if (que.length >= 1) {
      search(que);
    }
  };
  const toggleForm = () => {
    setShowForm((c) => !c);
    setshowDetails((c) => !c);
  };

  const search = async (query: any) => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Query/guest?query=${query}`,
        {
          headers: {
            'Authorization': `Bearer ${user.token}`
          },
          params: { query },
        }
      );
      setResults(response.data);
      console.log("The Guest!", response.data)
    } catch (error) {
      console.error(error);
      console.log("The Guest!")
    }
  };

  
  const SubmitGuestItem = async () => {
    try {
        setIsLoader(true);
        const response = await axios.post(`${WHITE_HOUSE_API}/Guest/create`, iCreateGuest, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        });
        if (response.data) {
          setTimeout(() => {
            setIsLoader(false);
          }, 1000);
    
          if (response.data.status === "Success") {
            setResponseMessage(response.data.eMessage);
            setDispatchToaster(true);
            setTimeout(() => {
              setDispatchToaster(false);
            }, 2000);
          }
        }
        console.log(response.data);
    } catch (error) {
        console.error('Error:', error);
    }
};

const handleGuestDateInputChange =
(fieldName: string) => (date: Date | null | undefined) => {
  if (date) {
    console.log(`The Date onSelectDate for ${fieldName}`, date);
    setICreateGuest((prevState) => ({
      ...prevState,
      [fieldName]: date,
    }));
  }
};

const handleGuestInputChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
) => {
  const target = event.target as HTMLInputElement;
  const { name, value, files } = target;
  if (target.type === 'file') {
    setICreateGuest((prevState) => ({ ...prevState, [name]: files ? files[0] : null }));
  } else {
    setICreateGuest((prevState) => ({ ...prevState, [name]: value === '' ? null : value }));
  }
};



const handleExpiryDateChange = (event: any) => {
  let value = event.target.value;
  if (value.length === 2 && expiryDate.length === 1) {
    value += '/';
  }
  if (value.length <= 7) {
    setExpiryDate(value);
    setICreateGuest((prevState) => ({ ...prevState, CardExpirationDate: value === '' ? null : value }));
  }
};

const handleInputMoneyEventChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  data: InputOnChangeData
) => {
  const cardNumber = data.value || '';
  let type = '';
  if (cardNumber.startsWith('4')) {
    type = visaLogo;
  } else if (cardNumber.startsWith('5')) {
    type = mastercardLogo;
  } else if (cardNumber.startsWith('6')) {
    type = verveLogo;
  } else {
    type = None;
  }
  setCardType(type);
};


const handleServiceChange = async (name:string, event:any) => {
  console.log("Services Guest change: ", name);
  let holder:any;
  const service = event.target.innerText;
  if(name === "titleId"){
    holder = titleMapping.find(c => c.titleName === service);
    if(holder){
      setICreateGuest((prevState) => ({ ...prevState, [name]: holder.titleId }));
    }
  }else{
    holder = genderMapping.find(c => c.genderName === service);
    if(holder){
      setICreateGuest((prevState) => ({ ...prevState, [name]: holder.genderId }));
    }
  }
 
}

const handleRequestIdChange = async (name:string, event:any) => {
  const service = event.target.innerText;
  console.log("the data service", service);
  setICreateGuest((prevState) => ({ ...prevState, [name]: service }));
}


const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { name, checked } = event.target;

  if (checked) {
    setICreateGuest((prevState) => ({ ...prevState, PaymentTerms: name }));
  } else if (iCreateGuest.paymentTerms === name) {
    setICreateGuest((prevState) => ({
      ...prevState,
      PaymentTerms: name,
    }));
  }
};


const handleManagedRadioChange = (e:any) => {
  if (e.target.value === "InitialAddress") {
      setICreateGuest((prevState) => ({
          ...prevState,
          BillingAddress: iCreateGuest.address,
      }));
  } else {
    setICreateGuest((prevState) => ({
      ...prevState,
      BillingAddress: iCreateGuest.billingAddress,
  }));
  }
};

const handleGuestSelect = (guest:any) => {
  setPopulateUser(guest);
  let fullname = guest.lastName + " " + guest.firstName;
  setHeaderName(fullname);
  setButtonName("Update User");
  setICreateGuest(guest)
  setResults([]);
  setQuery("");

};

  return (
    <>
      <div className="ms-Fabric ms-Layer-content content-46">
        <div className="wc-no outline">
          <div className="ms-Modal is-open root-39">
            <div
              aria-hidden="true"
              className="ms-Overlay ms-Overlay--dark root-48"
            ></div>
            <div
              id="cilsco-focuszone"
              className="ms-Dialog-main createListModalMain_979b2e47 main-40"
            >
              <div
                aria-hidden="true"
                id="Ariel-fixed"
                data-is-visible="true"
                data-is-focus-trap-zone-bumper="true"
              ></div>
              <div className="ms-Modal-scrollableContent scrollableContent-54 customScrollBar">
                <div className="Merging-34">
                  <div className="createList-root templatePreviewPage">
                    <div className="templatePreview">
                      <div className="createList-pageRoot templatePreviewContainer">
                        <div className="createList-header">
                        {dispatchToaster && (
                            <DispatchToast message={successMessage}/>
                        )}
                         {isLoader && (
                          <div className="restrict-the-inner">
                              <Loaders />

                          </div>
                          )}
                          <div className="templateTitleWithLogo">
                            <Avatar
                              name={headername !== "Guest" ? headername : undefined}
                              icon={headername === "Guest"? <PeopleRegular /> : undefined}
                              color='colorful'
                              idForColor="id-123"
                              aria-label="Group"
                            />
                            <h1 className="createList-headerTitle wc-margin">
                              {headername}
                            </h1>
                          </div>
                          <div className="ui-box Wsearch-ev-ew">
                            <SearchBox
                              placeholder="Quick Link .."
                              value={query}
                              onChange={handleInputChange}
                              onBlur={search}
                              className="sear-padd"
                            />

                            {results && (
                              <>
                                {results.map((g) => {
                                  let fullname = g.firstName + ' ' + g.lastName;
                                  let emailorphone = g.phone ?? g.email;
                                  return (
                                    <div
                                      key={g.guestId}
                                      onClick={() => handleGuestSelect(g)}
                                      className="search-curtain guest-maker"
                                    >
                                      <div className="curtain-a guest-curtain-ui wform-css-283-right">
                                        <div className="fui-Persona rlroi9i">
                                          <span
                                            role="img"
                                            id="avatar-rd"
                                            className="fui-Avatar r81b29z fui-Persona__avatar ___1c989qy_1o9k6tz f13ar0e0 fid048z f1hek2iy f9h729m f18q9vkd f103ycu4"
                                            aria-label="Kevin Sturgis"
                                            aria-labelledby="avatar-rd avatar-rd__badge"
                                          >
                                            <AvatarGroup>
                                              <AvatarGroupItem
                                                name={fullname}
                                              />
                                            </AvatarGroup>
                                          </span>
                                          <span className="fui-Persona__primaryText rvj41k9">
                                            {g.firstName} {g.lastName}
                                          </span>
                                          <span className="fui-Persona__secondaryText rp1pf9e ___19lp2a0_1p5a1yj f1ryq6si">
                                           Phone: {emailorphone}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          </div>
                          <div className="createList-closeBtnContainer UGuest">
                            <Button
                              icon={<i className="fi fi-sr-cross-small" />}
                              onClick={handleGuestClose}
                            ></Button>
                          </div>
                        </div>
                        <>
                          <div className="createList-pageContent includeBottomPadding customScrollBar">
                            <div className="previewContainer  wc-add-paddin">
                              <form action="" className="Form-content">
                                <div className="wform-MyContentControl-module__edgeworth-stack-item-container-refresh__3mUvv wform-css-283 ">
                                  <div className="ui-divider  ua-gi-ub-uc">
                                    <div className="wc-form-wrapper">
                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title MEHEB_UTb">
                                            Title
                                          </div>
                                          <Dropdown
                                            placeholder="Select a Title"
                                            appearance="filled-darker"                           
                                            name="titleId"
                                            onOptionSelect={(item) => handleServiceChange("titleId", item)}
                                          >
                                            {titleMapping.map((item) => (
                                                <Option key={item.titleId} value={item.titleName}>{item.titleName}</Option>
                                            ))}
                                          </Dropdown>
                                        </div>
                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title MEHEB_UTb">
                                            Gender
                                          </div>
                                          <Dropdown
                                          placeholder="Select a gender"
                                          appearance="filled-darker"
                                          name="genderId"
                                          onOptionSelect={(item) => handleServiceChange("genderId", item)}
                                      >
                                          {genderMapping.map((item) => (
                                              <Option key={item.genderId} value={item.genderName}>{item.genderName}</Option>
                                          ))}
                                      </Dropdown>

                                        </div>
                                      </div>

                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                            First Name
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              placeholder="First Name"
                                              name="firstName"
                                              value={iCreateGuest?.firstName ?? ""}
                                              appearance="filled-darker"
                                            
                                              onChange={handleGuestInputChange}
                                            />
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                            Last Name
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              appearance="filled-darker"
                                              name="lastName"
                                              value={iCreateGuest?.lastName ?? ""}
                                              placeholder="Last Name"
                                            
                                              onChange={handleGuestInputChange}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                            Date Of Birth
                                          </div>
                                          <div className="firstName-container">
                                            <Field className="Container-dateGuest">
                                              <DatePicker
                                              value={iCreateGuest?.dateOfBirth ? new Date(iCreateGuest.dateOfBirth) : null}
                                                showWeekNumbers={true}
                                                name="dateOfBirth"
                                                firstWeekOfYear={1}
                                                showMonthPickerAsOverlay={true}
                                                placeholder="Date of birth..."
                                                className={styles.control}
                                                onSelectDate={(date) => handleGuestDateInputChange('dateOfBirth')(date)}
                                              />
                                            </Field>
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                            Country
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                            value={iCreateGuest?.country ?? ""}
                                              appearance="filled-darker"
                                              name="country"
                                              placeholder="Country"
                                            
                                              onChange={handleGuestInputChange}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                            Phone Number
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              placeholder="Phone Number"
                                              appearance="filled-darker"
                                              name="phone"
                                              value={iCreateGuest?.phone ?? ""}
                                              onChange={handleGuestInputChange}
                                            />
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                            City
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              appearance="filled-darker"
                                              placeholder="City"
                                              name="city"
                                              value={iCreateGuest?.city ?? ""}
                                              onChange={handleGuestInputChange}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                            State
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              placeholder="State"
                                              appearance="filled-darker"
                                              name="state"
                                             value={iCreateGuest?.state ?? ""}
                                              onChange={handleGuestInputChange}
                                            />
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                            Email
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              appearance="filled-darker"
                                              name="email"
                                              placeholder="Email"
                                              value={iCreateGuest?.email ?? ""}
                                              onChange={handleGuestInputChange}
                                            
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                            Address
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              placeholder="address"
                                              value={iCreateGuest?.address ?? ""}
                                              appearance="filled-darker"
                                              name="address"
                                              onChange={handleGuestInputChange}
                                            
                                            />
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                            Passport/Id Number
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              appearance="filled-darker"
                                              name="guestIdCard"
                                              placeholder="ID/Passport Number"
                                              value={iCreateGuest?.guestIdCard ?? ""}
                                              onChange={handleGuestInputChange}
                                            
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                            Issues Date
                                          </div>
                                          <div className="firstName-container">
                                            <Field className="Container-dateGuest">
                                              <DatePicker
                                              value={iCreateGuest?.issueDateofId ? new Date(iCreateGuest.issueDateofId) : null}
                                                showWeekNumbers={true}
                                                firstWeekOfYear={1}
                                                showMonthPickerAsOverlay={true}
                                                placeholder="Issues Date..."
                                                name="issueDateofId"
                                                className={styles.control}
                                                onSelectDate={(date) => handleGuestDateInputChange('IssueDateofId')(date)}

                                              />
                                            </Field>
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                            Expire Date
                                          </div>
                                          <div className="firstName-container">
                                            <Field className="Container-dateGuest">
                                              <DatePicker                                        
                                              value={iCreateGuest?.epirdate ? new Date(iCreateGuest.epirdate) : null}
                                                showWeekNumbers={true}
                                                firstWeekOfYear={1}
                                                showMonthPickerAsOverlay={true}
                                                placeholder="Expire Date..."
                                                name="epirdate"
                                                className={styles.control}
                                                onSelectDate={(date) => handleGuestDateInputChange('epirdate')(date)}
                                              />
                                            </Field>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                            Nationality
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              placeholder="nationaliy"
                                              appearance="filled-darker"
                                             value={iCreateGuest?.nationaliy ?? ""}
                                              name="nationaliy"
                                              onChange={handleGuestInputChange}
                                            />
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                            Language
                                          </div>
                                          <div className="firstName-container">
                                            <Input
                                              appearance="filled-darker"
                                              placeholder="First Language"
                                              name="language"
                                            value={iCreateGuest?.language ?? ""}
                                              onChange={handleGuestInputChange}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-add-Guest-room Guest-management-job">
                                        <div className="w-add-room-guest rms-block-left">
                                          <div className="w-add-room-guest Guest-inline-property-title">
                                            Checking Date
                                          </div>
                                          <div className="firstName-container">
                                            <Field className="Container-dateGuest">
                                              <DatePicker
                                              value={iCreateGuest?.checkInTime ? new Date(iCreateGuest.checkInTime) : null}
                                                showWeekNumbers={true}
                                                firstWeekOfYear={1}
                                                showMonthPickerAsOverlay={true}
                                                name="checkInTime"
                                                onSelectDate={(date) => handleGuestDateInputChange('checkInTime')(date)}
                                                placeholder="Issues Date..."
                                                className={styles.control}
                                              />
                                            </Field>
                                          </div>
                                        </div>
                                        <div className="w-add-room-guest rms-block-right">
                                          <div className="w-add-room-guest rms-inline-property-title">
                                            Checkout Date
                                          </div>
                                          <div className="firstName-container">
                                            <Field className="Container-dateGuest">
                                              <DatePicker
                                              value={iCreateGuest?.checkOutTime ? new Date(iCreateGuest.checkOutTime) : null}
                                                showWeekNumbers={true}
                                                firstWeekOfYear={1}
                                                showMonthPickerAsOverlay={true}
                                                name="checkOutTime"
                                                onSelectDate={(date) => handleGuestDateInputChange('checkOutTime')(date)}
                                                placeholder="Expire Date..."
                                                className={styles.control}
                                              />
                                            </Field>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="wform-MyContentControl-module__edgeworth-stack-item-container-refresh__3mUvv wform-css-283-right">
                                  <Accordion defaultOpenItems="basicInfo">
                                    <AccordionItem value="basicInfo">
                                      <AccordionHeader as="h2">
                                        Credit information
                                      </AccordionHeader>
                                      <AccordionPanel>
                                        <Label className="wc-pmargin-b">
                                          Mode of payment
                                        </Label>
                                        <AccordionPanel>
                                          <div
                                            role="group"
                                            aria-labelledby="hobbiesLabel"
                                          >
                                            <Checkbox 
                                            label="Credit Card" 
                                            name="Credit Card"
                                            onChange={handleCheckboxChange}/>
                                            <Checkbox 
                                            name="Subscription"
                                            label="Subscription"
                                            onChange={handleCheckboxChange} />
                                            <Checkbox 
                                            label="Point of sell"
                                            name="Point of sell"
                                            onChange={handleCheckboxChange} />
                                            <Checkbox 
                                            label="Voucher"
                                            name="Voucher"
                                              onChange={handleCheckboxChange} />
                                          </div>
                                        </AccordionPanel>
                                        <RadioGroup
                                          defaultValue="InitialAddress"
                                          aria-labelledby="ageLabel"
                                          className="wc-flex"
                                          onChange={(e) => handleManagedRadioChange(e)}
                                        >
                                          <Radio
                                            value="InitialAddress"
                                            label="Address is the same"
                                          />
                                          <Radio
                                            value="InitialAddress2"
                                            label="New Billing Address"
                                          />
                                        </RadioGroup>
                                        <div className="w-add-Guest-room Guest-management-job">
                                       
                                          <div className="w-add-room-guest rms-block-left">
                                            <div className="w-add-room-guest Guest-inline-property-title">
                                              Full Name
                                            </div>
                                            <div className="firstName-container">
                                              <Input
                                                placeholder="Full Name"
                                                name="fullname"
                                                value={iCreateGuest?.fullname ?? ""}
                                                appearance="filled-darker"
                                                onChange={handleGuestInputChange}
                                              
                                              />
                                            </div>
                                          </div>

                                          <div className="w-add-room-guest rms-block-right">
                                            <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                              Billing Address
                                            </div>
                                            <div className="firstName-container wc-margin">
                                              <Input
                                                appearance="filled-darker"
                                                placeholder="Card Address"
                                                name="billingAddress"
                                                onChange={handleGuestInputChange}
                                               value={iCreateGuest?.billingAddress ?? ""}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="w-add-Guest-room Guest-management-job">
                                          <div className="w-add-room-guest rms-block-left">
                                            <div className="w-add-room-guest Guest-inline-property-title">
                                             Billing Country
                                            </div>
                                            <div className="firstName-container">
                                              <Input
                                                placeholder="Enter Country if different from above"
                                                name="billingCountry"
                                                appearance="filled-darker"
                                                onChange={handleGuestInputChange}
                                                value={iCreateGuest?.billingCountry ?? ""}
                                              />
                                            </div>
                                          </div>

                                          <div className="w-add-room-guest rms-block-right">
                                            <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                             
                                            </div>
                                            <div className="firstName-container wc-margin">
                                             
                                            </div>
                                          </div>
                                        </div>
                                        <RadioGroup
                                          defaultValue={iCreateGuest?.currency ?? "NGN"}
                                          onChange={handleCurrencyChange}
                                          aria-labelledby="ageLabel"
                                          className="wc-flex"
                                        >
                                          <Radio value="NGN" label="NGN" />
                                          <Radio value="USD" label="USD" />
                                        </RadioGroup>
                                        <div className="w-add-Guest-room Guest-management-job">
                                          <div className="w-add-room-guest rms-block-left">
                                            <div className="w-add-room-guest Guest-inline-property-title">
                                              Card Number
                                            </div>
                                            <div className="firstName-container">
                                            <Input
                                                placeholder="Card Number"
                                                type="number"
                                                name="CreditCardNumber"
                                                onChange={handleInputMoneyEventChange}
                                                contentAfter={<img src={cardType} alt="" />}
                                              />
                                            
                                            </div>
                                          </div>

                                          <div className="w-add-room-guest rms-block-right wc-margin">
                                            <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                              Expiring Date
                                            </div>
                                            <div className="firstName-container">
                                            <Input
                                              type="text"
                                              name="CardExpirationDate"
                                              placeholder="MM/YYYY"
                                              value={expiryDate}
                                              onChange={handleExpiryDateChange}
                                            />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="w-add-Guest-room Guest-management-job">
                                          <div className="w-add-room-guest rms-block-left">
                                            <div className="w-add-room-guest Guest-inline-property-title">
                                              CVV
                                            </div>
                                            <div className="firstName-container">
                                              <Input
                                                placeholder="CVV"
                                                appearance="filled-darker"
                                                type="password"
                                                name="CVV"
                                                onChange={handleGuestInputChange}
                                              
                                              />
                                            </div>
                                          </div>

                                          <div className="w-add-room-guest rms-block-right wc-margin">
                                            <div className="w-add-room-guest rms-inline-property-title wc-margin">
                                              Amount
                                            </div>
                                            <div className="firstName-container">
                                              <Input
                                                placeholder="Amount to Credit"
                                                type="number"
                                                contentBefore={
                                                  <Text
                                                    size={400}
                                                    id={beforeLabelId}
                                                  >
                                                    {currencySymbol}
                                                  </Text>
                                                }
                                                contentAfter={
                                                  <Text
                                                    size={400}
                                                    id={afterLabelId}
                                                  >
                                                    .00
                                                  </Text>
                                                }
                                                appearance="filled-darker"
                                              
                                              />
                                            </div>
                                          </div>
                                        </div>

                                      </AccordionPanel>
                                    </AccordionItem>
                                    <AccordionItem value="residence">
                                      <AccordionHeader as="h2">
                                        Guest Status
                                      </AccordionHeader>
                                      <AccordionPanel>
                                        <RadioGroup
                                          defaultValue={iCreateGuest?.maritalStatus ?? "Single"}
                                          aria-labelledby="ageLabel"
                                          className="wc-flex"
                                        >
                                          <Radio
                                            value="married"
                                            label="Married"
                                          />
                                          <Radio
                                            value="Single"
                                            label="Single"
                                          />
                                        </RadioGroup>
                                        <RadioGroup
                                         defaultValue={iCreateGuest?.childrenstatus ?? "Nochildren"}
                                          aria-labelledby="ageLabel"
                                          className="wc-flex"
                                        >
                                          <Radio
                                            value="Children"
                                            label="Children"
                                          />
                                          <Radio
                                            value="Nochildren"
                                            label="Without children"
                                          />
                                        </RadioGroup>
                                        <div className="w-add-Guest-room Guest-management-job">
                                          <div className="w-add-room-guest rms-block-left">
                                            <div className="w-add-room-guest Guest-inline-property-title">
                                             Total Number of Adults
                                            </div>
                                            <div className="firstName-container">
                                              <Input
                                                placeholder="Number of Adults"
                                                name=""
                                                appearance="filled-darker"
                                              
                                              />
                                            </div>
                                          </div>
                                          <div className="w-add-room-guest rms-block-right">
                                            <div className="w-add-room-guest rms-inline-property-title">
                                              Total Number of Children
                                            </div>
                                            <div className="firstName-container wc-margin">
                                              <Input
                                                appearance="filled-darker"
                                                placeholder="number of childreen"
                                              
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </AccordionPanel>
                                    </AccordionItem>
                                    <AccordionItem value="hobbies">
                                      <AccordionHeader as="h2">
                                        Requests
                                      </AccordionHeader>
                                      <AccordionPanel>
                                        <div
                                          role="group"
                                          aria-labelledby="hobbiesLabel"
                                        >
                                          <Dropdown
                                          placeholder="Select a request"
                                          appearance="filled-darker"
                                          name="RequestId"
                                          onOptionSelect={(item) => handleRequestIdChange("RequestId", item)}
                                      >
                                          {specialRequest.map((item) => (
                                              <Option key={item.id} value={item.name}>{item.name}</Option>
                                          ))}
                                      </Dropdown>
                                        </div>
                                      </AccordionPanel>
                                    </AccordionItem>
                                  </Accordion>
                               </div>
                              </form>
                            </div>
                          </div>
                        </>
                      </div>
                    </div>
                    <div className="createList-pageFooter">
                      <div className="createList-leftActions">
                        <Button
                          data-automationid="createListGoBackButton"
                          className=""
                          icon={<ChevronLeft24Regular />}
                          onClick={toggleForm}
                        >
                          {' '}
                          Back
                        </Button>
                      </div>
                      <div className="createList-rightActions">
                        <Button className="wc-first-root-68" onClick={SubmitGuestItem}>{buttonName}</Button>
                        <Button className="" onClick={handleGuestClose}>
                          {' '}
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

     
    </>
  );
};

export default Guests;
