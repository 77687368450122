import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { Navigate, useRoutes, RouteProps, useNavigate } from 'react-router-dom';
import {LoginDetails } from './ReturnedEmployee';
import axios from 'axios';
import { WHITE_HOUSE_API } from '../../Constants/Constant';


interface User {
  email: string,
  phoneNumber:string,
  token: string,
}


interface Message {
  message: string,
  id:number,
}

interface Icredential {
  email: string,
  PhoneNumber:  string,
  status:  string,
}
interface AuthState {
  user: User | null;
  message: Message | null;
  isLoggedIn: boolean;
  setIsLoggedIn: (loggedIn: boolean) => void;
  signIn: (details: LoginDetails) => Promise<void>;
  signOut: (cred:Icredential) => Promise<void>;
}

const defaultAuthState: AuthState = {
  user: null,
  message: null,
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  signIn: async () => {},
  signOut: async () => {},
};

const authContext = createContext<AuthState>(defaultAuthState);

// ProvideAuth component
export function ProvideAuth({ children }: { children: ReactNode }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object and re-render when it changes.
export function useAuth() {
  return useContext(authContext);
}

// Provider hook that creates auth object and handles state
export function useProvideAuth(): AuthState {
  const [user, setUser] = useState<User | null>(() => {
    const storedUser = sessionStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
});
  const [message, setEmessage] = useState<Message|null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  useEffect(() => {
    if (user) {
        sessionStorage.setItem('user', JSON.stringify(user));
    } else {
        sessionStorage.removeItem('user');
    }
}, [user]);

  const signIn = async (users: LoginDetails) => {
    try {

      if (users.status === "Success") {
        const luser = {
          email:users.email,
          phoneNumber:users.phoneNumber,
          token: users.token,
        };
        setUser(luser);
        setIsLoggedIn(true);
      } else {
        // Handle login failure
        console.error('Failed to log in');
      }
    } catch (error) {
      // Handle request failure
      console.error('An error occurred while making the request', error);
    }
  };
  


  const signOut = async (cred: Icredential) => {
    if(cred.status === "Success"){
      setUser(null);
      setIsLoggedIn(false);
    }
  };

  useEffect(() => {
    if (user) {
      sessionStorage.setItem('user', JSON.stringify(user));
    } else {
      sessionStorage.removeItem('user');
    }
  }, [user]);
  return {
    message,
    user,
    isLoggedIn,
    setIsLoggedIn,
    signIn,
    signOut,
  };
}

