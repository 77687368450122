import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AuthContext } from './Auth';
import './Login.scss';
import { Button, Input, Spinner } from '@fluentui/react-components';
import HTLOGO from '../Themes/Images/Payment-status.png';

import SignIn from './SignIn';
import axios from 'axios';
import { WHITE_HOUSE_API } from '../../Constants/Constant';
import { Employee, LoginData, LoginDetails } from './ReturnedEmployee';
import { useAuth } from './PrivateAuth';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage } from '../Common/messageBar/errormessage';


type storedUser = {
  email: string,
  PhoneNumber:  string,
  token: string,
}

const Login: React.FC = () => {
  const { signIn } = useAuth();
  const [isSign, setIsSignIn] = useState<boolean>(false);
  const [userInput, setUserInput] = useState<string>('');
  const [userPassword, setUserpassword] = useState<string>('');
  const [employee, setEmployee] = useState<Employee | null>(null);
  const [error, setError] = useState<string>('');
  const [userExist, setUserExist] = useState<boolean>(false);
  const [spinnerController, setSpnnerController] = useState<boolean>(false);
  const [storedUser, setStoredUser] = useState<storedUser>()
  let ButtonText = '';
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [errorTitle, setErorrTitle] = useState<string>('');
  const [errorMessage, setErorrMessage] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const storedUserString = sessionStorage.getItem('user');
  const expiry = localStorage.getItem('expiry');
  const [greeting, setGreeting] = useState('');
  // Inside your component
  const navigate = useNavigate();

  const searchUser = async (action: string) => {
    try {
      setSpnnerController(true);

      switch (action) {
        case 'Sign In':
          if (employee?.email && employee?.phoneNumber) {
            const users: LoginData = {
              email: employee.email,
              phoneNumber: employee.phoneNumber,
              password: userPassword,
            };
            try {
              const response = await axios.post(
                `${WHITE_HOUSE_API}/Login/login`,
                users
              );
              // On success, set the user object
              if (response.data.status === 'Success') {
                const LoginDetails: LoginDetails = {
                  email: response.data.email,
                  phoneNumber: response.data.phoneNumber,
                  status: response.data.status,
                  token: response.data.token,
                };
                await signIn(LoginDetails);
                sessionStorage.setItem('user', JSON.stringify(LoginDetails));
                localStorage.setItem('expiry', String(Date.now() + 30*60*1000)); // 30 minutes from now
                setSpnnerController(false);
                setUserExist(true);
                setError('');
                sessionStorage.setItem('isLoggedIn', JSON.stringify(true)); 
                console.log("Redirecting back to home");
                navigate('/chms', { replace: true });
              } else {
                console.log("error message:", response.data.errorMessage);
                setErorrTitle(response.data.status);
                setErorrMessage(response.data.errorMessage);
                  setSpnnerController(false);
                  setIsError(true);
              }
            } catch (error) {
              // Handle request failure
              console.error(
                'An error occurred while making the request',
                error
              );
            }
          }
          break;
        case 'Submit ID':
          const response = await axios.post(
            `${WHITE_HOUSE_API}/Login/Validate?userInput=${userInput}`
          );
          try{
            if(response.data.status === "Success") {
              setEmployee(response.data.userRecord);
                setSpnnerController(false);
              setUserExist(true);
              setError('');
            }
            else{
              console.log("error message:", response.data.errorMessage);
              setErorrTitle(response.data.status);
              setErorrMessage(response.data.errorMessage);
                setSpnnerController(false);
                setIsError(true);
            }
           
          } 
          catch (error)
          {
            console.error(
              'An error occurred while making the request',
              error
            );
          }
         
          break;
        default:
          // Handle other cases
          break;
      }
    } catch (err) {
      const error: any = err;
      if (error.response && error.response.data) {
        setError(error.response.data.message);
        setTimeout(() => {
          setSpnnerController(false);
        }, 10000);
      } else {
        setError('An error occurred');
      }
      setEmployee(null);
    }
  };

  ButtonText = userExist ? 'Sign In' : 'Submit ID';

  const signuserpage = () => {
    setIsSignIn(true);
  };

  const resetTimer = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      setIsSignIn(false);
    }, 45000);
  }, []);

  useEffect(() => {
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keypress', resetTimer);
    const date = new Date();
    const hour = date.getHours();

    if (hour < 12) {
        setGreeting('Good Morning!');
    } else if (hour < 18) {
        setGreeting('Good Afternoon!');
    } else {
        setGreeting('Good Evening!');
    };
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keypress', resetTimer);
    };
    
  }, [expiry, resetTimer, storedUser, storedUserString]);





// Your existing useEffect hook
useEffect(() => {
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      setIsSignIn(true);
    }
  };

  // Add event listener
  window.addEventListener('keydown', handleKeyDown);

  // Remove event listener on cleanup
  return () => {
    window.removeEventListener('keydown', handleKeyDown);
  };
}, []);

const closeIserror = () => {
  setIsError(false);
};


  return (
    <>
      <div
        data-transition="true"
        className="csco___MSH1 csco____2882v csco__vs3x"
        role="banner"
      >
        <div className="csco___shell">
          {isSign ? (
            <>
              <div className="csco____Wr gh-show-below csco____SE01 csco_______o2v">
                {spinnerController ? (
                  <div className="user-profile">
                    <div className="spiner-container">
                      <Spinner size="huge" />
                    </div>
                  </div>
                ) : (
                  <div className="user-profile">
                    <img className="user-image" src={HTLOGO} alt="" />
                    {isError ? (
                    <div className="errormessage-available">
                    <ErrorMessage errorTitle={errorTitle} errorMessages={errorMessage} closeFunction={closeIserror}/>
                  </div>
                    ):(
                      <div className="">
                      <h2 className="user-name">
                        {employee?.firstName} {employee?.lastName}
                      </h2>
                    </div>
                    )}
                
                    {userExist ? (
                      <div className="manage-input-filed">
                        <Input
                          placeholder="Enter password"
                          type="password"
                          value={userPassword}
                          onChange={(e) => setUserpassword(e.target.value)}
                          appearance="filled-darker"
                          id="Sign-input-field"
                        />
                      </div>
                    ) : (
                      <div className="manage-input-filed">
                        <Input
                          placeholder="USER ID"
                          value={userInput}
                          onChange={(e) => setUserInput(e.target.value)}
                          appearance="filled-darker"
                          id="Sign-input-field"
                        />
                      </div>
                    )}

                    <div className="response-error">
                      {error && <span className="error-message">{error}</span>}
                    </div>
                    <div className="wc-submitbtn">
                      <Button
                        appearance="primary"
                        onClick={() => searchUser(ButtonText)}
                        disabled={!userInput && !userPassword}
                      >
                        {ButtonText}
                      </Button>
                    </div>

                    {/* <div className="manage-recovery-id">
             <a href="#" className="forgot-id">I forgot my ID</a>
          </div> */}
                  </div>
                )}
              </div>
            </>
          ) : (
            <div
              data-name="csco___visibleB"
              id="csco___MSH3"
              className="csco_____P83m csco_____A892g"
            >
              <div className="titling bound">
                <h1 className="product-name">
                  <span>{greeting}</span>
                </h1>
                <div className="ma-wex">
                  <div className="carousel-style__EmblaSlideInner-sc-gi4lyt-3 jVufzs">
                    <h3 className="typography__Header-sc-17ll9dr-1 _vertical-carousel__StyledHeader-sc-18si4sf-0 eBAVKv hxeFjX"></h3>
                  </div>
                </div>
                <p className="tagline">
                  {' '}
                  <span className="mark-space">Solutions for</span>
                  <span className="mark-space"> success</span>
                  <span>and Driving Growth</span>
                </p>
              </div>
              <section className="precis above-fold">
                <div className="plate bound">
                <h2>
                    {' '}
                    <a href="www.ceitron.com" className="commpany-link">
                      Cilpron
                    </a>{' '}
                    designing the future for true visionaries
                  </h2>
                  <div className="csco-wrapper">
                    <div className="csco">
                      <a
                        data-m="Account"
                        className="internal Singin-button"
                        onClick={signuserpage}
                      >
                        <span>Sign in to working enviroment</span>
                      </a>
                    </div>
                  </div>
                </div>
              </section>
              <div className="mobile-cta" aria-hidden="true">
                <div className="bound">
                  <h2>
                    {' '}
                    <a href="www.ceitron.com" className="commpany-link">
                      Cilpron
                    </a>{' '}
                    designing the future for true visionaries
                  </h2>
                  <div className="csco-wrapper">
                    <div className="csco">
                      <a
                        data-m="Account"
                        className="internal Singin-button"
                        onClick={signuserpage}
                      >
                        <span>Sign in to working enviroment</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="csco______2yww"></div>
      </div>
    </>
  );
};

export default Login;
