export interface BarLoungeMenu {
    itemId: number;
    itemName: string | null;
    description: string | null;
    price: number | null;
    isAvailable: boolean | null;
    categoryId: number | null;
    ingredients: string | null;
    alcoholContent: string | null;
    imageUrl: string | null;
    preparationTime: string | null;
    isSpecial: boolean | null;
    dateadded: Date;
}

export interface IBarLoungeMenu {
    itemName: string | null;
    discription: string | null;
    price: number | null;
    isAvailable: boolean | null;
    CategoryId: number | null;
    categoryname:string | null;
    Ingredients: string | null;
    AlcoholContent: string | null;
    ImageUrl: string | null;
    PreparationTime: string | null;
    IsSpecial: boolean | null;
}


export const InitialIBar: BarLoungeMenu = {
    itemName: '',
    description: '',
    price: 0,
    isAvailable: true,
    categoryId: 0,
    ingredients: '',
    alcoholContent: '',
    imageUrl: '',
    preparationTime: "",
    isSpecial: false,
    itemId: 0,
    dateadded: new Date(),
}