import { Button, Combobox, Input, Label, Option, Menu, MenuButton, MenuItem, MenuList, MenuPopover, MenuTrigger, Persona, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, Toolbar, ToolbarButton, ToolbarDivider, TableSelectionCell, useTableSelection, useTableSort, useTableFeatures, Spinner, Checkbox, TableCellLayout } from '@fluentui/react-components';
import { Add24Regular, ArrowBetweenDown24Regular, ArrowCounterclockwise24Regular, ArrowDownload24Filled, DeleteRegular, Edit24Regular, EditRegular, Money24Regular, PersonVoice24Regular, PresenceAvailable24Filled, PresenceAvailableFilled, PresenceAway24Filled, PresenceAwayFilled } from '@fluentui/react-icons';


import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import "./Invoice.scss";
import { WHITE_HOUSE_API } from '../../../Constants/Constant';
import axios from 'axios';
import { InvoiceInterface } from '../../../Data/General/Invoice';


interface User {
  email: string,
  phoneNumber:string,
  token:string,

  // other properties...
}

interface User {
  email: string,
  phoneNumber:string,
  token:string,
}

interface Message {
  message: string,
  id:number,
}

type Adjuster = {
  firstname?:string;
  lastname?:string;
  phoneNumber?:string;
  userid?: number;
}
interface MRaiseInvoice {
  onOpen: () => void;
  user:User;
  message:Message;
  adjuster:Adjuster;
}

const Invoice:  React.FC<MRaiseInvoice> = ({onOpen, user, message, adjuster}) => {
  const [refreshKey, setRefreshKey] = useState(0);
  const [isRotated, setIsRotated] = useState(false);
  const [getInvoices, setGetInvoices] = useState<InvoiceInterface[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCateItem, setSelectedCatItem] = useState<number | null>(null);
  const [isHovered, setIsHovered] = useState<Record<number, boolean>>({});
  const handleRefresh = () => {
    setIsRotated(true);
    setRefreshKey(prevKey => prevKey + 1);
    setTimeout(() => {
      setIsRotated(false);
    }, 3000);
   
    //setIsRotated(true);
  };
  const handleClick = () => {
    setIsRotated(!isRotated);
  };

  const handleDeleteItem = async(id:any) => {
    try {
      const response = await axios.delete(`${WHITE_HOUSE_API}/BarLoungeMenu/${id}`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
      if(response.data.status === "Success"){
        message.message = response.data.eMessage;
        fetchInvoice();
      }
    } catch (error) {
      console.error(error);
    }
    }
      const handleUpdateitem = (u:any) => {
        onOpen();
        message.id = u;
    
      }
      let options: { weekday: 'long' | 'short' | 'narrow', hour: 'numeric' | '2-digit', minute: 'numeric' | '2-digit', hour12: boolean } = { 
        weekday: 'short', 
        hour: 'numeric', 
        minute: 'numeric', 
        hour12: true 
    };
    

      const fetchInvoice = useCallback(async () => {
        try {
          const response = await axios.get(`${WHITE_HOUSE_API}/Invoice`, {
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
          });
          setGetInvoices(response.data);
        } catch (error) {
          console.error(error);
        }
      }, [user.token]);
      
      useEffect(() => {
        fetchInvoice();
      }, [fetchInvoice, user.token]);
    
      const unpaidAmount = getInvoices.reduce((total, invoice) => {
        if (invoice.paymentStatus === "Not Paid" && invoice.amount !== undefined) {
            return total + invoice.amount;
        } else {
            return total;
        }
    }, 0);
    
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
  });
  
  const formattedAmount = formatter.format(unpaidAmount);
  
    return (
        <>
     <div className="ts-messages-header messages-header-v2">
        <div className="app-messages-header ts-section-divider">
          <div className="app-chat-header-content">
            <Toolbar aria-label="with Popover" size="small">
            <ToolbarButton onClick={handleRefresh} aria-label="update sells" icon={isRotated ? <Spinner size="tiny" />:<ArrowCounterclockwise24Regular  />}>
              Refresh
            </ToolbarButton>
            <ToolbarButton
            onClick={onOpen}
              aria-label="Add"
              icon={<Add24Regular />}>
              Create Invoice
            </ToolbarButton>
            <ToolbarButton
              icon={<ArrowDownload24Filled />}
              aria-label="download">
              Export to CSV
            </ToolbarButton>
            <ToolbarDivider />
            <ToolbarButton
              aria-label="edit"
              icon={<Edit24Regular  />}>
               Edit Invoice details
            </ToolbarButton>
            <ToolbarDivider />
            <ToolbarButton  icon={<PersonVoice24Regular  />}>Feed back</ToolbarButton>
            <Menu>
        <MenuTrigger disableButtonEnhancement>
          <MenuButton appearance="transparent" icon={<Money24Regular />}>
            Curency
          </MenuButton>
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            <MenuItem>&#36; USD</MenuItem>
            <MenuItem>&#8358; NGN</MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
          </Toolbar>
          </div>
          </div>
          </div>
          {/* home display */}
          <div className="ts-tab-content flex-fill">
        <div className="ts-embedded-container flex-fill hybrid-passthrough">
          <div className="content-child flex-fill">
            <div className="ts-embedded-container flex-fill hybrid-passthrough">
              <div className="content-child flex-fill">
                           <div className='ext-info-section fxc-base fxc-section fxc-section-wrapper'></div>
                           <div className="ext-search-pill-container">
                           <div className="azc-inputbox-wrapper azc-textBox-wrapper input-qty">
                           <Input 
                              placeholder='Search category' 
                              value={searchTerm}
                              onChange={event => setSearchTerm(event.target.value)}
                            />

                             </div>
                             <div className="ext-pill-control fxc-base">
                                     <div className="fxc-pill">
                                       <span className="fxc-pill-content fxc-pill-content-no-remove-button fxc-pill-read-mode">
                                       <span className="fxc-pill-inner-content" id="_weave_e_1183"><div className="fxc-pill-read-container"id="_weave_e_1179"><span className="fxc-pill-content-key" id="_weave_e_1180">Adjuster: </span><span className="fxc-pill-content-operator" id="_weave_e_1181">:</span><b className="fxc-pill-content-value" id="_weave_e_1182">{`${adjuster.lastname} ${adjuster.firstname}`}</b></div>
                                       </span>
                                       </span>
                                     </div>
                               </div>
                               <div className="ext-pill-control fxc-base">
                                     <div className="fxc-pill">
                                       <span className="fxc-pill-content fxc-pill-content-no-remove-button fxc-pill-read-mode">
                                       <span className="fxc-pill-inner-content" id="_weave_e_1183"><div className="fxc-pill-read-container"id="_weave_e_1179"><span className="fxc-pill-content-key" id="_weave_e_1180">Feed</span><span className="fxc-pill-content-operator" id="_weave_e_1181">:</span><b className="fxc-pill-content-value" id="_weave_e_1182">No feed back from adjuster</b></div>
                                       </span>
                                       </span>
                                     </div>
                               </div>
                              
                           </div>
                           <div className="ext-pre-sub-section wx-margin-bottom fxc-base fxc-section fxc-section-wrapper">
                                <div className="fxt-tabs-inner">
                                  <div className="ext-inline-flex">
                                    <div className="pcControl: metrics">
                                      <ul className="azc-metrics-container">
                                      <li>
                                      <div className="msportalfx-chart-color-3 azc-metrics-barColor">
                                          <svg focusable="false" role="presentation">
                                          <rect role="presentation" className="azc-metrics-bar-rectangle" width="5" data-bind="attr:{height:$ctl._metricSize}" height="35"></rect>
                                          </svg>
                                          </div>
                                            <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting" data-bind="azcSingleSetting:$data" data-control="true">
                                              <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Amount Due</div>
                                              <div className="azc-singleSetting-data"><div className="azc-singleSetting-value" data-bind="text: data.value">&#8358; {formattedAmount}</div>
                                              </div>
                                              <div className="azc-singleSetting-live fxs-hide-accessible-label"></div>
                                          </div>
                                      </li>
                                      <li>
                                      <div className="msportalfx-chart-color-0 azc-metrics-barColor"> 
                                        <svg focusable="false" role="presentation"><rect role="presentation" className="azc-metrics-bar-rectangle" width="5" height="35"></rect></svg>                                     
                                        </div>
                                          <div className="azc-metrics-singleSetting fxc-base azc-control azc-singleSetting">
                                            <div className="azc-singleSetting-caption msportalfx-text-ellipsis">Next invoice</div>
                                            <div className="azc-singleSetting-data">
                                              <div className="azc-singleSetting-value">24/12/2023</div>
                                              </div>
                                              <div className="azc-singleSetting-live fxs-hide-accessible-label" role="status">
                                            </div>
                                        </div>
                                    </li>
                                 
                                      </ul>
                                    </div>
                                   
                                  </div>
                                </div>
                              </div>
                          <div className="sbdocs-preview">
                           <div className="ext-azure-datagrid-container paddin-table ext-azure-subscription-grid-cell fxc-base fxc-gc-vscroll fxc-gc-hscroll fxc-gc-fixture fxs-vivaresize">
                             <div className="fxc-gc azc-fabric fxc-gc-dataGrid">
                               <div className="fxc-gc azc-fabric fxc-gc-dataGrid Overflow-exgrid customScrollBar">
                              <Table size="small" role="grid" aria-label="Table with paddin-table grid keyboard navigation">
                              <TableHeader>
                                  <TableRow>                                   
                                  <TableHeaderCell className="autoTableCell"><Checkbox />Invoice ID</TableHeaderCell>
                                  <TableHeaderCell className="autoTableCell">Invoice Date</TableHeaderCell>
                                      <TableHeaderCell className="autoTableCell">Billing Period</TableHeaderCell>
                                      <TableHeaderCell className="autoTableCell">Total Amount</TableHeaderCell>
                                      <TableHeaderCell className="autoTableCell">Status</TableHeaderCell>            
                                      <TableHeaderCell className="autoTableCell">Manage Invoice</TableHeaderCell>
                                  </TableRow>
                              </TableHeader>
                              <TableBody>
                              {getInvoices.filter(item => item.invoiceNumber?.toLowerCase().includes(searchTerm.toLowerCase())).map((item) => {
                                let dates = new Date(item.dueDate || "");
                                let formattedDate = dates.toLocaleString('en-US', options);
                               let invoiceDates = new Date(item.invoiceDate|| ""); 
                               let invoiceDueDates = new Date(item.dueDate|| ""); 
                                 let structuredinvoicedate = invoiceDates.toLocaleString('en-US', options);
                                 let formateAmount = '0.00';
                                 if (item.amount !== undefined) {
                                  const formatter = new Intl.NumberFormat('en-US', {
                                      style: 'decimal',
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                  });
                              
                                  formateAmount = formatter.format(item.amount);
                              }
                                 return(
                                <TableRow key={item.invoiceId}
                                   className={item.invoiceId === selectedCateItem ? 'cat-nhxbackground' : ''}
                                   onClick={() => setSelectedCatItem(prev => prev === item.invoiceId ? null : item.invoiceId)}>
                                  <TableCell className="autoTableCell fxc-gc-cell invoice-number"><Checkbox checked={item.invoiceId === selectedCateItem} /> #{item.invoiceNumber}</TableCell>
                                  <TableCell className="autoTableCell fxc-gc-cell">{invoiceDates.toLocaleDateString()}</TableCell>
                                  <TableCell className="autoTableCell fxc-gc-cell">{invoiceDates.toLocaleDateString()} -{invoiceDueDates.toLocaleDateString()}</TableCell>
                                  <TableCell className="autoTableCell fxc-gc-cell">&#8358; {formateAmount}</TableCell>
                                  <TableCell className="autoTableCell fxc-gc-cell">
                                    {item.paymentStatus === 'Paid' ? <PresenceAvailableFilled className='icon-green'/> : <PresenceAwayFilled className='icon-notpaid'/>}
                                    {item.paymentStatus}
                                  </TableCell>
                                <TableCell>
                                <TableCellLayout 
                                  className="Curspointer-event" 
                                  onMouseEnter={() => setIsHovered(prevState => ({ ...prevState, [item.invoiceId]: true }))}
                                  onMouseLeave={() => setIsHovered(prevState => ({ ...prevState, [item.invoiceId]: false }))}
                                >
                                  {isHovered[item.invoiceId] && 
                                    <>
                                      <DeleteRegular className="delete-icon-spacing" onClick={() => handleDeleteItem(item.invoiceId)} />
                                      <EditRegular className="delete-icon-spacing" onClick={() => handleUpdateitem(item.invoiceId)} />
                                    </>
                                  }
                                  {formattedDate ?? "No recent update"}
                                </TableCellLayout>


                              </TableCell>
                                </TableRow>
                              )})}
                              </TableBody>
                              </Table>
                               </div>
                             </div>
                           </div>
                           </div>
                         </div>
                   </div>
                 </div>
               </div>
           </div>
           
         
        </>
    )
}

export default Invoice;