import React, { ChangeEvent, FormEvent, useCallback, useContext, useEffect, useState } from 'react';
import { Button, Field, Input, InputOnChangeData, Label, Select } from '@fluentui/react-components';
import { AlignSpaceEvenlyHorizontal24Regular, AppRecent24Regular, ArrowAutofitWidth24Regular, ChevronLeft24Regular, WeatherRainShowersNight24Regular } from '@fluentui/react-icons';

import "./EventReservation.scss";

import {
    Dropdown,
    makeStyles,
    Option,
    shorthands,
    useId,
    Caption1,
    tokens,
    Text,
} from "@fluentui/react-components";
import { SearchBox } from '@fluentui/react-search-preview';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import axios from 'axios';
import { initializeIcons } from '@fluentui/react';
import { MoreHorizontal20Regular } from '@fluentui/react-icons';
import {
    Card,
    CardHeader,
    CardPreview,
    CardProps,
} from '@fluentui/react-components';
import Base_456Image from "../../Themes/Images/meetings_light.svg";
import { Gender } from '../../../Data/General/IGender';
import { WHITE_HOUSE_API, WHITE_HOUSE_S3 } from '../../../Constants/Constant';
import { BookingContext } from '../../Common/Context/BookContext';
import { SelectedRoomDetail } from '../../../Data/General/RoomDetails';
import Loaders from '../../Common/Loader/Loader';
import {Room} from '../../../Data/General/Room';
import { RoomDataListItem } from '../../../Data/General/RoomDetail';
import { reservation, IEventReservation } from '../../../Data/General/EventReservation';
import { HotelEventCenter } from '../../../Data/General/HotelEventCenter';
import DispatchToast from '../../Common/Toast/DispatchToast';
import { debounce } from 'lodash';
const resolveAsset = (asset: string) => {
    const ASSET_URL =
        `${WHITE_HOUSE_S3}`;

    return `${ASSET_URL}${asset}`;
};

const useStyles = makeStyles({
    main: {
        ...shorthands.gap('16px'),
        display: 'flex',
        flexWrap: 'wrap',
    },

    card: {
        width: '100%',
        maxWidth: '100%',
        minHeight: '250px',
        height: 'fit-content',
    },

    caption: {
        color: tokens.colorNeutralForeground3,
    },

    smallRadius: {
        ...shorthands.borderRadius(tokens.borderRadiusSmall),
    },

    grayBackground: {
        backgroundColor: tokens.colorNeutralBackground3,
    },


    control: {
        maxWidth: "300px",
    },
    wrapper: {
        columnGap: "15px",
        display: "flex",
        marginBottom: '10px',
    },
});


interface User {
    email: string,
    phoneNumber:string,
    token:string,
  
    // other properties...
  }
  interface SelectedEvent{
    number:any;
    name:any;
  }

interface Bookingguest {
    user:User;
    onClose: () => void;
    onPaymentOpen: (customerId: any, ServiceId:any) => void;
}

interface Titles {
    titleId: number;
    titleName: string;
}

const EventReservation: React.FC<Bookingguest> = ({user, onPaymentOpen, onClose}) => {
    const [availableEvent, setAvailableEvent] = useState<HotelEventCenter>();
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [isSearchActive, setisSearchActive] = useState(false);
    const [name, setName] = useState<string>('');
    const [results, setResults] = useState<HotelEventCenter[]>([]);
    const [reserverEvent, setReserveEvent] = useState<IEventReservation>(reservation);
    const [payment, setPayment] = useState<boolean>(false);
    const [query, setQuery] = useState('');
    const styles = useStyles();
    initializeIcons();
    const [dispatchToaster, setDispatchToaster] = useState<boolean>(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [inputValue, setInputValue] = useState('');


      const handleEventReservationInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setReserveEvent(prevState => ({ ...prevState, [name]: value }));
      };


    const fetchEventsById = useCallback(async (id:any) => {
        try {
            const response = await axios.get(`${WHITE_HOUSE_API}/EventServices/${id}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });
            setAvailableEvent(response.data);
            if(availableEvent){
              setReserveEvent((prevState) => ({ ...prevState, eventId: availableEvent?.eventCenterId }));
              setReserveEvent((prevState) => ({ ...prevState, eventName:  availableEvent?.eventCenterName}));
              setName(availableEvent?.eventCenterName);
              }
          } catch (error) {
            console.error('Error fetching roles:', error);
        }
    }, [availableEvent, user.token]);

   


    const handleDateInputChange =
    (name: string) => (e: Date | null | undefined) => {
      if (e) {
        setReserveEvent((prevState) => ({ ...prevState, [name]: e }));
      }
    };

    
    function formatDate(date:any) {
        const options = { weekday: 'short' as const, year: 'numeric' as const, day: 'numeric' as const };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }
    const returnBack = ()=> {
       
        }

    const search = async (query: any) => {
       
    };

    const fetchResults = async (query:any) => {
      setResults([]);
      try {
          const response = await axios.get(
              `${WHITE_HOUSE_API}/EventServices/Query?query=${query}`,
              {
                  params: { query },
                  headers: {
                      'Authorization': `Bearer ${user.token}`
                  }
              }
          );
          console.log("response data: ", response.data);
          setResults(response.data);
          
      } catch (error) {
          console.error(error);
      }
  };
  
  const debouncedFetchResults = debounce(fetchResults, 500);
  
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const que = event.target.value;
    console.log("search term", que);
    setisSearchActive(que !== '');
    setInputValue(que);
  
    // Call fetchResults if the input value has at least 2 characters
    if (que.length >= 2) {
      debouncedFetchResults(que);
  }
  };
  
  
            const handleItemClick =(id:any) => {
              fetchEventsById(id);
              setisSearchActive(false);
              setQuery('');

              }

              const ReserveEvent = async () => {
                try {
                   setIsLoader(true);
                   const response = await axios.post(
                     `${WHITE_HOUSE_API}/EventReservation`,
                     reserverEvent,
                     {
                       headers: {
                         'Authorization': `Bearer ${user.token}`
                       }
                     }
                   );
                     if (response.data.status === 'Success') {
                       setResponseMessage(response.data.eMessage);
                       onPaymentOpen(response.data.eCustomerid, response.data.eServiceId)
                       setDispatchToaster(true);
                       setTimeout(() => {
                        setIsLoader(false);
                         setDispatchToaster(false);
                       
                       }, 1500);
                       setPayment(true);
                       onClose();
                     }
                 } catch (error) {
                   setResponseMessage('Registration ' + error);
                   console.error('Error fetching roles:', error);
                 }
               };
               useEffect(() => {   
                console.log("what is the value: ", name);       
               }, [availableEvent, availableEvent?.eventCenterId, availableEvent?.eventCenterName, debouncedFetchResults, inputValue, name]);
           

    return (
<>
        <div className="ms-Fabric ms-Layer-content content-46">
        <div className="wc-no outline">
          <div className="ms-Modal is-open root-39">
            <div aria-hidden="true" className="ms-Overlay ms-Overlay--dark root-48"></div>
            <div id="cilsco-focuszone" className="ms-Dialog-main createListModalMain_979b2e47 main-40">
              <div aria-hidden="true" id="Ariel-fixed" data-is-visible="true"
                data-is-focus-trap-zone-bumper="true">
              </div>
              <div className="ms-Modal-scrollableContent scrollableContent-54 customScrollBar">
                <div className="Merging-34">
                  <div className="createList-root templatePreviewPage">
                    <div className="templatePreview">
                      <div className="createList-pageRoot templatePreviewContainer">
                        {isLoader && (
                        <Loaders />
                        )}
                         {dispatchToaster && <DispatchToast message={responseMessage} />}
                        <div className="createList-header">
                          <div className="templateTitleWithLogo">
                            <div className="templateLogo-container sp-List-color6">
                              <span className="WRoomNumbers">
                                {name.substring(0, 2).toUpperCase() || 'EC'}
                              </span>
                            </div>
                            <h1 className="createList-headerTitle">
                             {name || 'EVENT CENTER'}
                            </h1>
                          </div>
                          <div className={`ui-box Wsearch-ev-ew wc-check-search c-search_modal__wrapper
                            popover-little ${ isSearchActive ? 'mouse-over-active' : '' }`}>
                            <SearchBox                        
                            appearance="filled-lighter" 
                            placeholder="Search Event .." 
                            value={inputValue}
                            onChange={handleInputChange}
                            onBlur={search} className="sear-padd" />
                            <div className={`c-search_autocomplete__container
                              c-search_autocomplete__container--query-ordered ${ isSearchActive
                              ? 'serachbar-active' : '' }`}>
                              <div className="c-search_autocomplete c-search_autocomplete--nav-attached">
                                <div className="offscreen">
                                  <span id="search-autocomplete-query-label-prefix-node">
                                    Search
                                  </span>
                                </div>
                                {results.map((envt) => {
                                let availabilityText = envt.bookingStatus
                                ? 'Available'
                                : 'Not Available';
                                let fullname = `${envt.eventCenterName}`;
                                let initials = fullname.substring(0, 2).toUpperCase();
                               
                                return (
                                <div key={envt.eventCenterId}
                                  className="c-search_autocomplete__suggestion_list c-search_autocomplete__suggestion_list--taller event-reservation"
                                  onClick={()=> handleItemClick(envt.eventCenterId)}
                                  >
                                  <div className="curtain-a wform-css-283-right wc-ciHHEB">
                                    <div className="fui-Persona rlroi9i">
                                      <span role="img" id="avatar-rd"
                                        className="fui-Avatar r81b29z fui-Persona__avatar ___1c989qy_1o9k6tz f13ar0e0 fid048z f1hek2iy f9h729m f18q9vkd f103ycu4"
                                        aria-label="Kevin Sturgis"
                                        aria-labelledby="avatar-rd avatar-rd__badge">
                                        {/* <AvatarGroup>
                                          <AvatarGroupItem name={fullname} />
                                        </AvatarGroup> */}
                              
                                        <div className="fui-Persona rlroi9i"><span role="img" id="avatar-rd"
                                            className="fui-Avatar r81b29z fui-Persona__avatar ___1c989qy_1o9k6tz f13ar0e0 fid048z f1hek2iy f9h729m f18q9vkd f103ycu4"
                                            aria-label="Kevin Sturgis"
                                            aria-labelledby="avatar-rd avatar-rd__badge">
                                            <div role="group"
                                              className="fui-AvatarGroup ___19hqz8g_10mhep2 ftuwxu6 f10pi13n">
                                              <div
                                                className="fui-AvatarGroupItem ___liu8hg0_1wjcx8a f122n59 ftuwxu6 fi64zpg f10pi13n f8fbkgy f1nfllo7 f1djnp8u f1s8kh49 f1l333zn f1szoe96 f1d2rq10">
                                                <span role="img" id="avatar-r6h"
                                                  className="fui-Avatar r81b29z fui-AvatarGroupItem__avatar ___mgf8q40_1ccxybz f1euv43f"
                                                  aria-label=""><span id="avatar-r6h__initials"
                                                    className="fui-Avatar__initials rip04v ___1jrfxqc_4vgnv00 f135dsb4 f6hvv1p">{initials}</span></span>
                                              </div>
                                            </div>
                                          </span><span
                                            className="fui-Persona__primaryText rvj41k9">{envt.eventCenterName}</span><span
                                            className="fui-Persona__secondaryText rp1pf9e ___19lp2a0_1p5a1yj f1ryq6si">
                                            {availabilityText}</span></div>
                                      </span>
                                    </div>
                                
                                  </div>
                                </div>
                                );
                                })}
                              </div>
                            </div>
                          </div>                       
                          <div className="createList-closeBtnContainer">
                            <Button icon={<i className="fi fi-sr-cross-small" />}
                            onClick={onClose}
                            ></Button>
                          </div>
                        </div>
                        <div className="createList-pageContent includeBottomPadding customScrollBar">
            <div className="previewContainer  wc-add-paddin">
                <form action="" className='Form-content'>
                    <div className="wform-MyContentControl-module__edgeworth-stack-item-container-refresh__3mUvv wform-css-283 ">
                        <div className="ui-divider  ua-gi-ub-uc">

                            <div className="wc-form-wrapper">                  
                               
                                <div className="w-add-Guest-room Guest-management-job">
                                    <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">First Name</div>
                                        <div className="firstName-container">
                                            <Input placeholder='First Name'
                                             appearance="filled-darker"
                                              id="firstName"
                                              name="firstName"
                                              onChange={handleEventReservationInputChange} />
                                        </div>
                                    </div>
                                    <div className="w-add-room-guest rms-block-right">
                                        <div className="w-add-room-guest rms-inline-property-title">Last Name</div>
                                        <div className="firstName-container">
                                            <Input appearance="filled-darker"
                                             placeholder='Last Name'
                                              id="lastName"
                                              name="lastName"
                                              onChange={handleEventReservationInputChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-add-Guest-room Guest-management-job">
                                    <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">Number of Attendees</div>
                                        <div className="firstName-container">
                                            <Input placeholder='Number Of Attendees'
                                             appearance="filled-darker"
                                              id="numberOfAttendees"
                                              name="numberOfAttendees"
                                              onChange={handleEventReservationInputChange} />
                                        </div>
                                    </div>
                                    <div className="w-add-room-guest rms-block-right">
                                        <div className="w-add-room-guest rms-inline-property-title">Email</div>
                                        <div className="firstName-container">
                                            <Input appearance="filled-darker"
                                             placeholder='Email' 
                                             id="emailAddress"
                                             name="emailAddress"
                                             onChange={handleEventReservationInputChange} />
                                        </div>
                                    </div>
                                </div>

                                <div className="w-add-Guest-room Guest-management-job">
                                    <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">Address</div>
                                        <div className="firstName-container">
                                            <Input placeholder='Address'
                                             appearance="filled-darker"
                                              id="address"
                                              name="address"
                                              onChange={handleEventReservationInputChange} />
                                        </div>
                                    </div>
                                    <div className="w-add-room-guest rms-block-right">
                                        <div className="w-add-room-guest rms-inline-property-title">Phone Number</div>
                                        <div className="firstName-container">
                                        <Input placeholder='Phone Number' 
                                            appearance="filled-darker"
                                             id="phoneNumber"
                                             name="phoneNumber"
                                             onChange={handleEventReservationInputChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-add-Guest-room Guest-management-job">
                                    <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">Room/Space Preferences</div>
                                        <div className="firstName-container">
                                            <Input placeholder='Preferred Room/Space'
                                             appearance="filled-darker"
                                              id="preferredRoom"
                                              name="preferredRoom"
                                              onChange={handleEventReservationInputChange} />
                                        </div>
                                    </div>
                                    <div className="w-add-room-guest rms-block-right">
                                        <div className="w-add-room-guest rms-inline-property-title">Room Type Reference</div>
                                        <div className="firstName-container">
                                            <Input appearance="filled-darker"
                                             placeholder='Number of Hotel Rooms Needed'
                                              id="roomTypePreferences"
                                              name="roomTypePreferences"
                                              onChange={handleEventReservationInputChange}/>
                                        </div>
                                    </div>
                                </div> 
                                <div className="w-add-Guest-room Guest-management-job">
                                    <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">Number of Rooms</div>
                                        <div className="firstName-container">
                                        <Input placeholder='Number of Rooms/Spaces Needed'
                                        type='number'
                                             appearance="filled-darker"
                                              id="numberOfRooms"
                                              name="numberOfRooms"
                                              onChange={handleEventReservationInputChange} />
                                        </div>
                                    </div>

                                    <div className="w-add-room-guest rms-block-right">
                                        <div className="w-add-room-guest rms-inline-property-title">Catering Requirements</div>
                                        <div className="firstName-container">
                                        <Input placeholder='Catering Requirements'
                                             appearance="filled-darker"
                                              id="cateringRequirements"
                                              name="cateringRequirements"
                                              onChange={handleEventReservationInputChange} />
                                      
                                        </div>
                                    </div>
                                </div>
                                  <div className="w-add-Guest-room Guest-management-job">
                                    <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">Audiovisual Equipment</div>
                                        <div className="firstName-container">
                                            <Input placeholder='Equipment Needs (e.g., projector, sound system)' 
                                            appearance="filled-darker"
                                             id="equipmentNeeds"
                                             name="equipmentNeeds"
                                             onChange={handleEventReservationInputChange} />
                                        </div>
                                    </div>
                                    <div className="w-add-room-guest rms-block-right">
                                        <div className="w-add-room-guest rms-inline-property-title">Spacial Requests</div>
                                        <div className="firstName-container">
                                            <Input
                                            appearance="filled-darker"
                                             placeholder='Any additional requests ' 
                                             id="specialRequests"
                                             name="specialRequests"
                                             onChange={handleEventReservationInputChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-add-Guest-room Guest-management-job">
                                    <div className="w-add-room-guest rms-block-left">
                                        <div className="w-add-room-guest Guest-inline-property-title">Start Date</div>
                                        <div className="firstName-container">
                                            <Field>
                                                <DatePicker
                                                    name="startDate"
                                                    showWeekNumbers={true}
                                                    firstWeekOfYear={1}
                                                    showMonthPickerAsOverlay={true}
                                                    placeholder="Event start Date..."
                                                    className={styles.control}
                                                    onSelectDate={(date) => handleDateInputChange('startDate')(date)}   
                                                  
                                                />
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="w-add-room-guest rms-block-right">
                                        <div className="w-add-room-guest rms-inline-property-title">End Date</div>
                                        <div className="firstName-container">
                                            <Field>
                                                <DatePicker
                                                    name="endDate"
                                                    showWeekNumbers={true}
                                                    firstWeekOfYear={1}
                                                    showMonthPickerAsOverlay={true}
                                                    placeholder="Event End out..."
                                                    className={styles.control}
                                                    onSelectDate={(date) => handleDateInputChange('endDate')(date)}   
                                                />
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>

                    </div>
                    <div className="wform-MyContentControl-module__edgeworth-stack-item-container-refresh__3mUvv wform-css-283-right">
                    <div className="sbdocs-preview wc-room-details">
                       <div className="xt-azure-datagrid-container REX-Guest paddin-table ext-azure-subscription-grid-cell fxc-base fxc-gc-vscroll fxc-gc-hscroll fxc-gc-fixture fxs-vivaresize">
                        <Card className={styles.card}>
                            <CardPreview
                                className={styles.grayBackground}
                                logo={
                                    <img
                                        className='wc-imanage-image'
                                        // src={resolveAsset('9015_whitehouseimage?AWSAccessKeyId=AKIAWD6U5GWRA5SIAJWA&Expires=1724692914&Signature=BNspr%2FdClwQiZFUsMbUc0r6reZs%3D')}
                                        src={availableEvent?.photos || Base_456Image}
                                        alt="event"
                                        width="600px"
                                        height="300px" />
                                }
                            >
                            </CardPreview>
                        </Card>
                        <div className="grid-container">
                        <div className="grid-item"><strong>Hall ID:</strong>{availableEvent?.eventCenterId}</div>
                        <div className="grid-item"><strong>Hall Name:</strong>{availableEvent?.eventCenterName}</div>
                        <div className="grid-item"><strong>Hall Capacity:</strong>{availableEvent?.capacity}</div>
                        <div className="grid-item"><strong>Description:</strong>{availableEvent?.description}</div>
                        <div className="grid-item"><strong>Price:</strong>{availableEvent?.basePrice.toLocaleString()}</div>
                        <div className="grid-item"><strong>Payment Currency:</strong>{availableEvent?.currency}</div>
                        <div className="grid-item"><strong>Contact Person:</strong>{availableEvent?.contactFirstName} {availableEvent?.contactLastName}</div>
                        <div className="grid-item"><strong>Contact Email:</strong>{availableEvent?.contactEmail}</div>
                        <div className="grid-item"><strong>Contact Phone:</strong>{availableEvent?.contactPhone}</div>

                    </div>

                        </div>
                        </div>
                    </div>
                </form>
            </div >

            </div>
                      </div>
                    </div>
                    <div className="createList-pageFooter padd-tops wsc-wiesle-slide">
                      <div className="createList-leftActions margin-both-side">
                        <Button data-automationid="createListGoBackButton" className=""
                          icon={<ChevronLeft24Regular />}
                        onClick={returnBack}
                        >
                        Back
                        </Button>
                      </div>
                      <div className="createList-rightActions margin-rights">
                        <Button className="wc-first-root-68" onClick={ReserveEvent}>
                          Submit
                        </Button>
                        <Button className="" onClick={onClose}>
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


        
            
        </>

    )
}


export default EventReservation;

