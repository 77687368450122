import React, { useEffect, useState, useRef } from 'react';

interface TypewriterProps {
  text: string[];
}

const Typewriter: React.FC<TypewriterProps> = ({ text }) => {
  const [typewriterText, setTypewriterText] = useState('');
  const [textIndex, setTextIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const typing = setInterval(() => {
      if (charIndex < text[textIndex].length) {
        setTypewriterText((prevText) => prevText + text[textIndex].charAt(charIndex));
        setCharIndex((prevCharIndex) => prevCharIndex + 1);
      } else {
        setTextIndex((prevIndex) => (prevIndex + 1) % text.length);
        setTypewriterText('');
        setCharIndex(0);
      }
    }, 150); // Adjust speed here

    return () => clearInterval(typing);
  }, [text, textIndex, charIndex]);

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleBlur = () => {
    if (inputRef.current) {
      inputRef.current.value = typewriterText;
    }
  };

  return (
    <input
      className="ts-header-search  commandbox-string-experiment" 
      ref={inputRef}
      type="text"
      value={typewriterText}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  );
};


export default Typewriter;
