export const SeriesData: {
    name: string;
    data: [number, number][];
}[] = [
        {
            name: "Kelvin",
            data: [
                [1672530000000, 30.95], [1672616400000, 31.34], [1672702800000, 31.18], [1672789200000, 31.05],
                [1673048400000, 31.00], [1673134800000, 30.95], [1673221200000, 31.24], [1673307600000, 31.29], [1673394000000, 31.85],
                [1673653200000, 31.86], [1673739600000, 32.28], [1673826000000, 32.10], [1673912400000, 32.65], [1673998800000, 32.21],
                [1674258000000, 32.35], [1674344400000, 32.44], [1674430800000, 32.46], [1674517200000, 32.86], [1674603600000, 32.75],
                [1674959200000, 32.54], [1675045600000, 32.33], [1675132000000, 32.97], [1675218400000, 33.41], [1675477600000, 33.27],
                [1675564000000, 33.27], [1675650400000, 32.89], [1675736800000, 33.10], [1675823200000, 33.73], [1676082400000, 33.22],
                [1676168800000, 31.99], [1676255200000, 32.41], [1676341600000, 33.05], [1676428000000, 33.64], [1676687200000, 33.56],
                [1676773600000, 34.22], [1676860000000, 33.77], [1676946400000, 34.17], [1677032800000, 33.82], [1677292000000, 34.51],
                [1677378400000, 33.16], [1677464800000, 33.56], [1677551200000, 33.71], [1677637600000, 33.81], [1677896800000, 34.40],
                [1677983200000, 34.63], [1678069600000, 34.46], [1678156000000, 34.48], [1678242400000, 34.31], [1678501600000, 34.70],
                [1678588000000, 34.31], [1678674400000, 33.46], [1678760800000, 33.59], [1679113200000, 33.22], [1679199600000, 32.61],
                [1679286000000, 33.01], [1679372400000, 33.55], [1679458800000, 33.18], [1679718000000, 32.84], [1679804400000, 33.84],
                [1679890800000, 33.39], [1679977200000, 32.91], [1680063600000, 33.06], [1680322800000, 32.62], [1680409200000, 32.40],
                [1680495600000, 33.13], [1680582000000, 33.26], [1680668400000, 33.58], [1680927600000, 33.55], [1681014000000, 33.77],
                [1681100400000, 33.76], [1681186800000, 33.32], [1681273200000, 32.61], [1681532400000, 32.52], [1681618800000, 32.67],
                [1681705200000, 32.52], [1681791600000, 31.92], [1681878000000, 32.20], [1682137200000, 32.23], [1682223600000, 32.33],
                [1682310000000, 32.36], [1682396400000, 32.01], [1682482800000, 31.31], [1682742000000, 32.01], [1682828400000, 32.01],
                [1682914800000, 32.18], [1683001200000, 31.54], [1683087600000, 31.60], [1683433200000, 32.05], [1683519600000, 31.29],
                [1683606000000, 31.05], [1683692400000, 29.82], [1683951600000, 30.31], [1684038000000, 30.70], [1684124400000, 31.69],
                [1684210800000, 31.32], [1684297200000, 31.65], [1684556400000, 31.13], [1684642800000, 31.77], [1684729200000, 31.79],
                [1684815600000, 31.67], [1684902000000, 32.39], [1685161200000, 32.63], [1685247600000, 32.89], [1685334000000, 31.99],
                [1685420400000, 31.23], [1685506800000, 31.57], [1685766000000, 30.84], [1685852400000, 31.07], [1685938800000, 31.41],
                [1686025200000, 31.17], [1686111600000, 32.37], [1686370800000, 32.19], [1686457200000, 32.51], [1686630000000, 32.53],
                [1686716400000, 31.37], [1686975600000, 30.43], [1687062000000, 30.44], [1687148400000, 30.20], [1687234800000, 30.14],
                [1687321200000, 30.65], [1687580400000, 30.40], [1687666800000, 30.65], [1687753200000, 31.43], [1687839600000, 31.89],
                [1687926000000, 31.38], [1688185200000, 30.64], [1688271600000, 30.02], [1688358000000, 30.33], [1688444400000, 30.95],
                [1688530800000, 31.89], [1688790000000, 31.01], [1688876400000, 30.88], [1688962800000, 30.69], [1689049200000, 30.58],
                [1689135600000, 32.02], [1689394800000, 32.14], [1689481200000, 32.37], [1689567600000, 32.51], [1689654000000, 32.65],
                [1689740400000, 32.64], [1690003200000, 32.27], [1690089600000, 32.10], [1690176000000, 32.91], [1690262400000, 33.65],
                [1690348800000, 33.80], [1690608000000, 33.92], [1690694400000, 33.75], [1690780800000, 33.84], [1690867200000, 33.50],
                [1690953600000, 32.26], [1691212800000, 32.32], [1691299200000, 32.06], [1691385600000, 31.96], [1691472000000, 31.46],
                [1691558400000, 31.27], [1691904000000, 31.43], [1691990400000, 32.26], [1692076800000, 32.79], [1692163200000, 32.46],
                [1692422400000, 32.13], [1692508800000, 32.43], [1692595200000, 32.42], [1692681600000, 32.81], [1692768000000, 33.34],
                [1693027200000, 33.41], [1693113600000, 32.57], [1693200000000, 33.12], [1693286400000, 34.53], [1693372800000, 33.83],
                [1693632000000, 33.41], [1693718400000, 32.90], [1693804800000, 32.53], [1693891200000, 32.80], [1693977600000, 32.44],
                [1694236800000, 32.62], [1694323200000, 32.57], [1694409600000, 32.60], [1694496000000, 32.68], [1694582400000, 32.47],
                [1694841600000, 32.23], [1694928000000, 31.68], [1695014400000, 31.51], [1695100800000, 31.78], [1695187200000, 31.94],
                [1695446400000, 32.33], [1695532800000, 33.24], [1695619200000, 33.44], [1695705600000, 33.48], [1695792000000, 33.24],
                [1696051200000, 33.49], [1696137600000, 33.31], [1696224000000, 33.36], [1696310400000, 33.40], [1696396800000, 34.01],
                [1696832400000, 34.02], [1696918800000, 34.36], [1697005200000, 34.39], [1697264400000, 34.24], [1697350800000, 34.39],
                [1697437200000, 33.47], [1697523600000, 32.98], [1697610000000, 32.90], [1697869200000, 32.70], [1697955600000, 32.54],
                [1698042000000, 32.23], [1698128400000, 32.64], [1698214800000, 32.65], [1698474000000, 32.92], [1698560400000, 32.64],
                [1698646800000, 32.84], [1698826800000, 33.40], [1699086000000, 33.30], [1699172400000, 33.18], [1699258800000, 33.88],
                [1699345200000, 34.09], [1699431600000, 34.61], [1699690800000, 34.70], [1699777200000, 35.30], [1699863600000, 35.40],
                [1699950000000, 35.14], [1700036400000, 35.48], [1700295600000, 35.75], [1700382000000, 35.54], [1700468400000, 35.96],
                [1700554800000, 35.53], [1700641200000, 37.56], [1700900400000, 37.42], [1700986800000, 37.49], [1701073200000, 38.09],
                [1701159600000, 37.87], [1701246000000, 37.71], [1701505200000, 37.53], [1701678000000, 37.55], [1701764400000, 37.30],
                [1701850800000, 36.90], [1702110000000, 37.68], [1702196400000, 38.34], [1702282800000, 37.75], [1702369200000, 38.13],
                [1702628400000, 37.94], [1702714800000, 38.14], [1702801200000, 38.66], [1702887600000, 38.62], [1702974000000, 38.09],
                [1703233200000, 38.16], [1703319600000, 38.15], [1703406000000, 37.88], [1703492400000, 37.73], [1703578800000, 37.98],
                [1703924400000, 37.95], [1704010800000, 38.25], [1704097200000, 38.10], [1704183600000, 38.32], [1704442800000, 38.24],
                [1704529200000, 38.52], [1704615600000, 37.94], [1704702000000, 37.83], [1704788400000, 38.34], [1705047600000, 38.10],
                [1705134000000, 38.51], [1705220400000, 38.40], [1705306800000, 38.07], [1705393200000, 39.12], [1705652400000, 38.64],
                [1705738800000, 38.89], [1705825200000, 38.81], [1705911600000, 38.61], [1705998000000, 38.63], [1706343600000, 38.99],
                [1706430000000, 38.77], [1706516400000, 38.34], [1706602800000, 38.55], [1706862000000, 38.11], [1706948400000, 38.59],
                [1707034800000, 39.60]
            ]

        }]



export const RoomChatData: {
    name: string;
    data: [number, number][];
}[] = [
        {
            name: "Rooms",
            data: [
                [1672530000000, 30.95], [1672616400000, 31.34], [1672702800000, 31.18], [1672789200000, 31.05],
                [1673048400000, 31.00], [1673134800000, 30.95], [1673221200000, 31.24], [1673307600000, 31.29], [1673394000000, 31.85],
                [1673653200000, 31.86], [1673739600000, 32.28], [1673826000000, 32.10], [1673912400000, 32.65], [1673998800000, 32.21],
                [1674258000000, 32.35], [1674344400000, 32.44], [1674430800000, 32.46], [1674517200000, 32.86], [1674603600000, 32.75],
                [1674959200000, 32.54], [1675045600000, 32.33], [1675132000000, 32.97], [1675218400000, 33.41], [1675477600000, 33.27],
                [1675564000000, 33.27], [1675650400000, 32.89], [1675736800000, 33.10], [1675823200000, 33.73], [1676082400000, 33.22],
                [1676168800000, 31.99], [1676255200000, 32.41], [1676341600000, 33.05], [1676428000000, 33.64], [1676687200000, 33.56],
                [1676773600000, 34.22], [1676860000000, 33.77], [1676946400000, 34.17], [1677032800000, 33.82], [1677292000000, 34.51],
                [1677378400000, 33.16], [1677464800000, 33.56], [1677551200000, 33.71], [1677637600000, 33.81], [1677896800000, 34.40],
                [1677983200000, 34.63], [1678069600000, 34.46], [1678156000000, 34.48], [1678242400000, 34.31], [1678501600000, 34.70],
                [1678588000000, 34.31], [1678674400000, 33.46], [1678760800000, 33.59], [1679113200000, 33.22], [1679199600000, 32.61],
                [1679286000000, 33.01], [1679372400000, 33.55], [1679458800000, 33.18], [1679718000000, 32.84], [1679804400000, 33.84],
                [1679890800000, 33.39], [1679977200000, 32.91], [1680063600000, 33.06], [1680322800000, 32.62], [1680409200000, 32.40],
                [1680495600000, 33.13], [1680582000000, 33.26], [1680668400000, 33.58], [1680927600000, 33.55], [1681014000000, 33.77],
                [1681100400000, 33.76], [1681186800000, 33.32], [1681273200000, 32.61], [1681532400000, 32.52], [1681618800000, 32.67],
                [1681705200000, 32.52], [1681791600000, 31.92], [1681878000000, 32.20], [1682137200000, 32.23], [1682223600000, 32.33],
                [1682310000000, 32.36], [1682396400000, 32.01], [1682482800000, 31.31], [1682742000000, 32.01], [1682828400000, 32.01],
                [1682914800000, 32.18], [1683001200000, 31.54], [1683087600000, 31.60], [1683433200000, 32.05], [1683519600000, 31.29],
                [1683606000000, 31.05], [1683692400000, 29.82], [1683951600000, 30.31], [1684038000000, 30.70], [1684124400000, 31.69],
                [1684210800000, 31.32], [1684297200000, 31.65], [1684556400000, 31.13], [1684642800000, 31.77], [1684729200000, 31.79],
                [1684815600000, 31.67], [1684902000000, 32.39], [1685161200000, 32.63], [1685247600000, 32.89], [1685334000000, 31.99],
                [1685420400000, 31.23], [1685506800000, 31.57], [1685766000000, 30.84], [1685852400000, 31.07], [1685938800000, 31.41],
                [1686025200000, 31.17], [1686111600000, 32.37], [1686370800000, 32.19], [1686457200000, 32.51], [1686630000000, 32.53],
                [1686716400000, 31.37], [1686975600000, 30.43], [1687062000000, 30.44], [1687148400000, 30.20], [1687234800000, 30.14],
                [1687321200000, 30.65], [1687580400000, 30.40], [1687666800000, 30.65], [1687753200000, 31.43], [1687839600000, 31.89],
                [1687926000000, 31.38], [1688185200000, 30.64], [1688271600000, 30.02], [1688358000000, 30.33], [1688444400000, 30.95],
                [1688530800000, 31.89], [1688790000000, 31.01], [1688876400000, 30.88], [1688962800000, 30.69], [1689049200000, 30.58],
                [1689135600000, 32.02], [1689394800000, 32.14], [1689481200000, 32.37], [1689567600000, 32.51], [1689654000000, 32.65],
                [1689740400000, 32.64], [1690003200000, 32.27], [1690089600000, 32.10], [1690176000000, 32.91], [1690262400000, 33.65],
                [1690348800000, 33.80], [1690608000000, 33.92], [1690694400000, 33.75], [1690780800000, 33.84], [1690867200000, 33.50],
                [1690953600000, 32.26], [1691212800000, 32.32], [1691299200000, 32.06], [1691385600000, 31.96], [1691472000000, 31.46],
                [1691558400000, 31.27], [1691904000000, 31.43], [1691990400000, 32.26], [1692076800000, 32.79], [1692163200000, 32.46],
                [1692422400000, 32.13], [1692508800000, 32.43], [1692595200000, 32.42], [1692681600000, 32.81], [1692768000000, 33.34],
                [1693027200000, 33.41], [1693113600000, 32.57], [1693200000000, 33.12], [1693286400000, 34.53], [1693372800000, 33.83],
                [1693632000000, 33.41], [1693718400000, 32.90], [1693804800000, 32.53], [1693891200000, 32.80], [1693977600000, 32.44],
                [1694236800000, 32.62], [1694323200000, 32.57], [1694409600000, 32.60], [1694496000000, 32.68], [1694582400000, 32.47],
                [1694841600000, 32.23], [1694928000000, 31.68], [1695014400000, 31.51], [1695100800000, 31.78], [1695187200000, 31.94],
                [1695446400000, 32.33], [1695532800000, 33.24], [1695619200000, 33.44], [1695705600000, 33.48], [1695792000000, 33.24],
                [1696051200000, 33.49], [1696137600000, 33.31], [1696224000000, 33.36], [1696310400000, 33.40], [1696396800000, 34.01],
                [1696832400000, 34.02], [1696918800000, 34.36], [1697005200000, 34.39], [1697264400000, 34.24], [1697350800000, 34.39],
                [1697437200000, 33.47], [1697523600000, 32.98], [1697610000000, 32.90], [1697869200000, 32.70], [1697955600000, 32.54],
                [1698042000000, 32.23], [1698128400000, 32.64], [1698214800000, 32.65], [1698474000000, 32.92], [1698560400000, 32.64],
                [1698646800000, 32.84], [1698826800000, 33.40], [1699086000000, 33.30], [1699172400000, 33.18], [1699258800000, 33.88],
                [1699345200000, 34.09], [1699431600000, 34.61], [1699690800000, 34.70], [1699777200000, 35.30], [1699863600000, 35.40],
                [1699950000000, 35.14], [1700036400000, 35.48], [1700295600000, 35.75], [1700382000000, 35.54], [1700468400000, 35.96],
                [1700554800000, 35.53], [1700641200000, 37.56], [1700900400000, 37.42], [1700986800000, 37.49], [1701073200000, 38.09],
                [1701159600000, 37.87], [1701246000000, 37.71], [1701505200000, 37.53], [1701678000000, 37.55], [1701764400000, 37.30],
                [1701850800000, 36.90], [1702110000000, 37.68], [1702196400000, 38.34], [1702282800000, 37.75], [1702369200000, 38.13],
                [1702628400000, 37.94], [1702714800000, 38.14], [1702801200000, 38.66], [1702887600000, 38.62], [1702974000000, 38.09],
                [1703233200000, 38.16], [1703319600000, 38.15], [1703406000000, 37.88], [1703492400000, 37.73], [1703578800000, 37.98],
                [1703924400000, 37.95], [1704010800000, 38.25], [1704097200000, 38.10], [1704183600000, 38.32], [1704442800000, 38.24],
                [1704529200000, 38.52], [1704615600000, 37.94], [1704702000000, 37.83], [1704788400000, 38.34], [1705047600000, 38.10],
                [1705134000000, 38.51], [1705220400000, 38.40], [1705306800000, 38.07], [1705393200000, 39.12], [1705652400000, 38.64],
                [1705738800000, 38.89], [1705825200000, 38.81], [1705911600000, 38.61], [1705998000000, 38.63], [1706343600000, 38.99],
                [1706430000000, 38.77], [1706516400000, 38.34], [1706602800000, 38.55], [1706862000000, 38.11], [1706948400000, 38.59],
                [1707034800000, 39.60]
            ]

        }]
