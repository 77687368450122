import * as React from "react";
import { DismissRegular } from "@fluentui/react-icons";
import {
  MessageBar,
  MessageBarActions,
  MessageBarTitle,
  MessageBarBody,
  Button,
} from "@fluentui/react-components";

interface ErrorMessageProps {
  errorTitle: string;
  errorMessages: string;
  closeFunction: () => void;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ errorTitle, errorMessages, closeFunction }) => {
  return (
    <MessageBar intent="error">
      <MessageBarBody style={{color: "#444", fontWeight: "100", fontFamily: "Segeo UI", fontSize: "12px"}}>
        <MessageBarTitle style={{color: "#000", fontWeight: "600", fontFamily: "Segeo UI"}}>{errorTitle}</MessageBarTitle>
        {errorMessages}
      </MessageBarBody>
      <MessageBarActions
        containerAction={
          <Button
            aria-label="dismiss"
            appearance="transparent"
            icon={<DismissRegular />}
            onClick={closeFunction}
          />
        }
      >
      </MessageBarActions>
    </MessageBar>
  );
};
