import {
  CatchUp24Regular,
  Share24Regular,
  PeopleMoney24Regular,
  Payment24Filled,
  CreditCardClock24Filled,
} from '@fluentui/react-icons';
import React, { useCallback, useEffect, useState } from 'react';
import './AccountHeader.scss';
import MessageIcon from '../../Themes/Images/MessageIcon.svg';
import {
  FolderRegular,
  EditRegular,
  OpenRegular,
  DocumentRegular,
  PeopleRegular,
  DocumentPdfRegular,
  VideoRegular,
  BuildingBank24Filled,
  DataBarVertical24Filled,
} from '@fluentui/react-icons';
import {
  PresenceBadgeStatus,
  Avatar,
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  TableSelectionCell,
  useTableFeatures,
  TableColumnDefinition,
  useTableSelection,
  TableCellLayout,
  createTableColumn,
  Persona,
  Button,
} from '@fluentui/react-components';
import LineChart from './LineChart';
import { InvoiceInterface } from '../../../Data/General/Invoice';
import axios from 'axios';
import { WHITE_HOUSE_API } from '../../../Constants/Constant';

type InvoiceCell = {
  label: string;
  icon: string;
};

type LastUpdatedCell = {
  label: string;
  timestamp: number;
};

type LastUpdateCell = {
  label: string;
  icon: JSX.Element;
};

type AmountCell = {
  label: string;
};

type ReceiveAbleItem = {
  Invoice: InvoiceCell;
  Amount: AmountCell;
  receivedDate: LastUpdatedCell;
  dueDate: LastUpdatedCell;
  lastUpdate: LastUpdateCell;
};

type PayAbleItem = {
  Invoice: InvoiceCell;
  Amount: AmountCell;
  receivedDate: LastUpdatedCell;
  dueDate: LastUpdatedCell;
  lastUpdate: LastUpdateCell;
}



// const items: Item[] = [
//   {
//     Invoice: { label: '89938398', icon: '#' },
//     Amount: { label: '₦ 8,989' },
//     lastUpdated: { label: 'Mon 18, 2023', timestamp: 3 },
//     lastUpdate: {
//       label: 'click here to edit invoice',
//       icon: <EditRegular />,
//     },
//   },
//   {
//     Invoice: { label: '99382003', icon: '#' },
//     Amount: { label: 'Erika Mustermann' },
//     lastUpdated: { label: 'Yesterday at 1:45 PM', timestamp: 2 },
//     lastUpdate: {
//       label: 'You recently opened this',
//       icon: <OpenRegular />,
//     },
//   },
//   {
//     Invoice: { label: '883938724', icon: '#' },
//     Amount: { label: 'John Doe' },
//     lastUpdated: { label: 'Yesterday at 1:45 PM', timestamp: 2 },
//     lastUpdate: {
//       label: 'You recently opened this',
//       icon: <OpenRegular />,
//     },
//   },
//   {
//     Invoice: { label: '93838938', icon: '#' },
//     Amount: { label: 'Jane Doe' },
//     lastUpdated: { label: 'Tue at 9:30 AM', timestamp: 1 },
//     lastUpdate: {
//       label: 'You shared this in a Teams chat',
//       icon: <PeopleRegular />,
//     },
//   },
// ];




interface User {
  email: string,
  phoneNumber:string,
  token:string,
}
interface MAccounting {
  user:User;
}
const Accounting: React.FC<MAccounting> = ({user}) => {
  const [getInvoicesPayable, setGetInvoicesPayable] = useState<InvoiceInterface[]>([]);
  const [getInvoices, setGetInvoices] = useState<InvoiceInterface[]>([]);


  const items: ReceiveAbleItem[] = getInvoices.map((invinvoiceLabel) => {
    const invoiceDate = new Date(invinvoiceLabel?.invoiceDate);
    const dueDate = new Date(invinvoiceLabel?.dueDate);
  
    return {
      Invoice: { label: invinvoiceLabel?.invoiceNumber.toString(), icon: '#' },
      Amount: { label: invinvoiceLabel?.amount ? invinvoiceLabel.amount.toLocaleString() : 'N/A' },
      receivedDate: { 
        label: invinvoiceLabel?.invoiceDate ? invoiceDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) : " ", 
        timestamp: 2 
      },
      dueDate: { 
        label: invinvoiceLabel?.dueDate ? dueDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) : " ", 
        timestamp: 2 
      },
      lastUpdate: {
        label: invinvoiceLabel?.serviceName,
        icon:  <PeopleRegular />,
      },
    };
  });
  
  
  const items2: PayAbleItem[] = getInvoicesPayable.map((invinvoiceLabel) => {
    const invoiceDate = new Date(invinvoiceLabel?.invoiceDate);
    const dueDate = new Date(invinvoiceLabel?.dueDate);
  
    return {
      Invoice: { label: invinvoiceLabel?.invoiceNumber.toString(), icon: '#' },
      Amount: { label: invinvoiceLabel?.amount ? invinvoiceLabel.amount.toLocaleString() : 'N/A' },
      receivedDate: { 
        label: invinvoiceLabel?.invoiceDate ? invoiceDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) : " ", 
        timestamp: 2 
      },
      dueDate: { 
        label: invinvoiceLabel?.dueDate ? dueDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) : " ", 
        timestamp: 2 
      },
      lastUpdate: {
        label: invinvoiceLabel?.serviceName,
        icon:  <PeopleRegular />,
      },
    };
  });
  


  const columns: TableColumnDefinition<ReceiveAbleItem>[] = [
    createTableColumn<ReceiveAbleItem>({
      columnId: 'Invoice',
    }),
    createTableColumn<ReceiveAbleItem>({
      columnId: 'Amount',
    }),
    createTableColumn<ReceiveAbleItem>({
      columnId: 'receivedDate',
    }),
    createTableColumn<ReceiveAbleItem>({
      columnId: 'dueDate',
    }),
    createTableColumn<ReceiveAbleItem>({
      columnId: 'lastUpdate',
    }),
  ];


  const columns2: TableColumnDefinition<ReceiveAbleItem>[] = [
    createTableColumn<ReceiveAbleItem>({
      columnId: 'Invoice',
    }),
    createTableColumn<ReceiveAbleItem>({
      columnId: 'Amount',
    }),
    createTableColumn<ReceiveAbleItem>({
      columnId: 'receivedDate',
    }),
    createTableColumn<ReceiveAbleItem>({
      columnId: 'dueDate',
    }),
    createTableColumn<ReceiveAbleItem>({
      columnId: 'lastUpdate',
    }),
  ];
  const fetchInvoice = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/Invoice/Receiveable`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
      setGetInvoices(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);
  

  const fetchInvoicePayable = useCallback(async () => {
    try {
      const response = await axios.get(`${WHITE_HOUSE_API}/Invoice/purchase`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
      setGetInvoicesPayable(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [user.token]);
  


  const {
    getRows,
    selection: { toggleRow, isRowSelected },
  } = useTableFeatures(
    {
      columns,
      items,
    },
    [
      useTableSelection({
        selectionMode: 'single',
        defaultSelectedItems: new Set([1]),
      }),
    ]
  );


//Invoice payable

const {
  getRows: getRows2,
  selection: { toggleRow: toggleRow2, isRowSelected: isRowSelected2 },
} = useTableFeatures(
  {
    columns: columns2,
    items: items2,
  },
  [
    useTableSelection({
      selectionMode: 'single',
      defaultSelectedItems: new Set([1]),
    }),
  ]
);

const rows2 = getRows2((row) => {
  const selected = isRowSelected2(row.rowId);
  return {
    ...row,
    onClick: (e: React.MouseEvent) => toggleRow2(e, row.rowId),
    onKeyDown: (e: React.KeyboardEvent) => {
      if (e.key === ' ') {
        e.preventDefault();
        toggleRow2(e, row.rowId);
      }
    },
    selected,
    appearance: selected ? ('brand' as const) : ('none' as const),
  };
});


  // const rows = getRows((row) => {
  //   const selected = isRowSelected(row.rowId);
  //   return {
  //     ...row,
  //     onClick: (e: React.MouseEvent) => toggleRow(e, row.rowId),
  //     onKeyDown: (e: React.KeyboardEvent) => {
  //       if (e.key === ' ') {
  //         e.preventDefault();
  //         toggleRow(e, row.rowId);
  //       }
  //     },
  //     selected,
  //     appearance: selected ? ('brand' as const) : ('none' as const),
  //   };
  // });

  const ReceiveAbleRows = getRows((row) => {
    const selected = isRowSelected(row.rowId);
    return {
      ...row,
      onClick: (e: React.MouseEvent) => toggleRow(e, row.rowId),
      onKeyDown: (e: React.KeyboardEvent) => {
        if (e.key === ' ') {
          e.preventDefault();
          toggleRow(e, row.rowId);
        }
      },
      selected,
      appearance: selected ? ('brand' as const) : ('none' as const),
    };
  });


  

  useEffect(() => {
    fetchInvoice();
    fetchInvoicePayable();
    console.log("Invoice: ", getInvoices);
  }, [fetchInvoice, fetchInvoicePayable, getInvoices, user.token]);


  return (
    <>
      <div className="LETE907 GHET_8728">
        <div className="root-DS-EntryPoint1-3 ___77lcry0 f10pi13n">
          <div className="WeatherLifeDayHourPanelRoot-DS-EntryPoint1-">
            <div className="wDailyInfoSection-DS-EntryPoint1-1">
              <div className="wDailyTitleSection-DS-EntryPoint1-1">
                <div className="wDailyTitleSection_Line1-DS-EntryPoint1-1 Management-hideBackground">
                  <button className="lifeIndexBarWrapper-DS-EntryPoint1-1">
                    <div className="lifeIndexBarIconContainer-DS-EntryPoint1-1 departires-wc">
                      <PeopleMoney24Regular />
                    </div>
                    <div className="lifeIndexBarTextContainer-DS-EntryPoint1-1">
                      <div className="lifeIndexBarTitle-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                        Overdue invoice & Bills
                      </div>
                      <div className="lifeIndexBarSummary-DS-EntryPoint1-1">
                        <span className="lifeIndexBarLevelText-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                          Saturday, 9 2023
                        </span>
                      </div>
                    </div>
                  </button>
                </div>
                <div className="lifeIndexBarWrapper-DS-EntryPoint1-1-display Life-landing-body"></div>
              </div>
              <div className="wDailyOverlapSection-DS-EntryPoint1-1 owa-wc  Wc-hieght-scrollable customScrollBar">
                <div className="lifeIndexBarWrapper-DS-EntryPoint1-1 Life-landing-body">
                  <Persona
                    className="De-human-Personal"
                    textAlignment="start"
                    name="Kevin Sturgis"
                    tertiaryText="Jas Hennessy & Co"
                  />
                  <div className="quoteChangeV2-DS-EntryPoint1-1 greenColor Flex-content-column">
                    <span className="mtext-color">&#8358; 1,635, 888.86</span>
                    <span> (+0.22%) profit</span>
                  </div>
                </div>
                <div className="lifeIndexBarWrapper-DS-EntryPoint1-1 Life-landing-body">
                  <Persona
                    className="De-human-Personal"
                    textAlignment="start"
                    name="Kevin Sturgis"
                    tertiaryText="Jas Hennessy & Co"
                  />
                  <div className="quoteChangeV2-DS-EntryPoint1-1 greenColor Flex-content-column">
                    <span className="mtext-color">&#8358; 1,635, 888.86</span>
                    <span> (+0.22%) profit</span>
                  </div>
                </div>
                <div className="lifeIndexBarWrapper-DS-EntryPoint1-1 Life-landing-body">
                  <Persona
                    className="De-human-Personal"
                    textAlignment="start"
                    name="Kevin Sturgis"
                    tertiaryText="Jas Hennessy & Co"
                  />
                  <div className="quoteChangeV2-DS-EntryPoint1-1 greenColor Flex-content-column">
                    <span className="mtext-color">&#8358; 11,335, 88.86</span>
                    <span> (+0.62%) profit</span>
                  </div>
                </div>
                <div className="lifeIndexBarWrapper-DS-EntryPoint1-1 Life-landing-body">
                  <Persona
                    className="De-human-Personal"
                    textAlignment="start"
                    name="Kevin Sturgis"
                    tertiaryText="Jas Hennessy & Co"
                  />
                  <div className="quoteChangeV2-DS-EntryPoint1-1 greenColor Flex-content-column">
                    <span className="mtext-color">&#8358; 635,376.86</span>
                    <span> (+122.22%) profit</span>
                  </div>
                </div>
                <div className="lifeIndexBarWrapper-DS-EntryPoint1-1 Life-landing-body">
                  <Persona
                    className="De-human-Personal"
                    textAlignment="start"
                    name="Kevin Sturgis"
                    tertiaryText="Jas Hennessy & Co"
                  />
                  <div className="quoteChangeV2-DS-EntryPoint1-1 greenColor Flex-content-column">
                    <span className="mtext-color">&#8358; 4,600,000</span>
                    <span> (+82.22%) profit</span>
                  </div>
                </div>
              </div>
              <div className="wDailyChartSection-DS-EntryPoint1-1"></div>
            </div>
          </div>
        </div>

        <div className="root-DS-EntryPoint1-3 ___77lcry0 f10pi13n">
          <div className="WeatherLifeDayHourPanelRoot-DS-EntryPoint1-">
            <div className="wDailyInfoSection-DS-EntryPoint1-1">
              <div className="wDailyTitleSection-DS-EntryPoint1-1">
                <div className="wDailyTitleSection_Line1-DS-EntryPoint1-1 Management-hideBackground">
                  <button className="lifeIndexBarWrapper-DS-EntryPoint1-1">
                    <div className="lifeIndexBarIconContainer-DS-EntryPoint1-1 stays-wc">
                      <PeopleMoney24Regular />
                    </div>
                    <div className="lifeIndexBarTextContainer-DS-EntryPoint1-1">
                      <div className="lifeIndexBarTitle-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                       Financial Health
                      </div>
                      <div className="lifeIndexBarSummary-DS-EntryPoint1-1">
                        <span className="lifeIndexBarLevelText-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                          Saturday, 9 2023
                        </span>
                      </div>
                    </div>
                  </button>
                </div>
                <div className="lifeIndexBarWrapper-DS-EntryPoint1-1-display Life-landing-body"></div>
              </div>
              <div className="wDailyOverlapSection-DS-EntryPoint1-1 owa-wc  Wc-hieght-scrollable customScrollBar">
                <div className="lifeIndexBarWrapper-DS-EntryPoint1-1 Life-landing-body">
                  <Persona
                    className="De-human-Personal"
                    textAlignment="start"
                    name="Kevin Sturgis"
                    tertiaryText="Jas Hennessy & Co"
                  />
                  <div className="quoteChangeV2-DS-EntryPoint1-1 greenColor Flex-content-column">
                    <span className="mtext-color">&#8358; 1,635, 888.86</span>
                    <span> (+0.22%) profit</span>
                  </div>
                </div>
                <div className="lifeIndexBarWrapper-DS-EntryPoint1-1 Life-landing-body">
                  <Persona
                    className="De-human-Personal"
                    textAlignment="start"
                    name="Kevin Sturgis"
                    tertiaryText="Jas Hennessy & Co"
                  />
                  <div className="quoteChangeV2-DS-EntryPoint1-1 greenColor Flex-content-column">
                    <span className="mtext-color">&#8358; 1,635, 888.86</span>
                    <span> (+0.22%) profit</span>
                  </div>
                </div>
                <div className="lifeIndexBarWrapper-DS-EntryPoint1-1 Life-landing-body">
                  <Persona
                    className="De-human-Personal"
                    textAlignment="start"
                    name="Kevin Sturgis"
                    tertiaryText="Jas Hennessy & Co"
                  />
                  <div className="quoteChangeV2-DS-EntryPoint1-1 greenColor Flex-content-column">
                    <span className="mtext-color">&#8358; 11,335, 88.86</span>
                    <span> (+0.62%) profit</span>
                  </div>
                </div>
                <div className="lifeIndexBarWrapper-DS-EntryPoint1-1 Life-landing-body">
                  <Persona
                    className="De-human-Personal"
                    textAlignment="start"
                    name="Kevin Sturgis"
                    tertiaryText="Jas Hennessy & Co"
                  />
                  <div className="quoteChangeV2-DS-EntryPoint1-1 greenColor Flex-content-column">
                    <span className="mtext-color">&#8358; 635,376.86</span>
                    <span> (+122.22%) profit</span>
                  </div>
                </div>
                <div className="lifeIndexBarWrapper-DS-EntryPoint1-1 Life-landing-body">
                  <Persona
                    className="De-human-Personal"
                    textAlignment="start"
                    name="Kevin Sturgis"
                    tertiaryText="Jas Hennessy & Co"
                  />
                  <div className="quoteChangeV2-DS-EntryPoint1-1 greenColor Flex-content-column">
                    <span className="mtext-color">&#8358; 4,600,000</span>
                    <span> (+82.22%) profit</span>
                  </div>
                </div>
              </div>
              <div className="wDailyChartSection-DS-EntryPoint1-1"></div>
            </div>
          </div>
        </div>

      </div>
      <div className="LETE907 GHET_8728">
        <div className="lifeDailyInfoGridContainer-DS-EntryPoint1-1 Wc-Mbuttom-hieght Account-box">
          <div className="wDailyInfoSection-DS-EntryPoint1-1">
            <div className="wDailyTitleSection_Line1-DS-EntryPoint1-1 Management-hideBackground">
              <button className="lifeIndexBarWrapper-DS-EntryPoint1-1">
                <div className="lifeIndexBarIconContainer-DS-EntryPoint1-1 stays-wc">
                  <BuildingBank24Filled />
                </div>
                <div className="lifeIndexBarTextContainer-DS-EntryPoint1-1">
                  <div className="lifeIndexBarTitle-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                    Accounts Payables & Owing
                  </div>
                  <div className="lifeIndexBarSummary-DS-EntryPoint1-1">
                    <span className="lifeIndexBarLevelText-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1"></span>
                  </div>
                </div>
              </button>
            </div>

            <div className="lifeIndexBarWrapper-DS-EntryPoint1-1-display Life-landing-body"></div>
            <div className="wDailyOverlapSection-DS-EntryPoint1-1 owa-wc Wc-hieght-scrollable customScrollBar">
              <Table aria-label="Table with single selection">
                <TableHeader>
                  <TableRow>
                    <TableSelectionCell type="radio" hidden />
                    <TableHeaderCell>Invoice No.</TableHeaderCell>
                    <TableHeaderCell>Amount Payable</TableHeaderCell>
                    <TableHeaderCell>Received Date</TableHeaderCell>
                    <TableHeaderCell>Due Date</TableHeaderCell>
                    <TableHeaderCell>Manage Invoice</TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {rows2.map(
                    ({ item, selected, onClick, onKeyDown, appearance }) => (
                      <TableRow
                        key={item.Invoice.label}
                        onClick={onClick}
                        onKeyDown={onKeyDown}
                        aria-selected={selected}
                        appearance={appearance}
                      >
                        <TableSelectionCell
                          checked={selected}
                          type="radio"
                          radioIndicator={{ 'aria-label': 'Select row' }}
                        />
                        <TableCell>
                          <TableCellLayout media={item.Invoice.icon}>
                            {item.Invoice.label}
                          </TableCellLayout>
                        </TableCell>
                        <TableCell>&#8358;{item.Amount.label}</TableCell>
                        <TableCell>{item.receivedDate.label}</TableCell>
                        <TableCell>{item.dueDate.label}</TableCell>
                        <TableCell role="gridcell">
                          <TableCellLayout>
                            <Button
                              icon={<Payment24Filled className="Pai-Icon" />}
                            >
                              Pay
                            </Button>
                          </TableCellLayout>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </div>
      <div className="LETE907 GHET_8728">
        <div className="lifeDailyInfoGridContainer-DS-EntryPoint1-1 Wc-Mbuttom-hieght Account-box">
          <div className="wDailyInfoSection-DS-EntryPoint1-1">
            <div className="wDailyTitleSection_Line1-DS-EntryPoint1-1 Management-hideBackground">
              <button className="lifeIndexBarWrapper-DS-EntryPoint1-1">
                <div className="lifeIndexBarIconContainer-DS-EntryPoint1-1 stays-wc Receiveables">
                  <BuildingBank24Filled />
                </div>
                <div className="lifeIndexBarTextContainer-DS-EntryPoint1-1">
                  <div className="lifeIndexBarTitle-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1">
                    Accounts Receivable
                  </div>
                  <div className="lifeIndexBarSummary-DS-EntryPoint1-1">
                    <span className="lifeIndexBarLevelText-DS-EntryPoint1-1 textEllipsis-DS-EntryPoint1-1"></span>
                  </div>
                </div>
              </button>
            </div>

            <div className="lifeIndexBarWrapper-DS-EntryPoint1-1-display Life-landing-body"></div>
            <div className="wDailyOverlapSection-DS-EntryPoint1-1 owa-wc Wc-hieght-scrollable customScrollBar">
              <Table aria-label="Table with single selection">
                <TableHeader>
                  <TableRow>
                    <TableSelectionCell type="radio" hidden />
                    <TableHeaderCell>Invoice No.</TableHeaderCell>
                    <TableHeaderCell>Amount Receiveable</TableHeaderCell>
                    <TableHeaderCell>Received Date</TableHeaderCell>
                    <TableHeaderCell>Due Date</TableHeaderCell>
                    <TableHeaderCell>Manage Invoice</TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {ReceiveAbleRows.map(
                    ({ item, selected, onClick, onKeyDown, appearance }) => (
                      <TableRow
                        key={item.Invoice.label}
                        onClick={onClick}
                        onKeyDown={onKeyDown}
                        aria-selected={selected}
                        appearance={appearance}
                      >
                        <TableSelectionCell
                          checked={selected}
                          type="radio"
                          radioIndicator={{ 'aria-label': 'Select row' }}
                        />
                        <TableCell>
                          <TableCellLayout media={item.Invoice.icon}>
                            {item.Invoice.label}
                          </TableCellLayout>
                        </TableCell>
                        <TableCell>&#8358;{item.Amount.label}</TableCell>
                        <TableCell>{item.receivedDate.label}</TableCell>
                        <TableCell>{item.dueDate.label}</TableCell>
                        <TableCell role="gridcell">
                          <TableCellLayout>
                            <Button
                              className="Receive-payment"
                              icon={
                                <CreditCardClock24Filled className="Pai-Icon-receive" />
                              }
                            >
                              Receive
                            </Button>
                          </TableCellLayout>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accounting;
