export interface ITimeShift {
    ShiftId: number | null;
    EmployeeId: number | null;
    ShiftStart: string |null;
    ShiftEnd: string | null;
    Position: string | null;
    Shiftdate: Date | null;
}

export interface TimeShift {
    shiftId: number | null;
    employeeId: number | null;
    shiftStart: string |null;
    shiftEnd: string | null;
    position: string | null;
    shiftdate: Date | null;
}

export const InitialITimeShift = {
    ShiftId: 0,
    EmployeeId: 0,
    ShiftStart: "",
    ShiftEnd:  "",
    Position:  "",
    Shiftdate: new Date()
}