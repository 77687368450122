import { IAccounting } from "./Accounting";
import { IDepartment } from "./Department";
import { EmployeeRoleMapping } from "./EmployeeRoleMapping";
import { EmployeeUnitsOfWork } from "./EmployeeUnitsOfWork";
import { GeneralLedger } from "./GeneralLedger";
import { Payroll } from "./Payroll";
import { Transaction } from "./Transaction";

export interface Employee {
    EmployeeId:number | null;
    Phone: string | null;
    Title: string | null;
    FirstName?: string | null;
    LastName?: string | null;
    Role?: string | null;
    PhoneNumber: string | null;
    Email?: string | null;
    EmegergencyContactPhone: string | null;
    Position?: string | null;
    HireDate?: Date | null;
    Salary?: number | null;
    FirstContactEmail1?:string;
    FirstContactEmail2?:string;
    FirstContactPhone1?:string;
    FirstContactPhone2?:string;
    FirstContactFullName1?:string;
    FirstContactFullName2?:string;
    Country?:string;
    State?:string;
    City?:string;
    PerformanceEvaluations?: string | null;
    TrainingRecords?: string | null;
    Address?: string | null;
    DateOfBirth?: Date | null;
    Gender?: string | null;
    Nationality?: string | null;
    SocialSecurityNumber?: string | null;
    BankAccountNumber?: string | null;
    BankName?: string | null;
    Currency?: string | null;
    PaymentTerms?:string | null;
    MaritalStatus?:string |null;
    EmploymentStatus?: string | null;
    Department?: string | null;
    SupervisorId?: number | null;
    VacationDays?: number | null;
    SickLeaveDays?: number | null;
    IsManager?: boolean | null;
    IsAdmin?: boolean | null;
    Passport?: File;
    Credentials?: File;

}



export interface GetEmployee {
        employeeId: number;
        firstName?: string;
        lastName?: string;
        position?: string;
        hireDate?: Date;
        salary?: number;
        firstContactEmail1?:string;
        firstContactEmail2?:string;
        firstContactPhone1?:string;
        firstContactPhone2?:string;
        firstContactFullName1?:string;
        firstContactFullName2?:string;
        performanceEvaluations?: string;
        trainingRecords?: string;
        address?: string;
        country?:string;
        state?:string;
        city?:string;
        dateOfBirth?: Date;
        gender?: string;
        socialSecurityNumber?: string;
        bankAccountNumber?: string;
        employmentStatus?: string;
        department?: string;
        supervisorId?: number;
        vacationDays?: number;
        sickLeaveDays?: number;
        isManager?: boolean;
        isAdmin?: boolean;
        photos?: string;
        employeeFile?: string;
        email?: string;
        phoneNumber?: string;
        role?: string;
        userId?: number;
        bankName?: string;
        currency?: string;
        maritalStatus?: string;
        paymentTerms?: string;
        isDeleted?: boolean;
        deletionReason?: string;
    }
    



export const InitialIEmployee: GetEmployee = {
        employeeId: 0,
        firstName: "",
        lastName: "",
        position: "",
        hireDate: new Date(),
        salary: 0,
        performanceEvaluations: "",
        trainingRecords: "",
        address: "",
        dateOfBirth: new Date(),
        gender: "",
        country: "",
        state: "",
        city: "",
        firstContactEmail1: "",
        firstContactEmail2: "",
        firstContactPhone1: "",
        firstContactPhone2: "",
        firstContactFullName1: "",
        firstContactFullName2: "",
        socialSecurityNumber: "",
        bankAccountNumber: "",
        employmentStatus: "",
        department: "",
        supervisorId: 0,
        vacationDays: 0,
        sickLeaveDays: 0,
        isManager: false,
        isAdmin: false,
        photos: "",
        employeeFile: "",
        email: "",
        phoneNumber: "",
        role: "",
        userId: 0,
        bankName: "",
        currency: "",
        maritalStatus: "",
        paymentTerms: "",
        isDeleted: false,
        deletionReason: "",
    
}

export interface DeletedEmployee {
    Reason:string;
}

export const InitializeDeleted:DeletedEmployee ={
    Reason: ""
}