import React, { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
  Label,
  useId,
  Input,
  makeStyles,
  Divider,
  Field,
  Combobox,
  Option,
  Text,
  Persona,
  Dropdown,
} from '@fluentui/react-components';
import './BarBilling.scss';
import {
  IBusinessTypes,
  InitializeBusinessTypes,
} from '../../../Data/General/BusinessType';
import { WHITE_HOUSE_API, generateUniqueID } from '../../../Constants/Constant';
import axios from 'axios';
import Loaders from '../../Common/Loader/Loader';
import DispatchToast from '../../Common/Toast/DispatchToast';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import { IDynamic } from '../../../Data/General/IDynamic';
import { IDropdownOption } from '@fluentui/react';
import {
  ICreateInvoice,
  initializeInvoice,
} from '../../../Data/General/ICreateInvoice';
import {
  BarLoungeMenu,
  IBarLoungeMenu,
  InitialIBar,
} from '../../../Data/General/BarMenuLounge';
import { BarLoungeCategory } from '../../../Data/General/IBarCategory';
import {
  IHotelBarBill,
  InitHotelBarBill,
} from '../../../Data/General/HotelBarBill';
import { GetEmployee } from '../../../Data/General/Employee';
import { ICreateGuest } from '../../../Data/General/Guest';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '400px',
  },
  control: {
    maxWidth: '300px',
  },
});

interface Message {
  message: string;
  id: number;
}

interface SelectItem {
  price?: number;
  Totalprice?: number;
  itemName?: string;

}

interface User {
  email: string;
  phoneNumber: string;
  token: string;
}

// other properties...
interface BusinessTypesProps {
  onBarClose: () => void;
  user: User;
  message: Message;
  onPaymentOpen: (customerId: any, ServiceId: any) => void;
}

const BarBilling: React.FC<BusinessTypesProps> = ({
  onBarClose,
  message,
  onPaymentOpen,
  user,
}) => {
  const [dispatchToaster, setDispatchToaster] = useState<boolean>(false);
  const [selectedInvoicee, setSelectedInvoicee] = useState<number>();
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [results, setResults] = useState<IDynamic[]>([]);
  const [selectedService, setSelectedService] = useState<string | null>(null);
  const [apiData, setApiData] = useState<string[]>([]);
  const [createBill, setCreatedBill] =
    useState<IHotelBarBill>(InitHotelBarBill);
  const [getEmployee, setGetEmployee] = useState<GetEmployee[]>([]);
  const [getGuest, setGetGuest] = useState<ICreateGuest[]>([]);
  const [getBarItems, setBarItems] = useState<BarLoungeMenu[]>([]);
  const [itemprice, setItemPrice] = useState<SelectItem>();
  const [input, setInput] = useState('');
  const inputId = useId('input');
  const styles = useStyles();
  const [getExistingBarcategory, setgetExistingBarcategory] = useState<
    BarLoungeCategory[]
  >([]);
  const [responseMessage, setResponseMessage] = useState('');
  const [currency, setCurrency] = useState('NGN');
  const currencySymbol = currency === 'NGN' ? '₦' : '$';
  const [initialBarCatItem, setInitialBarCatItem] = useState<string>('');
  const [adjuster, setAdjuster] = useState<GetEmployee>();

  const handleBarInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'quantity') {
      const quantity = Number(value);
      const price = itemprice?.price || 0;
      const finalValue = quantity * price;
    setItemPrice(prevState => ({...prevState, Totalprice: finalValue || 0}));
    }
    setCreatedBill((prevState) => ({
      ...prevState, [name]: value,
      adjusterId: adjuster?.employeeId || 0,
      totalAmount: itemprice?.Totalprice || 0,
      price: itemprice?.price || 0,
      itemName: itemprice?.itemName || "",
      orderId: generateUniqueID() || 0,
      }));
  };

  const HandleSubmitData = async () => {
    try {
      setIsLoader(true);
      const response = await axios.post(
        `${WHITE_HOUSE_API}/HotelBarBill/HB`,
        createBill,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (response.data) {
        setTimeout(() => {
          setIsLoader(false);
        }, 1500);

        if (response.data.status === 'Success') {
          setResponseMessage(response.data.eMessage);
          setDispatchToaster(true);
          setTimeout(() => {
            setDispatchToaster(false);
            setCreatedBill({...InitHotelBarBill});
          }, 1500);
        }
      }
    } catch (error) {
      setTimeout(() => {
        setIsLoader(false);
      }, 1500);
      setResponseMessage('Registration ' + error);
      console.error('Error fetching roles:', error);
    }
  };

  const handleInputChange = (event: any) => {
    setInput(event.target.value);
  };

  function formatDate(dateString: Date): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero indexed, so +1 and pad with 0
    const day = ('0' + date.getDate()).slice(-2); // Pad with 0
    return `${year}-${month}-${day}`;
  }

  const fetchAutocompleteEmployee = async (inputs: any) => {
    if (inputs.length < 0) {
      return; // don't call the API if the input is less than 2 characters
    }
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/HotelBarBill/Customer?prefix=${inputs}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setGetGuest(response.data);
      console.log("Employee: ", response.data);
    } catch (error) {
      console.error('Error fetching autocomplete results:', error);
    }
  };

  const fetchAutoCompleteBarItem = async (inputs: any) => {
    if (inputs.length < 0) {
      return; // don't call the API if the input is less than 2 characters
    }
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/HotelBarBill/Product?prefix=${inputs}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setBarItems(response.data);
    } catch (error) {
      console.error('Error fetching autocomplete results:', error);
    }
  };

  const handleGuestChangeName = (event: any) => {
    fetchAutocompleteEmployee(event.target.value);
  };

  const handleBarItemChangeName = (event: any) => {
    fetchAutoCompleteBarItem(event.target.value);
  };

  const handleEmployeeSelection = async (name: string, event: ICreateGuest) => {
    setCreatedBill((prevState) => ({ ...prevState, customerId: event.guestId }));
    console.log("Selected Guest: ", event.guestId, "Object: ", createBill);
  };

  const handleBarItemSelection = async (name: string, event: BarLoungeMenu) => {
    console.log("Items Event: ", event);
    setItemPrice((prevState) => ({
      ...prevState, 
      price: event.price || 0,
      Totalprice: 0, 
      itemName: event?.itemName || ""}));
      setCreatedBill((prevState) => ({
        ...prevState,
        [name]: event.itemId,
      }));
      setCreatedBill((prevState) => ({
        ...prevState,
        itemName: event.itemName,
      }));
    
  };

  const fetchAutocompleteResults = useCallback(async () => {
    if (input.length < 2) {
      return;
    }

    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Invoice/Query?prefix=${input}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setResults(response.data);
    } catch (error) {
      console.error('Error fetching autocomplete results:', error);
    }
  }, [input, user.token]);

  const fetchAdjuster = useCallback(async () => {
    try {
      const response = await axios.get(
        `${WHITE_HOUSE_API}/Adjuster/${user.phoneNumber}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const adjuster = response.data;
      setAdjuster(adjuster);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  }, [user.phoneNumber, user.token]);

  useEffect(() => {
    console.log(itemprice);
    console.log(createBill);
    fetchAutocompleteResults();
    fetchAdjuster();
  }, [createBill, fetchAdjuster, fetchAutocompleteResults, getGuest, itemprice, message.id]);

  // const setSelectedId = (name: any) => {
  //   console.log("name: ", name);
  //   console.log("name.innerText: ", name.target.innerText);

  //   let value = getExistingBarcategory.find(item => item.categoryName === name.target.innerText);
  //   console.log("The value Item: ", value);

  //   if (value) {
  //     let catid = Number(value?.categoryId);
  //       setCreatedBar((prevState) => ({ ...prevState, categoryId: catid }));
  //   } else {
  //       console.log("No match found in getExistingBarcategory for name.innerText");
  //   }
  // }

  return (
    <div className="fui-FluentProvider fui-FluentProvider361 ___1bsm0mz f19n0e5 fxugw4r f1o700av fk6fouc fkhj508 figsok6 f1i3iumi f1euv43f f15twtuk f1vgc2s3 f1e31b4d f494woh">
      <div
        aria-hidden="true"
        className="fui-DialogSurface__backdrop ___adeqq90 f19dog8a fju19wo f113wtx2 f10k790i f5gq2j6 f1xynx9j"
      ></div>

      <div className="fui-DialogSurface ___1g5txne ftgm304 f1c515w f1bitti f15twtuk f1e31b4d f1yab3r1 f1vgc2s3 fuq56rw f15kemlc fl2zwns fdgang7 fgr6219 f1ujusj6 f10jk5vf fcgxt0o fzkkow9 fcdblym fg706s2 fjik90z f1ln0qer fa2wlxz f120kxnn f19dog8a f3052tw fvgz9i8 f6a9g1z f1ewtqcl f10nrhrw fxugw4r f19n0e5 f5ogflp f1hqa2wf f1f09k3d finvdd3 fghlq4f f1gn591s fb073pr fjscplz fnivh3a fc7yr5o f1el4m67 f8yange ff54dml ftqa4ok f2hkw1w f8hki3x f1d2448m f1bjia2o ffh67wi f15bsgw9 f14e48fq f18yb2kv fd6o370 fh1cnn4 fy7oxxb fpukqih f184ne2d frrh606 f1v5zibi ful5kiu fo2hd23 f1jqcqds ftffrms f2e7qr6 fsr1zz6 f1dvezut fd0oaoj fjvm52t f1cwg4i8 f57olzd f4stah7 f480a47 fs1por5">
        <div className="fui-DialogBody ___6dxt060 f13qh94s f120kxnn flnwrvu f1ewtqcl f1ln0qer fa2wlxz f4px1ci fn67r4l f1x46k84 fjj47a5 ff54dml f1t97isy">
          {isLoader && (
            <div className="restrict-the-inner">
              <Loaders />
            </div>
          )}
          {dispatchToaster && <DispatchToast message={responseMessage} />}
          <div className="header-content">
            <h2
              id="dialog-title-363"
              className="fui-DialogTitle ___wfyv5z0 f16hsg94 f1amvztq fwpfdsa fsyjsko fk6fouc f1pp30po fl43uef f106mvju f1hu3pq6 f11qmguv f19f4twv f1tyq0we"
            >
              Create Bar Request
            </h2>
          </div>

          <div className="fui-DialogContent ___1dce55l f5zp4f f1nxs5xn f1ewtqcl faunodf f88nxoq fwpfdsa fsyjsko fr4fn7s f171n1a0 f1ean75l f1jwbs5a fxlnl9e ffs23cn f2uu7rc f1v5tmjd fk6fouc fkhj508 figsok6 f1i3iumi">
            <h3 className="user-initiating">
              {adjuster?.firstName} {adjuster?.lastName}
            </h3>
            <div className="First-form-flex-inoice">
              <div className={styles.root}>
                <Label htmlFor={inputId}>Customer</Label>         
                <Combobox
                className=""
                placeholder="Search name or Room number"
                aria-label="autocomplete"
                name="customerId"
                onChange={handleGuestChangeName}
                onSelect={(event) => {
                  const target = event.target as HTMLInputElement;
                  const selectedValue = target.value;
                  let selectedGuest = getGuest.find(o => o.firstName + " " + o.lastName === selectedValue);
                  if (selectedGuest) {
                    handleEmployeeSelection(
                      'customerId',
                      selectedGuest
                    );
                  }
                }}
              >
                {getGuest && getGuest.map((employee) => (
                  <Option
                    key={employee.guestId}
                    value={`${employee.firstName} ${employee.lastName}`}
                    text={`${employee.firstName} ${employee.lastName}`}
                  >
                    <Persona
                      avatar={{
                        color: 'colorful',
                        'aria-hidden': true,
                      }}
                      name={`${employee.firstName} ${employee.lastName}`}
                      presence={{
                        status: 'available',
                      }}
                      secondaryText={employee.email}
                    />
                  </Option>
                ))}
              </Combobox>


              </div>
              <div className={styles.root}>
                <Label htmlFor={inputId}>Requested Item</Label>
                <Combobox
                  className=""
                  placeholder="Search bar items"
                  aria-label="autocomplete"
                  name="itemId"
                  onChange={handleBarItemChangeName}
                  onSelect={(event) => {
                    const target = event.target as HTMLInputElement;
                    const selectedValue = target.value;
                    const selectedName = target.name;
                    let tskid = getBarItems.find(
                      (c) => c.itemName === selectedValue
                    );
                    if(tskid){
                      handleBarItemSelection('itemId', tskid);
                    }
                   
                  }}
                  
                >
                  {getBarItems.map((result) => (
                    <Option
                      key={result.itemId}
                      value={`${result.itemName}`}
                      text={`${result.itemName}`}
                    >
                      <Persona
                        avatar={{
                          color: 'colorful',
                          'aria-hidden': true,
                        }}
                        name={`${result.itemName}`}
                        presence={{
                          status: 'available',
                        }}
                        secondaryText={result.description}
                      />
                    </Option>
                  ))}
                </Combobox>
              </div>
            </div>
            <div className="First-form-flex-inoice">
              <div className={styles.root}>
                <Label htmlFor="discription">Quantity</Label>
                <Input
                  type="number"
                  id={inputId}
                  name="quantity"
                  placeholder="Enter quantity"
                  onChange={handleBarInputChange}
                />
              </div>
              <div className={styles.root}>
                <Label htmlFor={inputId}>Product Price</Label>
                <Input
                  placeholder="Purchasing Price"
                  name="price"
                  type="number"
                  readOnly
                  value={
                    itemprice?.price ? itemprice?.price.toString() : ''
                  }
                  onChange={handleBarInputChange}
                  contentBefore={
                    <Text size={400} id="beforeLabelId">
                      {currencySymbol}
                    </Text>
                  }
                  contentAfter={
                    <Text size={400} id="wafterLabelId">
                      .00
                    </Text>
                  }
                  appearance="filled-darker"
                  id="filledDarkerIdk"
                />
              </div>
            </div>
            <div className="First-form-flex-inoice">
              <div className={styles.root}>
                <Label htmlFor={inputId}>Product Name</Label>
                <Input
                  id={inputId}
                  name="itemName"
                  readOnly
                  value={
                    itemprice?.itemName
                      ? itemprice?.itemName.toString()
                      : ''
                  }
                  placeholder="Product Name"
                  onChange={handleBarInputChange}
                />
              </div>
              <div className={styles.root}>
                <Label htmlFor={inputId}>Total Amount</Label>
                <Input
                  placeholder="Total Amount"
                  name="price"
                  type="number"
                  readOnly
                  value={itemprice?.Totalprice?itemprice.Totalprice.toString() : ''}
                  onChange={handleBarInputChange}
                  contentBefore={
                    <Text size={400} id="beforeLabelId">
                      {currencySymbol}
                    </Text>
                  }
                  contentAfter={
                    <Text size={400} id="wafterLabelId">
                      .00
                    </Text>
                  }
                  appearance="filled-darker"
                  id="filledDarkerIdk"
                />
              </div>
            </div>
          </div>
          <div className="fui-DialogActions ___1mdwomm f3052tw f1ewtqcl f22iagw f1cztu7b fcu02xx f4px1ci fn67r4l f6glcwc f1e3st1r f1a7i8kp fd46tj4 fsyjsko f1f41i0t f1jaqex3 f2ao6jk">
            <Button className="Button-Submit" onClick={HandleSubmitData}>
              Add Service
            </Button>
            <Button className="Button-close" onClick={onBarClose}>
              cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarBilling;
