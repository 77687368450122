import React, { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
} from "@fluentui/react-components";
import { Dismiss24Regular, DismissRegular } from "@fluentui/react-icons";

interface Message {
  header:string;
  body:string;
}


interface CountdownProps {
  ResponseMessage: Message;
    close: () => void;
  }
const TransactionState: React.FC<CountdownProps> = ({ ResponseMessage, close }) => {
    const [message, setMessage] = useState<Message>();
    const [open, setOpen] = useState(true);



  useEffect(() => {
    setMessage(ResponseMessage);
    setOpen(true);
  }, [ResponseMessage], );
    return (
        //<div className="spin-shadow">
      <Dialog open={open} onOpenChange={(event, data) => setOpen(data.open)}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{message?.header}</DialogTitle>
          <DialogContent>
           {message?.body}
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Close</Button>
            </DialogTrigger>
            {/* <Button appearance="primary">Do Something</Button> */}
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
      //  </div>
    )
}

export default TransactionState;