import Accounting from "../../Components/Hms/Accounting/Accounting";
import { IAccounting } from "./Accounting";
import { Communication } from "./Communication";
import { InvoiceInterface } from "./Invoice";
import { OnlineReservation } from "./OnlineReservation";
import { Reservation } from "./Reservation";
import { Review } from "./Review";

export interface Guest {
    GuestId: number;
    FirstName: string;
    LastName: string;
    Email?: string;
    Phone: string;
    Address: string;
    GuestIdCard?: string;
    Country?: string;
    State?: string;
    City?: string;
    IsCheckedIn?: boolean;
    IsCheckedOut?: boolean;
    PaymentStatus?: boolean;
    CheckInTime?: Date;
    CheckOutTime?: Date;
    Preferences?: string;
    LoyaltyProgram?: string;
    LoyaltyProgramId?: string;
    Points?: number;
    EliteStatus?: string;
    HistoryOfStays?: string;
    Notes?: string;
    VisitDate?: Date;
    PaymentDetails?: string;
    PaymentMethod?: string;
    CreditCardNumber?: string;
    CardholderName?: string;
    CardExpirationDate?: string;
    Cvv?: string;
    BillingAddress?: string;
    PaymentAmount?: number;
    Currency?: string;
    TransactionId?: string;
    PaymentTimestamp?: Date;
    RoomNumber?: number;
    IsTravelAgent?: boolean;
    Title?: number;
    StateId?: number;
    StateName?: number; 
    Password?: string; 
    ZipCode?: string;
    Accountings: IAccounting[];
    Communications: Communication[];
    Invoices: InvoiceInterface[];
    OnlineReservations: OnlineReservation[];
    Reservations: Reservation[];
    Reviews: Review[];
}


export interface IGuestCheckedIn {
    guestId?: number;
    firstName?: string;
    lastName?: string;
    guestStatus?: string;
    roomName?: string;
    outstanding?: any;
    arrivalDate?: any;
    numberOfNight?: number;
    numberOfChildren?: number;
    numberOfAdult?: number;
    departureDate?: any;
    amounpaid?:any;
    balance?:any,
    reservationType?: string;
    paymentSuccess?: boolean;
    roomNumber?: number;
}


export interface ICreateGuest {

    guestId: number;
    firstName: string;
    lastName: string;
    email?: string;
    phone: string;
    address: string;
    guestIdCard?: string;
    country?: string;
    state?: string;
    city?: string;
    isCheckedIn?: boolean;
    isCheckedOut?: boolean;
    paymentStatus?: boolean;
    checkInTime?: Date | null;
    checkOutTime?: Date | null;
    preferences?: string;
    loyaltyProgram?: string;
    loyaltyProgramId?: string;
    points?: number;
    eliteStatus?: string;
    historyOfStays?: string;
    notes?: string;
    VisitDate?: Date | null;
    paymentDetails?: string;
    paymentMethod?: string;
    creditCardNumber?: string;
    cardholderName?: string;
    cardExpirationDate?: string;
    cvv?: string;
    billingAddress?: string;
    paymentAmount?: number;
    currency?: string;
    transactionId?: string;
    paymentTimestamp?: Date | null;
    roomNumber?: number;
    isTravelAgent?: boolean;
    titleId?: number;
    genderId?:number;
    stateId?: number;
    stateName?: number;
    password?: string;
    zipCode?: string;
    roomId?: number;
    guidId?: string;
    modeOfPayment?: string;
    agentId?: number;
    rates?: string;
    rateId?: number;
    taxExemptionId?: string;
    billTo?: string;
    paymentTerms?: string;
    idType?:string;
    issueDateofId?: Date | null;
    epirdate?:Date | null;
    language?:string;
    nationaliy?:string;
    company?: string;
    businessSourcess?: string;
    spacialOfferId?: number;
    requestId?:number;
    checkinId?: number;
    maritalStatus?:string;
    childrenstatus?:string;
    fullname?:string;
    billingCountry?:string;
    releaseDate?: Date | null;
    dateOfBirth?:Date | null;
    specialOfferId?: number;
    commissionPlan?: string;
    commissionId?: number;
  }
  

 export const createGuest: ICreateGuest = {
    guestId: 0,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    guestIdCard: '',
    idType: '',
    country: '',
    state: '',
    city: '',
    isCheckedIn: false,
    isCheckedOut: false,
    paymentStatus: false,
    checkInTime: new Date(),
    checkOutTime: new Date(),
    preferences: '',
    language: '',
    nationaliy: '',
    issueDateofId: new Date(),
    epirdate:new Date(),
    loyaltyProgram: '',
    loyaltyProgramId: '',
    points: 0,
    eliteStatus: '',
    requestId:0,
    historyOfStays: '',
    notes: '',
    VisitDate: new Date(),
    paymentDetails: '',
    paymentMethod: '',
    creditCardNumber: '',
    cardholderName: '',
    cardExpirationDate: '',
    cvv: '',
    billingAddress: '',
    paymentAmount: 0,
    currency: '',
    maritalStatus: "",
    transactionId: '',
    paymentTimestamp: new Date(),
    dateOfBirth: new Date(),
    roomNumber: 0,
    isTravelAgent: false,
    titleId: 0,
    genderId: 0,
    stateId: 0,
    stateName: 0,
    password: '',
    zipCode: '',
    roomId: 0,
    guidId: '',
    modeOfPayment: '',
    agentId: 0,
    rates: '',
    rateId: 0,
    taxExemptionId: '',
    billTo: '',
    paymentTerms: '',
    childrenstatus: "",
    company: '',
    businessSourcess: '',
    spacialOfferId: 0,
    checkinId: 0,
    fullname: '',
    releaseDate: new Date(),
    specialOfferId: 0,
    commissionPlan: '',
    commissionId: 0,
  };
  

 export type GetAllGuest = {
    guestId: number;
    firstName: string;
    lastName: string;
    email?: string;
    phone: string;
    address: string;
    guestIdCard?: string;
    country?: string;
    state?: string;
    city?: string;
    isCheckedIn?: boolean;
    isCheckedOut?: boolean;
    paymentStatus?: boolean;
    fullName?: string;
    checkInTime?: Date | null;
    checkOutTime?: Date | null;
    preferences?: string;
    loyaltyProgram?: string;
    loyaltyProgramId?: string;
    points?: number;
    eliteStatus?: string;
    historyOfStays?: string;
    notes?: string;
    sources?:string;
    visitDate?: Date;
    paymentDetails?: string;
    paymentMethod?: string;
    guestStatus?:string;
    creditCardNumber?: string;
    cardholderName?: string;
    cardExpirationDate?: string;
    cvv?: string;
    billingAddress?: string;
    BillingCountry?:string;
    paymentAmount?: number;
    currency?: string;
    transactionId?: string;
    paymentTimestamp?: Date;
    roomNumber?: number;
    isTravelAgent?: boolean;
    dateOfBirth?: Date;
    issueDateofId?: Date | null;
    epirdate?:Date | null;
    title?: number;
    genderid?:number;
    gender?:string;
    stateId?: number;
    stateName?: number; 
    maritalStatus?:string;
    childrenstatus?:string;
    nationaliy:string;
    language?:string;
    password?: string; 
    zipCode?: string;
    accountings: IAccounting[];
    communications: Communication[];
    invoices: InvoiceInterface[];
    onlineReservations: OnlineReservation[];
    reservations: Reservation[];
    reviews: Review[];
    dateadded: Date | null;
}


export interface GuestActivities {
    guestId: number;
    firstName: string;
    lastName: string;
    email?: string;
    phone: string;
    address: string;
    lastCheckedin: Date;
    lastCheckedOut:Date;
    numberofNightSpent: number;
    lastSourceofBooking: string;
    guestStatus:string;

}
