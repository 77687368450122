import React, { useEffect, useState } from "react";
interface CountdownProps {
    startInSeconds: number;
    close: () => void;
  }
const PaymentLoaders: React.FC<CountdownProps> = ({ startInSeconds, close }) => {
    const [count, setCount] = useState<number>(startInSeconds);

  useEffect(() => {
    if (count > 0) {
      const timerId = setTimeout(() => {
        setCount(count - 1);
      }, 1000);
      return () => clearTimeout(timerId);
    }else{
        close();
    }
  }, [close, count]);
    return (
        <div className="spin-shadow">
            {/* <h2 className="MainCounter">{count}s</h2> */}
            <div className="c-spinner"></div>
        </div>
    )
}

export default PaymentLoaders;