export interface ICheckinGuest {
    guestId: number;
    title: string;
    gender: string;
    firstName: string;
    lastName: string;
    address: string;
    country: string;
    state: string;
    city: string;
    isReserved: boolean;
    isCheckin: boolean;
    isBooked: boolean;
    identity: string;
    idCardNumber: string;
    commissionId: number;
    nationality: string;
    guestStatus: string;
    email: string;
    phone: string;
    roomNumber: number;
    numberOfRooms:number;
    roomName: string;
    arrivalDate: Date;
    numberOfNight: number;
    departureDate: Date;
    reservationType: string;
    numberOfChildren: number;
    numberOfAdult: number;
    modeOfPayment: string;
    rates: string;
    billTo: string;
    isSpecialOffers: boolean;
    isAgent: boolean;
    taxExemptionId: string;
    currency: string;
    releaseDate?: Date;
    paymentTerms: string;
    company: string;
    businessSources: string;
    travelAgent: string;
    agencyName: string;
    isTravelAgent: boolean;
    commissionPlan: string;
    spacialOfferId: number;
    discount: number;
    value: string;
    vocNumber: string;
    dicountType: string;
    amount: number;
}


export interface CheckinGuests {
    GuestId?: any;
    Title?: any;
    Gender?: any;
    FirstName?: any;
    LastName?: any;
    Address?: any;
    Country?: any;
    State?: any;
    City?: any;
    Identity?: any;
    IdCardNumber?: any;
    CommissionId?:any;
    Nationality?: any;
    GuestStatus?: any;
    Email?: any;
    Phone?: any;
    RoomNumber?: any;
    RoomName?: any;
    ArrivalDate?: any;
    NumberOfNight?: any;
    DepartureDate?: any;
    ReservationType?: any;
    NumberOfChildren?: any;
    NumberOfAdult?: any;
    ModeOfPayment?: any;
    Rates?: any;
    BillTo?: any;
    IsSpecialOffers: any|null;
    IsAgent: any;
    TaxExemptionId?: any;
    Currency?: any;
    ReleaseDate?: any;
    PaymentTerms?: any;
    Company?: any;
    BusinessSources?: any;
    TravelAgent?: any;
    AgencyName?: any;
    IsTravelAgent?: any;
    CommissionPlan?: any;
    SpacialOfferId?: any;
    Discount?: any;
    Value?: any;
    VocNumber?: any;
    DicountType?: any;
    Amount?: any;
    tab?: any;
    value?: any;
}


export const InitializeCheckingGuest:ICheckinGuest = {
    guestId: 0,
    title: "",
    gender: "",
    firstName: "",
    lastName: "",
    address: "",
    country: "",
    state: "",
    city: "",
    identity: "",
    numberOfRooms:0,
    idCardNumber: "",
    nationality: "",
    guestStatus: "",
    email: "",
    phone: "",
    roomNumber: 0,
    roomName: "",
    arrivalDate: new Date(),
    numberOfNight: 0,
    departureDate: new Date(),
    reservationType: "",
    numberOfChildren: 0,
    numberOfAdult: 0,
    modeOfPayment: "",
    rates: "",
    billTo: "",
    taxExemptionId: "",
    currency: "NGN",
    releaseDate: new Date(),
    paymentTerms: "",
    isBooked: false,
    isReserved:false,
    isCheckin:false,
    company: "",
    businessSources: "",
    travelAgent: "",
    agencyName: "",
    isTravelAgent: false,
    commissionPlan: "",
    spacialOfferId: 0,
    discount: 0.00,
    isSpecialOffers: false,
    isAgent: false,
    commissionId:0,
    value: "",
    vocNumber: "",
    dicountType: "",
    amount: 0.00

}

export interface IBooking {
    guestId?: any;
    title?: any;
    gender?: any;
    firstName?: any;
    lastName?: any;
    address?: any;
    country?: any;
    state?: any;
    city?: any;
    identity?: any;
    idCardNumber?: any;
    issuesdate?:any;
    expiredate?:any;
    source:string;
    commissionId?:any;
    nationality?: any;
    guestStatus?: any;
    email?: any;
    phone?: any;
    roomNumber?: any;
    roomName?: any;
    arrivalDate?: any ;
    dateofbirth?:any;
    numberOfNight?: any;
    numberOfRoom?: any;
    departureDate?: any;
    reservationType?: any;
    numberOfChildren?: any;
    numberOfadult?: any;
    modeOfPayment?: any;
    language?:any;
    currency?: any;
}



export const InitializeBooking:IBooking = {
    guestId: 0,
    title: "",
    gender: "",
    firstName: "",
    lastName: "",
    address: "",
    country: "",
    state: "",
    city: "",
    identity: "",
    idCardNumber: "",
    issuesdate: new Date(),
    expiredate: new Date(),
    nationality: "",
    guestStatus: "",
    email: "",
    phone: "",
    language: "",
    roomNumber: 0,
    arrivalDate: "",
    numberOfNight: 0,
    departureDate: new Date(),
    reservationType: "",
    numberOfChildren: 0,
    numberOfadult: 0,
    numberOfRoom: 1,
    currency: "NGN",
    dateofbirth: new Date(),
    source: ""
}
