import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import "./GuestChart.scss";
interface GuestChartProps {
    series: {
        name: string;
        data: [number, number][];
    }[];
}

const GuestChart: React.FC<GuestChartProps> = ({ series }) => {
    const [data, setData] = useState<[number, number][]>([]);


    useEffect(() => {
        const interval = setInterval(() => {
            const newDataPoint = [new Date().getTime(), Math.floor(Math.random() * 100)];

            // Update data state
            setData((prevData) => [...prevData, newDataPoint as [number, number]]);

            // Update chart with new data
            ApexCharts.exec('realtime-chart', 'updateSeries', [{ data }]);
        }, 1000);

        return () => clearInterval(interval);
    }, [data]);

    const [options, setOptions] = useState<ApexOptions>({
        chart: {
            id: 'area-datetime',
            type: 'area',
            height: 350,
            zoom: {
                autoScaleYaxis: true,
            },
            animations: {
                enabled: true,
                easing: 'linear',
                dynamicAnimation: {
                    speed: 1000,
                },
            },
        },
        annotations: {
            yaxis: [
                {
                    y: 30,
                    borderColor: '#999',
                    label: {
                        offsetY: -15,
                        text: 'Support',
                        textAnchor: 'end',
                        position: 'insideRight',
                        style: {
                            color: '#fff',
                            background: 'rgb(202, 80, 16)',
                        },
                    },
                },
            ],
            xaxis: [
                {
                    x: new Date().getTime(),
                    borderColor: '#999',
                    label: {
                        text: 'Rally',
                        offsetY: -15,
                        style: {
                            color: '#fff',
                            background: 'rgb(202, 80, 16)',
                        },
                    },
                },
            ],
        },
        dataLabels: {
            enabled: false,
        },
        markers: {
            size: 0,
        },
        xaxis: {
            type: 'datetime',
            min: new Date().getTime(),
            tickAmount: 6,
        },
        tooltip: {
            x: {
                format: 'dd MMM yyyy',
            },
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 100],
            },
        },
    });

    const updateData = (timeline: string) => {
        const currentDate = new Date();
        const oneMonthAgo = new Date(currentDate.setMonth(currentDate.getMonth() - 1));
        const sixMonthsAgo = new Date(currentDate.setMonth(currentDate.getMonth() - 6));
        const oneYearAgo = new Date(currentDate.setFullYear(currentDate.getFullYear() - 1));
        const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
        const allStartDate = new Date('23 Jan 2020');

        const formatDate = (date: Date) => date.toISOString().split('T')[0];

        const dateRanges: Record<string, [string, string]> = {
            one_month: [formatDate(oneMonthAgo), formatDate(currentDate)],
            six_months: [formatDate(sixMonthsAgo), formatDate(currentDate)],
            one_year: [formatDate(oneYearAgo), formatDate(currentDate)],
            ytd: [formatDate(startOfYear), formatDate(currentDate)],
            all: [formatDate(allStartDate), formatDate(currentDate)],
        };


        const [start, end] = dateRanges[timeline];
        const chart: any = document.querySelector('#area-datetime');
        if (chart) {
            ApexCharts.exec(chart, 'zoomX', new Date(start).getTime(), new Date(end).getTime());
        }
    };

    const dateRanges = Object.keys(updateData);

    return (
        <div id="chart">
            <div className="toolbar">
                {dateRanges.map((timeline) => (
                    <button key={timeline} onClick={() => updateData(timeline)}>
                        {timeline.toUpperCase()}
                    </button>
                ))}
            </div>
            <div id="chart-timeline">
                <ReactApexChart options={options} series={series} type="area" height={350} />
            </div>
        </div>
    );
};

export default GuestChart;
